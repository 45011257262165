import React from 'react';
import styles from './Spinner.module.scss';
import cn from 'classnames';

const Spinner = (props) => (
  <div className={cn(styles.spinner)}>
    <div
      className={cn(
        styles.dot,
        styles.bounce1,
        props.className,
        props.purple ? styles.purple : null,
        props.destructive ? styles.destructive : null
      )}
    ></div>
    <div
      className={cn(
        styles.dot,
        styles.bounce2,
        props.className,
        props.purple ? styles.purple : null,
        props.destructive ? styles.destructive : null
      )}
    ></div>
    <div
      className={cn(
        styles.dot,
        styles.bounce3,
        props.className,
        props.purple ? styles.purple : null,
        props.destructive ? styles.destructive : null
      )}
    ></div>
  </div>
);

export default Spinner;
