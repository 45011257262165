import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';
import readModelHub from '../../utils/readModelHub';

function updateUserRequest(userData, updateOther) {
  return {
    type: types.UPDATE_USER,
    userData,
    updateOther,
  };
}

function updateUserSuccess() {
  return {
    type: types.UPDATE_USER_SUCCESS,
  };
}

function updateUserFailed(error) {
  return {
    type: types.UPDATE_USER_ERROR,
    error,
  };
}

function createUpdateUserRequest(userData) {
  return {
    title: userData.title,
    profession: userData.profession,
    firstName: userData.firstName,
    surname: userData.surname,
    placementsYear: userData.placementsYear,
    yearsOfPlacements: userData.yearsOfPlacements,
    restorationsYear: userData.restorationsYear,
    yearsOfRestorations: userData.yearsOfRestorations,
    email: userData.email,
    oldPassword: userData.oldPassword,
    password: userData.password,
    passwordRepeat: userData.passwordRepeat,
    teethNotation: userData.teethNotation,
    logoutTimeInMinutes: userData.logoutTimeInMinutes,
    sourceUserGroup: {
      id: userData.sourceUserGroup.id,
    },
  };
}

export function updateUser(userId, userData) {
  const request = createUpdateUserRequest(userData);

  return (dispatch) => {
    const updateOther = userId ? true : false;

    dispatch(updateUserRequest(userData, updateOther));

    const url = userId ? `admin/users/${userId}` : 'users';

    ApiFetch.put(url, request)
      .then((data) => {
        dispatch(updateUserSuccess(data));
      })
      .catch((error) => {
        dispatch(updateUserFailed(error));
      });
  };
}

function registerUserRequest() {
  return {
    type: types.REGISTER_USER,
  };
}

function registerUserSuccess(userId) {
  return {
    type: types.REGISTER_USER_SUCCESS,
    userId: userId,
  };
}

function registerUserFailed(error) {
  return {
    type: types.REGISTER_USER_ERROR,
    error,
  };
}

function createRegisterUserRequest(userData, eulaId, eulaCountry) {
  return {
    title: userData.title,
    profession: userData.profession,
    firstName: userData.firstName,
    surName: userData.surName,
    clinicName: userData.clinicName,
    NumberOfPractitionersInClinic: userData.numberOfPractitioners,
    placementsYear: userData.placementsYear,
    yearsOfPlacements: userData.yearsOfPlacements,
    restorationsYear: userData.restorationsYear,
    yearsOfRestorations: userData.yearsOfRestorations,
    address: {
      street: userData.address,
      careOf: userData.co,
      city: userData.city,
      postcode: userData.postcode,
      countryCode: userData.countryCode,
      state: userData.state,
    },
    eulaId: eulaId,
    eulaCountry: eulaCountry,
  };
}

export function registerUser(userData, eulaId, eulaCountry) {
  const request = createRegisterUserRequest(userData, eulaId, eulaCountry);

  return (dispatch) => {
    dispatch(registerUserRequest());

    ApiFetch.post('users', request)
      .then((data) => {
        dispatch(registerUserSuccess(data));
      })
      .catch((error) => {
        dispatch(registerUserFailed(error));
      });
  };
}

function getUserRequest(userId) {
  return {
    type: types.GET_USER,
    userId,
  };
}

function getUserSuccess(data, userId) {
  return {
    type: types.GET_USER_SUCCESS,
    user: data,
    userId,
  };
}

function getUserFailed(error, userId) {
  return {
    type: types.GET_USER_ERROR,
    error,
    userId,
  };
}

export function getUser(userId) {
  return (dispatch) => {
    dispatch(getUserRequest(userId));

    const url = userId ? `admin/users/${userId}` : 'users';

    ApiFetch.get(url)
      .then((user) => {
        dispatch(getUserSuccess(user, userId));
        if (!userId) {
          //dispatch(translationActions.changeLocale(user.profile.locale));
        }
      })
      .catch((error) => {
        dispatch(getUserFailed(error, userId));
      });
  };
}

function getUsersRequest() {
  return {
    type: types.GET_USERS,
  };
}

function getUsersSuccess(data) {
  return {
    type: types.GET_USERS_SUCCESS,
    users: data,
  };
}

function getUsersFailed(error) {
  return {
    type: types.GET_USERS_ERROR,
    error,
  };
}

export function getUsers(searchCriteria) {
  return (dispatch) => {
    dispatch(getUsersRequest());

    const url = 'admin/users';

    ApiFetch.post(url, searchCriteria)
      .then((users) => {
        dispatch(getUsersSuccess(users));
      })
      .catch((error) => {
        dispatch(getUsersFailed(error));
      });
  };
}

function changeUserRoleSuccess(userId, newUserRole) {
  return {
    type: types.CHANGE_USER_ROLE_SUCCESS,
    userId: userId,
    newUserRole: newUserRole,
  };
}

function changeUserRoleFailed(error, userId) {
  return {
    type: types.CHANGE_USER_ROLE_ERROR,
    error,
    userId: userId,
  };
}

export function changeUserRole(userId, userSsoId, newUserRole, successCallback, failCallback) {
  return (dispatch) => {
    const url = 'admin/users/userrole';

    const request = {
      userId: userId,
      userSsoId: userSsoId,
      newUserRole: newUserRole,
    };

    ApiFetch.put(url, request)
      .then(() => {
        dispatch(changeUserRoleSuccess(userId, newUserRole));
        successCallback();
      })
      .catch((error) => {
        dispatch(changeUserRoleFailed(error, userId));
        failCallback(error);
      });
  };
}

function deleteUserRequest() {
  return {
    type: types.DELETE_USER,
  };
}

function deleteUserSuccess() {
  return {
    type: types.DELETE_USER_SUCCESS,
  };
}

function deleteUserFailed(error) {
  return {
    type: types.DELETE_USER_ERROR,
    error,
  };
}

export function deleteUser(userId, callback) {
  return (dispatch) => {
    dispatch(deleteUserRequest());

    ApiFetch.delete(`admin/users/${userId}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(deleteUserFailed(error));
      });
  };
}

export function userDeleteItSelf(userId, callback) {
  return (dispatch) => {
    dispatch(deleteUserRequest());

    ApiFetch.delete(`users/${userId}`)
      .then(() => {
        callback();
      })
      .catch((error) => {
        dispatch(deleteUserFailed(error));
      });
  };
}

export function clearUser() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_USER,
    });
  };
}

export function clearUsers() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_USERS,
    });
  };
}

export function dismissNote(noteId) {
  return (dispatch) => {
    dispatch({
      type: types.DISMISS_NOTE,
      noteId,
    });

    ApiFetch.post(`users/notes/${noteId}`);
  };
}

export function subscribeToUsersChange() {
  return (dispatch) => {
    console.log('SignalR: Subscribing to users (subscribeToUsersChange)');
    readModelHub.subscribe('user', null, () => {
      dispatch(getUsers());
    });
  };
}

export function unsubscribeFromUsersChange() {
  return () => {
    console.log('SignalR: Unsubscribing from users (unsubscribeFromUsersChange)');
    readModelHub.unsubscribe('user');
  };
}

export function subscribeToUserChange(userId, action) {
  return (dispatch) => {
    console.log('SignalR: Subscribing to user (subscribeToUserChange) userId:' + userId);
    readModelHub.subscribe('user', userId, () => {
      if (action) {
        action();
      } else {
        dispatch(getUser());
      }
    });
  };
}

export function unsubscribeFromUserChange(userId) {
  return () => {
    console.log('SignalR: Unsubscribing from user (unsubscribeFromUserChange) userId:' + userId);
    readModelHub.unsubscribe('user', userId);
  };
}

export function exportUsers(callback) {
  return () => {
    ApiFetch.downloadFile(`admin/users/export`, callback);
  };
}
