import React, { useCallback, useEffect, useRef, useState } from 'react';
import TeethToolbar from './toolbar/toolbarBottom/TeethToolbar';
import TeethWorkspace from './workspace/TeethWorkspace';
import { VisitsContainer } from './toolbar/toolbarProcedureSteps/VisitsContainer';
import { TeethViewSettingsToolbar } from './toolbar/toolbarViewSettings/TeethViewSettingsToolbar';
import styled from 'styled-components';
import { useAppSelector } from '../../../../utils/hooks';
import WhatsNewWizard from '../../../../components/Shared/Modals/WhatsNewWizard/WhatsNewWizard';

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

interface Props {
  handleTakeScreenshot: () => Promise<string | undefined>;
}

const paddingRight = 100;
const paddingLeft = 100;
const paddingTop = 80;
const paddingBottom = 20;

const wrapperWidth = 960;
const wrapperHeight = 600;

const TeethView = React.forwardRef<HTMLDivElement, Props>(({ handleTakeScreenshot }, ref) => {
  const darkMode = useAppSelector((state) => state.consultation.workspace.present.view.darkMode);
  const imageView = useAppSelector((state) => state.consultation.workspace.present.image);

  const [width, setWidth] = useState(wrapperWidth);
  const [height, setHeight] = useState(wrapperHeight);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const resize = useCallback(function resize() {
    if (!wrapperRef.current?.parentElement) return;

    const maxWidth = window.innerWidth - paddingLeft - paddingRight;
    const maxHeight = window.innerHeight - paddingBottom - paddingTop;

    const maxScale = 1.2;
    const scaleX = clamp(maxWidth / wrapperWidth, 0, maxScale),
      scaleY = clamp(maxHeight / wrapperHeight, 0, maxScale);

    const scale = Math.min(scaleX, scaleY);

    wrapperRef.current.style.transform = `scale(${scale}) translate(0,-${wrapperHeight / 2}px)`;
    wrapperRef.current.style.top = `50%`;
    wrapperRef.current.parentElement.style.width = `${wrapperWidth * scaleX}px`;
    wrapperRef.current.parentElement.style.height = `${maxHeight / 2 + (600 * scale) / 2}px`;

    setWidth(() => 960);
    setHeight(() => 600);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resize);
    requestAnimationFrame(resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  // WhatsNewWizard refs
  const softTissueRef = useRef(null);
  const visitContainerRef = useRef(null);
  const addImageRef = useRef(null);

  return (
    <div ref={ref}>
      <WhatsNewWizard
        wizardSteps={[
          {
            title: 'Introducing new features!',
            text: 'Click through the wizard to see the latest in OsstellConnect.',
            ref: addImageRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.x + 100;
            },
            getCalculatedY: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - 140;
            },
            arrowDirection: 'left',
          },
          {
            title: 'Images as step',
            text: 'Adding an image as a consultation step. For example if you want to dispay an x-ray.',
            ref: addImageRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.x + 100;
            },
            getCalculatedY: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - 140;
            },
            arrowDirection: 'left',
          },
          {
            title: 'Writing and drawing',
            text: 'You can even draw and write on the images.',
            ref: addImageRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.x + 100;
            },
            getCalculatedY: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - 140;
            },
            arrowDirection: 'left',
          },
          {
            title: 'Soft tissue tools',
            text: 'New tools for modifying soft tissue and soft tissue grafting.',
            ref: softTissueRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.x - 130;
            },
            getCalculatedY: (DOMRect: DOMRect, wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - wizardHeight - 160;
            },
            arrowDirection: 'bottom',
          },
          {
            title: 'Steps and Visits',
            text: 'Your consultation steps are now grouped into visits.',
            ref: visitContainerRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, wizardWidth: number) => {
              return DOMRect.x - wizardWidth - 50;
            },
            getCalculatedY: (DOMRect: DOMRect, wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - wizardHeight + wizardHeight;
            },
            arrowDirection: 'right',
          },
          {
            title: 'Move your step',
            text: 'Drag a step to change the order inside a visit. Or drag and drop steps between visits.',
            ref: visitContainerRef,
            getCalculatedX: (DOMRect: DOMRect, _wizardHeight: number, wizardWidth: number) => {
              return DOMRect.x - wizardWidth - 50;
            },
            getCalculatedY: (DOMRect: DOMRect, wizardHeight: number, _wizardWidth: number) => {
              return DOMRect.y - wizardHeight + wizardHeight;
            },
            arrowDirection: 'right',
          },
        ]}
      />

      {/* <TeethUndoToolPanel /> */}
      <div
        style={{
          margin: 'auto',
          maxWidth: '100vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          ref={wrapperRef}
          style={{
            width: wrapperWidth,
            height: wrapperHeight,
            position: 'relative',
            flexGrow: 0,
            flexShrink: 0,
          }}
        >
          <TeethWorkspace width={width} height={height} />
          {!imageView && (
            <TeethDirectionMarkers darkMode={darkMode}>
              <span>R</span>
              <span>L</span>
            </TeethDirectionMarkers>
          )}
        </div>
      </div>
      <VisitsContainer
        visitContainerRef={visitContainerRef}
        handleTakeScreenshot={handleTakeScreenshot}
      />
      <TeethToolbar softTissueRef={softTissueRef} />
      <TeethViewSettingsToolbar addImageRef={addImageRef} />
    </div>
  );
});

const TeethDirectionMarkers = styled.div<{ darkMode?: boolean }>`
  position: absolute;
  top: -16px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 800px;

  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  font-size: 2rem;
  opacity: ${(props) => (props.darkMode ? 0.8 : 0.4)};
  pointer-events: none;
  color: ${(props) => (props.darkMode ? '#fff' : 'inherit')};
`;

TeethView.displayName = 'TeethView';

export default TeethView;
