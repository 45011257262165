import * as React from 'react';

function SvgSvgErase(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.77 28.55" {...props}>
      <defs>
        <style>
          {
            '.SvgErase_svg__cls-1,.SvgErase_svg__cls-2{fill:none;}.SvgErase_svg__cls-1{stroke-miterlimit:10}.SvgErase_svg__cls-2{stroke-linecap:round;stroke-linejoin:round}'
          }
        </style>
      </defs>
      <g id="SvgErase_svg__Layer_2" data-name="Layer 2">
        <g id="SvgErase_svg__Layer_1-2" data-name="Layer 1">
          <path
            className="SvgErase_svg__cls-1"
            d="M23.48 23.52H7.88M24.82 10L11.3 23.52H7.88L3 18.67a3.52 3.52 0 010-5L15.78.94a1.5 1.5 0 012.13 0l6.91 6.91a1.5 1.5 0 010 2.15z"
          />
          <path className="SvgErase_svg__cls-2" d="M3.5 23.52h-3M5.33 25.92l-2.12 2.13" />
          <path strokeLinecap="round" strokeMiterlimit={10} fill="none" d="M4.72 12.01l7.31 7.31" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgErase;
