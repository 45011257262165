import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { H2 } from '../../components/Shared/Headings';

import styles from './CommunityPage.module.scss';
import Header from '../../components/header/Header';
import { Section, SectionInner } from '../../components/Shared/Section';
import { Button } from '../../components/Shared/Buttons';

import * as promotionActions from '../../redux/actions/promotionActions';
import * as generalActions from '../../redux/actions/generalActions';
import { ReactComponent as LogoCommunity } from '../../shared/icons/assets-logo_community.svg';

class CommunityPage extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;

    this.state = {
      showThanks: false,
      promotion: undefined,
    };

    this.formSubmitted = this.formSubmitted.bind(this);
  }

  componentDidMount() {
    //Load Hubspot form
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '454089',
          formId: '80e2e862-c5b4-4e08-bbc6-498dd03eeac3',
          target: '#hubspotFeedbackForm',
        });
      }
    });

    //Add eventlistner to submitted form
    window.addEventListener('message', (event) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        //Removed promotion 2022-04-29
        //this.props.actions.registerPromotion('CommunityFeedback', 1, this.formSubmitted);
        this.formSubmitted();
      }
    });
  }

  formSubmitted(promotion) {
    this.setState({
      showThanks: true,
      promotion: promotion,
    });
  }

  renderThanks() {
    return (
      <div>
        <div className={styles.headerThank}>
          <div className={styles.headerText}>
            <LogoCommunity />
            <FormattedMessage id="community.thankYou" defaultMessage="THANK YOU!" />
          </div>
        </div>
        <Section>
          <SectionInner className={styles.contentThanks}>
            <div className={styles.contentBox}>
              <FormattedMessage
                id="community.thankYouText"
                defaultMessage="Thank you for being part of the community — it is your contribution that helps us make OsstellConnect better."
              />
            </div>
            {this.props.user.promotions.some((x) => x.description === 'CommunityFeedback') &&
              this.state.promotion && (
                <div
                  className={styles.promotionBox}
                  onClick={() => this.props.actions.showFreeSmartPegs(this.state.promotion)}
                >
                  <p>
                    <FormattedMessage
                      id="community.freeSmartPegText"
                      defaultMessage="To show our appreciation, we will send you a box of SmartPegs free of charge. 
                    Click here to choose your SmartPegs type and confirm your shipping address."
                    />
                  </p>
                  <div>
                    <Button
                      value={this.intl.formatMessage({
                        id: 'community.smartpegPromotionButton',
                        defaultMessage: 'Collect SmartPegs!',
                      })}
                    />
                  </div>
                </div>
              )}
          </SectionInner>
        </Section>
      </div>
    );
  }

  render() {
    const headerButtonsLeft = [
      {
        label: this.intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
        icon: 'back',
        action: this.props.history.goBack,
      },
    ];
    return (
      <div className={styles.AboutPage}>
        <Header
          title={this.intl.formatMessage({ id: 'community.Header', defaultMessage: 'COMMUNITY' })}
          leftButtons={headerButtonsLeft}
          isTogglerHidden
        />
        {this.state.showThanks ? (
          this.renderThanks()
        ) : (
          <div>
            <div className={styles.header}>
              <div className={styles.headerText}>
                <LogoCommunity />
                <FormattedMessage id="community.building" defaultMessage="Building for the" />{' '}
                <span className={styles.future}>
                  <FormattedMessage id="community.future" defaultMessage="future" />
                </span>
              </div>
            </div>
            <Section>
              <SectionInner>
                <div className={styles.contentBox}>
                  <FormattedMessage
                    id="community.infoText"
                    defaultMessage="Share your valuable feedback and suggestions to further improve the experience of OsstellConnect,
to help build the future OsstellConnect, for you and thousands of clinicians around the world."
                  />
                </div>
                <div className={styles.title}>
                  <FormattedMessage
                    id="community.SubmitHeader"
                    defaultMessage="Submit your ideas or feedback:"
                  />
                </div>
                <div className={styles.formWrapper}>
                  <div className={styles.hubspot} id="hubspotFeedbackForm"></div>
                  <div className={styles.guideLines}>
                    <H2>
                      <FormattedMessage id="community.guideLines" defaultMessage="Guidelines" />
                    </H2>
                    <FormattedMessage
                      id="community.guideLinesText1"
                      defaultMessage="The more you can tell us about your idea or feedback, the more impact it will have."
                    />
                    <br />
                    <FormattedMessage
                      id="community.guideLinesText2"
                      defaultMessage="Who (what type of role or team) is the feature for?"
                    />
                    <br />
                    <FormattedMessage
                      id="community.guideLinesText3"
                      defaultMessage="What is the goal?"
                    />
                    <br />
                    <FormattedMessage
                      id="community.guideLinesText4"
                      defaultMessage="What value will this add?"
                    />
                    <br />
                    <FormattedMessage
                      id="community.guideLinesText5"
                      defaultMessage="Are there examples of other places or products that have this feature?"
                    />
                    <br />
                    <FormattedMessage
                      id="community.guideLinesText6"
                      defaultMessage="Screenshots, pictures, drawings, external links are helpful."
                    />
                  </div>
                </div>
              </SectionInner>
            </Section>
          </div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...promotionActions, ...generalActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(CommunityPage));
