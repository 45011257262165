import { Link } from 'react-router-dom';
import { Button } from '../../components/Shared/Buttons';
import { useIntl } from 'react-intl';
import { AlertBox } from '../../components/Shared/Alerts';
import { InputItem } from '../../components/Shared/Forms';
import { appInsights } from '../../utils/applicationInsights';
import { changeLocale } from '../../redux/actions/translationActions';
import { languageOptions } from '../../constants/languageOptions';
import { FormattedMessage } from 'react-intl';
import { getEula, acceptEula } from '../../redux/actions/eulaActions';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { loginAccount, clearToken } from '../../redux/actions/accountActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react';
import loginVideo from '../../shared/videos/loginBg.mp4';
import styles from './LoginPage.module.scss';
import Switch from 'react-switch';
import Modal from '../../components/Shared/Modal/Modal';

function Login() {
  const query = useQuery();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<{
    from?: {
      pathname: string;
    };
  }>();
  const dispatch = useAppDispatch();

  const externalLoginError = query.get('errorMessage');
  const { user } = useAppSelector((state) => state.user);
  const { locale } = useAppSelector((state) => state.translation);

  const { eula, acceptEula: acceptingEula } = useAppSelector((state) => state.eula);
  const { login, token, inactivity, tokenExpired, lastUsedEmail, accountConfirmation } =
    useAppSelector((state) => state.account);

  const [email, setEmail] = useState(lastUsedEmail === 'demo@osstell.com' ? 'demo' : lastUsedEmail);
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showEulaText, setShowEulaText] = useState(false);

  useEffect(() => {
    if (user.loaded) {
      if (!user.userCreated) {
        dispatch(clearToken(false, false));
        history.replace('/register');
      } else {
        if (user.currentEulaAccepted === true) {
          const from = location.state?.from?.pathname || '/home';
          history.replace(from);
          if (window.ReactNativeWebView && token)
            window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'login', token }));
        } else {
          dispatch(getEula(user.eulaCountry));
          setShowModal(true);
        }
      }
    } else if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'login', token: '' }));
    }
  }, [user, history, dispatch, location, token]);

  const hideModal = () => {
    setShowModal(false);
    setShowEulaText(false);
    dispatch(clearToken(false, false));
  };

  const acceptsEulaHandler = () => {
    appInsights.trackEvent({
      name: 'TermsAndConditions',
      properties: {
        type: 'termsAccepted',
        email: user.email,
      },
    });
    dispatch(acceptEula(user.id, eula.id));
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      tryLogin();
    }
  };

  const tryLogin = () => {
    if (email === 'usedev' && window.ReactNativeWebView)
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'changeurl',
          url: 'https://development.osstellconnect.com',
        })
      );
    else if (email === 'demo' && password === 'demo')
      dispatch(loginAccount('demo@osstell.com', 'Demo123!'));
    else dispatch(loginAccount(email.trim(), password));
  };

  const incompatibleBrowser = () => {
    const ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11
    return msie > 0 || trident > 0;
  };

  const inactivityMessage = inactivity
    ? {
        messages: [
          {
            text: intl.formatMessage({
              id: 'login.AlertLoggedOutTimeOut',
              defaultMessage: 'Logged out',
            }),
          },
        ],
      }
    : null;
  const tokenExpiredMessage = tokenExpired
    ? {
        messages: [
          {
            text: intl.formatMessage({
              id: 'login.AlertLoggedOutToken',
              defaultMessage: 'Logged out',
            }),
          },
        ],
      }
    : null;

  let errors = inactivityMessage || tokenExpiredMessage || login.error || accountConfirmation.error;

  let errorHeaderText =
    inactivityMessage || tokenExpiredMessage
      ? intl.formatMessage({
          id: 'login.securityInformation',
          defaultMessage: 'Security Information',
        })
      : intl.formatMessage({
          id: 'general.generalError',
          defaultMessage: 'Oops, there was an error',
        });

  if (externalLoginError) {
    errors = externalLoginError;
    errorHeaderText = intl.formatMessage({
      id: 'genereal.generalError',
      defaultMessage: 'There was an error signing in with external provider',
    });
  }

  return (
    <div className={styles.loginPage}>
      <div className={styles.loginForm}>
        <div className={styles.loginPanelWrapper}>
          <div className={styles.loginPanel}>
            {process.env.REACT_APP_DEMO ? (
              <div className={styles.siteNote}>
                {intl.formatMessage({
                  id: 'header.demoNote',
                  defaultMessage: 'DEMO - Not for clinical use',
                })}
              </div>
            ) : null}
            <div className={styles.logo}>
              <Logo />
            </div>
            {incompatibleBrowser() && (
              <AlertBox
                headerText={intl.formatMessage({
                  id: 'login.IncompatibleBrowser',
                  defaultMessage: 'Incompatible Browser',
                })}
                message={intl.formatMessage({
                  id: 'login.IncompatibleBrowserText',
                  defaultMessage:
                    'You are using an old incompatible browser. Pages in OsstellConnect may not be displayed properly. Please update to latest version of Microsoft Edge or Chrome.',
                })}
              />
            )}
            {errors ? (
              <AlertBox
                dataCyHeader="login-page-alert-header"
                dataCyMessage="login-page-alert-message"
                headerText={errorHeaderText}
                messages={errors.messages.map((m: { text: string }) => m.text)}
              />
            ) : null}
            <InputItem
              label={intl.formatMessage({ id: 'general.email', defaultMessage: 'Email' })}
              placeholder={intl.formatMessage({
                id: 'login.pleaseEnterEmail',
                defaultMessage: 'Please enter your e-mail',
              })}
              type="email"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
              onKeyPress={handleKeyPress}
              value={email}
              dataCy="email-input"
            />
            <InputItem
              label={intl.formatMessage({
                id: 'general.password',
                defaultMessage: 'Password',
              })}
              placeholder={intl.formatMessage({
                id: 'login.pleaseEnterPassword',
                defaultMessage: 'Please enter your password',
              })}
              type="password"
              onChange={(evt: ChangeEvent<HTMLInputElement>) => setPassword(evt.target.value)}
              onKeyPress={handleKeyPress}
              value={password}
              dataCy="password-input"
            />
            <Button
              dataCy="login-button"
              primary
              big
              full
              value={intl.formatMessage({ id: 'login.signIn', defaultMessage: 'Sign in' })}
              rightIcon="forward"
              onClick={tryLogin}
              disabled={login.busy}
              loading={login.busy}
              centered
            />
            <div className={styles.auxActions}>
              <div>
                <Link to="/register">
                  {intl.formatMessage({
                    id: 'login.createAccount',
                    defaultMessage: 'Create account',
                  })}
                </Link>
              </div>
              <div>
                <Link to="/retrieve">
                  {intl.formatMessage({
                    id: 'login.forgotPassword',
                    defaultMessage: 'Forgot password?',
                  })}
                </Link>
              </div>
            </div>
            <InputItem
              label={intl.formatMessage({
                id: 'login.Language',
                defaultMessage: 'Language',
              })}
              type="select"
              value={locale}
              options={languageOptions}
              onChange={(evt: ChangeEvent<HTMLInputElement>) =>
                dispatch(changeLocale(evt.target.value))
              }
            />
            {window.ReactNativeWebView && (
              <label className={styles.switchWrapper}>
                <span>
                  <FormattedMessage id="login.useDemoSite" defaultMessage="Use Demo Site" />
                </span>
                <Switch
                  checked={process.env.REACT_APP_DEMO !== undefined}
                  onChange={(checked) => {
                    window.ReactNativeWebView &&
                      window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                          type: 'changeurl',
                          url: checked
                            ? 'https://demo.osstellconnect.com'
                            : 'https://osstellconnect.com',
                        })
                      );
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={styles.purple}
                  offColor="#fff"
                  onHandleColor="#fff"
                  offHandleColor={styles.purple}
                  activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                  handleDiameter={20}
                  height={25}
                  width={45}
                />
              </label>
            )}
          </div>
        </div>
      </div>
      <div className={styles.info}>
        <div className={styles.videoWrapper}>
          <div className={styles.videoShade}></div>
          <video autoPlay muted loop playsInline className={styles.videoBg}>
            <source src={loginVideo} type="video/mp4" />
          </video>
        </div>
      </div>
      {eula.data ? (
        <Modal header="Terms of service" show={showModal} onDismiss={hideModal}>
          <div>
            {!showEulaText ? (
              <div className={styles.termsOfServiceWrapper}>
                <p>
                  {intl.formatMessage({
                    id: 'login.eulaText',
                    defaultMessage:
                      'We have updated our terms and conditions. Before using the application again, please read and agree to the terms and conditions at the end of the document.',
                  })}
                </p>
                <Button
                  center
                  onClick={() => setShowEulaText(true)}
                  primary
                  value={intl.formatMessage({
                    id: 'login.readTermsAndConditions',
                    defaultMessage: 'Read terms and conditions',
                  })}
                />
              </div>
            ) : (
              <>
                <div dangerouslySetInnerHTML={eula.data} />
                <Button
                  primary
                  big
                  full
                  value="Accept"
                  onClick={acceptsEulaHandler}
                  disabled={acceptingEula.busy}
                />
                <Button big full value="Decline" onClick={hideModal} />
              </>
            )}
          </div>
        </Modal>
      ) : null}
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default Login;
