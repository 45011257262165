import {
  ABUTMENT,
  GRAFT,
  IMPLANT,
  IMPLANT_TYPE2,
  LOSS_ALL,
  LOSS_LOCAL,
  SOFT_TISSUE_LOSS_ALL,
  SOFT_TISSUE_LOSS_LOCAL,
  SOFT_TISSUE_GRAFT,
  PEAK,
  PREPARATION,
  RESTORATION_SINGLE,
  RESTORATION_BRIDGE,
  RESTORATION_OVERDENTURE,
  SINUS_GRAFT,
  STABILITY,
  TOGGLE_TOOTH_ALL,
  TOGGLE_TOOTH_SINGLE,
  ABUTMENT_OVERDENTURE,
  IMPLANT_TRANSFORM,
} from '../../../../../redux/slices/workspaceSlice';
import {
  ConsultationSubTool,
  ConsultationTool,
} from '../../../../../redux/slices/data-structures/workspaceStructure';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';

export const TeethToolResolver = (
  tool?: ConsultationTool,
  subTool?: ConsultationSubTool
): ActionCreatorWithPayload<any, string> | undefined => {
  switch (tool?.name) {
    case 'Teeth':
      switch (subTool?.name) {
        case 'Single':
          return TOGGLE_TOOTH_SINGLE;
        case 'All':
          return TOGGLE_TOOTH_ALL;
      }
      break;
    case 'Bone':
      switch (subTool?.name) {
        case 'Loss Local':
          return LOSS_LOCAL;
        case 'Loss All':
          return LOSS_ALL;
        case 'Graft':
          return GRAFT;
        case 'Sinus Graft':
          return SINUS_GRAFT;
      }
      break;
    case 'SoftTissue':
      switch (subTool?.name) {
        case 'Loss Local':
          return SOFT_TISSUE_LOSS_LOCAL;
        case 'Loss All':
          return SOFT_TISSUE_LOSS_ALL;
        case 'Graft':
          return SOFT_TISSUE_GRAFT;
        case 'Peak':
          return PEAK;
      }
      break;
    case 'Implant':
      switch (subTool?.name) {
        case 'Type1':
          return IMPLANT;
        case 'Type2':
          return IMPLANT_TYPE2;
        case 'Transform':
          return IMPLANT_TRANSFORM;
      }
      break;
    case 'Stability':
      return STABILITY;
    case 'Abutment':
      switch (subTool?.name) {
        case 'Fixed':
          return ABUTMENT;
        case 'Overdenture':
          return ABUTMENT_OVERDENTURE;
      }
      break;
    /*      return ABUTMENT;*/
    case 'Preparation':
      return PREPARATION;
    case 'Restoration':
      switch (subTool?.name) {
        case 'Single':
          return RESTORATION_SINGLE;
        case 'Bridge':
          return RESTORATION_BRIDGE;
        case 'Overdenture':
          return RESTORATION_OVERDENTURE;
      }
      break;
    case 'Text':
      return;
    case 'Draw':
      return;
    default:
      console.info(`${tool?.name}:${subTool?.name} not implemented yet`);
      return;
  }
};
