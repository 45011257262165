import { H2 } from '../../components/Shared/Headings';
import { toastr } from 'react-redux-toastr';
import { Button } from '../../components/Shared/Buttons';
import { useIntl } from 'react-intl';
import { appInsights } from '../../utils/applicationInsights';
import { toastrOptions } from '../../constants/toastrOptions';
import { IDentalNotation } from '../../types/dentalNotation';
import { FormattedMessage } from 'react-intl';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Section, SectionInner } from '../../components/Shared/Section';
import { IImplant, IImplantMeasurement } from '../../types/implant';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  getPatient as getPatientRequest,
  getPatientDentalChart as getPatientDentalChartRequest,
  getPatientImplants as getPatientImplantsRequest,
  deletePatient as deletePatientRequest,
  deleteImplant as deleteImplantRequest,
  subscribeToPatientChange as subscribeToPatientChangeRequest,
  subscribeToPatientDentalChartChange as subscribeToPatientDentalChartChangeRequest,
  subscribeToPatientImplantsChange as subscribeToPatientImplantsChangeRequest,
  unsubscribeFromPatientChange as unsubscribeFromPatientChangeRequest,
  unsubscribeFromPatientDentalChartChange as unsubscribeFromPatientDentalChartChangeRequest,
  unsubscribeFromPatientImplantsChange as unsubscribeFromPatientImplantsChangeRequest,
  selectPatientImplant as selectPatientImplantRequest,
} from '../../redux/actions/patientActions';
import {
  showConfirmation as showConfirmationRequest,
  showAlert,
} from '../../redux/actions/generalActions';
import {
  getStudyImplantData as getStudyImplantDataRequest,
  getStudyAbutmentData as getStudyAbutmentDataRequest,
  getAbutmentData as getAbutmentDataRequest,
} from '../../redux/actions/referenceDataActions';
import CreateMeasurementModal from '../../components/implants/CreateMeasurementModal';
import CreateTreatmentModal from '../../components/implants/CreateTreatmentModal';
import CreateImplantModal from '../../components/implants/CreateImplantModal';
import FailImplantModal from '../../components/implants/FailImplantModal';
import ImplantPanel from '../../components/implants/ImplantPanel';
import DentalChart from '../../components/DentalChart/DentalChart';
import Header from '../../components/header/Header';
import styles from './PatientPage.module.scss';

const PatientPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();

  const {
    currentPatient: patient,
    currentDentalChart: dentalChart,
    currentImplants: implants,
    selectedPatientImplant: selectedImplant,
    deletePatient,
    getPatient,
  } = useAppSelector((state) => state.patient);
  const { activeInstrument } = useAppSelector((state) => state.instrument);
  const { dentalNotifications } = useAppSelector((state) => state.referenceData);
  const { user } = useAppSelector((state) => state.user);

  const [showCreateTreatmentModal, setShowCreateTreatmentModal] = useState(false);
  const [showCreateImplantModal, setShowCreateImplantModal] = useState(false);
  const [showCreateAbutmentModal, setShowCreateAbutmentModal] = useState(false);
  const [showCreateMeasurementModal, setShowCreateMeasurementModal] = useState(false);
  const [showFailImplantModal, setShowFailImplantModal] = useState(false);
  const [currentImplant, setCurrentImplant] = useState<IImplant | null>(null);
  const [manualISQ, setManualISQ] = useState(false);
  const [measurement, setMeasurement] = useState<IImplantMeasurement | null | undefined>(undefined);
  const [deletingPatient, setDeletingPatient] = useState(false);

  const headerButtonsLeft = [
    {
      label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
      icon: 'back',
      action: history.goBack,
    },
  ];
  const headerButtonsRight = [
    {
      label: intl.formatMessage({ id: 'patient.exportPdf', defaultMessage: 'Export' }),
      icon: 'pdf',
      action: () => navigateHandler(`/usergroup/${userGroupId}/patients/${patientId}/export`),
    },
    {
      label: intl.formatMessage({ id: 'general.edit', defaultMessage: 'Edit' }),
      icon: 'draw',
      action: () =>
        navigateHandler(`/usergroup/${userGroupId}/patients/${patientId}/patientInformation`),
    },
    {
      label: intl.formatMessage({
        id: 'general.conslutation',
        defaultMessage: 'Consultation',
      }),
      icon: 'patient_records',
      action: () => navigateHandler(`/usergroup/${userGroupId}/patients/${patientId}/consultation`),
    },
    {
      label: intl.formatMessage({ id: 'general.delete', defaultMessage: 'Delete' }),
      icon: 'delete',
      action: () => deletePatientClickHandler(),
    },
  ];

  const showModal = showCreateImplantModal || showCreateAbutmentModal;

  useEffect(() => {
    appInsights.trackEvent({
      name: 'PageVisit',
      properties: {
        page: 'patientpage',
        email: user.email,
      },
    });

    const userId = user.id;

    dispatch(getPatientRequest(userGroupId, patientId));
    dispatch(getPatientDentalChartRequest(userGroupId, patientId));
    dispatch(getPatientImplantsRequest(userGroupId, patientId));
    dispatch(subscribeToPatientChangeRequest(userId, userGroupId, patientId));
    dispatch(subscribeToPatientDentalChartChangeRequest(userId, userGroupId, patientId));
    dispatch(subscribeToPatientImplantsChangeRequest(userId, userGroupId, patientId));
    dispatch(getStudyImplantDataRequest());
    dispatch(getStudyAbutmentDataRequest());
    dispatch(getAbutmentDataRequest(true));

    return () => {
      dispatch(unsubscribeFromPatientChangeRequest(patientId));
      dispatch(unsubscribeFromPatientDentalChartChangeRequest(patientId));
      dispatch(unsubscribeFromPatientImplantsChangeRequest(patientId));
    };
  }, [user]);

  useEffect(() => {
    if (getPatient.error) {
      history.push(`/patients`);
    }
    if (deletePatient.complete && deletingPatient) {
      setDeletingPatient(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'patient.PatientDeleted',
          defaultMessage: 'Patient deleted',
        }),
        toastrOptions
      );
      history.push(`/patients`);
    }

    if (selectedImplant > 0 && implants.length > 0)
      return setCurrentImplant(
        implants.find((implant: IImplant) => implant.tooth === selectedImplant)
      );

    setCurrentImplant(null);
  }, [
    getPatient.error,
    deletePatient.complete,
    deletingPatient,
    selectedImplant,
    implants,
    history,
  ]);

  const navigateHandler = (path: string) => history.push(path);

  const renderImplantsPanelHandler = () => {
    if (implants && implants.length >= 0) {
      let singleImplant = implants.find((implant: IImplant) => implant.tooth === selectedImplant);
      if (singleImplant) {
        const notification = dentalNotifications.find(
          (notification: IDentalNotation) => notification.uns === singleImplant.tooth
        );
        singleImplant = Object.assign({}, singleImplant, {
          uns: notification.uns,
          fdi: notification.fdi,
        });
      }
      return (
        <ImplantPanel
          implant={singleImplant}
          tooth={selectedImplant}
          showCreateTreatmentModal={showCreateTreatmentModalHandler}
          showCreateImplantModal={showCreateImplantModalHandler}
          showCreateAbutmentModal={showCreateAbutmentModalHandler}
          showCreateMeasurementModal={showCreateMeasurementModalHandler}
          showFailImplantModal={showFailImplantModalHandler}
          deleteTreatment={deleteTreatmentHandler}
        />
      );
    }

    return null;
  };

  const renderSelectToothHandler = () => {
    return (
      <div className={styles.selectToothText}>
        <FormattedMessage
          id="patient.selectTooth"
          defaultMessage="Select a tooth to start a treatment"
        />
      </div>
    );
  };

  const showCreateTreatmentModalHandler = (tooth: number) => {
    if (selectedImplant !== tooth) {
      dispatch(selectPatientImplantRequest(tooth));
    }
    setShowCreateTreatmentModal(true);
  };

  const hideCreateTreatmentModalHandler = () => setShowCreateTreatmentModal(false);

  const showCreateImplantModalHandler = (tooth: number) => {
    if (user.hasInstrumentPendingUpdates) return showUpgradeModal();
    if (selectedImplant !== tooth) {
      dispatch(selectPatientImplantRequest(tooth));
    }
    setShowCreateImplantModal(true);
  };

  const showCreateAbutmentModalHandler = (tooth: number) => {
    if (user.hasInstrumentPendingUpdates) return showUpgradeModal();
    if (selectedImplant !== tooth) {
      dispatch(selectPatientImplantRequest(tooth));
    }
    setShowCreateAbutmentModal(true);
  };

  const hideCreateImplantModalHandler = () => {
    setShowCreateImplantModal(false);
    setShowCreateAbutmentModal(false);
  };

  const showCreateMeasurementModalHandler = (
    tooth: number,
    manual: boolean,
    measurement: IImplantMeasurement | null
  ) => {
    if (user.hasInstrumentPendingUpdates) return showUpgradeModal();
    if (selectedImplant !== tooth) {
      dispatch(selectPatientImplantRequest(tooth));
    }
    setShowCreateMeasurementModal(true);
    setManualISQ(manual);
    setMeasurement(measurement);
  };

  const hideCreateMeasurementModalHandler = () => {
    setShowCreateMeasurementModal(false);
    setManualISQ(false);
    setMeasurement(undefined);
  };

  const showFailImplantModalHandler = (tooth: number) => {
    if (selectedImplant !== tooth) {
      dispatch(selectPatientImplantRequest(tooth));
    }
    setShowFailImplantModal(true);
  };

  const hideFailImplantModalHandler = () => setShowFailImplantModal(false);

  const showUpgradeModal = () => {
    const content = (
      <div>
        <p>
          {intl.formatMessage({
            id: 'idxMessage.inOrderToUse',
            defaultMessage:
              'In order to use this functionality you need to upgrade your IDx to the latest software.',
          })}
        </p>
        <p>
          {intl.formatMessage({
            id: 'idxMessage.forMoreInformation',
            defaultMessage: 'For more information on how to update your IDx please follow:',
          })}
        </p>
        <a
          href="https://www.osstell.com/wp-content/uploads/2015/03/Software-Upgrade-6.0.pdf"
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({
            id: 'idxMessage.howToUpgrade',
            defaultMessage: 'How to upgrade',
          })}
        </a>
      </div>
    );
    dispatch(
      showAlert(
        intl.formatMessage({
          id: 'idxMessage.upgradeAvailable',
          defaultMessage: 'IDx Upgrade available',
        }),
        content
      )
    );
  };

  const renderDentalChart = () => {
    return (
      <Section>
        <SectionInner className={styles.patientSectionInner}>
          <div id="dentalChartContainer" className={styles.dentalChartContainer}>
            <DentalChart implants={dentalChart.implants} />
          </div>
        </SectionInner>
      </Section>
    );
  };

  const deletePatientClickHandler = () => {
    const content = (
      <div>
        <p>
          {intl.formatMessage({
            id: 'patient.DeleteConfirmation',
            defaultMessage:
              'Are you sure your want to delete the patient with all its implants and measurements?',
          })}
        </p>
      </div>
    );
    dispatch(
      showConfirmationRequest(
        intl.formatMessage({ id: 'patient.DeletePatient', defaultMessage: 'Delete Patient' }),
        content,
        deletePatientHandler
      )
    );
  };

  const deleteTreatmentHandler = (tooth: number) => {
    const content = (
      <div>
        <p>
          {intl.formatMessage({
            id: 'patient.DeleteTreatmentConfirm',
            defaultMessage:
              'Are you sure your want to permanently delete the treatment with all its measurements?',
          })}
        </p>
      </div>
    );
    dispatch(
      showConfirmationRequest(
        intl.formatMessage({
          id: 'patient.DeleteTreatment',
          defaultMessage: 'Delete Treatment',
        }),
        content,
        () => dispatch(deleteImplantRequest(patient.sourceUserGroupId, patient.id, tooth))
      )
    );
  };

  const deletePatientHandler = () => {
    setDeletingPatient(true);
    dispatch(deletePatientRequest(patient.sourceUserGroupId, patient.id));
  };

  const instrumentId =
    activeInstrument.Active && !activeInstrument.RegCode ? activeInstrument.InstrumentId : null;
  let title =
    patient.firstName && patient.lastName ? patient.firstName + ' ' + patient.lastName + ' - ' : '';
  title = title.concat(patient.patientIdentifier || '');
  const header = (
    <div>
      {title}
      <Button
        white
        secondary
        noShadow
        className={styles.riskButton}
        onClick={() =>
          navigateHandler(
            `/usergroup/${userGroupId}/patients/${patientId}/patientInformation/risks`
          )
        }
        value={intl.formatMessage({
          id: 'general.riskFactors',
          defaultMessage: 'Risk factors',
        })}
      />
    </div>
  );

  return (
    <div className={styles.fullPage}>
      <Header
        title={header}
        isTogglerHidden
        leftButtons={headerButtonsLeft}
        rightButtons={headerButtonsRight}
        skipTitleOnMobile={true}
      />
      <H2 className={styles.mobileTitle}>{title}</H2>
      {renderDentalChart()}
      {selectedImplant > 0 ? renderImplantsPanelHandler() : renderSelectToothHandler()}
      <CreateTreatmentModal
        show={showCreateTreatmentModal}
        hide={hideCreateTreatmentModalHandler}
        implant={currentImplant}
      />
      {showModal && (
        <CreateImplantModal
          show={showCreateImplantModal || showCreateAbutmentModal}
          hide={hideCreateImplantModalHandler}
          implant={currentImplant}
          abutment={showCreateAbutmentModal}
        />
      )}
      {showCreateMeasurementModal && (
        <CreateMeasurementModal
          show={showCreateMeasurementModal}
          hide={hideCreateMeasurementModalHandler}
          editable={manualISQ}
          activeInstrumentId={instrumentId}
          measurement={measurement}
          implant={currentImplant}
        />
      )}

      <FailImplantModal show={showFailImplantModal} hide={hideFailImplantModalHandler} />
    </div>
  );
};

export default PatientPage;
