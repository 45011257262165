import React from 'react';
import styles from './Headings.module.scss';
import cn from 'classnames';

const H2 = (props) => (
  <h2 className={cn(styles.h2, props.className)} style={props.style}>
    {props.children}
  </h2>
);

export default H2;
