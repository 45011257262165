import * as types from '../../constants/actionTypes';
import * as refData from '../../constants/referenceData';
import { dentalNotations } from '../../constants/dentalNotations';
import _ from 'lodash';

const initialState = {
  studyImplantData: [],
  studyAbutmentData: [],
  implantData: [],
  abutmentData: [],
  smartPegData: [],
  smartPegDataMegagen: [],
  refListResponse: {
    complete: false,
    error: undefined,
    busy: false,
  },
  refListData: {},
  countries: [],

  //To be removed later, components should get themget directly from constants/referenceData
  dentalNotifications: dentalNotations,
  titles: refData.titles,
  professions: refData.professions,
  numberOfPractitioners: refData.numberOfPractitioners,
  loadingProtocols: refData.loadingProtocols,
  plannedProstheticSolutions: refData.plannedProstheticSolutions,
  surgicalProtocols: refData.surgicalProtocols,
  patientRiskFactors: refData.patientRiskFactors,
  implantsYear: refData.implantsYear,
  yearsOfExperience: refData.yearsOfExperience,
  prePlacementProcedures: refData.prePlacementProcedures,
  implantSitePlacementOptions: refData.implantSitePlacementOptions,
  augmentationMaterialOptions: refData.augmentationMaterialOptions,
  surgicalTechniqueOptions: refData.surgicalTechniqueOptions,
  implantFailureTypes: refData.implantFailureTypes,
  implantReasonForFailure: refData.implantReasonForFailure,

  // appSettings: {
  //   loaded: false,
  // },
  updateImplantData: {
    complete: false,
    error: undefined,
    busy: false,
  },
  updateAbutmentData: {
    complete: false,
    error: undefined,
    busy: false,
  },
  releaseRefList: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function referenceData(state = initialState, action) {
  //let appSettingsCopy;
  switch (action.type) {
    case types.GET_COUNTRIES:
      return Object.assign({}, state, {});
    case types.GET_COUNTRIES_SUCCESS:
      return Object.assign({}, state, { countries: action.countries });
    case types.GET_COUNTRIES_ERROR:
      return Object.assign({}, state, {});
    case types.GET_DENTAL_NOTIFICATIONS:
      return Object.assign({}, state, {});
    case types.GET_DENTAL_NOTIFICATIONS_SUCCESS:
      return Object.assign({}, state, {
        dentalNotifications: action.dentalNotifications,
      });
    case types.GET_DENTAL_NOTIFICATIONS_ERROR:
      return Object.assign({}, state, {});
    case types.GET_IMPLANT_DATA:
      return Object.assign({}, state, { implantData: [] });
    case types.GET_IMPLANT_DATA_SUCCESS:
      return Object.assign({}, state, { implantData: action.implantData });
    case types.GET_IMPLANT_DATA_ERROR:
      return Object.assign({}, state, {});
    case types.GET_ABUTMENT_DATA:
      return Object.assign({}, state, { abutmentData: [] });
    case types.GET_ABUTMENT_DATA_SUCCESS:
      return Object.assign({}, state, { abutmentData: action.abutmentData });
    case types.GET_ABUTMENT_DATA_ERROR:
      return Object.assign({}, state, {});
    case types.GET_SMARTPEG_DATA:
      return Object.assign({}, state, {});
    case types.GET_SMARTPEG_DATA_SUCCESS:
      if (action.megagen)
        return Object.assign({}, state, { smartPegDataMegagen: action.smartPegData });
      else return Object.assign({}, state, { smartPegData: action.smartPegData });
    case types.GET_SMARTPEG_DATA_ERROR:
      return Object.assign({}, state, {});

    case types.GET_REFLIST_DATA:
      return Object.assign({}, state, {
        refListData: {},
        refListResponse: { complete: false, error: undefined, busy: true },
      });

    case types.GET_REFLIST_DATA_SUCCESS:
      return Object.assign({}, state, {
        refListData: action.refListData,
        refListResponse: { complete: true, error: undefined, busy: false },
      });

    case types.GET_REFLIST_DATA_ERROR:
      return Object.assign({}, state, {
        refListResponse: { complete: false, error: action.error, busy: false },
      });

    case types.GET_STUDY_IMPLANT_DATA:
      return Object.assign({}, state, { studyImplantData: [] });
    case types.GET_STUDY_IMPLANT_DATA_SUCCESS:
      return Object.assign({}, state, { studyImplantData: action.studyImplantData });
    case types.GET_STUDY_IMPLANT_DATA_ERROR:
      return Object.assign({}, state, {});

    case types.GET_STUDY_ABUTMENT_DATA:
      return Object.assign({}, state, { studyAbutmentData: [] });
    case types.GET_STUDY_ABUTMENT_DATA_SUCCESS:
      return Object.assign({}, state, { studyAbutmentData: action.studyAbutmentData });
    case types.GET_STUDY_ABUTMENT_DATA_ERROR:
      return Object.assign({}, state, {});

    case types.UPDATE_IMPLANT_DATA:
      return Object.assign({}, state, {
        updateImplantData: { complete: false, error: undefined, busy: true },
      });
    case types.UPDATE_IMPLANT_DATA_SUCCESS:
      return Object.assign({}, state, {
        updateImplantData: { complete: true, error: undefined, busy: false },
      });
    case types.UPDATE_IMPLANT_DATA_ERROR:
      return Object.assign({}, state, {
        updateImplantData: { complete: false, error: action.error, busy: false },
      });

    case types.UPDATE_ABUTMENT_DATA:
      return Object.assign({}, state, {
        updateAbutmentData: { complete: false, error: undefined, busy: true },
      });
    case types.UPDATE_ABUTMENT_DATA_SUCCESS:
      return Object.assign({}, state, {
        updateAbutmentData: { complete: true, error: undefined, busy: false },
      });
    case types.UPDATE_ABUTMENT_DATA_ERROR:
      return Object.assign({}, state, {
        updateAbutmentData: { complete: false, error: action.error, busy: false },
      });

    case types.RELEASE_REFLIST:
      return Object.assign({}, state, {
        releaseRefList: { complete: false, error: undefined, busy: true },
      });
    case types.RELEASE_REFLIST_SUCCESS:
      return Object.assign({}, state, {
        releaseRefList: { complete: true, error: undefined, busy: false },
      });
    case types.RELEASE_REFLIST_ERROR:
      return Object.assign({}, state, {
        releaseRefList: { complete: false, error: action.error, busy: false },
      });

    // case types.GET_APP_SETTINGS:
    //   return Object.assign({}, state, {});
    // case types.GET_APP_SETTINGS_SUCCESS:
    //   appSettingsCopy = Object.assign({}, action.appSettings);
    //   appSettingsCopy.loaded = true;
    //   return Object.assign({}, state, { appSettings: appSettingsCopy });
    // case types.GET_APP_SETTINGS_ERROR:
    //   return Object.assign({}, state, {});
    default:
      return state;
  }
}

export function sortImplantAbutmentData(data) {
  return _(data)
    .map((datum) => {
      // NOTE Need to zero fill length since when sorting alphabetically on strings 15 comes before 9, but 09 comes before 15
      return Object.assign({}, datum, {
        zeroFilledLength: Array.from(new Array(10))
          .fill('0')
          .concat(datum.length.split(''))
          .slice(-10)
          .join(''),
      });
    })
    .sortBy(['modelName', 'diameter', 'zeroFilledLength', 'platformName'])
    .value();
}

export function smartPegCompatibleSelector(implantData, smartPegName) {
  const allImplants = implantData.filter((implant) => implant.smartPeg === smartPegName);

  const brands = filterBrands(allImplants);

  let implants = [];
  brands.forEach((brand) => {
    brand.models = filterModels(brand, allImplants);
    brand.models.forEach((model) => {
      model.implants = sortImplantAbutmentData(filterImplants(brand, model, allImplants));
      implants = [...implants, ...model.implants];
    });
  });

  return { brands, implants };
}
export function abutmentCompatibleSelector(abutmentData, smartPegName) {
  const compatibleAbutments = abutmentData.filter((abutment) => {
    return abutment.smartPeg === smartPegName;
  });

  const brands = filterBrands(compatibleAbutments);
  let abutments = [];
  brands.forEach((brand) => {
    brand.models = filterModels(brand, compatibleAbutments);
    brand.models.forEach((model) => {
      model.abutments = sortImplantAbutmentData(filterImplants(brand, model, compatibleAbutments));
      abutments = [...abutments, ...model.abutments];
    });
  });

  return { brands, abutments };
}
const sortbyKey = (prop) => (a, b) => {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
};

const filterBrands = (list) =>
  list
    .map((x) => {
      return x.brandName;
    })
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((x, i) => {
      return { brandName: x, id: i };
    })
    .sort(sortbyKey('brandName'));

const filterModels = (brand, list) =>
  list
    .filter((item) => item.brandName === brand.brandName)
    .filter((v, i, a) => a.findIndex((u) => u.modelName === v.modelName) === i)
    .map((item) => {
      return { modelName: item.modelName };
    })
    .sort(sortbyKey('modelName'));

const filterImplants = (brand, model, list) =>
  list
    .filter((item) => item.brandName === brand.brandName && item.modelName === model.modelName)
    .sort(sortbyKey('partNumber'));
