import * as React from 'react';

function SvgDoneliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1488 2048" {...props}>
      <path d="M745 425q174 0 322 86.5T1301.5 746t86.5 322-86.5 322-234.5 234-322 86-322-86-234.5-234-86.5-322 86.5-322T423 511.5 745 425zm0 1200q113 0 216-44t178-119 119-178 44-216q0-151-74.5-279.5t-203-203.5T745 510t-279.5 75-203 203.5T188 1068q0 113 44 216t119 178 178 119 216 44zm291-722q8-7 19-7t18 7 7 18-7 18l-373 373q-8 8-19 8-10 0-18-8l-246-246q-7-8-7-18t7-18 18-8 19 8l227 228z" />
    </svg>
  );
}

export default SvgDoneliga;
