import { IFormValues } from './formInitialValues';

export const createProfileFormValidator = (values: IFormValues) => {
  const errors: Record<string, string> = {};
  if (!values.firstName) {
    errors.firstName = 'Please enter first name';
  }
  if (!values.lastName) {
    errors.lastName = 'Please enter last name';
  }
  if (!values.email) {
    errors.email = 'Please enter email';
  }
  if (!values.title) {
    errors.title = 'Please select title';
  }
  if (!values.profession) {
    errors.profession = 'Please select profession';
  }
  if (!values.password) {
    errors.password = 'Please enter password';
  }
  if (!values.repeatPassword) {
    errors.repeatPassword = 'Please enter password';
  }
  if (!values.eulaCountry) {
    errors.eulaCountry = 'Please select country';
  }
  if (!values.termsOfServiceAccepted) {
    errors.termsOfServiceAccepted = 'Please read and accept terms of service';
  }
  if (!values.placementsYear) {
    errors.placementsYear = 'Please select placements/year';
  }
  if (!values.yearsOfPlacements) {
    errors.yearsOfPlacements = 'Please select years of placements';
  }
  if (!values.restorationsYear) {
    errors.restorationsYear = 'Please select restorations/year';
  }
  if (!values.yearsOfRestorations) {
    errors.yearsOfRestorations = 'Please select years of restorations';
  }

  return errors;
};
