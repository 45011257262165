import { FC } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import styled from 'styled-components';

interface DataObject {
  name: string;
}

interface Props {
  data: DataObject[];
  dataKeys: string[];
  title: string;
  infotext?: string;
  customWidth?: number;
  customHeight?: number;
}

const LineChartFunc: FC<Props> = ({
  data,
  title,
  dataKeys,
  infotext,
  customWidth,
  customHeight,
}) => {
  const colors = ['#ff6600', ' #ff0000', '#0000FF', '#5fd99b', '#000000'];

  if (data === undefined || data.length === 0) {
    return (
      <StyledLineChart>
        <h2>{title}</h2>
        <h3>No data</h3>
      </StyledLineChart>
    );
  }

  return (
    <StyledLineChart>
      <h2>{title}</h2>
      <LineChart
        width={customWidth ? customWidth : 1200}
        height={customHeight ? customHeight : 400}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {dataKeys.map((dataKey, index) => {
          if (index === 0) {
            return (
              <Line
                type="monotone"
                dataKey={dataKey}
                stroke={colors[index]}
                key={dataKey}
                activeDot={{ r: 8 }}
              />
            );
          }
          return <Line type="monotone" dataKey={dataKey} stroke={colors[index]} key={dataKey} />;
        })}
      </LineChart>
      {infotext && (
        <p>
          <b>{infotext}</b>
        </p>
      )}
    </StyledLineChart>
  );
};

const StyledLineChart = styled.div`
  max-width: 1700px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 10px;
  flex-direction: column;
  margin-bottom: 20px;
`;

export default LineChartFunc;
