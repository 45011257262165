import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

import { InputItem } from '../../components/Shared/Forms';
import * as referenceDataActions from '../../redux/actions/referenceDataActions';

import styles from './InsightFilterBar.module.scss';
//import { parseZone } from 'moment';

class InsightFilterBar extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;

    this.state = {
      filterTime: 'all',
      cutOffDate: null,
      filterProsthetic: 'all',
    };

    this.filteredData = {
      healingTimeProtocol: [],
      healingTimeProcedure: [],
      healingTimeBrand: [],
      healingTimeRisk: [],
      failureProtocol: [],
      failureProcedure: [],
      failureBrand: [],
      failureRisk: [],
      isqPlacement: [],
      isqReady: [],
      distributionProtocol: [],
      distributionRisk: [],
      distributionStage: [],
      distributionBrand: [],
    };

    this.patientRiskLabels = {};
    props.patientRiskFactors.forEach((item) => {
      this.patientRiskLabels[item.key] = this.intl.formatMessage({
        id: ''.concat('referenceData.', item.key),
        defaultMessage: item.value,
      });
    });

    this.loadingProtocols = {};
    props.loadingProtocols.forEach((item) => {
      this.loadingProtocols[item.key] = this.intl.formatMessage({
        id: ''.concat('referenceData.', item.key),
        defaultMessage: item.value,
      });
    });
    this.loadingProtocols['other'] = this.intl.formatMessage({
      id: 'general.notSet',
      defaultMessage: 'Not set',
    });

    this.filterTimeChanged = this.filterTimeChanged.bind(this);
    this.filterProstheticChanged = this.filterProstheticChanged.bind(this);
    this.filterData = this.filterData.bind(this);
    this.filterTimeOptions = [
      {
        key: 'all',
        value: this.intl.formatMessage({
          id: 'general.all',
          defaultMessage: 'All',
        }),
      },
      {
        key: 'lastYear',
        value: this.intl.formatMessage({
          id: 'general.lastYear',
          defaultMessage: 'Last Year',
        }),
      },
      {
        key: 'lastMonth',
        value: this.intl.formatMessage({
          id: 'general.lastMonth',
          defaultMessage: 'Last Month',
        }),
      },
    ];
    this.filterProstheticOptions = [
      {
        key: 'all',
        value: this.intl.formatMessage({
          id: 'general.all',
          defaultMessage: 'All',
        }),
      },
      {
        key: 'single',
        value: this.intl.formatMessage({
          id: 'referenceData.Single',
          defaultMessage: 'Single',
        }),
      },
      {
        key: 'partial',
        value: this.intl.formatMessage({
          id: 'referenceData.Partial',
          defaultMessage: 'Partial',
        }),
      },
      {
        key: 'full',
        value: this.intl.formatMessage({
          id: 'referenceData.FullArch',
          defaultMessage: 'Full Arch',
        }),
      },
    ];
  }

  componentDidMount() {
    this.filterData();
  }

  componentWillUnmount() {}
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.rawData !== this.props.rawData) this.filterData(nextProps.rawData);
  }

  sumArray(array) {
    return array.reduce((sum, item) => sum + item, 0);
  }
  averageArray(array) {
    if (array.length === 0) return 0;
    const sum = this.sumArray(array);
    return Math.round(sum / array.length);
  }

  quantile(arr, q) {
    if (arr.length === 0) return 0;
    const sorted = arr.sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
      return sorted[base];
    }
  }

  includeByDate(date) {
    if (this.state.cutOffDate === null) return true;
    if (this.state.cutOffDate.getTime() < new Date(date).getTime()) return true;
    return false;
  }

  includeByProsthetic(solution) {
    if (this.state.filterProsthetic === 'single' && solution !== 'Single') return false;
    if (this.state.filterProsthetic === 'partial' && solution !== 'Partial') return false;
    if (this.state.filterProsthetic === 'full' && solution !== 'FullArch') return false;
    return true;
  }

  generateHealingTimeProtocolData(implants) {
    const initial = {
      ImmediateLoading: [],
      EarlyLoading: [],
      ConventionalLoading: [],
      other: [],
    };
    const dataArray = implants.reduce((result, implant) => {
      if (implant.failureType === 'EarlyFailure') return result;
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.healingTime) {
          if (implant.loadingProtocol === 'ImmediateLoading') {
            result.ImmediateLoading.push(implant.healingTime);
          } else if (implant.loadingProtocol === 'EarlyLoading') {
            result.EarlyLoading.push(implant.healingTime);
          } else if (implant.loadingProtocol === 'ConventionalLoading') {
            result.ConventionalLoading.push(implant.healingTime);
          } else {
            result.other.push(implant.healingTime);
          }
        }
      }
      return result;
    }, initial);

    let result = [];
    for (var key in dataArray) {
      const item = {
        label: this.loadingProtocols[key],
        value: this.averageArray(dataArray[key]),
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }

    return result;
  }

  generateHealingTimeProcedureData(implants) {
    const initial = {
      mandible: [],
      maxilla: [],
    };
    const dataArray = implants.reduce((result, implant) => {
      if (implant.failureType === 'EarlyFailure') return result;
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.healingTime) {
          if (implant.tooth <= 16) {
            result.maxilla.push(implant.healingTime);
          } else {
            result.mandible.push(implant.healingTime);
          }
        }
      }
      return result;
    }, initial);
    const dataAverage = {
      mandible: this.averageArray(dataArray.mandible),
      maxilla: this.averageArray(dataArray.maxilla),
    };
    return [
      {
        label: 'Mandible',
        value: dataAverage.mandible,
      },
      {
        label: 'Maxilla',
        value: dataAverage.maxilla,
      },
    ];
  }

  generateHealingTimeBrandData(implants) {
    const initial = {};
    const dataArray = implants.reduce((result, implant) => {
      if (implant.failureType === 'EarlyFailure') return result;
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.brandName) {
          if (result[implant.brandName] === undefined)
            result[implant.brandName] = { number: 0, healingTime: [] };
          result[implant.brandName].number += 1;
          if (implant.healingTime) result[implant.brandName].healingTime.push(implant.healingTime);
        }
      }
      return result;
    }, initial);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: key,
        value: this.averageArray(dataArray[key].healingTime),
        sort: dataArray[key].number,
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
          sort: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 3 even if more values are equal to 3th value)
    result.sort((a, b) => b.sort - a.sort);
    return result.slice(0, 3);
  }

  generateHealingTimeRiskData(implants) {
    const initial = {};
    const dataArray = implants.reduce((result, implant) => {
      if (implant.failureType === 'EarlyFailure') return result;
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.riskFactors.length > 0) {
          implant.riskFactors.forEach((item) => {
            if (item === 'norisks') return;
            if (result[item] === undefined) result[item] = { number: 0, healingTime: [] };
            if (implant.healingTime) result[item].healingTime.push(implant.healingTime);
            result[item].number += 1;
          });
        }
      }
      return result;
    }, initial);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: this.patientRiskLabels[key],
        value: this.averageArray(dataArray[key].healingTime),
        sort: dataArray[key].number,
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Bruxist',
          value: 0,
          sort: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 5 even if more values are equal to 3th value)
    result.sort((a, b) => b.sort - a.sort);
    return result.slice(0, 3);
  }

  generateFailureProtocolData(implants) {
    const initial = {
      ImmediateLoading: { failed: 0, total: 0 },
      EarlyLoading: { failed: 0, total: 0 },
      ConventionalLoading: { failed: 0, total: 0 },
      other: { failed: 0, total: 0 },
    };
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.loadingProtocol === 'ImmediateLoading') {
          if (implant.failed) result.ImmediateLoading.failed += 1;
          result.ImmediateLoading.total += 1;
        } else if (implant.loadingProtocol === 'EarlyLoading') {
          if (implant.failed) result.EarlyLoading.failed += 1;
          result.EarlyLoading.total += 1;
        } else if (implant.loadingProtocol === 'ConventionalLoading') {
          if (implant.failed) result.ConventionalLoading.failed += 1;
          result.ConventionalLoading.total += 1;
        } else {
          if (implant.failed) result.other.failed += 1;
          result.other.total += 1;
        }
      }
      return result;
    }, initial);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: this.loadingProtocols[key],
        value: dataArray[key].total
          ? Math.round((dataArray[key].failed * 100) / dataArray[key].total)
          : 0,
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }

    return result;
  }

  generateFailureProcedureData(implants) {
    const initial = {
      mandible: 0,
      maxilla: 0,
    };
    const dataArray = implants.reduce((result, implant) => {
      if (!implant.failed) return result;
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.tooth <= 16) {
          result.maxilla += 1;
        } else {
          result.mandible += 1;
        }
      }
      return result;
    }, initial);
    return [
      {
        label: 'Mandible',
        value: dataArray.mandible,
      },
      {
        label: 'Maxilla',
        value: dataArray.maxilla,
      },
    ];
  }

  generateFailureBrandData(implants) {
    const initial = {};
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.brandName) {
          if (result[implant.brandName] === undefined)
            result[implant.brandName] = { failed: 0, total: 0 };
          if (implant.failed) result[implant.brandName].failed += 1;
          result[implant.brandName].total += 1;
        }
      }
      return result;
    }, initial);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: key,
        value: Math.round((dataArray[key].failed * 100) / dataArray[key].total),
        sort: dataArray[key].total,
      };
      result.push(item);
    }
    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
          sort: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 3 even if more values are equal to 3th value)
    result.sort((a, b) => b.sort - a.sort);
    return result.slice(0, 3);
  }

  generateFailureRiskData(implants) {
    const initial = {};
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.riskFactors.length > 0) {
          implant.riskFactors.forEach((item) => {
            if (item === 'norisks') return;
            if (result[item] === undefined) result[item] = { failed: 0, total: 0 };
            if (implant.failed) result[item].failed += 1;
            result[item].total += 1;
          });
        }
      }
      return result;
    }, initial);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: this.patientRiskLabels[key],
        value: Math.round((dataArray[key].failed * 100) / dataArray[key].total),
        sort: dataArray[key].total,
      };
      result.push(item);
    }
    if (result.length === 0) {
      result = [
        {
          label: 'Bruxist',
          value: 0,
          sort: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 3 even if more values are equal to 5th value)
    result.sort((a, b) => b.sort - a.sort);
    return result.slice(0, 3);
  }

  generateDistributionProtocolData(implants) {
    const initial = {
      ImmediateLoading: 0,
      EarlyLoading: 0,
      ConventionalLoading: 0,
      other: 0,
    };
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.plannedProstheticSolution) {
          if (implant.loadingProtocol === 'ImmediateLoading') {
            result.ImmediateLoading += 1;
          } else if (implant.loadingProtocol === 'EarlyLoading') {
            result.EarlyLoading += 1;
          } else if (implant.loadingProtocol === 'ConventionalLoading') {
            result.ConventionalLoading += 1;
          } else {
            result.other += 1;
          }
        }
      }
      return result;
    }, initial);
    const totalImplants = Object.values(dataArray).reduce((sum, current) => sum + current, 0);

    let result = [];
    for (var key in dataArray) {
      if (dataArray[key] === 0) continue;
      const item = {
        label: this.loadingProtocols[key],
        value: Math.round((100 * dataArray[key]) / totalImplants),
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }
    return result;
  }

  generateDistributionRisklData(patients) {
    const initial = {};
    const dataArray = patients.reduce((result, patient) => {
      //   if (!this.includeByProsthetic(patient.plannedProstheticSolution)) return result;
      if (this.includeByDate(patient.filterDate)) {
        if (patient.riskFactors.length > 0) {
          patient.riskFactors.forEach((item) => {
            if (item === 'norisks') return;
            if (result[item] === undefined) result[item] = 0;
            result[item] += 1;
          });
        }
      }
      return result;
    }, initial);
    const totalImplants = Object.values(dataArray).reduce((sum, current) => sum + current, 0);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: this.patientRiskLabels[key],
        value: Math.round((dataArray[key] * 100) / totalImplants),
        sort: dataArray[key],
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 3 even if more values are equal to 5th value)
    result.sort((a, b) => b.value - a.value);
    return result.slice(0, 3);
  }

  generateDistributionStageData(implants) {
    const initial = {
      one: 0,
      two: 0,
      other: 0,
    };
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (implant.surgicalProtocol === 'OneStage') {
          result.one += 1;
        } else if (implant.surgicalProtocol === 'TwoStage') {
          result.two += 1;
        } else {
          result.other += 1;
        }
      }
      return result;
    }, initial);
    const totalImplants = Object.values(dataArray).reduce((sum, current) => sum + current, 0);
    let dataDistribution = {};
    if (totalImplants === 0) {
      dataDistribution = {
        one: 0,
        two: 0,
        other: 0,
      };
    } else {
      dataDistribution = {
        one: Math.round((100 * dataArray.one) / totalImplants),
        two: Math.round((100 * dataArray.two) / totalImplants),
        other: Math.round((100 * dataArray.other) / totalImplants),
      };
    }
    return [
      {
        label: '1-Stage',
        value: dataDistribution.one,
      },
      {
        label: '2-Stage',
        value: dataDistribution.two,
      },
      {
        label: 'Not set',
        value: dataDistribution.other,
      },
    ];
  }

  generateDistributionBrandData(implants) {
    const initial = {};
    const dataArray = implants.reduce((result, implant) => {
      if (!this.includeByProsthetic(implant.plannedProstheticSolution)) return result;
      if (this.includeByDate(implant.filterDate)) {
        if (result[implant.brandName] === undefined) result[implant.brandName] = 0;
        result[implant.brandName] += 1;
      }
      return result;
    }, initial);
    const totalImplants = Object.values(dataArray).reduce((sum, current) => sum + current, 0);
    let result = [];
    for (var key in dataArray) {
      const item = {
        label: key,
        value: Math.round((dataArray[key] * 100) / totalImplants),
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }
    // sort and get top 3 (will cut to 3 even if more values are equal to 5th value)
    result.sort((a, b) => b.value - a.value);
    return result.slice(0, 3);
  }

  generateIsqData(measurements, placement = true) {
    const initial = {
      ImmediateLoading: [],
      EarlyLoading: [],
      ConventionalLoading: [],
      other: [],
    };
    const dataArray = measurements.reduce((result, measurement) => {
      if (!placement && measurement.failureType === 'EarlyFailure') return result;
      const typeComparison = placement
        ? measurement.type === 'DuringPlacement'
        : measurement.type === 'DuringLoading';
      if (!typeComparison) return result;
      if (!this.includeByProsthetic(measurement.plannedProstheticSolution)) return result;
      if (this.includeByDate(measurement.filterDate)) {
        if (measurement.isq) {
          if (measurement.loadingProtocol === 'ImmediateLoading') {
            result.ImmediateLoading.push(measurement.isq);
          } else if (measurement.loadingProtocol === 'EarlyLoading') {
            result.EarlyLoading.push(measurement.isq);
          } else if (measurement.loadingProtocol === 'ConventionalLoading') {
            result.ConventionalLoading.push(measurement.isq);
          } else {
            result.other.push(measurement.isq);
          }
        }
      }
      return result;
    }, initial);

    const q25 = (arr) => this.quantile(arr, 0.25);
    const q75 = (arr) => this.quantile(arr, 0.75);
    const max = (arr) => this.quantile(arr, 1);
    const min = (arr) => this.quantile(arr, 0);

    let result = [];
    for (var key in dataArray) {
      if (dataArray[key].length === 0) continue;
      const item = {
        label: this.loadingProtocols[key],
        lowPercentile: q25(dataArray[key]),
        highPercentile: q75(dataArray[key]),
        min: min(dataArray[key]),
        max: max(dataArray[key]),
      };
      result.push(item);
    }

    if (result.length === 0) {
      result = [
        {
          label: 'Empty',
          value: 0,
        },
      ];
    }
    return result;
  }

  countFiltered(data) {
    const { implants, measurements, patients } = data;
    let result = {
      implants: { total: implants.length, filtered: 0, percent: 0 },
      measurements: { total: measurements.length, filtered: 0, percent: 0 },
      patients: { total: patients.length, filtered: 0, percent: 0 },
    };
    implants.forEach((item) => {
      if (!this.includeByProsthetic(item.plannedProstheticSolution)) return result;
      if (this.includeByDate(item.filterDate)) result.implants.filtered += 1;
    });
    measurements.forEach((item) => {
      if (!this.includeByProsthetic(item.plannedProstheticSolution)) return result;
      if (this.includeByDate(item.filterDate)) result.measurements.filtered += 1;
    });
    patients.forEach((item) => {
      //if (!this.includeByProsthetic(item.plannedProstheticSolution)) return result;
      if (this.includeByDate(item.filterDate)) result.patients.filtered += 1;
    });
    result.implants.percent =
      result.implants.total === 0
        ? 0
        : Math.round((100 * result.implants.filtered) / result.implants.total);
    result.measurements.percent =
      result.measurements.total === 0
        ? 0
        : Math.round((100 * result.measurements.filtered) / result.measurements.total);
    result.patients.percent =
      result.patients.total === 0
        ? 0
        : Math.round((100 * result.patients.filtered) / result.patients.total);
    return result;
  }

  filterData(inData = null) {
    let data = this.props.rawData;
    if (inData) data = inData;

    if (!data) return;

    const { implants, measurements, patients } = data;

    this.filteredData.healingTimeProtocol = this.generateHealingTimeProtocolData(implants);
    this.filteredData.healingTimeProcedure = this.generateHealingTimeProcedureData(implants);
    this.filteredData.healingTimeBrand = this.generateHealingTimeBrandData(implants);
    this.filteredData.healingTimeRisk = this.generateHealingTimeRiskData(implants);
    this.filteredData.failureProtocol = this.generateFailureProtocolData(implants);
    this.filteredData.failureProcedure = this.generateFailureProcedureData(implants);
    this.filteredData.failureBrand = this.generateFailureBrandData(implants);
    this.filteredData.failureRisk = this.generateFailureRiskData(implants);
    this.filteredData.isqPlacement = this.generateIsqData(measurements);
    this.filteredData.isqReady = this.generateIsqData(measurements, false);
    this.filteredData.distributionProtocol = this.generateDistributionProtocolData(implants);
    this.filteredData.distributionRisk = this.generateDistributionRisklData(patients);
    this.filteredData.distributionStage = this.generateDistributionStageData(implants);
    this.filteredData.distributionBrand = this.generateDistributionBrandData(implants);

    this.props.onChange({
      ...this.state,
      filteredData: this.filteredData,
      filterStats: this.countFiltered(data),
    });
  }

  filterTimeChanged(event) {
    const value = event.target.value;
    const now = new Date();

    let cutOffDate = null;
    if (value === 'lastYear') cutOffDate = new Date(now.getTime() - 31536000000);
    else if (value === 'lastMonth') cutOffDate = new Date(now.getTime() - 2678400000);

    this.setState(
      {
        filterTime: value,
        cutOffDate,
      },
      () => this.filterData()
    );
  }
  filterProstheticChanged(event) {
    const value = event.target.value;
    this.setState(
      {
        filterProsthetic: value,
      },
      () => this.filterData()
    );
  }

  render() {
    return (
      <div className={styles.filterBar}>
        <div className={styles.filterGroup}>
          <InputItem
            type="selectbutton"
            label={this.intl.formatMessage({
              id: 'clinic.timeInterval',
              defaultMessage: 'Time Interval',
            })}
            options={this.filterTimeOptions}
            onChange={this.filterTimeChanged}
            value={this.state.filterTime}
            onWhite
            span
            centered
          />
        </div>
        <div className={styles.filterGroup}>
          <InputItem
            type="selectbutton"
            label={this.intl.formatMessage({
              id: 'clinic.prostheticSolution',
              defaultMessage: 'Prosthetic solutions',
            })}
            options={this.filterProstheticOptions}
            onChange={this.filterProstheticChanged}
            value={this.state.filterProsthetic}
            onWhite
            span
            centered
          />
        </div>
      </div>
    );
  }
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...referenceDataActions }, dispatch),
  };
}

function mapState(state) {
  return {
    patientRiskFactors: state.referenceData.patientRiskFactors,
    loadingProtocols: state.referenceData.loadingProtocols,
  };
}

export default connect(mapState, mapDispatch)(injectIntl(InsightFilterBar));
