import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import {
  LineChart,
  Line,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
  Legend,
} from 'recharts';

import styles from './ImplantChart.module.scss';

import ChartDot from './ChartDot';
import ChartTooltip from './ChartTooltip';

class ImplantChart extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.animationDuration = 800;
    this.animationEasing = 'ease';
  }

  //TODO Fix update when locale changes
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.excludeAbutment !== nextProps.excludeAbutment) return true;
    if (this.props.showDetails !== nextProps.showDetails) return true;

    if (!this.props.measurements && nextProps.measurements) return true;

    if (
      this.props.measurements &&
      nextProps.measurements &&
      this.props.measurements.length !== nextProps.measurements.length
    )
      return true;

    if (
      this.props.measurements &&
      this.props.measurements.length > 0 &&
      nextProps.measurements &&
      nextProps.measurements.length > 0
    ) {
      let shouldUpdate = false;
      this.props.measurements.forEach(function (measurement, index) {
        if (measurement.date !== nextProps.measurements[index].date) shouldUpdate = true;
        if (measurement.isqBl !== nextProps.measurements[index].isqBl) shouldUpdate = true;
        if (measurement.isqMd !== nextProps.measurements[index].isqMd) shouldUpdate = true;
        if (measurement.isq !== nextProps.measurements[index].isq) shouldUpdate = true;
      }, this);

      if (shouldUpdate) return true;
    }

    if (this.props.noColor !== nextProps.noColor) return true;

    return false;
  }

  getChartDataFromMeasurements(measurements, abutment = false) {
    const { formatTime } = this.props.intl;
    let type = 'Implant';
    if (abutment) type = 'Abutment';
    const implantMeasurements = measurements.filter((item) => item.level === type);
    return implantMeasurements.map((measurement) => {
      return {
        id: measurement.id,
        date: new Date(measurement.date).getTime(),
        dateString: formatTime(measurement.date, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
        BL: measurement.isqBl ? measurement.isqBl : '',
        MD: measurement.isqMd ? measurement.isqMd : '',
        lowest: measurement.isq,
        manual: measurement.manual
          ? this.intl.formatMessage({ id: 'implant.manualISQ', defaultMessage: 'Manual ISQ' })
          : null,
        abutment: measurement.level === 'Abutment',
      };
    });
  }

  dotClicked(measurement) {
    if (this.props.showCreateMeasurementModal)
      this.props.showCreateMeasurementModal(this.props.tooth, true, measurement);
  }

  render() {
    let data;
    let dataAbutments;

    if (this.props.measurements && this.props.measurements.length > 0) {
      data = this.getChartDataFromMeasurements(this.props.measurements);
      dataAbutments = this.getChartDataFromMeasurements(this.props.measurements, true);

      if (this.props.projection) {
        data[data.length - 1].projection = data[data.length - 1].lowest;
        data.push(this.props.projection);
      }
    }

    return (
      <div className={styles.chartWrapper}>
        <ResponsiveContainer height={237} width="100%">
          <LineChart margin={{ top: 26, right: 0, left: 0, bottom: 26 }}>
            <CartesianGrid stroke="white" vertical={false} />
            <YAxis
              stroke="none"
              tick={{ fill: styles.purple }}
              axisLine={{ stroke: 'none' }}
              type="number"
              dataKey="lowest"
              domain={[30, 90]}
              interval={0}
              ticks={[30, 40, 50, 60, 70, 80, 90]}
              width={32}
            />

            <XAxis type="number" dataKey="date" domain={['dataMin', 'auto']} hide />
            <Tooltip coordinate={{ x: 100, y: 140 }} content={<ChartTooltip />} />
            {!this.props.noColor && (
              <ReferenceLine y={70} stroke={styles.ISQhigh} strokeWidth={1} />
            )}
            {!this.props.noColor && (
              <ReferenceLine y={60} stroke={styles.ISQmedium} strokeWidth={1} />
            )}

            {/*<Line type="linear" dataKey="projection" stroke={styles.vividpurple} Dot={false} strokeWidth={2} dot={<ChartDot projection />} strokeDasharray="2 3" />*/}
            {this.props.showDetails && (
              <Line
                data={data}
                type="linear"
                dataKey="BL"
                stroke={styles.lineColorBL}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
              />
            )}
            {this.props.showDetails && (
              <Line
                data={data}
                type="linear"
                dataKey="MD"
                stroke={styles.lineColorMD}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
              />
            )}
            {!this.props.excludeAbutment && this.props.showDetails && (
              <Line
                name={
                  this.intl.formatMessage({ id: 'implant.abutment', defaultMessage: 'Abutment' }) +
                  ' BL'
                }
                data={dataAbutments}
                type="linear"
                dataKey="BL"
                stroke={styles.ISQneutral}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
                strokeDasharray="5 5"
              />
            )}
            {!this.props.excludeAbutment && this.props.showDetails && (
              <Line
                name={
                  this.intl.formatMessage({ id: 'implant.abutment', defaultMessage: 'Abutment' }) +
                  ' MD'
                }
                data={dataAbutments}
                type="linear"
                dataKey="MD"
                stroke={styles.ISQneutralMuted}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
                strokeDasharray="5 5"
              />
            )}
            {!this.props.showDetails && (
              <Line
                name={this.intl.formatMessage({ id: 'implant.implant', defaultMessage: 'Implant' })}
                data={data}
                type="linear"
                dataKey="lowest"
                stroke={styles.lineColor}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
              />
            )}
            {!this.props.excludeAbutment && !this.props.showDetails && (
              <Line
                name={this.intl.formatMessage({
                  id: 'implant.abutment',
                  defaultMessage: 'Abutment',
                })}
                data={dataAbutments}
                type="linear"
                dataKey="lowest"
                stroke={styles.ISQneutral}
                activeDot={false}
                strokeWidth={1.5}
                dot={
                  <ChartDot dotClicked={this.dotClicked.bind(this)} noColor={this.props.noColor} />
                }
                animationDuration={this.animationDuration}
                animationEasing={this.animationEasing}
                strokeDasharray="5 5"
              />
            )}
            {(this.props.showDetails || !this.props.excludeAbutment) && (
              <Legend height={16} wrapperStyle={{ bottom: 12 }} iconType="plainline" />
            )}
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default injectIntl(ImplantChart);
