import { defineMessage } from 'react-intl';

export type Depth = 0 | 1 | 2 | 3;

export interface Bone {
  depth: Depth;
  graft: boolean;
  sinusGraft: Depth;
  peak: Array<boolean | undefined>;
  softTissueDepth: Depth;
  softTissueGraft: boolean;
}

export interface Position {
  x: number;
  y: number;
}

export interface TextField {
  id: string;
  position: Position;
  text: string;
  color?: ColorType;
}

export interface DrawShape {
  id: string;
  position: Position;
  color: ColorType;
  nodes: Array<Position>;
}

export interface Nodes {
  nodes: Array<Position>;
}

export interface ViewSettings {
  darkMode: boolean;
  gumsTransparencyMode: boolean;
  smileMode: boolean;
  sinusMode: boolean;
  nerveNodes: boolean;
  toothNumbers: boolean;
  skintone: number;
}

export interface ConsultationWorkspaceState {
  teethState: boolean[];
  teethPreparation: boolean[];
  teethOverdenture: [boolean, boolean][];
  implant: boolean[];
  implantType: Array<'TYPE1' | 'TYPE2'>;
  implantNode: Position[];
  stability: boolean[];
  abutment: boolean[];
  abutmentType: Array<'FIXED' | 'OVERDENTURE'>;
  boneState: Bone[];
  nerveState: [Nodes, Nodes];
  sinusState: [Nodes, Nodes, Nodes];
  smileState: [Nodes];
  textFields?: Array<TextField>;
  drawShapes: Array<DrawShape>;
  view: ViewSettings;
  image?: string;
}

export type NodeArea =
  | 'SINUS_LEFT'
  | 'SINUS_RIGHT'
  | 'NERVES_LEFT'
  | 'NERVES_RIGHT'
  | 'NOSE'
  | 'SMILE';

export enum ToolIcons {
  TEETH_SINGLE = 'TEETH_SINGLE',
  TEETH_ALL = 'TEETH_ALL',
  BONE = 'BONE',
  BONE_LOSS_LOCAL = 'BONE_LOSS_LOCAL',
  BONE_LOSS_ALL = 'BONE_LOSS_ALL',
  BONE_GRAFT = 'BONE_GRAFT',
  BONE_PEAK = 'BONE_PEAK',
  BONE_SINUS_GRAFT = 'BONE_SINUS_GRAFT',
  SOFT_TISSUE = 'SOFT_TISSUE',
  DESCRIPTION = 'DESCRIPTION',
  IMPLANT = 'IMPLANT',
  IMPLANT_TRANSFORM = 'IMPLANT_TRANSFORM',
  STABILITY = 'STABILITY',
  ABUTMENT = 'ABUTMENT',
  PREPARATION = 'PREPARATION',
  RESTORATION = 'RESTORATION',
  RESTORATION_SINGLE = 'RESTORATION_SINGLE',
  RESTORATION_BRIDGE = 'RESTORATION_BRIDGE',
  RESTORATION_OVERDENTURE = 'RESTORATION_OVERDENTURE',
  TEXT = 'TEXT',
  DRAW = 'DRAW',
  DRAW_FREEHAND = 'DRAW_FREEHAND',
  DRAW_LINE = 'DRAW_LINE',
  DRAW_ERASE = 'DRAW_ERASE',
  DRAW_CLEAR = 'DRAW_CLEAR',
  DELETE_STEP = 'DELETE_STEP',
}

export interface ConsultationTool {
  id: number;
  name: string;
  label: { id: string; defaultMessage: string };
  toolTip?: { id: string; defaultMessage: string };
  icon: ToolIcons;
  subTools?: Array<ConsultationSubTool>;
}

export interface ConsultationSubTool {
  id: number;
  name: string;
  label: { id: string; defaultMessage: string };
  toolTip?: { id: string; defaultMessage: string };
  icon: ToolIcons;
}

const allTrue = new Array<boolean>(32).fill(true);
const allFalse = new Array<boolean>(32).fill(false);
export const topImplantNode = { x: 0, y: -64 };
export const bottomImplantNode = { x: 0, y: 64 };
const topImplantNodes = new Array<Position>(16).fill(topImplantNode);
const bottomImplantNodes = new Array<Position>(16).fill(bottomImplantNode);

export const Color: Record<string, string> = {
  Green: '#00B238',
  LightBlue: '#01A8FB',
  Blue: '#394BBE',
  Purple: '#AB07B7',
  Yellow: '#FFBF2C',
  Red: '#FF421C',
  Grey: '#9D9D9D',
  Black: '#000000',
};
export type ColorType = keyof typeof Color;

export const initialWorkspaceState: ConsultationWorkspaceState = {
  teethState: allTrue,
  teethPreparation: allFalse,
  teethOverdenture: new Array<[boolean, boolean]>(32).fill([false, false]),
  implant: allFalse,
  implantType: new Array<'TYPE1' | 'TYPE2'>(32).fill('TYPE1'),
  implantNode: [...topImplantNodes, ...bottomImplantNodes],
  stability: allFalse,
  abutment: allFalse,
  abutmentType: new Array<'FIXED' | 'OVERDENTURE'>(32).fill('FIXED'),
  boneState: new Array<Bone>(32).fill({
    depth: 0,
    graft: false,
    sinusGraft: 0,
    peak: [false, false],
    softTissueDepth: 0,
    softTissueGraft: false,
  }),
  nerveState: [
    {
      nodes: [
        {
          x: 0,
          y: 54,
        },
        {
          x: 50,
          y: 70,
        },
        {
          x: 175,
          y: 94,
        },
        {
          x: 256,
          y: 96,
        },
      ],
    },
    {
      nodes: [
        {
          x: 0,
          y: 54,
        },
        {
          x: 50,
          y: 70,
        },
        {
          x: 175,
          y: 94,
        },
        {
          x: 256,
          y: 96,
        },
      ],
    },
  ],
  sinusState: [
    {
      nodes: [
        {
          x: 0,
          y: 16,
        },
        {
          x: 140,
          y: 48,
        },
        {
          x: 280,
          y: 32,
        },
        {
          x: 270,
          y: 80,
        },
        {
          x: 200,
          y: 100,
        },
        {
          x: 100,
          y: 120,
        },
        {
          x: 0,
          y: 100,
        },
      ],
    },
    {
      nodes: [
        {
          x: 340,
          y: 16,
        },
        {
          x: 210,
          y: 48,
        },
        {
          x: 72,
          y: 32,
        },
        {
          x: 90,
          y: 80,
        },
        {
          x: 180,
          y: 100,
        },
        {
          x: 280,
          y: 120,
        },
        {
          x: 350,
          y: 100,
        },
      ],
    },
    {
      nodes: [
        {
          x: 50,
          y: 20,
        },
        {
          x: 50,
          y: 20,
        },
      ],
    },
  ],
  smileState: [
    {
      nodes: [
        { x: 600, y: 140 },
        { x: 400, y: 90 },
      ],
    },
  ],
  textFields: [],
  drawShapes: [],
  view: {
    darkMode: false,
    gumsTransparencyMode: true,
    smileMode: false,
    sinusMode: true,
    nerveNodes: true,
    toothNumbers: true,
    skintone: 0,
  },
};

export const workspaceToolsState: ConsultationTool[] = [
  {
    id: 0,
    name: 'Teeth',
    label: defineMessage({
      id: 'consultation.tool.teeth',
      defaultMessage: 'Teeth',
    }),
    icon: ToolIcons.TEETH_SINGLE,
    subTools: [
      {
        id: 0,
        name: 'Single',
        label: defineMessage({
          id: 'consultation.tool.planning.single',
          defaultMessage: 'Single',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.planning.single.toolTip',
          defaultMessage: 'Click on a tooth position to remove/restore the tooth',
        }),
        icon: ToolIcons.TEETH_SINGLE,
      },
      {
        id: 1,
        name: 'All',
        label: defineMessage({
          id: 'consultation.tool.planning.all',
          defaultMessage: 'All',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.planning.all.toolTip',
          defaultMessage: 'Click on a tooth position to remove/restore all teeth in the jaw',
        }),
        icon: ToolIcons.TEETH_ALL,
      },
    ],
  },
  {
    id: 1,
    name: 'Bone',
    label: defineMessage({
      id: 'consultation.tool.bone',
      defaultMessage: 'Bone',
    }),
    icon: ToolIcons.BONE,
    subTools: [
      {
        id: 0,
        name: 'Loss Local',
        label: defineMessage({
          id: 'consultation.tool.bone.lossLocal',
          defaultMessage: 'Loss Local',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.bone.lossLocal.toolTip',
          defaultMessage: 'Click on a tooth position to remove bone locally',
        }),
        icon: ToolIcons.BONE_LOSS_LOCAL,
      },
      {
        id: 1,
        name: 'Loss All',
        label: defineMessage({
          id: 'consultation.tool.bone.lossAll',
          defaultMessage: 'Loss All',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.bone.lossAll.toolTip',
          defaultMessage: 'Click on a tooth position to remove all bone in the jaw',
        }),
        icon: ToolIcons.BONE_LOSS_ALL,
      },
      {
        id: 2,
        name: 'Graft',
        label: defineMessage({
          id: 'consultation.tool.bone.graft',
          defaultMessage: 'Graft',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.bone.graft.toolTip',
          defaultMessage: 'Click on a positon with bone loss to fill with bone graft',
        }),
        icon: ToolIcons.BONE_GRAFT,
      },
      {
        id: 3,
        name: 'Sinus Graft',
        label: defineMessage({
          id: 'consultation.tool.bone.sinusGraft',
          defaultMessage: 'Sinus Graft',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.bone.sinusGraft.toolTip',
          defaultMessage:
            'Click on a tooth position to create sinus grafting in the sinus area above',
        }),
        icon: ToolIcons.BONE_SINUS_GRAFT,
      },
    ],
  },
  {
    id: 2,
    name: 'SoftTissue',
    label: defineMessage({
      id: 'consultation.tool.softTissue',
      defaultMessage: 'Soft Tissue',
    }),
    icon: ToolIcons.SOFT_TISSUE,
    subTools: [
      {
        id: 0,
        name: 'Loss Local',
        label: defineMessage({
          id: 'consultation.tool.softTissue.lossLocal',
          defaultMessage: 'Loss Local',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.softTissue.lossLocal.toolTip',
          defaultMessage: 'Click on a tooth position to remove soft tissue locally',
        }),
        icon: ToolIcons.BONE_LOSS_LOCAL,
      },
      {
        id: 1,
        name: 'Loss All',
        label: defineMessage({
          id: 'consultation.tool.softTissue.lossAll',
          defaultMessage: 'Loss All',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.softTissue.lossAll.toolTip',
          defaultMessage: 'Click on a tooth position to remove soft tissue in the jaw',
        }),
        icon: ToolIcons.BONE_LOSS_ALL,
      },
      {
        id: 2,
        name: 'Graft',
        label: defineMessage({
          id: 'consultation.tool.softTissue.graft',
          defaultMessage: 'Graft',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.softTissue.graft.toolTip',
          defaultMessage: 'Click on a positon with soft tissue loss to fill with soft tissue graft',
        }),
        icon: ToolIcons.BONE_GRAFT,
      },
      {
        id: 3,
        name: 'Peak',
        label: defineMessage({
          id: 'consultation.tool.softTissue.peak',
          defaultMessage: 'Peak',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.softTissue.peak.toolTip',
          defaultMessage: 'Click between two positions with bone losses to format the peak between',
        }),
        icon: ToolIcons.BONE_PEAK,
      },
    ],
  },
  {
    id: 3,
    name: 'Implant',
    label: defineMessage({
      id: 'consultation.tool.implant',
      defaultMessage: 'Implant',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.implant.toolTip',
      defaultMessage: 'Click on a tooth position to place/remove an implant',
    }),
    icon: ToolIcons.IMPLANT,
    subTools: [
      {
        id: 0,
        name: 'Type1',
        label: defineMessage({
          id: 'consultation.tool.implant.type1',
          defaultMessage: 'Type 1',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.implant.place.tooltip',
          defaultMessage: 'Click on a tooth position to place/remove an implant',
        }),
        icon: ToolIcons.IMPLANT,
      },
      {
        id: 1,
        name: 'Type2',
        label: defineMessage({
          id: 'consultation.tool.implant.type2',
          defaultMessage: 'Type 2',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.implant.place.tooltip',
          defaultMessage: 'Click on a tooth position to place/remove an implant',
        }),
        icon: ToolIcons.IMPLANT,
      },
      {
        id: 2,
        name: 'Transform',
        label: defineMessage({
          id: 'consultation.tool.implant.modify',
          defaultMessage: 'Modify',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.implant.transform.tooltip',
          defaultMessage: 'Drag the node of a placed implant to modify length and angulation',
        }),
        icon: ToolIcons.IMPLANT_TRANSFORM,
      },
    ],
  },
  {
    id: 4,
    name: 'Stability',
    label: defineMessage({
      id: 'consultation.tool.stability',
      defaultMessage: 'Stability',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.stability.toolTip',
      defaultMessage: 'Click on an implant or abutment to place/remove a SmartPeg',
    }),
    icon: ToolIcons.STABILITY,
  },
  {
    id: 5,
    name: 'Abutment',
    label: defineMessage({
      id: 'consultation.tool.abutment',
      defaultMessage: 'Abutment',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.abutment.toolTip',
      defaultMessage: 'Click on an implant to add/remove an abutment',
    }),
    subTools: [
      {
        id: 0,
        name: 'Fixed',
        label: defineMessage({
          id: 'consultation.tool.abutment.fixed',
          defaultMessage: 'Fixed',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.abutment.fixed.toolTip',
          defaultMessage: 'Click on an implant to add/remove an abutment (fixed)',
        }),
        icon: ToolIcons.ABUTMENT,
      },
      {
        id: 1,
        name: 'Overdenture',
        label: defineMessage({
          id: 'consultation.tool.abutment.overdenture',
          defaultMessage: 'Overdenture',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.abutment.overdenture.toolTip',
          defaultMessage: 'Click on an implant to add/remove an abutment (overdenture)',
        }),
        icon: ToolIcons.ABUTMENT,
      },
    ],
    icon: ToolIcons.ABUTMENT,
  },
  {
    id: 6,
    name: 'Preparation',
    label: defineMessage({
      id: 'consultation.tool.preparation',
      defaultMessage: 'Preparation',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.preparation.toolTip',
      defaultMessage: 'Click on a tooth to prepare it for placing a crown',
    }),
    icon: ToolIcons.PREPARATION,
  },
  {
    id: 7,
    name: 'Restoration',
    label: defineMessage({
      id: 'consultation.tool.restoration',
      defaultMessage: 'Restoration',
    }),
    icon: ToolIcons.RESTORATION,
    subTools: [
      {
        id: 0,
        name: 'Single',
        label: defineMessage({
          id: 'consultation.tool.restoration.crown',
          defaultMessage: 'Crown',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.restoration.crown.toolTip',
          defaultMessage: 'Click on a tooth position to place/remove a crown',
        }),
        icon: ToolIcons.RESTORATION_SINGLE,
      },
      {
        id: 1,
        name: 'Bridge',
        label: defineMessage({
          id: 'consultation.tool.restoration.bridge',
          defaultMessage: 'Bridge',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.restoration.bridge.toolTip',
          defaultMessage: 'Click on a tooth position to place/remove a bridge',
        }),
        icon: ToolIcons.RESTORATION_BRIDGE,
      },
      {
        id: 2,
        name: 'Overdenture',
        label: defineMessage({
          id: 'consultation.tool.restoration.overdenture',
          defaultMessage: 'Overdenture',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.restoration.overdenture.toolTip',
          defaultMessage: 'Click on a tooth position to place/remove an overdenture',
        }),
        icon: ToolIcons.RESTORATION_OVERDENTURE,
      },
    ],
  },
  {
    id: 8,
    name: 'Draw',
    label: defineMessage({
      id: 'consultation.tool.draw',
      defaultMessage: 'Draw',
    }),
    icon: ToolIcons.DRAW,
    subTools: [
      {
        id: 0,
        name: 'Freehand',
        label: defineMessage({
          id: 'consultation.tool.draw.freehand',
          defaultMessage: 'Freehand',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.draw.freehand.toolTip',
          defaultMessage: 'Select color, click and drag to draw',
        }),
        icon: ToolIcons.DRAW_FREEHAND,
      },
      {
        id: 1,
        name: 'Lines',
        label: defineMessage({
          id: 'consultation.tool.draw.lines',
          defaultMessage: 'Lines',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.draw.lines.toolTip',
          defaultMessage: 'Select color, click and drag to draw a line',
        }),
        icon: ToolIcons.DRAW_LINE,
      },
      {
        id: 2,
        name: 'Erase',
        label: defineMessage({
          id: 'consultation.tool.draw.eraser',
          defaultMessage: 'Erase',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.draw.eraser.toolTip',
          defaultMessage: 'Click and drag a line across the item to erase',
        }),
        icon: ToolIcons.DRAW_ERASE,
      },
      {
        id: 3,
        name: 'Clear',
        label: defineMessage({
          id: 'consultation.tool.draw.clear',
          defaultMessage: 'Clear',
        }),
        toolTip: defineMessage({
          id: 'consultation.tool.draw.clear.toolTip',
          defaultMessage: 'Click to clear all drawings',
        }),
        icon: ToolIcons.DRAW_CLEAR,
      },
    ],
  },
  {
    id: 9,
    name: 'Text',
    label: defineMessage({
      id: 'consultation.tool.textfield',
      defaultMessage: 'Text',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.textfield.toolTip',
      defaultMessage:
        'Click to create or move a text box. Remove the text box by deleting the content',
    }),
    icon: ToolIcons.TEXT,
  },
  {
    id: 10,
    name: 'Description',
    label: defineMessage({
      id: 'consultation.tool.description',
      defaultMessage: 'Description',
    }),
    toolTip: defineMessage({
      id: 'consultation.tool.description.toolTip',
      defaultMessage: 'Describe important information for this step',
    }),
    icon: ToolIcons.DESCRIPTION,
  },
];
