import * as React from 'react';

function SvgGumsliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1478 2048" {...props}>
      <path d="M1309 855q24 18 39 47t20.5 74 7 76 1.5 89v181q2 125-83 216t-206 93H392q-122-2-207-93.5T102 1321v-180q0-58 1.5-89t7-76 20.5-74 39-47q32-25 83-22-22-200-22-299-8-86 49-154 58-69 145-77 19-1 37 0 54 0 127 25 75 27 144 25 61-2 115-31 23-12 37-18t44-12 68-6h1q98 0 168.5 70t70.5 168v11q0 98-22 298 57-6 94 22zm-347 600q36 0 69-49.5t58.5-134 44.5-180 33-204.5q-209 63-428 57-219 7-438-60 14 109 33 205t44.5 181 58.5 135 69 50q28 0 47.5-15.5t32.5-49 20.5-68.5 15.5-92q5-36 8.5-54t9.5-47.5 13.5-44.5 18.5-32 26.5-24 35.5-7 35 7 26 24 18.5 31.5 14 45 9 47.5 8.5 54q8 57 15.5 92t20.5 68.5 33 49 48 15.5zM273 533q0 114 22 306 8 2 13 3 212 67 431 59 225 6 431-59h1l1-1q22-192 22-306v-11q0-81-57.5-138.5T998 328h-1q-33 0-59 5.5T900 344t-32 16q-60 33-134 35-78 2-159-27-65-23-115-23-11-1-31 1-72 6-117 61t-39 126z" />
    </svg>
  );
}

export default SvgGumsliga;
