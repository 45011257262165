import React, { Component } from 'react';
import { FormattedDate } from 'react-intl';

import styles from './ItemRow.module.scss';

import { TR, TD } from '../../../components/Shared/Table';

class ItemRow extends Component {
  constructor(props) {
    super(props);

    let emptyItem = {
      brandName: '',
      modelName: '',
      platformName: '',
      diameter: '',
      length: '',
      angle: '',
      partNumber: '',
      udi: '',
      smartPeg: 'Type 01',
      info: '',
      comment: '',
      status: -1,
    };

    this.state = {
      item: this.props.item ? Object.assign({}, this.props.item) : emptyItem,
      edit: false,
      numberToAdd: 1,
    };

    this.saving = false;

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onRevert = this.onRevert.bind(this);
    this.onAddNew = this.onAddNew.bind(this);

    this.statusOptions = [
      { key: -1, value: 'To Ref List' },
      { key: 0, value: 'On Ref List' },
      { key: 1, value: 'Obsolete' },
      { key: 2, value: 'Pending' },
      { key: 3, value: 'In Study' },
      { key: -2, value: 'To be removed' },
    ];
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item && (!this.state.edit || this.saving)) {
      this.setState({
        item: Object.assign({}, nextProps.item),
        edit: false,
      });
      this.saving = false;
    }
  }

  onEdit() {
    this.setState({
      edit: true,
    });
  }

  onDelete() {
    this.props.onDeleted();
  }

  onSave() {
    this.saving = true;
    this.props.onChanged(this.state.item);
    this.onCancel();
  }

  onCancel() {
    this.setState({
      item: Object.assign({}, this.props.item),
      edit: false,
    });
  }

  onRevert() {
    this.props.onRevert();
  }

  onTextChange(key, evt) {
    var newItem = Object.assign({}, this.state.item);
    var value = undefined;
    if (key === 'brandName') value = evt.target.value.toUpperCase();
    else if (key === 'status') value = Number(evt.target.value);
    else value = evt.target.value;

    newItem[key] = value;
    this.setState({
      item: newItem,
    });
  }

  renderSmartPegs() {
    let options = this.props.smartPegs.map((smartPeg) => (
      <option key={smartPeg.type} value={smartPeg.type}>
        {smartPeg.type}
      </option>
    ));
    options.push(
      <option key="Type XX" value="Type XX">
        Type XX
      </option>
    );
    return (
      <select onChange={this.onTextChange.bind(this, 'smartPeg')} value={this.state.item.smartPeg}>
        {options}
      </select>
    );
  }

  renderStatus() {
    const options = this.statusOptions.map((status) => (
      <option key={status.key} value={status.key}>
        {status.value}
      </option>
    ));
    return (
      <select onChange={this.onTextChange.bind(this, 'status')} value={this.state.item.status}>
        {options}
      </select>
    );
  }

  onAddNew() {
    let item = this.state.item;
    for (var key in item) {
      if (typeof item[key] === 'string') item[key] = item[key].trim();
    }
    let items = [];
    for (var i = 0; i < this.state.numberToAdd; i++) {
      items.push(Object.assign({}, this.state.item));
    }

    this.props.onAddNew(items);
  }

  numberToAddChanged(evt) {
    this.setState({
      numberToAdd: evt.target.value,
    });
  }

  renderNewRow() {
    return (
      <TR className={styles.edit}>
        <TD></TD>
        <TD>
          <div>Brand</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'brandName')}
            value={this.state.item.brandName}
          ></input>
        </TD>
        <TD>
          <div>Model</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'modelName')}
            value={this.state.item.modelName}
          ></input>
        </TD>
        <TD>
          <div>Platform</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'platformName')}
            value={this.state.item.platformName}
          ></input>
        </TD>
        <TD>
          <div>Diameter</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'diameter')}
            value={this.state.item.diameter}
          ></input>
        </TD>
        <TD>
          <div>Length</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'length')}
            value={this.state.item.length}
          ></input>
        </TD>
        {this.props.abutment ? (
          <TD>
            <div>Angle</div>
            <input
              type="text"
              onChange={this.onTextChange.bind(this, 'angle')}
              value={this.state.item.angle}
            ></input>
          </TD>
        ) : null}
        <TD>
          <div>PartNumber</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'partNumber')}
            value={this.state.item.partNumber}
          ></input>
        </TD>
        <TD>
          <div>UDI</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'udi')}
            value={this.state.item.udi}
          ></input>
        </TD>
        <TD>
          <div>SmartPeg</div>
          {this.renderSmartPegs()}
        </TD>
        <TD>
          <div>Info</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'info')}
            value={this.state.item.info}
          ></input>
        </TD>
        <TD>
          <div>Comment</div>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'comment')}
            value={this.state.item.comment}
          ></input>
        </TD>
        <TD></TD>
        <TD>
          <div>Status</div>
          {this.renderStatus()}
        </TD>
        <TD></TD>
        <TD>
          <input
            type="text"
            onChange={this.numberToAddChanged.bind(this)}
            value={this.state.numberToAdd}
          ></input>
          <span className={styles.button} onClick={this.onAddNew}>
            Add
          </span>
        </TD>
      </TR>
    );
  }

  renderEdit() {
    return (
      <TR className={styles.edit}>
        <TD>{this.state.item.dataId}</TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'brandName')}
            value={this.state.item.brandName}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'modelName')}
            value={this.state.item.modelName}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'platformName')}
            value={this.state.item.platformName}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'diameter')}
            value={this.state.item.diameter}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'length')}
            value={this.state.item.length}
          ></input>
        </TD>
        {this.props.abutment ? (
          <TD>
            <input
              type="text"
              onChange={this.onTextChange.bind(this, 'angle')}
              value={this.state.item.angle}
            ></input>
          </TD>
        ) : null}
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'partNumber')}
            value={this.state.item.partNumber}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'udi')}
            value={this.state.item.udi}
          ></input>
        </TD>
        <TD>{this.renderSmartPegs()}</TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'info')}
            value={this.state.item.info}
          ></input>
        </TD>
        <TD>
          <input
            type="text"
            onChange={this.onTextChange.bind(this, 'comment')}
            value={this.state.item.comment}
          ></input>
        </TD>
        <TD></TD>
        <TD>{this.renderStatus()}</TD>
        <TD>{this.state.item.modified}</TD>
        <TD>
          <span className={styles.button} onClick={this.onSave}>
            Save
          </span>{' '}
          <span className={styles.button} onClick={this.onCancel}>
            Cancel
          </span>
        </TD>
      </TR>
    );
  }

  render() {
    if (this.state.edit) {
      return this.renderEdit();
    } else if (this.props.new) {
      return this.renderNewRow();
    }
    const status = this.statusOptions.find((x) => x.key === this.state.item.status);
    const modifiedItem =
      this.state.item.modified && this.state.item.modified === 'changed' ? true : false;
    return (
      <TR>
        <TD>{this.state.item.dataId}</TD>
        <TD>{this.state.item.brandName}</TD>
        <TD>{this.state.item.modelName}</TD>
        <TD>{this.state.item.platformName}</TD>
        <TD>{this.state.item.diameter}</TD>
        <TD>{this.state.item.length}</TD>
        {this.props.abutment ? <TD>{this.state.item.angle}</TD> : null}
        <TD>{this.state.item.partNumber}</TD>
        <TD>{this.state.item.udi}</TD>
        <TD>{this.state.item.smartPeg}</TD>
        <TD>{this.state.item.info}</TD>
        <TD>{this.state.item.comment}</TD>
        <TD>
          {this.state.item.date ? (
            <FormattedDate
              value={this.state.item.date}
              year="numeric"
              month="short"
              day="2-digit"
            />
          ) : null}
        </TD>
        <TD>{status ? status.value : 'Unkown'}</TD>
        <TD>{this.state.item.modified}</TD>
        <TD>
          <span className={styles.button} onClick={this.onEdit}>
            Edit
          </span>
          {this.state.item.modified === 'new' ? (
            <span className={styles.button} onClick={this.onDelete}>
              Delete
            </span>
          ) : null}
          {modifiedItem ? (
            <span className={styles.button} onClick={this.onRevert}>
              Revert
            </span>
          ) : null}
        </TD>
      </TR>
    );
  }
}

export default ItemRow;
