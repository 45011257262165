import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';
import { useIntl } from 'react-intl';
import { UPDATE_STEP } from '../../../../../redux/slices/procedureSlice';
import React, { ChangeEvent, useState } from 'react';
import { InputItem } from '../../../../Shared/Forms';
import styled from 'styled-components';
import { OverlayBoxRounded } from '../../../../../common/graphics/lib/StyledComponentLibrary';

export function TeethStepDetailDescriptionField() {
  const procedure = useAppSelector((state) => state.consultation.procedure);
  const dispatch = useAppDispatch();
  const intl = useIntl();

  const stepDetails =
    procedure.visits[procedure.currentVisit]?.procedureStepStates[procedure.currentStep];

  const [collapsed, setCollapsed] = useState(false);

  return stepDetails ? (
    <TeethDescriptionTool>
      {collapsed ? (
        <CollapseLabel onClick={() => setCollapsed(false)}>
          <p>Show Description</p>
        </CollapseLabel>
      ) : (
        <>
          <InputItem
            label={intl.formatMessage({
              id: 'consultation.procedure.detail.description.label',
              defaultMessage: 'Description',
            })}
            type="textarea"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              dispatch(
                UPDATE_STEP({
                  updateData: {
                    description: e.target.value,
                  },
                })
              )
            }
            value={stepDetails?.description || ''}
            placeholder={intl.formatMessage({
              id: 'consultation.procedure.detail.description.placeholder',
              defaultMessage: 'Describe important information for this step',
            })}
            className={'yes'}
            /*onClick={() => setCollapsed(true)}*/
          />
        </>
      )}
    </TeethDescriptionTool>
  ) : null;
}

const TeethDescriptionTool = styled.div`
  position: absolute;
  bottom: 80px;
  right: -8px;
  width: 400px;
`;

const CollapseLabel = styled(OverlayBoxRounded)`
  width: 100%;
  max-width: 200px;
  user-select: none;
  cursor: pointer;
`;
