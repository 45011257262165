import { FC } from 'react';
import { Center } from '../../../../../common/graphics/lib/StyledComponentLibrary';
import { useIntl } from 'react-intl';
import { GOTO_STEP, SET_VISIT_NAME } from '../../../../../redux/slices/procedureSlice';
import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';
import SvgBackliga from '../../../../../shared/icons/osstell-regular/BackLiga';
import styled from 'styled-components';

const ProcedureDetailHeader: FC = () => {
  const intl = useIntl();
  const procedure = useAppSelector((state) => state.consultation.procedure);
  const dispatch = useAppDispatch();
  const { currentVisit, currentStep, visits } = procedure;

  const visit = visits[currentVisit];
  return (
    <TopBar>
      <StyledProcedureDetailHeader>
        {procedure.visits.length > 0 ? (
          <>
            <div className={'header_content'}>
              <input
                id={'visit_name'}
                value={visit?.name}
                onChange={(e) =>
                  dispatch(SET_VISIT_NAME({ index: currentVisit, name: e.target.value }))
                }
              />
            </div>

            {visit.procedureStepStates.length > 0 && (
              <StepSelector>
                <div
                  className={'previous'}
                  onClick={() =>
                    dispatch(
                      GOTO_STEP({
                        visitIndex: currentVisit,
                        stepIndex: Math.max(currentStep - 1, 0),
                      })
                    )
                  }
                >
                  <SvgBackliga
                    fill={'white'}
                    width={32}
                    height={32}
                    opacity={currentStep > 0 ? 1 : 0}
                  />
                </div>
                <div>{`${intl.formatMessage({ id: 'general.step', defaultMessage: 'Step' })} ${
                  currentStep + 1
                }/${visit.procedureStepStates.length}`}</div>
                <div
                  className={'next'}
                  onClick={() =>
                    dispatch(
                      GOTO_STEP({
                        visitIndex: currentVisit,
                        stepIndex: Math.min(currentStep + 1, visit.procedureStepStates.length - 1),
                      })
                    )
                  }
                >
                  <SvgBackliga
                    fill={'white'}
                    width={32}
                    height={32}
                    className={'next_step'}
                    opacity={currentStep < visit.procedureStepStates.length - 1 ? 1 : 0}
                  />
                </div>
              </StepSelector>
            )}
          </>
        ) : null}
      </StyledProcedureDetailHeader>
    </TopBar>
  );
};

const StepSelector = styled.div`
  ${Center};
  font-size: 1.2rem;
  padding-right: 25px;
  & .previous {
    padding: 0 5px;
    cursor: pointer;
  }
  & .next {
    padding: 0 5px;
    transform: scaleX(-1);
    cursor: pointer;
  }
`;

const TopBar = styled.div`
  background-color: var(--color-purple);
  width: 100%;
  ${Center};
`;
const StyledProcedureDetailHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  background-color: var(--color-purple);
  color: white;
  & .header_content {
    align-items: center;
    flex: 1;
    margin-left: 1.5rem;

    & > input#visit_name {
      box-sizing: border-box;
      height: 40px;
      background-color: rgba(255, 255, 255, 0.3);
      color: white;
      border-radius: 8px;
      border: none;
      font-size: 18px;
      font-family: 'Source Sans Pro', sans-serif;
      padding: 0 1rem;
    }
  }
`;
export default ProcedureDetailHeader;
