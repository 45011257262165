import React, { Component } from 'react';
import cn from 'classnames';
import styles from './ToothImage.module.scss';

class ToothImage extends Component {
  render() {
    return (
      <div
        id="toothImage"
        className={cn(
          styles.tooth,
          styles[this.props.row],
          this.props.implant ? styles.implant : null
          // this.props.abutment ? styles.abutment : null
        )}
        style={{ backgroundImage: 'url(' + this.props.image + ')' }}
      >
        {this.props.abutment && (
          <div
            className={styles.abutmentInner}
            style={{ backgroundImage: 'url(' + this.props.abutment + ')' }}
          ></div>
        )}
      </div>
    );
  }
}

export default ToothImage;
