import { toastr } from 'react-redux-toastr';
import { Button } from '../../../components/Shared/Buttons';
import { InputItem } from '../../../components/Shared/Forms';
import { toastrOptions } from '../../../constants/toastrOptions';
import { IAdminListUser } from '../../../types/user';
import { ChangeEvent, FC, useEffect, useState, KeyboardEvent } from 'react';
import { PanelWrapper, Panel } from '../../../components/Shared/Panel';
import { getUsers as getUsersRequest } from '../../../redux/actions/userActions';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import { SectionTabContent, Row, RowItem } from '../../../components/Shared/Section';
import {
  unsubscribeFromUsersChange,
  changeUserRole,
  exportUsers,
  clearUsers,
} from '../../../redux/actions/userActions';
import UserRow from './UserRow';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import styles from './UsersAdministration.module.scss';

interface Props {
  deleteUser: (user: IAdminListUser, callback: () => void) => void;
  retrievePassword: (email: string) => void;
}

const UsersAdministration: FC<Props> = ({ deleteUser, retrievePassword }) => {
  const dispatch = useAppDispatch();
  const { getUsers, user: currentUser } = useAppSelector((state) => state.user);

  const [users, setUsers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [isExportDone, setIsExportDone] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState('3');

  const searchTypes = [
    { value: 'Email', key: 3 },
    { value: 'Name', key: 1 },
    { value: 'Clinic name', key: 2 },
  ];

  useEffect(() => {
    setUsers(getUsers.users);
  }, [dispatch, getUsers.users]);

  useEffect(() => {
    return () => {
      dispatch(clearUsers());
      dispatch(unsubscribeFromUsersChange());
    };
  }, [dispatch]);

  const deleteUserHandler = (user: IAdminListUser) => deleteUser(user, getUsersHandler);

  const userRoleChanged = (userId: string, userSsoId: string, selectedRole: string) => {
    dispatch(
      changeUserRole(
        userId,
        userSsoId,
        selectedRole,
        showChangeUserRoleSuccess,
        showChangeUserRoleError
      )
    );
  };

  const showChangeUserRoleSuccess = () =>
    toastr.success('Success', 'User role changed', toastrOptions);

  const showChangeUserRoleError = () =>
    toastr.error('Error', 'Failed to change user role', toastrOptions);

  const exportUsersHandler = () => {
    setIsExportDone(true);
    dispatch(exportUsers(exportDoneHandler));
  };

  const exportDoneHandler = () => setIsExportDone(false);

  const getUsersHandler = () => {
    const searchCriteria = {
      searchText: filterText,
      searchType: selectedSearchType,
    };
    dispatch(getUsersRequest(searchCriteria));
  };

  const filterTextChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    const text = evt.target.value;
    if (text) return setFilterText(text.toLowerCase());
    setFilterText('');
  };

  const searchTypeChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setSelectedSearchType(evt.target.value);

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) =>
    event.key === 'Enter' && getUsersHandler();

  const renderUsers = () => {
    return users.map((user, index) => {
      return (
        <UserRow
          key={index}
          user={user}
          deleteAction={deleteUserHandler}
          retrievePasswordAction={retrievePassword}
          userRoleChanged={userRoleChanged}
          changeUserRoleDisabled={currentUser.role !== 'SuperAdmin'}
        />
      );
    });
  };

  return (
    <SectionTabContent>
      <Row className={styles.marginTop}>
        {(currentUser.role === 'SuperAdmin' || currentUser.role === 'AdminLv1') && (
          <RowItem>
            <div className="push-right">
              <Button
                primary
                value="Export"
                onClick={exportUsersHandler}
                loading={isExportDone}
                disabled={isExportDone}
              />
            </div>
          </RowItem>
        )}
      </Row>
      <PanelWrapper>
        <Panel full filled>
          <Row className={styles.headerRow}>
            <InputItem
              type="search"
              placeholder="Find a user by name, email or clinic name"
              onChange={filterTextChanged}
              onKeyPress={handleKeyPress}
              onWhite
            />
            <div className={styles.searchTypeDropdown}>
              <InputItem
                label="Search type"
                type="select"
                options={searchTypes}
                onChange={searchTypeChanged}
                onWhite
              />
            </div>
            <Button
              className={styles.findButton}
              primary
              value="Find"
              onClick={getUsersHandler}
              loading={getUsers.busy}
              disabled={getUsers.busy}
            />
          </Row>
          <div>
            {getUsers.busy ? <Spinner className={styles.spinnerBackground} /> : renderUsers()}
          </div>
        </Panel>
      </PanelWrapper>
    </SectionTabContent>
  );
};

export default UsersAdministration;
