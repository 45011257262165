import { FC } from 'react';
import { IImplant, IImplantMeasurement } from '../../types/implant';
import { FormattedMessage, FormattedDate } from 'react-intl';
import classNames from 'classnames';
import styles from './ISQListItem.module.scss';

interface Props {
  label: string;
  data: IImplant;
  all?: boolean;
  levelInfo: boolean;
  showCreateMeasurementModal?: (
    tooth: number,
    implantLevel: boolean,
    measurement: IImplantMeasurement | null
  ) => void;
}

const ISQListItem: FC<Props> = ({ label, data, all, levelInfo, showCreateMeasurementModal }) => {
  const getColor = (item: IImplantMeasurement) => {
    if (item.isq) {
      if (item.level === 'Abutment') {
        return styles.neutral;
      } else if (item.isq >= 70) {
        return styles.high;
      } else if (item.isq >= 60) {
        return styles.mid;
      } else if (item.isq > 0) {
        return styles.low;
      }
    }
    return styles.noMeasurement;
  };

  const getList = (implantMeasurements: IImplantMeasurement[]) => {
    const measurements = [...implantMeasurements];
    measurements.sort((measurement, otherMeasurement) => {
      return new Date(measurement.date).getTime() - new Date(otherMeasurement.date).getTime();
    });
    if (all) return measurements;
    return [
      {
        id: data.currentMeasurementId,
        isq: data.currentIsq,
        isqMd: data.currentMd,
        isqBl: data.currentBl,
        type: data.currentType,
        date: data.currentMeasurementDate,
        level: data.currentLevel,
        measurement: measurements[measurements.length - 1],
      },
    ];
  };

  const showMeasurementModal = (measurement: IImplantMeasurement) => {
    measurement = { ...measurement, BL: measurement.isqBl, MD: measurement.isqMd };
    if (showCreateMeasurementModal) showCreateMeasurementModal(data.tooth, true, measurement);
  };

  const renderLabel = () => {
    return <div className={styles.label}>{label}</div>;
  };

  const renderItems = (items: IImplantMeasurement[]) => {
    if (items) {
      return items.map((item) => {
        let type;
        if (item.type === 'DuringPlacement')
          type = <FormattedMessage id={'implant.placement'} defaultMessage={'Placement'} />;
        else if (item.type === 'DuringFollowup')
          type = <FormattedMessage id={'implant.followup'} defaultMessage={'Follow up'} />;
        else if (item.type === 'DuringLoading')
          type = (
            <FormattedMessage
              id={'implant.finalRestoration'}
              defaultMessage={'Final restoration'}
            />
          );

        return (
          <div className={styles.row} key={item.id}>
            <div className={styles.frame} key={item.id}>
              <div className={classNames(styles.isq, getColor(item))}>{item.isq}</div>
              <div className={styles.type}>{type}</div>
              {levelInfo && item.level === 'Abutment' && (
                <div className={styles.levelInfo}>
                  (<FormattedMessage id="implant.abutment" defaultMessage="Abutment" />)
                </div>
              )}
              <div className={styles.date}>
                <FormattedDate value={item.date} year="numeric" month="short" day="numeric" />
              </div>
            </div>
            {showCreateMeasurementModal && (
              <button
                className={classNames(styles.frame, styles.edit)}
                onClick={() => {
                  showMeasurementModal(item);
                }}
              >
                draw
              </button>
            )}
          </div>
        );
      });
    }
  };

  const measurements = getList(data.measurements);
  return (
    <div className={styles.ISQitem}>
      {renderLabel()}
      {renderItems(measurements as IImplantMeasurement[])}
    </div>
  );
};

export default ISQListItem;
