import React from 'react';
import styles from './Modal.module.scss';
import { PanelWrapper, Panel } from '../Panel';
import cn from 'classnames';

const Modal = (props) => {
  const fullModal = props.fullModal ? styles.fullModal : null;
  return (
    <div className={cn(styles.modal, fullModal, props.show === true ? styles.show : styles.hide)}>
      <div
        className={cn(styles.backdrop)}
        onClick={props.dismissOnClick ? props.onDismiss : null}
      />
      <PanelWrapper className={styles.PanelWrapper}>
        <Panel
          filled
          overrideClassName={props.overrideClassName}
          className={cn(styles.panel, props.modalPanelClass ? props.modalPanelClass : null)}
        >
          <div className={styles.dismissAction} onClick={props.onDismiss}>
            <i>close</i>
          </div>
          {props.header && <h2 className={styles.h2}>{props.header}</h2>}
          <div className={cn(props.modalContentClass, styles.content)}>{props.children}</div>
        </Panel>
      </PanelWrapper>
    </div>
  );
};
export default Modal;
