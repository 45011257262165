import React, { Component } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './Buttons.module.scss';

class SelectButton extends Component {
  renderOptions() {
    return this.props.options.map((option, index) => {
      const value = option.key ? option.key : option.value;
      return (
        <label key={index}>
          <input
            data-cy={this.props.dataCy && `${this.props.dataCy}-${value}`}
            type="radio"
            onChange={this.props.onChange}
            onClick={this.props.onClick}
            value={value}
            checked={this.props.value === value}
          ></input>
          <div
            className={classNames(
              styles.selectOption,
              this.props.onWhite ? styles.onWhite : null,
              this.props.centered ? styles.centered : null
            )}
          >
            {option.value}
          </div>
        </label>
      );
    });
  }

  renderBool() {
    return (
      <label>
        <input
          data-cy={this.props.dataCy && this.props.dataCy}
          type="checkbox"
          onChange={this.props.onChange}
          value={this.props.value}
          checked={this.props.checked}
        ></input>
        <div className={classNames(styles.boolButton, this.props.onWhite ? styles.onWhite : null)}>
          <div>{this.props.label}</div>
          {this.props.boolText &&
            (this.props.checked ? (
              <FormattedMessage id="general.yes" defaultMessage="Yes" />
            ) : (
              <FormattedMessage id="general.no" defaultMessage="No" />
            ))}
        </div>
      </label>
    );
  }

  render() {
    const cn = classNames(
      this.props.className,
      styles.selectButton,
      this.props.vertical ? styles.vertical : null,
      this.props.span ? styles.span : null
    );

    return <div className={cn}>{this.props.bool ? this.renderBool() : this.renderOptions()}</div>;
  }
}

export default SelectButton;
