import { useIntl } from 'react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useOnClickOutside } from '../../../../../../utils/hooks';
import { MODAL_LOAD } from '../../../../../../redux/slices/UISlice';
import { ConsultationRef, getConsultationRefs } from '../../../../../../redux/slices/apiSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { StyledPrompt } from './SavePrompt';
import Spinner from '../../../../../Shared/Loaders/Spinner';
import styled from 'styled-components';
import SvgSvgLoad from '../../../../../../shared/icons/custom/SvgLoad';
import SvgDeleteliga from '../../../../../../shared/icons/osstell-light/DeleteLiga';

interface LoadProps {
  onLoad: (id: string) => void;
  onDelete: (id: string) => void;
  userGroupId?: string;
  patientId?: string;
}

function ActionButtons({
  onLoad,
  onDelete,
  consultationId,
  index,
}: {
  onLoad: (consultationId: string) => void;
  onDelete: (consultationId: string) => void;
  consultationId: string;
  index: number;
}) {
  const deleteBusy = useAppSelector(
    (state) => state.consultation.api.deleteConsultationStatus.busy
  );
  const loadBusy = useAppSelector((state) => state.consultation.api.getConsultationStatus.busy);
  const [loading, setLoading] = useState(false);
  return (
    <StyledActionButtons>
      <span
        data-cy={`load-prompt-load-${index}`}
        onClick={() => {
          onLoad(consultationId);
          setLoading(true);
        }}
      >
        {loadBusy && loading ? <Spinner purple /> : <SvgSvgLoad width={30} />}
      </span>
      <span
        data-cy={`load-prompt-delete-${index}`}
        onClick={() => {
          onDelete(consultationId);
          setLoading(true);
        }}
      >
        {deleteBusy && loading ? <Spinner purple /> : <SvgDeleteliga width={32} />}
      </span>
    </StyledActionButtons>
  );
}

const StyledActionButtons = styled.div`
  display: flex;

  & > span {
    display: grid;
    place-items: center;
    margin-right: 1rem;
    padding: 0.5rem;
    fill: var(--color-purple);
    stroke: var(--color-purple);
    cursor: pointer;
    &:hover {
      stroke: var(--color-lightpurple);
      fill: var(--color-lightpurple);
    }
  }
`;
export default function LoadPrompt({ onLoad, onDelete, userGroupId, patientId }: LoadProps) {
  const intl = useIntl();
  const promptRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const configurations = useAppSelector((state) => state.consultation.api.consultationRefs);
  const showModal = useAppSelector((state) => state.consultation.view.modal.loadOpen);

  const [loading, setLoading] = useState(false);
  useOnClickOutside(promptRef, () => dispatch(MODAL_LOAD(false)));

  useEffect(() => {
    if (showModal) {
      setLoading(true);
      dispatch(
        getConsultationRefs({
          userGroupId: userGroupId ?? '',
          patientId: patientId ?? '',
        })
      )
        .then(unwrapResult)
        .then(() => setLoading(false));
    }
  }, [showModal, setLoading, dispatch, userGroupId, patientId]);

  return showModal ? (
    <StyledPrompt ref={promptRef} xOffset={'72px'}>
      {loading ? (
        <>
          <span>
            {intl.formatMessage({
              id: 'consultation.configuration.load.loadingConsultations',
              defaultMessage: 'Loading consultations...',
            })}
          </span>
          <Spinner purple />
        </>
      ) : configurations?.length > 0 ? (
        <ul data-cy="consultation-load-modal-ul">
          <span className={'table-head-row'}>
            {intl.formatMessage({
              id: 'consultation.configuration.load.name',
              defaultMessage: 'Name',
            })}
          </span>
          <span className={'table-head-row'}>
            {intl.formatMessage({
              id: 'consultation.configuration.load.modified',
              defaultMessage: 'Modified',
            })}
          </span>
          <span className={'table-head-row'}>
            {' '}
            {intl.formatMessage({
              id: 'consultation.configuration.load.actions',
              defaultMessage: 'Actions',
            })}
          </span>
          {configurations.map((config: ConsultationRef, index: number) => (
            <React.Fragment key={config.consultationId}>
              <span data-cy={`load-prompt-${index}`}>{config.name || 'Unnamed'}</span>
              <span>{new Date(config.lastModified).toLocaleString()}</span>
              <ActionButtons
                index={index}
                onLoad={onLoad}
                onDelete={onDelete}
                consultationId={config.consultationId}
              />
            </React.Fragment>
          ))}
        </ul>
      ) : (
        <span>
          {intl.formatMessage({
            id: 'consultation.configuration.load.noConsultations',
            defaultMessage: 'No stored consultations',
          })}
        </span>
      )}
    </StyledPrompt>
  ) : null;
}
