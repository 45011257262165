export const createHeaders = (token: string, skipAuthorization?: boolean, contentType?: string) => {
  if (!token && skipAuthorization !== true) {
    throw new Error('Token not set');
  }

  const headers = new Headers({
    'Content-Type': contentType ? contentType + '; boundary=&&--' : 'application/json',
  });
  if (skipAuthorization !== true) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
};
