import { FC, ReactNode, useState } from 'react';
import { PanelWrapper, Panel } from '../Shared/Panel';
import styles from './ExportSection.module.scss';
import cn from 'classnames';

interface Props {
  children: ReactNode;
  className?: string;
}

const ExportSection: FC<Props> = ({ children, className }) => {
  const [isHidden, setIsHidden] = useState(false);
  const onDismiss = () => setIsHidden(true);

  return (
    <div className={cn(styles.exportSection, className, isHidden === true ? styles.hide : null)}>
      <PanelWrapper className={styles.panelWrapper}>
        <Panel full filled className={styles.panel}>
          <div className={styles.dismissAction} onClick={onDismiss} id="dismissButton">
            <i>close</i>
          </div>
          {children}
        </Panel>
      </PanelWrapper>
    </div>
  );
};

export default ExportSection;
