import colors from '../../shared/styles/_variables.scss';

const fontFamily = "'Source Sans Pro', sans-serif";
export const osstellChartTheme = {
  background: 'transparent',
  fontFamily,
  fontSize: 11,
  textColor: '#333333',
  axis: {
    domain: {
      line: {
        stroke: 'transparent',
        strokeWidth: 2,
      },
    },
    ticks: {
      line: {
        stroke: colors.tintedLightgrey,
        strokeWidth: 1,
      },
      text: { fontFamily, fontSize: 10, fontWeight: 400, fill: colors.black },
    },
    legend: {
      text: {
        fontSize: 10,
        fill: colors.black,
      },
    },
  },
  grid: {
    line: {
      stroke: colors.tintedLightgrey,
      strokeWidth: 1,
    },
  },
  legends: {
    text: { fill: 'red' },
  },
  labels: {
    text: { fill: 'red' },
  },
  markers: {
    lineColor: '#000000',
    lineStrokeWidth: 1,
    text: {},
  },
  dots: {
    text: {},
  },
  tooltip: {
    container: {
      background: 'white',
      color: 'inherit',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '5px 9px',
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
    },
    chip: {
      marginRight: 7,
    },
    table: {},
    tableCell: {
      padding: '3px 5px',
    },
  },
  crosshair: {
    line: {
      stroke: '#000000',
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: '6 6',
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    outline: {
      fill: 'none',
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
  },
};
