import { FC } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormikValues } from 'formik';

interface Props {
  id: string;
  label: string;
  value: string;
  formik: FormikValues;
  error: boolean | undefined;
  onChange?: (event: SelectChangeEvent) => void;
  menuList: { key: string; value: string }[];
  disabled: boolean;
  helperText: string | boolean | undefined;
  onOpenModalClick: () => void;
}

const FormikCountrySelect: FC<Props> = ({
  id,
  label,
  value,
  error,
  formik,
  onChange,
  disabled,
  menuList,
  helperText,
  onOpenModalClick,
}) => {
  const intl = useIntl();
  return (
    <>
      <FormControl fullWidth size={'small'} error={error}>
        <InputLabel>{label}</InputLabel>
        <Select
          id={id}
          label={label}
          value={value}
          disabled={disabled}
          onChange={onChange}
          error={Boolean(error)}
        >
          {menuList.map((item: { key: string; value: string }, idx: number) => (
            <MenuItem key={idx} value={item.key}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText error={true}>{helperText}</FormHelperText>}
      </FormControl>
      {formik.values.eulaCountry && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 10,
          }}
        >
          <div>
            <FormControlLabel
              id="termsOfServiceAccepted"
              control={<Checkbox size="small" />}
              disabled={disabled}
              label={
                <Typography variant="subtitle2">
                  {intl.formatMessage({
                    id: 'registerAccount.ConfirmEula',
                    defaultMessage: 'I have read and accept the terms and conditions',
                  })}
                </Typography>
              }
              {...formik.getFieldProps('termsOfServiceAccepted')}
            />
            {formik.touched.termsOfServiceAccepted &&
              Boolean(formik.errors.termsOfServiceAccepted) && (
                <FormHelperText error={true}>
                  {intl.formatMessage({
                    id: 'registerAccount.ReqestEula',
                    defaultMessage: 'Please read and accept terms of service',
                  })}
                </FormHelperText>
              )}
          </div>
          <Button
            variant="text"
            onClick={onOpenModalClick}
            size="small"
            sx={{ fontSize: '12px', alignSelf: 'flex-start', pt: '8px' }}
          >
            <FormattedMessage
              id="registerAccount.TermsAndConditions"
              defaultMessage="Terms and conditions"
            />
          </Button>
        </div>
      )}
    </>
  );
};

export default FormikCountrySelect;
