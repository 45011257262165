import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { v4 as uuidv4 } from 'uuid';
import {
  initialWorkspaceState,
  ConsultationWorkspaceState,
} from './data-structures/workspaceStructure';
import { initialPreviewImageSrc } from './data-structures/initialPreviewImageSrc';

export interface ProcedureStep {
  id: string;
  state: ConsultationWorkspaceState;
  description?: string;
  previewImageSrc?: string;
}

export interface Visit {
  visitIndex: number;
  procedureStepStates: Array<ProcedureStep>;
  name: string;
  date: string;
}

export interface ProcedureStepState {
  consultationId?: string;
  name?: string;
  currentStep: number;
  currentVisit: number;
  visits: Array<Visit>;
  configurationHasUnsavedChanges: boolean;
  patientId?: string;
  title?: string;
  note?: string;
}

export const initialState: ProcedureStepState = {
  currentStep: 0,
  currentVisit: 0,
  visits: [
    {
      visitIndex: 0,
      procedureStepStates: [
        {
          id: uuidv4(),
          state: initialWorkspaceState,
          previewImageSrc: initialPreviewImageSrc,
        },
      ],
      name: '',
      date: new Date().toUTCString(),
    },
  ],
  configurationHasUnsavedChanges: false,
};

export const procedureSlice = createSlice({
  name: 'CONSULTATION_PROCEDURE',
  initialState,
  reducers: {
    ADD_STEP: (
      state,
      action: PayloadAction<{
        id: string;
        workspace: ConsultationWorkspaceState;
        screenshot?: string;
        visitIndex: number;
      }>
    ) => {
      // Save changes to current step before pushing
      if (state.visits[state.currentVisit].procedureStepStates.length > 0) {
        let currentStep = state.visits[state.currentVisit].procedureStepStates[state.currentStep];

        currentStep = {
          ...currentStep,
          previewImageSrc: action.payload?.screenshot || '',
          state: action.payload.workspace,
        };

        state.visits[state.currentVisit].procedureStepStates[state.currentStep] = currentStep;
      }

      // Push new step
      state.visits[action.payload.visitIndex].procedureStepStates.push({
        id: action.payload.id,
        state: action.payload.workspace,
        previewImageSrc: action.payload?.screenshot || '',
      });
      state.currentVisit = action.payload.visitIndex;
      state.currentStep = state.visits[action.payload.visitIndex].procedureStepStates.length - 1;
      state.configurationHasUnsavedChanges = true;
    },
    ADD_VISIT: (
      state,
      action: PayloadAction<{
        name: string;
        stepWorkspace: ConsultationWorkspaceState;
        stepScreenshot?: string;
      }>
    ) => {
      // Save changes to current step before pushing
      if (state.visits[state.currentVisit].procedureStepStates.length > 0) {
        let currentStep = state.visits[state.currentVisit].procedureStepStates[state.currentStep];

        currentStep = {
          ...currentStep,
          previewImageSrc: action.payload?.stepScreenshot || '',
          state: action.payload.stepWorkspace,
        };

        state.visits[state.currentVisit].procedureStepStates[state.currentStep] = currentStep;
      }

      // Push new visit
      const previousDate = new Date(state.visits[state.visits.length - 1].date);
      const newDate = previousDate.setDate(previousDate.getDate() + 14);

      state.visits.push({
        visitIndex: state.visits.length,
        name: action.payload.name + ' ' + (state.visits.length + 1),
        procedureStepStates: [],
        date: new Date(newDate).toUTCString(),
      });

      //Add new step in visit
      state.visits[state.visits.length - 1].procedureStepStates.push({
        id: uuidv4(),
        state: action.payload.stepWorkspace,
        previewImageSrc: action.payload?.stepScreenshot || '',
      });

      state.currentVisit = state.visits.length - 1;
      state.currentStep = state.visits[state.visits.length - 1].procedureStepStates.length - 1;

      state.configurationHasUnsavedChanges = true;
    },
    REMOVE_STEP: (
      state,
      action: PayloadAction<{
        visitIndex: number;
        stepIndex: number;
      }>
    ) => {
      const { visitIndex, stepIndex } = action.payload;

      // Remove last step on last visit
      if (state.visits[visitIndex].procedureStepStates.length === 1 && state.visits.length === 1) {
        return initialState;
      }
      // Remove last step but have more visits
      else if (
        state.visits[visitIndex].procedureStepStates.length === 1 &&
        state.visits.length > 1
      ) {
        state.visits.splice(visitIndex, 1);
        state.currentVisit = state.visits.length - 1;
        state.currentStep = state.visits[state.currentVisit].procedureStepStates.length - 1;
      }
      // Remove step and have more steps on same visit
      else {
        state.visits[visitIndex].procedureStepStates.splice(stepIndex, 1);
        state.currentStep = state.visits[visitIndex].procedureStepStates.length - 1;
      }

      state.configurationHasUnsavedChanges = true;
    },
    UPDATE_STEP: (
      state,
      action: PayloadAction<{
        updateData?: Partial<ProcedureStep>;
        visitIndex?: number;
        stepIndex?: number;
      }>
    ) => {
      const visitIndex = action.payload.visitIndex ?? state.currentVisit;
      const stepIndex = action.payload.stepIndex ?? state.currentStep;
      const updatedInfo = action.payload.updateData || {};
      if (state.visits[visitIndex].procedureStepStates[stepIndex]) {
        state.visits[visitIndex].procedureStepStates[stepIndex] = {
          ...state.visits[visitIndex].procedureStepStates[stepIndex],
          ...updatedInfo,
        };
      } else {
        // Push new step
        state.visits[visitIndex].procedureStepStates.push({
          id: uuidv4(),
          state: action.payload.updateData?.state || initialWorkspaceState,
          previewImageSrc: action.payload.updateData?.previewImageSrc || '',
        });
      }

      state.configurationHasUnsavedChanges = true;
    },
    MOVE_STEP_WITHIN_VISIT: (
      state,
      action: PayloadAction<{ stepIndex: number; visitIndex: number; newStepIndex: number }>
    ) => {
      const visitIndex = action.payload.visitIndex;
      const idOfCurrentStep = state.visits[visitIndex].procedureStepStates[state.currentStep].id;
      const stepIndex = Math.max(
        Math.min(action.payload.stepIndex, state.visits[visitIndex].procedureStepStates.length - 1),
        0
      );
      const newStepIndex = Math.max(
        Math.min(
          action.payload.newStepIndex,
          state.visits[visitIndex].procedureStepStates.length - 1
        ),
        0
      );
      const step = state.visits[visitIndex].procedureStepStates.splice(stepIndex, 1)[0];
      state.visits[visitIndex].procedureStepStates.splice(newStepIndex, 0, step);

      const index = state.visits[visitIndex].procedureStepStates.findIndex(
        (step) => step.id === idOfCurrentStep
      );

      state.currentStep = index;

      state.configurationHasUnsavedChanges = true;
    },
    MOVE_STEP_BETWEEN_VISITS: (
      state,
      action: PayloadAction<{
        stepIndex: number;
        visitIndex: number;
        newVisitIndex: number;
      }>
    ) => {
      const { visitIndex, stepIndex, newVisitIndex } = action.payload;
      const step = state.visits[visitIndex].procedureStepStates.splice(stepIndex, 1)[0];

      state.visits[newVisitIndex].procedureStepStates.unshift(step);
      state.currentStep = 0;
      state.currentVisit = newVisitIndex;
      state.configurationHasUnsavedChanges = true;
    },
    GOTO_STEP: (
      state,
      action: PayloadAction<{
        visitIndex: number;
        stepIndex: number;
        workspace?: ConsultationWorkspaceState;
        screenshot?: string;
        shouldUpdateCurrent?: boolean;
      }>
    ) => {
      // Update current step
      if (action.payload.shouldUpdateCurrent) {
        let currentStep = state.visits[state.currentVisit].procedureStepStates[state.currentStep];

        currentStep = {
          ...currentStep,
          previewImageSrc: action.payload?.screenshot || '',
          state: action.payload.workspace!,
        };

        state.visits[state.currentVisit].procedureStepStates[state.currentStep] = currentStep;
      }
      // Go to new step
      state.currentVisit = action.payload.visitIndex;
      state.currentStep = action.payload.stepIndex;
    },
    SET_VISIT_DATE: (state, action: PayloadAction<{ index: number; date: string }>) => {
      state.visits[action.payload.index].date = action.payload.date;
      state.configurationHasUnsavedChanges = true;
    },
    SET_VISIT_NAME: (state, action: PayloadAction<{ index: number; name: string }>) => {
      state.visits[action.payload.index].name = action.payload.name;
      state.configurationHasUnsavedChanges = true;
    },
    RESET: (_) => initialState,
    LOAD: (_, action: PayloadAction<ProcedureStepState>) => {
      return {
        ...initialState,
        ...action.payload,
        currentVisit: 0,
        currentStep: 0,
        configurationHasUnsavedChanges: false,
      };
    },
    SET_NAME: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    SET_ID: (state, action: PayloadAction<string | undefined>) => {
      state.consultationId = action.payload;
    },
    SET_DIRTY: (state, action: PayloadAction<boolean>) => {
      state.configurationHasUnsavedChanges = action.payload;
    },
    SET_PATIENT_ID: (state, action: PayloadAction<string>) => {
      state.patientId = action.payload;
    },
    SET_VISITS: (state, action: PayloadAction<Visit[]>) => {
      state.visits = action.payload;
    },
    SET_TITLE: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
      state.configurationHasUnsavedChanges = true;
    },
    SET_NOTE: (state, action: PayloadAction<string>) => {
      state.note = action.payload;
      state.configurationHasUnsavedChanges = true;
    },
  },
});

export const {
  MOVE_STEP_WITHIN_VISIT,
  MOVE_STEP_BETWEEN_VISITS,
  ADD_VISIT,
  ADD_STEP,
  REMOVE_STEP,
  RESET,
  GOTO_STEP,
  UPDATE_STEP,
  LOAD,
  SET_NAME,
  SET_ID,
  SET_DIRTY,
  SET_PATIENT_ID,
  SET_VISITS,
  SET_VISIT_DATE,
  SET_VISIT_NAME,
  SET_TITLE,
  SET_NOTE,
} = procedureSlice.actions;

export const allProcedureSteps = (state: RootState) => state.consultation.procedure;

export default procedureSlice.reducer;
