import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiFetch from '../../../src/utils/apiFetch';
import Store from '../store';

interface ActionStatus {
  complete: boolean;
  busy: boolean;
  error?: string;
}
export interface ConsultationRef {
  consultationId: string;
  userGroupId: string;
  patientId: string;
  createdBy: string;
  name: string;
  lastModified: string;
}
interface ConsultationsState {
  getConsultationRefsStatus: ActionStatus;
  getConsultationStatus: ActionStatus;
  storeConsultationStatus: ActionStatus;
  deleteConsultationStatus: ActionStatus;
  shareConsultationStatus: ActionStatus;
  consultationRefs: ConsultationRef[];
}

const actionStatusInitial = {
  complete: false,
  error: undefined,
  busy: false,
};

export const initialState: ConsultationsState = {
  getConsultationRefsStatus: actionStatusInitial,
  getConsultationStatus: actionStatusInitial,
  storeConsultationStatus: actionStatusInitial,
  deleteConsultationStatus: actionStatusInitial,
  shareConsultationStatus: actionStatusInitial,
  consultationRefs: [],
};

export const getConsultationRefs = createAsyncThunk(
  'consultation/getConsultationRefs',
  async ({ userGroupId, patientId }: { userGroupId: string; patientId: string }) => {
    const response = await ApiFetch.get(`consultation/${userGroupId}/${patientId}`);
    return response;
  }
);
export const getConsultation = createAsyncThunk(
  'consultation/getConsultation',
  async (consultationId: string) => {
    const response = await ApiFetch.get(`consultation/${consultationId}`);
    return response;
  }
);
export const storeConsultation = createAsyncThunk(
  'consultation/storeConsultation',
  async ({
    userGroupId,
    patientId,
    name,
    createdBy,
    consultationId,
  }: {
    userGroupId: string;
    patientId: string;
    name: string;
    createdBy: string;
    consultationId?: string;
  }) => {
    const state = { ...Store.store.getState() };
    const request = {
      consultationId: consultationId,
      configuration: JSON.stringify(state.consultation.procedure),
      name: name,
      createdBy: createdBy,
    };
    const response = await ApiFetch.post(`consultation/${userGroupId}/${patientId}`, request);
    return response;
  }
);
export const deleteConsultation = createAsyncThunk(
  'consultation/deleteConsultation',
  async ({
    userGroupId,
    patientId,
    consultationId,
  }: {
    userGroupId: string;
    patientId: string;
    consultationId: string;
  }) => {
    const response = await ApiFetch.delete(
      `consultation/${userGroupId}/${patientId}/${consultationId}`
    );
    return response;
  }
);
export const shareConsultation = createAsyncThunk(
  'consultation/shareConsultation',
  async ({ email, fileName, file }: { email: string; fileName: string; file: File }) => {
    const data = new FormData();
    data.append('email', email);
    data.append('fileName', fileName);
    data.append('file', file);
    const response = await ApiFetch.postFormData(`consultation/share`, data);
    return response;
  }
);
export const consultationInfoSlice = createSlice({
  name: 'consultations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get Consultation References
    builder.addCase(getConsultationRefs.fulfilled, (state, action) => {
      state.consultationRefs = action.payload;
      state.getConsultationRefsStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(getConsultationRefs.rejected, (state, action) => {
      state.getConsultationRefsStatus.complete = true;
      state.getConsultationRefsStatus.error = action.error.message;
      state.getConsultationRefsStatus.busy = false;
    });
    builder.addCase(getConsultationRefs.pending, (state, _) => {
      state.getConsultationRefsStatus.complete = false;
      state.getConsultationRefsStatus.error = undefined;
      state.getConsultationRefsStatus.busy = true;
    });

    //Get Consultation
    builder.addCase(getConsultation.fulfilled, (state, _) => {
      //state.consultation = action.payload;
      state.getConsultationStatus.complete = true;
      state.getConsultationStatus.error = undefined;
      state.getConsultationStatus.busy = false;
    });
    builder.addCase(getConsultation.rejected, (state, action) => {
      state.getConsultationStatus.complete = true;
      state.getConsultationStatus.error = action.error.message;
      state.getConsultationStatus.busy = false;
    });
    builder.addCase(getConsultation.pending, (state, _) => {
      state.getConsultationStatus.complete = false;
      state.getConsultationStatus.error = undefined;
      state.getConsultationStatus.busy = true;
    });

    //Store Consultation
    builder.addCase(storeConsultation.fulfilled, (state, _) => {
      state.storeConsultationStatus.complete = true;
      state.storeConsultationStatus.error = undefined;
      state.storeConsultationStatus.busy = false;
    });
    builder.addCase(storeConsultation.rejected, (state, action) => {
      state.storeConsultationStatus.complete = true;
      state.storeConsultationStatus.error = action.error.message;
      state.storeConsultationStatus.busy = false;
    });
    builder.addCase(storeConsultation.pending, (state, _) => {
      state.storeConsultationStatus.complete = false;
      state.storeConsultationStatus.error = undefined;
      state.storeConsultationStatus.busy = true;
    });

    //Delete Consultation
    builder.addCase(deleteConsultation.fulfilled, (state, _) => {
      state.deleteConsultationStatus.complete = true;
      state.deleteConsultationStatus.error = undefined;
      state.deleteConsultationStatus.busy = false;
    });
    builder.addCase(deleteConsultation.rejected, (state, action) => {
      state.deleteConsultationStatus.complete = true;
      state.deleteConsultationStatus.error = action.error.message;
      state.deleteConsultationStatus.busy = false;
    });
    builder.addCase(deleteConsultation.pending, (state, _) => {
      state.deleteConsultationStatus.complete = false;
      state.deleteConsultationStatus.error = undefined;
      state.deleteConsultationStatus.busy = true;
    });

    //Share Consultation
    builder.addCase(shareConsultation.fulfilled, (state, _) => {
      state.shareConsultationStatus.complete = true;
      state.shareConsultationStatus.error = undefined;
      state.shareConsultationStatus.busy = false;
    });
    builder.addCase(shareConsultation.rejected, (state, action) => {
      state.shareConsultationStatus.complete = true;
      state.shareConsultationStatus.error = action.error.message;
      state.shareConsultationStatus.busy = false;
    });
    builder.addCase(shareConsultation.pending, (state, _) => {
      state.shareConsultationStatus.complete = false;
      state.shareConsultationStatus.error = undefined;
      state.shareConsultationStatus.busy = true;
    });
  },
});
export default consultationInfoSlice.reducer;
