import React, { Component } from 'react';
import styles from './ImplantChart.module.scss';
import classNames from 'classnames';

export default class ChartTooltip extends Component {
  render() {
    const { active, payload } = this.props;

    if (active && payload) {
      const BL = payload[0].payload.BL;
      const MD = payload[0].payload.MD;
      const date = payload[0].payload.dateString;
      const projection = payload[0].payload.projection ? true : false;
      const manual = payload[0].payload.manual;
      return (
        <div className={classNames(projection ? styles.projection : null, styles.toolTip)}>
          <div className={styles.data}>
            BL:{BL} MD:{MD}
          </div>
          <div className={styles.label}>{date}</div>
          {manual ? <div className={styles.manual}>{manual}</div> : null}
        </div>
      );
    }
    return null;
  }
}
