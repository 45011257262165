import * as React from 'react';
import { useEffect, useRef } from 'react';
import Konva from 'konva';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import { TEXTFIELD_REMOVE, TEXTFIELD_UPDATE } from '../../../../../../redux/slices/workspaceSlice';
import { RootState } from '../../../../../../redux/store';
import { Group, Rect, Text } from 'react-konva';
import { SET_COLOR, SET_TEXTFIELD_STATUS } from '../../../../../../redux/slices/UISlice';
import { Html } from 'react-konva-utils';
import { TextField } from '../../../../../../redux/slices/data-structures/workspaceStructure';

export function TeethMovableTextField({
  id,
  changeCursor,
}: {
  id: string;
  changeCursor?: (cursor: string) => void;
}) {
  const textRef = useRef<Konva.Text>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const textField = useAppSelector((state) =>
    state.consultation.workspace.present.textFields.find((text: TextField) => text.id === id)
  );
  const textFieldBeingEdited = useAppSelector(
    (state: RootState) => state.consultation.view.textFieldEditing
  );
  const currentColor = useAppSelector((state) => state.consultation.view.drawCurrentColor);
  const isEditing = textField?.id === textFieldBeingEdited;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isEditing) dispatch(TEXTFIELD_UPDATE({ id: textField.id, color: currentColor }));
  }, [currentColor]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 0);
    }
  }, [isEditing, inputRef]);

  function onClickedText() {
    dispatch(SET_TEXTFIELD_STATUS({ id: textField.id }));
    dispatch(SET_COLOR(textField.color || 'black'));
    if (inputRef.current) {
      setTimeout(function () {
        inputRef.current?.focus();
      }, 0);
    }
  }

  if (!textField) return null;
  return (
    <Group
      x={textField.position.x}
      y={textField.position.y}
      draggable={true}
      onDragEnd={(e) => {
        dispatch(TEXTFIELD_UPDATE({ id: textField.id, x: e.target.attrs.x, y: e.target.attrs.y }));
      }}
    >
      <Rect
        x={-4}
        y={-4}
        width={(textRef.current?.width() || 0) + 32}
        height={(textRef.current?.height() || 0) + 12}
        fillEnabled={false}
        stroke={'#00000011'}
        strokeWidth={6}
        strokeHitEnabled={true}
        opacity={0.1}
        onMouseEnter={() => changeCursor?.('move')}
        onMouseLeave={() => changeCursor?.('default')}
      />
      <Text
        x={4}
        y={4}
        ref={textRef}
        text={`${textField?.text}`}
        onMouseDown={onClickedText}
        onTouchStart={onClickedText}
        fontSize={20}
        opacity={isEditing ? 0 : 1}
        onMouseEnter={() => changeCursor?.('move')}
        onMouseLeave={() => changeCursor?.('default')}
        fill={textField.color || 'black'}
      />
      <Html
        divProps={{
          style: {
            position: 'absolute',
            top: 0,
            left: 0,
            display: isEditing ? 'block' : 'none',
            backgroundColor: 'transparent',
            height: 16,
            width: (textRef.current?.width() || 0) + 48,
          },
        }}
      >
        <input
          ref={inputRef}
          value={textField.text}
          placeholder="Text"
          width={500}
          style={{
            backgroundColor: 'transparent',
            fontSize: 20,
            width: (textRef.current?.width() || 0) + 48,
            color: textField.color || 'black',
          }}
          onBlur={(e) => {
            if (e.target.value.trim() === '') {
              dispatch(TEXTFIELD_REMOVE({ id: textField.id }));
              dispatch(SET_TEXTFIELD_STATUS({ id: undefined }));
            }
            if (isEditing) dispatch(SET_TEXTFIELD_STATUS({ id: undefined }));
          }}
          onChange={(e) => {
            dispatch(TEXTFIELD_UPDATE({ id: textField.id, text: e.target.value || '' }));
          }}
        />
      </Html>
    </Group>
  );
}
