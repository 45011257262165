import { FC, MutableRefObject } from 'react';
import styled from 'styled-components';
import {
  Center,
  OverlayBoxRounded,
} from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import SvgDarkModeliga from '../../../../../../shared/icons/osstell-light/DarkModeLiga';
import SvgSinusliga from '../../../../../../shared/icons/osstell-light/SinusLiga';
import SvgGumsliga from '../../../../../../shared/icons/osstell-light/GumsLiga';
import SvgNerveliga from '../../../../../../shared/icons/osstell-light/NerveLiga';
import SvgCameraliga from '../../../../../../shared/icons/osstell-light/CameraLiga';
import {
  TOGGLE_MODE_DARK,
  TOGGLE_MODE_GUMS,
  TOGGLE_MODE_NERVE,
  TOGGLE_MODE_TOOTH_NUMBERS,
  TOGGLE_MODE_SINUS,
  TOGGLE_MODE_SMILE,
} from '../../../../../../redux/slices/workspaceSlice';
import { TOGGLE_MODE_IMAGE_LIBRARY, SET_TOOL } from '../../../../../../redux/slices/UISlice';
import SvgSvgSmile from '../../../../../../shared/icons/custom/SvgSmile';
import ImageLibrary from '../../../../../PatientImages/ImageLibrary';
import { FormattedMessage } from 'react-intl';

interface Props {
  addImageRef: MutableRefObject<null>;
}

export const TeethViewSettingsToolbar: FC<Props> = ({ addImageRef }) => {
  const dispatch = useAppDispatch();
  const viewSettings = useAppSelector((state) => state.consultation.workspace.present.view);
  const viewImageLibrary = useAppSelector((state) => state.consultation.view.imageLibrary);
  return (
    <ProcedureStepToolbar>
      <ProcedureStepToolbarTabs>
        <TabButton selected={viewSettings.darkMode} onClick={() => dispatch(TOGGLE_MODE_DARK())}>
          <SvgDarkModeliga />
          <span>
            <FormattedMessage id="consultation.viewsettings.darkmode" defaultMessage="Dark mode" />
          </span>
        </TabButton>
        <TabButton
          selected={viewSettings.gumsTransparencyMode}
          onClick={() => dispatch(TOGGLE_MODE_GUMS())}
        >
          <SvgGumsliga />
          <span>
            <FormattedMessage id="consultation.viewsettings.gums" defaultMessage="Gums" />
          </span>
        </TabButton>
        <TabButton selected={viewSettings.sinusMode} onClick={() => dispatch(TOGGLE_MODE_SINUS())}>
          <SvgSinusliga />
          <span>
            <FormattedMessage id="consultation.viewsettings.sinus" defaultMessage="Sinus" />
          </span>
        </TabButton>
        <TabButton selected={viewSettings.nerveNodes} onClick={() => dispatch(TOGGLE_MODE_NERVE())}>
          <SvgNerveliga />
          <span>
            <FormattedMessage id="consultation.viewsettings.nerve" defaultMessage="Nerve" />
          </span>
        </TabButton>
        <TabButton
          selected={viewSettings.toothNumbers}
          onClick={() => dispatch(TOGGLE_MODE_TOOTH_NUMBERS())}
        >
          1,2,3
          <span>
            <FormattedMessage
              id="consultation.viewsettings.toothNumbers"
              defaultMessage="Tooth numbers"
            />
          </span>
        </TabButton>
        <TabButton
          selected={viewSettings.smileMode}
          onClick={() => {
            dispatch(TOGGLE_MODE_SMILE());
            dispatch(SET_TOOL());
          }}
        >
          <SvgSvgSmile stroke={'#000'} />
          <span>
            <FormattedMessage id="consultation.viewsettings.smile" defaultMessage="Smile" />
          </span>
        </TabButton>
        <TabButton
          ref={addImageRef}
          selected={viewImageLibrary}
          onClick={() => dispatch(TOGGLE_MODE_IMAGE_LIBRARY())}
        >
          <SvgCameraliga />
          <span>
            <FormattedMessage id="consultation.viewsettings.images" defaultMessage="Images" />
          </span>
        </TabButton>
      </ProcedureStepToolbarTabs>
      {viewImageLibrary && <ImageLibrary />}
    </ProcedureStepToolbar>
  );
};

const ProcedureStepToolbar = styled.div`
  ${Center};
  flex-direction: row;
  justify-content: start;
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
`;

const ProcedureStepToolbarTabs = styled(OverlayBoxRounded)`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0.5rem;
  user-select: none;
  border-radius: 16px;

  & > div:not(:first-child) {
    margin-top: 8px;
  }
`;

const TabButton = styled.div<{ selected?: boolean }>`
  ${Center};
  height: 3rem;
  padding: 4px;
  user-select: none;
  border-radius: 16px;
  flex-direction: column;

  & > span {
    font-size: 9px;
    white-space: nowrap;
  }

  & > svg:active {
    opacity: 0.5;
  }

  & > svg {
    width: 2rem;
    height: 2rem;
  }
  ${(props) =>
    props.selected &&
    `background-color: ${props.theme.colors.selectedgrey};
     transition: background-color 270ms ease-in-out; 
     width: 100%;
     color: ${props.theme.colors.purple}; 
     svg {
      fill: ${props.theme.colors.purple};
     }
     `};
`;
