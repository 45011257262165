import * as types from '../../constants/actionTypes';

const initialState = {
  clinicUpdate: {
    complete: false,
    error: undefined,
    busy: false,
  },
  updateReferrals: {
    complete: false,
    error: undefined,
    busy: false,
  },
  clinic: {},
  clinicView: {
    clinics: [],
    loaded: false,
  },
};

export default function clinic(state = initialState, action) {
  let clinicCopy;
  switch (action.type) {
    case types.UPDATE_CLINIC:
      clinicCopy = Object.assign({}, state.clinic, action.clinicData);
      return Object.assign({}, state, {
        clinicUpdate: { complete: false, error: undefined, busy: true },
        clinic: clinicCopy,
      });
    case types.UPDATE_CLINIC_SUCCESS:
      return Object.assign({}, state, {
        clinicUpdate: { complete: true, error: undefined, busy: false },
      });
    case types.UPDATE_CLINIC_ERROR:
      return Object.assign({}, state, {
        clinicUpdate: { complete: false, error: action.error, busy: false },
      });

    case types.GET_CLINIC:
      if (action.userId) return Object.assign({}, state, { clinicView: { loaded: false } });
      return Object.assign({}, state, {});
    case types.GET_CLINIC_SUCCESS:
      clinicCopy = Object.assign({}, action.clinics);
      clinicCopy.loaded = true;
      if (!action.userId) return Object.assign({}, state, { clinic: clinicCopy });
      return Object.assign({}, state, { clinicView: { clinics: action.clinics, loaded: true } });
    case types.GET_CLINIC_ERROR:
      return Object.assign({}, state, {});

    case types.UPDATE_REFERRALS:
      clinicCopy = Object.assign({}, state.clinic);
      clinicCopy.referrals = action.referrals;
      return Object.assign({}, state, {
        updateReferrals: { complete: false, error: undefined, busy: true },
        clinic: clinicCopy,
      });
    case types.UPDATE_REFERRALS_SUCCESS:
      return Object.assign({}, state, {
        updateReferrals: { complete: true, error: undefined, busy: false },
      });
    case types.UPDATE_REFERRALS_ERROR:
      return Object.assign({}, state, {
        updateReferrals: { complete: false, error: action.error, busy: false },
      });

    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
