import React from 'react';
import styled from 'styled-components';
import Draggable from 'react-draggable';
import 'react-resizable/css/styles.css';

interface Props {
  name: string;
  index: number;
  dayWidth: number;
  selected?: boolean;
  disabled?: boolean;
  onDrag?: (index: number, diffDays: number) => void;
  onDragStop?: (index: number, diffDays: number) => void;
  offset: number;
  boundLeft?: number;
  boundRight?: number;
}

const ProcedureTimelineVisit: React.FC<Props> = ({
  name,
  index,
  dayWidth,
  selected,
  disabled = false,
  onDrag,
  onDragStop,
  offset,
  boundLeft,
  boundRight,
}) => {
  return (
    <Draggable
      axis="x"
      bounds={{ left: boundLeft, right: boundRight }}
      handle=".handle"
      onDrag={(_e, data) => {
        onDrag?.(index, Math.floor((data.lastX + data.deltaX) / dayWidth) - offset);
      }}
      onStop={(_e, data) => {
        onDragStop?.(index, Math.floor((data.lastX + data.deltaX) / dayWidth) - offset);
      }}
      position={{ x: offset * dayWidth, y: 0 }}
      disabled={disabled}
    >
      <VisitWrapper>
        <VisitTitle>{name.length > 20 ? name.substring(0, 20) + '...' : name}</VisitTitle>
        <VisitDot disabled={disabled} selected={selected} className="handle">
          {index + 1}
        </VisitDot>
      </VisitWrapper>
    </Draggable>
  );
};

const VisitWrapper = styled.div`
  position: absolute;
  width: 50px;
  margin-left: -25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const VisitTitle = styled.div`
  background: #edeef7;
  font-size: 1rem;
  white-space: nowrap;
`;

const VisitDot = styled.div<{ disabled: boolean; selected?: boolean }>`
  position: absolute;
  top: 25px;
  background-color: var(--color-purple);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  ${(props) => !props.disabled && 'cursor: grabbing;'}
  transition: box-shadow 0.3s ease;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  &:hover {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }
  ${(props) =>
    props.selected &&
    `
  &:after {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    content: '';
    border: 3px solid #edeef7;
  }
  `}
`;

export default ProcedureTimelineVisit;
