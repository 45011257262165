import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import Header from '../../components/header/Header';
import styled, { ThemeProvider } from 'styled-components';
import { styledTheme } from '../../common/themes';
import { Section, SectionInner } from '../../components/Shared/Section';
import { PanelWrapper, Panel } from '../../components/Shared/Panel';
import { Table, TableHead, TableBody, TH, TR, TD } from '../../components/Shared/Table';
import { Button } from '../../components/Shared/Buttons';
import { H2, H3 } from '../../components/Shared/Headings';
import { CreateStudyModal } from '../../components/Studies/CreateStudyModal';
import { EditStudyModal } from '../../components/Studies/EditStudyModal';
import Spinner from '../../components/Shared/Loaders/Spinner';

import {
  getStudyGroups,
  exportStudy,
  leaveStudy,
  acceptStudy,
} from '../../redux/slices/studyGroupSlice';
import { showConfirmation } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router';
import { ICountry } from '../../types/country';
import { IStudyGroup, IStudyMemeber } from '../../types/studyGroups';

const StudyPage = () => {
  const intl = useIntl();
  const user = useAppSelector((state) => state.user.user);
  const history = useHistory();
  const studyGroups = useAppSelector((state) => state.studyGroup.studyGroups);
  const getStudyGroupsStatus = useAppSelector((state) => state.studyGroup.getStudyGroupsStatus);

  const [createStudy, createStudySet] = useState(false);
  const [editStudy, editStudySet] = useState<IStudyGroup | undefined>(undefined);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStudyGroups(false));
  }, []);

  const myStudies = studyGroups.filter((study: IStudyGroup) =>
    study.members.some(
      (x: { userId: string; pending?: boolean }) => x.userId === user.id && !x.pending
    )
  );

  const invites = studyGroups.filter((study: IStudyGroup) =>
    study.members.some(
      (x: { userId: string; pending?: boolean }) => x.userId === user.id && x.pending
    )
  );

  return (
    <StyledStydyPage>
      <ThemeProvider theme={styledTheme}>
        <Header
          isTogglerHidden
          title={intl.formatMessage({ id: 'study.Header', defaultMessage: 'STUDIES' })}
          leftButtons={[
            {
              label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
              icon: 'back',
              action: () => history.goBack(),
            },
          ]}
          rightButtons={[
            {
              label: intl.formatMessage({ id: 'study.addNew', defaultMessage: 'Add new' }),
              icon: 'add',
              action: () => createStudySet(!createStudy),
            },
          ]}
        />
        <Section>
          <SectionInner>
            <H2>
              <FormattedMessage id="study.myStudies" defaultMessage="My Studies" />
            </H2>
            <PanelWrapper>
              <Panel full filled className="studyGroupWrapper">
                {myStudies.length > 0 ? (
                  myStudies.map((studyGroup: IStudyGroup, index: number) => (
                    <StudyInformation studyGroup={studyGroup} key={index} onEdit={editStudySet} />
                  ))
                ) : getStudyGroupsStatus.busy ? (
                  <Spinner purple />
                ) : (
                  <FormattedMessage
                    id="study.noStudies"
                    defaultMessage="No studies, click Add new to create your first."
                  />
                )}
              </Panel>
            </PanelWrapper>
            {invites.length > 0 && (
              <>
                <H2>
                  <FormattedMessage id="study.invites" defaultMessage="Study Invites" />
                </H2>
                <PanelWrapper>
                  <Panel full filled>
                    {invites.map((studyGroup: IStudyGroup, index: number) => (
                      <StudyInvitation studyGroup={studyGroup} key={index} />
                    ))}
                  </Panel>
                </PanelWrapper>
              </>
            )}
            <StudyDescription>
              <H3>
                <FormattedMessage id="study.whatIsAStudy" defaultMessage="What is a Study?" />
              </H3>
              <p>
                <FormattedMessage
                  id="study.description1"
                  defaultMessage="Study groups is a way of collecting and analysing implant cases for a specific purpose, where you can tag patients implants to be included in a study."
                />
              </p>
              <p>
                <FormattedMessage
                  id="study.description2"
                  defaultMessage="A Study could either be private where you only can access your own tagged implant cases. But you can also create a shared study group where you can invite other clinicians to contribute to a study. In a shared study you can access and export each other’s tagged implant cases."
                />
              </p>
            </StudyDescription>
          </SectionInner>
        </Section>
        {createStudy && <CreateStudyModal onDismiss={() => createStudySet(false)} />}
        {editStudy && (
          <EditStudyModal onDismiss={() => editStudySet(undefined)} study={editStudy} />
        )}
      </ThemeProvider>
    </StyledStydyPage>
  );
};

const StudyInformation: FC<{
  studyGroup: IStudyGroup;
  onEdit?: (study: IStudyGroup) => void;
}> = ({ studyGroup, onEdit }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [exporting, setExporting] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);

  const user = useAppSelector((state) => state.user.user);
  const isAdmin = studyGroup.members.some(
    (x: { userId: string; userPermission: string }) =>
      x.userId === user.id && x.userPermission === 'Admin'
  );
  const countries = useAppSelector((state) => state.referenceData.countries);
  const [expanded, setExpanded] = useState<boolean>(false);

  const getMemberRow = (member: IStudyMemeber) => {
    const country = countries.find((c: ICountry) => c.key === member.country);
    return (
      <div className="mobileMemberRow" key={member.userId}>
        <div>
          <span>
            <b>{intl.formatMessage({ id: 'general.Name', defaultMessage: 'Name' })}: </b>
          </span>
          <span>
            {' '}
            {member.firstName} {member.surname}
          </span>
        </div>
        <div>
          <span>
            <b>{intl.formatMessage({ id: 'general.Email', defaultMessage: 'Email' })}: </b>
          </span>
          <span>{member.email}</span>
        </div>

        <div>
          <span>
            <b>{intl.formatMessage({ id: 'general.Country', defaultMessage: 'Country' })}: </b>
          </span>
          <span>{country?.value}</span>
        </div>

        <div>
          <span>
            <b>{intl.formatMessage({ id: 'general.Implant', defaultMessage: 'Implants' })}: </b>
          </span>
          <span>{member.numberOfImplants}</span>
        </div>

        <div>
          <span>
            <b>{intl.formatMessage({ id: 'general.role', defaultMessage: 'Role' })}: </b>
          </span>
          <span>{member.userPermission}</span>
        </div>

        <div>
          {' '}
          {member.pending && <FormattedMessage id="general.pending" defaultMessage="Pending" />}
        </div>
      </div>
    );
  };

  const members = studyGroup.members.map((member: IStudyMemeber, index: number) => {
    const country = countries.find((c: ICountry) => c.key === member.country);
    return (
      <TR key={index}>
        <TD>
          {member.firstName} {member.surname}
        </TD>
        <TD>{member.email}</TD>
        <TD>{country?.value}</TD>
        <TD>{member.numberOfImplants}</TD>
        <TD>{member.userPermission}</TD>
        <TD>
          {member.pending && <FormattedMessage id="general.pending" defaultMessage="Pending" />}
        </TD>
      </TR>
    );
  });
  return (
    <StudyGroupWrapper>
      <StudyGroupHeader onClick={() => setExpanded(!expanded)}>
        <span className="name">{studyGroup.name}</span> - <span>{studyGroup.description}</span>
        <div>
          {intl.formatMessage({ id: 'general.members', defaultMessage: 'Members' })} :{' '}
          {members.length},{' '}
          {intl.formatMessage({ id: 'general.implants', defaultMessage: 'Implants' })}:{' '}
          {studyGroup.numberOfImplants}{' '}
        </div>
        <div className="button">
          <i className="osstell-icons">more</i>
        </div>
      </StudyGroupHeader>
      {expanded && (
        <div className="expandedContaienr">
          <div className="mobileTable">
            {studyGroup.members &&
              studyGroup.members.map((member: IStudyMemeber) => getMemberRow(member))}
          </div>
          {console.log(studyGroup.members)}
          <Table className="table">
            <TableHead>
              <TR>
                <TH>{intl.formatMessage({ id: 'general.Name', defaultMessage: 'Name' })}</TH>
                <TH>{intl.formatMessage({ id: 'general.Email', defaultMessage: 'Email' })}</TH>
                <TH>{intl.formatMessage({ id: 'general.Country', defaultMessage: 'Country' })}</TH>
                <TH>{intl.formatMessage({ id: 'general.Implant', defaultMessage: 'Implants' })}</TH>
                <TH>{intl.formatMessage({ id: 'general.role', defaultMessage: 'Role' })}</TH>
                <TH></TH>
              </TR>
            </TableHead>
            <TableBody>{members}</TableBody>
          </Table>
          <div className="expandedButtonContainer">
            {isAdmin ? (
              <Button
                value={intl.formatMessage({
                  id: 'general.edit',
                  defaultMessage: 'Edit',
                })}
                primary
                onClick={() => onEdit?.(studyGroup)}
              />
            ) : (
              <Button
                value={intl.formatMessage({
                  id: 'general.leave',
                  defaultMessage: 'Leave',
                })}
                onClick={() =>
                  dispatch(
                    showConfirmation(
                      intl.formatMessage({
                        id: 'study.leaveStudy',
                        defaultMessage: 'Are you sure you want to leave this study',
                      }),
                      <></>,
                      () => {
                        setLeaving(true);
                        dispatch(leaveStudy(studyGroup.id)).then(() => {
                          setLeaving(false);
                          dispatch(getStudyGroups(false));
                        });
                      }
                    )
                  )
                }
                loading={leaving}
                disabled={leaving}
              />
            )}
            <Button
              value={intl.formatMessage({
                id: 'general.export',
                defaultMessage: 'Export',
              })}
              primary
              onClick={() => {
                setExporting(true);
                dispatch(exportStudy(studyGroup.id)).then(() => setExporting(false));
              }}
              loading={exporting}
              disabled={exporting}
            />
          </div>
        </div>
      )}
    </StudyGroupWrapper>
  );
};

const StudyInvitation: React.FC<{
  studyGroup: IStudyGroup;
}> = ({ studyGroup }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [accepting, setAccepting] = useState<boolean>(false);
  const [leaving, setLeaving] = useState<boolean>(false);

  return (
    <StudyGroupWrapper>
      <StudyGroupHeader>
        <span className="name">{studyGroup.name}</span> - <span>{studyGroup.description}</span>
        <div>
          {intl.formatMessage({ id: 'general.members', defaultMessage: 'Members' })} :{' '}
          {studyGroup.members.length},{' '}
          {intl.formatMessage({ id: 'general.implants', defaultMessage: 'Implants' })}:{' '}
          {studyGroup.numberOfImplants}{' '}
        </div>
        <div className="button">
          <Button
            value={intl.formatMessage({
              id: 'general.decline',
              defaultMessage: 'Decline',
            })}
            onClick={() =>
              dispatch(
                showConfirmation(
                  intl.formatMessage({
                    id: 'study.leaveStudy',
                    defaultMessage: 'Are you sure you want to leave this study',
                  }),
                  <></>,
                  () => {
                    setLeaving(true);
                    dispatch(leaveStudy(studyGroup.id)).then(() => {
                      setLeaving(false);
                      dispatch(getStudyGroups(false));
                    });
                  }
                )
              )
            }
            loading={leaving}
            disabled={leaving}
          />
          <Button
            value={intl.formatMessage({
              id: 'general.accept',
              defaultMessage: 'Accept',
            })}
            primary
            onClick={() => {
              setAccepting(true);
              dispatch(acceptStudy(studyGroup.id)).then(() => {
                setAccepting(false);
                dispatch(getStudyGroups(false));
              });
            }}
            loading={accepting}
            disabled={accepting}
          />
        </div>
      </StudyGroupHeader>
    </StudyGroupWrapper>
  );
};

const StyledStydyPage = styled.div`
  @media (max-width: 500px) {
    .studyGroupWrapper {
      padding: 0px 5px;
    }
  }
`;

const StudyGroupWrapper = styled.div`
  position: relative;
  font-weight: 400;
  padding: 0px 10px;
  &:not(:first-child) {
    margin-top: 1rem;
    border-top: 1px solid var(--color-subtlegrey);
  }

  .mobileTable {
    display: none;
  }

  .mobileMemberRow {
    border-radius: 5px;
    background-color: #edeef7;
    padding: 5px;
    margin-bottom: 5px;
    width: 90%;
  }

  @media (max-width: 750px) {
    .mobileTable {
      display: block;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .table {
      display: none;
    }
    .expandedButtonContainer {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      padding-bottom: 20px;
      button {
        margin: 0px;
      }

      button:last-child {
        margin-top: 10px;
      }
    }
  }
`;

const StudyGroupHeader = styled.div`
  padding: 1rem 0;
  cursor: pointer;
  .name {
    font-size: 1rem;
    font-weight: 700;
  }
  .button {
    position: absolute;
    font-size: 1.8rem;
    right: 1rem;
    top: 1rem;
  }
`;

const StudyDescription = styled.div`
  margin-top: 3rem;
  width: 93%;
  @media (max-width: 500px) {
    margin: 3rem auto;
    h3,
    p {
      text-align: center;
    }
  }
`;

export default StudyPage;
