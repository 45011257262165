import React from 'react';
import { transformToothIdToUNS } from '../../../../../utils/transformations';
import styled from 'styled-components';
import { useAppSelector } from '../../../../../utils/hooks';

interface Props {
  id: number;
  hideBoundaries?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, id: number) => void;
}

export const ZoneWidthsUpper = [58, 62, 66, 43, 46, 47, 44, 55, 55, 44, 46, 45, 43, 67, 62, 57];
export const ZoneWidthsLower = [78, 71, 65, 45, 42, 41, 41, 40, 42, 41, 41, 42, 45, 68, 71, 71];
export const InteractionZoneWidths = [...ZoneWidthsUpper, ...ZoneWidthsLower];

const ToothInteractionZone: React.FC<Props> = ({ id, onClick, hideBoundaries = false }) => {
  const hasTooth = useAppSelector((state) => state.consultation.workspace.present.teethState)[id];
  const bone = useAppSelector((state) => state.consultation.workspace.present.boneState)[id];

  return (
    <ToothZone
      data-testid={'interactionZone'}
      index={id}
      hideBoundaries={hideBoundaries}
      onClick={(e) => onClick?.(e, id)}
    >
      {!hideBoundaries && (
        <>
          <p>
            {transformToothIdToUNS(id)} {hasTooth ? '🦷' : ''}
          </p>
          <p>d: {bone.depth}</p>
          <p>g: {bone.graft ? 'yes' : 'no'}</p>
        </>
      )}
    </ToothZone>
  );
};

export const zoneScale = 1;
const ToothZone = styled.div<{ index: number; hideBoundaries: boolean }>`
  ${(props) => `width: ${InteractionZoneWidths[props.index] * zoneScale}px;`}
  color: white;
  border: 1px solid peachpuff;
  box-sizing: border-box;
  opacity: ${(props) => (props.hideBoundaries ? 0 : 1)};
  cursor: crosshair;
`;

export default ToothInteractionZone;
