import { Group } from 'react-konva';
import * as React from 'react';
import TeethData from '../../../../../../../redux/slices/data-structures/teethPositionStructure';
import CanvasTeethPosition from './TeethCanvasTeethPosition';

export function TeethCanvasTeethLayer() {
  return (
    <Group
      id={'teeth'}
      x={-20}
      y={212}
      clip={{
        x: 0,
        y: -400,
        width: 1000,
        height: 800,
      }}
    >
      {TeethData.map((teeth, index) => {
        const upperRow = index < 16;

        return teeth ? (
          <CanvasTeethPosition
            key={'teeth' + index}
            x={teeth.offset}
            upperRow={upperRow}
            teeth={teeth}
          />
        ) : null;
      })}
    </Group>
  );
}
