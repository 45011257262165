import { FC } from 'react';
import { H1 } from '../Shared/Headings';
import { IUser } from '../../types/user';
import { IClinic } from '../../types/clinic';
import { IImplant } from '../../types/implant';
import { osstellLogo } from '../../constants/ostellLogo';
import { useAppSelector } from '../../utils/hooks';
import { ICurrentPatient } from '../../types/patient';
import { IPatientRiskFactors } from '../../types/patientRiskFactors';
import { FormattedMessage, useIntl } from 'react-intl';
import RenderImplantsReport from './RenderImplantsReport';
import beaconMeasurement from '../../shared/images/beaconMeasurement.png';
import isqScaleImage from '../../shared/images/isqScale.png';
import ExportSection from './ExportSection';
import EditableItem from '../Shared/EditableItem/EditableItem';
import SvgDoneliga from '../../shared/icons/osstell-regular/DoneLiga';
import styles from './PatientExport.module.scss';

interface Props {
  user: IUser;
  clinic: IClinic;
  patient: ICurrentPatient;
  patientImplants: IImplant[];
  edit: boolean;
}

const ReferralReport: FC<Props> = ({ user, clinic, patient, patientImplants, edit }) => {
  const intl = useIntl();
  const {
    studyImplantData,
    patientRiskFactors,
    dentalNotifications,
    implantData: implants,
  } = useAppSelector((state) => state.referenceData);

  const referringClinician = patient.referralId
    ? (clinic.referrals ?? []).filter((referral) => referral.id === patient.referralId)[0]
    : undefined;

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const getRiskFactors = () => {
    if (!patient.riskFactors) return;
    const translatedRiskFactors = [];
    patient.riskFactors.forEach((riskFactor) => {
      const risk = patientRiskFactors.find((x: IPatientRiskFactors) => x.key === riskFactor);
      if (risk)
        translatedRiskFactors.push(
          intl
            .formatMessage({
              id: ''.concat('referenceData.', risk.key),
              defaultMessage: risk.value,
            })
            .toLowerCase()
        );
    });
    if (patient.otherRisks) translatedRiskFactors.push(patient.otherRisks);
    if (translatedRiskFactors.length === 0) return '-';
    translatedRiskFactors[0] = capitalizeFirstLetter(translatedRiskFactors[0]);
    return translatedRiskFactors.join(', ');
  };

  const renderClinics = () => {
    return (
      <div className={styles.fromToWrapper}>
        <ExportSection>
          <div className={styles.fromTo}>
            <FormattedMessage id="patientexport.from" defaultMessage="FROM" />
          </div>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'patientexport.clinic',
              defaultMessage: 'Clinic',
            })}
            :
          </span>
          <EditableItem noMarginTop type="text" edit={edit} value={clinic.name} />
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'general.clinician',
              defaultMessage: 'Clinician',
            })}
            :
          </span>
          <EditableItem
            noMarginTop
            type="text"
            edit={edit}
            value={user.title + ' ' + ' ' + user.firstName + ' ' + user.surname}
          />
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'general.address',
              defaultMessage: 'Address',
            })}
            :
          </span>
          <EditableItem
            noMarginTop
            type="text"
            edit={edit}
            value={`${clinic?.address?.street}, ${clinic?.address?.city} ${clinic?.address?.postcode}`}
          />
        </ExportSection>
        <ExportSection>
          <div className={styles.fromTo}>
            <FormattedMessage id="patientexport.to" defaultMessage="TO" />
          </div>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'patientexport.clinic',
              defaultMessage: 'Clinic',
            })}
            :
          </span>
          <EditableItem noMarginTop type="text" edit={edit} value={referringClinician?.clinic} />
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'general.clinician',
              defaultMessage: 'Clinician',
            })}
            :
          </span>
          <EditableItem noMarginTop type="text" edit={edit} value={referringClinician?.clinician} />
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'general.address',
              defaultMessage: 'Address',
            })}
            :
          </span>
          <EditableItem noMarginTop type="text" edit={edit} value={referringClinician?.address} />
        </ExportSection>
      </div>
    );
  };

  const riskFactors = getRiskFactors();

  return (
    <div className={styles.report} id="pdf_report">
      <div>
        <H1 className={styles.header}>
          <FormattedMessage id="patientexport.heading" defaultMessage="Implant stability report" />
        </H1>
      </div>
      {renderClinics()}
      <ExportSection className={styles.section}>
        <H1 className={styles.sectionHeader}>
          <FormattedMessage id="patientexport.patient" defaultMessage="Patient" />
        </H1>
        <div className={styles.patientDataRow}>
          <div className={styles.dataItem}>
            <EditableItem type="text" edit={edit} value={patient.patientIdentifier} />
          </div>
          <div className={styles.dataItem}>
            <FormattedMessage id="patientexport.riskfactors" defaultMessage="Risk factors" />:{' '}
            <EditableItem type="text" edit={edit} value={riskFactors} />
          </div>
        </div>
      </ExportSection>
      {patientImplants.map((implant, idx) => (
        <RenderImplantsReport
          key={idx}
          edit={edit}
          implant={implant}
          implants={implants}
          studyImplantData={studyImplantData}
          dentalNotifications={dentalNotifications}
        />
      ))}
      <div className={styles.footer} style={{ width: '100%' }} id="footer">
        <img
          style={{ maxWidth: '200px', margin: '0.5rem auto', display: 'block' }}
          src={osstellLogo}
          alt="logo"
        />
      </div>
      <div className={styles.infoPage} id="implantSection">
        <h1 className={styles.mainHeader}>
          {intl.formatMessage({
            id: 'patientexport.pdf.header1',
            defaultMessage: 'Why measure Osstell ISQ',
          })}
        </h1>
        <p className={styles.infoText1}>
          {intl.formatMessage({
            id: 'patientexport.pdf.text1',
            defaultMessage:
              'Osstell helps you to optimize your patients’ time to teeth by objectively and non-invasively determining implant stability. It helps you:',
          })}
        </p>
        <div className={styles.checkContainer}>
          <div>
            <SvgDoneliga fill="#5ADB96" />
          </div>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.check1',
              defaultMessage:
                'Determine if an implant is stable enough to support the final restoration',
            })}
          </span>
        </div>
        <div className={styles.checkContainer}>
          <div>
            <SvgDoneliga fill="#5ADB96" />
          </div>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.check2',
              defaultMessage:
                'Identify situations in which it is best to unload a provisional and delay a final restoration',
            })}
          </span>
        </div>
        <div className={styles.checkContainer}>
          <div>
            <SvgDoneliga fill="#5ADB96" />
          </div>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.check3',
              defaultMessage: 'Shorten treatment times',
            })}
          </span>
        </div>
        <div className={styles.checkContainer}>
          <div>
            <SvgDoneliga fill="#5ADB96" />
          </div>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.check4',
              defaultMessage: 'Improve case documentation and quality assurance',
            })}
          </span>
        </div>
        <div className={styles.checkContainer}>
          <div>
            <SvgDoneliga fill="#5ADB96" />
          </div>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.check5',
              defaultMessage: 'Optimize communication and increase trust with colleagues',
            })}
          </span>
        </div>
        <div className={styles.textAndImgContainer}>
          <p>
            {intl.formatMessage({
              id: 'patientexport.pdf.text2',
              defaultMessage: `Osstell ISQ, or Implant Stability Quotient, is a scale from 1 to 100 and is a measure of the stability of an implant using Osstell’s proprietary and evidencedbased Resonance Frequency Analysis (RFA) technology, based on more than 1000 scientific studies. The result is presented as an Osstell ISQ value of 1–100. The higher the Osstell ISQ, the more stable the implant. The values and stability indications are based on scientific data. For references and more information on the Osstell ISQ scale: osstell.com/clinical-guidelines. `,
            })}
          </p>
          <div>
            <img src={beaconMeasurement} />
          </div>
        </div>
        <h1 className={styles.secondHeader}>
          {intl.formatMessage({
            id: 'patientexport.pdf.header2',
            defaultMessage: 'The Osstell ISQ scale',
          })}
        </h1>
        <div className={styles.isqScaleContainer}>
          <img src={isqScaleImage} alt="isqScale" />
        </div>

        <span className={styles.scientificReferences}>
          {intl.formatMessage({
            id: 'patientexport.pdf.header3',
            defaultMessage: 'Scientific References:',
          })}
        </span>
        <ol>
          <li>Sennerby L Prof. Implantologie 2013;21(1):21-33</li>
          <li>
            Kokovic V, Jung R, Feloutzis A, Todorovic V, Jurisic M, Hämmerle C. Clinical Oral
            Implants Research, 00, 2013, 1-6
          </li>
          <li>
            Michael M. Bornstein, Dr. med. dent.; Christopher N. Hart, DMD; Sandro A. Halbritter,
            Dr. med. dent.; Dean Morton, BDS, MS; Daniel Buser, Prof. Dr. med. dent. Clin Implant
            Dent Relat Res 2009
          </li>
          <li>
            Serge Baltayan, Joan Pi-Anfruns, Tara Aghaloo, Peter Moy J Oral Maxillofac Surg
            74:1145-1152, 2016
          </li>
          <li>
            Pär-Olov Östman DDS, PhD, MD, Private practitioner, Falun- and Biomaterial group,
            Sahlgrenska. Academy, Gothenburg, Clinical Implant Dentistry and Related Research,
            Volume 7, Supplement 1, 2005
          </li>
          <li>
            Daniel Rodrigo, Luis Aracil, Conchita Martin, Mariano Sanz. Clin. Oral Impl. Res. 21,
            2010; 255-261
          </li>
          <li>
            Pagliani L, Sennerby L, Petersson A, Verrocchi D, Volpe S & Andersson P. Journal of Oral
            Rehabilitation, 2012
          </li>
          <li>
            Paolo Trisi PhD, Teocrito Carlesi DDS, Marco Colagiovanni DDS, Giorgio Perfetti MD, DDS.
            Journal of Osteology and Biomaterials, Volume 1, Number 3, 2010
          </li>
          <li>
            Stefan Paul Hicklin, Esther Schneebeli, Vivianne Chappuis, Simone Francesco Marco
            Janner, Daniel Buser, Urs Brägger Clin. Oral Impl. Res 00, 2015; 1-9
          </li>
        </ol>
        <div className={styles.contact}>
          <span>
            {intl.formatMessage({
              id: 'patientexport.pdf.contactUs',
              defaultMessage:
                'Contact us on osstell.com/contact to incorporate Osstell ISQ in your practice',
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ReferralReport;
