import { FC } from 'react';
import { H1, H2 } from '../../components/Shared/Headings';
import { FormattedMessage, IntlShape } from 'react-intl';
import {
  IClinicInsights,
  IClinicOverview,
  IFilterStats,
  IFilteredData,
} from '../../types/clinicOverview';
import KeyPerformanceIndicators from '../../components/KeyPerformanceIndicators/KeyPerformanceIndicators';
import InsightFilterBar from '../../components/KeyPerformanceIndicators/InsightFilterBar';
import ChartPanel from '../../components/Charts/ChartPanel';
import styles from './ClinicPage.module.scss';

interface Props {
  intl: IntlShape;
  filterStats: IFilterStats | null;
  filteredData: IFilteredData;
  clinicInsights: IClinicInsights;
  filterChanged: (event: IClinicOverview) => void;
}

const ClinicTabPanel: FC<Props> = ({
  intl,
  filterStats,
  filteredData,
  clinicInsights,
  filterChanged,
}) => {
  const getStatus = (type: string) => {
    if (!filterStats) return '';
    const { implants, measurements, patients } = filterStats;
    if (type === 'implants') {
      return (
        <FormattedMessage
          id="clinicPage.showingImplants"
          defaultMessage="Showing {percent}% of your data ({filtered} implants)"
          values={{ percent: implants.percent, filtered: implants.filtered }}
        />
      );
    }
    if (type === 'measurements') {
      return (
        <FormattedMessage
          id="clinicPage.showingMeasurements"
          defaultMessage="Showing {percent}% of your data ({filtered} measurements)"
          values={{ percent: measurements.percent, filtered: measurements.filtered }}
        />
      );
    }
    if (type === 'patients') {
      return (
        <FormattedMessage
          id="clinicPage.showingPatients"
          defaultMessage="Showing {percent}% of your data ({filtered} patients)"
          values={{ percent: patients.percent, filtered: patients.filtered }}
        />
      );
    }
    return '';
  };

  const renderHealingTimeCharts = () => {
    return (
      <div className={styles.container}>
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.healingTimeProtocolTitle',
            defaultMessage: 'Loading protocol',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.healingTimeProtocolLegend',
            defaultMessage: 'Healing time (days)',
          })}
          data={filteredData.healingTimeProtocol}
          footer={getStatus('implants')}
          unit=" days"
        />
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.healingTimeBrandTitle',
            defaultMessage: 'Implant brands (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.healingTimeBrandLegend',
            defaultMessage: 'Healing time (days)',
          })}
          data={filteredData.healingTimeBrand}
          footer={getStatus('implants')}
          unit=" days"
        />
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.healingTimeRiskFactorsTitle',
            defaultMessage: 'Healing time for different risk attributes (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.healingTimeRiskFactorsLegend',
            defaultMessage: 'Healing time (days)',
          })}
          data={filteredData.healingTimeRisk}
          footer={getStatus('implants')}
          unit=" days"
        />
      </div>
    );
  };

  const renderFailureCharts = () => {
    return (
      <div className={styles.container}>
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.failureProtocolTitle',
            defaultMessage: 'Loading protocol',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.failureProtocolLegend',
            defaultMessage: '% failures',
          })}
          data={filteredData.failureProtocol}
          footer={getStatus('implants')}
          unit="%"
        />
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.failureBrandTitle',
            defaultMessage: 'Implant brands (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.failureBrandLegend',
            defaultMessage: '% failures',
          })}
          data={filteredData.failureBrand}
          footer={getStatus('implants')}
          unit="%"
        />
        <ChartPanel
          type="bar"
          title={intl.formatMessage({
            id: 'clinicPage.failureRiskFactorsTitle',
            defaultMessage: 'Failure for different risk attributes (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.failureRiskFactorsLegend',
            defaultMessage: '% failures',
          })}
          data={filteredData.failureRisk}
          footer={getStatus('implants')}
          unit="%"
        />
      </div>
    );
  };

  const renderISQCharts = () => {
    return (
      <div className={styles.container}>
        <ChartPanel
          title={intl.formatMessage({
            id: 'clinicPage.isqPlacementTitle',
            defaultMessage: 'ISQ value at placement',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.isqLegend',
            defaultMessage: 'ISQ Value',
          })}
          data={filteredData.isqPlacement}
          footer={getStatus('measurements')}
          type="candle-stick"
        />
        <ChartPanel
          title={intl.formatMessage({
            id: 'clinicPage.isqReadyTitle',
            defaultMessage: 'ISQ value at final restoration',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.isqLegend',
            defaultMessage: 'ISQ Value',
          })}
          data={filteredData.isqReady}
          footer={getStatus('measurements')}
          type="candle-stick"
        />
      </div>
    );
  };

  const renderDistributionCharts = () => {
    return (
      <div className={styles.container}>
        <ChartPanel
          title={intl.formatMessage({
            id: 'clinicPage.distributionProtocolTitle',
            defaultMessage: 'Different loading protocols',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.distributionProtocolLegend',
            defaultMessage: '% of Cases',
          })}
          data={filteredData.distributionProtocol}
          footer={getStatus('implants')}
          type="bar"
          layout="horizontal"
          unit="%"
        />
        <ChartPanel
          title={intl.formatMessage({
            id: 'clinicPage.distributionBrandTitle',
            defaultMessage: 'Implant brands (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.distributionBrandLegend',
            defaultMessage: '% of Cases',
          })}
          data={filteredData.distributionBrand}
          footer={getStatus('implants')}
          type="bar"
          layout="horizontal"
          unit="%"
        />
        <ChartPanel
          title={intl.formatMessage({
            id: 'clinicPage.distributionRiskTitle',
            defaultMessage: 'Patients with risk attributes (top 3 used)',
          })}
          yLegend={intl.formatMessage({
            id: 'clinicPage.distributionRiskLegend',
            defaultMessage: '% of Cases',
          })}
          data={filteredData.distributionRisk}
          footer={getStatus('patients')}
          type="bar"
          layout="horizontal"
          unit="%"
        />
      </div>
    );
  };

  return (
    <>
      <H1 className={styles.pageHeading}>
        {' '}
        <FormattedMessage id="clinicPage.clinicOverview" defaultMessage="Clinic overview" />
      </H1>
      <KeyPerformanceIndicators />
      <H2 className={styles.pageHeadingTwo}>
        {' '}
        <FormattedMessage id="clinicPage.filterInsights" defaultMessage="Filter insights" />
      </H2>
      <div className={styles.stickyFilter}>
        <InsightFilterBar rawData={clinicInsights} onChange={filterChanged} />
      </div>

      <div className={styles.diagramSection}>
        <H2 className={styles.pageHeadingTwo}>
          <FormattedMessage id="clinicPage.healingTime" defaultMessage="Healing time" />
        </H2>
        <p className={styles.sectionDescription}>
          <FormattedMessage
            id="clinicPage.healingTimeDescription"
            defaultMessage="Diagrams that show the relationship between factors that affect healing time."
          />
        </p>
        {renderHealingTimeCharts()}
      </div>

      <div className={styles.diagramSection}>
        <H2 className={styles.pageHeadingTwo}>
          <FormattedMessage id="clinicPage.failures" defaultMessage="Failures" />
        </H2>
        <p className={styles.sectionDescription}>
          <FormattedMessage
            id="clinicPage.failuresDescription"
            defaultMessage="Diagrams that show the relationship between different factors in the failure of implants."
          />
        </p>
        {renderFailureCharts()}
      </div>

      <div className={styles.diagramSection}>
        <H2 className={styles.pageHeadingTwo}>ISQ</H2>
        <p className={styles.sectionDescription}>
          <FormattedMessage
            id="clinicPage.isqDescription"
            defaultMessage="Diagrams that show the ISQ values for different situations."
          />
        </p>
        {renderISQCharts()}
      </div>

      <div className={styles.diagramSection}>
        <H2 className={styles.pageHeadingTwo}>
          <FormattedMessage id="clinicPage.distribution" defaultMessage="Distribution" />
        </H2>
        <p className={styles.sectionDescription}>
          <FormattedMessage
            id="clinicPage.distributionDescription"
            defaultMessage="Diagrams that show the relationship between different factors of implants."
          />
        </p>
        {renderDistributionCharts()}
      </div>
    </>
  );
};

export default ClinicTabPanel;
