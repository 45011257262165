import React, { useEffect, useRef } from 'react';

//@ts-ignore
import styles from './ScaleContentToFit.module.scss';

interface Props {
  className?: string;
  minSize?: number;
  onResize?: () => void;
}

const ScaleContentToFit: React.FC<Props> = ({ className, children, minSize = 0.1, onResize }) => {
  const ref = useRef<HTMLDivElement>(null);

  const resize = () => {
    if (!ref.current) return;

    const outerCss = getComputedStyle(ref.current);
    const maxWidth = Number.parseFloat(outerCss.width);
    // const maxHeight = Number.parseFloat(outerCss.height);

    const innerEl: HTMLDivElement = ref.current.children[0] as HTMLDivElement;
    const innerCss = getComputedStyle(innerEl);
    const width = Number.parseFloat(innerCss.width);
    // const height = Number.parseFloat(innerCss.height);

    let scale = 1;

    if (maxWidth >= width) {
      innerEl.style.transform = 'scale(1)';
    } else {
      scale = Math.min(maxWidth / width);
      if (scale < minSize) {
        scale = minSize;
      }

      innerEl.style.transform = `scale(${scale})`;
    }

    if (onResize) requestAnimationFrame(onResize);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      resize();
    });

    if (ref.current) {
      resizeObserver.observe(ref.current);
    }

    resize();

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div ref={ref} className={`${styles.outer} ${className ?? ''}`}>
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default ScaleContentToFit;
