import { FC } from 'react';
import { H1 } from '../Shared/Headings';
import { IUser } from '../../types/user';
import { IClinic } from '../../types/clinic';
import { osstellLogo } from '../../constants/ostellLogo';
import { useAppSelector } from '../../utils/hooks';
import { ICurrentPatient } from '../../types/patient';
import { IDentalNotation } from '../../types/dentalNotation';
import { FormattedMessage, useIntl } from 'react-intl';
import { IImplant, IImplantData, IImplantMeasurement } from '../../types/implant';
import { Table, TableHead, TableBody, TH, TR, TD } from '../Shared/Table';
import ExportSection from './ExportSection';
import EditableItem from '../Shared/EditableItem/EditableItem';
import ImplantChart from '../implants/chart/ImplantChart';
import styles from './PatientExport.module.scss';

interface Props {
  user: IUser;
  clinic: IClinic;
  patient: ICurrentPatient;
  patientImplants: IImplant[];
  edit: boolean;
}

const PatientReport: FC<Props> = ({ user, clinic, patient, patientImplants, edit }) => {
  const intl = useIntl();
  const {
    dentalNotifications,
    studyImplantData,
    implantData: implants,
  } = useAppSelector((state) => state.referenceData);

  const renderImplantData = () => {
    const implantsData = patientImplants.map((implant, index) => {
      let implantData = implants.find((data: IImplantData) => data.dataId === implant.dataId);

      if (!implantData) {
        implantData = studyImplantData.find((data: IImplantData) => data.dataId === implant.dataId);
      }

      if (!implantData) {
        implantData = {};
      }

      const { uns, fdi } = dentalNotifications.find(
        (item: IDentalNotation) => item.uns === implant.tooth
      );
      const toothNumber = user.teethNotation === 'UNS' ? uns : fdi;
      return (
        <TR key={index}>
          <TD>{toothNumber}</TD>
          <TD>{implantData.brandName}</TD>
          <TD>{implantData.modelName}</TD>
          <TD>{implantData.length}</TD>
          <TD>{implantData.diameter}</TD>
          <TD>{implantData.platformName}</TD>
          <TD>{implantData.partNumber}</TD>
        </TR>
      );
    });
    return (
      <ExportSection className={styles.section}>
        <div>
          <H1 className={styles.sectionHeader}>
            <FormattedMessage id="patientexport.implantData" defaultMessage="Implant Data" />
          </H1>
          <div className={styles.treatmentSection}>
            <Table className={styles.implantTable}>
              <TableHead>
                <TR>
                  <TH>
                    <FormattedMessage
                      id="patientexport.implantPosition"
                      defaultMessage="Implant position"
                    />
                  </TH>
                  <TH>
                    <FormattedMessage id="patientexport.system" defaultMessage="System" />
                  </TH>
                  <TH>
                    <FormattedMessage id="patientexport.type" defaultMessage="Type" />
                  </TH>
                  <TH>
                    <FormattedMessage id="implant.length" defaultMessage="Length (mm)" />
                  </TH>
                  <TH>
                    <FormattedMessage id="implant.diameter" defaultMessage="Diameter" />
                  </TH>
                  <TH>
                    <FormattedMessage id="implant.platform" defaultMessage="Platform" />
                  </TH>
                  <TH>
                    <FormattedMessage id="patientexport.id" defaultMessage="ID" />
                  </TH>
                </TR>
              </TableHead>
              <TableBody>{implantsData}</TableBody>
            </Table>
          </div>
        </div>
      </ExportSection>
    );
  };

  const sortImplantMeasurements = (measurements: IImplantMeasurement[]) =>
    measurements.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

  const renderImplants = () => {
    return patientImplants.map((implant) => {
      const orderedMeasurements = sortImplantMeasurements([...implant.measurements]);

      const { uns, fdi } = dentalNotifications.find(
        (item: IDentalNotation) => item.uns === implant.tooth
      );

      const toothNumber = user.teethNotation === 'UNS' ? uns : fdi;

      return (
        <div className={styles.implantSection} key={implant.tooth}>
          <ExportSection className={styles.section}>
            <div className={styles.treatmentWrapper}>
              <div>
                <H1 className={styles.sectionHeader}>
                  <FormattedMessage
                    id="patientexport.OsstellISQdata"
                    defaultMessage="Osstell ISQ data"
                  />
                </H1>
                <div className={styles.treatmentSection}>
                  <div style={{ display: 'flex' }}>
                    <FormattedMessage
                      id="patientexport.ImplantPosition"
                      defaultMessage="Implant position"
                    />
                    :&nbsp;
                    <span className={styles.patientData}>{toothNumber}</span>
                  </div>
                  {renderISQList(implant)}
                </div>
              </div>
              <div className={styles.chartWrapper}>
                <ImplantChart
                  noColor
                  measurements={orderedMeasurements}
                  tooth={implant.tooth}
                  excludeAbutment={false}
                  projection={undefined}
                  showCreateMeasurementModal={undefined}
                  showDetails={false}
                />
              </div>
            </div>
          </ExportSection>
        </div>
      );
    });
  };

  const renderISQList = (implant: IImplant) => {
    const { formatDate } = intl;
    const orderedMeasurements = sortImplantMeasurements([...implant.measurements]);

    if (orderedMeasurements.length === 0)
      return (
        <div>
          <FormattedMessage id="patientexport.noMeasurements" defaultMessage="No measurements" />
        </div>
      );

    return orderedMeasurements.map((measurement, index) => {
      let typeText = index === 0 ? 'ISQ* ' : 'ISQ ';
      switch (measurement.type) {
        case 'DuringPlacement':
          typeText = typeText.concat(
            intl.formatMessage({
              id: 'patientexport.isqAtInsertion',
              defaultMessage: 'at implant insertion',
            })
          );
          break;
        case 'DuringFollowup':
          typeText = typeText.concat(
            intl.formatMessage({
              id: 'patientexport.isqAtFollowup',
              defaultMessage: 'at follow up',
            })
          );
          break;
        case 'DuringLoading':
          typeText = typeText.concat(
            intl.formatMessage({
              id: 'patientexport.isqAtPlacement',
              defaultMessage: 'before final restoration',
            })
          );
          break;
        default:
          break;
      }
      const isqValue = <span className={styles.patientData}>{measurement.isq}</span>;
      return (
        <div className={styles.isqList} key={measurement.id}>
          <div style={{ display: 'flex' }}>
            {typeText}:&nbsp;{isqValue}
          </div>
          <div>
            {formatDate(measurement.date, { year: 'numeric', month: 'short', day: 'numeric' })}
          </div>
        </div>
      );
    });
  };

  const renderClinic = () => {
    return (
      <ExportSection className={styles.section}>
        <div className={styles.clinicWrapper}>
          <div>
            <H1 className={styles.sectionHeader}>
              <FormattedMessage id="general.clinic" defaultMessage="Clinic Name" />
            </H1>
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'patientexport.clinic',
                defaultMessage: 'Clinic',
              })}
              :
            </span>
            <EditableItem noMarginTop type="text" edit={edit} value={clinic.name} />
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'general.clinician',
                defaultMessage: 'Clinician',
              })}
              :
            </span>
            <EditableItem
              noMarginTop
              type="text"
              edit={edit}
              value={user.title + ' ' + ' ' + user.firstName + ' ' + user.surname}
            />
            <span className={styles.label}>
              {intl.formatMessage({
                id: 'general.address',
                defaultMessage: 'Address',
              })}
              :
            </span>
            <EditableItem
              noMarginTop
              type="text"
              edit={edit}
              value={`${clinic.address.street}, ${clinic.address.city} ${clinic.address.postcode}`}
            />
          </div>
          <div>
            <H1 className={styles.sectionHeader}>
              <FormattedMessage id="patientexport.patient" defaultMessage="Patient" />
            </H1>
            <EditableItem type="text" edit={edit} value={patient.patientIdentifier} />
          </div>
        </div>
      </ExportSection>
    );
  };

  const renderInfobox = () => {
    return (
      <ExportSection className={styles.section}>
        <p>
          <FormattedMessage
            id="patientexport.infoText1"
            defaultMessage="
          During your treatment your dentist has been using Osstell to measure the stability of your
          dental implants. Implant stability is very important as it gives an indication of implant
          healing (osseointegration) and the ability of the implant to take the load of a crown,
          bridge or denture."
          />
        </p>
        <p>
          <FormattedMessage
            id="patientexport.infoText2"
            defaultMessage="
          Osstell’s method measures stability by measuring the Resonance Frequency of a small sensor
          attached to the implant at different treatment stages. It is a completely harmless and
          non-invasive test method. The Osstell technology is evidence-based, backed by more than
          1,000 scientific studies available at"
          />{' '}
          <a href="http://www.osstell.com/scientific-database/" target="_blank" rel="noreferrer">
            www.osstell.com/scientific-database/
          </a>
        </p>
        <p>
          <FormattedMessage
            id="patientexport.infoText3"
            defaultMessage="
          Results are presented as Osstell ISQ (Implant Stability Quotient) between 1-100.
          Measurements may change at different times during the course of treatment. Your dentist is
          an expert in interpreting Osstell ISQ in the context of your overall treatment."
          />
        </p>
        <p>
          <FormattedMessage
            id="patientexport.infoText4"
            defaultMessage="
          The information below is the data recorded by the Osstell instrument for your implants
          during treatment. If your surgery has been carried out by a specialist, the information is
          helpful to your restoring dentist who can continue to monitor your implants if they have
          Osstell. Below are the details of the type/s of dental implants you have had placed. This
          is a useful record for any future dentists and treatment you may have."
          />
        </p>
        <p>
          <FormattedMessage
            id="patientexport.infoText5"
            defaultMessage="
          This is an important record, please keep it safe. For more information see"
          />{' '}
          <a href="http://www.osstell.com/patient" target="_blank" rel="noreferrer">
            www.osstell.com/patient
          </a>
        </p>
        <em>The Osstell Team</em>
      </ExportSection>
    );
  };

  return (
    <div className={styles.report} id="pdf_report">
      <div>
        <H1 className={styles.header}>
          <FormattedMessage
            id="patientReport.heading"
            defaultMessage="Your Osstell Implant Stability Patient Report"
          />
        </H1>
      </div>
      {renderClinic()}
      {renderInfobox()}
      {renderImplantData()}
      {renderImplants()}
      <div className={styles.footer} style={{ width: '100%' }} id="footer">
        <img
          style={{ maxWidth: '200px', margin: '0.5rem auto', display: 'block' }}
          src={osstellLogo}
          alt="logo"
        />
      </div>
    </div>
  );
};

export default PatientReport;
