import { combineReducers } from '@reduxjs/toolkit';
import consultationSlice from '../slices/workspaceSlice';
import viewSlice from '../slices/UISlice';
import procedureSlice from '../slices/procedureSlice';
import apiSlice from '../slices/apiSlice';
import undoable, { excludeAction, groupByActionTypes } from 'redux-undo';

const consultationReducer = combineReducers({
  workspace: undoable(consultationSlice ?? (() => {}), {
    limit: 30,
    groupBy: groupByActionTypes('CONSULTATION_WORKSPACE/DRAWING_UPDATE'),
    filter: excludeAction(['CONSULTATION_WORKSPACE/RESTORE']),
  }),
  procedure: procedureSlice,
  view: viewSlice,
  api: apiSlice,
});

export default consultationReducer;
