import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as referenceDataActions from '../../../redux/actions/referenceDataActions';

import styles from './ImplantList.module.scss';

import { Row, RowItem } from '../../../components/Shared/Section';
import { Table, TableHead, TableBody, TH, TR } from '../../../components/Shared/Table';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import ItemRow from './ItemRow';
import { Button } from '../../../components/Shared/Buttons';

class ImplantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
      columnSort: undefined,
      descSort: true,
      implants: props.implants ? [...this.sortItems(props.implants)] : [],
    };
    this.sortItems = this.sortItems.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.implants && nextProps.implants.length > 0) {
      let implants = this.sortItems(nextProps.implants, 'dataId');

      this.setState({
        implants: [...implants],
      });
    }
  }

  sortItems(implants, column, descSort = false) {
    if (column) {
      this.setState({
        columnSort: column,
        descSort: descSort,
      });
    }
    implants.sort(function (a, b) {
      var result;
      result = a[column] - b[column];
      return (
        (column
          ? isNaN(result)
            ? String(a[column]).localeCompare(String(b[column]))
            : result
          : false) ||
        a.brandName.localeCompare(b.brandName) ||
        a.modelName.localeCompare(b.modelName)
      );
    });
    return descSort ? implants.reverse() : implants;
  }

  onHeaderClick(header) {
    const descSort = this.state.columnSort === header ? !this.state.descSort : false;
    let implants = this.sortItems(this.state.implants, header, descSort);

    this.setState({
      implants: [...implants],
    });
  }

  renderTableHeader() {
    return (
      <TableHead>
        <TR>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'dataId')}>ID</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'brandName')}>Brand</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'modelName')}>Model</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'platformName')}>Platform</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'diameter')}>Diameter</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'length')}>Length</div>
          </TH>
          {this.props.abutments ? (
            <TH>
              <div onClick={this.onHeaderClick.bind(this, 'angle')}>Angle</div>
            </TH>
          ) : null}
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'partNumber')}>PartNumber</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'udi')}>UDI</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'smartPeg')}>SmartPeg</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'info')}>Info</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'comment')}>Comment</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'date')}>Date</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'status')}>Status</div>
          </TH>
          <TH>
            <div onClick={this.onHeaderClick.bind(this, 'modified')}>Modified</div>
          </TH>
          <TH></TH>
        </TR>
      </TableHead>
    );
  }

  renderList() {
    return this.state.implants.map((item) => {
      return (
        <ItemRow
          key={item.dataId}
          item={item}
          abutment={this.props.abutments}
          smartPegs={this.props.smartPegs}
          onChanged={this.itemChanged.bind(this, item.dataId)}
          onDeleted={this.itemDeleted.bind(this, item.dataId)}
          onRevert={this.itemReverted.bind(this, item.dataId)}
        />
      );
    });
  }

  itemChanged(dataId, item) {
    const index = this.state.implants.findIndex((x) => x.dataId === Number(dataId));
    if (JSON.stringify(this.state.implants[index]) === JSON.stringify(item)) {
      alert('No changes');
      return;
    }
    let implants = this.state.implants;
    implants[index] = Object.assign({}, item, {
      modified: item.modified !== 'new' ? 'changed' : 'new',
      date: new Date(),
    });
    this.setState({
      implants: implants,
    });
  }

  itemDeleted(dataId) {
    const index = this.state.implants.findIndex((x) => x.dataId === Number(dataId));
    let implants = this.state.implants;
    implants.splice(index, 1);
    this.setState({
      implants: implants,
    });
  }

  itemsAdded(items) {
    let implants = this.state.implants;
    let id = Math.max(...this.state.implants.map((x) => x.dataId));
    items.forEach((item) => {
      item.modified = 'new';
      item.dataId = ++id;
      item.date = new Date();
      implants.push(item);
    });
    this.setState({
      implants: implants,
    });
  }

  itemReverted(dataId) {
    const index = this.state.implants.findIndex((x) => x.dataId === Number(dataId));
    let oldItem = this.props.implants.find((x) => x.dataId === Number(dataId));
    if (!oldItem) {
      alert('Failed to revert');
      return;
    }
    let implants = this.state.implants;
    implants[index] = Object.assign({}, oldItem);
    this.setState({
      implants: implants,
    });
  }

  onSave() {
    if (this.state.implants.some((x) => x.smartPeg === 'Type XX' && x.status !== 2)) {
      alert('Only possible to select SmartPeg Type XX for pending items.');
      return;
    }
    let implants = this.state.implants.filter((x) => x.modified);
    if (this.props.abutments) {
      this.props.actions.updateAbutmentData(implants);
    } else {
      this.props.actions.updateImplantData(implants);
    }
  }

  render() {
    let loading = !this.props.implants || this.props.implants.length === 0;
    return (
      <div>
        <Row>
          <RowItem>
            {loading ? (
              <Spinner className={styles.spinnerBackground} />
            ) : (
              <Table className={styles.table}>
                {this.renderTableHeader()}
                <TableBody>
                  {this.renderList()}
                  <ItemRow
                    new
                    abutment={this.props.abutments}
                    smartPegs={this.props.smartPegs}
                    onAddNew={this.itemsAdded.bind(this)}
                  />
                </TableBody>
              </Table>
            )}
          </RowItem>
        </Row>
        <Row>
          <RowItem>
            <div className={styles.buttonGroup}>
              <Button primary value="Save Work" onClick={this.onSave.bind(this)} />
            </div>
          </RowItem>
        </Row>
      </div>
    );
  }
}

function mapState() {
  return {};
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(referenceDataActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(ImplantList);
