import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { usePrevious } from '../../utils/hooks';
import { toastrOptions } from '../../constants/toastrOptions';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToInstrumentStatus,
  unsubscribeToInstrumentStatus,
} from '../../redux/actions/instrumentActions';

function InstrumentSubscriber() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const user = useSelector((state) => state.user.user);
  const activeInstrument = useSelector((state) => state.instrument.activeInstrument);

  const prevActiveInstrument = usePrevious(activeInstrument);

  useEffect(() => {
    const sourceGroupId = user.sourceUserGroup.id;
    dispatch(subscribeToInstrumentStatus(sourceGroupId));
    return () => dispatch(unsubscribeToInstrumentStatus(sourceGroupId));
  }, [user, dispatch]);

  useEffect(() => {
    if (!activeInstrument.Active && prevActiveInstrument?.Active) {
      toastr.success(
        intl.formatMessage({
          id: 'instrumentSubscriber.disconnected',
          defaultMessage: 'Beacon Disconnected',
        }),
        toastrOptions
      );
    } else if (
      activeInstrument.Active &&
      prevActiveInstrument?.InstrumentId !== activeInstrument.InstrumentId
    ) {
      toastr.success(
        intl.formatMessage({
          id: 'instrumentSubscriber.connected',
          defaultMessage: 'Beacon Connected',
        }),
        '# ' + activeInstrument.SerialNumber,
        toastrOptions
      );
    }
  }, [activeInstrument, intl, prevActiveInstrument]);
  return null;
}

export default InstrumentSubscriber;
