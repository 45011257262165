import { Row } from '../../../components/Shared/Section';
import { Button } from '../../../components/Shared/Buttons';
import { useIntl } from 'react-intl';
import { ICountry } from '../../../types/country';
import { getClinic } from '../../../redux/actions/clinicActions';
import { IAdminClinic } from '../../../types/clinic';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import DataItem from '../../../components/Shared/DataItem/DataItem';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import styles from './ClinicView.module.scss';
import Modal from '../../../components/Shared/Modal/Modal';

interface Props {
  userId: string;
  countries: ICountry[];
  isClinicModalOpen: boolean;
  onModalCloseClick: () => void;
}

const ClinicView: FC<Props> = ({ userId, countries, isClinicModalOpen, onModalCloseClick }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { clinics, loaded } = useAppSelector((state) => state.clinic.clinicView);
  const [selectedClinic, setSelectedClinic] = useState<IAdminClinic | undefined>(undefined);
  const [stepper, setStepper] = useState(0);
  const [country, setCountry] = useState<ICountry>({
    key: '',
    value: 'Unknown',
  });

  useEffect(() => {
    if (!userId) return;
    dispatch(getClinic(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (!clinics) return;
    setSelectedClinic(clinics[stepper]);
  }, [clinics, countries, stepper]);

  useEffect(() => {
    if (selectedClinic) {
      const country = countries.find((c) => c.key === selectedClinic.address.countryCode);
      country && setCountry(country);
    }
  }, [selectedClinic, countries]);

  return (
    <Modal
      fullModal={true}
      header={'Clinic Information'}
      onDismiss={onModalCloseClick}
      show={isClinicModalOpen}
      modalContentClass={styles.modalContent}
    >
      <div className={styles.addressWrapper}>
        {loaded && selectedClinic ? (
          <>
            <Row>
              <DataItem label="Clinic name" value={selectedClinic.name} span />
            </Row>
            <Row>
              <DataItem label="Address" value={selectedClinic.address.street} span />
              <DataItem label="Co" value={selectedClinic.address.careOf} span />
            </Row>
            <Row>
              <DataItem label="Zip" value={selectedClinic.address.postcode} span />
              <DataItem label="State" value={selectedClinic.address.state} span />
              <DataItem label="City" value={selectedClinic.address.city} span />
            </Row>
            <Row>
              <DataItem label="Country Code" value={country.value} span />
            </Row>
            {clinics.length > 1 && (
              <>
                <Button
                  disabled={stepper === 0}
                  onClick={() => setStepper((prevState) => prevState - 1)}
                  value={intl.formatMessage({
                    id: 'general.previous',
                    defaultMessage: 'Previous',
                  })}
                >
                  Prev
                </Button>
                <Button
                  disabled={stepper === clinics.length - 1}
                  onClick={() => setStepper((prevState) => prevState + 1)}
                  value={intl.formatMessage({
                    id: 'general.next',
                    defaultMessage: 'Next',
                  })}
                />
              </>
            )}
          </>
        ) : (
          <Spinner className={styles.spinnerBackground} />
        )}
      </div>
    </Modal>
  );
};

export default ClinicView;
