import React from 'react';
import { useAppSelector } from '../../../../../../utils/hooks';
import { ZoneWidthsUpper } from '../ToothInteractionZone';
import { Bone } from '../../../../../../redux/slices/data-structures/workspaceStructure';

interface Props {
  rightSide?: boolean;
}
const SvgTeethGraftShapes: React.FC<Props> = ({ rightSide = false }) => {
  const boneState = useAppSelector<Bone[]>(
    (state) => state.consultation.workspace.present.boneState
  );
  const depths = boneState
    .filter((_: Bone, index: number) => (rightSide ? index > 8 : index <= 8))
    .map((bone: Bone) => bone.sinusGraft);

  return rightSide ? (
    <g transform={'translate(0 100)'}>
      {depths.map((depth: number, index: number) => {
        const x = ZoneWidthsUpper.slice(9, index + 9).reduce((acc, current) => acc + current, 0);
        return (
          <rect
            key={`teethGraftShape_right_${index}`}
            x={x}
            y={100 - depth * 40}
            width={ZoneWidthsUpper[index + 9]}
            height={200}
            fill={'black'}
            rx="28"
          />
        );
      })}
    </g>
  ) : (
    <g transform={'translate(0 100)'}>
      {depths.map((depth: number, index: number) => {
        const x = ZoneWidthsUpper.slice(0, index).reduce((acc, current) => acc + current, 25);
        return (
          <rect
            key={`teethGraftShape_left_${index}`}
            x={x}
            y={100 - depth * 40}
            width={ZoneWidthsUpper[index]}
            height={200}
            fill={'black'}
            rx="28"
          />
        );
      })}
    </g>
  );
};
export default SvgTeethGraftShapes;
