import * as React from 'react';

function SvgSinusGraftliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1554 2048" {...props}>
      <path d="M1375 373q27 26 44.5 56t24 67.5 8.5 68-4.5 79-12 79T1418 810q-2 11-4.5 23.5t-5.5 28-5 23.5q-18 95-7 242 15 190-15 343-20 103-71.5 169t-124.5 84q-20 5-48 5-115 0-196-103-63-79-357-375-340-342-400-423-96-131-79-227 18-93 142-172 62-39 145-56.5t169.5-16T752 361q132 6 205 6 124 0 238-35 42-12 64-14.5t51.5 9.5 64.5 46zm1 429q14-66 20-100t11.5-86 .5-83.5-20-66.5-42-62q-40-39-67.5-43.5T1207 373q-121 37-249 37-74 0-208-6-101-4-184-5.5t-161.5 14T270 464q-110 69-123 143-15 79 72 195 51 70 311 333 3-16 8-32t14-41.5 19.5-46.5 26.5-45.5 34.5-39.5 43-28 52.5-11.5 62 10.5q45 15 74.5 59.5t40 106 14 124.5-3 131-12 109-11.5 74q60 66 82 93 41 52 94.5 74t106.5 9q61-15 104.5-72t60.5-147q19-96 20.5-185.5T1354 1130q-12-157 7-254 10-50 15-74z" />
    </svg>
  );
}

export default SvgSinusGraftliga;
