import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import styled from 'styled-components';
import {
  Center,
  OverlayBoxShadow,
} from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import { SET_SUBTOOL } from '../../../../../../redux/slices/UISlice';
import {
  ToolIcons,
  workspaceToolsState,
} from '../../../../../../redux/slices/data-structures/workspaceStructure';
import { ResolveToolIcon } from './TeethToolbar';
import { FormattedMessage } from 'react-intl';

interface Props {
  id: number;
  label: { id: string; defaultMessage: string };
  icon: ToolIcons;
}
const TeethSubToolItem: React.FC<Props> = ({ id, label, icon }) => {
  const currentSubTool = useAppSelector((state) => state.consultation.view.currentSubTool);
  const currentTool = useAppSelector((state) => state.consultation.view.currentTool);
  const dispatch = useAppDispatch();

  return (
    <SubTool
      selected={currentSubTool?.id === id}
      onClick={() =>
        dispatch(
          SET_SUBTOOL(
            workspaceToolsState?.find((tool) => tool?.id === currentTool?.id)?.subTools?.[id] ??
              undefined
          )
        )
      }
    >
      {ResolveToolIcon(icon)}
      <span>
        <FormattedMessage {...label} />
      </span>
    </SubTool>
  );
};

export const SubToolPanel = styled.div`
  ${Center};
  ${OverlayBoxShadow};
  position: absolute;
  left: 4px;
  bottom: 72px;
  height: 58px;
  background-color: white;
  user-select: none;
  border-radius: 1rem;
  padding: 4px;
`;

const SubTool = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  background-color: white;
  transition: background-color 270ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 50px;
  height: 50px;
  font-size: 9px;
  svg {
    width: 22px;
    stroke: ${(props) => props.theme.colors.grey};
  }

  ${(props) =>
    props.selected &&
    `background-color: ${props.theme.colors.selectedgrey};
     transition: background-color 270ms ease-in-out; 
     color: ${props.theme.colors.purple}; 
     svg {
      fill: ${props.theme.colors.purple};
      stroke: ${props.theme.colors.purple};
     }
     `};

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export default TeethSubToolItem;
