import * as React from 'react';

function SvgSvgClear(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.04 23.04" {...props}>
      <defs>
        <style>
          {'.SvgClear_svg__cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round}'}
        </style>
      </defs>
      <g id="SvgClear_svg__Layer_2" data-name="Layer 2">
        <g id="SvgClear_svg__Layer_1-2" data-name="Layer 1">
          <path
            className="SvgClear_svg__cls-1"
            d="M15.73 13.69l6.06 3.14M12.96 16.03l2.07 6.51M9.35 15.73l-3.14 6.06M7.01 12.96L.5 15.03M7.31 9.35L1.25 6.21M10.07 7.01L8.01.5M13.69 7.31l3.14-6.06M16.03 10.08l6.51-2.07"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgClear;
