import { Button } from '../../components/Shared/Buttons';
import { isMoment } from 'moment';
import { IClinicReferral } from '../../types/clinic';
import { oralHygieneOptions } from './helpers/oralHygieneOptions';
import { IPatientRiskFactors } from '../../types/patientRiskFactors';
import { useHistory, useParams } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { getClinic as getClinicRequest } from '../../redux/actions/clinicActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { ChangeEvent, useEffect, useState } from 'react';
import { Section, SectionInner, Row, RowItem } from '../../components/Shared/Section';
import { InputItem, InputRow, InputGroup, InputSubGroup } from '../../components/Shared/Forms';
import {
  subscribeToPatientChange,
  unsubscribeFromPatientChange,
  getPatient as getPatientRequest,
  createPatient as createPatientRequest,
  updatePatient as updatePatientRequest,
} from '../../redux/actions/patientActions';
import ReferralModal from '../../components/ReferralModal/ReferralModal';
import styled from 'styled-components';
import Header from '../../components/header/Header';

const PatientInformationPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { userGroupId, patientId, section } = useParams<Record<string, string | undefined>>();

  const {
    createPatient,
    updatePatient,
    currentPatient: patient,
  } = useAppSelector((state) => state.patient);
  const { user } = useAppSelector((state) => state.user);
  const { clinic } = useAppSelector((state) => state.clinic);
  const { patientRiskFactors } = useAppSelector((state) => state.referenceData);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState<string | Date>('');
  const [patientNumber, setPatientNumber] = useState('');
  const [gender, setGender] = useState<string | undefined>(undefined);
  const [referringClinician, setReferringClinician] = useState<string | undefined>(undefined);
  const [referralId, setReferralId] = useState<string | undefined>(undefined);
  const [comment, setComment] = useState('');
  const [selectedRisks, setSelectedRisks] = useState<string[]>([]);
  const [oralHygiene, setOralHygiene] = useState<string | undefined>(undefined);
  const [otherRisks, setOtherRisks] = useState('');
  const [showReferralModal, setShowReferralModal] = useState(false);
  const [showRiskSection, setShowRiskSection] = useState(section === 'risks');
  const [newPatientCreated, setNewPatientCreated] = useState(false);

  const genderOptions = [
    {
      key: 'Male',
      value: intl.formatMessage({ id: 'patientInformation.male', defaultMessage: 'Male' }),
    },
    {
      key: 'Female',
      value: intl.formatMessage({
        id: 'patientInformation.female',
        defaultMessage: 'Female',
      }),
    },
  ];

  const referralOptions = clinic.referrals
    ? clinic.referrals.map((referral: IClinicReferral) => {
        return { label: referral.clinician, value: referral.id };
      })
    : [];

  const toggleReferralModal = () => setShowReferralModal((prevState) => !prevState);

  const referralFotter = (
    <div data-cy="manage-referrals-div" onClick={toggleReferralModal}>
      <FormattedMessage id="patientInformation.manageReferrals" defaultMessage="Manage referrals" />{' '}
      <i>draw</i>
    </div>
  );

  const navigatedToRisks = section === 'risks';
  const newPatient = patientId === 'new';

  useEffect(() => {
    dispatch(getClinicRequest());
    if (!newPatient) {
      const userId = user.id;
      dispatch(getPatientRequest(userGroupId, patientId));
      dispatch(subscribeToPatientChange(userId, userGroupId, patientId));
    }

    return () => {
      if (!newPatient) {
        dispatch(unsubscribeFromPatientChange(patientId));
      }
    };
  }, [newPatient, user.id, userGroupId, patientId]);

  useEffect(() => {
    if (createPatient.complete && newPatientCreated) {
      setNewPatientCreated(false);
      const { newPatient } = createPatient;
      const newPath = `/usergroup/${newPatient.sourceUserGroupId}/patients/${newPatient.id}`;
      history.replace(newPath);
    }
  }, [createPatient.complete, newPatientCreated]);

  useEffect(() => {
    if (patient.id && !newPatient) {
      setFirstName(patient.firstName ?? '');
      setLastName(patient.lastName ?? '');
      setDateOfBirth(patient.birthDate ? new Date(patient.birthDate) : '');
      setPatientNumber(patient.patientNumber);
      setGender(patient.gender ? patient.gender : undefined);
      setReferringClinician(getClinicianName(patient.referralId));
      setReferralId(patient.referralId);
      setComment(patient.comment);
      setSelectedRisks(patient.riskFactors);
      setOralHygiene(patient.oralHygiene?.toString());
      setOtherRisks(patient.otherRisks ?? '');
    }
  }, [newPatient, patient]);

  const firstNameChanged = (evt: ChangeEvent<HTMLInputElement>) => setFirstName(evt.target.value);

  const lastNameChanged = (evt: ChangeEvent<HTMLInputElement>) => setLastName(evt.target.value);

  const patientNumberChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setPatientNumber(evt.target.value);

  const dateOfBirthChanged = (evt: Date) => setDateOfBirth(evt);

  const genderChanged = (evt: ChangeEvent<HTMLInputElement>) => setGender(evt.target.value);

  const referringClinicianChanged = (option: { label: string; value: string }) => {
    setReferringClinician(option.label);
    setReferralId(option.value);
  };

  const getClinicianName = (clinicianId: string) => {
    if (!clinic.referrals) return clinicianId;
    const clinician = clinic.referrals.find(
      (referral: IClinicReferral) => referral.id === clinicianId
    );
    return clinician ? clinician.clinician : null;
  };
  const commentChanged = (evt: ChangeEvent<HTMLInputElement>) => setComment(evt.target.value);

  const oralHygieneChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setOralHygiene(evt.target.value);

  const otherRisksChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelectedRisks(selectedRisks.filter((id) => id !== 'norisks'));
    setOtherRisks(evt.target.value.replace(';', ''));
  };

  const compareTranslated = (a: IPatientRiskFactors, b: IPatientRiskFactors) => {
    if (a.translated < b.translated) return -1;
    if (a.translated > b.translated) return 1;
    return 0;
  };

  const renderRiskFactors = (patientRiskFactors: IPatientRiskFactors[]) => {
    const riskfactors = patientRiskFactors.map((risk) => {
      return {
        ...risk,
        ...{
          translated: intl.formatMessage({
            id: ''.concat('referenceData.', risk.key),
            defaultMessage: risk.value,
          }),
        },
      };
    });
    const categoryDisease = riskfactors
      .filter((risk) => risk.category === 'Disease')
      .sort(compareTranslated);
    const categoryMedication = riskfactors
      .filter((risk: IPatientRiskFactors) => risk.category === 'Medication')
      .sort(compareTranslated);
    const categoryOther = riskfactors
      .filter((risk: IPatientRiskFactors) => risk.category === 'Other')
      .sort(compareTranslated);

    return (
      <div className="riskFactorContainer">
        <InputSubGroup
          label={intl.formatMessage({
            id: 'patientInformation.medication',
            defaultMessage: 'Medication',
          })}
        >
          {categoryMedication.map((risk) => {
            return (
              <InputItem
                dataCy={`patient-information-risk-${risk.key}`}
                key={risk.key}
                type="boolbutton"
                noMarginTop
                buttonLabel={risk.translated}
                value={risk.key}
                checked={selectedRisks.indexOf(risk.key) !== -1}
                onChange={() => riskSelected(risk.key)}
                span
                noGrow
              />
            );
          })}
        </InputSubGroup>
        <InputSubGroup
          label={intl.formatMessage({
            id: 'patientInformation.riskFactors',
            defaultMessage: 'Disease',
          })}
        >
          {categoryDisease.map((risk) => {
            return (
              <InputItem
                dataCy={`patient-information-risk-${risk.key}`}
                key={risk.key}
                type="boolbutton"
                noMarginTop
                buttonLabel={intl.formatMessage({
                  id: ''.concat('referenceData.', risk.key),
                  defaultMessage: risk.value,
                })}
                value={risk.key}
                checked={selectedRisks.indexOf(risk.key) !== -1}
                onChange={() => riskSelected(risk.key)}
                span
                noGrow
              />
            );
          })}
        </InputSubGroup>
        <InputSubGroup
          label={intl.formatMessage({
            id: 'patientInformation.other',
            defaultMessage: 'Other',
          })}
        >
          {categoryOther.map((risk) => {
            return (
              <InputItem
                dataCy={`patient-information-risk-${risk.key}`}
                key={risk.key}
                type="boolbutton"
                noMarginTop
                buttonLabel={intl.formatMessage({
                  id: ''.concat('referenceData.', risk.key),
                  defaultMessage: risk.value,
                })}
                value={risk.key}
                checked={selectedRisks.indexOf(risk.key) !== -1}
                onChange={() => riskSelected(risk.key)}
                span
                noGrow
              />
            );
          })}
        </InputSubGroup>
        <InputItem
          label={intl.formatMessage({
            id: 'patientInformation.additionalRiskFactors',
            defaultMessage: 'Additional risk factors',
          })}
          type="text"
          onChange={otherRisksChanged}
          value={otherRisks}
        />
        <InputItem
          type="boolbutton"
          noMarginTop
          label={intl.formatMessage({
            id: 'patientInformation.OR',
            defaultMessage: 'OR',
          })}
          buttonLabel={intl.formatMessage({
            id: 'patientInformation.NoRisks',
            defaultMessage: 'No risks',
          })}
          value={'norisks'}
          checked={selectedRisks.indexOf('norisks') !== -1}
          onChange={() => riskSelected('norisks')}
        />
      </div>
    );
  };

  const riskSelected = (risk: string) => {
    if (risk === 'norisks') {
      setSelectedRisks(['norisks']);
      setOtherRisks('');
      return;
    }
    if (selectedRisks.indexOf(risk) !== -1)
      return setSelectedRisks(selectedRisks.filter((id) => id !== risk));
    setSelectedRisks(selectedRisks.concat(risk).filter((id) => id !== 'norisks'));
  };

  const onSave = () => {
    const risks: { [key: string]: number | string } = {};
    selectedRisks.forEach((item) => {
      risks[item] = 1;
    });

    if (otherRisks.length > 0) {
      risks['other'] = otherRisks;
    }

    if (newPatient) {
      dispatch(
        createPatientRequest(
          userGroupId,
          patientNumber,
          firstName,
          lastName,
          risks,
          isMoment(dateOfBirth) ? dateOfBirth.format() : dateOfBirth,
          gender,
          comment,
          referralId,
          oralHygiene
        )
      );
      return setNewPatientCreated(true);
    }
    dispatch(
      updatePatientRequest(
        patient.sourceUserGroupId,
        patient.id,
        patientNumber,
        firstName,
        lastName,
        risks,
        isMoment(dateOfBirth) ? dateOfBirth.format() : dateOfBirth,
        gender,
        comment,
        referralId,
        oralHygiene
      )
    );
    history.goBack();
  };

  const toggleRiskSection = () => setShowRiskSection((prevState) => !prevState);

  return (
    <StyledPatientInformationPage>
      <Header
        title={intl.formatMessage({
          id: 'patientInformation.Header',
          defaultMessage: 'PATIENT INFORMATION',
        })}
        isTogglerHidden
        leftButtons={
          showRiskSection
            ? [
                {
                  label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
                  icon: 'back',
                  action: navigatedToRisks ? history.goBack : () => toggleRiskSection(),
                },
              ]
            : [
                {
                  label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
                  icon: 'back',
                  action: history.goBack,
                },
              ]
        }
      />
      <Section>
        <SectionInner>
          {!showRiskSection && (
            <InputGroup
              label={intl.formatMessage({
                id: 'patientInformation.generalInformation',
                defaultMessage: 'Patient overview',
              })}
            >
              <Row>
                <RowItem>
                  <InputRow>
                    <InputItem
                      dataCy="patient-information-first-name"
                      label={intl.formatMessage({
                        id: 'general.firstName',
                        defaultMessage: 'First Name',
                      })}
                      type="text"
                      onChange={firstNameChanged}
                      value={firstName}
                    />
                    <InputItem
                      dataCy="patient-information-last-name"
                      label={intl.formatMessage({
                        id: 'general.lastName',
                        defaultMessage: 'Last Name',
                      })}
                      type="text"
                      onChange={lastNameChanged}
                      value={lastName}
                    />
                  </InputRow>
                </RowItem>
              </Row>
              <Row>
                <RowItem>
                  <InputRow>
                    <InputItem
                      label={intl.formatMessage({
                        id: 'general.dateOfBirth',
                        defaultMessage: 'Date Of Birth',
                      })}
                      type="date"
                      onChange={dateOfBirthChanged}
                      value={dateOfBirth}
                      timeVisible={false}
                      startWithYears
                      initialDate={new Date(1980, 1, 1)}
                    />
                    <InputItem
                      dataCy="patient-information-patient-id"
                      label={
                        '*' +
                        intl.formatMessage({
                          id: 'general.patientID',
                          defaultMessage: 'PatientID',
                        })
                      }
                      type="text"
                      onChange={patientNumberChanged}
                      value={patientNumber}
                    />
                  </InputRow>
                </RowItem>
              </Row>
              <Row>
                <RowItem>
                  <InputRow>
                    <InputItem
                      dataCy="patient-information-gender"
                      label={intl.formatMessage({
                        id: 'general.gender',
                        defaultMessage: 'Gender',
                      })}
                      type="selectbutton"
                      onChange={genderChanged}
                      options={genderOptions}
                      value={gender}
                    />
                    <InputItem
                      dataCy="referring-clinician-dropdown"
                      label={intl.formatMessage({
                        id: 'general.referringClinician',
                        defaultMessage: 'Referring Clinician',
                      })}
                      type="search-select"
                      options={referralOptions}
                      onChange={referringClinicianChanged}
                      value={referringClinician}
                      footer={referralFotter}
                    />
                  </InputRow>
                </RowItem>
              </Row>
              <Row>
                <RowItem>
                  <InputRow>
                    <InputItem
                      dataCy="patient-information-comment"
                      label={intl.formatMessage({
                        id: 'general.comment',
                        defaultMessage: 'Comment',
                      })}
                      type="textarea"
                      onChange={commentChanged}
                      value={comment}
                    />
                  </InputRow>
                </RowItem>
              </Row>
              <Row>
                <RowItem>
                  <InputRow>
                    <InputItem
                      dataCy="patient-information-oral-hygiene"
                      label={intl.formatMessage({
                        id: 'general.oralHygiene',
                        defaultMessage: 'Oral Hygiene',
                      })}
                      minLabel={intl.formatMessage({
                        id: 'general.poor',
                        defaultMessage: 'Poor',
                      })}
                      maxLabel={intl.formatMessage({
                        id: 'general.good',
                        defaultMessage: 'Good',
                      })}
                      type="selectbutton"
                      onChange={oralHygieneChanged}
                      options={oralHygieneOptions}
                      value={oralHygiene}
                      noGrow
                      centered
                    />
                  </InputRow>
                </RowItem>
              </Row>
              <Row right>
                <em>
                  *{' '}
                  <FormattedMessage
                    id="general.mandatoryFields"
                    defaultMessage="Mandatory fields"
                  />
                </em>
              </Row>
              <Row right className="buttonRow">
                <Button
                  value={intl.formatMessage({
                    id: 'general.cancel',
                    defaultMessage: 'Cancel',
                  })}
                  onClick={history.goBack}
                  white
                />
                <Button
                  dataCy="patient-information-next-button"
                  primary
                  value={intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' })}
                  rightIcon="forward"
                  onClick={toggleRiskSection}
                  disabled={!patientNumber}
                />
              </Row>
            </InputGroup>
          )}
          {showRiskSection && (
            <InputGroup
              label={
                '*' +
                intl.formatMessage({
                  id: 'general.riskFactors',
                  defaultMessage: 'Risk factors',
                })
              }
            >
              <Row>
                <RowItem>
                  <InputRow>{renderRiskFactors(patientRiskFactors)}</InputRow>
                </RowItem>
              </Row>
              <Row right>
                <em>
                  *{' '}
                  <FormattedMessage
                    id="patientInformation.selectRisk"
                    defaultMessage="Choose at least one factor or select No risks"
                  />
                </em>
              </Row>
              <Row right className="buttonRow">
                <Button
                  value={intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' })}
                  leftIcon="back"
                  onClick={navigatedToRisks ? history.goBack : toggleRiskSection}
                  white
                />
                <Button
                  dataCy={'patient-information-save-button'}
                  primary
                  value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
                  rightIcon="done"
                  onClick={() => onSave()}
                  disabled={
                    (selectedRisks.length === 0 && !otherRisks) ||
                    newPatientCreated ||
                    updatePatient.busy
                  }
                  loading={newPatientCreated || updatePatient.busy}
                />
              </Row>
            </InputGroup>
          )}
        </SectionInner>
      </Section>
      <ReferralModal show={showReferralModal} onHide={toggleReferralModal} />
    </StyledPatientInformationPage>
  );
};

const StyledPatientInformationPage = styled.div`
  @media (max-width: 600px) {
    .riskFactorContainer {
      div {
        width: 100% !important;
        min-width: unset !important;
      }
    }
    .buttonRow {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-bottom: 60px;
      button {
        margin: 0;
        div {
          justify-content: center !important;
        }
      }

      button:last-child {
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 400px) {
    .buttonRow {
      button {
        width: 100%;
      }
    }
  }
`;

export default PatientInformationPage;
