import { H1 } from '../../components/Shared/Headings';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Shared/Buttons';
import { AlertBox } from '../../components/Shared/Alerts';
import { InputItem } from '../../components/Shared/Forms';
import { getCountries } from '../../redux/actions/referenceDataActions';
import { Section, SectionInner } from '../../components/Shared/Section';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { getEula as getEulaRequest } from '../../redux/actions/eulaActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { registerAccount as registerAccountRequest } from '../../redux/actions/accountActions';
import { ChangeEvent, MouseEvent, useEffect, useState, KeyboardEvent } from 'react';
import styles from './RegisterAccountPage.module.scss';
import Modal from '../../components/Shared/Modal/Modal';
import { ICountry } from '../../types/country';

const RegisterAccountPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector((state) => state.referenceData);
  const { getEula, eula } = useAppSelector((state) => state.eula);
  const { accountRegistration } = useAppSelector((state) => state.account);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [eulaCountry, setEulaCountry] = useState('');
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [firstPage, setFirstPage] = useState(true);
  const [error, setError] = useState<{ messages: { text: string }[] } | undefined>(undefined);

  const steps = [
    {
      icon: 'profile',
      text: intl.formatMessage({
        id: 'registerAccount.step1',
        defaultMessage: 'Register new account',
      }),
    },
    {
      icon: 'email',
      text: intl.formatMessage({
        id: 'registerAccount.step2',
        defaultMessage: 'Confirm your email',
      }),
    },
    {
      icon: 'clinic',
      text: intl.formatMessage({
        id: 'registerAccount.step3',
        defaultMessage: 'Create your profile and clinic',
      }),
    },
    {
      icon: 'beacon',
      text: intl.formatMessage({
        id: 'registerAccount.step4',
        defaultMessage: 'Register your instrument',
      }),
    },
    {
      icon: 'smartpeg',
      text:
        intl.formatMessage({
          id: 'registerAccount.step5',
          defaultMessage: 'Collect your FREE SmartPegs!',
        }) + '*',
    },
  ];

  useEffect(() => {
    dispatch(getCountries());
    const script = document.createElement('script');
    script.src = '//www.googleadservices.com/pagead/conversion_async.js';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const toggleModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setShowModal(!showModal);
  };

  const emailChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setEmail(evt.target.value.toLowerCase().trim());

  const passwordChanged = (evt: ChangeEvent<HTMLInputElement>) => setPassword(evt.target.value);

  const passwordRepeatChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setPasswordRepeat(evt.target.value);

  const countryChanged = (option: { value: string; label: string }) => {
    setEulaCountry(option.value);
    dispatch(getEulaRequest(option.value));
  };

  const termsOfServiceChanged = () => setTermsOfServiceAccepted(!termsOfServiceAccepted);

  const registerAccount = () => {
    const errorMessages = [];

    if (!termsOfServiceAccepted && eula.id) {
      errorMessages.push({
        text: intl.formatMessage({
          id: 'registerAccount.ReqestEula',
          defaultMessage: 'Please read and accept terms of service',
        }),
      });
    }

    if (errorMessages.length > 0) return setError({ messages: errorMessages });
    setError(undefined);

    const accountData = {
      email,
      password,
      passwordRepeat,
      eulaCountry,
      termsOfServiceAccepted,
      eulaId: eula.id,
    };

    dispatch(registerAccountRequest(accountData));
  };

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      registerAccount();
    }
  };

  const googReportConversion = (url?: string) => {
    window.google_conversion_id = 975253165;
    window.google_conversion_label = 'BkF0COH-lnQQrd2E0QM';
    window.google_remarketing_only = false;
    window.google_conversion_format = '3';
    const opt: {
      onload_callback?: () => void;
    } = {};
    opt.onload_callback = () => {
      if (typeof url !== 'undefined') {
        window.location.pathname = url;
      }
    };
    const conv_handler = window['google_trackConversion'];
    if (typeof conv_handler === 'function') {
      conv_handler(opt);
    }
  };

  const disableNext = () =>
    !email || !password || !passwordRepeat || !eulaCountry || !termsOfServiceAccepted;

  const renderFistPage = () => {
    const registrationSteps = steps.map((step, index) => {
      return (
        <div key={index}>
          {index > 0 ? <div className={styles.divider}></div> : null}
          <div className={styles.step}>
            <div className={styles.stepIcon}>
              <i>{step.icon}</i>
            </div>
            {step.text}
          </div>
        </div>
      );
    });
    return (
      <div>
        <H1>
          <FormattedMessage id="registerAccount.GetStarted" defaultMessage="Get started in 5 min" />
        </H1>
        <p className={styles.p}>
          <FormattedMessage
            id="registerAccount.WeWillGuide"
            defaultMessage="We will guide you through the following registration steps!"
          />
        </p>
        <div>{registrationSteps}</div>
        <span>
          *
          <FormattedMessage
            id="registerAccount.FreeSmartPegDisclaimer"
            defaultMessage="not valid in every country"
          />
        </span>
        <Button
          primary
          big
          full
          value={intl.formatMessage({ id: 'general.start', defaultMessage: "Let's start" })}
          rightIcon="forward"
          onClick={startAccountRegistration}
        />
        <Link to="/">
          <Button
            rightIcon="back"
            className={styles.cancelButton}
            big
            full
            value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          />
        </Link>
      </div>
    );
  };

  const renderTerms = () => {
    if (eulaCountry) {
      return (
        <div>
          <p className={styles.small}>
            {intl.formatMessage({
              id: 'registerAccount.RegisterTerms',
              defaultMessage: 'Register Terms',
            })}
            .
          </p>
          <div className={styles.inputRow}>
            <InputItem
              label={intl.formatMessage({
                id: 'registerAccount.ConfirmEula',
                defaultMessage: 'I have read and accept the terms and conditions',
              })}
              type="checkbox"
              onChange={termsOfServiceChanged}
              onKeyPress={handleKeyPress}
              noBorder
            />
            <button className={styles.termsLink} onClick={toggleModal}>
              <FormattedMessage
                id="registerAccount.TermsAndConditions"
                defaultMessage="Terms and conditions"
              />
            </button>
          </div>
        </div>
      );
    }
  };

  const startAccountRegistration = () => setFirstPage(false);

  let content;

  if (accountRegistration.complete) {
    googReportConversion();
    content = (
      <div>
        <H1>
          <FormattedMessage
            id="registerAccount.ConfirmAccount"
            defaultMessage="Please confirm your email"
          />
        </H1>
        <p>
          <FormattedMessage
            id="registerAccount.SentEmail"
            defaultMessage="We have sent an email to: "
          />
          <b>{email}</b>
        </p>
        <p>
          <FormattedMessage
            id="registerAccount.CheckEmail"
            defaultMessage="Please follow the link in the email to confirm your account. The link is valid for 12h."
          />
        </p>
        <p>
          <FormattedMessage
            id="registerAccount.EmailInformation"
            defaultMessage="If you haven’t received the email within a few minutes, please check your spam inbox or consult with your IT department"
          />
        </p>
        <p>
          <FormattedMessage
            id="registerAccount.Contact"
            defaultMessage="If you have any issues or questions, please contact Osstell Support at: "
          />
          <a href="mailto:support@osstell.com">support@osstell.com</a>
        </p>
        <br></br>
        <Link to="/">
          <Button
            primary
            big
            full
            value={
              <FormattedMessage id="profile.backToMainPage" defaultMessage="Back to start page" />
            }
          />
        </Link>
      </div>
    );
  } else if (firstPage) {
    content = renderFistPage();
  } else {
    const errorList = error || accountRegistration.error;
    const countriesList = countries.map((country: ICountry) => {
      return { label: country.value, value: country.key };
    });

    content = (
      <div>
        <H1>
          <FormattedMessage
            id="registerAccount.RegisterNewAccount"
            defaultMessage="Register new account"
          />
        </H1>
        <p className={styles.p}>
          <FormattedMessage
            id="registerAccount.EnterEmailAndPassword"
            defaultMessage="Please enter an email and password for your new account"
          />
        </p>
        {errorList ? (
          <AlertBox
            headerText={intl.formatMessage({
              id: 'general.generalError',
              defaultMessage: 'Oops, there was an error',
            })}
            messages={errorList.messages.map((m: { text: string }) => m.text)}
          />
        ) : null}
        <div className={styles.inputGroup}>
          <InputItem
            label={intl.formatMessage({ id: 'general.email', defaultMessage: 'Email' })}
            type="text"
            onChange={emailChanged}
            onKeyPress={handleKeyPress}
            value={email}
          />
          <div className={styles.inputRow}>
            <InputItem
              label={intl.formatMessage({
                id: 'general.password',
                defaultMessage: 'Password',
              })}
              type="password"
              onChange={passwordChanged}
              onKeyPress={handleKeyPress}
              value={password}
            />
            <InputItem
              label={intl.formatMessage({
                id: 'registerAccount.repeatPassword',
                defaultMessage: 'Repeat Password',
              })}
              type="password"
              onChange={passwordRepeatChanged}
              onKeyPress={handleKeyPress}
              value={passwordRepeat}
            />
          </div>
          <p className={styles.comment}>
            <FormattedMessage
              id="registerAccount.Comment"
              defaultMessage="The password must be at least 6 characters long and contain: uppercase, lowercase, number and special sign."
            />
          </p>
          <p className={styles.p}>
            <FormattedMessage
              id="registerAccount.TermsAndConditions"
              defaultMessage="Terms and conditions"
            />
          </p>
          <InputItem
            label={intl.formatMessage({
              id: 'registerAccount.SelectCountry',
              defaultMessage: 'Please select country for applicable terms and condition',
            })}
            type="search-select"
            options={countriesList}
            onChange={countryChanged}
            value={eulaCountry}
          />
          {renderTerms()}
        </div>
        <Button
          primary
          big
          full
          value={intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' })}
          rightIcon="forward"
          onClick={registerAccount}
          disabled={accountRegistration.busy || disableNext()}
          loading={accountRegistration.busy}
        />
        <Link to="/">
          <Button
            rightIcon="back"
            className={styles.cancelButton}
            big
            full
            value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          />
        </Link>
      </div>
    );
  }
  return (
    <div className={styles.registrationPage}>
      <div className={styles.fullHeight}>
        <Section>
          <SectionInner>
            <div className={styles.registrationPanelWrapper}>
              <div className={styles.registrationPanel}>
                <div className={styles.logo}>
                  <Logo />
                </div>
                {content}
              </div>
            </div>
          </SectionInner>
        </Section>
      </div>
      <Modal
        header={intl.formatMessage({
          id: 'registerAccount.TermsAndConditions',
          defaultMessage: 'Terms and conditions',
        })}
        show={showModal}
        onDismiss={toggleModal}
        dismissOnClick
      >
        {getEula.complete ? eula.data ? <div dangerouslySetInnerHTML={eula.data} /> : null : null}
      </Modal>
    </div>
  );
};

export default RegisterAccountPage;
