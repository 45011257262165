import { InputItem, InputRow } from '../../components/Shared/Forms';
import { ChangeEvent, FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { deviceNameIcon, idxNameIcon } from '../../constants/registerIllustrations';
import styles from './RegisterInstrumentModal.module.scss';

interface Props {
  instrumentName: string;
  selectedInstrumentType: string | null | undefined;
  instrumentNameChanged: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ChangeDeviceName: FC<Props> = ({
  instrumentName,
  selectedInstrumentType,
  instrumentNameChanged,
}) => {
  const intl = useIntl();

  return (
    <Fragment>
      <p>
        <FormattedMessage
          id="registerDevice.instrumentName"
          defaultMessage="In order for you to keep track of your instruments select a name for your instrument."
        />
      </p>
      <div className={styles.narrow}>
        <div className={styles.illustrationWrapper}>
          {selectedInstrumentType !== 'IDx' && deviceNameIcon}
          {selectedInstrumentType === 'IDx' && <img src={idxNameIcon} alt="idxName" />}
        </div>
        <InputRow>
          <InputItem
            type="text"
            placeholder={intl.formatMessage({
              id: 'registerDevice.instrumentNameLabel',
              defaultMessage: 'User’s instrument',
            })}
            onChange={instrumentNameChanged}
            value={instrumentName}
            dark
          />
        </InputRow>
      </div>
    </Fragment>
  );
  // }
};

export default ChangeDeviceName;
