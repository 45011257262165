import { MutableRefObject, useCallback, useState, FC } from 'react';
import { Center } from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import { GOTO_STEP, ADD_VISIT, Visit } from '../../../../../../redux/slices/procedureSlice';
import { RESTORE } from '../../../../../../redux/slices/workspaceSlice';
import { ActionCreators } from 'redux-undo';
import styled from 'styled-components';
import { Visit as VisitComponent } from './Visit';
import { useIntl } from 'react-intl';
import CircularButton from '../../../../../Shared/CircularButton/CircularButton';
import { RootState } from '../../../../../../redux/store';

interface Props {
  handleTakeScreenshot: () => Promise<string | undefined>;
  visitContainerRef: MutableRefObject<null>;
}

export const VisitsContainer: FC<Props> = ({ handleTakeScreenshot, visitContainerRef }) => {
  const intl = useIntl();
  const workspacePresent = useAppSelector((state) => state.consultation.workspace.present);
  const visits = useAppSelector((state: RootState) => state.consultation.procedure.visits);
  const workspaceUnsavedPastLength = useAppSelector(
    (state) => state.consultation.workspace.past.length
  );
  const hasUnsavedChanges = workspaceUnsavedPastLength > 0;
  const dispatch = useAppDispatch();
  const [hoveredVisitIndex, setHoveredVisitIndex] = useState<number | undefined>(undefined);
  const [isStepDragged, setIsStepDragged] = useState<boolean>(false);
  const restoreState = useCallback(
    function restoreState(stepIndex, visitIndex) {
      const procedureState = visits[visitIndex].procedureStepStates[stepIndex];
      if (procedureState) {
        dispatch(RESTORE(procedureState.state));
        dispatch(ActionCreators.clearHistory());
      }
    },
    [dispatch, visits]
  );

  const visitTop = 23; // px
  const visitBottom = 57; // px
  const stepHeight = 60; // px

  const getVisitOffsetArray = () => {
    const visitHeights = visits.map(
      (visit: Visit) => visitTop + visit.procedureStepStates.length * stepHeight + visitBottom
    );
    return visitHeights.map((_elem: any, index: number) =>
      visitHeights.slice(0, index + 1).reduce((a: any, b: any) => a + b)
    );
  };

  const visitOffsetArray = getVisitOffsetArray();

  return (
    <StyledVisitContainer ref={visitContainerRef}>
      {visits.map((visit: Visit, index: number) => {
        return (
          <VisitComponent
            setIsStepDragged={(isStepDragged) => setIsStepDragged(isStepDragged)}
            setHoveredVisit={(visitIndex) => setHoveredVisitIndex(visitIndex)}
            isHovered={hoveredVisitIndex === index && isStepDragged}
            visitOffsetArray={visitOffsetArray}
            handleTakeScreenShot={() => handleTakeScreenshot()}
            key={index}
            visitName={visit.name}
            visitIndex={index}
            hasUnsavedChanges={hasUnsavedChanges}
            procedureStepStates={visit.procedureStepStates}
            onClickImage={(stepIndex, visitIndex) => {
              if (hasUnsavedChanges) {
                handleTakeScreenshot()
                  .then((screenshotImgSrc) => {
                    dispatch(
                      GOTO_STEP({
                        stepIndex,
                        visitIndex,
                        workspace: workspacePresent,
                        screenshot: screenshotImgSrc || '',
                        shouldUpdateCurrent: true,
                      })
                    );
                    restoreState(stepIndex, visitIndex);
                  })
                  .catch((e) => console.error(e));
              } else {
                dispatch(
                  GOTO_STEP({
                    stepIndex,
                    visitIndex,
                    shouldUpdateCurrent: false,
                  })
                );
                restoreState(stepIndex, visitIndex);
              }
            }}
          />
        );
      })}
      <div className="addVisit">
        <CircularButton
          dataCy="consultation-add-visit"
          icon="add"
          buttonText={intl.formatMessage({
            id: 'consultation.consultation.steptoolbar.div',
            defaultMessage: 'Add visit',
          })}
          onClick={() => {
            handleTakeScreenshot()
              .then((screenshotImgSrc) => {
                dispatch(
                  ADD_VISIT({
                    name: intl.formatMessage({
                      id: 'consultation.visit',
                      defaultMessage: 'Visit',
                    }),
                    stepWorkspace: workspacePresent,
                    stepScreenshot: screenshotImgSrc,
                  })
                );
              })
              .catch((e) => console.error(e));
          }}
        />
      </div>
    </StyledVisitContainer>
  );
};

const StyledVisitContainer = styled.div`
  ${Center};
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(0, -50%);
  height: 100%;
  min-width: 135px;
  padding: 20px 0 20px 30px;
  overflow: hidden scroll;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
    width: 12px;
  }
  ::-webkit-scrollbar:horizontal {
    height: 12px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #822973;
    border-radius: 8px;
    border: 2px solid #edeef7;
  }
  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
  }
  .addVisit {
    width: 112px;
    min-height: 50px;
    border-radius: 8px;
    border: 3px solid #822973;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
