import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import Resizer from 'react-image-file-resizer';

interface Props {
  maxWidth: number;
  maxHeight: number;
  label: string;
  image: string;
  onUpload: (e: string) => void;
}
const ImageUploader: React.FC<Props> = ({ maxWidth, maxHeight, label, image, onUpload }) => {
  const resizeImage = (file: File, maxWidth: number, maxHeight: number): Promise<string> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri as string);
        },
        'base64'
      );
    });
  };

  const onDrop = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (!fileList || fileList.length === 0) return;

    const picture = fileList[0];
    if (picture === undefined || !picture.type.startsWith('image/')) return;
    const image = await resizeImage(picture, maxWidth, maxHeight);
    if (onUpload) onUpload(image);
  };

  return (
    <Uploader>
      <input type="file" name="file" id="file" accept="image/*" onChange={onDrop} />
      <label htmlFor="file">
        {image && <img src={image} alt="" />}
        <span>{label}</span>
      </label>
    </Uploader>
  );
};

const Uploader = styled.div`
  width: 100px;
  height: 100px;
  border: 2px dashed white;
  border-radius: 16px;

  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
    height: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
    }
    span {
      position: absolute;
      background: white;
      opacity: 0.7;
      border-radius: 5px;
      padding: 0 5px;
    }
  }
`;

export default ImageUploader;
