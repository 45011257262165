import Store from '../redux/store';
import * as accountActions from '../redux/actions/accountActions';
import Storage from './storage';
import history from './history';
import { DownloadBlob } from './nativeHelpers';

class ErrorWithMultipleMessages extends Error {
  constructor(error) {
    super(error.message);
    this.statusCode = error.statusCode;
    this.code = error.statusCode.toString();
    this.messages = error.messages ? error.messages : [{ key: error.message, text: error.message }];
    this.error = error;
  }
}

class HttpError extends ErrorWithMultipleMessages {
  constructor(error) {
    super(error);
    this.name = 'HttpError';
  }
}

class ApiFetch {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_API_URL}/api/`;
    this.parseJSON = this.parseJSON.bind(this);
  }

  setToken(token) {
    this.token = token;
  }

  clearToken() {
    this.token = null;
  }

  createHeaders(skipAuthorization, contentType) {
    if (!this.token && skipAuthorization !== true) {
      throw new Error('Token not set');
    }

    const headers = new Headers({
      'Content-Type': contentType ? contentType + '; boundary=&&--' : 'application/json',
    });

    if (skipAuthorization !== true) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }

    return headers;
  }

  get(url, skipAuthorization) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'get');
    return fetch(`${this.baseUrl}${url}`, {
      headers: this.createHeaders(skipAuthorization),
    }).then(this.parseJSON);
  }

  post(url, body, skipAuthorization) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'post');
    return fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: this.createHeaders(skipAuthorization),
    }).then(this.parseJSON);
  }

  postFormData(url, formData) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'post');

    const headers = new Headers({
      Authorization: `Bearer ${this.token}`,
    });

    return fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      body: formData,
      headers: headers,
    }).then(this.parseJSON);
  }

  postFile(url, fileData) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'post');
    const formData = new FormData();
    formData.append('file', fileData);

    const headers = new Headers({
      Authorization: `Bearer ${this.token}`,
    });

    return fetch(`${this.baseUrl}${url}`, {
      method: 'POST',
      body: formData,
      headers: headers,
    }).then(this.parseJSON);
  }

  downloadFile(url, callback, model) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, model ? 'post' : 'get');
    const headers = new Headers({
      Authorization: `Bearer ${this.token}`,
    });

    let fileName = 'file';

    const payload = {
      headers: headers,
    };

    if (model) {
      payload.method = 'POST';
      payload.body = JSON.stringify(model);
      headers.append('Content-Type', 'application/json');
    }

    return fetch(`${this.baseUrl}${url}`, payload)
      .then(function (response) {
        if (response.status === 401) {
          //Token expired (unauthorized)
          Store.dispatch(accountActions.clearToken(true, false));
          history.push('/login');
          return;
        } else if (response.status !== 200) {
          throw new HttpError({ message: 'Failed to download file', statusCode: response.status });
        }

        if (response.headers.get('FileName')) fileName = response.headers.get('FileName');

        return response.blob();
      })
      .then((blob) => {
        if (!blob) return;

        DownloadBlob(blob, fileName).then(() => {
          if (callback) callback();
        });
      });
  }

  put(url, body, skipAuthorization) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'put');
    return fetch(`${this.baseUrl}${url}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: this.createHeaders(skipAuthorization),
    }).then(this.parseJSON);
  }

  delete(url, body, skipAuthorization) {
    // Storage.storeApiCall(`${this.baseUrl}${url}`, 'delete');
    return fetch(`${this.baseUrl}${url}`, {
      method: 'DELETE',
      body: body ? JSON.stringify(body) : null,
      headers: this.createHeaders(skipAuthorization),
    }).then(this.parseJSON);
  }

  parseJSON(response) {
    const body = response.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });

    if (response.status >= 200 && response.status < 300) {
      return body;
    }

    return body.then((error) => {
      console.log('Status code: ' + response.status);
      console.log(error);

      Storage.storeError(error);

      const errorMessages = [];

      if (error.modelState) {
        Object.keys(error.modelState).forEach(function (key) {
          error.modelState[key].forEach(function (errorText) {
            if (errorMessages.indexOf(errorText) === -1) {
              const errorKey = key.substring(key.lastIndexOf('.') + 1);
              errorMessages.push({ key: errorKey, text: errorText });
            }
          });
        });
      } else if (error) {
        if (response.status === 500) {
          errorMessages.push({ key: error, text: 'Unhandled error occurred' });
        } else if (response.status === 409) {
          errorMessages.push({ key: error, text: 'Conflict' });
        } else if (response.status === 401) {
          //Token expired (unauthorized)
          if (history.location.pathname !== '/confirm') {
            Store.dispatch(accountActions.clearToken(true, false));
            history.push('/login');
          }
        } else {
          if (error.message) {
            errorMessages.push({ key: error.message, text: error.message });
          } else {
            errorMessages.push({ key: '', text: '' });
          }
        }
      }
      throw {
        error: error,
        statusCode: response.status,
        messages: errorMessages,
        message: error.message ?? errorMessages[0]?.text,
      };
    });
  }
}

export default new ApiFetch();
