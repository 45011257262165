import { H1 } from '../Shared/Headings';
import { IDentalNotation } from '../../types/dentalNotation';
import { implantDataState } from '../../constants/defaultStates';
import { IImplant, IImplantData } from '../../types/implant';
import { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ExportSection from './ExportSection';
import EditableItem from '../Shared/EditableItem/EditableItem';
import ImplantChart from '../implants/chart/ImplantChart';
import DentalChart from '../DentalChart/DentalChart';
import styles from './PatientExport.module.scss';

interface Props {
  edit: boolean;
  implant: IImplant;
  implants: IImplantData[];
  studyImplantData: IImplantData[];
  dentalNotifications: IDentalNotation[];
}

const RenderImplantsReport: FC<Props> = ({
  edit,
  implant,
  implants,
  studyImplantData,
  dentalNotifications,
}) => {
  const intl = useIntl();
  const [implantData, setImplantData] = useState<IImplantData>(implantDataState);

  const notification = dentalNotifications.find(
    (notification: IDentalNotation) => notification.uns === implant.tooth
  );

  const getStatus = (implant: IImplant) => {
    if (implant.dataId === -1)
      return intl.formatMessage({
        id: 'patient.awaitingImplant',
        defaultMessage: 'Awaiting implant',
      });
    if (implant.loadingDate)
      return intl.formatMessage({
        id: 'patient.readyForFinalRestoration',
        defaultMessage: 'Ready for final restoration',
      });
    if (implant.status === 'AwaitingLoading')
      return intl.formatMessage({
        id: 'patient.awaitingLoading',
        defaultMessage: 'Awaiting loading',
      });
    return implant.status;
  };

  const renderISQList = (implant: IImplant) => {
    const { formatDate } = intl;

    if (implant.measurements.length === 0) {
      return (
        <div>
          <FormattedMessage id="patientexport.noMeasurements" defaultMessage="No measurements" />
        </div>
      );
    }

    return orderedMeasurements.map((measurement) => {
      let typeText = 'ISQ ';

      if (measurement.type === 'DuringPlacement') {
        typeText = typeText.concat(
          intl.formatMessage({
            id: 'patientexport.isqAtInsertion',
            defaultMessage: 'at implant insertion',
          })
        );
      } else if (measurement.type === 'DuringFollowup') {
        typeText = typeText.concat(
          intl.formatMessage({
            id: 'patientexport.isqAtFollowup',
            defaultMessage: 'at follow up',
          })
        );
      } else if (measurement.type === 'DuringLoading') {
        typeText = typeText.concat(
          intl.formatMessage({
            id: 'patientexport.isqAtPlacement',
            defaultMessage: 'before final restoration',
          })
        );
      }

      const isqValue =
        measurement.isqNoDir > 0 ? (
          <span className={styles.patientData}>{measurement.isq}</span>
        ) : (
          <div>
            BL: <span className={styles.patientData}>{measurement.isqBl}</span>, MD:{' '}
            <span className={styles.patientData}>{measurement.isqMd}</span>
          </div>
        );

      return (
        <div className={styles.isqList} key={measurement.id}>
          <div style={{ display: 'flex' }}>
            {typeText}:&nbsp;{isqValue}
          </div>
          <div>
            {formatDate(measurement.date, { year: 'numeric', month: 'short', day: 'numeric' })}
          </div>
        </div>
      );
    });
  };

  const orderedMeasurements = [...implant.measurements].sort((measurement, otherMeasurement) => {
    return new Date(measurement.date).getTime() - new Date(otherMeasurement.date).getTime();
  });

  const lastIsq =
    orderedMeasurements.length > 0 ? orderedMeasurements[orderedMeasurements.length - 1].isq : null;

  const placementDate = new Date(implant.placementDate || '');
  const loadingDate = new Date(implant.loadingDate || '');

  const healingTime = Math.floor((loadingDate.getTime() - placementDate.getTime()) / 86400000);

  useEffect(() => {
    let data = implants.find((data: IImplantData) => data.dataId === implant.dataId);

    if (!data) {
      data = studyImplantData.find((data: IImplantData) => data.dataId === implant.dataId);
    }

    if (!data) return;

    setImplantData(data);
  }, [implant.dataId, implants, studyImplantData]);

  return (
    <div className={styles.implantSection} key={implant.tooth} id="implantSection">
      <ExportSection className={styles.section}>
        <div className={styles.positionWrapper}>
          <div>
            <H1 className={styles.sectionHeader}>
              <FormattedMessage id="patientexport.position" defaultMessage="Position" />
            </H1>
            {notification && (
              <span className={styles.dentalNotifications}>
                UNS: {notification.uns} FDI:{notification.fdi}
              </span>
            )}
          </div>
          <div className={styles.dentalChart}>
            <DentalChart static small implants={[{ tooth: implant.tooth, isq: lastIsq }]} />
          </div>
        </div>
      </ExportSection>
      <ExportSection className={styles.section}>
        <div className={styles.treatmentWrapper}>
          <div>
            <H1 className={styles.sectionHeader}>
              <FormattedMessage id="patientexport.treatmentdata" defaultMessage="Treatment data" />
            </H1>
            <div className={styles.treatmentSection}>{renderISQList(implant)}</div>
            <div>
              <div className={styles.treatmentSection}>
                <FormattedMessage
                  id="patientexport.insertionTorque"
                  defaultMessage="Maximum Insertion Torque (Ncm)"
                />
                :{' '}
                <span className={styles.patientData}>
                  {implant.insertionTorque > 0 ? implant.insertionTorque : ''}
                </span>
              </div>
              <div className={styles.treatmentSection}>
                <div>
                  <FormattedMessage id="general.status" defaultMessage="Status" />:{' '}
                  <span className={styles.patientData}>{getStatus(implant)}</span>
                </div>
                <div>
                  <FormattedMessage
                    id="patientexport.prostheticSolution"
                    defaultMessage="Prosthetic solution"
                  />
                  :{' '}
                  <span className={styles.patientData}>
                    {intl.formatMessage({
                      id: ''.concat('referenceData.', implant.plannedProstheticSolution),
                      defaultMessage: implant.plannedProstheticSolution,
                    })}
                  </span>
                </div>
                <div>
                  <FormattedMessage
                    id="patientexport.surgicalProtocol"
                    defaultMessage="Surgical protocol"
                  />
                  :{' '}
                  <span className={styles.patientData}>
                    {intl.formatMessage({
                      id: ''.concat('referenceData.', implant.surgicalProtocol),
                      defaultMessage: implant.surgicalProtocol,
                    })}
                  </span>
                </div>
                <div>
                  <FormattedMessage
                    id="patientexport.loadingProtocol"
                    defaultMessage="Loading protocol"
                  />
                  :{' '}
                  <span className={styles.patientData}>
                    {intl.formatMessage({
                      id: ''.concat('referenceData.', implant.loadingProtocol),
                      defaultMessage: implant.loadingProtocol,
                    })}
                  </span>
                </div>
                <div>
                  <FormattedMessage id="patientexport.healingTime" defaultMessage="Healing time" />:{' '}
                  <span className={styles.patientData}>
                    {healingTime > 0 ? (
                      <FormattedMessage
                        id="patientexport.days"
                        defaultMessage="{days} days"
                        values={{ days: healingTime }}
                      />
                    ) : (
                      ''
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.chartWrapper}>
            <ImplantChart
              measurements={orderedMeasurements}
              tooth={implant.tooth}
              noColor={undefined}
              excludeAbutment={false}
              projection={undefined}
              showCreateMeasurementModal={undefined}
              showDetails={false}
            />
          </div>
        </div>
      </ExportSection>
      <ExportSection className={styles.section}>
        <H1 className={styles.sectionHeader}>
          <FormattedMessage id="patientexport.implantdata" defaultMessage="Implant Data" />
        </H1>
        <div>
          <div className={styles.implantDataRow}>
            <div>
              <FormattedMessage id="patientexport.brand" defaultMessage="Brand" />:{' '}
              {implantData.brandName}
            </div>
            <div>
              <FormattedMessage id="patientexport.model" defaultMessage="Model" />:{' '}
              {implantData.modelName}
            </div>
            <div>
              <FormattedMessage id="patientexport.articleNumber" defaultMessage="Article Number" />:{' '}
              {implantData.partNumber}
            </div>
          </div>
          <div className={styles.implantDataRow}>
            <div>
              <FormattedMessage id="patientexport.dimater" defaultMessage="Diameter (mm)" />:{' '}
              {implantData.diameter}
            </div>
            <div>
              <FormattedMessage id="patientexport.length" defaultMessage="Length (mm)" />:{' '}
              {implantData.length}
            </div>
            <div>
              <FormattedMessage id="patientexport.platform" defaultMessage="Platform" />:{' '}
              {implantData.platformName}
            </div>
          </div>
          <div className={styles.implantDataRow}>
            <div>
              <FormattedMessage id="patientexport.smartPeg" defaultMessage="Suitable SmartPeg" />:{' '}
              {implantData.smartPeg}
            </div>
          </div>
        </div>
      </ExportSection>
      <ExportSection className={styles.section}>
        <div>
          <div>
            <FormattedMessage id="patientexport.comment" defaultMessage="Comment" />:{' '}
            <EditableItem type="textarea" edit={edit} value={implant.comment} />
          </div>
        </div>
      </ExportSection>
    </div>
  );
};

export default RenderImplantsReport;
