import React from 'react';
import styles from './Table.module.scss';
import cn from 'classnames';

const TR = (props) => (
  <tr
    className={cn(
      props.className,
      props.header === true ? styles.trHead : styles.trBody,
      styles.tr
    )}
    onClick={props.onClick}
  >
    {props.children}
  </tr>
);

export default TR;
