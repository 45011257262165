import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toastr } from 'react-redux-toastr';
import { injectIntl } from 'react-intl';

import Modal from '../Shared/Modal/Modal';
import { Button } from '../Shared/Buttons';
import FreeSmartPegs from './FreeSmartPegs';

import * as clinicActions from '../../redux/actions/clinicActions';
import * as promotionActions from '../../redux/actions/promotionActions';
import * as generalActions from '../../redux/actions/generalActions';

class FreeSmartPegsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canProceed: false,
    };
    this.intl = props.intl;

    this.hideModal = this.hideModal.bind(this);
    this.getData = this.getData.bind(this);
    this.sendOrder = this.sendOrder.bind(this);
    this.updateClinicAddress = this.updateClinicAddress.bind(this);

    this.sendingOrder = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.usePromotion.complete && this.sendingOrder) {
      toastr.success(
        this.intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        this.intl.formatMessage({
          id: 'freeSmartpegs.SmartPegsOrdered',
          defaultMessage: 'Free SmartPegs ordered',
        }),
        { showCloseButton: false }
      );
      this.sendingOrder = false;
      this.hideModal();
    } else if (nextProps.usePromotion.error && this.sendingOrder) {
      nextProps.usePromotion.error.messages.forEach((m) =>
        toastr.error(
          this.intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
          this.intl.formatMessage({ id: m.text }),
          { showCloseButton: false }
        )
      );
      this.sendingOrder = false;
      this.hideModal();
    }
  }

  hideModal() {
    this.setState(
      {
        canProceed: false,
      },
      () => {
        if (this.props.hide) {
          this.props.hide();
        }
      }
    );

    this.order = undefined;
  }

  sendOrder() {
    if (
      this.order &&
      this.order.smartPegTypes.some((smartPegType) => smartPegType !== '-1') &&
      this.props.freeSmartPegs.promotion
    ) {
      if (process.env.REACT_APP_DEMO) {
        const content = (
          <div>
            <p>
              {this.intl.formatMessage({
                id: 'freeSmartpegs.NoSmartPegsSent',
                defaultMessage: 'For demo purposes only. No SmartPegs will be sent.',
              })}
            </p>
          </div>
        );
        this.props.actions.showAlert(
          this.intl.formatMessage({
            id: 'freeSmartpegs.DemoLimitation',
            defaultMessage: 'DEMO Limitation',
          }),
          content
        );
        return;
      }
      this.sendingOrder = true;
      this.props.actions.getFreeSmartPegs(this.props.freeSmartPegs.promotion.id, this.order);
      this.updateClinicAddress();
    }
  }

  updateClinicAddress() {
    let clinic = {
      ...this.props.clinic,
      name: this.order.clinicName,
      address: {
        name: this.order.clinicName,
        street: this.order.clinicAddress.street,
        careOf: this.order.clinicAddress.careOf,
        city: this.order.clinicAddress.city,
        postcode: this.order.clinicAddress.postcode,
        countryCode: this.order.clinicAddress.countryCode,
        state: this.order.clinicAddress.state,
      },
    };

    this.props.actions.updateClinic(clinic);
  }

  getData(clinicAddress, smartPegTypes) {
    this.order = {
      clinicName: clinicAddress.name,
      clinicAddress: clinicAddress,
      smartPegTypes: smartPegTypes,
      megagenUser: this.props.user.megagenUser,
    };

    this.setState({
      canProceed:
        this.order &&
        this.order.smartPegTypes.filter((smartPegType) => smartPegType !== '-1').length >=
          this.props.freeSmartPegs.promotion.quantity,
    });
  }

  render() {
    if (this.props.show === true) {
      return (
        <Modal
          header={this.intl.formatMessage({
            id: 'freeSmartpegs.Header',
            defaultMessage: 'Collect free SmartPegs',
          })}
          onDismiss={this.hideModal}
          show={this.props.show}
        >
          <FreeSmartPegs
            sendData={this.getData}
            quantity={this.props.freeSmartPegs.promotion.quantity}
          />
          <Button
            primary
            big
            full
            value={this.intl.formatMessage({
              id: 'freeSmartpegs.SendOrder',
              defaultMessage: 'Send Order',
            })}
            onClick={this.sendOrder}
            disabled={!this.state.canProceed || this.props.usePromotion.busy}
            loading={this.props.usePromotion.busy}
          />
        </Modal>
      );
    }
    return null;
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    user: state.user.user,
    clinic: state.clinic.clinic,
    freeSmartPegs: state.general.freeSmartPegs,
    usePromotion: state.promotion.usePromotion,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      { ...clinicActions, ...promotionActions, ...generalActions },
      dispatch
    ),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(FreeSmartPegsModal));
