import React, { Component } from 'react';

import styles from './Panel.module.scss';
import Dropdown from '../Dropdown/Dropdown';

class PanelOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOptions: false,
    };

    this.activateOptions = this.activateOptions.bind(this);
  }

  activateOptions(event) {
    this.setState({
      showOptions: !this.state.showOptions,
    });

    event.preventDefault();
    event.stopPropagation();
  }

  render() {
    return (
      <div className={styles.optionsWrapper}>
        <Dropdown className={styles.optionButton} options={this.props.options} align="right">
          <i className="osstell-icons">more</i>
        </Dropdown>
      </div>
    );
  }
}

export default PanelOptions;
