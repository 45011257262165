import { useIntl } from 'react-intl';
import { FC, useState } from 'react';
import { IClinicMember } from '../../types/clinic';
import { useAppSelector } from '../../utils/hooks';
import { useGetClinicQuery } from '../../redux/api/clinic/clinicApi';
import { UserPermissionEnum } from '../../constants/enums';
import {
  Button,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import InviteUserForm from '../../components/users-tab-forms/InviteUserForm';
import EditUserForm from '../../components/users-tab-forms/EditUserForm';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ErrorPanel from '../../components/error-panel/ErrorPanel';
import Spinner from '../../components/Shared/Loaders/Spinner';
import AddIcon from '@mui/icons-material/Add';
import styles from './ClinicPage.module.scss';

const UsersTabPanel: FC = () => {
  const intl = useIntl();
  const { user } = useAppSelector((state) => state.user);
  const { data: clinic, isLoading, error, isFetching } = useGetClinicQuery();

  const userPermission = user.sourceUserGroup.userPermission;

  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editUser, setEditUser] = useState<IClinicMember | undefined>({
    id: '',
    profession: '',
    firstName: '',
    lastName: '',
    email: '',
    permission: '',
  });

  const openInviteModalHandler = () => setIsInviteModalOpen(true);
  const closeInviteModalHandler = () => setIsInviteModalOpen(false);

  const openEditModalHandler = (user: IClinicMember) => {
    setEditUser(user);
    setIsEditModalOpen(true);
  };
  const closeEditModalHandler = () => {
    setEditUser(undefined);
    setIsEditModalOpen(false);
  };

  if (error && 'status' in error)
    return <ErrorPanel error={{ data: { message: 'test' }, status: 400 }} />;

  return (
    <Paper sx={{ minHeight: '70vh' }}>
      {isLoading || isFetching ? (
        <Spinner purple />
      ) : (
        <Stack spacing={2}>
          {userPermission === UserPermissionEnum.ADMIN && (
            <div className={styles.filterPanel}>
              <Button startIcon={<AddIcon />} onClick={openInviteModalHandler}>
                {intl.formatMessage({
                  id: 'users.inviteUser',
                  defaultMessage: 'Invite user',
                })}
              </Button>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {intl.formatMessage({ id: 'general.name', defaultMessage: 'Name' })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'general.profession',
                      defaultMessage: 'Profession',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'general.email',
                      defaultMessage: 'Email',
                    })}
                  </TableCell>
                  <TableCell>
                    {intl.formatMessage({
                      id: 'general.userPermission',
                      defaultMessage: 'User permission',
                    })}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clinic?.members.map((u, idx) => (
                  <TableRow key={idx} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{u.id ? `${u.firstName + ' ' + u.lastName}` : ''}</TableCell>
                    <TableCell>{u.id ? u.profession : ''}</TableCell>
                    <TableCell>{u.email}</TableCell>
                    <TableCell>
                      {u.id
                        ? u.permission
                        : intl.formatMessage({
                            id: 'users.pendingInvite',
                            defaultMessage: 'Pending invite',
                          })}
                    </TableCell>
                    <TableCell align="right">
                      {user.id !== u.id && userPermission === UserPermissionEnum.ADMIN ? (
                        <IconButton onClick={() => openEditModalHandler(u)}>
                          <MoreVertIcon />
                        </IconButton>
                      ) : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <InviteUserForm
            isOpen={isInviteModalOpen}
            userGroupId={clinic?.userGroupId || ''}
            onDialogCloseClick={closeInviteModalHandler}
          />
          {isEditModalOpen && (
            <EditUserForm
              user={editUser}
              isOpen={isEditModalOpen}
              userGroupId={clinic?.userGroupId || ''}
              onDialogCloseClick={closeEditModalHandler}
            />
          )}
        </Stack>
      )}
    </Paper>
  );
};

export default UsersTabPanel;
