import { INewStudy, IStudyGroup } from '../../types/studyGroups';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiFetch from '../../../src/utils/apiFetch';

interface ActionStatus {
  complete: boolean;
  busy: boolean;
  error?: string;
}

interface studyGroupState {
  getStudyGroupsStatus: ActionStatus;
  exportStudyStatus: ActionStatus;
  createStudyStatus: ActionStatus;
  updateStudyStatus: ActionStatus;
  deleteStudyStatus: ActionStatus;
  acceptStudyStatus: ActionStatus;
  leaveStudyStatus: ActionStatus;
  studyGroups: IStudyGroup[];
}

const actionStatusInitial = {
  complete: false,
  error: undefined,
  busy: false,
};

export const initialState: studyGroupState = {
  getStudyGroupsStatus: actionStatusInitial,
  exportStudyStatus: actionStatusInitial,
  createStudyStatus: actionStatusInitial,
  updateStudyStatus: actionStatusInitial,
  deleteStudyStatus: actionStatusInitial,
  acceptStudyStatus: actionStatusInitial,
  leaveStudyStatus: actionStatusInitial,
  studyGroups: [],
};

export const getStudyGroups = createAsyncThunk(
  'studygroups/getStudyGroups',
  async (admin: boolean) => {
    const response = await ApiFetch.get(admin ? 'admin/studyGroups' : 'studygroups');
    return response;
  }
);

export const exportStudy = createAsyncThunk(
  'studygroups/exportStudy',
  async (studyGroupId: string) => {
    const response = await ApiFetch.downloadFile(`studyGroups/${studyGroupId}/export`);
    return response;
  }
);

export const createStudy = createAsyncThunk(
  'studygroups/createStudy',
  async (studyGroup: INewStudy) => {
    const response = await ApiFetch.post(`studyGroups`, studyGroup);
    return response;
  }
);

export const updateStudy = createAsyncThunk(
  'studygroups/updateStudy',
  async (studyGroup: IStudyGroup) => {
    const response = await ApiFetch.put(`studyGroups/${studyGroup.id}`, studyGroup);
    return response;
  }
);

export const deleteStudy = createAsyncThunk(
  'studygroups/deleteStudy',
  async (studyGroupId: string) => {
    const response = await ApiFetch.delete(`studyGroups/${studyGroupId}`);
    return response;
  }
);

export const leaveStudy = createAsyncThunk(
  'studygroups/leaveStudy',
  async (studyGroupId: string) => {
    const response = await ApiFetch.delete(`studyGroups/${studyGroupId}/members`);
    return response;
  }
);

export const acceptStudy = createAsyncThunk(
  'studygroups/acceptStudy',
  async (studyGroupId: string) => {
    const response = await ApiFetch.put(`studyGroups/${studyGroupId}/members`);
    return response;
  }
);

export const getUserId = createAsyncThunk('studygroups/getUser', async (email: string) => {
  const response = await ApiFetch.get(`users/userid?email=${email}`);
  return response;
});

export const studyGroupSlice = createSlice({
  name: 'studyGroups',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Get StudyGroups
    builder.addCase(getStudyGroups.fulfilled, (state, action) => {
      state.studyGroups = action.payload;
      state.getStudyGroupsStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(getStudyGroups.rejected, (state, action) => {
      state.exportStudyStatus.complete = true;
      state.getStudyGroupsStatus.error = action.error.message;
      state.getStudyGroupsStatus.busy = false;
    });
    builder.addCase(getStudyGroups.pending, (state, _) => {
      state.getStudyGroupsStatus.complete = false;
      state.getStudyGroupsStatus.error = undefined;
      state.getStudyGroupsStatus.busy = true;
    });

    //Export StudyGroup
    builder.addCase(exportStudy.fulfilled, (state, _) => {
      state.exportStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(exportStudy.rejected, (state, action) => {
      state.exportStudyStatus.complete = true;
      state.exportStudyStatus.error = action.error.message;
      state.exportStudyStatus.busy = false;
    });
    builder.addCase(exportStudy.pending, (state, _) => {
      state.exportStudyStatus.complete = false;
      state.exportStudyStatus.error = undefined;
      state.exportStudyStatus.busy = true;
    });

    //Create StudyGroup
    builder.addCase(createStudy.fulfilled, (state, _) => {
      state.createStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(createStudy.rejected, (state, action) => {
      state.createStudyStatus.complete = true;
      state.createStudyStatus.error = action.error.message;
      state.createStudyStatus.busy = false;
    });
    builder.addCase(createStudy.pending, (state, _) => {
      state.createStudyStatus.complete = false;
      state.createStudyStatus.error = undefined;
      state.createStudyStatus.busy = true;
    });

    //Update StudyGroup
    builder.addCase(updateStudy.fulfilled, (state, _) => {
      state.updateStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(updateStudy.rejected, (state, action) => {
      state.updateStudyStatus.complete = true;
      state.updateStudyStatus.error = action.error.message;
      state.updateStudyStatus.busy = false;
    });
    builder.addCase(updateStudy.pending, (state, _) => {
      state.updateStudyStatus.complete = false;
      state.updateStudyStatus.error = undefined;
      state.updateStudyStatus.busy = true;
    });

    //Delete StudyGroup
    builder.addCase(deleteStudy.fulfilled, (state, _) => {
      state.deleteStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(deleteStudy.rejected, (state, action) => {
      state.deleteStudyStatus.complete = true;
      state.createStudyStatus.error = action.error.message;
      state.deleteStudyStatus.busy = false;
    });
    builder.addCase(deleteStudy.pending, (state, _) => {
      state.deleteStudyStatus.complete = false;
      state.deleteStudyStatus.error = undefined;
      state.deleteStudyStatus.busy = true;
    });

    //Accept StudyGroup
    builder.addCase(acceptStudy.fulfilled, (state, _) => {
      state.acceptStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(acceptStudy.rejected, (state, action) => {
      state.acceptStudyStatus.complete = true;
      state.acceptStudyStatus.error = action.error.message;
      state.acceptStudyStatus.busy = false;
    });
    builder.addCase(acceptStudy.pending, (state, _) => {
      state.acceptStudyStatus.complete = false;
      state.acceptStudyStatus.error = undefined;
      state.acceptStudyStatus.busy = true;
    });

    //Leave StudyGroup
    builder.addCase(leaveStudy.fulfilled, (state, _) => {
      state.leaveStudyStatus = {
        complete: true,
        error: undefined,
        busy: false,
      };
    });
    builder.addCase(leaveStudy.rejected, (state, action) => {
      state.leaveStudyStatus.complete = true;
      state.leaveStudyStatus.error = action.error.message;
      state.leaveStudyStatus.busy = false;
    });
    builder.addCase(leaveStudy.pending, (state, _) => {
      state.leaveStudyStatus.complete = false;
      state.leaveStudyStatus.error = undefined;
      state.leaveStudyStatus.busy = true;
    });
  },
});
export default studyGroupSlice.reducer;
