import React, { Component } from 'react';
import classNames from 'classnames';
import Spinner from '../Loaders/Spinner';

import styles from './Buttons.module.scss';

class Button extends Component {
  render() {
    const cn = classNames(
      this.props.className,
      styles.button,
      this.props.iconButton && styles.iconButton,
      !this.props.noShadow && styles.shadow,
      this.props.light ? styles.light : null,
      this.props.white ? styles.white : null,
      this.props.primary === true ? styles.primary : null,
      this.props.secondary ? styles.secondary : null,
      this.props.full === true ? styles.full : null,
      this.props.big === true ? styles.big : null,
      this.props.small === true ? styles.small : null,
      this.props.leftAlign === true ? styles.leftAlign : null,
      this.props.centered === true ? styles.centered : null,
      this.props.disabled === true
        ? this.props.primary === true
          ? styles.disabledPrimary
          : styles.disabled
        : null,
      !this.props.loading && (this.props.rightIcon || this.props.leftIcon) ? styles.hasIcon : null
    );

    return (
      <button
        data-cy={this.props.dataCy}
        id={this.props.id}
        className={cn}
        onClick={this.props.onClick}
        disabled={this.props.disabled === true}
      >
        {!this.props.loading ? (
          <div>
            {this.props.leftIcon ? <i className={styles.leftIcon}>{this.props.leftIcon}</i> : null}
            {this.props.value}
            {this.props.rightIcon ? (
              <i className={styles.rightIcon}>{this.props.rightIcon}</i>
            ) : null}
          </div>
        ) : null}
        {this.props.loading && <Spinner purple={!this.props.primary} />}
      </button>
    );
  }
}

export default Button;
