import { H2 } from '../../components/Shared/Headings';
import { useHistory } from 'react-router';
import { Section, SectionInner } from '../../components/Shared/Section';
import { FormattedMessage, useIntl } from 'react-intl';
import isqLogo from '../../shared/images/isqLogo.png';
import Header from '../../components/header/Header';
import FBicon from '../../shared/images/FB.png';
import IGicon from '../../shared/images/IG.png';
import LIicon from '../../shared/images/LI.png';
import TWicon from '../../shared/images/TW.png';
import YTicon from '../../shared/images/YT.png';
import styles from './AboutPage.module.scss';

const AboutPage = () => {
  const intl = useIntl();
  const history = useHistory();

  const headerButtonsLeft = [
    {
      label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
      icon: 'back',
      action: history.goBack,
    },
  ];

  return (
    <div className={styles.aboutPage}>
      <Header
        title={intl.formatMessage({ id: 'about.Header', defaultMessage: 'ABOUT' })}
        leftButtons={headerButtonsLeft}
        isTogglerHidden
      />
      <div className={styles.header}>
        <div className={styles.headerText}>
          <FormattedMessage
            id="about.WeHelp"
            defaultMessage="We help clinicians provide each patient with optimal time to teeth"
          />
        </div>
      </div>
      <Section>
        <SectionInner className={styles.content}>
          <div className={styles.missionAndMadeInSwedenContainer}>
            <div className={styles.contentBox}>
              <H2>
                <FormattedMessage id="about.OurMission" defaultMessage="Our Mission" />
              </H2>
              <p className={styles.contentParagraph}>
                <FormattedMessage
                  id="about.OurMissionText"
                  defaultMessage="Osstell is the global leader in implant stability measurement and osseointegration progress monitoring. Osstell was formed in 1999 to commercialize Prof. Neil Meredith's invention using Resonance Frequency Analysis (RFA) to determine the clinical status of a dental implant. Osstell’s proprietary and patented technology helps its customers, dentists around the world, to provide each of their patients with optimal time to teeth, by measuring the stability of implants objectively and non-invasively using the ISQ scale (Implant Stability Quotient)."
                />
              </p>
            </div>
            <div className={styles.contentBox}>
              <H2>
                <FormattedMessage id="about.MadeInSweden" defaultMessage="Made in Sweden" />
              </H2>
              <FormattedMessage
                id="about.MadeInSwedenText"
                defaultMessage="Osstell is headquartered in Gothenburg, Sweden – the birthplace of dental implants. Since 2018, Osstell is part of the W&H group, one of the world’s leading manufacturers of dental precision instruments and devices.  Our devices are designed and manufactured in Sweden. "
              />
            </div>
          </div>
          <div className={styles.contentBox}>
            <div>
              <img className={styles.isqLogo} src={isqLogo} alt="" />
            </div>
            <div>
              <H2>
                <FormattedMessage id="about.EvidenceBased" defaultMessage="Evidence-based" />
              </H2>
              <FormattedMessage
                id="about.EvidenceBasedText"
                defaultMessage="More than 1 200 scientific publications confirm the benefits of the technology, its clinical value, and the use of the ISQ scale for dentists in their daily practices. Our regularly updated database, where we have compiled all scientific research relating to Osstell and ISQ, is accessible here: "
              />
              <a
                href="https://www.osstell.com/scientific-database/"
                rel="noreferrer"
                target="_blank"
              >
                www.osstell.com/scientific-database
              </a>
            </div>
          </div>
          <div className={styles.contact}>
            <H2>
              <FormattedMessage id="about.ConnectWithUs" defaultMessage="Connect with us" />
            </H2>
            <div className={styles.contactIcons}>
              <a href="https://www.facebook.com/osstellAB/" rel="noreferrer" target="_blank">
                <img src={FBicon} alt="" />
              </a>
              <a href="https://twitter.com/osstell" rel="noreferrer" target="_blank">
                <img src={TWicon} alt="" />
              </a>
              <a
                href="https://www.instagram.com/osstell_isq/?hl=en"
                rel="noreferrer"
                target="_blank"
              >
                <img src={IGicon} alt="" />
              </a>
              <a href="https://www.youtube.com/user/Osstell" rel="noreferrer" target="_blank">
                <img src={YTicon} alt="" />
              </a>
              <a href="https://www.linkedin.com/company/1022836" rel="noreferrer" target="_blank">
                <img src={LIicon} alt="" />
              </a>
            </div>
          </div>
          <div className={styles.versions}>
            <p>
              UDI
              <br />
              (01) 09010522001073
              <br />
              (8012) {process.env.REACT_APP_GIT_VERSION}
            </p>
            <p></p>
          </div>
        </SectionInner>
      </Section>
    </div>
  );
};

export default AboutPage;
