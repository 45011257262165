import * as React from 'react';

function SvgDeleteliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1443 2048" {...props}>
      <path d="M1321 478q9 0 15.5 6t6.5 15-6.5 15-15.5 6h-102v840q0 97-69 165.5T984 1594H460q-97 0-165.5-68.5T226 1360V520H124q-9 0-15.5-6t-6.5-15 6.5-15 15.5-6h327v-81q0-44 31-75t75-31h331q44 0 75 31t31 75v81h327zm-828-81v81h459v-81q0-26-19-45t-45-19H557q-26 0-45 19t-19 45zm683 963V520H269v840q0 80 55.5 135.5T460 1551h524q80 0 136-56t56-135zM472 754q9 0 15 6.5t6 15.5v503q0 9-6 15.5t-15 6.5-15-6.5-6-15.5V776q0-9 6-15.5t15-6.5zm251 0q9 0 15 6.5t6 15.5v503q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V776q0-9 6.5-15.5T723 754zm250 0q9 0 15 6.5t6 15.5v503q0 9-6 15.5t-15 6.5-15-6.5-6-15.5V776q0-9 6-15.5t15-6.5z" />
    </svg>
  );
}

export default SvgDeleteliga;
