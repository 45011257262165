import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/Draggable';
import { Center } from '../../../../../../common/graphics/lib/StyledComponentLibrary';

gsap.registerPlugin(Draggable);

interface Props {
  enabled?: boolean;
  setEnabled?: (value: boolean) => void;
  onDragEnd?: (event: PointerEvent, position: gsap.Point2D) => void;
  onDrag?: (event: PointerEvent, position: gsap.Point2D) => void;
  onClick?: (event: PointerEvent) => void;
  bounds?:
    | gsap.DOMTarget
    | Draggable.BoundsMinMax
    | Draggable.BoundsRectangle
    | Draggable.BoundsRotation;
  storedX: number;
  storedY: number;
}

export const TeethDraggableNode = React.forwardRef<HTMLDivElement, Props>(
  (
    { onDragEnd, onDrag, bounds, storedX = 0, storedY = 0, enabled = false, setEnabled = () => {} },
    ref
  ) => {
    const divRef = useRef<HTMLDivElement>(null);
    const dragRef = useRef<globalThis.Draggable[]>();

    const setEnabledState = useCallback(
      (enabled: boolean) => {
        const ref = dragRef?.current?.[0];
        setEnabled(enabled);
        ref?.enabled(enabled);
      },
      [setEnabled]
    );

    const onDragCallback = useCallback(
      (event, method) => {
        const positionX = gsap.getProperty(divRef.current, 'x');
        const positionY = gsap.getProperty(divRef.current, 'y');
        return method?.(event, {
          x: parseFloat(positionX.toString()),
          y: parseFloat(positionY.toString()),
        });
      },
      [divRef]
    );

    useEffect(() => {
      if (!(dragRef.current && dragRef.current[0].vars.onDrag)) {
        dragRef.current = Draggable.create(divRef?.current, {
          type: 'x,y',
          bounds: bounds,
          onDrag: (e: PointerEvent) => {
            onDragCallback(e, onDrag);
          },
          onDragEnd: (e: PointerEvent) => {
            onDragCallback(e, onDragEnd);
          },
          onClick: (_: PointerEvent) => {
            setEnabledState(!dragRef?.current?.[0].enabled());
          },
        });
      }
    }, [dragRef, onDragCallback, onDragEnd, onDrag, bounds, setEnabledState]);

    useEffect(() => {
      gsap.set(divRef.current, { x: storedX, y: storedY });
    }, [storedX, storedY]);

    useEffect(() => {
      bounds && dragRef.current?.[0].applyBounds(bounds);
    }, [bounds]);

    useEffect(() => {
      setEnabledState(enabled);
    }, [enabled, setEnabledState]);

    return (
      <NodeWrapper ref={ref}>
        <NodePoint ref={divRef} enabled={enabled}>
          <NodeSvg />
        </NodePoint>
      </NodeWrapper>
    );
  }
);

TeethDraggableNode.displayName = 'TeethDraggableNode';

const NodePoint = styled.div<{ enabled: boolean }>`
  ${Center};
  width: 32px;
  height: 32px;
  border-radius: 999px;
  opacity: ${(props) => (props.enabled ? '1' : '0.1')};
  transition: opacity 200ms ease-in;
  pointer-events: ${(props) => (props.enabled ? 'all' : 'none')};
`;
const NodeWrapper = styled.div`
  position: absolute;
  width: 32px;
  height: 32px;
  pointer-events: none;
`;

const NodeSvg = () => (
  <svg
    id="Nerv_Nodes"
    data-name="Nerv Nodes"
    data-html2canvas-ignore={true}
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    viewBox="0 0 23 23"
  >
    <path
      id="Subtraction_7"
      data-name="Subtraction 7"
      d="M11.5,23A11.5,11.5,0,0,1,3.368,3.368,11.5,11.5,0,0,1,19.632,19.632,11.424,11.424,0,0,1,11.5,23Zm0-21.85a10.349,10.349,0,1,0,7.318,3.031A10.362,10.362,0,0,0,11.5,1.15Z"
      transform="translate(0)"
      fill="#fff"
    />
    <g
      id="Ellipse_14"
      data-name="Ellipse 14"
      transform="translate(7 7)"
      fill="#615f96"
      stroke="#fff"
      strokeWidth="1"
    >
      <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
      <circle cx="4.5" cy="4.5" r="4" fill="none" />
    </g>
  </svg>
);
