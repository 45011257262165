import React from 'react';
import {
  ReactElement,
  ReactNode,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';

import html2pdf from 'html2pdf.js';
import styles from './PdfPreviewExport.module.scss';

type PdfWorkerThen<T> = {
  then: (args: any) => T;
};

type PdfWorker = {
  then: (args: any) => PdfWorker;
  from: (src: Element) => PdfWorkerThen<PdfWorker> & PdfWorker;
  toContainer: () => PdfWorkerThen<PdfWorker> & PdfWorker;
  toCanvas: () => PdfWorkerThen<PdfWorker> & PdfWorker;
  toPdf: () => PdfWorkerThen<PdfWorker> & PdfWorker;
  get: (key: string) => PdfWorkerThen<PdfWorker> & PdfWorker;
  output: (key: string) => PdfWorkerThen<PdfWorker> & PdfWorker;
  save: (key: string) => PdfWorkerThen<PdfWorker> & PdfWorker;
};

interface Props {
  children: ReactNode | ReactNode[];
  skipClasses: string[];
  breakClasses: string[];
  orientation: string;
}

export type PdfPreviewExportHandle = {
  export: (callBack: (worker: PdfWorker) => PdfWorker) => Promise<void>;
};

const PdfPreviewExport: ForwardRefRenderFunction<PdfPreviewExportHandle, Props> = (
  { children, skipClasses, breakClasses, orientation },
  ref
) => {
  const [printing, setPrinting] = useState<boolean>(false);
  const [pages, setPages] = useState<ReactElement[][]>([]);

  const pagesRef = useRef<HTMLDivElement>(null);
  const workspaceRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    export: async (callBack: (worker: PdfWorker) => PdfWorker) => {
      let worker: PdfWorker | undefined = undefined;
      if (workspaceRef.current) {
        split();
        requestAnimationFrame(() => {
          setPrinting(true);
          requestAnimationFrame(async () => {
            if (!workspaceRef.current) return undefined;

            const opt = {
              jsPDF: { unit: 'in', format: 'a4', orientation: orientation },
              html2canvas: {
                dpi: 192 * 2,
                letterRendering: true,
                useCORS: true,
                logging: true,
                scale: 4,
              },
              image: { type: 'jpeg', quality: 1 },
            };
            const element = workspaceRef.current;
            const pages = element.getElementsByClassName(styles.page);
            worker = html2pdf().set(opt) as PdfWorker;
            Array.from(pages).forEach((page, index) => {
              if (worker) {
                worker = worker
                  .from(page)
                  .toContainer()
                  .toCanvas()
                  .toPdf()
                  .get('pdf')
                  .then((pdf: any) => {
                    if (index < pages.length - 1) {
                      pdf.addPage();
                    }
                  });
              }
            });

            if (worker)
              callBack(worker).then(() => {
                //@ts-ignore
                worker.finally(() => {
                  setPrinting(false);
                });
              });
            else setPrinting(false);
          });
        });
      }
    },
  }));

  const split = () => {
    if (!pagesRef.current) return;

    let breakNum = 0;
    const newPages: ReactElement[][] = [[]];

    pagesRef.current.childNodes.forEach((elNode, index) => {
      const el = elNode as HTMLElement;
      const node = (
        <React.Fragment key={`Page_Node_Fragment_${index}`}>
          <span dangerouslySetInnerHTML={{ __html: el.outerHTML }}></span>
        </React.Fragment>
      );

      if (el.className.includes('horizontalPage')) {
        newPages.push([node]);
      }
      if (el && node) {
        const style = window.getComputedStyle(el);
        const height =
          Number.parseFloat(style.height) +
          Number.parseFloat(style.paddingTop) +
          Number.parseFloat(style.marginTop) +
          Number.parseFloat(style.paddingBottom) +
          Number.parseFloat(style.marginBottom);

        if (skipClasses.filter((skip) => el.className.includes(skip)).length === 0) {
          breakNum += height;
        }

        if (breakClasses.filter((skip) => el.className.includes(skip)).length > 0) {
          breakNum = Number.MAX_SAFE_INTEGER;
        }

        if (breakNum >= 985) {
          newPages.push([node]);
          breakNum = height;
        } else newPages[newPages.length - 1].push(node);
      }
    });

    setPages(newPages);
  };

  return (
    <>
      <div style={{ maxHeight: printing ? 0 : undefined }}>
        <div ref={pagesRef} className={styles.preview}>
          {children}
        </div>
      </div>

      <div ref={workspaceRef}>
        {printing &&
          pages.map((page, index) => {
            return (
              <div key={`Page_Node_${index}`} className={styles.page}>
                {page}
              </div>
            );
          })}
      </div>
    </>
  );
};

export default forwardRef(PdfPreviewExport);
