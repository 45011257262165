import * as React from 'react';

function SvgAllTeethliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1875 2048" {...props}>
      <path d="M1735 705q41 46 38 108 0 13-2.5 60.5t-13 134.5-27 163-48 133-72.5 57q-18 0-32.5-6t-24-20-16-26.5-11.5-35.5-7.5-35.5-5.5-37.5q-12-94-30-94-17 0-29 94-4 29-7 45.5t-10.5 41.5-16.5 39-25.5 24.5-38.5 10.5q-50 0-86-85-59 224-152 224-24 0-43-8.5t-32-27.5-21.5-36.5-15.5-48.5-10-48.5-8-51.5q-3-23-4.5-34t-5-31-7-30.5-8.5-23-11.5-17.5-13.5-5q-21 0-31.5 37T887 1279q-5 35-8 51.5t-9.5 48.5-15 48.5-21.5 36-32 28-43 8.5q-93 0-152-224-36 85-87 85-18 0-32.5-6t-24-20-16-26.5T435 1273t-7.5-35.5-5.5-37.5q-12-94-29-94-18 0-30 94-3 25-5.5 37.5T350 1273t-11.5 35.5-16 26.5-24 20-32.5 6q-41 0-72.5-57t-48-133-27-162.5-13-134T103 815q-6-55 30-99t93-50q5-1 14-1 93 3 161 65l94 78q10 8 10 16 0 9-6.5 15t-15.5 6q-6 0-14-6-76-61-95-76-59-53-134-56-2 0-5 .5t-5 .5q-39 4-63.5 35T146 813q0 81 14.5 195.5t43.5 212 62 97.5q25 0 35.5-29.5T321 1194q5-32 8-48.5t10.5-39 21-32.5 32.5-10q13 0 23 4.5t17.5 16 12 20 8.5 28 5.5 29 4.5 32.5q9 65 19.5 94.5T519 1318q6 0 14-5t23-31.5 29-72.5l2-2 2-2q-20-95-33.5-215t-17-180.5T535 734q-1-18 0-36-39 0-71 20-5 3-11 3-12 0-18-10-11-18 7-29 44-27 98-27 1 0 2 1.5t2 1.5q10-31 32-59 49-61 126-69 6-1 20-1 129 5 223 91l133 110q9 7 9 16 0 8-6.5 14.5T1066 767q-5 0-13-6-109-88-136-109-85-77-196-80-9 0-14 1-61 6-98 52-37 47-31 106 0 119 21 284t64.5 304 94.5 139q21 0 35.5-12.5t24.5-41 15-56 12-75.5q4-31 6.5-45.5t8-40 11.5-38 15.5-27 22-20.5 29.5-6q20 0 35 11t23.5 24.5 16 42 10.5 46 8 54.5q7 48 12 75.5t15 55.5 24.5 40.5 35.5 12.5q51 0 94.5-139t64.5-303 21-282q4-72-41-122-46-51-112-54-50-3-101 25-17 10-29-9-10-17 9-29 52-27 123-29 87 5 142 67 32 36 44 82 91 3 159 65l94 77q9 7 9 17 0 9-6 15t-15 6q-6 0-14-6-76-61-95-76-53-49-125-55 0 4 .5 11.5t.5 11.5q0 16-3.5 77t-17 181.5T1287 1206q3 4 4 5 14 45 29 71t23 31 14 5q25 0 35.5-29.5t19.5-94.5q5-34 8-49t10.5-38.5 20.5-33.5 32-10q16 0 27.5 8.5t18.5 18.5 12.5 31 7.5 34l6 39q9 65 19.5 94.5t35.5 29.5q33 0 62-97.5t43.5-211.5 14.5-194q3-49-27-82-29-32-74-35-35-3-72 19-8 4-16 2t-13-10q-11-18 7-29 43-25 96-25 62 3 104 50z" />
    </svg>
  );
}

export default SvgAllTeethliga;
