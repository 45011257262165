import * as React from 'react';

function SvgDarkModeliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1497 2048" {...props}>
      <path d="M1390 489q6 24 6 50v825q0 73-41.5 132.5T1246 1582q-15 7-37 11l-1 1q-12 2-19 2h-8q-12 2-19 2H337q-7 0-19-2h-8q-28 0-60-15-33-13-63-38-8-6-9-7l-6-6h-1l-10-9 2-2q-61-68-61-155V539q0-97 69-165.5T337 305h825q89 0 155 60l1-1 8 9q0 1 3 3.5t4 2.5q21 21 35.5 49t18 42.5 3.5 18.5zM907 805L755 957l-151 151q63 63 151 63 89 0 151.5-62.5T969 957q0-90-62-152zm-762 559q0 69 48 125l382-382q-25-30-37-59-19-44-19-91 0-23 5-48 8-43 36-84 16-23 28-35 51-51 119-64 25-5 48-5 48 0 92 19 22 9 40 22 3 2 6 4.5t6.5 5.5 5.5 5l382-382q-56-48-125-48H337q-79 0-135.5 56.5T145 539v825z" />
    </svg>
  );
}

export default SvgDarkModeliga;
