import React from 'react';
import styles from './Section.module.scss';
import cn from 'classnames';

const SectionInner = (props) => (
  <div className={cn(styles.inner, props.className, props.flexContent ? styles.flexContent : null)}>
    {props.children}
  </div>
);

export default SectionInner;
