import { hubConnection } from 'signalr-no-jquery';

class ReadModelHub {
  connect() {
    this.connection = hubConnection(process.env.REACT_APP_API_URL, {
      logging: true,
    });
    this.connection.error(function (error) {
      console.log('ReadModelHub-SignalR error: ' + error);
    });

    this.proxy = this.connection.createHubProxy('readModelHub');
    this.subscriptions = [];
    console.log('Starting ReadModelHub');
    this.proxy.on(
      'modelUpdated',
      function (modelName, modelId) {
        console.log('ReadModelHub-modelUpdated information');
        this.subscriptions.forEach((subscription) => {
          console.log(
            'incoming message from server with modelName:' + modelName + ' and modelId:' + modelId
          );
          if (
            subscription.modelName === modelName &&
            (!subscription.notificationId || subscription.notificationId === modelId)
          ) {
            if (subscription.notificationId && !subscription.delay) {
              console.log(
                'Executing actions for modelName ' +
                  modelName +
                  ' and notificationId ' +
                  subscription.notificationId
              );
              subscription.actions.forEach((action) => {
                action();
              });
            } else {
              if (!subscription.pendingAction) {
                subscription.pendingAction = setTimeout(() => {
                  subscription.actions.forEach((action) => {
                    action();
                  });
                  subscription.pendingAction = null;
                }, subscription.delay);
              } else {
                console.log(
                  'Clearing timeout for modelName ' +
                    modelName +
                    ' and notificationId ' +
                    subscription.notificationId
                );
                clearTimeout(subscription.pendingAction);
                subscription.pendingAction = setTimeout(() => {
                  subscription.actions.forEach((action) => {
                    action();
                  });
                  subscription.pendingAction = null;
                }, subscription.delay);
              }
            }
          } else {
            console.log(
              'Not our message!' +
                'incoming message from server with modelName:' +
                modelName +
                ' and modelId:' +
                modelId +
                ' and we want modelName ' +
                subscription.modelName +
                ' and modelId=' +
                subscription.notificationId
            );
          }
        });
      }.bind(this)
    );

    this.subscriptionsToData = [];
    this.proxy.on(
      'dataReceived',
      function (dataType, dataId, data) {
        console.log('ReadModelHub-sendToClient information');
        this.subscriptionsToData.forEach((subscription) => {
          console.log(
            'incoming message from server with dataType:' + dataType + ' and dataId:' + dataId
          );
          if (
            subscription.dataType === dataType &&
            (!subscription.dataId || subscription.dataId === dataId)
          ) {
            if (subscription.dataId && !subscription.delay) {
              console.log(
                'Executing action for dataType ' +
                  dataType +
                  ' and dataId ' +
                  subscription.dataId +
                  ' and data ' +
                  data
              );
              subscription.action(data ? JSON.parse(data) : undefined);
            } else {
              if (!subscription.pendingAction) {
                subscription.pendingAction = setTimeout(() => {
                  subscription.action(data);
                  subscription.pendingAction = null;
                }, subscription.delay);
              } else {
                console.log(
                  'Clearing timeout for dataType ' + dataType + ' and dataId ' + subscription.dataId
                );
                clearTimeout(subscription.pendingAction);
                subscription.pendingAction = setTimeout(() => {
                  subscription.action(data ? JSON.parse(data) : undefined);
                  subscription.pendingAction = null;
                }, subscription.delay);
              }
            }
          } else {
            console.log(
              'Not our message!' +
                'incoming message from server with dataType:' +
                dataType +
                ' and dataId:' +
                dataId +
                ' and we want dataType ' +
                subscription.dataType +
                ' and dataId=' +
                subscription.dataId
            );
          }
        });
      }.bind(this)
    );
  }

  addSubscription(modelName, notificationId, action, delay) {
    const existingSubscriptions = this.subscriptions.filter(
      (subscription) =>
        subscription.modelName === modelName && subscription.notificationId === notificationId
    );

    if (existingSubscriptions && existingSubscriptions.length > 0) {
      if (existingSubscriptions[0].actions.indexOf(action) !== -1) return;

      if (!existingSubscriptions[0].actions) existingSubscriptions[0].actions = [];

      existingSubscriptions[0].actions.push(action);
      return;
    }

    console.log(
      'ReadmodelHub- Adding subscription to modelName:' +
        modelName +
        ' with notificationid:' +
        notificationId
    );

    this.subscriptions.push({
      modelName,
      notificationId,
      actions: [action],
      delay: delay,
    });
  }

  removeSubscription(modelName, notificationId) {
    this.subscriptions = this.subscriptions.filter(
      (subscription) =>
        subscription.modelName !== modelName || subscription.notificationId !== notificationId
    );
  }

  subscribe(modelName, notificationId, action, delay) {
    this.addSubscription(modelName, notificationId, action, delay);

    const options = {
      withCredentials: false,
    };

    this.connection
      .start(options)
      .done(
        function () {
          console.log('ReadModelHub-Sucessfully started connection to Signal R');

          this.proxy.invoke('subscribeToModel', modelName, notificationId).fail(function (error) {
            console.log('subscribeToModel error: ' + error);
          });
        }.bind(this)
      )
      .fail(function (error) {
        console.log('ReadModelHub-Could not connect to Signal R. error:' + error);
      });
  }

  unsubscribe(modelName, notificationId) {
    this.removeSubscription(modelName, notificationId);

    const options = {
      withCredentials: false,
    };

    this.connection
      .start(options)
      .done(
        function () {
          console.log(
            'unsubscribeFromModel, modelName: ' + modelName + ', notificationId: ' + notificationId
          );
          this.proxy.invoke('unsubscribeFromModel', modelName, notificationId);
        }.bind(this)
      )
      .fail(function () {
        console.log('ReadModelHub-Could not connect to Signal R');
      });
  }

  addSubscriptionToData(dataType, dataId, action, delay) {
    const existingSubscriptions = this.subscriptionsToData.filter(
      (subscription) => subscription.dataType === dataType && subscription.dataId === dataId
    );
    console.log(
      'ReadmodelHub- Adding subscription to dataType:' + dataType + ' with dataId:' + dataId
    );

    if (existingSubscriptions && existingSubscriptions.length > 0) return;

    this.subscriptionsToData.push({
      dataType,
      dataId,
      action,
      delay: delay,
    });
  }

  removeSubscriptionToData(dataType, dataId) {
    this.subscriptionsToData = this.subscriptionsToData.filter(
      (subscription) => subscription.dataType !== dataType || subscription.dataId !== dataId
    );
  }

  subscribeToData(dataType, dataId, action, delay) {
    this.addSubscriptionToData(dataType, dataId, action, delay);

    const options = {
      withCredentials: false,
    };

    this.connection
      .start(options)
      .done(
        function () {
          console.log('ReadModelHub-Sucessfully started connection to Signal R');

          this.proxy.invoke('subscribeToData', dataType, dataId).fail(function (error) {
            console.log('subscribeToData error: ' + error);
          });
        }.bind(this)
      )
      .fail(function (error) {
        console.log('ReadModelHub-Could not connect to Signal R. error:' + error);
      });
  }

  unsubscribeToData(dataType, dataId) {
    this.removeSubscriptionToData(dataType, dataId);

    const options = {
      withCredentials: false,
    };

    this.connection
      .start(options)
      .done(
        function () {
          console.log('unsubscribeFromData, dataType: ' + dataType + ', dataId: ' + dataId);
          this.proxy.invoke('unsubscribeFromData', dataType, dataId);
        }.bind(this)
      )
      .fail(function () {
        console.log('ReadModelHub-Could not connect to Signal R');
      });
  }
}

export default new ReadModelHub();
