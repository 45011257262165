import * as types from '../../constants/actionTypes';

const initialState = {
  usePromotion: {
    complete: false,
    error: undefined,
    busy: false,
  },
  registerPromotion: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function promotion(state = initialState, action) {
  switch (action.type) {
    case types.USE_PROMOTION:
      return Object.assign({}, state, {
        usePromotion: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.USE_PROMOTION_SUCCESS:
      return Object.assign({}, state, {
        usePromotion: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.USE_PROMOTION_ERROR:
      return Object.assign({}, state, {
        usePromotion: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.REGISTER_PROMOTION:
      return Object.assign({}, state, {
        registerPromotion: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.REGISTER_PROMOTION_SUCCESS:
      return Object.assign({}, state, {
        registerPromotion: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.REGISTER_PROMOTION_ERROR:
      return Object.assign({}, state, {
        registerPromotion: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    default:
      return state;
  }
}
