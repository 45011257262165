import * as React from 'react';

function SvgDrawliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1239 2048" {...props}>
      <path d="M1030 158q76 44 99 129t-21 161l-6 11q-3 4-7 7 0 4-1 6-6 11-19 11-5 0-10-3l-147-85-591 1024 128 74 529-915q5-8 13-10t16 2q8 5 10 13t-2 16l-538 931-1 1-1 1v1l-1 1q0 2-1 2-13 20-32 30l-198 114q-24 13-48 13-27 0-49-13-49-28-49-84l-1-229q0-22 11-44 0-1 .5-1t.5-1h1q0-1 1-3L734 247l6-11q43-76 129-99 84-23 161 21zm-150 20q-67 18-103 79l-3 6 146 85h1l146 85 4-6q35-61 17-129t-79-103q-61-36-129-17zM228 1643l198-114 2-2-141-81-141-82v1q-1 1-1 2v229q0 31 28 47 27 16 55 0zm62-245L881 374l-128-74-592 1024z" />
    </svg>
  );
}

export default SvgDrawliga;
