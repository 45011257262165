export enum UserPermissionEnum {
  USER = 'User',
  ADMIN = 'Admin',
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
  PUT = 'PUT',
}
