import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { History } from 'history';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { storeConsultation } from '../../redux/slices/apiSlice';
import AutosaveConsultation from './AutosaveConsultation';
import {
  ConsultationTab,
  currentTab,
  exportView,
  MODAL_ISQ,
  MODAL_LOAD,
  MODAL_SAVE,
  SET_CURRENT_TAB,
  SET_EXPORT_VIEW,
} from '../../redux/slices/UISlice';
import {
  GOTO_STEP,
  LOAD,
  RESET,
  SET_DIRTY,
  SET_ID,
  SET_NAME,
  SET_PATIENT_ID,
  UPDATE_STEP,
  SET_VISIT_NAME,
} from '../../redux/slices/procedureSlice';
import { RESET as RESET_WORKSPACE, RESTORE } from '../../redux/slices/workspaceSlice';
import { ActionCreators } from 'redux-undo';
import { deleteConsultation, getConsultation } from '../../redux/slices/apiSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import Modal from '../../components/Shared/Modal/Modal';
import Button from '../../components/Shared/Buttons/Button';

//@ts-ignore
import { showConfirmation } from '../../redux/actions/generalActions';
import styled, { ThemeProvider } from 'styled-components';
import { getPatient } from '../../redux/actions/patientActions';
import { styledTheme } from '../../common/themes';
import fscreen from 'fscreen';
import ProcedureView from '../../components/Consultation/features/procedure-view/ProcedureView';
import Header from '../../components/header/Header';
import TeethView from '../../components/Consultation/features/teeth-view/TeethView';
import html2canvas from 'html2canvas';
import { useParams } from 'react-router';
import SavePrompt from '../../components/Consultation/features/teeth-view/toolbar/dropdowns/SavePrompt';
import LoadPrompt from '../../components/Consultation/features/teeth-view/toolbar/dropdowns/LoadPrompt';
import { ImageViewer } from '../../components/PatientImages/ImageLibrary';
import { ISQModal } from '../../components/implants/IsqInfoModal';
import ConsultationExportPage from './ConsultationExportPage';
import { appInsights } from '../../utils/applicationInsights';
import { ICurrentPatient } from '../../types/patient';
import { IUser } from '../../types/user';

interface Props {
  history: History;
}

declare global {
  interface Window {
    HubSpotConversations: any;
    hsConversationsOnReady: any;
    hsConversationsSettings: any;
  }
}

const ConsultationPage: React.FC<Props> = ({ history }) => {
  const patient = useAppSelector<ICurrentPatient>((state) => state.patient.currentPatient);
  const user = useAppSelector<IUser>((state) => state.user.user);

  const dispatch = useDispatch();
  const intl = useIntl();

  const tabCurrent = useAppSelector(currentTab);
  const viewExport = useAppSelector<boolean>(exportView);
  const dispatchTyped = useAppDispatch();

  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();
  const consultationPatientId = useAppSelector((state) => state.consultation.procedure.patientId);
  const workspaceRef = useRef<HTMLDivElement>(null);

  const takeScreenshot = async () => {
    if (workspaceRef.current) {
      const workspaceContainer =
        workspaceRef.current.getElementsByClassName('workspace')[0].parentElement;
      if (!workspaceContainer) return;

      try {
        const scale = Number.parseFloat(workspaceContainer.style.transform.replace('scale(', ''));
        const canvas = await html2canvas(workspaceContainer, {
          allowTaint: true,
          imageTimeout: 0,
          x: 0,
          y: 0,
          scale: 1 / scale,
          backgroundColor: null,
        });

        // Center and size the canvas to the teeth
        const ctx = canvas.getContext('2d');
        const imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
        if (imageData) {
          const pixel = imageData?.data ?? [];

          let minX: number = canvas.width,
            maxX = 0,
            minY: number = canvas.height,
            maxY = 0;
          let w = 0;
          let h = 0;
          for (let p = 0; p < pixel.length; p += 4) {
            if (pixel[p + 3] === 255) {
              if (w < minX) {
                minX = w;
              }
              if (w > maxX) {
                maxX = w;
              }
              if (h < minY) {
                minY = h;
              }
              if (h > maxY) {
                maxY = h;
              }
            }

            w++;
            if (w % canvas.width === 0) {
              h++;
              w = 0;
            }
          }

          const padding = 50;
          canvas.width = maxX - minX + padding * 2;
          canvas.height = maxY - minY + padding * 2;

          const ratio = 1.8;
          const devider = (canvas.height / canvas.width) * ratio;
          canvas.height = canvas.height / devider;

          ctx?.putImageData(imageData, -minX + padding, -minY + padding);
        }

        const img = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        return Promise.resolve(img);
      } catch (e) {
        return Promise.reject(e);
      }
    }
  };

  const resetConsultation = () => {
    dispatchTyped(RESET());
    dispatchTyped(RESET_WORKSPACE());
    dispatchTyped(SET_ID());
    dispatch(ActionCreators.clearHistory());
    dispatchTyped(SET_PATIENT_ID(patientId ?? ''));
    dispatchTyped(
      SET_VISIT_NAME({
        index: 0,
        name:
          intl.formatMessage({
            id: 'consultation.visit',
            defaultMessage: 'Visit',
          }) + ' 1',
      })
    );
    dispatchTyped(SET_DIRTY(false));
  };

  useEffect(() => {
    if (patient?.id !== patientId) {
      dispatch(getPatient(userGroupId, patientId));
    }

    if (consultationPatientId !== patientId) {
      resetConsultation();
    }
  }, [patientId, userGroupId, dispatch]);

  useEffect(() => {
    appInsights.trackEvent({
      name: 'PageVisit',
      properties: {
        page: 'consultation',
        email: user.email,
      },
    });
    if (window.HubSpotConversations) {
      //Hubspot API ready, check status of the Widget
      const status = window.HubSpotConversations.widget.status();
      // If Hubspot Widget not yet loaded, set timout to remove it
      if (status.pending)
        setTimeout(() => {
          window.HubSpotConversations.widget.remove();
        }, 3000);
      else {
        window.HubSpotConversations.widget.remove();
      }
    } else {
      //Hubspot API not ready yet, set callback to not load and remove it.
      window.hsConversationsOnReady = [
        () => {
          window.hsConversationsSettings = {
            loadImmediately: false,
          };
          window.HubSpotConversations.widget.remove();
        },
      ];
    }
    return () => {
      window.HubSpotConversations && window.HubSpotConversations.widget.load();
    };
  }, []);

  const TabContent = () => {
    switch (tabCurrent) {
      case ConsultationTab.Planning:
        return <TeethView handleTakeScreenshot={takeScreenshot} ref={workspaceRef} />;
      case ConsultationTab.Procedure:
        return (
          <TabContentWrapper>
            <ProcedureView />
          </TabContentWrapper>
        );
      default:
        return null;
    }
  };

  function handleConfigurationLoad(id: string) {
    dispatchTyped(getConsultation(id))
      .then(unwrapResult)
      .then((consultation) => {
        const config = JSON.parse(consultation.configuration);
        config.consultationId = consultation.id;
        dispatchTyped(LOAD(config));

        dispatchTyped(RESTORE(config.visits[0]?.procedureStepStates[0]?.state));
        dispatchTyped(GOTO_STEP({ visitIndex: 0, stepIndex: 0, shouldUpdateCurrent: false }));
        dispatch(ActionCreators.clearHistory());
        dispatchTyped(MODAL_LOAD(false));
        dispatchTyped(SET_DIRTY(false));
      })
      .catch((error) => console.log('Error', error));
  }

  function handleConfigurationDelete(id: string) {
    if (userGroupId && patientId)
      dispatchTyped(deleteConsultation({ userGroupId, patientId, consultationId: id }))
        .then(unwrapResult)
        .then(() => {
          resetConsultation();
          dispatchTyped(MODAL_LOAD(false));
          dispatchTyped(SET_DIRTY(false));
        })
        .catch((error) => console.log('Error', error));
  }
  if (viewExport) return <ConsultationExportPage />;

  return (
    <ThemeProvider theme={styledTheme}>
      <div>
        <ConsultationHeader takeScreenshot={takeScreenshot} />

        <ScreenTooSmallModal>
          <Modal
            header={intl.formatMessage({
              id: 'general.screenToSmall',
              defaultMessage: 'Screen too small',
            })}
            show={true}
            onDismiss={() => {
              history.push('/');
            }}
          >
            <p>
              {intl.formatMessage({
                id: 'general.screenToSmallForThisFeature',
                defaultMessage: 'Screen too small for this feature.',
              })}
            </p>
            <div>
              <Button
                primary
                big
                full
                value={intl.formatMessage({
                  id: 'profile.backToMainPage',
                  defaultMessage: 'Back to start page',
                })}
                onClick={() => history.push('/')}
              />
            </div>
          </Modal>
        </ScreenTooSmallModal>

        <SavePrompt
          patientId={patientId}
          userGroupId={userGroupId}
          takeScreenshot={tabCurrent === ConsultationTab.Planning ? takeScreenshot : undefined}
        />
        <LoadPrompt
          onLoad={(id) => handleConfigurationLoad(id)}
          onDelete={(id) => handleConfigurationDelete(id)}
          userGroupId={userGroupId}
          patientId={patientId}
        />

        <ISQInfo />

        {patient && (
          <>
            <TabContent />
          </>
        )}
      </div>
      <ImageViewer />
    </ThemeProvider>
  );
};

const ScreenTooSmallModal = styled.div`
  text-align: center;
  @media (min-width: 950px) {
    display: none;
  }
`;

function ISQInfo() {
  const dispatch = useDispatch();
  const isqInfoOpen = useAppSelector((state) => state.consultation.view.modal.isqInfoOpen);
  return isqInfoOpen && <ISQModal onDismiss={() => dispatch(MODAL_ISQ(false))} />;
}

function ConsultationHeader({
  takeScreenshot,
}: {
  takeScreenshot: () => Promise<string | undefined>;
}) {
  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();
  const patient = useAppSelector((state) => state.patient.currentPatient);
  const intl = useIntl();
  const dispatch = useDispatch();
  const dispatchTyped = useAppDispatch();
  const workspacePastLength = useAppSelector((state) => state.consultation.workspace.past.length);
  const workspaceFutureLength = useAppSelector(
    (state) => state.consultation.workspace.future.length
  );
  const tabCurrent = useAppSelector((state) => state.consultation.view.currentTab);
  const workspacePresent = useAppSelector((state) => state.consultation.workspace.present);
  const configurationID = useAppSelector((state) => state.consultation.procedure.consultationId);
  const procedure = useAppSelector((state) => state.consultation.procedure);
  const user = useAppSelector((state) => state.user.user);
  const configurationName = useAppSelector((state) => state.consultation.procedure.name);
  const [saving, setSaving] = useState<boolean>(false);

  const configurationHasUnsavedChanges = useAppSelector(
    (state) => state.consultation.procedure.configurationHasUnsavedChanges
  );

  async function screenshotAndUpdateState() {
    takeScreenshot()
      .then((screenshot) => {
        dispatch(
          UPDATE_STEP({
            updateData: {
              state: workspacePresent,
              previewImageSrc: screenshot,
            },
          })
        );
        dispatch(ActionCreators.clearHistory());
      })
      .catch((e) => {
        console.error(e);
        return Promise.reject(e);
      });
  }

  const resetConsultation = () => {
    dispatchTyped(RESET());
    dispatchTyped(RESET_WORKSPACE());
    dispatchTyped(SET_ID());
    dispatch(ActionCreators.clearHistory());
    dispatchTyped(SET_PATIENT_ID(patientId ?? ''));
    dispatchTyped(
      SET_VISIT_NAME({
        index: 0,
        name:
          intl.formatMessage({
            id: 'consultation.visit',
            defaultMessage: 'Visit',
          }) + ' 1',
      })
    );
    dispatchTyped(SET_DIRTY(false));
  };

  const onReset = () => {
    dispatch(ActionCreators.jumpToPast(0));
  };

  const [fullScreen, setFullScreen] = useState(false);

  function handleFullscreen() {
    if (fscreen.fullscreenElement !== null) {
      setFullScreen(true);
    } else {
      setFullScreen(false);
    }
  }

  const storeConsultationInDb = async () => {
    setSaving(true);
    try {
      //Update snapshot only if being in planning view
      if (tabCurrent === ConsultationTab.Planning) {
        const previewImageSrc = await takeScreenshot();
        dispatch(
          UPDATE_STEP({
            updateData: {
              state: workspacePresent,
              previewImageSrc,
            },
          })
        );
      }

      dispatchTyped(
        storeConsultation({
          userGroupId: userGroupId ?? '',
          patientId: patientId ?? '',
          name: configurationName,
          createdBy: user.id,
          consultationId: procedure.consultationId,
        })
      )
        .then(unwrapResult)
        .then((consultationId) => {
          dispatch(SET_ID(consultationId));
          dispatch(SET_NAME(configurationName));
          dispatch(MODAL_SAVE(false));
          dispatch(SET_DIRTY(false));
          setSaving(false);
        });
    } catch (e) {
      setSaving(false);
      console.error(e);
      return Promise.reject(e);
    }
  };

  function handleCreateNew() {
    if (configurationHasUnsavedChanges) {
      dispatch(
        showConfirmation(
          intl.formatMessage({
            id: 'consultation.newprompt',
            defaultMessage: `Are you sure you want to create a new consultaion`,
          }),
          <></>,
          () => {
            resetConsultation();
            dispatchTyped(SET_DIRTY(false));
          }
        )
      );
    } else {
      resetConsultation();
    }
  }

  const onToggleView = () => {
    if (tabCurrent === ConsultationTab.Planning) {
      if (configurationHasUnsavedChanges) {
        screenshotAndUpdateState().then(() => dispatch(SET_CURRENT_TAB(ConsultationTab.Procedure)));
      } else {
        dispatch(SET_CURRENT_TAB(ConsultationTab.Procedure));
      }
    } else {
      dispatch(SET_CURRENT_TAB(ConsultationTab.Planning));
    }
  };

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', handleFullscreen, false);

    return () => {
      fscreen.removeEventListener('fullscreenchange', handleFullscreen);
    };
  }, []);
  const patientIdentifier =
    patient.firstName || patient.lastName ? `: ${patient.firstName} ${patient.lastName}` : '';
  return (
    <>
      <AutosaveConsultation storeConsultationInDb={storeConsultationInDb} />
      <Header
        isTogglerHidden
        title={
          intl.formatMessage({
            id: 'consultation.Header',
            defaultMessage: 'CONSULTATION',
          }) + patientIdentifier
        }
        leftButtons={[
          ...(!window.ReactNativeWebView
            ? [
                {
                  label: intl.formatMessage({
                    id: 'general.fullscreen',
                    defaultMessage: 'Fullscreen',
                  }),
                  icon: fullScreen ? 'custom/fullscreenExit' : 'custom/fullscreen',
                  action: () => {
                    if (fullScreen) {
                      fscreen.exitFullscreen();
                    } else {
                      fscreen.requestFullscreen(document.body);
                    }
                  },
                },
              ]
            : []),
          tabCurrent === ConsultationTab.Planning
            ? {
                label: intl.formatMessage({
                  id: 'consultation.sequenceView',
                  defaultMessage: 'Sequence View',
                }),
                icon: 'calendar',
                action: onToggleView,
              }
            : {
                label: intl.formatMessage({
                  id: 'consultation.planningView',
                  defaultMessage: 'Planning View',
                }),
                icon: 'all_teeth',
                action: onToggleView,
              },
        ]}
        rightButtons={[
          {
            disabled: !configurationHasUnsavedChanges && configurationID !== undefined,
            loading: saving,
            label: intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' }),
            icon: 'custom/save',
            action: () => {
              if (configurationID) {
                storeConsultationInDb();
              } else {
                dispatchTyped(MODAL_SAVE(true));
              }
            },
          },
          {
            label: intl.formatMessage({ id: 'general.undo', defaultMessage: 'Undo' }),
            icon: 'undo',
            action: () => dispatch(ActionCreators.undo()),
            disabled: workspacePastLength === 0,
          },
          {
            label: intl.formatMessage({ id: 'general.redo', defaultMessage: 'Redo' }),
            icon: 'redo',
            action: () => dispatch(ActionCreators.redo()),
            disabled: workspaceFutureLength === 0,
          },
          {
            label: intl.formatMessage({ id: 'general.reset', defaultMessage: 'Reset' }),
            icon: 'close',
            action: () =>
              dispatch(
                showConfirmation(
                  intl.formatMessage({
                    id: 'consultation.planning.resetworkspace',
                    defaultMessage: 'Reset all changes made since last saved?',
                  }),
                  <></>,
                  onReset
                )
              ),
            disabled: workspacePastLength === 0,
          },
          {
            label: intl.formatMessage({
              id: 'general.more',
              defaultMessage: 'More',
            }),
            icon: 'more',
            submenu: [
              {
                label: intl.formatMessage({
                  id: 'general.store.create',
                  defaultMessage: 'Create new',
                }),
                icon: 'add',
                action: handleCreateNew,
              },
              {
                label: intl.formatMessage({ id: 'general.load', defaultMessage: 'Load' }),
                icon: 'custom/load',
                action: () => dispatchTyped(MODAL_LOAD(true)),
              },
              {
                label: intl.formatMessage({ id: 'general.export', defaultMessage: 'Export' }),
                icon: 'pdf',
                action: () => dispatchTyped(SET_EXPORT_VIEW(true)),
              },
              {
                label: intl.formatMessage({
                  id: 'general.saveAsNew',
                  defaultMessage: 'Save as new',
                }),
                icon: 'custom/save',
                action: () => dispatchTyped(MODAL_SAVE(true)),
              },
            ],
          },
        ]}
      />
    </>
  );
}

const TabContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  padding-top: 20px;
`;

export default ConsultationPage;
