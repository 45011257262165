import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getCountriesRequest() {
  return {
    type: types.GET_COUNTRIES,
  };
}

function getCountriesSuccess(data) {
  return {
    type: types.GET_COUNTRIES_SUCCESS,
    countries: data,
  };
}

function getCountriesFailed(error) {
  return {
    type: types.GET_COUNTRIES_ERROR,
    error,
  };
}

export function getCountries() {
  return (dispatch) => {
    dispatch(getCountriesRequest());

    ApiFetch.get('referencedata/countries', true)
      .then((data) => {
        dispatch(getCountriesSuccess(data));
      })
      .catch((error) => {
        dispatch(getCountriesFailed(error));
      });
  };
}

function getDentalNotificationsRequest() {
  return {
    type: types.GET_DENTAL_NOTIFICATIONS,
  };
}

function getDentalNotificationsSuccess(data) {
  return {
    type: types.GET_DENTAL_NOTIFICATIONS_SUCCESS,
    dentalNotifications: data,
  };
}

function getDentalNotificationsFailed(error) {
  return {
    type: types.GET_DENTAL_NOTIFICATIONS_ERROR,
    error,
  };
}

export function getDentalNotifications() {
  return (dispatch) => {
    dispatch(getDentalNotificationsRequest());

    ApiFetch.get('referencedata/dentalnotifications')
      .then((data) => {
        dispatch(getDentalNotificationsSuccess(data));
      })
      .catch((error) => {
        dispatch(getDentalNotificationsFailed(error));
      });
  };
}

function getImplantDataRequest() {
  return {
    type: types.GET_IMPLANT_DATA,
  };
}

function getImplantDataSuccess(data) {
  return {
    type: types.GET_IMPLANT_DATA_SUCCESS,
    implantData: data,
  };
}

function getImplantDataFailed(error) {
  return {
    type: types.GET_IMPLANT_DATA_ERROR,
    error,
  };
}

export function getImplantData(ignoreObsolete, draft) {
  return (dispatch) => {
    dispatch(getImplantDataRequest());

    const url = draft ? 'referencedata/implantdatadraft' : 'referencedata/implantdata';
    const ignore = ignoreObsolete ? ignoreObsolete : false;

    ApiFetch.get(`${url}?ignoreobsolete=${ignore}`, true)
      .then((data) => {
        dispatch(getImplantDataSuccess(data));
      })
      .catch((error) => {
        dispatch(getImplantDataFailed(error));
      });
  };
}

function getAbutmentDataRequest() {
  return {
    type: types.GET_ABUTMENT_DATA,
  };
}

function getAbutmentDataSuccess(data) {
  return {
    type: types.GET_ABUTMENT_DATA_SUCCESS,
    abutmentData: data,
  };
}

function getAbutmentDataFailed(error) {
  return {
    type: types.GET_ABUTMENT_DATA_ERROR,
    error,
  };
}

export function getAbutmentData(ignoreObsolete, draft) {
  return (dispatch) => {
    dispatch(getAbutmentDataRequest());

    const url = draft ? 'referencedata/abutmentdatadraft' : 'referencedata/abutmentdata';
    const ignore = ignoreObsolete ? ignoreObsolete : false;

    ApiFetch.get(`${url}?ignoreobsolete=${ignore}`, true)
      .then((data) => {
        dispatch(getAbutmentDataSuccess(data));
      })
      .catch((error) => {
        dispatch(getAbutmentDataFailed(error));
      });
  };
}

function getSmartPegDataRequest() {
  return {
    type: types.GET_SMARTPEG_DATA,
  };
}

function getSmartPegDataSuccess(data, megagen) {
  return {
    type: types.GET_SMARTPEG_DATA_SUCCESS,
    smartPegData: data,
    megagen,
  };
}

function getSmartPegDataFailed(error) {
  return {
    type: types.GET_SMARTPEG_DATA_ERROR,
    error,
  };
}

export function getSmartPegData(megagen) {
  return (dispatch) => {
    dispatch(getSmartPegDataRequest());

    const url = megagen ? 'referencedata/smartpegdatamegagen' : 'referencedata/smartpegdata';

    ApiFetch.get(url, true)
      .then((data) => {
        dispatch(getSmartPegDataSuccess(data, megagen));
      })
      .catch((error) => {
        dispatch(getSmartPegDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function getRefListDataRequest() {
  return {
    type: types.GET_REFLIST_DATA,
  };
}

function getRefListDataSuccess(data) {
  return {
    type: types.GET_REFLIST_DATA_SUCCESS,
    refListData: data,
  };
}

function getRefListDataFailed(error) {
  return {
    type: types.GET_REFLIST_DATA_ERROR,
    error,
  };
}

export function getRefListData() {
  return (dispatch) => {
    dispatch(getRefListDataRequest());

    ApiFetch.get(`referencedata/reflistdata`)
      .then((data) => {
        dispatch(getRefListDataSuccess(data));
      })
      .catch((error) => {
        dispatch(getRefListDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function releaseRefListRequest() {
  return {
    type: types.RELEASE_REFLIST,
  };
}

function releaseRefListSuccess() {
  return {
    type: types.RELEASE_REFLIST_SUCCESS,
  };
}

function releaseRefListFailed(error) {
  return {
    type: types.RELEASE_REFLIST_ERROR,
    error,
  };
}

export function releaseRefList(version, date) {
  return (dispatch) => {
    dispatch(releaseRefListRequest());

    const request = {
      version: version,
      date: date,
    };

    ApiFetch.post(`referencedata/releasereflist`, request)
      .then(() => {
        dispatch(releaseRefListSuccess());
      })
      .catch((error) => {
        dispatch(releaseRefListFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function updateImplantDataRequest() {
  return {
    type: types.UPDATE_IMPLANT_DATA,
  };
}

function updateImplantDataSuccess(_) {
  return {
    type: types.UPDATE_IMPLANT_DATA_SUCCESS,
  };
}

function updateImplantDataFailed(error) {
  return {
    type: types.UPDATE_IMPLANT_DATA_ERROR,
    error,
  };
}

export function updateImplantData(implants) {
  return (dispatch) => {
    dispatch(updateImplantDataRequest());

    ApiFetch.post(`referencedata/implantdata`, implants)
      .then((data) => {
        dispatch(updateImplantDataSuccess(data));
      })
      .catch((error) => {
        dispatch(updateImplantDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function updateAbutmentDataRequest() {
  return {
    type: types.UPDATE_ABUTMENT_DATA,
  };
}

function updateAbutmentDataSuccess(_) {
  return {
    type: types.UPDATE_ABUTMENT_DATA_SUCCESS,
  };
}

function updateAbutmentDataFailed(error) {
  return {
    type: types.UPDATE_ABUTMENT_DATA_ERROR,
    error,
  };
}

export function updateAbutmentData(abutments) {
  return (dispatch) => {
    dispatch(updateAbutmentDataRequest());

    ApiFetch.post(`referencedata/abutmentdata`, abutments)
      .then((data) => {
        dispatch(updateAbutmentDataSuccess(data));
      })
      .catch((error) => {
        dispatch(updateAbutmentDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

export function exportImplantsSql(callback) {
  return () => {
    ApiFetch.downloadFile(`referencedata/implantdata/export`, callback);
  };
}

export function exportAbutmentsSql(callback) {
  return () => {
    ApiFetch.downloadFile(`referencedata/abutmentdata/export`, callback);
  };
}

export function exportSmartPegDiffList(callback) {
  return () => {
    ApiFetch.downloadFile(`referencedata/smartpeglist/exportdiffcsv`, callback);
  };
}

export function exportSmartPegList(draft, callback) {
  return () => {
    ApiFetch.downloadFile(`referencedata/smartpeglist/exportpdf?draft=${draft}`, callback);
  };
}

export function getSmartPegListForBrand(brandName, callback) {
  const encodedURIBrandName = encodeURIComponent(brandName);
  return () => {
    ApiFetch.downloadFile(
      `referencedata/smartpeglist/brand?brandName=${encodedURIBrandName}`,
      callback
    );
  };
}

// function getAppSettingsRequest() {
//   return {
//     type: types.GET_APP_SETTINGS,
//   };
// }

// function getAppSettingsSuccess(data) {
//   return {
//     type: types.GET_APP_SETTINGS_SUCCESS,
//     appSettings: data,
//   };
// }

// function getAppSettingsFailed(error) {
//   return {
//     type: types.GET_APP_SETTINGS_ERROR,
//     error,
//   };
// }

// export function getAppSettings() {
//   return (dispatch) => {
//     dispatch(getAppSettingsRequest());

//     ApiFetch.get('referencedata/appsettings', true)
//       .then((data) => {
//         dispatch(getAppSettingsSuccess(data));
//       })
//       .catch((error) => {
//         dispatch(getAppSettingsFailed(error));
//       });
//   };
// }

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function getStudyImplantDataRequest() {
  return {
    type: types.GET_STUDY_IMPLANT_DATA,
  };
}

function getStudyImplantDataSuccess(data) {
  return {
    type: types.GET_STUDY_IMPLANT_DATA_SUCCESS,
    studyImplantData: data,
  };
}

function getStudyImplantDataFailed(error) {
  return {
    type: types.GET_STUDY_IMPLANT_DATA_ERROR,
    error,
  };
}

export function getStudyImplantData() {
  return (dispatch) => {
    dispatch(getStudyImplantDataRequest());

    ApiFetch.get(`referencedata/studyimplantdata`)
      .then((data) => {
        dispatch(getStudyImplantDataSuccess(data));
      })
      .catch((error) => {
        dispatch(getStudyImplantDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////

function getStudyAbutmentDataRequest() {
  return {
    type: types.GET_STUDY_ABUTMENT_DATA,
  };
}

function getStudyAbutmentDataSuccess(data) {
  return {
    type: types.GET_STUDY_ABUTMENT_DATA_SUCCESS,
    studyAbutmentData: data,
  };
}

function getStudyAbutmentDataFailed(error) {
  return {
    type: types.GET_STUDY_ABUTMENT_DATA_ERROR,
    error,
  };
}

export function getStudyAbutmentData() {
  return (dispatch) => {
    dispatch(getStudyAbutmentDataRequest());

    ApiFetch.get(`referencedata/studyabutmentdata`)
      .then((data) => {
        dispatch(getStudyAbutmentDataSuccess(data));
      })
      .catch((error) => {
        dispatch(getStudyAbutmentDataFailed(error));
      });
  };
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
