import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAppSelector } from '../../utils/hooks';
import { useHistory } from 'react-router';
import LineChart from '../../components/Charts/LineChart';
import LineChartOptionWrapper from '../../components/Charts/LineChartOptionWrapper';
import { getInternalStatistics } from '../../redux/actions/statisticsActions';
import { useDispatch } from 'react-redux';

const InternalStatisticsPage: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useDispatch();
  const internalStatistics = useAppSelector(
    (state) => state.statistics.internalStatistics.statistics
  );
  const signInsbyDevice = internalStatistics?.signInByDevice;
  const signInsbyInstrument = internalStatistics?.signInByInstrument;
  const scannedSmartpegs = internalStatistics?.scannedSmartpegs;
  const scannedImplants = internalStatistics?.scannedImplants;
  const scannedAbutments = internalStatistics?.scannedAbutments;
  const pageVisits = internalStatistics?.pageVisits;
  const pdfExports = internalStatistics?.pdfExports;
  const patientsStats = internalStatistics?.patients;
  const implantssStats = internalStatistics?.implants;
  const measurementsStats = internalStatistics?.measurements;
  const measurementsGrowth = internalStatistics?.measurementsGrowth;
  const implantsGrowth = internalStatistics?.implantsGrowth;
  const patientsGrowth = internalStatistics?.patientsGrowth;
  const signedInThreeWeekRow = internalStatistics?.signedInThreeWeekRow;
  const totalUsersGrowth = internalStatistics?.totalUsersGrowth;
  const totalUsers = internalStatistics?.totalUsers;
  const signedInThreeTimesInTwoMonths = internalStatistics?.signedInThreeTimesInTwoMonths;

  const [isAllowedAccess, setIsAllowedAccess] = useState(false);

  useEffect(() => {
    dispatch(getInternalStatistics());
  }, [dispatch]);

  useEffect(() => {
    if (user === undefined || user.email === undefined) {
      return;
    }

    if (
      user.email === 'martin.gabre@osstell.com' ||
      user.email === 'stefan.horn@osstell.com' ||
      user.email === 'thaddeus.picklo@osstell.com' ||
      user.email === 'viktoria.martensson@osstell.com' ||
      user.email === 'petter.fogel@osstell.com' ||
      user.email === 'leon.koopman@osstell.com'
    ) {
      setIsAllowedAccess(true);
    } else {
      history.goBack();
    }
  }, [user, history]);

  // First three datapoints in these graphs are empty becuase we did not collect this data
  // at that time
  const newSignedInThreeWeekRow = signedInThreeWeekRow && signedInThreeWeekRow.slice(3);
  const newSignedInThreeTimesInTwoMonths =
    signedInThreeTimesInTwoMonths && signedInThreeTimesInTwoMonths.slice(3);

  return (
    <StyledInternalStatisticsPage>
      {!isAllowedAccess && <p>page not found</p>}
      {isAllowedAccess && (
        <>
          <h1 className="header">Our Big Hairy Audacious Goal</h1>
          <div className="container">
            <LineChartOptionWrapper
              renderGraphOne={() => (
                <LineChart
                  title="Users - Total"
                  dataKeys={['total', 'withRegisteredInstrument']}
                  data={totalUsers}
                  infotext=""
                />
              )}
              optionOne="Total"
              renderGraphTwo={() => (
                <LineChart
                  title="Users - Weekly growth"
                  dataKeys={['total', 'withRegisteredInstrument']}
                  data={totalUsersGrowth}
                  infotext="* The migration made in juli 2017 is disregarded in this graph."
                />
              )}
              optionTwo="Weekly growth"
            />
            <LineChartOptionWrapper
              renderGraphOne={() => (
                <LineChart
                  title="Patients - Total"
                  dataKeys={['amount']}
                  data={patientsStats ? patientsStats.stats : []}
                />
              )}
              optionOne="Total"
              renderGraphTwo={() => (
                <LineChart
                  title="Patients - Weekly growth"
                  dataKeys={['amount']}
                  data={patientsGrowth ? patientsGrowth.stats : []}
                  infotext="* The migration made in juli 2017 is disregarded in this graph."
                />
              )}
              optionTwo="Weekly growth"
            />
            <LineChartOptionWrapper
              renderGraphOne={() => (
                <LineChart
                  title="Implants - total"
                  dataKeys={['amount']}
                  data={implantssStats ? implantssStats.stats : []}
                />
              )}
              optionOne="Total"
              renderGraphTwo={() => (
                <LineChart
                  title="Implants - Weekly growth "
                  dataKeys={['amount']}
                  data={implantsGrowth ? implantsGrowth.stats : []}
                />
              )}
              optionTwo="Weekly growth"
            />
            <LineChartOptionWrapper
              renderGraphOne={() => (
                <LineChart
                  title="Measurents - total"
                  dataKeys={['amount']}
                  data={measurementsStats ? measurementsStats.stats : []}
                />
              )}
              optionOne="Total"
              renderGraphTwo={() => (
                <LineChart
                  title="Measurents - Weekly growth"
                  dataKeys={['amount']}
                  data={measurementsGrowth ? measurementsGrowth.stats : []}
                />
              )}
              optionTwo="Weekly growth"
            />
            <LineChart
              title="Users that signed in for third week in a row"
              dataKeys={['amount']}
              data={newSignedInThreeWeekRow}
              infotext="* Data collected since January 2023"
            />
            <LineChart
              title="Signed in 3 times in last two months"
              dataKeys={['amount']}
              data={newSignedInThreeTimesInTwoMonths}
              infotext="* Data collected since January 2023"
            />
            <LineChart
              title="Sign in by device"
              dataKeys={['total', 'browser', 'mobile', 'tablet']}
              data={signInsbyDevice}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Sign in by instrument"
              dataKeys={['total', 'beacon', 'idx', 'megagen']}
              data={signInsbyInstrument}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Scanned smartpegs"
              dataKeys={['amount']}
              data={scannedSmartpegs}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Scanned implants"
              dataKeys={['scanned', 'scannedFailed']}
              data={scannedImplants}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Scanned abutments"
              dataKeys={['scanned', 'scannedFailed']}
              data={scannedAbutments}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Page visits"
              dataKeys={[
                'clinicPage',
                'consultationModule',
                'helpPage',
                'patientPage',
                'patientList',
              ]}
              data={pageVisits}
              infotext="* Data collected since 2023/04/27"
            />
            <LineChart
              title="Pdf export"
              dataKeys={['consultation', 'referral', 'patientInformation']}
              data={pdfExports}
              infotext="* Data collected since 2023/04/27"
            />
          </div>
        </>
      )}
    </StyledInternalStatisticsPage>
  );
};

const StyledInternalStatisticsPage = styled.div`
  background-color: #edeef7;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info {
    font-size: 20px;
  }

  .header {
    margin-bottom: 70px;
    text-align: center;
    display: block;
  }
`;

export default InternalStatisticsPage;
