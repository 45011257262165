import { FC, FormEvent, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { errorToast } from '../../utils/errorToast';
import { FormikInputField } from '../formik/formik-input-field/FormikInputField';
import { UserPermissionEnum } from '../../constants/enums';
import { useSendClinicInviteMutation } from '../../redux/api/clinic/clinicApi';
import UserPermissionSelect from '../formik/user-permission-select/UserPermissionSelect';
import Spinner from '../Shared/Loaders/Spinner';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

interface IFormValues {
  email: string;
  permission: UserPermissionEnum;
}

interface Props {
  isOpen: boolean;
  userGroupId: string;
  onDialogCloseClick: () => void;
}

const InviteUserForm: FC<Props> = ({ isOpen, userGroupId, onDialogCloseClick }) => {
  const intl = useIntl();
  const [inviteMember, { isLoading: isInviting }] = useSendClinicInviteMutation();

  const validate = (values: IFormValues) => {
    const errors: Record<string, string> = {};
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    if (!values.permission) {
      errors.permission = 'Please select user permission';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      permission: UserPermissionEnum.USER,
    },
    validate,
    enableReinitialize: false,
    validateOnMount: true,
    onSubmit: async ({ email, permission }) => {
      const res = await inviteMember({
        email,
        permission,
        userGroupId,
      });
      onDialogCloseClick();
      if ('error' in res && 'data' in res.error) {
        const { modelState } = res.error.data;
        errorToast(intl, modelState);
        return;
      }
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'implant.inviteSuccess',
          defaultMessage: 'The invitation was sent successfully',
        }),
        { showCloseButton: false }
      );
    },
  });

  useEffect(() => {
    formik.resetForm();
  }, [onDialogCloseClick]);

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogCloseClick}
      maxWidth="xs"
      PaperProps={{
        component: 'form',
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        },
      }}
    >
      <DialogTitle>
        {intl.formatMessage({
          id: 'users.inviteUser',
          defaultMessage: 'Invite user',
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {intl.formatMessage({
            id: 'users.inviteUserFormInfo',
            defaultMessage:
              'Invite a desired user by email and select permission. Click submit when you’re done.',
          })}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikInputField
              id={'email'}
              type={'email'}
              disabled={isInviting}
              label={intl.formatMessage({
                id: 'general.email',
                defaultMessage: 'Email',
              })}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <UserPermissionSelect disabled={isInviting} formik={formik} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onDialogCloseClick} disabled={isInviting}>
          {intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button type="submit" disabled={isInviting}>
          {isInviting ? (
            <Spinner />
          ) : (
            intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserForm;
