import { FC, useRef, useState } from 'react';
import { OverlayBoxShadow } from '../../common/graphics/lib/StyledComponentLibrary';
import { useOnClickOutside } from '../../utils/hooks';
import SvgSvgFullscreenExit from '../../shared/icons/custom/SvgFullscreenExit';
import SvgSvgFullscreen from '../../shared/icons/custom/SvgFullscreen';
import SvgSvgLoad from '../../shared/icons/custom/SvgLoad';
import SvgSvgSave from '../../shared/icons/custom/SvgSave';
import Spinner from '../Shared/Loaders/Spinner';
import styled from 'styled-components';
import styles from './Header.module.scss';
import cn from 'classnames';

const customIcons: Record<string, JSX.Element> = {
  'custom/save': <SvgSvgSave width={'2rem'} />,
  'custom/load': <SvgSvgLoad width={'2rem'} />,
  'custom/fullscreen': <SvgSvgFullscreen width={'2rem'} />,
  'custom/fullscreenExit': <SvgSvgFullscreenExit width={'2rem'} />,
};

const resolveIcon = (icon: string) => {
  if (customIcons[icon]) return customIcons[icon];
  return <i>{icon}</i>;
};

interface Props {
  dataCy: string;
  key: number;
  action?: () => void;
  label: string;
  icon: string;
  side: string;
  loading?: boolean;
  disabled?: boolean;
  submenu?: {
    label: string;
    icon: string;
    action: () => void;
    disabled?: boolean;
    loading?: boolean;
  }[];
}

const HeaderIcon: FC<Props> = (props) => {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const menuRef = useRef(null);
  useOnClickOutside(menuRef, () => setShowSubmenu(false));
  const submenu = props.submenu?.map((item, index) => {
    return (
      <StyledSubMenuItem
        key={index}
        className={cn(item.disabled ? styles.disabled : null)}
        onClick={item.loading || item.disabled ? undefined : item.action}
      >
        {item.loading ? <Spinner purple /> : resolveIcon(item.icon)}
        <span data-cy={`${props.dataCy}-submenu-${index}`}>{item.label}</span>
      </StyledSubMenuItem>
    );
  });
  return (
    <div
      data-cy={props.dataCy}
      className={cn(
        styles.navItem,
        props.side === 'right' ? styles.right : styles.left,
        props.disabled ? styles.disabled : null,
        props.label === 'Consultation' ? styles.consultationButton : null
      )}
      onClick={
        props.loading || props.disabled
          ? undefined
          : props.submenu
          ? (_event) => {
              setShowSubmenu(!showSubmenu);
            }
          : props.action
      }
    >
      {props.loading ? <Spinner purple /> : resolveIcon(props.icon)}
      <span className={styles.label}>{props.label}</span>
      {showSubmenu && <StyledSubMenu ref={menuRef}>{submenu}</StyledSubMenu>}
    </div>
  );
};

export const StyledSubMenu = styled.div`
  ${OverlayBoxShadow};
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 72px;
  z-index: 90000000;
  right: 0px;

  padding: 0.5rem;
  background-color: white;
  border-radius: 0 0 5px 5px;
`;

export const StyledSubMenuItem = styled.div`
  display: flex;
  text-align: center;
  position: relative;
  cursor: pointer;
  align-items: center;
  padding: 0.5rem;
  i {
    font-size: 2rem;
    width: 2rem;
  }
  svg {
    margin: 0;
    fill: var(--color-purple);
    stroke: var(--color-purple);
    height: 1.75rem;
    padding-bottom: 0.25rem;
  }
  span {
    white-space: nowrap;
    font-size: 0.7rem;
    padding-left: 0.5rem;
  }

  @media (max-width: 700px) {
    .header {
      height: 55px;
    }
    .left {
      display: none;
    }

    .siteNote {
      display: none;
    }

    .navItem {
      min-width: 55px;
    }
  }
`;

export default HeaderIcon;
