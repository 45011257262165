import { FC } from 'react';
import { FormikValues } from 'formik';
import { InputAdornment, TextField } from '@mui/material';

interface Props {
  id: string;
  label: string;
  type?: string;
  helperText: string | boolean | undefined;
  error: boolean | undefined;
  formik: FormikValues;
  disabled?: boolean;
  adornmentSymbol?: string;
}

export const FormikInputField: FC<Props> = ({
  id,
  label,
  type,
  helperText,
  error,
  formik,
  disabled,
  adornmentSymbol,
}) => {
  return (
    <TextField
      id={id}
      fullWidth
      variant={'outlined'}
      color={'primary'}
      size={'small'}
      type={type ? type : 'text'}
      label={label}
      name={id}
      autoComplete={'on'}
      disabled={disabled}
      helperText={helperText}
      error={Boolean(error)}
      {...formik.getFieldProps(id)}
      InputProps={{
        endAdornment: adornmentSymbol ? (
          <InputAdornment position="end">{adornmentSymbol}</InputAdornment>
        ) : null,
      }}
    />
  );
};
