import * as React from 'react';

function SvgTextliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1503 2048" {...props}>
      <path d="M1167 107q97 0 166 68.5t69 165.5v1175q0 97-69 165.5t-166 68.5H337q-97 0-166-68.5T102 1516V308q0-9 6.5-15t15.5-6 15 6 6 15v1208q0 80 56.5 136t135.5 56h830q79 0 135.5-56t56.5-136V341q0-79-56.5-135.5T1167 149H124q-9 0-15.5-6t-6.5-15 6.5-15 15.5-6h1043zM752 1342q-9 0-15-6t-6-15V610H447q-9 0-15-6t-6-15 6-15 15-6h610q9 0 15 6t6 15-6 15-15 6H773v711q0 9-6 15t-15 6z" />
    </svg>
  );
}

export default SvgTextliga;
