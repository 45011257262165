export interface IFormValues {
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  profession: string;
  password: string;
  repeatPassword: string;
  placementsYear: string;
  yearsOfPlacements: string;
  restorationsYear: string;
  yearsOfRestorations: string;
  eulaCountry: string;
  eulaId: string;
  termsOfServiceAccepted: boolean;
}

export const formInitialValues: IFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  profession: '',
  password: '',
  repeatPassword: '',
  placementsYear: '',
  yearsOfPlacements: '',
  restorationsYear: '',
  yearsOfRestorations: '',
  eulaCountry: '',
  eulaId: '',
  termsOfServiceAccepted: false,
};
