import * as React from 'react';

function SvgResourcesliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1505 2048" {...props}>
      <path d="M396 579q-9 0-15-6.5t-6-15.5 6-15 15-6h712q9 0 15 6t6 15-6 15.5-15 6.5H396zm0 299q-9 0-15-6t-6-15 6-15 15-6h712q9 0 15 6t6 15-6 15-15 6H396zm0 300q-9 0-15-6.5t-6-15.5 6-15 15-6h712q9 0 15 6t6 15-6 15.5-15 6.5H396zm0 299q-9 0-15-6t-6-15 6-15.5 15-6.5h712q9 0 15 6.5t6 15.5-6 15-15 6H396zm919-1290q36 0 61.5 25.5T1402 274v1474q0 36-25.5 61.5T1315 1835H189q-36 0-61.5-25.5T102 1748V435q0-9 6.5-15.5T124 413t15 6.5 6 15.5v1313q0 18 13 31t31 13h1126q19 0 31.5-12.5t12.5-31.5V274q0-19-12.5-31.5T1315 230H124q-9 0-15.5-6t-6.5-15 6.5-15.5T124 187h1191z" />
    </svg>
  );
}

export default SvgResourcesliga;
