import { FC } from 'react';
import ReactPlayer from 'react-player';
import styles from './VideoModal.module.scss';
import Modal from '../../Modal/Modal';
import cn from 'classnames';

interface Props {
  hide: () => void;
  show: boolean;
  header: string;
  videoUrl: string;
  userEmail: string;
}

const VideoModal: FC<Props> = ({ header, hide, show, videoUrl }) => {
  return (
    <Modal header={header} onDismiss={hide} show={show}>
      <div
        className={cn(styles.videoWrapper, videoUrl?.includes('youtube') ? styles.fixedSize : null)}
      >
        <ReactPlayer
          url={videoUrl}
          playing
          controls
          className={styles.video}
          width="100%"
          height="100%"
        />
      </div>
    </Modal>
  );
};

export default VideoModal;
