import { Theme } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';


export const MuiPrototypesPageStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1, 3),
    maxWidth: '900px',
    overflow: 'auto',
    height: '100%',
    width: '100%',
  },
}));
