import { FC } from 'react';
import styles from './ErrorPanel.module.scss';

interface Props {
  error: {
    data: { message: string };
    status: number;
  };
}

const ErrorPanel: FC<Props> = ({ error }) => {
  return (
    <div className={styles.errorPanel}>
      <div>
        <h4 className={styles.errorTitle}>Error - {error.status}</h4>
        <p className={styles.errorSubtitle}>{error.data.message}</p>
      </div>
    </div>
  );
};

export default ErrorPanel;
