import { IFilteredData } from '../types/clinicOverview';

export const dummyData: IFilteredData = {
  healingTimeProtocol: [
    {
      label: 'Immediate loading',
      value: 0,
    },
    {
      label: 'Early loading',
      value: 0,
    },
    {
      label: 'Conventional loading',
      value: 0,
    },
  ],
  healingTimeProcedure: [
    {
      label: 'Mandible',
      value: 0,
    },
    {
      label: 'Maximilia',
      value: 0,
    },
  ],
  healingTimeRisk: [
    {
      label: 'Bruxist',
      value: 0,
    },
    {
      label: 'Oral hygiene',
      value: 0,
    },
    {
      label: 'Osteoporosis',
      value: 0,
    },
    {
      label: 'Recreational drug use',
      value: 0,
    },
    {
      label: 'Diabetes',
      value: 0,
    },
  ],
  healingTimeBrand: [
    {
      label: '',
      value: 0,
    },
  ],
  failureProtocol: [
    {
      label: 'Immediate loading',
      value: 0,
    },
    {
      label: 'Early loading',
      value: 0,
    },
    {
      label: 'Conventional loading',
      value: 0,
    },
  ],
  failureProcedure: [
    {
      label: 'Mandible',
      value: 0,
    },
    {
      label: 'Maximilia',
      value: 0,
    },
  ],
  failureBrand: [
    {
      label: '',
      value: 0,
    },
  ],
  failureRisk: [
    {
      label: 'Bruxist',
      value: 0,
    },
    {
      label: 'Oral hygiene',
      value: 0,
    },
    {
      label: 'Osteoporosis',
      value: 0,
    },
    {
      label: 'Recreational drug use',
      value: 0,
    },
    {
      label: 'Diabetes',
      value: 0,
    },
  ],
  isqPlacement: [
    {
      label: 'Immediate',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
    {
      label: 'Early Placement',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
    {
      label: 'Conventional',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
  ],
  isqReady: [
    {
      label: 'Immediate',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
    {
      label: 'Early Ready',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
    {
      label: 'Conventional',
      lowPercentile: 0,
      highPercentile: 0,
      min: 0,
      max: 0,
    },
  ],
  distributionProtocol: [
    {
      label: 'Immediate Loading',
      value: 0,
    },
    {
      label: 'Early loading',
      value: 0,
    },
    {
      label: 'Conventional loading',
      value: 0,
    },
  ],
  distributionRisk: [
    {
      label: 'With riskfactors',
      value: 0,
    },
    {
      label: 'Without riskfactors',
      value: 0,
    },
  ],
  distributionStage: [
    {
      label: '1 - Stage',
      value: 0,
    },
    {
      label: '2 - Stage',
      value: 0,
    },
  ],
  distributionBrand: [
    {
      label: '',
      value: 0,
    },
  ],
};
