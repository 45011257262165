import { IDentalNotation } from '../types/dentalNotation';

export const dentalNotations: IDentalNotation[] = [
  {
    uns: 1,
    fdi: 18,
    palmer: 8,
    name: 'third molar',
  },
  {
    uns: 2,
    fdi: 17,
    palmer: 7,
    name: 'second molar',
  },
  {
    uns: 3,
    fdi: 16,
    palmer: 6,
    name: 'first molar',
  },
  {
    uns: 4,
    fdi: 15,
    palmer: 5,
    name: 'second premolar',
  },
  {
    uns: 5,
    fdi: 14,
    palmer: 4,
    name: 'first premolar',
  },
  { uns: 6, fdi: 13, palmer: 3, name: 'canine' },

  {
    uns: 7,
    fdi: 12,
    palmer: 2,
    name: 'lateral incisor',
  },
  {
    uns: 8,
    fdi: 11,
    palmer: 1,
    name: 'central incisor',
  },
  {
    uns: 9,
    fdi: 21,
    palmer: 1,
    name: 'central incisor',
  },
  {
    uns: 10,
    fdi: 22,
    palmer: 2,
    name: 'lateral incisor',
  },
  { uns: 11, fdi: 23, palmer: 3, name: 'canine' },

  {
    uns: 12,
    fdi: 24,
    palmer: 4,
    name: 'first premolar',
  },
  {
    uns: 13,
    fdi: 25,
    palmer: 5,
    name: 'second premolar',
  },
  {
    uns: 14,
    fdi: 26,
    palmer: 6,
    name: 'first molar',
  },
  {
    uns: 15,
    fdi: 27,
    palmer: 7,
    name: 'second molar',
  },
  {
    uns: 16,
    fdi: 28,
    palmer: 8,
    name: 'third molar',
  },
  {
    uns: 17,
    fdi: 38,
    palmer: 8,
    name: 'third molar',
  },
  {
    uns: 18,
    fdi: 37,
    palmer: 7,
    name: 'second molar',
  },
  {
    uns: 19,
    fdi: 36,
    palmer: 6,
    name: 'first molar',
  },
  {
    uns: 20,
    fdi: 35,
    palmer: 5,
    name: 'second premolar',
  },
  {
    uns: 21,
    fdi: 34,
    palmer: 4,
    name: 'first premolar ',
  },
  { uns: 22, fdi: 33, palmer: 3, name: 'canine' },

  {
    uns: 23,
    fdi: 32,
    palmer: 2,
    name: 'lateral incisor',
  },
  {
    uns: 24,
    fdi: 31,
    palmer: 1,
    name: 'central incisor',
  },
  {
    uns: 25,
    fdi: 41,
    palmer: 1,
    name: 'central incisor',
  },
  {
    uns: 26,
    fdi: 42,
    palmer: 2,
    name: 'lateral incisor',
  },
  { uns: 27, fdi: 43, palmer: 3, name: 'canine' },

  {
    uns: 28,
    fdi: 44,
    palmer: 4,
    name: 'first premolar ',
  },
  {
    uns: 29,
    fdi: 45,
    palmer: 5,
    name: 'second premolar ',
  },
  {
    uns: 30,
    fdi: 46,
    palmer: 6,
    name: 'first molar',
  },
  {
    uns: 31,
    fdi: 47,
    palmer: 7,
    name: 'second molar',
  },
  {
    uns: 32,
    fdi: 48,
    palmer: 8,
    name: 'third molar',
  },
];
