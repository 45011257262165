import { Panel } from '../Shared/Panel';
import { ChangeEvent, FC } from 'react';
import classNames from 'classnames';
import styles from './ISQItem.module.scss';

interface Props {
  dataCy?: string;
  editable: boolean;
  direction: string;
  isq: string;
  isqChanged: (direction: string, value: string) => void;
  active: boolean;
  updated: boolean;
  abutment: boolean;
}

const ISQItem: FC<Props> = ({
  direction,
  isq,
  abutment,
  active,
  updated,
  editable,
  isqChanged,
  dataCy,
}) => {
  const getColor = () => {
    if (isq) {
      if (abutment) return styles.neutral;
      if (Number(isq) >= 70) return styles.high;
      if (Number(isq) >= 60) return styles.mid;
      if (Number(isq) > 0) return styles.low;
    }
    return styles.noMeasurement;
  };

  const flash = () => {
    if (active) return styles.active;
    if (updated) return styles.flash;
  };

  const isqChangedHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const text = evt.target.value;
    if (isqChanged) {
      if (text.length === 0 || (text.length < 3 && Number(text) > 0)) {
        isqChanged(direction, evt.target.value);
      }
    }
  };

  const tile = classNames(styles.tile, styles.medium, 'display', getColor(), flash());

  return (
    <Panel className={tile}>
      <div className={styles.header}>{direction}</div>
      <div className={styles.isq}>
        {editable ? (
          <input
            data-cy={dataCy && dataCy}
            className={styles.isqInput}
            type="text"
            value={isq}
            onChange={isqChangedHandler}
            pattern="\d*"
          />
        ) : (
          isq
        )}
      </div>
      <div className={styles.colorIndicator}></div>
    </Panel>
  );
};

export default ISQItem;
