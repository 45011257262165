import * as types from '../../constants/actionTypes';

const initialState = {
  complete: false,
  error: undefined,
  busy: false,
  items: [],
};

export default function patient(state = initialState, action) {
  switch (action.type) {
    case types.GET_KEY_PERFORMANCE_INDICATORS:
      return Object.assign({}, state, { complete: false, error: undefined, busy: true });
    case types.GET_KEY_PERFORMANCE_INDICATORS_SUCCESS:
      return Object.assign({}, state, {
        items: action.keyPerformanceIndicators,
        complete: true,
        error: undefined,
        busy: false,
      });
    case types.GET_KEY_PERFORMANCE_INDICATORS_ERROR:
      return Object.assign({}, state, { complete: false, error: action.error, busy: false });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
