import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useGetUserClinicsQuery } from '../../redux/api/clinic/clinicApi';
import ClinicToggler from '../clinic-toggler/ClinicToggler';
import HeaderIcon from './HeaderIcon';
import styles from './Header.module.scss';
import { useAppSelector } from '../../utils/hooks';

const renderButtons = (side: string, buttons?: HeaderButtons[]) => {
  if (buttons) {
    return buttons.map((button, index: number) => {
      return (
        <HeaderIcon
          dataCy={`consultation-header-${button.label}`}
          key={index}
          action={button.action}
          label={button.label}
          icon={button.icon}
          side={side}
          loading={button.loading}
          disabled={button.disabled}
          submenu={button.submenu}
        />
      );
    });
  }
};

export interface HeaderButtons {
  label: string;
  icon: string;
  action?: () => void;
  loading?: boolean;
  disabled?: boolean;
  submenu?: {
    label: string;
    icon: string;
    action: () => void;
  }[];
}

interface Props {
  title: string | JSX.Element;
  loading?: boolean;
  disabled?: boolean;
  isTogglerHidden?: boolean;
  skipTitleOnMobile?: boolean;
  leftButtons?: HeaderButtons[];
  rightButtons?: HeaderButtons[];
}

const Header: FC<Props> = ({
  title,
  skipTitleOnMobile,
  isTogglerHidden,
  leftButtons,
  rightButtons,
}) => {
  const intl = useIntl();
  const { data: clinics, refetch } = useGetUserClinicsQuery();
  const { clinicUpdate } = useAppSelector((state) => state.clinic);

  useEffect(() => {
    if (clinics && clinics.length > 1) {
      refetch();
    }
  }, [clinicUpdate, refetch, clinics]);

  return (
    <div className={styles.header}>
      <div className={styles.buttons}>
        <span>{renderButtons('left', leftButtons)}</span>
      </div>

      <div className={`${styles.titleDiv} ${skipTitleOnMobile && styles.skipTitleOnMobile}`}>
        <span className={styles.title}>{title}</span>
        {process.env.REACT_APP_DEMO ? (
          <div className={styles.siteNote}>
            {intl.formatMessage({
              id: 'header.demoNote',
              defaultMessage: 'DEMO - Not for clinical use',
            })}
          </div>
        ) : null}
      </div>
      <div className={styles.buttons}>
        <span>
          <div className={styles.toggler}>
            {clinics && clinics.length > 1 && !isTogglerHidden && (
              <ClinicToggler clinics={clinics} />
            )}
          </div>
          {renderButtons('right', rightButtons)}
        </span>
      </div>
    </div>
  );
};

export default Header;
