import React from 'react';
import styles from './Section.module.scss';
import cn from 'classnames';

const Row = (props) => (
  <section className={cn(styles.row, props.className, props.right ? styles.pullRight : null)}>
    {props.children}
  </section>
);

export default Row;
