import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import cn from 'classnames';

import * as patientActions from '../../redux/actions/patientActions';

import styles from './ToothWH.module.scss';
import ToothImage from './ToothImage';
import tooth_11 from './Images/11.png';
import tooth_12 from './Images/12.png';
import tooth_13 from './Images/13.png';
import tooth_14 from './Images/14.png';
import tooth_15 from './Images/15.png';
import tooth_16 from './Images/16.png';
import tooth_17 from './Images/17.png';
import tooth_18 from './Images/18.png';
import tooth_21 from './Images/21.png';
import tooth_22 from './Images/22.png';
import tooth_23 from './Images/23.png';
import tooth_24 from './Images/24.png';
import tooth_25 from './Images/25.png';
import tooth_26 from './Images/26.png';
import tooth_27 from './Images/27.png';
import tooth_28 from './Images/28.png';
import tooth_31 from './Images/31.png';
import tooth_32 from './Images/32.png';
import tooth_33 from './Images/33.png';
import tooth_34 from './Images/34.png';
import tooth_35 from './Images/35.png';
import tooth_36 from './Images/36.png';
import tooth_37 from './Images/37.png';
import tooth_38 from './Images/38.png';
import tooth_41 from './Images/41.png';
import tooth_42 from './Images/42.png';
import tooth_43 from './Images/43.png';
import tooth_44 from './Images/44.png';
import tooth_45 from './Images/45.png';
import tooth_46 from './Images/46.png';
import tooth_47 from './Images/47.png';

import implantNeutral from './Images/implant_neutral.png';
import implantLow from './Images/implant_low.png';
import implantMedium from './Images/implant_medium.png';
import implantHigh from './Images/implant_high.png';

import abutment from './Images/abutment.png';

class ToothWH extends Component {
  constructor(props) {
    super(props);

    this.selectTooth = this.selectTooth.bind(this);
    this.getTooth = this.getTooth.bind(this);
    this.getImage = this.getImage.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getStatusClass = this.getStatusClass.bind(this);
    this.getImplant = this.getImplant.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {}

  getposition() {
    switch (this.props.position) {
      case '' || null || undefined:
        return null;
      case 'upper':
        return this.props.small ? styles.upperSmall : styles.upper;
      case 'lower':
        return this.props.small ? styles.lowerSmall : styles.lower;
      default:
        throw new Error('Unsupported position');
    }
  }

  getStatus() {
    if (this.props.implant.isq && this.props.implant.isq >= 70) {
      return 'high';
    } else if (
      this.props.implant.isq &&
      this.props.implant.isq < 70 &&
      this.props.implant.isq >= 60
    ) {
      return 'medium';
    } else if (this.props.implant.isq && this.props.implant.isq > 0) {
      return 'low';
    }
    return null;
  }

  getStatusClass() {
    if (this.props.implant.isq && this.props.implant.isq >= 70) {
      return styles.isqHigh;
    } else if (
      this.props.implant.isq &&
      this.props.implant.isq < 70 &&
      this.props.implant.isq >= 60
    ) {
      return styles.isqMedium;
    } else if (this.props.implant.isq && this.props.implant.isq > 0) {
      return styles.isqLow;
    }
  }

  activeTooth() {
    if (this.props.implant || this.props.abutment || this.props.prosthetic) {
      return styles.active;
    }
  }

  selectedTooth() {
    if (this.props.selected) {
      return styles.selected;
    }
  }

  isFaded() {
    if (this.props.faded) {
      return styles.fade;
    }
  }

  selectTooth() {
    const notation = this.props.selected ? -1 : this.props.notation;
    this.props.actions.selectPatientImplant(notation);
  }

  getImplant() {
    if (this.props.implant) {
      switch (this.getStatus()) {
        case 'low':
          return implantLow;
        case 'medium':
          return implantMedium;
        case 'high':
          return implantHigh;
        default:
          return implantNeutral;
      }
    }
    return null;
  }

  getTooth(fdi) {
    let row = 'upper';
    if (fdi === 11) {
      return <ToothImage image={tooth_11} row={row} />;
    }
    if (fdi === 12) {
      return <ToothImage image={tooth_12} row={row} />;
    }
    if (fdi === 13) {
      return <ToothImage image={tooth_13} row={row} />;
    }
    if (fdi === 14) {
      return <ToothImage image={tooth_14} row={row} />;
    }
    if (fdi === 15) {
      return <ToothImage image={tooth_15} row={row} />;
    }
    if (fdi === 16) {
      return <ToothImage image={tooth_16} row={row} />;
    }
    if (fdi === 17) {
      return <ToothImage image={tooth_17} row={row} />;
    }
    if (fdi === 18) {
      return <ToothImage image={tooth_18} row={row} />;
    }
    if (fdi === 21) {
      return <ToothImage image={tooth_21} row={row} />;
    }
    if (fdi === 22) {
      return <ToothImage image={tooth_22} row={row} />;
    }
    if (fdi === 23) {
      return <ToothImage image={tooth_23} row={row} />;
    }
    if (fdi === 24) {
      return <ToothImage image={tooth_24} row={row} />;
    }
    if (fdi === 25) {
      return <ToothImage image={tooth_25} row={row} />;
    }
    if (fdi === 26) {
      return <ToothImage image={tooth_26} row={row} />;
    }
    if (fdi === 27) {
      return <ToothImage image={tooth_27} row={row} />;
    }
    if (fdi === 28) {
      return <ToothImage image={tooth_28} row={row} />;
    }
    row = 'lower';
    if (fdi === 31) {
      return <ToothImage image={tooth_31} row={row} />;
    }
    if (fdi === 32) {
      return <ToothImage image={tooth_32} row={row} />;
    }
    if (fdi === 33) {
      return <ToothImage image={tooth_33} row={row} />;
    }
    if (fdi === 34) {
      return <ToothImage image={tooth_34} row={row} />;
    }
    if (fdi === 35) {
      return <ToothImage image={tooth_35} row={row} />;
    }
    if (fdi === 36) {
      return <ToothImage image={tooth_36} row={row} />;
    }
    if (fdi === 37) {
      return <ToothImage image={tooth_37} row={row} />;
    }
    if (fdi === 38) {
      return <ToothImage image={tooth_38} row={row} />;
    }
    if (fdi === 41) {
      return <ToothImage image={tooth_41} row={row} />;
    }
    if (fdi === 42) {
      return <ToothImage image={tooth_42} row={row} />;
    }
    if (fdi === 43) {
      return <ToothImage image={tooth_43} row={row} />;
    }
    if (fdi === 44) {
      return <ToothImage image={tooth_44} row={row} />;
    }
    if (fdi === 45) {
      return <ToothImage image={tooth_45} row={row} />;
    }
    if (fdi === 46) {
      return <ToothImage image={tooth_46} row={row} />;
    }
    if (fdi === 47) {
      return <ToothImage image={tooth_47} row={row} />;
    }
  }

  getImage(fdi) {
    if (this.props.implant) {
      const implantStyle = this.props.small ? styles.implantSVGSmall : styles.implantSVG;
      const flipped = this.props.position === 'lower' ? styles.implantFlipped : null;
      if (!this.props.implant.isq) {
        const emptyStyle = this.props.implant.hasImplant ? null : styles.empty;

        return (
          <div className={cn(flipped, implantStyle, emptyStyle)}>
            {this.props.implant.hasImplant && (
              <ToothImage
                image={implantNeutral}
                row={this.props.position}
                implant
                abutment={this.props.implant.hasAbutment ? abutment : null}
              />
            )}
            {/*<ImplantOutLine />*/}
          </div>
        );
      }
      const implantVariant = this.getImplant();
      return (
        <div className={cn(flipped, this.getStatusClass(), implantStyle)}>
          <ToothImage
            image={implantVariant}
            abutment={this.props.implant.hasAbutment ? abutment : null}
            row={this.props.position}
            implant
          />
          {/*<Implant />*/}
        </div>
      );
    } else {
      const toothStyle = this.props.small ? styles.toothSVGSmall : styles.toothSVG;
      return <div className={toothStyle}>{this.getTooth(fdi)}</div>;
    }
  }

  renderHistoryIcon() {
    return <i className={styles.historyIcon}>history</i>;
  }

  render() {
    return (
      <div
          data-cy={this.props.dataCy && this.props.dataCy}
          className={classNames(
          styles.tooth,
          this.getposition(),
          this.activeTooth(),
          this.selectedTooth(),
          this.isFaded()
        )}
        onClick={this.selectTooth}
      >
        {this.props.static ? (
          this.getImage(this.props.fdiNotation)
        ) : (
          <div>{this.getImage(this.props.fdiNotation)}</div>
        )}
        <div
          className={classNames(styles.positionText, this.props.small ? styles.smallText : null)}
        >
          {this.props.displayNotation === 'UNS' ? this.props.notation : this.props.fdiNotation}
          {this.props.implant && (this.props.implant.hasHistory ? this.renderHistoryIcon() : null)}
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {};
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(patientActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(ToothWH);
