import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';
import { REHYDRATE } from 'redux-persist/lib/constants';
import Store from '../store';

const initialState = {
  token: '',
  tokenExpired: false,
  inactivity: false,
  inactivityWarning: false,
  rehydrated: false,
  lastUsedEmail: '',
  login: {
    complete: false,
    error: undefined,
    busy: false,
  },
  accountRegistration: {
    complete: false,
    error: undefined,
    busy: false,
  },
  accountConfirmation: {
    complete: false,
    error: undefined,
    busy: false,
  },
  retrievePassword: {
    complete: false,
    error: undefined,
    busy: false,
  },
  resetPassword: {
    complete: false,
    error: undefined,
    busy: false,
  },
  reauthenticate: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function user(state = initialState, action) {
  let incoming;
  let initialStateCopy;
  switch (action.type) {
    case types.RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPassword: { complete: false, error: undefined, busy: true },
      });
    case types.RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        resetPassword: { complete: true, error: undefined, busy: false },
      });
    case types.RESET_PASSWORD_ERROR:
      return Object.assign({}, state, {
        resetPassword: { complete: false, error: action.error, busy: false },
      });
    case types.REGISTER_ACCOUNT:
      return Object.assign({}, state, {
        accountRegistration: { complete: false, error: undefined, busy: true },
      });
    case types.REGISTER_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        accountRegistration: { complete: true, error: undefined, busy: false },
      });
    case types.REGISTER_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        accountRegistration: { complete: false, error: action.error, busy: false },
      });
    case types.CONFIRM_ACCOUNT:
      ApiFetch.setToken(action.token);
      return Object.assign({}, state, {
        accountConfirmation: { complete: false, error: undefined, busy: true },
        token: action.token,
        tokenExpired: false,
      });
    case types.ADD_TOKEN:
      ApiFetch.setToken(action.token);
      return Object.assign({}, state, {
        token: action.token,
        tokenExpired: false,
      });
    case types.CONFIRM_ACCOUNT_SUCCESS:
      if (action.token) {
        ApiFetch.setToken(action.token);
        return Object.assign({}, state, {
          login: { complete: true, error: undefined, busy: false },
          token: action.token,
          tokenExpired: false,
          accountConfirmation: { complete: true, error: undefined, busy: false },
        });
      }
      return Object.assign({}, state, {
        accountConfirmation: { complete: true, error: undefined, busy: false },
      });
    case types.CONFIRM_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        accountConfirmation: { complete: false, error: action.error, busy: false },
      });
    case types.LOGIN_ACCOUNT:
      return Object.assign({}, state, { login: { complete: false, error: undefined, busy: true } });
    case types.LOGIN_ACCOUNT_SUCCESS:
      ApiFetch.setToken(action.token);
      return Object.assign({}, state, {
        login: { complete: true, error: undefined, busy: false },
        token: action.token,
        tokenExpired: false,
        inactivity: false,
        lastUsedEmail: action.email,
      });
    case types.LOGIN_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        login: { complete: false, error: action.error, busy: false },
        tokenExpired: false,
        inactivity: false,
      });
    case types.CLEAR_TOKEN:
      initialStateCopy = Object.assign({}, state, initialState);
      ApiFetch.clearToken();
      initialStateCopy.rehydrated = true;
      initialStateCopy.tokenExpired = action.tokenExpired;
      initialStateCopy.inactivity = action.inactivity;
      initialStateCopy.lastUsedEmail = state.lastUsedEmail;
      Store.purge();
      return Object.assign({}, state, initialStateCopy);
    case types.RETRIEVE_PASSWORD:
      return Object.assign({}, state, {
        retrievePassword: { complete: false, error: undefined, busy: true },
      });
    case types.REAUTHENTICATE:
      return Object.assign({}, state, {
        reauthenticate: { complete: false, error: undefined, busy: true },
      });
    case types.REAUTHENTICATE_SUCCESS:
      return Object.assign({}, state, {
        reauthenticate: { complete: true, error: undefined, busy: false },
      });
    case types.REAUTHENTICATE_ERROR:
      return Object.assign({}, state, {
        reauthenticate: { complete: false, error: action.errorMessages, busy: false },
      });
    case types.RETRIEVE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        retrievePassword: { complete: true, error: undefined, busy: false },
      });
    case types.RETRIEVE_PASSWORD_ERROR:
      return Object.assign({}, state, {
        retrievePassword: { complete: false, error: action.error, busy: false },
      });
    case REHYDRATE:
      incoming = action.payload?.account;
      if (incoming) {
        if (incoming.token) ApiFetch.setToken(incoming.token);
        return Object.assign({}, state, {
          lastUsedEmail: incoming.lastUsedEmail ?? '',
          token: incoming.token ?? '',
          rehydrated: true,
        });
      }
      return Object.assign({}, state, { rehydrated: true });
    case types.INACTIVITY_WARNING:
      return Object.assign({}, state, { inactivityWarning: action.inactivity });
    default:
      return state;
  }
}
