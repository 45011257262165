import React from 'react';
import styles from './Table.module.scss';
import cn from 'classnames';

const TD = (props) => (
  <td
    className={cn(
      props.className,
      styles.td,
      (props.button || props.separator) && styles.separator,
      props.button && styles.button,
      props.align && styles[props.align],
      props.contentWidth && styles.contentWidth
    )}
    onClick={props.onClick}
    data-cy={props.dataCy && props.dataCy}
  >
    {props.children}
  </td>
);

export default TD;
