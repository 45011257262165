import * as React from 'react';

function SvgPeakliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1394 2048" {...props}>
      <path d="M1271 1598q9 0 15.5 6t6.5 15-6.5 15-15.5 6H124q-9 0-15.5-6t-6.5-15 6.5-15 15.5-6q74 0 135-31.5t102-75.5 74-126 51-146.5 34.5-176 22.5-175T559 687q7-88 11.5-131.5t13-109.5 18-98 24.5-67.5 35-49.5 47-14 48 14.5 37 52 26 71 20 103 15.5 115T869 711q9 88 14 135.5t16.5 134 21.5 137 28 124.5 38 117.5 49 95 62.5 78.5 77.5 46 95 19zm-976 0h819q-39-24-72-60t-58.5-87-45-98.5-35.5-119-26.5-122-21-134.5T840 847t-14-132q-7-74-11.5-113.5T802 499t-16-97.5-19.5-72-26-54T708 259q-17 0-30.5 15.5t-24 51.5-17.5 67.5-14 93.5-10.5 96.5T602 691q-7 83-11.5 130.5t-14 131.5-20.5 137.5-27.5 126.5-38 124-49.5 103.5-65 90.5-81 63z" />
    </svg>
  );
}

export default SvgPeakliga;
