import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getUserGroupsRequest() {
  return {
    type: types.GET_USER_GROUPS,
  };
}

function getUserGroupsSuccess(userGroups) {
  return {
    type: types.GET_USER_GROUPS_SUCCESS,
    userGroups,
  };
}

function getUserGroupsFailed(error) {
  return {
    type: types.GET_USER_GROUPS_ERROR,
    error,
  };
}

export function getUserGroups() {
  return (dispatch) => {
    dispatch(getUserGroupsRequest());

    ApiFetch.get('admin/userGroups')
      .then((data) => {
        dispatch(getUserGroupsSuccess(data));
      })
      .catch((error) => {
        dispatch(getUserGroupsFailed(error));
      });
  };
}

function connectUserGroupToInstrumentsRequest() {
  return {
    type: types.CONNECT_USER_GROUP_TO_INSTRUMENTS,
  };
}

function connectUserGroupToInstrumentsSuccess() {
  return {
    type: types.CONNECT_USER_GROUP_TO_INSTRUMENTS_SUCCESS,
  };
}

function connectUserGroupToInstrumentsFailed(error) {
  return {
    type: types.CONNECT_USER_GROUP_TO_INSTRUMENTS_ERROR,
    error,
  };
}

export function connectUserGroupToInstruments(userGroupId, instrumentIds, callback) {
  return (dispatch) => {
    dispatch(connectUserGroupToInstrumentsRequest());

    ApiFetch.put(`admin/userGroups/${userGroupId}/instruments`, instrumentIds)
      .then((_) => {
        dispatch(connectUserGroupToInstrumentsSuccess());
        callback();
      })
      .catch((error) => {
        dispatch(connectUserGroupToInstrumentsFailed(error));
      });
  };
}
