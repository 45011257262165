import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as studyGroupActions from '../../../redux/slices/studyGroupSlice';

import styles from './StudyGroupsAdministration.module.scss';
import StudyGroupRow from './StudyGroupRow';
import { SectionTabContent } from '../../../components/Shared/Section';
import { PanelWrapper, Panel, PanelHeader } from '../../../components/Shared/Panel';
import Spinner from '../../../components/Shared/Loaders/Spinner';

class StudyGroupsAdministration extends Component {
  componentDidMount() {
    this.props.actions.getStudyGroups(true);
  }

  renderStudyGroups() {
    return this.props.studyGroups.map((studyGroup, index) => {
      return <StudyGroupRow key={index} id={index} studyGroup={studyGroup} />;
    });
  }

  render() {
    return (
      <SectionTabContent>
        <PanelWrapper className={styles.marginTop}>
          <Panel full filled>
            <PanelHeader smallHeader="Groups" bigHeader="Group list" />
            <div>
              {this.props.getStudyGroups.busy ? (
                <Spinner className={styles.spinnerBackground} />
              ) : (
                this.renderStudyGroups()
              )}
            </div>
          </Panel>
        </PanelWrapper>
      </SectionTabContent>
    );
  }
}

function mapState(state) {
  return {
    getStudyGroups: state.studyGroup.getStudyGroupsStatus,
    studyGroups: state.studyGroup.studyGroups,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(studyGroupActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(StudyGroupsAdministration);
