export interface AdminTabs {
  name: string;
  active: boolean;
}

export const superAdminTabs: AdminTabs[] = [
  { name: 'Users', active: true },
  { name: 'Instruments', active: false },
  { name: 'Firmwares', active: false },
  { name: 'Studies', active: false },
  { name: 'Logs', active: false },
  { name: 'Implants', active: false },
];

export const adminLv1Tabs: AdminTabs[] = [
  { name: 'Users', active: true },
  { name: 'Instruments', active: false },
  { name: 'Logs', active: false },
];

export const adminLv2Tabs: AdminTabs[] = [
  { name: 'Users', active: true },
  { name: 'Instruments', active: false },
];
