import { toastr } from 'react-redux-toastr';
import { Button } from '../Shared/Buttons';
import { useIntl } from 'react-intl';
import { AlertBox } from '../Shared/Alerts';
import { toastrOptions } from '../../constants/toastrOptions';
import { IReferenceDataObj } from '../../types/referenceData';
import { InputItem, InputRow } from '../Shared/Forms';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { setImplantFailed as setImplantFailedRequest } from '../../redux/actions/patientActions';
import styles from './FailImplantModal.module.scss';
import Modal from '../Shared/Modal/Modal';

interface Props {
  show: boolean;
  hide: () => void;
}

const FailImplantModal: FC<Props> = ({ show, hide }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [failType, setFailType] = useState('');
  const [failDate, setFailDate] = useState(new Date());
  const [failReason, setFailReason] = useState('');
  const [isImplantFailed, setIsImplantFailed] = useState(false);

  const {
    setImplantFailed,
    currentPatient: patient,
    selectedPatientImplant: selectedImplant,
  } = useAppSelector((state) => state.patient);

  const { implantFailureTypes: failTypes, implantReasonForFailure: failReasons } = useAppSelector(
    (state) => state.referenceData
  );

  const modifiedFailTypes = failTypes.map((x: IReferenceDataObj) => ({
    key: x.key,
    value: intl.formatMessage({ ...x.label }),
  }));
  const modifiedFailReasons = failReasons.map((x: IReferenceDataObj) => ({
    key: x.key,
    value: intl.formatMessage({ ...x.label }),
  }));

  useEffect(() => {
    if (setImplantFailed.complete && isImplantFailed) {
      setIsImplantFailed(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'failImplant.implantMovedToHistory',
          defaultMessage: 'Implant moved to history',
        }),
        toastrOptions
      );
      hideFailImplantModal();
    }
  }, [setImplantFailed.complete, isImplantFailed]);

  const disableSave = !failType || !failDate || !failReason;

  const hideFailImplantModal = () => {
    setFailType('');
    setFailDate(new Date());
    setFailReason('');
    if (hide) {
      hide();
    }
  };

  const failImplant = () => {
    setIsImplantFailed(true);
    dispatch(
      setImplantFailedRequest(
        patient.sourceUserGroupId,
        patient.id,
        selectedImplant,
        failDate,
        failType,
        failReason
      )
    );
  };

  const failTypeChanged = (event: ChangeEvent<HTMLInputElement>) => setFailType(event.target.value);

  const failDateChanged = (date: Date) => setFailDate(date);

  const failReasonChanged = (event: ChangeEvent<HTMLInputElement>) =>
    setFailReason(event.target.value);

  const header = intl.formatMessage({
    id: 'failImplant.markImplantAsFailed',
    defaultMessage: 'Mark implant as failed',
  });

  const error = setImplantFailed.error;
  return (
    <Modal
      modalPanelClass={styles.modalPanel}
      header={header}
      onDismiss={hideFailImplantModal}
      show={show}
    >
      {error ? (
        <AlertBox
          color="black"
          headerText="Oops, there was an error"
          messages={error.messages.map((m: { text: string }) => m.text)}
        />
      ) : null}
      <div className={styles.content}>
        <div className={styles.column}>
          <div>
            <InputItem
              label={intl.formatMessage({
                id: 'implant.typeOfFailure',
                defaultMessage: 'Type of failure',
              })}
              type="selectbutton"
              options={modifiedFailTypes}
              value={failType}
              onChange={failTypeChanged}
              centered
              onWhite
              span
            />
            <InputItem
              label={intl.formatMessage({
                id: 'implant.failureDate',
                defaultMessage: 'Failure date',
              })}
              value={failDate}
              type="date"
              onChange={failDateChanged}
              onWhite
              timeVisible={false}
            />
          </div>
          <InputRow>
            <InputItem
              label={intl.formatMessage({
                id: 'implant.reasonForFailure',
                defaultMessage: 'Reason for failure',
              })}
              type="select"
              options={modifiedFailReasons}
              defaultKey={'NoSelection'}
              defaultValue={''}
              value={failReason}
              onChange={failReasonChanged}
              onWhite
            />
          </InputRow>
        </div>
      </div>
      <div className={styles.buttons}>
        <Button
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={hideFailImplantModal}
          diabled={isImplantFailed}
        />
        <Button
          primary
          value={intl.formatMessage({
            id: 'failImplant.markAsFailed',
            defaultMessage: 'Mark as failed',
          })}
          onClick={failImplant}
          disabled={disableSave || isImplantFailed}
          loading={isImplantFailed}
        />
      </div>
    </Modal>
  );
};

export default FailImplantModal;
