import * as React from 'react';

function SvgLocalLossliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1482 2048" {...props}>
      <path d="M1240 216q59 0 100.5 41t41.5 98v1051q0 97-69 165.5t-166 68.5H337q-97 0-166-68.5T102 1406V355q0-57 41-98t98-41 96.5 36.5T382 344q3 35 6 91 3 54 5.5 89t7.5 99 11 108.5T427.5 834t21.5 97.5 27 75.5 35.5 54 43.5 18h374q23 0 43.5-18t35.5-54 27-75.5 21.5-97.5 15.5-102.5 11-108.5 7.5-99 5.5-89q3-56 6-91 5-55 44-91.5t94-36.5zm99 1190V355q0-40-28-68t-68-28-67.5 25-30.5 63q-3 37-6 90-6 100-12 172t-16.5 157.5-26 143.5-36.5 109-51 76.5-68 25.5H554q-29 0-53-14.5t-42.5-44.5-33.5-65.5-26-88-19.5-102-15-117-11-123T345 437q-3-53-6-90-3-38-30.5-63T244 259q-42 0-70.5 28T145 355v1051q0 80 56 136t135 56h811q79 0 135.5-56t56.5-136z" />
    </svg>
  );
}

export default SvgLocalLossliga;
