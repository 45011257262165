import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getInsightsRequest() {
  return {
    type: types.GET_INSIGHTS,
  };
}

function getInsightsSuccess(data) {
  return {
    type: types.GET_INSIGHTS_SUCCESS,
    insights: data,
  };
}

function getInsightsFailed(error) {
  return {
    type: types.GET_INSIGHTS_ERROR,
    error,
  };
}

export function getInsights(userId) {
  return (dispatch) => {
    dispatch(getInsightsRequest());

    ApiFetch.get(`insights/${userId}`)
      .then((data) => {
        dispatch(getInsightsSuccess(data));
      })
      .catch((error) => {
        dispatch(getInsightsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function getClinicInsightsRequest() {
  return {
    type: types.GET_CLINIC_INSIGHTS,
  };
}

function getClinicInsightsSuccess(data) {
  return {
    type: types.GET_CLINIC_INSIGHTS_SUCCESS,
    insights: data,
  };
}

function getClinicInsightsFailed(error) {
  return {
    type: types.GET_CLINIC_INSIGHTS_ERROR,
    error,
  };
}

export function getClinicInsights() {
  return (dispatch) => {
    dispatch(getClinicInsightsRequest());

    ApiFetch.get(`clinic/insights`)
      .then((data) => {
        dispatch(getClinicInsightsSuccess(data));
      })
      .catch((error) => {
        dispatch(getClinicInsightsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

//TODO subscribe to clinic data updates
