import React, { MutableRefObject } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import {
  ToolIcons,
  workspaceToolsState,
} from '../../../../../../redux/slices/data-structures/workspaceStructure';
import { SET_TOOL } from '../../../../../../redux/slices/UISlice';
import { OverlayBoxRounded } from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import TeethSubToolItem, { SubToolPanel } from './TeethSubToolItem';
import TeethToolItem from './TeethToolItem';
import SvgToothliga from '../../../../../../shared/icons/osstell-light/ToothLiga';
import SvgAllTeethliga from '../../../../../../shared/icons/osstell-light/AllTeethLiga';
import SvgBoneliga from '../../../../../../shared/icons/osstell-light/BoneLiga';
import SvgLocalLossliga from '../../../../../../shared/icons/osstell-light/LocalLossLiga';
import SvgLossAllliga from '../../../../../../shared/icons/osstell-light/LossAllLiga';
import SvgGraftliga from '../../../../../../shared/icons/osstell-light/GraftLiga';
import SvgPeakliga from '../../../../../../shared/icons/osstell-light/PeakLiga';
import SvgSinusGraftliga from '../../../../../../shared/icons/osstell-light/SinusGraftLiga';
import SvgGumsliga from '../../../../../../shared/icons/osstell-light/GumsLiga';
import SvgImplantliga from '../../../../../../shared/icons/osstell-light/ImplantLiga';
import SvgSmartpegliga from '../../../../../../shared/icons/osstell-light/SmartpegLiga';
import SvgAbutmentliga from '../../../../../../shared/icons/osstell-light/AbutmentLiga';
import SvgPrepliga from '../../../../../../shared/icons/osstell-light/PrepLiga';
import SvgOpaqueliga from '../../../../../../shared/icons/osstell-light/OpaqueLiga';
import SvgOverdenture from '../../../../../../shared/icons/custom/Overdenture';
import SvgBridge from '../../../../../../shared/icons/custom/Bridge';
import SvgTextliga from '../../../../../../shared/icons/osstell-light/TextLiga';
import SvgDrawliga from '../../../../../../shared/icons/osstell-light/DrawLiga';
import ResourcesLiga from '../../../../../../shared/icons/osstell-light/ResourcesLiga';
import SvgTransparentliga from '../../../../../../shared/icons/osstell-light/TransparentLiga';
import SvgDrawFreehand from '../../../../../../shared/icons/custom/DrawFreehand';
import SvgDrawLines from '../../../../../../shared/icons/custom/DrawLines';
import TeethToolbarDrawPalette from './TeethToolbarDrawPalette';
import SvgSvgErase from '../../../../../../shared/icons/custom/SvgErase';
import SvgSvgSave from '../../../../../../shared/icons/custom/SvgSave';
import SvgSvgLoad from '../../../../../../shared/icons/custom/SvgLoad';
import SvgSvgClear from '../../../../../../shared/icons/custom/SvgClear';
import { TeethStepDetailDescriptionField } from '../TeethStepDetailDescriptionField';
import { FormattedMessage } from 'react-intl';

interface Props {
  softTissueRef: MutableRefObject<null> | null;
}

const TeethToolbar: React.FC<Props> = ({ softTissueRef }) => {
  const currentTool = useAppSelector((state) => state.consultation.view.currentTool);
  const currentSubTool = useAppSelector((state) => state.consultation.view.currentSubTool);
  const smileMode = useAppSelector((state) => state.consultation.workspace.present.view.smileMode);
  const imageView = useAppSelector((state) => state.consultation.workspace.present.image);
  const procedure = useAppSelector((state) => state.consultation.procedure);
  const toolTip = currentSubTool?.toolTip ?? currentTool?.toolTip;
  const stepDetails =
    procedure.visits[procedure.currentVisit]?.procedureStepStates[procedure.currentStep];
  const dispatch = useAppDispatch();
  const handleOnClick = (index: number) => {
    if (currentTool?.id === index) {
      dispatch(SET_TOOL());
    } else {
      const pickedTool = workspaceToolsState?.find((tool) => tool?.id === index) ?? undefined;
      dispatch(SET_TOOL(pickedTool));
    }
  };

  return !smileMode ? (
    <Toolbar data-testid={'toolbar'}>
      {toolTip && (
        <ToolTip>
          <FormattedMessage {...toolTip} />
        </ToolTip>
      )}
      {stepDetails && currentTool?.name === 'Description' && <TeethStepDetailDescriptionField />}
      {workspaceToolsState
        .filter(
          (tool) => tool?.name !== 'Description' || (tool?.name === 'Description' && stepDetails)
        )
        .map((tool, index) => (
          <div style={{ position: 'relative' }} key={tool.name}>
            {index === currentTool?.id &&
              (currentTool?.name === 'Draw' || currentTool?.name === 'Text') && (
                <TeethToolbarDrawPalette />
              )}
            {index === currentTool?.id && tool.subTools && (
              <SubToolPanel>
                {tool.subTools?.map((subTool) => (
                  <TeethSubToolItem {...subTool} key={subTool.name} />
                ))}
              </SubToolPanel>
            )}
            <TeethToolItem
              selected={index === currentTool?.id}
              onClick={() => handleOnClick(index)}
              disabled={imageView && tool.name !== 'Draw' && tool.name !== 'Text'}
            >
              {ResolveToolIcon(tool.icon)}
              {tool.name === 'SoftTissue' ? (
                <span ref={softTissueRef}>
                  <FormattedMessage {...tool.label} />
                </span>
              ) : (
                <span>
                  <FormattedMessage {...tool.label} />
                </span>
              )}

              {tool?.name === 'Description' && stepDetails?.description && <Notification />}
            </TeethToolItem>
          </div>
        ))}
    </Toolbar>
  ) : null;
};

export const TOOL_ICONS = {
  TEETH_SINGLE: <SvgToothliga />,
  TEETH_ALL: <SvgAllTeethliga />,
  BONE: <SvgBoneliga />,
  BONE_LOSS_LOCAL: <SvgLocalLossliga />,
  BONE_LOSS_ALL: <SvgLossAllliga />,
  BONE_GRAFT: <SvgGraftliga />,
  BONE_PEAK: <SvgPeakliga />,
  BONE_SINUS_GRAFT: <SvgSinusGraftliga />,
  SOFT_TISSUE: <SvgGumsliga />,
  DESCRIPTION: <ResourcesLiga />,
  DELETE_STEP: <ResourcesLiga />,
  IMPLANT: <SvgImplantliga />,
  IMPLANT_TRANSFORM: <SvgImplantliga tilted={true} />,
  STABILITY: <SvgSmartpegliga />,
  ABUTMENT: <SvgAbutmentliga />,
  PREPARATION: <SvgPrepliga />,
  RESTORATION: <SvgTransparentliga />,
  RESTORATION_SINGLE: <SvgOpaqueliga />,
  RESTORATION_BRIDGE: <SvgBridge />,
  RESTORATION_OVERDENTURE: <SvgOverdenture />,
  TEXT: <SvgTextliga />,
  DRAW: <SvgDrawliga />,
  DRAW_FREEHAND: <SvgDrawFreehand />,
  DRAW_LINE: <SvgDrawLines />,
  DRAW_ERASE: <SvgSvgErase />,
  DRAW_CLEAR: <SvgSvgClear />,
  SAVE: <SvgSvgSave />,
  LOAD: <SvgSvgLoad />,
};

export function ResolveToolIcon(icon: ToolIcons) {
  return TOOL_ICONS[icon];
}

const Toolbar = styled(OverlayBoxRounded)`
  height: 72px;
  position: absolute;
  left: 50%;
  bottom: 32px;
  transform: translate(-50%);
  background-color: white;
  padding: 4px;
  user-select: none;

  > * {
    &:not(:last-child) {
      margin-right: 14px;
    }
  }
`;

export const ToolTip = styled.div`
  position: absolute;
  left: 10px;
  bottom: -20px;
`;

const Notification = styled.div`
  position: absolute;
  right: 15px;
  top: 8px;
  width: 10px;
  height: 10px;
  background-color: var(--color-vividpurple);
  border-radius: 100%;
`;

export default TeethToolbar;
