import React from 'react';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  src2x?: string;
  alt?: string;
}

export const ImageResponsive: React.FC<Props> = ({ src, src2x, alt = 'image', ...props }) => {
  const setSrc = (src: string) => src2x && { srcSet: `${src}, ${src2x} 2x` };
  return <img alt={alt} src={src} {...setSrc(src)} {...props} />;
};
