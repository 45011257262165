import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import translationReducer from './translationReducer';
import tileReducer from './tileReducer';
import userReducer from './userReducer';
import clinicReducer from './clinicReducer';
import patientReducer from './patientReducer';
import referenceDataReducer from './referenceDataReducer';
import accountReducer from './accountReducer';
import instrumentReducer from './instrumentReducer';
import firmwareReducer from './firmwareReducer';
import eulaReducer from './eulaReducer';
import integrationReducer from './integrationReducer';
import userGroupReducer from './userGroupReducer';
import studyGroupSlice from '../slices/studyGroupSlice';
import generalReducer from './generalReducer';
import insightReducer from './insightReducer';
import statisticsReducer from './statisticsReducer';
import keyPerformanceIndicatorsReducer from './keyPerformanceIndicatorsReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import logReducer from './logReducer.js';
import promotionReducer from './promotionReducer';
import consultationReducer from './consultationReducer';
import patientImageSlice from '../slices/patientImageSlice';
import * as actionTypes from '../../constants/actionTypes';
import { clinicApi } from '../api/clinic/clinicApi';
import { manageInviteApi } from '../api/manage-invite/manageInviteApi';

const combinedReducer = combineReducers({
  routing: routerReducer,
  referenceData: referenceDataReducer,
  translation: translationReducer,
  tile: tileReducer,
  user: userReducer,
  clinic: clinicReducer,
  patient: patientReducer,
  patientImages: patientImageSlice,
  account: accountReducer,
  instrument: instrumentReducer,
  firmware: firmwareReducer,
  eula: eulaReducer,
  integration: integrationReducer,
  userGroup: userGroupReducer,
  studyGroup: studyGroupSlice,
  general: generalReducer,
  insight: insightReducer,
  keyPerformanceIndicators: keyPerformanceIndicatorsReducer,
  toastr: toastrReducer,
  log: logReducer,
  promotion: promotionReducer,
  consultation: consultationReducer,
  statistics: statisticsReducer,
  [clinicApi.reducerPath]: clinicApi.reducer,
  [manageInviteApi.reducerPath]: manageInviteApi.reducer,
});

//Set initial state to all reducers exept for account, translation and referenceData at logout
const appReducers = (state, action) => {
  if (action.type === actionTypes.CLEAR_TOKEN) {
    state = {
      account: state.account,
      translation: state.translation,
      referenceData: state.referenceData,
    };
  }
  return combinedReducer(state, action);
};

export default appReducers;
