import { ChangeEvent, FC } from 'react';
import { Center } from '../../../../../common/graphics/lib/StyledComponentLibrary';
import { InputItem } from '../../../../Shared/Forms';
import { UPDATE_STEP } from '../../../../../redux/slices/procedureSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';
import styled from 'styled-components';

const ProcedureDetailPanel: FC = () => {
  const procedureStep = useAppSelector((state) => state.consultation.procedure);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { currentVisit, currentStep, visits } = procedureStep;
  const visit = visits[currentVisit];
  const stepDetails = visits[currentVisit]?.procedureStepStates[currentStep];

  return (
    <Panel>
      <StyledProcedureDetailPanel>
        {visit.procedureStepStates.length > 0 ? (
          <>
            <DescriptionWrapper>
              <InputItem
                label={intl.formatMessage({
                  id: 'consultation.procedure.detail.description.label',
                  defaultMessage: 'Description',
                })}
                type="textarea"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  dispatch(
                    UPDATE_STEP({
                      updateData: {
                        description: e.target.value,
                      },
                    })
                  )
                }
                value={stepDetails?.description || ''}
                placeholder={intl.formatMessage({
                  id: 'consultation.procedure.detail.description.placeholder',
                  defaultMessage: 'Describe important information for this step',
                })}
              />
            </DescriptionWrapper>
            <div>
              {stepDetails?.previewImageSrc && (
                <img src={stepDetails?.previewImageSrc} width={500} alt={'step view'} />
              )}
            </div>
          </>
        ) : (
          <FormattedMessage
            id={'consultation.procedure.detail.nosteps'}
            defaultMessage={'No steps created'}
          />
        )}
      </StyledProcedureDetailPanel>
    </Panel>
  );
};

const DescriptionWrapper = styled.div`
  textarea {
    height: 250px;
    resize: none;
  }
`;

const Panel = styled.div`
  width: 100%;
  ${Center};
`;
const StyledProcedureDetailPanel = styled.div`
  display: flex;

  justify-content: space-between;
  padding-top: 1rem;
  background-color: white;

  & > div:first-child {
    width: 400px;
  }
`;
export default ProcedureDetailPanel;
