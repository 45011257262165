import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getInstrumentLogRequest() {
  return {
    type: types.GET_INSTRUMENT_LOG,
  };
}

function getInstrumentLogSuccess(data) {
  return {
    type: types.GET_INSTRUMENT_LOG_SUCCESS,
    logs: data,
  };
}

function getInstrumentLogFailed(error) {
  return {
    type: types.GET_INSTRUMENT_LOG_ERROR,
    error,
  };
}

export function getInstrumentLog(logType, serialNumber) {
  return (dispatch) => {
    dispatch(getInstrumentLogRequest());

    const url = serialNumber ? `admin/logs/${logType}/${serialNumber}` : `admin/logs`;

    ApiFetch.get(url)
      .then((data) => {
        dispatch(getInstrumentLogSuccess(data));
      })
      .catch((error) => {
        dispatch(getInstrumentLogFailed(error));
      });
  };
}

function getAdminLogRequest() {
  return {
    type: types.GET_ADMIN_LOG,
  };
}

function getAdminLogSuccess(data) {
  return {
    type: types.GET_ADMIN_LOG_SUCCESS,
    logs: data,
  };
}

function getAdminLogFailed(error) {
  return {
    type: types.GET_ADMIN_LOG_ERROR,
    error,
  };
}

export function getAdminLog(searchCriteria) {
  return (dispatch) => {
    dispatch(getAdminLogRequest());

    const url = 'admin/logs';

    ApiFetch.post(url, searchCriteria)
      .then((data) => {
        dispatch(getAdminLogSuccess(data));
      })
      .catch((error) => {
        dispatch(getAdminLogFailed(error));
      });
  };
}

export function clearLogs() {
  return (dispatch) => {
    dispatch({ type: types.CLEAR_LOGS });
  };
}
