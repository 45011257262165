import React, { useState } from 'react';
import Modal from '../Shared/Modal/Modal';
import styled from 'styled-components';
import isqScaleImage from '../../shared/images/isqScale.png';
import { FormattedMessage, useIntl } from 'react-intl';

interface Props {
  onDismiss?: () => void;
}

export const ISQModal: React.FC<Props> = ({ onDismiss }) => {
  const intl = useIntl();
  return (
    <Modal
      fullModal={true}
      header={intl.formatMessage({
        id: 'isq.osstellIsqScale',
        defaultMessage: 'The Osstell ISQ Scale',
      })}
      onDismiss={() => onDismiss?.()}
      show={true}
    >
      <InfoWrapper>
        <p>
          <FormattedMessage
            id="isq.InfoText"
            defaultMessage={
              'ISQ, or Implant Stability Quotient, is a scale from 1 to 100 and is a measure of the stability of an implant. The ISQ scale has a non-linear correlation to micro mobility. With more than 1200 scientific references, we now know that high stability means >70 ISQ, between 60-69 is medium stability and < 60 ISQ is considered as low stability.'
            }
          />
        </p>
        <img src={isqScaleImage} alt="ISQ Scale" />
      </InfoWrapper>
    </Modal>
  );
};

export default function IsqInfoModal() {
  const [showModal, setShowModal] = useState<boolean>(false);

  return showModal ? (
    <ISQModal onDismiss={() => setShowModal(false)} />
  ) : (
    <InfoIcon onClick={() => setShowModal(true)}>
      <i>info</i>
      <FormattedMessage id="isq.aboutISQ" defaultMessage={'About ISQ Scale'} />
    </InfoIcon>
  );
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  img {
    max-height: 50vh;
  }

  @media (max-width: 750px) {
    img {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    img {
      display: none;
    }
  }
`;

const InfoIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;
  i {
    font-size: 1.5rem;
    color: var(--color-purple);
    margin-right: 2px;
  }
`;
