import { FC, useState, useEffect, useRef, ChangeEvent } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { useIntl } from 'react-intl';
import { SET_TITLE } from '../../redux/slices/procedureSlice';

interface Props {
  editModeInExportPage: boolean;
}

const EditableExportTitle: FC<Props> = ({ editModeInExportPage }) => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const titleInput = useRef<HTMLInputElement>(null);
  const currentTitle = useAppSelector((state) => state.consultation.procedure.title);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editableTitle, setEditableTitle] = useState<string>(currentTitle ? currentTitle : '');

  useEffect(() => {
    if (currentTitle === undefined || currentTitle === '') {
      setEditableTitle(
        intl.formatMessage({
          id: 'consultationexport.heading',
          defaultMessage: 'YOUR PROPOSED TREATMENT PLAN',
        })
      );
    }
  }, []);

  useEffect(() => {
    if (isEditing) {
      titleInput?.current?.focus();
    }
  }, [isEditing]);

  return (
    <StyledEditableExportTitle isEditing={isEditing} className="titleInputDiv">
      {editModeInExportPage ? (
        <>
          <input
            maxLength={30}
            ref={titleInput}
            spellCheck={false}
            disabled={!isEditing}
            className="titleInput"
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setEditableTitle(event.target.value)
            }
            value={editableTitle}
          />

          <button
            onClick={() => {
              if (isEditing) {
                dispatch(SET_TITLE(editableTitle));
              }
              setIsEditing(!isEditing);
            }}
            className={'button'}
          >
            <i className={'icon'}>{isEditing ? 'done' : 'draw'}</i>
          </button>
        </>
      ) : (
        <h1 className="titleInput">{editableTitle}</h1>
      )}
    </StyledEditableExportTitle>
  );
};

const StyledEditableExportTitle = styled.div<{
  isEditing: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .titleInput {
    border-radius: 8px;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 200;
    margin-top: 10mm;
    margin-bottom: 0mm;
    font-size: 40pt;
    color: var(--color-purple);
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    border: none;
    width: 85%;
    outline: none;
    ${(props) => props.isEditing && 'border: none;'};
    ${(props) => props.isEditing && '-moz-box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);'};
    ${(props) => props.isEditing && '-webkit-box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);'};
    ${(props) => props.isEditing && 'box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.15);'};
    &::placeholder {
      color: $midgrey;
    }
    // allows styling on ipad
    -webkit-appearance: none;
  }

  .button {
    position: absolute;
    right: 15px;
    top: 49px;
    width: 3rem;
    height: 3rem;
    margin: 0 0 0 -3.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edeef7;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    color: var(--color-purple);
    .icon {
      font-size: 1.625rem;
      padding-top: 3px;
    }
    &:focus {
      outline: none;
    }
  }
`;

export default EditableExportTitle;
