import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Button } from '../../Buttons';
import styles from './ConfirmationModal.module.scss';
import Modal from '../../Modal/Modal';

class ConfirmationModal extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;
    this.performAction = this.performAction.bind(this);
  }

  performAction() {
    if (this.props.action) this.props.action();

    if (this.props.hide) this.props.hide();
  }

  render() {
    if (!this.props.header || (!this.props.text && !this.props.children && !this.props.content))
      return null;

    return (
      <Modal
        header={this.props.header}
        headerData={this.props.headerData}
        onDismiss={this.props.hide}
        show={this.props.show}
      >
        {this.props.text ? <p>{this.props.text}</p> : null}
        {this.props.children ? this.props.children : null}
        {this.props.content ? this.props.content : null}
        <div className={styles.buttons}>
          <Button
            big
            full
            value={this.intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
            onClick={this.props.hide}
          />
          <Button
            primary
            big
            full
            value={this.intl.formatMessage({ id: 'general.ok', defaultMessage: 'Ok' })}
            onClick={this.performAction}
          />
        </div>
      </Modal>
    );
  }
}

export default injectIntl(ConfirmationModal);
