import * as types from '../../constants/actionTypes';

const initialState = {
  getFirmwares: {
    complete: false,
    error: undefined,
    busy: false,
    firmwares: [],
  },
  setFirmwareToCurrent: {
    complete: false,
    error: undefined,
    busy: false,
  },
  uploadFirmware: {
    complete: false,
    error: undefined,
    busy: false,
  },
  downloadFirmware: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function firmware(state = initialState, action) {
  switch (action.type) {
    case types.GET_FIRMWARES:
      return Object.assign({}, state, {
        getFirmwares: {
          complete: false,
          error: undefined,
          busy: true,
          firmwares: state.getFirmwares.firmwares,
        },
      });
    case types.GET_FIRMWARES_SUCCESS:
      return Object.assign({}, state, {
        getFirmwares: {
          complete: true,
          error: undefined,
          busy: false,
          firmwares: action.firmwares,
        },
      });
    case types.GET_FIRMWARES_ERROR:
      return Object.assign({}, state, {
        getFirmwares: {
          complete: false,
          error: action.error,
          busy: false,
          firmwares: state.getFirmwares.firmwares,
        },
      });
    case types.REGISTER_FIRMWARE:
      return Object.assign({}, state, {
        uploadFirmware: { complete: false, error: undefined, busy: true },
      });
    case types.REGISTER_FIRMWARE_SUCCESS:
      return Object.assign({}, state, {
        uploadFirmware: { complete: true, error: undefined, busy: false },
      });
    case types.REGISTER_FIRMWARE_ERROR:
      return Object.assign({}, state, {
        uploadFirmware: { complete: false, error: action.error, busy: false },
      });
    case types.SET_FIRMWARE_TO_CURRENT:
      return Object.assign({}, state, {
        setFirmwareToCurrent: { complete: false, error: undefined, busy: true },
      });
    case types.SET_FIRMWARE_TO_CURRENT_SUCCESS:
      return Object.assign({}, state, {
        setFirmwareToCurrent: { complete: true, error: undefined, busy: false },
      });
    case types.SET_FIRMWARE_TO_CURRENT_ERROR:
      return Object.assign({}, state, {
        setFirmwareToCurrent: { complete: false, error: action.error, busy: false },
      });
    case types.DOWNLOAD_FIRMWARE:
      return Object.assign({}, state, {
        downloadFirmware: { complete: false, error: undefined, busy: true },
      });
    case types.DOWNLOAD_FIRMWARE_SUCCESS:
      return Object.assign({}, state, {
        downloadFirmware: { complete: true, error: undefined, busy: false },
      });
    case types.DOWNLOAD_FIRMWARE_ERROR:
      return Object.assign({}, state, {
        downloadFirmware: { complete: false, error: action.error, busy: false },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
