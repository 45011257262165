import React, { Component } from 'react';

import { PanelHeader } from '../../components/Shared/Panel';
import BarChart from '../../components/Charts/BarChart';
import CandleStickChart from '../../components/Charts/CandleStickChart';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

class ChartPanel extends Component {
  // constructor(props) {
  //   super(props);
  //   // this.state = {};
  // }

  shouldComponentUpdate(nextProps) {
    let refresh = false;
    if (nextProps.data.length !== this.props.data.lenth) return true;
    if (nextProps.type === 'candle-stick') {
      // Candle stich chart
      nextProps.data.forEach((item, index) => {
        if (item === undefined || this.props.data[index] === undefined) return true;
        if (item.highPercentile !== this.props.data[index].highPercentile) refresh = true;
        if (item.lowPercentile !== this.props.data[index].lowPercentile) refresh = true;
        if (item.min !== this.props.data[index].min) refresh = true;
        if (item.max !== this.props.data[index].max) refresh = true;
      });
      return refresh;
    }
    // Other chart types
    nextProps.data.forEach((item, index) => {
      if (item === undefined || this.props.data[index] === undefined) return true;
      if (item.value !== this.props.data[index].value) refresh = true;
    });
    return refresh;
  }

  render() {
    let hasData = false;
    this.props.data.forEach((item) => {
      const values = ['value', 'lowPercentile', 'highPercentile', 'min', 'max'];
      values.forEach((val) => {
        if (item[val]) hasData = true;
      });
    });

    return (
      <StyledChartPanel>
        <div className="chartPanel">
          <PanelHeader className={'chartHeader'} header={this.props.title} />
          {!hasData && (
            <div className={'noData'}>
              <FormattedMessage id="general.noDataAvailable" defaultMessage="No data available" />
            </div>
          )}

          {hasData && this.props.type && this.props.type === 'bar' && (
            <BarChart data={this.props.data} yLegend={this.props.yLegend} {...this.props} />
          )}
          {hasData && this.props.type && this.props.type === 'candle-stick' && (
            <CandleStickChart data={this.props.data} yLegend={this.props.yLegend} {...this.props} />
          )}
          <div className={'footer'}>{hasData && this.props.footer}</div>
        </div>
      </StyledChartPanel>
    );
  }
}

const StyledChartPanel = styled.div`
  padding: 0px 10px 15px 10px;
  .footer {
    margin-top: 0.5rem;
    font-weight: 400;
    font-size: 0.75rem;
    min-height: 15px;
  }

  .chartPanel {
    width: 275px;
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    align-self: stretch;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .chartHeader {
    height: 36px;
    h2 {
      font-size: 0.875rem;
    }
  }
  .noData {
    font-weight: 300;
    height: 286px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 750px) {
    padding: 0px 0px 15px 0px;
    .chartPanel {
      width: 600px;
    }
  }

  @media (max-width: 720px) {
    .chartPanel {
      width: 500px;
    }
  }

  @media (max-width: 615px) {
    .chartPanel {
      width: 400px;
    }
  }

  @media (max-width: 515px) {
    .chartPanel {
      width: 300px;
    }
  }

  @media (max-width: 415px) {
    .chartPanel {
      width: 250px;
    }
  }

  @media (max-width: 330px) {
    .chartPanel {
      width: 235px;
    }
  }
`;

export default ChartPanel;
