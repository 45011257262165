import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InputItem, InputRow, InputGroup } from '../../../components/Shared/Forms';
import { Button } from '../../../components/Shared/Buttons';
import { toastr } from 'react-redux-toastr';
import { updateUser } from '../../../redux/actions/userActions';
import { showAlert } from '../../../redux/actions/generalActions';

/**
 * UpdatePasswordInputGroup is responsible for logic regarding the
 * input group for resetting password.
 */

const ResetPasswordForm: React.FC = () => {
  const intl = useIntl();
  const userUpdate = useAppSelector((state) => state.user.userUpdate);
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('');
  const [isUpdatingUser, setIsUpdatingUser] = useState<boolean>(false);
  const toastrOptions = { showCloseButton: false };

  useEffect(() => {
    if (isUpdatingUser && userUpdate?.error) {
      setIsUpdatingUser(false);
      userUpdate.error.messages.forEach((m: { text: string }) =>
        toastr.error(
          intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
          intl.formatMessage({ id: m.text }),
          toastrOptions
        )
      );
    }
    if (isUpdatingUser && userUpdate?.complete) {
      setIsUpdatingUser(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'profile.profileUpdated',
          defaultMessage: 'Profile updated',
        }),
        toastrOptions
      );
      setNewPassword('');
      setOldPassword('');
      setNewPasswordRepeat('');
    }
  }, [userUpdate]);

  const tryToUpdateUser = () => {
    if (user.role === 'Demo') {
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'patientList.demoLimitation',
            defaultMessage: 'DEMO Limitation',
          }),
          intl.formatMessage({
            id: 'general.actionLimitedOnDemoAccount',
            defaultMessage: 'You cannot perform this action on a demo account.',
          })
        )
      );
      setNewPassword('');
      setOldPassword('');
      setNewPasswordRepeat('');
    } else if (newPassword !== newPasswordRepeat) {
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        intl.formatMessage({
          id: 'general.passwordsNotSame',
          defaultMessage: 'Repeated password is not the same as new password',
        }),
        toastrOptions
      );
    } else {
      setIsUpdatingUser(true);
      dispatch(
        updateUser(null, {
          title: user.title,
          profession: user.profession,
          email: user.email,
          firstName: user.firstName,
          surname: user.surname,
          placementsYear: user.placementsYear,
          yearsOfPlacements: user.yearsOfPlacements,
          restorationsYear: user.restorationsYear,
          yearsOfRestorations: user.yearsOfRestorations,
          teethNotation: user.teethNotation,
          logoutTimeInMinutes: user.logoutTimeInMinutes,
          oldPassword: oldPassword,
          password: newPassword,
          passwordRepeat: newPasswordRepeat,
        })
      );
    }
  };

  return (
    <>
      <InputGroup
        label={<FormattedMessage id="profile.changePassword" defaultMessage="Change password" />}
      >
        <p>
          <FormattedMessage
            id="profile.changePasswordInstruction"
            defaultMessage="Enter your old and new password below and click save to confirm your password change"
          />
        </p>
        <InputRow half>
          <InputItem
            dataCy="profile-page-old-password"
            label={<FormattedMessage id="profile.OldPassword" defaultMessage="Old password" />}
            type="password"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setOldPassword(event.target.value)}
            value={oldPassword}
          />
        </InputRow>
        <InputRow>
          <InputItem
            dataCy="profile-page-new-password"
            label={<FormattedMessage id="profile.NewPassword" defaultMessage="New password" />}
            type="password"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setNewPassword(event.target.value)}
            value={newPassword}
          />
          <InputItem
            dataCy="profile-page-new-password-repeat"
            label={
              <FormattedMessage
                id="profile.RepeatNewPassword"
                defaultMessage="Repeat new password"
              />
            }
            type="password"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setNewPasswordRepeat(event.target.value)
            }
            value={newPasswordRepeat}
          />
        </InputRow>
      </InputGroup>
      <Button
        dataCy="profile-page-save-password"
        disabled={oldPassword === '' || newPassword === '' || newPasswordRepeat === ''}
        primary
        value={<FormattedMessage id="general.save" defaultMessage="Save" />}
        rightIcon="done"
        onClick={() => tryToUpdateUser()}
      />
    </>
  );
};

export default ResetPasswordForm;
