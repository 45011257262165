import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import ReduxToastr from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import { Buffer } from 'buffer';
import { appInsights } from '../utils/applicationInsights';

import { getImplantData, getCountries } from '../redux/actions/referenceDataActions';
import { getUser } from '../redux/actions/userActions';
import { getClinic } from '../redux/actions/clinicActions';

import styles from './App.module.scss';

import readModelHub from '../utils/readModelHub';

import {
  LoginPage,
  RegisterAccountPage,
  RetrievePasswordPage,
  ResetPasswordPage,
  RegisterUserPage,
  MainPage,
  ManageInvitePage,
} from '../pages';
import DeleteAccountCompletePage from '../pages/DeleteAccountCompletePage/DeleteAccountCompletePage';
import InternalStatisticsPage from '../pages/InternalStatisticsPage/InternalStatisticsPage';
import UserStatisticsPage from '../pages/InternalStatisticsPage/UserStatisticsPage';
import { confirmAccount, addTokenToBrowser } from '../redux/actions/accountActions';
import { AlertBox } from '../components/Shared/Alerts';
import Spinner from '../components/Shared/Loaders/Spinner';

import messages_en from '../translations/en.json';
import messages_de from '../translations/de.json';
import messages_es from '../translations/es.json';
import messages_fr from '../translations/fr.json';
import messages_it from '../translations/it.json';
import messages_zhs from '../translations/zhs.json';
import messages_ko from '../translations/ko.json';
import messages_pt from '../translations/pt.json';
import messages_ja from '../translations/ja.json';

const messages = {
  en: messages_en,
  de: messages_de,
  es: messages_es,
  fr: messages_fr,
  it: messages_it,
  zh: messages_zhs,
  ko: messages_ko,
  pt: messages_pt,
  ja: messages_ja,
};

function App() {
  global.Buffer = global.Buffer || Buffer;
  const token = useSelector((state) => state.account.token);
  const user = useSelector((state) => state.user.user);
  const locale = useSelector((state) => state.translation.locale);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    dispatch(getUser());
  }, [token, dispatch]);

  useEffect(() => {
    if (!token) return;
    dispatch(getClinic());
  }, [token, dispatch, user]);

  useEffect(() => {
    //dispatch(getAppSettings());
    dispatch(getImplantData());
    dispatch(getCountries());

    if (process.env.REACT_APP_CYPRESS === undefined) {
      //Load Hubspot chat
      let hubspotScript = document.createElement('script');
      hubspotScript.src = '//js.hs-scripts.com/454089.js';
      hubspotScript.async = true;
      document.body.appendChild(hubspotScript);
    }
    //Connect readModelHub
    readModelHub.connect();

    // Disable missing translation message as translations will be added later.
    const consoleError = console.error.bind(console);
    console.error = (message, ...args) => {
      if (message.code === 'MISSING_TRANSLATION') {
        return;
      }
      consoleError(message, ...args);
    };
  }, [dispatch]);

  return (
    <div className={styles.app}>
      <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en" key={locale}>
        <Switch>
          <Route path="/register" component={RegisterAccountPage} />
          <Route path="/retrieve" component={RetrievePasswordPage} />
          <Route path="/reset" component={ResetPasswordPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/confirm" component={ConfirmAccount} />
          <Route path="/integrationpatient" component={IntegrationPatient} />
          <Route path="/accountdeleted" component={DeleteAccountCompletePage} />
          <Route path="/internalstatisticspage" component={InternalStatisticsPage} />
          <Route path="/userstatisticspage" component={UserStatisticsPage} />
          <Route path="/manage-invite" component={ManageInvitePage} />
          <PrivateRoute path="/" component={MainPage} user={user} />
        </Switch>
        <ReduxToastr
          position={'bottom-right'}
          preventDuplicates
          closeOnToastrClick
          timeOut={5000}
        />
      </IntlProvider>
    </div>
  );
}

function PrivateRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return user && user.userCreated && user.currentEulaAccepted ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ConfirmAccount() {
  let query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector((state) => state.user.user);
  const accountConfirmation = useSelector((state) => state.account.accountConfirmation);

  const code = query.get('code');
  const confirmToken = query.get('token');
  const userId = query.get('userId');
  const eulaId = query.get('eulaId');
  const eulaCountry = query.get('eulaCountry');

  useEffect(() => {
    if (code && userId && confirmToken) {
      dispatch(confirmAccount(userId, code, confirmToken));
    }
  }, [userId, code, confirmToken, dispatch]);

  // If user already exist or just registered and reloaded, forward to home
  useEffect(() => {
    if (user.userCreated) {
      history.replace('/home');
    }
  });

  if (accountConfirmation.error)
    return (
      <div className={styles.confirmContent}>
        <div>
          <AlertBox
            headerText={intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' })}
            message={intl.formatMessage({
              id: 'confirmEmail.error',
              defaultMessage:
                'The confirmation link is not valid or has expired. Please check that you followed the link in the last confirmation email.',
            })}
          />
        </div>
        <div>
          <FormattedMessage
            id="confirmEmail.goToRegister"
            defaultMessage="To send a new confirmation email, please follow the link below to create a new account."
          />
        </div>
        <div>
          <Link to="/register">
            {intl.formatMessage({
              id: 'login.createAccount',
              defaultMessage: 'Create account',
            })}
          </Link>
        </div>
      </div>
    );

  if (accountConfirmation.busy)
    return (
      <div className={styles.confirmContent}>
        <Spinner purple />
      </div>
    );

  return (
    accountConfirmation.complete && (
      <RegisterUserPage eulaId={eulaId} eulaCountry={eulaCountry} history={history} />
    )
  );
}

function IntegrationPatient() {
  let query = useQuery();
  const history = useHistory();
  const dispatch = useDispatch();
  const savedToken = useSelector((state) => state.account.token);
  const user = useSelector((state) => state.user.user);
  const token = query.get('token');
  const userGroupId = query.get('userGroupId');
  const patientId = query.get('patientId');
  const doesExist = query.get('doesExist');
  const errorMessage = query.get('errorMessage');

  useEffect(() => {
    if (errorMessage) {
      history.push(`/login?error=${errorMessage}`);
    }
  }, [errorMessage, history]);
  
  useEffect(() => {
    if (token) {
      dispatch(addTokenToBrowser(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (savedToken === token) {
      appInsights.trackEvent({
        name: 'OpenIntegrationPatient',
        properties: {
          userGroupId: userGroupId,
          email: user.email,
        },
      });
      history.push(`/usergroup/${userGroupId}/patients/${patientId}`);
      // Two url pushed because patientInformation page will go back in history when saving
      if (doesExist === 'False') {
        history.push(`/usergroup/${userGroupId}/patients/${patientId}/patientInformation`);
      }
    }
  }, [savedToken]);

  return (
    <div>
      <h2>Loading patient...</h2>
    </div>
  );
}

export default App;
