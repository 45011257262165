import React, { SVGProps } from 'react';

interface Props extends SVGProps<SVGImageElement> {
  src: string;
  width: string | number;
  height: string | number;
}

export const ImageSvg: React.FC<Props> = ({ src, width, height, ...props }) => {
  return <image role="img" href={src} width={width} height={height} {...props} />;
};
