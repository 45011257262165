import * as types from '../../constants/actionTypes';

export function showVideo(videoUrl, header) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_VIDEO,
      videoUrl,
      header,
    });
  };
}

export function hideVideo() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_VIDEO,
    });
  };
}

export function showFreeSmartPegs(promotion) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_FREE_SMART_PEGS,
      promotion,
    });
  };
}

export function hideFreeSmartPegs() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_FREE_SMART_PEGS,
    });
  };
}

export function showAlert(header, content, action) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_ALERT,
      header,
      content,
      action,
    });
  };
}

export function hideAlert() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_ALERT,
    });
  };
}

export function showConfirmation(header, content, action) {
  return (dispatch) => {
    dispatch({
      type: types.SHOW_CONFIRMATION,
      header,
      content,
      action,
    });
  };
}

export function hideConfirmation() {
  return (dispatch) => {
    dispatch({
      type: types.HIDE_CONFIRMATION,
    });
  };
}
