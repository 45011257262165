export type MessagePost = DownloadaPost | StartScanDirPost;

type DownloadaPost = {
  type: 'download';
  payload: {
    base64: string;
    fileName: string;
  };
};

type StartScanDirPost = {
  type: 'startscan';
  scanText: string;
};

export const DownloadBlob = async (blob: Blob, fileName: string): Promise<void> => {
  if (!blob) return;

  if (window.ReactNativeWebView) {
    const base64 = await blobToBase64(blob);
    const messagePost: MessagePost = {
      type: 'download',
      payload: {
        fileName,
        base64,
      },
    };

    window.ReactNativeWebView.postMessage(JSON.stringify(messagePost));
  } else {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE11
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    }
  }
};

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.readAsDataURL(blob);
  });
}
