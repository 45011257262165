import React from 'react';
import { Section, SectionInner } from '../../components/Shared/Section';
import { H1 } from '../../components/Shared/Headings';
import { injectIntl, FormattedMessage } from 'react-intl';
import Header from '../../components/header/Header';

const NotFoundPage = () => {
  this.props.history.push('/home');
  return (
    <div>
      <Header isTogglerHidden />
      <Section>
        <SectionInner>
          <H1>
            <FormattedMessage id="pageNotFound.pageNotFound" defaultMessage="Page not found" />
          </H1>
        </SectionInner>
      </Section>
    </div>
  );
};
export default injectIntl(NotFoundPage);
