import { HttpMethods } from '../../../constants/enums';
import { ICustomError } from '../../../types/customError';
import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAcceptInviteRequest, IAcceptInviteResponse, IJoinClinicRequest } from './types';

export const manageInviteApi = createApi({
  reducerPath: 'manageInviteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
  }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, Record<string, unknown>>,
  endpoints: (builder) => ({
    acceptInvite: builder.mutation<IAcceptInviteResponse, IAcceptInviteRequest>({
      query: (body) => ({
        url: 'clinic/acceptinvite',
        method: HttpMethods.POST,
        body,
      }),
    }),
    createProfile: builder.mutation<{ data: string }, IJoinClinicRequest>({
      query: (body) => ({
        url: 'users/joinclinic',
        method: HttpMethods.POST,
        body,
      }),
    }),
  }),
});

export const { useAcceptInviteMutation, useCreateProfileMutation } = manageInviteApi;
