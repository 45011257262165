import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { FC, FormEvent } from 'react';
import { IClinicMember } from '../../types/clinic';
import { FormikInputField } from '../formik/formik-input-field/FormikInputField';
import {
  useDeleteClinicInviteMutation,
  useDeleteClinicUserMutation,
  useSendClinicInviteMutation,
} from '../../redux/api/clinic/clinicApi';
import UserPermissionSelect from '../formik/user-permission-select/UserPermissionSelect';
import Spinner from '../Shared/Loaders/Spinner';
import { useFormik } from 'formik';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';

interface IFormValues {
  email: string;
  permission: string;
}

interface Props {
  user?: IClinicMember;
  isOpen: boolean;
  userGroupId: string;
  onDialogCloseClick: () => void;
}

const EditUserForm: FC<Props> = ({ user, isOpen, userGroupId, onDialogCloseClick }) => {
  const intl = useIntl();
  const [inviteMember, { isLoading: isInviting }] = useSendClinicInviteMutation();
  const [deleteUser, { isLoading: isUserDeleting }] = useDeleteClinicUserMutation();
  const [deleteInvite, { isLoading: isInviteDeleting }] = useDeleteClinicInviteMutation();

  const validate = (values: IFormValues) => {
    const errors: Record<string, string> = {};
    if (!values.email) {
      errors.email = 'Please enter email';
    }
    if (!values.permission) {
      errors.permission = 'Please select user permission';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: user ? user.email : '',
      permission: user ? user.permission : '',
    },
    validate,
    enableReinitialize: false,
    validateOnMount: true,
    onSubmit: async () => onDialogCloseClick(),
  });

  const resendLinkHandler = async () => {
    if (!user) return;
    const res = await inviteMember({
      email: user.email,
      permission: user.permission,
      userGroupId,
    });
    if ('error' in res) return;
    onDialogCloseClick();
    toastr.success(
      intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
      intl.formatMessage({
        id: 'implant.inviteSuccess',
        defaultMessage: 'The invitation was sent successfully',
      }),
      { showCloseButton: false }
    );
  };

  const deleteUserHandler = async () => {
    await deleteUser({ userGroupId, userId: user?.id });
    onDialogCloseClick();
  };

  const deleteInviteHandler = async () => {
    const res = await deleteInvite({ userGroupId: userGroupId, email: user ? user.email : '' });
    if ('error' in res && 'data' in res.error) {
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        intl.formatMessage({
          id: 'general.requestProblem',
          defaultMessage: 'There was a problem with your request.',
        }),
        { showCloseButton: false }
      );
      return;
    }
    toastr.success(
      intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
      intl.formatMessage({
        id: 'users.deleteInviteSuccess',
        defaultMessage: 'The invitation was deleted successfully',
      }),
      { showCloseButton: false }
    );

    onDialogCloseClick();
  };

  const isLoading = isInviteDeleting || isUserDeleting || isInviting;

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogCloseClick}
      maxWidth="xs"
      PaperProps={{
        component: 'form',
        onSubmit: (event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          formik.handleSubmit();
        },
      }}
    >
      <DialogTitle>
        {user?.id
          ? intl.formatMessage({ id: 'users.editUser', defaultMessage: 'Edit user' })
          : intl.formatMessage({
              id: 'users.editInvite',
              defaultMessage: 'Edit invite',
            })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {user?.id
            ? intl.formatMessage({
                id: 'users.editUserFormInfo',
                defaultMessage: 'Edit the selected users permission. Click save when you’re done. ',
              })
            : intl.formatMessage({
                id: 'users.editInviteFormInfo',
                defaultMessage: 'Edit the selected invite. Click save when you’re done. ',
              })}
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormikInputField
              id={'email'}
              type={'email'}
              disabled
              label={intl.formatMessage({
                id: 'general.email',
                defaultMessage: 'Email',
              })}
              helperText={formik.touched.email && formik.errors.email}
              error={formik.touched.email && Boolean(formik.errors.email)}
              formik={formik}
            />
          </Grid>
          <Grid item xs={12}>
            <UserPermissionSelect disabled formik={formik} />
          </Grid>
        </Grid>
        {!user?.id && (
          <Grid item xs={12} mt={1}>
            <Button variant="text" disabled={isLoading} onClick={resendLinkHandler}>
              {intl.formatMessage({
                id: 'users.resendInvitationLink',
                defaultMessage: 'Resend invitation link',
              })}
            </Button>
          </Grid>
        )}
        <Grid item xs={12} mt={1}>
          <Button
            color="error"
            variant="text"
            disabled={isLoading}
            onClick={user?.id ? deleteUserHandler : deleteInviteHandler}
          >
            {isUserDeleting ? (
              <Spinner destructive />
            ) : user?.id ? (
              intl.formatMessage({ id: 'users.deleteUser', defaultMessage: 'Delete user' })
            ) : (
              intl.formatMessage({ id: 'users.deleteInvite', defaultMessage: 'Delete invite' })
            )}
          </Button>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onDialogCloseClick} disabled={isLoading}>
          {intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
        </Button>
        <Button type="submit" disabled={isLoading}>
          {isLoading ? (
            <Spinner />
          ) : (
            intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUserForm;
