class Storage {
  setItem(key, item) {
    try {
      const itemWithTime = {
        time: new Date(),
        item: item,
      };

      localStorage.setItem(key, itemWithTime);
    } catch (error) {
      localStorage.removeItem(key);
    }
  }

  storeError(error) {
    try {
      let errors = this.getItem('errors');
      if (!errors) {
        errors = [];
      } else {
        errors = JSON.parse(errors);
      }

      errors.push({ time: new Date(), error: error });
      localStorage.setItem('errors', JSON.stringify(errors));
    } catch (error) {
      localStorage.removeItem('errors');
    }
  }

  storeApiCall(url, method) {
    try {
      let apiCalls = this.getItem('apiCalls');
      if (!apiCalls) {
        apiCalls = [];
      } else {
        apiCalls = JSON.parse(apiCalls);
      }

      apiCalls.push({ time: new Date(), url: url, method: method });
      localStorage.setItem('apiCalls', JSON.stringify(apiCalls));
    } catch (error) {
      localStorage.removeItem('apiCalls');
    }
  }

  getErrors() {
    return localStorage.getItem('errors');
  }

  getApiCalls() {
    return localStorage.getItem('apiCalls');
  }

  getItem(key) {
    return localStorage.getItem(key);
  }

  clearErrors() {
    localStorage.removeItem('errors');
  }

  clearApiCalls() {
    localStorage.removeItem('apiCalls');
  }
}

export default new Storage();
