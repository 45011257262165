import {
  bezier,
  SvgPathPartial,
  transformXYToPoint,
} from '../../../../../../common/graphics/lib/SvgPath';
import React, { useMemo } from 'react';

interface Props {
  enabled: boolean;
  positions: any;
  onClick: () => void;
}

const SvgTeethNerveLine: React.FC<Props> = ({ positions, onClick, enabled = false }) => {
  const allNodes = useMemo(() => [...positions.map(transformXYToPoint)], [positions]);

  const path = useMemo(
    () => (
      <path
        d={`${SvgPathPartial({
          points: allNodes,
          command: bezier(0.1),
          moveToStart: true,
        })}`}
        fill={'none'}
        stroke={'var(--color-purple)'}
        strokeLinecap={'round'}
        strokeWidth={6}
        transform="translate(16, 15)"
        onClick={() => onClick?.()}
        style={{ pointerEvents: enabled ? 'all' : 'none' }}
      />
    ),
    [allNodes, onClick]
  );

  return (
    allNodes && (
      <svg width={400} height={200}>
        <g opacity={0.5}>{path}</g>
      </svg>
    )
  );
};
export default SvgTeethNerveLine;
