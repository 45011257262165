import * as types from '../../constants/actionTypes';

const initialState = {
  patients: [],
  getPatients: {
    busy: false,
  },
  currentPatient: {},
  currentDentalChart: {
    loaded: false,
  },
  currentImplants: [],
  selectedPatientImplant: undefined,
  receivedMeasurement: undefined,
  usedImplants: [],
  usedAbutments: [],
  searchPatients: {
    complete: false,
    error: undefined,
    busy: false,
    patients: [],
  },
  createPatient: {
    complete: false,
    error: undefined,
    busy: false,
    newPatient: undefined,
  },
  getPatient: {
    complete: false,
    error: undefined,
    busy: false,
  },
  updatePatient: {
    complete: false,
    error: undefined,
    busy: false,
  },
  deletePatient: {
    complete: false,
    error: undefined,
    busy: false,
  },
  createMeasurement: {
    complete: false,
    error: undefined,
    busy: false,
  },
  deleteMeasurement: {
    complete: false,
    error: undefined,
    busy: false,
  },
  createImplant: {
    complete: false,
    error: undefined,
    busy: false,
  },
  deleteImplant: {
    complete: false,
    error: undefined,
    busy: false,
  },
  setImplantFailed: {
    complete: false,
    error: undefined,
    busy: false,
  },
  setImplantData: {
    complete: false,
    error: undefined,
    busy: false,
  },
  exportPatient: {
    complete: false,
    error: undefined,
    busy: false,
  },
  restoreDemoPatients: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function patient(state = initialState, action) {
  let currentDentalChart;
  switch (action.type) {
    case types.GET_PATIENTS:
      return Object.assign({}, state, {
        getPatients: {
          busy: true,
        },
      });
    case types.GET_PATIENTS_SUCCESS:
      return Object.assign({}, state, {
        patients: action.patients,
        getPatients: {
          busy: false,
        },
      });
    case types.GET_PATIENTS_ERROR:
      return Object.assign({}, state, {
        getPatients: {
          busy: false,
        },
      });
    case types.GET_PATIENT:
      return Object.assign({}, state, {
        getPatient: {
          complete: false,
          error: undefined,
          busy: true,
        },
        currentPatient: {},
      });
    case types.GET_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        getPatient: {
          complete: true,
          error: undefined,
          busy: false,
        },
        currentPatient: action.patient,
      });
    case types.GET_PATIENT_ERROR:
      return Object.assign({}, state, {
        getPatient: {
          complete: false,
          error: action.error,
          busy: false,
        },
        currentPatient: {},
      });
    case types.GET_PATIENT_DENTAL_CHART:
      return Object.assign({}, state, {
        currentDentalChart: {
          loaded: false,
        },
      });
    case types.GET_PATIENT_DENTAL_CHART_SUCCESS:
      currentDentalChart = Object.assign({}, action.dentalChart);
      currentDentalChart.loaded = true;
      return Object.assign({}, state, {
        currentDentalChart: currentDentalChart,
      });
    case types.GET_PATIENT_DENTAL_CHART_ERROR:
      return Object.assign({}, state, {
        currentDentalChart: {
          loaded: true,
        },
      });
    case types.GET_PATIENT_IMPLANTS:
      return Object.assign({}, state, {
        //currentImplants: [],
      });
    case types.GET_PATIENT_IMPLANTS_SUCCESS:
      return Object.assign({}, state, {
        currentImplants: action.implants,
      });
    case types.GET_PATIENT_IMPLANTS_ERROR:
      return Object.assign({}, state, {
        currentImplants: [],
      });
    case types.SELECT_PATIENT_IMPLANT:
      return Object.assign({}, state, {
        selectedPatientImplant: action.selectedPatientImplant,
        receivedMeasurement: undefined,
      });
    case types.RECEIVE_MEASUREMENT:
      return Object.assign({}, state, {
        receivedMeasurement: action.receivedMeasurement,
      });
    case types.SEARCH_PATIENTS:
      return Object.assign({}, state, {
        searchPatients: {
          complete: false,
          error: undefined,
          busy: true,
          patients: [],
        },
      });
    case types.SEARCH_PATIENTS_SUCCESS:
      return Object.assign({}, state, {
        searchPatients: {
          complete: true,
          error: undefined,
          busy: false,
          patients: action.patients,
        },
      });
    case types.SEARCH_PATIENTS_ERROR:
      return Object.assign({}, state, {
        searchPatients: {
          complete: false,
          error: action.error,
          busy: false,
          patients: [],
        },
      });
    case types.CREATE_PATIENT:
      return Object.assign({}, state, {
        createPatient: {
          complete: false,
          error: undefined,
          busy: true,
          newPatient: undefined,
        },
      });
    case types.CREATE_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        createPatient: {
          complete: true,
          error: undefined,
          busy: false,
          newPatient: action.newPatient,
        },
      });
    case types.CREATE_PATIENT_ERROR:
      return Object.assign({}, state, {
        createPatient: {
          complete: false,
          error: action.error,
          busy: false,
          newPatient: undefined,
        },
      });
    case types.UPDATE_PATIENT:
      return Object.assign({}, state, {
        updatePatient: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.UPDATE_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        updatePatient: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.UPDATE_PATIENT_ERROR:
      return Object.assign({}, state, {
        updatePatient: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.DELETE_PATIENT:
      return Object.assign({}, state, {
        deletePatient: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.DELETE_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        deletePatient: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.DELETE_PATIENT_ERROR:
      return Object.assign({}, state, {
        deletePatient: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.CREATE_MEASUREMENT:
      return Object.assign({}, state, {
        createMeasurement: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.CREATE_MEASUREMENT_SUCCESS:
      return Object.assign({}, state, {
        createMeasurement: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.CREATE_MEASUREMENT_ERROR:
      return Object.assign({}, state, {
        createMeasurement: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.DELETE_MEASUREMENT:
      return Object.assign({}, state, {
        deleteMeasurement: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.DELETE_MEASUREMENT_SUCCESS:
      return Object.assign({}, state, {
        deleteMeasurement: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.DELETE_MEASUREMENT_ERROR:
      return Object.assign({}, state, {
        deleteMeasurement: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.CREATE_IMPLANT:
      return Object.assign({}, state, {
        createImplant: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.CREATE_IMPLANT_SUCCESS:
      return Object.assign({}, state, {
        createImplant: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.CREATE_IMPLANT_ERROR:
      return Object.assign({}, state, {
        createImplant: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.DELETE_IMPLANT:
      return Object.assign({}, state, {
        deleteImplant: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.DELETE_IMPLANT_SUCCESS:
      return Object.assign({}, state, {
        deleteImplant: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.DELETE_IMPLANT_ERROR:
      return Object.assign({}, state, {
        deleteImplant: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.SET_IMPLANT_DATA:
      return Object.assign({}, state, {
        setImplantData: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.SET_IMPLANT_DATA_SUCCESS:
      return Object.assign({}, state, {
        setImplantData: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.SET_IMPLANT_DATA_ERROR:
      return Object.assign({}, state, {
        setImplantData: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.SET_IMPLANT_FAILED:
      return Object.assign({}, state, {
        setImplantFailed: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.SET_IMPLANT_FAILED_SUCCESS:
      return Object.assign({}, state, {
        setImplantFailed: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.SET_IMPLANT_FAILED_ERROR:
      return Object.assign({}, state, {
        setImplantFailed: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.GET_USED_IMPLANTS:
      return Object.assign({}, state, {
        usedImplants: [],
        usedAbutments: [],
      });
    case types.GET_USED_IMPLANTS_SUCCESS:
      return Object.assign({}, state, {
        usedImplants: action.implants,
        usedAbutments: action.abutments,
      });
    case types.GET_USED_IMPLANTS_ERROR:
      return Object.assign({}, state, {
        usedImplants: [],
        usedAbutments: [],
      });
    case types.EXPORT_PATIENT:
      return Object.assign({}, state, {
        exportPatient: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.EXPORT_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        exportPatient: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.EXPORT_PATIENT_ERROR:
      return Object.assign({}, state, {
        exportPatient: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.RESTORE_DEMO_PATIENTS:
      return Object.assign({}, state, {
        restoreDemoPatients: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.RESTORE_DEMO_PATIENTS_SUCCESS:
      return Object.assign({}, state, {
        restoreDemoPatients: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.RESTORE_DEMO_PATIENTS_ERROR:
      return Object.assign({}, state, {
        restoreDemoPatients: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
