import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { Tabs, TabPanel } from '../../components/Shared/Tabs';
import { Button } from '../../components/Shared/Buttons';
import { Section, SectionInner, Row } from '../../components/Shared/Section';
import { InputItem, InputRow, InputGroup } from '../../components/Shared/Forms';
import UpdatePasswordInputGroup from './Forms/UpdatePasswordInputGroup';
import Dropdown from '../../components/Shared/Dropdown/Dropdown';
import DeleteAccountModal from './DeleteAccountModal';
import styles from './ProfilePage.module.scss';
import Header from '../../components/header/Header';
import styled from 'styled-components';
import * as userActions from '../../redux/actions/userActions';
import { showAlert } from '../../redux/actions/generalActions';
import { toastrOptions } from '../../constants/toastrOptions';

class ProfilePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        title: props.user.title,
        profession: props.user.profession,
        email: props.user.email,
        firstName: props.user.firstName,
        surname: props.user.surname,
        oldPassword: '',
        password: '',
        passwordRepeat: '',
        placementsYear: props.user.placementsYear,
        yearsOfPlacements: props.user.yearsOfPlacements,
        restorationsYear: props.user.restorationsYear,
        yearsOfRestorations: props.user.yearsOfRestorations,
        teethNotation: props.user.teethNotation,
        logoutTimeInMinutes: props.user.logoutTimeInMinutes,
        sourceUserGroup: {
          id: props.user.sourceUserGroup.id,
          userPermission: props.user.sourceUserGroup.userPermission,
        },
      },
      showDeleteAccountModal: false,
    };
    this.intl = props.intl;

    this.titleChanged = this.titleChanged.bind(this);
    this.professionChanged = this.professionChanged.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.firstNameChanged = this.firstNameChanged.bind(this);
    this.surnameChanged = this.surnameChanged.bind(this);
    this.placementsYearChanged = this.placementsYearChanged.bind(this);
    this.yearsOfPlacementsChanged = this.yearsOfPlacementsChanged.bind(this);
    this.restorationsYearChanged = this.restorationsYearChanged.bind(this);
    this.yearsOfRestorationsChanged = this.yearsOfRestorationsChanged.bind(this);
    this.teethNotationChanged = this.teethNotationChanged.bind(this);
    this.oldPasswordChanged = this.oldPasswordChanged.bind(this);
    this.passwordChanged = this.passwordChanged.bind(this);
    this.passwordRepeatChanged = this.passwordRepeatChanged.bind(this);
    this.updateUser = this.updateUser.bind(this);

    this.updateOther = props.match.params.userId ? true : false;

    this.teethNotationOptions = [{ value: 'UNS' }, { value: 'FDI' }];

    this.logoutTimeOptions = [
      {
        key: 30,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.30minutes',
          defaultMessage: '30 minutes',
        }),
        action: () => {
          this.setNewLogoutTime(30);
        },
      },
      {
        key: 60,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.1hour',
          defaultMessage: '1 hour',
        }),
        action: () => {
          this.setNewLogoutTime(60);
        },
      },
      {
        key: 180,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.3hours',
          defaultMessage: '3 hours',
        }),
        action: () => {
          this.setNewLogoutTime(180);
        },
      },
      {
        key: 360,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.6hours',
          defaultMessage: '6 hours',
        }),
        action: () => {
          this.setNewLogoutTime(360);
        },
      },
      {
        key: 720,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.12hours',
          defaultMessage: '12 hours',
        }),
        action: () => {
          this.setNewLogoutTime(720);
        },
      },
      {
        key: 1440,
        label: this.intl.formatMessage({
          id: 'profile.automaticSignOut.24hours',
          defaultMessage: '24 hours',
        }),
        action: () => {
          this.setNewLogoutTime(1440);
        },
      },
    ];
  }

  componentDidMount() {
    if (
      this.props.match.params.userId &&
      (this.props.user.role === 'SuperAdmin' ||
        this.props.user.role === 'AdminLv1' ||
        this.props.user.role === 'AdminLv2')
    ) {
      this.props.actions.getUser(this.props.match.params.userId);
    } else {
      this.props.actions.subscribeToUserChange(this.props.user.id);
    }
  }

  componentWillUnmount() {
    if (!this.props.match.params.userId)
      this.props.actions.unsubscribeFromUserChange(this.props.user.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      (nextProps.user && nextProps.user.id) ||
      (nextProps.match.params.userId && nextProps.actAsUser.loaded)
    ) {
      const user = nextProps.match.params.userId ? nextProps.actAsUser : nextProps.user;
      this.setState({
        user: {
          title: user.title,
          profession: user.profession,
          email: user.email,
          firstName: user.firstName,
          surname: user.surname,
          placementsYear: user.placementsYear,
          yearsOfPlacements: user.yearsOfPlacements,
          restorationsYear: user.restorationsYear,
          yearsOfRestorations: user.yearsOfRestorations,
          teethNotation: user.teethNotation,
          logoutTimeInMinutes: user.logoutTimeInMinutes,
          sourceUserGroup: {
            id: user.sourceUserGroup.id,
            userPermission: user.sourceUserGroup.userPermission,
          },
        },
      });
    }
    if (nextProps.userUpdate.complete && this.updatingUser) {
      this.updatingUser = false;
      toastr.success(
        this.intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        this.intl.formatMessage({
          id: 'profile.profileUpdated',
          defaultMessage: 'Profile updated',
        }),
        toastrOptions
      );
    }

    if (nextProps.userUpdate.error && this.updatingUser) {
      this.updatingUser = false;
      nextProps.userUpdate.error.messages.forEach((m) =>
        toastr.error(
          this.intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
          this.intl.formatMessage({ id: m.text }),
          toastrOptions
        )
      );
    }
  }

  emailChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        email: evt.target.value,
      },
    });
  }

  firstNameChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        firstName: evt.target.value,
      },
    });
  }

  surnameChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        surname: evt.target.value,
      },
    });
  }

  oldPasswordChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        oldPassword: evt.target.value,
      },
    });
  }

  passwordChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        password: evt.target.value,
      },
    });
  }

  passwordRepeatChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        passwordRepeat: evt.target.value,
      },
    });
  }

  titleChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        title: evt.target.value,
      },
    });
  }

  professionChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        profession: evt.target.value,
      },
    });
  }

  placementsYearChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        placementsYear: evt.target.value,
      },
    });
  }

  yearsOfPlacementsChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        yearsOfPlacements: evt.target.value,
      },
    });
  }

  restorationsYearChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        restorationsYear: evt.target.value,
      },
    });
  }

  yearsOfRestorationsChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        yearsOfRestorations: evt.target.value,
      },
    });
  }

  teethNotationChanged(evt) {
    this.setState({
      user: {
        ...this.state.user,
        teethNotation: evt.target.value,
      },
    });
  }

  toogleShowDeleteAccountModal() {
    if (this.props.user.role === 'Demo') {
      this.showDemoLimitationModal();
    } else {
      this.setState({
        showDeleteAccountModal: !this.state.showDeleteAccountModal,
      });
    }
  }

  showDemoLimitationModal() {
    this.props.actions.showAlert(
      this.intl.formatMessage({
        id: 'patientList.demoLimitation',
        defaultMessage: 'DEMO Limitation',
      }),
      this.intl.formatMessage({
        id: 'general.actionLimitedOnDemoAccount',
        defaultMessage: 'You cannot perform this action on a demo account.',
      })
    );
  }

  setNewLogoutTime(newTime) {
    this.setState({
      user: {
        ...this.state.user,
        logoutTimeInMinutes: newTime,
      },
    });
  }

  updateUser() {
    this.updatingUser = true;
    const userId = this.props.match.params.userId ? this.props.match.params.userId : null;
    this.props.actions.updateUser(userId, this.state.user);
  }

  missingField() {
    return (
      !this.state.user.email ||
      !this.state.user.firstName ||
      !this.state.user.surname ||
      !this.state.user.placementsYear ||
      !this.state.user.yearsOfPlacements ||
      !this.state.user.restorationsYear ||
      !this.state.user.yearsOfRestorations
    );
  }

  render() {
    //const error = this.props.userUpdate.error;
    const headerButtonsLeft = [
      {
        label: this.intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
        icon: 'back',
        action: this.props.history.goBack,
      },
    ];

    var chosenLogoutTimeIsCurrentLogoutTime =
      this.state.user.logoutTimeInMinutes === this.props.user.logoutTimeInMinutes;

    return (
      <StyledProfilePage>
        <DeleteAccountModal
          show={this.state.showDeleteAccountModal}
          toogleShowDeleteAccountModal={() => this.toogleShowDeleteAccountModal()}
        />

        <Header
          title={this.intl.formatMessage({ id: 'profile.Header', defaultMessage: 'PROFILE' })}
          leftButtons={headerButtonsLeft}
          isTogglerHidden
        />
        <Section>
          <SectionInner>
            <Tabs
              tabs={[
                this.intl.formatMessage({
                  id: 'profile.userInformation',
                  defaultMessage: 'User information',
                }),
                this.intl.formatMessage({
                  id: 'profile.account',
                  defaultMessage: 'Account',
                }),
              ]}
            >
              <TabPanel>
                <InputGroup
                  label={this.intl.formatMessage({
                    id: 'profile.userInformation',
                    defaultMessage: 'User information',
                  })}
                >
                  <InputRow>
                    <InputRow>
                      <InputItem
                        label={this.intl.formatMessage({
                          id: 'general.title',
                          defaultMessage: 'Title',
                        })}
                        type="select"
                        options={this.props.titles.map((x) => ({
                          key: x.key,
                          value: this.intl.formatMessage({ ...x.label }),
                        }))}
                        onChange={this.titleChanged}
                        value={this.state.user.title}
                        shrink
                      />
                      <InputItem
                        label={
                          '*' +
                          this.intl.formatMessage({
                            id: 'general.firstName',
                            defaultMessage: 'First Name',
                          })
                        }
                        type="text"
                        onChange={this.firstNameChanged}
                        value={this.state.user.firstName}
                      />
                    </InputRow>

                    <InputItem
                      dataCy="profile-page-last-name"
                      label={
                        '*' +
                        this.intl.formatMessage({
                          id: 'general.lastName',
                          defaultMessage: 'Last Name',
                        })
                      }
                      type="text"
                      onChange={this.surnameChanged}
                      value={this.state.user.surname}
                    />
                  </InputRow>
                  <InputRow>
                    <InputItem
                      label={this.intl.formatMessage({
                        id: 'general.profession',
                        defaultMessage: 'Profession',
                      })}
                      type="select"
                      options={this.props.professions.map((x) => ({
                        key: x.key,
                        value: this.intl.formatMessage({ ...x.label }),
                      }))}
                      onChange={this.professionChanged}
                      value={this.state.user.profession}
                    />
                    <InputItem
                      label={
                        '*' +
                        this.intl.formatMessage({
                          id: 'general.Email',
                          defaultMessage: 'Email',
                        })
                      }
                      type="text"
                      onChange={this.emailChanged}
                      value={this.state.user.email}
                    />
                    {/*
                <InputItem
                  label={this.intl.formatMessage({
                    id: 'profile.PostGraduateQualification',
                    defaultMessage: 'Post Graduate Qualification',
                  })}
                  type="select"
                  options={this.props.professions}
                  onChange={this.professionChanged}
                  value={this.state.user.profession}
                />
                */}
                  </InputRow>
                </InputGroup>
                <InputGroup
                  label={this.intl.formatMessage({
                    id: 'profile.experienceOfPlacements',
                    defaultMessage: 'Experience of implant placements',
                  })}
                >
                  <p className={styles.label}>
                    {`* ${this.intl.formatMessage({
                      id: 'profile.placementsYear',
                      defaultMessage: 'Implant placements/Year',
                    })}`}
                  </p>
                  <InputRow className={styles.selectOptions}>
                    <InputItem
                      noMarginTop
                      type="selectbutton"
                      onChange={this.placementsYearChanged}
                      options={this.props.implantsYearOptions}
                      value={this.state.user.placementsYear}
                      span
                      centered
                    />
                  </InputRow>
                  <p className={styles.label}>
                    {`* ${this.intl.formatMessage({
                      id: 'profile.yearsOfPlacements',
                      defaultMessage: 'Experience working with implant placements (years)',
                    })}`}
                  </p>
                  <InputRow className={styles.selectOptions}>
                    <InputItem
                      noMarginTop
                      type="selectbutton"
                      onChange={this.yearsOfPlacementsChanged}
                      options={this.props.yearsOfExperienceOptions}
                      value={this.state.user.yearsOfPlacements}
                      span
                      centered
                    />
                  </InputRow>
                </InputGroup>
                <InputGroup
                  label={this.intl.formatMessage({
                    id: 'profile.experienceOfRestorations',
                    defaultMessage: 'Experience of final restorations',
                  })}
                >
                  <InputRow className={styles.selectOptions}>
                    <InputItem
                      label={
                        '*' +
                        this.intl.formatMessage({
                          id: 'profile.restorationsYear',
                          defaultMessage: 'Final restorations/Year',
                        })
                      }
                      type="selectbutton"
                      onChange={this.restorationsYearChanged}
                      options={this.props.implantsYearOptions}
                      value={this.state.user.restorationsYear}
                      span
                      centered
                    />
                  </InputRow>
                  <p className={styles.label}>
                    {`* ${this.intl.formatMessage({
                      id: 'profile.yearsOfRestorations',
                      defaultMessage: 'Experience working with final restorations (years)',
                    })}`}
                  </p>
                  <InputRow className={styles.selectOptions}>
                    <InputItem
                      type="selectbutton"
                      onChange={this.yearsOfRestorationsChanged}
                      options={this.props.yearsOfExperienceOptions}
                      value={this.state.user.yearsOfRestorations}
                      span
                      centered
                      noMarginTop
                    />
                  </InputRow>
                </InputGroup>
                <InputGroup
                  label={this.intl.formatMessage({
                    id: 'general.other',
                    defaultMessage: 'Other',
                  })}
                >
                  <InputRow half>
                    <InputItem
                      label={this.intl.formatMessage({
                        id: 'profile.teethNotation',
                        defaultMessage: 'Teeth Notation',
                      })}
                      type="selectbutton"
                      onChange={this.teethNotationChanged}
                      options={this.teethNotationOptions}
                      value={this.state.user.teethNotation}
                      span
                      centered
                    />
                  </InputRow>
                </InputGroup>
                <Row>
                  <div className={styles.mandatoryFields}>
                    <em>
                      *{' '}
                      <FormattedMessage
                        id="general.mandatoryFields"
                        defaultMessage="Mandatory fields"
                      />
                    </em>
                  </div>
                </Row>
                <Row>
                  <Button
                    primary
                    value={this.intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
                    rightIcon="done"
                    onClick={() => {
                      if (this.props.user.role === 'Demo') {
                        this.showDemoLimitationModal();
                      } else {
                        this.updateUser();
                      }
                    }}
                    disabled={this.updatingUser || this.missingField()}
                    loading={this.updatingUser}
                  />
                </Row>
              </TabPanel>
              <TabPanel>
                {this.props.match.params.userId ? (
                  <FormattedMessage
                    id="profile.notPossibleAsAdmin"
                    defaultMessage="Not possible as admin to update the user's account."
                  />
                ) : (
                  <>
                    <UpdatePasswordInputGroup />
                    <InputGroup
                      label={this.intl.formatMessage({
                        id: 'profile.automaticSignOut1',
                        defaultMessage: 'Automatic sign out',
                      })}
                    >
                      <p>
                        {this.intl.formatMessage({
                          id: 'profile.automaticSignOut2',
                          defaultMessage:
                            'To be sure that only you have access to your account it is important that your account is signed out automatically after a period of inactivity. Choose a time that suits your business best, but note that the shorter the time until the session expires the more sure you can be that no outsider will access your account. ',
                        })}
                      </p>

                      <div className="logoutTimerContainer">
                        <Dropdown options={this.logoutTimeOptions} disabled={false}>
                          <Button
                            small
                            white
                            value={
                              this.logoutTimeOptions.find(
                                (option) => option.key === this.state.user.logoutTimeInMinutes
                              )?.label
                            }
                            rightIcon="arrow_down"
                          />
                        </Dropdown>

                        <Button
                          className="logoutTimerSave"
                          primary
                          value={this.intl.formatMessage({
                            id: 'general.save',
                            defaultMessage: 'Save',
                          })}
                          rightIcon="done"
                          onClick={() => this.updateUser()}
                          disabled={chosenLogoutTimeIsCurrentLogoutTime}
                          loading={false}
                        />
                      </div>
                    </InputGroup>
                    <InputGroup label={'Delete account'}>
                      <p>
                        {this.intl.formatMessage({
                          id: 'profile.permanentlyDeleteAccount',
                          defaultMessage: 'Permanently delete your account.',
                        })}
                      </p>
                      <Button
                        primary
                        value={this.intl.formatMessage({
                          id: 'profile.deleteAccount',
                          defaultMessage: 'Delete account',
                        })}
                        onClick={() => this.toogleShowDeleteAccountModal()}
                      />
                    </InputGroup>
                  </>
                )}
              </TabPanel>
            </Tabs>
          </SectionInner>
        </Section>
      </StyledProfilePage>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.user,
    actAsUser: state.user.actAsUser,
    userUpdate: state.user.userUpdate,
    titles: state.referenceData.titles,
    professions: state.referenceData.professions,
    implantsYearOptions: state.referenceData.implantsYear,
    yearsOfExperienceOptions: state.referenceData.yearsOfExperience,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions, showAlert }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(ProfilePage));

const StyledProfilePage = styled.div`
  .logoutTimerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .logoutTimerSave {
      margin-left: 10px;
    }
  }

  @media (max-width: 400px) {
    .logoutTimerContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .logoutTimerSave {
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
`;
