import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { AlertBox } from '../../components/Shared/Alerts';
import { InputItem } from '../../components/Shared/Forms';
import { Button } from '../../components/Shared/Buttons';
import { Link } from 'react-router-dom';

import * as accountActions from '../../redux/actions/accountActions';

import styles from './RetrievePasswordPage.module.scss';

import { H1 } from '../../components/Shared/Headings';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';

class RetrievePasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };
    this.intl = props.intl;

    this.emailChanged = this.emailChanged.bind(this);
    this.retrievePassword = this.retrievePassword.bind(this);
  }

  emailChanged(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  retrievePassword() {
    this.props.actions.retrievePassword(this.state.email);
  }

  render() {
    let content;

    if (this.props.retrievePassword.complete) {
      content = (
        <div>
          <h1 className={styles.h1}>
            <FormattedMessage
              id="retrievePassword.ResetLinkSent"
              defaultMessage="Password reset link sent"
            />
          </h1>
          <p className={styles.p}>
            <FormattedMessage
              id="retrievePassword.CheckEmail"
              defaultMessage="Please check your email"
            />
          </p>
        </div>
      );
    } else {
      const error = this.props.retrievePassword.error;
      content = (
        <div>
          <div className={styles.logo}>
            <Logo />
          </div>
          <H1 className={styles.h1}>
            <FormattedMessage id="retrievePassword.ResetPassword" defaultMessage="Reset password" />
          </H1>
          <p className={styles.p}>
            <FormattedMessage
              id="retrievePassword.ResetInstruction"
              defaultMessage="We will send you an email with instructions on how to reset your password."
            />
          </p>

          {error ? (
            <AlertBox
              headerText={this.intl.formatMessage({
                id: 'general.generalError',
                defaultMessage: 'Oops, there was an error',
              })}
              messages={error.messages.map((m) => m.text)}
            />
          ) : null}
          <InputItem
            label={this.intl.formatMessage({ id: 'general.email', defaultMessage: 'Email' })}
            type="text"
            onChange={this.emailChanged}
            onKeyPress={(evt) => evt.key === 'Enter' && this.retrievePassword()}
          />
          <Button
            primary
            big
            full
            value={this.intl.formatMessage({ id: 'general.submit', defaultMessage: 'Submit' })}
            onClick={this.retrievePassword}
            disabled={this.props.retrievePassword.busy}
            loading={this.props.retrievePassword.busy}
          />
          <Link to="/">
            <Button
              className={styles.cancelButton}
              big
              full
              value={this.intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
            />
          </Link>
        </div>
      );
    }

    return (
      <div className={styles.retrievePage}>
        <div className={styles.panelWrapper}>
          <div className={styles.panel}>{content}</div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    retrievePassword: state.account.retrievePassword,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(accountActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(RetrievePasswordPage));
