import { FC } from 'react';
import { H3 } from '../Shared/Headings';
import { Button } from '../Shared/Buttons';
import { useIntl } from 'react-intl';
import { TabPanel } from '../Shared/Tabs';
import { IDentalNotation } from '../../types/dentalNotation';
import { selectPatientImplant } from '../../redux/actions/patientActions';
import { IImplant, IImplantMeasurement } from '../../types/implant';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import TabsWithIconsOnMobile from '../Shared/Tabs/TabsWithIconsOnMobile';
import ImplantStability from './ImplantStability';
import ImplantOverview from './ImplantOverview';
import ImplantHistory from './ImplantHistory';
import ImplantData from './ImplantData';
import styles from './ImplantPanel.module.scss';
import cn from 'classnames';

interface Props {
  implant: IImplant;
  tooth: number;
  showCreateTreatmentModal: (tooth: number) => void;
  showCreateAbutmentModal: (tooth: number) => void;
  showCreateImplantModal: (tooth: number) => void;
  showFailImplantModal: (tooth: number) => void;
  deleteTreatment: (id: number) => void;
  showCreateMeasurementModal: (
    tooth: number,
    isStability: boolean,
    measurement: IImplantMeasurement | null
  ) => void;
}

const ImplantPanel: FC<Props> = ({
  implant,
  tooth,
  showCreateTreatmentModal,
  showCreateImplantModal,
  showCreateAbutmentModal,
  showCreateMeasurementModal,
  showFailImplantModal,
  deleteTreatment,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { dentalNotifications } = useAppSelector((state) => state.referenceData);

  const getToothName = () => {
    const notification = dentalNotifications.find((item: IDentalNotation) => item.uns === tooth);
    if (notification) {
      const { name, uns, fdi } = notification;
      const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
      const toothNumber = user.teethNotation === 'UNS' ? uns : fdi;
      return toothNumber + '. ' + capitalized;
    }
    return '';
  };

  return (
    <section className={styles.implantPanel}>
      {!implant && (
        <div className={`${styles.newTreatment}  ${styles.overviewContainer}`}>
          <button onClick={() => dispatch(selectPatientImplant(-1))} className={styles.closeButton}>
            <i className={styles.closeIcon}>close</i>
          </button>
          <div>
            <div className={styles.columnWrapper}>
              <div className={cn(styles.dataCol, styles.actionCol)}>
                <H3>{getToothName()}</H3>
                <Button
                  dataCy="new-treatment-button"
                  value={intl.formatMessage({
                    id: 'patient.newTreatment',
                    defaultMessage: 'New treatment',
                  })}
                  onClick={() => showCreateTreatmentModal(tooth)}
                  leftIcon="add"
                  leftAlign
                  noShadow
                  secondary
                  small
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {implant && (
        <TabsWithIconsOnMobile
          breakPoint={700}
          onChange={undefined}
          iconTabs={['restore', 'implant', 'smartpeg', 'history']}
          selectedTabPanelClassName={styles.implantTabPanelSelected}
          className={(styles.tabWrapper, styles.implantTabPanelSelected)}
          textTabs={[
            intl.formatMessage({
              id: 'patient.treatment',
              defaultMessage: 'Treatment',
            }),

            intl.formatMessage({
              id: 'patient.implantAndAbutment',
              defaultMessage: 'Implant & abutment',
            }),
            intl.formatMessage({
              id: 'patient.stability',
              defaultMessage: 'Stability',
            }),

            intl.formatMessage({
              id: 'patient.history',
              defaultMessage: 'History',
            }),
          ]}
        >
          <TabPanel className={styles.tabContent}>
            <ImplantOverview
              implant={implant}
              showCreateImplantModal={showCreateImplantModal}
              showCreateAbutmentModal={showCreateAbutmentModal}
              showCreateTreatmentModal={showCreateTreatmentModal}
              showCreateMeasurementModal={showCreateMeasurementModal}
              showFailImplantModal={showFailImplantModal}
              deleteTreatment={deleteTreatment}
            />
          </TabPanel>
          <TabPanel className={styles.tabContent}>
            <ImplantData
              implant={implant}
              showCreateImplantModal={showCreateImplantModal}
              showCreateAbutmentModal={showCreateAbutmentModal}
            />
          </TabPanel>
          <TabPanel className={styles.tabContent}>
            <ImplantStability
              implant={implant}
              showCreateMeasurementModal={showCreateMeasurementModal}
            />
          </TabPanel>
          <TabPanel className={styles.tabContent}>
            <ImplantHistory implant={implant} />
          </TabPanel>
        </TabsWithIconsOnMobile>
      )}
      <div className={styles.fillerDiv}></div>
    </section>
  );
};

export default ImplantPanel;
