import * as types from '../../constants/actionTypes';
import readModelHub from '../../utils/readModelHub';
import ApiFetch from '../../utils/apiFetch';

function getFirmwaresRequest() {
  return {
    type: types.GET_FIRMWARES,
  };
}

function getFirmwaresSuccess(data) {
  return {
    type: types.GET_FIRMWARES_SUCCESS,
    firmwares: data,
  };
}

function getFirmwaresFailed(error) {
  return {
    type: types.GET_FIRMWARES_ERROR,
    error,
  };
}

export function getFirmwares() {
  return (dispatch) => {
    dispatch(getFirmwaresRequest());

    ApiFetch.get('admin/firmwares')
      .then((data) => {
        dispatch(getFirmwaresSuccess(data));
      })
      .catch((error) => {
        dispatch(getFirmwaresFailed(error));
      });
  };
}

function setFirmwareToCurrentRequest() {
  return {
    type: types.SET_FIRMWARE_TO_CURRENT,
  };
}

function setFirmwareToCurrentSuccess() {
  return {
    type: types.SET_FIRMWARE_TO_CURRENT_SUCCESS,
  };
}

function setFirmwareToCurrentFailed(error) {
  return {
    type: types.SET_FIRMWARE_TO_CURRENT_ERROR,
    error,
  };
}

export function setFirmwareToCurrent(firmwareId) {
  return (dispatch) => {
    dispatch(setFirmwareToCurrentRequest());

    ApiFetch.put(`admin/firmwares/${firmwareId}/current`)
      .then(() => {
        dispatch(setFirmwareToCurrentSuccess());
      })
      .catch((error) => {
        dispatch(setFirmwareToCurrentFailed(error));
      });
  };
}

function registerFirmwareRequest() {
  return {
    type: types.REGISTER_FIRMWARE,
  };
}

function registerFirmwareSuccess() {
  return {
    type: types.REGISTER_FIRMWARE_SUCCESS,
  };
}

function registerFirmwareFailed(error) {
  return {
    type: types.REGISTER_FIRMWARE_ERROR,
    error,
  };
}

export function registerFirmware(version, file) {
  return (dispatch) => {
    dispatch(registerFirmwareRequest());

    ApiFetch.postFile(`admin/firmwares?version=${version}`, file)
      .then((_) => {
        dispatch(registerFirmwareSuccess());
      })
      .catch((error) => {
        dispatch(registerFirmwareFailed(error));
      });
  };
}

function downloadFirmwareRequest() {
  return {
    type: types.DOWNLOAD_FIRMWARE,
  };
}

function downloadFirmwareSuccess() {
  return {
    type: types.DOWNLOAD_FIRMWARE_SUCCESS,
  };
}

function downloadFirmwareFailed(error) {
  return {
    type: types.DOWNLOAD_FIRMWARE_ERROR,
    error,
  };
}

export function downloadFirmware(file) {
  return (dispatch) => {
    dispatch(downloadFirmwareRequest());

    ApiFetch.downloadFile(file)
      .then((_) => {
        dispatch(downloadFirmwareSuccess());
      })
      .catch((error) => {
        dispatch(downloadFirmwareFailed(error));
      });
  };
}

export function subscribeToFirmwaresChange() {
  return (dispatch) => {
    console.log('SignalR: Subscribing to firmwares (subscribeToFirmwaresChange)');
    readModelHub.subscribe('firmware', null, () => {
      dispatch(getFirmwares());
    });
  };
}

export function unsubscribeFromFirmwaresChange() {
  return () => {
    console.log('SignalR: Unsubscribing from firmwares (unsubscribeFromFirmwaresChange)');
    readModelHub.unsubscribe('firmware');
  };
}
