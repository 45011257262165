import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import styles from './Buttons.module.scss';

class LinkButton extends Component {
  constructor() {
    super();
    this.navigateToLink = this.navigateToLink.bind(this);
    this.executeLinkAction = this.executeLinkAction.bind(this);
  }

  navigateToLink() {
    this.props.history.push(this.props.link);
  }

  executeLinkAction() {
    this.props.linkAction();
  }

  render() {
    const cn = classNames(this.props.className, styles.linkButton);

    return (
      <button
        className={cn}
        onClick={this.props.link ? this.navigateToLink : this.executeLinkAction}
      >
        {this.props.value}
      </button>
    );
  }
}

export default withRouter(LinkButton);
