import * as types from '../../constants/actionTypes';

const initialState = {
  getIntegrations: {
    complete: false,
    error: undefined,
    busy: false,
    integrations: [],
  },
  registerIntegration: {
    complete: false,
    error: undefined,
    busy: false,
  },
  deleteIntegration: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function integration(state = initialState, action) {
  switch (action.type) {
    case types.GET_INTEGRATIONS:
      return Object.assign({}, state, {
        getIntegrations: {
          complete: false,
          error: undefined,
          busy: true,
          integrations: state.getIntegrations.integrations,
        },
      });
    case types.GET_INTEGRATIONS_SUCCESS:
      return Object.assign({}, state, {
        getIntegrations: {
          complete: true,
          error: undefined,
          busy: false,
          integrations: action.integrations,
        },
      });
    case types.GET_INTEGRATIONS_ERROR:
      return Object.assign({}, state, {
        getIntegrations: {
          complete: false,
          error: action.error,
          busy: false,
          integrations: state.getIntegrations.integrations,
        },
      });
    case types.REGISTER_INTEGRATION:
      return Object.assign({}, state, {
        registerIntegration: { complete: false, error: undefined, busy: true },
      });
    case types.REGISTER_INTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        registerIntegration: { complete: true, error: undefined, busy: false },
      });
    case types.REGISTER_INTEGRATION_ERROR:
      return Object.assign({}, state, {
        registerIntegration: { complete: false, error: action.error, busy: false },
      });
    case types.DELETE_INTEGRATION:
      return Object.assign({}, state, {
        deleteIntegration: { complete: false, error: undefined, busy: true },
      });
    case types.DELETE_INTEGRATION_SUCCESS:
      return Object.assign({}, state, {
        deleteIntegration: { complete: true, error: undefined, busy: false },
      });
    case types.DELETE_INTEGRATION_ERROR:
      return Object.assign({}, state, {
        deleteIntegration: { complete: false, error: action.error, busy: false },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
