import { Button } from '../Shared/Buttons';
import { toastr } from 'react-redux-toastr';
import { AlertBox } from '../Shared/Alerts';
import { appInsights } from '../../utils/applicationInsights';
import { toastrOptions } from '../../constants/toastrOptions';
import { showConfirmation } from '../../redux/actions/generalActions';
import { IReferenceDataObj } from '../../types/referenceData';
import { FormattedMessage, useIntl } from 'react-intl';
import { IImplant, IImplantMeasurement } from '../../types/implant';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { InputItem, InputRow, InputSubGroup } from '../Shared/Forms';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  createMeasurement as createMeasurementRequest,
  deleteMeasurement as deleteMeasurementRequest,
} from '../../redux/actions/patientActions';
import {
  unsubscribeToReceviedMeasurement,
  subscribeToReceviedMeasurement,
} from '../../redux/actions/instrumentActions';
import IsqInfoModal from './IsqInfoModal';
import SvgBarcodeliga from '../../shared/icons/osstell-light/BarcodeLiga';
import gs1Parser from '../../modules/gs1-parser';
import ISQItem from './ISQItem';
import styles from './CreateMeasurementModal.module.scss';
import Modal from '../Shared/Modal/Modal';
import cn from 'classnames';

interface Props {
  show: boolean;
  hide: () => void;
  editable: boolean;
  activeInstrumentId: string;
  measurement: IImplantMeasurement | null | undefined;
  implant: IImplant | null;
}

const CreateMeasurementModal: FC<Props> = ({
  show,
  hide,
  editable,
  activeInstrumentId,
  measurement,
  implant,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.user);
  4;
  const {
    currentPatient: patient,
    selectedPatientImplant: selectedTooth,
    createMeasurement,
    deleteMeasurement,
    receivedMeasurement,
  } = useAppSelector((state) => state.patient);

  const { activeInstrument } = useAppSelector((state) => state.instrument);
  const { loadingProtocols, surgicalProtocols } = useAppSelector((state) => state.referenceData);

  const [isqBl, setIsqBl] = useState('');
  const [isqMd, setIsqMd] = useState('');
  const [updatedDir, setUpdatedDir] = useState<string | undefined>(undefined);
  const [nextDir, setNextDir] = useState(undefined);
  const [level, setLevel] = useState<string | undefined>('Implant');
  const [timeStamp, setTimeStamp] = useState<Date>(new Date());
  const [instrumentId, setInstrumentId] = useState<string | undefined>(undefined);
  const [instrumentIdMeasured, setInstrumentIdMeasured] = useState<string | undefined>(undefined);
  const [insertionTorque, setInsertionTorque] = useState<number | undefined>(undefined);
  const [session, setSession] = useState<string | undefined>(undefined);
  const [loadingProtocol, setLoadingProtocol] = useState('NoSelection');
  const [surgicalProtocol, setSurgicalProtocol] = useState('NoSelection');
  const [finalRestoration, setFinalRestoration] = useState(false);
  const [smartPegLot, setSmartPegLot] = useState('');
  const [errorMessages, setErrorMessages] = useState<
    { messages: { text: string; id: string }[] } | undefined
  >(undefined);

  const [isCreatingMeasurement, setIsCreatingMeasurement] = useState(false);
  const [isDeletingMeasurement, setIsDeletingMeasurement] = useState(false);

  const sessionOptions = [
    {
      key: 'Placement',
      value: intl.formatMessage({
        id: 'measurement.placement',
        defaultMessage: 'Placement',
      }),
    },
    {
      key: 'Followup',
      value: intl.formatMessage({ id: 'measurement.followup', defaultMessage: 'Follow up' }),
    },
  ];

  const levelOptions = [
    {
      key: 'Implant',
      value: intl.formatMessage({ id: 'measurement.implant', defaultMessage: 'Implant' }),
    },
    {
      key: 'Abutment',
      value: intl.formatMessage({ id: 'measurement.abutment', defaultMessage: 'Abutment' }),
    },
  ];

  const restorationOptions = [
    {
      key: 'Yes',
      value: intl.formatMessage({ id: 'general.yes', defaultMessage: 'Yes' }),
    },
    {
      key: 'No',
      value: intl.formatMessage({ id: 'general.no', defaultMessage: 'No' }),
    },
  ];

  useEffect(() => {
    const barcodeEventListener: EventListener = (e) => {
      const barcodeEvent = e as BarcodeEvent;
      boundOnScan(barcodeEvent);
    };

    if (window.ReactNativeWebView) {
      window.addEventListener('barcode', barcodeEventListener, true);
    }

    if (show && implant) {
      let type = undefined;
      if (measurement?.type === 'DuringPlacement') type = 'Placement';
      else if (measurement?.type === 'DuringFollowup' || measurement?.type === 'DuringLoading')
        type = 'Followup';

      setSession(type);
      setInsertionTorque(implant.insertionTorque);
      setLoadingProtocol(implant.loadingProtocol);
      setSurgicalProtocol(implant.surgicalProtocol);
      setFinalRestoration(
        measurement
          ? measurement.type === 'DuringLoading'
          : implant.measurements.some((x) => x.type === 'DuringLoading')
      );
    }

    //Received Beacon status
    if (show && activeInstrument.NextDirr) {
      setNextDir(activeInstrument.NextDir);
    }

    if (measurement) {
      setTimeStamp(new Date(measurement.date));
      setIsqBl(measurement.BL.toString());
      setIsqMd(measurement.MD.toString());
      setLevel(measurement.level);
      setSmartPegLot(measurement.smartPegLot ?? '');
    }

    return () => {
      if (instrumentId) {
        dispatch(unsubscribeToReceviedMeasurement(instrumentId));
      }
      if (window.ReactNativeWebView) {
        window.removeEventListener('barcode', barcodeEventListener, true);
      }
    };
  }, []);

  useEffect(() => {
    if (activeInstrumentId) {
      dispatch(subscribeToReceviedMeasurement(activeInstrumentId));
      setInstrumentId(activeInstrumentId);
    }
  }, [activeInstrumentId]);

  useEffect(() => {
    if (instrumentId && show) {
      if (instrumentId) {
        dispatch(unsubscribeToReceviedMeasurement(instrumentId));
      }
      dispatch(subscribeToReceviedMeasurement(instrumentId));
      setInstrumentId(instrumentId);
    }

    if (show && activeInstrument && activeInstrument.NextDir !== nextDir) {
      setNextDir(activeInstrument.NextDir);
    }
  }, [show, activeInstrument]);

  useEffect(() => {
    if (show) {
      if (receivedMeasurement && receivedMeasurement.Ct) {
        if (receivedMeasurement.Dir === 'BL') {
          setIsqBl(receivedMeasurement.Isq);
          setTimeStamp(timeStamp);
          setInstrumentIdMeasured(instrumentId);
          setUpdatedDir('BL');
          setNextDir(undefined);
        } else if (receivedMeasurement.Dir === 'MD') {
          setIsqMd(receivedMeasurement.Isq);
          setTimeStamp(timeStamp);
          setInstrumentIdMeasured(instrumentId);
          setUpdatedDir('MD');
          setNextDir(undefined);
        }
      }
    }
  }, [show, receivedMeasurement]);

  useEffect(() => {
    if (createMeasurement.complete && isCreatingMeasurement) {
      setIsCreatingMeasurement(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({ id: 'measurement.saved', defaultMessage: 'Measurement saved' }),
        toastrOptions
      );
      hideCreateMeasurementModal();
      return;
    }

    if (
      (createMeasurement.error && isCreatingMeasurement) ||
      (deleteMeasurement.error && isDeletingMeasurement)
    ) {
      setIsCreatingMeasurement(false);
      setIsDeletingMeasurement(false);

      const error = createMeasurement.error || deleteMeasurement.error;

      error &&
        error.messages.forEach((m: { text: string; id: string }) =>
          toastr.error(
            intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
            intl.formatMessage({ id: m.text }),
            toastrOptions
          )
        );
    }

    if (deleteMeasurement.complete && isDeletingMeasurement) {
      setIsDeletingMeasurement(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'measurement.deleted',
          defaultMessage: 'Measurement deleted',
        }),
        toastrOptions
      );
      hideCreateMeasurementModal();
      return;
    }
  }, [createMeasurement, isCreatingMeasurement, deleteMeasurement, isDeletingMeasurement]);

  const hideCreateMeasurementModal = () => {
    if (instrumentId) {
      dispatch(unsubscribeToReceviedMeasurement(instrumentId));
    }
    setIsqBl('');
    setIsqMd('');
    setUpdatedDir(undefined);
    setNextDir(undefined);
    setTimeStamp(new Date());
    setLevel('Implant');
    setInstrumentId(undefined);
    setInstrumentIdMeasured(undefined);
    setInsertionTorque(-1);
    setSession(undefined);
    setLoadingProtocol('NoSelection');
    setSurgicalProtocol('NoSelection');
    setFinalRestoration(false);
    setSmartPegLot('');

    if (hide) return hide();
  };

  const insertionTorqueChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    let value: string | undefined = evt.target.value;
    if (value.length === 0 || !Number(value)) value = undefined;
    setInsertionTorque(Number(value));
  };

  const sessionChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setSession(evt.target.value);
    if (evt.target.value === 'Placement') setFinalRestoration(false);
  };

  const levelChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    let value: string | undefined = evt.target.value;
    if (value.length === 0) value = undefined;
    setLevel(value);
  };

  const isqChanged = (dir: string, value: string) => {
    if (dir === 'BL') return setIsqBl(value);
    if (dir === 'MD') return setIsqMd(value);
  };

  const timeStampChanged = (date: Date) => setTimeStamp(date);

  const loadingProtocolChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setLoadingProtocol(evt.target.value);
    setSurgicalProtocol(evt.target.value === 'ConventionalLoading' ? 'TwoStage' : 'OneStage');
  };

  const surgicalProtocolChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setSurgicalProtocol(evt.target.value);

  const finalRestorationChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setFinalRestoration(evt.target.value === 'Yes');

  const lotChanged = (evt: ChangeEvent<HTMLInputElement>) => setSmartPegLot(evt.target.value);

  interface BarcodeEvent extends Event {
    detail: string;
  }

  const boundOnScan = (e: BarcodeEvent) => {
    const barcodeFromEvent = e.detail;
    const barCode = barcodeFromEvent.replace('\x1D', '').trim();
    let result;
    try {
      result = gs1Parser(barCode);
    } catch (error) {
      lotChanged({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
      setErrorMessages({
        messages: [
          {
            text: intl.formatMessage({
              id: 'addimplant.noMatchOnScan2',
              defaultMessage: `No barcode standard we support was detected.`,
            }),
            id: 'NoMatch',
          },
        ],
      });

      appInsights.trackEvent({
        name: 'ScannedSmartPegFail',
        properties: {
          barCode: barCode,
          parsedLotNumber: false,
          parsingSuccess: false,
          userId: user.userId,
          email: user.email,
        },
      });
      return;
    }

    let scannedSmartPegSuccess = false;

    try {
      const lotNumberElement = result.parsedCodeItems.find((parsedItem) => parsedItem.ai === '10');
      lotChanged({ target: { value: lotNumberElement.data } } as ChangeEvent<HTMLInputElement>);
      setErrorMessages(undefined);

      scannedSmartPegSuccess = true;
    } catch {
      scannedSmartPegSuccess = false;

      lotChanged({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
      setErrorMessages({
        messages: [
          {
            text: intl.formatMessage({
              id: 'measurement.noLotNrFoundWhenScanning',
              defaultMessage: 'Could not find a lotnumber in that barcode.',
            }),
            id: 'NoMatch',
          },
        ],
      });

      appInsights.trackEvent({
        name: 'ScannedSmartPegFail',
        properties: {
          barCode: barCode,
          parsedLotNumber: false,
          parsingSuccess: true,
          userId: user.userId,
          email: user.email,
        },
      });
    }

    if (scannedSmartPegSuccess) {
      appInsights.trackEvent({
        name: 'ScannedSmartPegSuccess',
        properties: {
          barCode: barCode,
          userId: user.userId,
          email: user.email,
        },
      });
    }
  };

  const saveMeasurement = () => {
    if (isqBl.length === 0 || isqMd.length === 0 || selectedTooth <= 0) return;

    let type = 'Unknown';

    if (session === 'Placement') type = 'DuringPlacement';
    else if (finalRestoration) type = 'DuringLoading';
    else type = 'DuringFollowup';

    setIsCreatingMeasurement(true);
    dispatch(
      createMeasurementRequest(
        patient.sourceUserGroupId,
        patient.id,
        selectedTooth,
        isqBl,
        isqMd,
        type,
        level,
        timeStamp,
        instrumentIdMeasured,
        measurement ? measurement.id : null,
        loadingProtocol,
        surgicalProtocol,
        insertionTorque,
        smartPegLot,
        implant && implant.measurements
      )
    );
  };

  const deleteMeasurementModal = () => {
    const content = (
      <div>
        <p>
          {intl.formatMessage({
            id: 'measurement.DeleteConfirmation',
            defaultMessage: 'Are you sure your want to delete the measurement?',
          })}
        </p>
      </div>
    );
    dispatch(
      showConfirmation(
        intl.formatMessage({
          id: 'measurement.DeleteMeasurement',
          defaultMessage: 'Delete Measurement',
        }),
        content,
        deleteMeasurementHandler
      )
    );
  };

  const deleteMeasurementHandler = () => {
    setIsDeletingMeasurement(true);

    dispatch(
      deleteMeasurementRequest(
        patient.sourceUserGroupId,
        patient.id,
        selectedTooth,
        measurement && measurement.id,
        implant && implant.measurements
      )
    );
  };

  const disableSave = () => {
    return (
      isCreatingMeasurement ||
      !session ||
      isqBl === undefined ||
      isqMd === undefined ||
      isqBl.length === 0 ||
      isqMd.length === 0 ||
      surgicalProtocol === 'NoSelection' ||
      loadingProtocol === 'NoSelection'
    );
  };

  const error = errorMessages;

  const content = (
    <div className={styles.content}>
      <div className={styles.column}>
        <InputRow>
          <InputItem
            dataCy="create-measurement-modal-session-input"
            label={
              '*' +
              intl.formatMessage({
                id: 'measurement.measurementSession',
                defaultMessage: 'Measurement Session',
              })
            }
            type="selectbutton"
            onChange={sessionChanged}
            options={sessionOptions}
            value={session}
            centered
            span
            onWhite
          />
        </InputRow>
        <div className={styles.timestamp}>
          <InputItem
            className={styles.date}
            label={'*' + intl.formatMessage({ id: 'general.date', defaultMessage: 'Date' })}
            value={timeStamp}
            type="date"
            onChange={timeStampChanged}
            onWhite
            timeVisible={true}
          />
        </div>
        <InputRow>
          <InputItem
            label={
              '*' +
              intl.formatMessage({
                id: 'measurement.measurementLevel',
                defaultMessage: 'Measurement level',
              })
            }
            type="selectbutton"
            onChange={levelChanged}
            options={levelOptions}
            value={level}
            centered
            span
            onWhite
          />
        </InputRow>
        <InputSubGroup
          className={styles.measurementWrapper}
          label={
            '*' +
            intl.formatMessage({
              id: 'measurement.enterISQ',
              defaultMessage: 'Measure or enter ISQ manually',
            })
          }
        >
          <div className={styles.isqWrapper}>
            <ISQItem
              dataCy="create-measurement-modal-input-bl"
              editable={editable}
              direction="BL"
              isq={isqBl}
              isqChanged={isqChanged}
              active={nextDir === 'BL'}
              updated={updatedDir === 'BL'}
              abutment={level === 'Abutment'}
            />
            <ISQItem
              dataCy="create-measurement-modal-input-md"
              editable={editable}
              direction="MD"
              isq={isqMd}
              isqChanged={isqChanged}
              active={nextDir === 'MD'}
              updated={updatedDir === 'MD'}
              abutment={level === 'Abutment'}
            />
          </div>
          <div className={cn(styles.instrumentStatus, activeInstrumentId ? styles.online : null)}>
            {activeInstrumentId ? (
              <FormattedMessage
                id="measurement.activeInstrument"
                defaultMessage="Beacon connected, ready to measure"
              />
            ) : (
              <FormattedMessage
                id="measurement.noActiveInstrument"
                defaultMessage="No active instrument"
              />
            )}
          </div>
          <div className={styles.isqInfoIcon}>
            <IsqInfoModal />
          </div>
          {level === 'Abutment' && (
            <div className={styles.abutmentNote}>
              *{' '}
              <FormattedMessage
                id="patient.abutmentNote"
                defaultMessage="The ISQ on abutment level will not be equal to measuring on implant level and should be used as a relative ISQ value for tracking the implant stability."
              />
            </div>
          )}
        </InputSubGroup>
      </div>
      <div className={styles.column}>
        {session === 'Placement' && (
          <InputRow>
            <InputItem
              dataCy="create-measurement-modal-input-max-insertion"
              label={intl.formatMessage({
                id: 'measurement.maximumInsertionTorque',
                defaultMessage: 'Maximum Insertion Torque (Ncm)',
              })}
              type="text"
              value={insertionTorque && insertionTorque > 0 ? insertionTorque : ''}
              onChange={insertionTorqueChanged}
              onWhite
            />
          </InputRow>
        )}
        {error ? (
          <AlertBox
            color="black"
            headerText={intl.formatMessage({
              id: 'general.generalError',
              defaultMessage: 'Oops, there was an error',
            })}
            messages={error.messages.map((m) => m.text)}
          />
        ) : null}
        <InputRow className={styles.scanRow}>
          <InputItem
            dataCy="create-measurement-modal-input-lotnumber"
            label={intl.formatMessage({
              id: 'measurement.batchLotNumber',
              defaultMessage: 'SmartPeg Batch/Lot Number',
            })}
            type="text"
            value={smartPegLot}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              lotChanged(e);
              setErrorMessages(undefined);
            }}
            onWhite
          />
          {window.ReactNativeWebView && (
            <div
              className={styles.scanButton}
              onClick={() => {
                window.ReactNativeWebView &&
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({
                      type: 'startscan',
                      scanText: intl.formatMessage({
                        id: 'app.scanSmartPeg',
                        defaultMessage: 'Scan the SmartPeg QR-code',
                      }),
                    })
                  );
              }}
            >
              <SvgBarcodeliga width={'40'} />
            </div>
          )}
        </InputRow>
        <InputRow>
          <InputItem
            label={
              '*' +
              intl.formatMessage({
                id: 'measurement.loadingProtocol',
                defaultMessage: 'Loading Protocol',
              })
            }
            type="selectbutton"
            options={loadingProtocols.map((x: IReferenceDataObj) => ({
              key: x.key,
              value: intl.formatMessage({ ...x.label }),
            }))}
            value={loadingProtocol}
            onChange={loadingProtocolChanged}
            centered
            onWhite
            span
          />
        </InputRow>
        <InputRow>
          <InputItem
            label={
              '*' +
              intl.formatMessage({
                id: 'measurement.surgicalProtocol',
                defaultMessage: 'Surgical Protocol',
              })
            }
            type="selectbutton"
            options={surgicalProtocols.map((x: IReferenceDataObj) => ({
              key: x.key,
              value: intl.formatMessage({ ...x.label }),
            }))}
            value={surgicalProtocol}
            onChange={surgicalProtocolChanged}
            centered
            onWhite
            span
          />
        </InputRow>
        {session === 'Followup' && (
          <InputRow>
            <InputItem
              type="selectbutton"
              label={intl.formatMessage({
                id: 'measurement.prostheticDecision',
                defaultMessage: 'Prosthetic Decision: Ready for final restoration?',
              })}
              options={restorationOptions}
              value={finalRestoration ? 'Yes' : 'No'}
              onChange={finalRestorationChanged}
              centered
              onWhite
              span
            />
          </InputRow>
        )}
      </div>
    </div>
  );
  return (
    <Modal
      header={intl.formatMessage({
        id: 'measurement.addMeasurement',
        defaultMessage: 'Add measurement',
      })}
      onDismiss={hideCreateMeasurementModal}
      show={show}
    >
      {content}
      <div className={styles.mandatoryFields}>
        <em>
          * <FormattedMessage id="general.mandatoryFields" defaultMessage="Mandatory fields" />
        </em>
      </div>
      <div className={styles.buttons}>
        {measurement ? (
          <Button
            value={intl.formatMessage({ id: 'general.delete', defaultMessage: 'Delete' })}
            onClick={deleteMeasurementModal}
            disabled={isDeletingMeasurement}
            loading={isDeletingMeasurement}
          />
        ) : null}
        <Button
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={() => hideCreateMeasurementModal()}
        />
        <Button
          dataCy="create-measurement-modal-save-button"
          primary
          value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
          onClick={saveMeasurement}
          disabled={disableSave()}
          loading={isCreatingMeasurement}
        />
      </div>
    </Modal>
  );
};

export default CreateMeasurementModal;
