import { useEffect, useState } from 'react';
import { SET_DIRTY } from '../../redux/slices/procedureSlice';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

/**
 * Renderless component that autosaves consultation to database and
 * sets configurationHasUnsavedChanges to true if workspacePastLength and
 * workspaceFutureLength change value.
 * @param {function} storeConsultationInDb - Function that saves consultation to the
 * database.
 */

function AutosaveConsultation({
  storeConsultationInDb,
}: {
  storeConsultationInDb: () => Promise<string | undefined>;
}) {
  const dispatch = useAppDispatch();
  const configurationID = useAppSelector((state) => state.consultation.procedure.consultationId);
  const configurationHasUnsavedChanges = useAppSelector(
    (state) => state.consultation.procedure.configurationHasUnsavedChanges
  );
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const workspacePastLength = useAppSelector((state) => state.consultation.workspace.past.length);
  const workspaceFutureLength = useAppSelector(
    (state) => state.consultation.workspace.future.length
  );

  const millisecondsToAutoSave = 30000;

  useEffect(() => {
    if (!configurationID || !configurationHasUnsavedChanges) {
      return;
    }
    if (timer) clearTimeout(timer);

    let mounted = true;
    const id = setTimeout(() => {
      if (mounted) {
        storeConsultationInDb();
      }
    }, millisecondsToAutoSave);
    setTimer(id);
    return () => {
      mounted = false;
      clearTimeout(id);
    };
  }, [configurationHasUnsavedChanges, workspacePastLength, workspaceFutureLength]);

  useEffect(() => {
    const userNavigatedToAnotherStep = workspacePastLength === 0 && workspaceFutureLength === 0;
    if (!configurationHasUnsavedChanges && !userNavigatedToAnotherStep) {
      dispatch(SET_DIRTY(true));
    }
  }, [workspacePastLength, workspaceFutureLength]);

  return null;
}

export default AutosaveConsultation;
