import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import styles from './Alerts.module.scss';
import { errors } from '../../../constants/referenceData';

class AlertBox extends Component {
  renderMessages() {
    const messages = [];

    if (this.props.message) {
      messages.push({ id: this.props.message, defaultMessage: this.props.message });
    }

    if (this.props.messages) {
      this.props.messages.forEach((message) => {
        if (errors[message]) {
          messages.push(errors[message]);
        } else if (!messages.some((x) => x.id === message)) {
          messages.push({ id: message, defaultMessage: message });
        }
      });
    }

    return messages.map((message, index) => {
      return (
        <li key={index} data-cy={this.props.dataCyMessage && this.props.dataCyMessage}>
          <FormattedMessage {...message} />
        </li>
      );
    });
  }

  render() {
    const style = this.props.color ? { color: this.props.color } : null;
    const classNames = cn(this.props.className, styles.alertBox);

    return (
      <div className={classNames} style={style}>
        <h2 data-cy={this.props.dataCyHeader && this.props.dataCyHeader}>
          {this.props.headerText}
        </h2>
        <ul>{this.renderMessages()}</ul>
      </div>
    );
  }
}

export default AlertBox;
