import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import {
  DRAWING_CLEAR,
  DRAWING_CREATE_SHAPE,
  DRAWING_DELETE_LINE_INTERSECTION,
  DRAWING_UPDATE,
  TEXTFIELD_CREATE,
  TEXTFIELD_REMOVE,
} from '../../../../../../redux/slices/workspaceSlice';
import { Group, Line, Rect } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { SET_DRAWING_STATUS, SET_TEXTFIELD_STATUS } from '../../../../../../redux/slices/UISlice';
import { TeethMovableTextField } from './TeethMovableTextField';
import { v4 as uuidv4 } from 'uuid';
import {
  DrawShape,
  Position,
  TextField,
} from '../../../../../../redux/slices/data-structures/workspaceStructure';

export interface InteractionEvent extends MouseEvent, TouchEvent {}

export function TeethCanvasDrawLayer({
  changeCursor,
}: {
  changeCursor?: (cursor: string) => void;
}) {
  const textfields = useAppSelector((state) => state.consultation.workspace.present.textFields);
  const drawShapes = useAppSelector((state) => state.consultation.workspace.present.drawShapes);
  const drawStatusId = useAppSelector((state) => state.consultation.view.drawShapeId);
  const tool = useAppSelector((state) => state.consultation.view.currentTool);
  const subTool = useAppSelector((state) => state.consultation.view.currentSubTool);
  const color = useAppSelector((state) => state.consultation.view.drawCurrentColor);

  const eraseMode = subTool?.name === 'Erase';
  const lineMode = eraseMode || subTool?.name === 'Lines';
  const clearMode = subTool?.name === 'Clear';
  const [linePreview, setLinePreview] = useState<{ start: Position | null; end: Position | null }>({
    start: null,
    end: null,
  });
  const [erasing, setErasing] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const active = tool?.name === 'Text' || tool?.name === 'Draw';

  function createElement(e: KonvaEventObject<InteractionEvent>) {
    if (clearMode) {
      return;
    }
    const uid = uuidv4();
    const pointerPosition = e.target.getRelativePointerPosition();
    const placementX = pointerPosition.x;
    const placementY = pointerPosition.y;

    //check and remove any empty textfields
    textfields.forEach((textField: TextField) => {
      const isEmpty = textField.text.trim() === '';
      if (isEmpty) {
        dispatch(TEXTFIELD_REMOVE({ id: textField.id }));
      }
    });

    if (tool?.name === 'Text') {
      dispatch(
        TEXTFIELD_CREATE({
          id: `textfield_${uid}`,
          x: placementX,
          y: placementY,
          color: color,
        })
      );
      dispatch(SET_TEXTFIELD_STATUS({ id: `textfield_${uid}` }));
    } else if (tool?.name === 'Draw') {
      if (eraseMode) {
        //change graphic when on erase?
        setErasing(true);
      } else {
        dispatch(
          DRAWING_CREATE_SHAPE({
            id: `drawing_${uid}`,
            x: placementX,
            y: placementY,
            color: color,
          })
        );
        dispatch(SET_DRAWING_STATUS({ id: `drawing_${uid}` }));
      }

      if (lineMode) {
        setLinePreview((_prev) => {
          const placement = {
            x: placementX,
            y: placementY,
          };
          return {
            start: placement,
            end: placement,
          };
        });
      }
    } else {
      dispatch(SET_TEXTFIELD_STATUS({ id: undefined }));
    }
  }

  function onRelease(e: KonvaEventObject<InteractionEvent>) {
    if (clearMode) {
      dispatch(DRAWING_CLEAR());
    }
    if (lineMode) {
      const pointerPosition = e.target.getRelativePointerPosition();
      const placementX = pointerPosition.x;
      const placementY = pointerPosition.y;

      dispatch(
        DRAWING_UPDATE({
          id: drawStatusId,
          x: placementX,
          y: placementY,
        })
      );

      setLinePreview((_prev) => {
        return {
          start: null,
          end: null,
        };
      });

      if (erasing) {
        dispatch(
          DRAWING_DELETE_LINE_INTERSECTION({
            xStart: linePreview.start?.x || 0,
            yStart: linePreview.start?.y || 0,
            xEnd: placementX,
            yEnd: placementY,
          })
        );
      }
    }

    setErasing(false);
    dispatch(SET_DRAWING_STATUS({ id: undefined }));
    dispatch(SET_DRAWING_STATUS({ id: undefined })); //double up to make sure release happens
  }

  function draw(e: KonvaEventObject<InteractionEvent>) {
    if (clearMode) {
      return;
    }
    const pointerPosition = e.target.getRelativePointerPosition();
    const placementX = pointerPosition.x;
    const placementY = pointerPosition.y;

    if (lineMode || eraseMode) {
      setLinePreview((prev) => {
        return {
          ...prev,
          end: {
            x: placementX,
            y: placementY,
          },
        };
      });
      if (eraseMode && erasing) {
        /*dispatch(
          DRAWING_DELETE_CLOSEST_SHAPE({
            x: placementX - absolutePosition.x,
            y: placementY - 190,
          })
        );*/
      }
    } else {
      if (drawStatusId) {
        dispatch(
          DRAWING_UPDATE({
            id: drawStatusId,
            x: placementX,
            y: placementY,
          })
        );
      }
    }
  }

  return (
    <Group listening={active}>
      {drawShapes.map((shape: DrawShape) => (
        <Line
          key={shape.id}
          id={shape.id}
          stroke={shape.color}
          points={toLinePoints(shape.nodes)}
          lineCap={'round'}
          tension={0.2}
        />
      ))}

      {/*      Render a preview before a line is created*/}
      {lineMode && linePreview?.start && linePreview?.end && (
        <Line
          stroke={eraseMode ? 'red' : color}
          opacity={0.5}
          points={toLinePoints([
            { x: linePreview.start.x, y: linePreview.start.y },
            { x: linePreview.end.x, y: linePreview.end.y },
          ])}
        />
      )}
      <Rect
        x={0}
        y={0}
        width={1200}
        height={800}
        onMouseDown={createElement}
        onMouseUp={onRelease}
        onMouseMove={draw}
        onTouchStart={createElement}
        onTouchEnd={onRelease}
        onTouchMove={draw}
        fill={'transparent'}
      />

      {/* Textfields and drawings*/}
      {textfields.map((textfield: TextField) => (
        <TeethMovableTextField
          id={textfield.id}
          key={`textfield_${textfield.id}`}
          changeCursor={changeCursor}
        />
      ))}
    </Group>
  );
}

function toLinePoints(nodes: Position[]) {
  return nodes.map((node) => [node.x, node.y]).flat();
}
