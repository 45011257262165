import * as React from 'react';

function SvgPrepliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1482 2048" {...props}>
      <path d="M1210 538q34 22 52 35.5t46.5 46.5 42.5 70.5 23 100.5 5 142q-4 80-16 175.5t-38 215-61.5 214T1172 1697t-123 65q-25 0-47-7.5t-39-18-31.5-31.5-24.5-37.5-19.5-47.5-15-50.5-12-57-9-55.5-8.5-58q-4-32-6.5-48.5T829 1305t-8.5-44-9.5-36.5-12.5-33-15-23.5-19-17-22.5-5q-16 0-29 9t-23 29-17 39-13.5 53.5-10 57.5-9.5 65q-6 40-8.5 58t-9 55.5-12 57-15 50.5-19.5 47.5-24.5 37.5-31.5 31.5-39 18-47 7.5q-67 0-123-65t-91.5-159.5-61.5-214-38-215T104 933q-3-57 1-106t11.5-83.5 23-65.5 28-49 35-38 35-28.5T274 538q28-18 40.5-28t29-28 23-41 6.5-55q0-42 8.5-79t33.5-76 65-66 107-44.5T742 103t155 17.5 107 44.5 65 66 33.5 76 8.5 79q0 32 6.5 55t23 41 29 28 40.5 28zm127 393q3-61-.5-109.5t-16-84T1296 678t-34.5-45.5-36-31.5-39.5-27q-30-20-44.5-31t-35-34-29.5-53-9-70q0-240-326-240-327 0-327 240 0 40-9 70t-29.5 53-35 34-44.5 31q-26 17-39.5 27t-36 31.5T187 678t-24.5 59.5-16 84T146 931q7 125 29.5 258.5t57.5 254 88 198 113 77.5q25 0 45.5-10.5t35-25 27-43 20-51 15.5-63 11.5-64 9.5-69.5q7-49 11-74.5t12.5-65.5 18-61.5 24-44.5 34-33 44.5-10q15 0 28.5 3.5t24 12.5 19.5 17.5 17 25.5 13.5 29 11 35.5 9 37 8 41.5 6.5 42 6 45q6 46 9.5 69.5t11.5 64 15.5 63 20 51 27 43 35 25 45.5 10.5q60 0 113-77.5t88-198 57.5-254T1337 931zm-145 276q8 2 13 9t4 16q-8 44-26 120-3 17-21 17-1 0-2-.5t-2-.5q-8-2-13-10t-3-16q13-55 25-118 1-8 9-13.5t16-3.5zm-124-588q41 11 69 25.5t57 50 41 88.5 11.5 143.5T1226 1139q-4 18-21 18h-4q-9-1-14-8.5t-3-16.5q14-87 18-157t1.5-116-15.5-81.5-24.5-54-34-32.5-35.5-19-37-11q-8-2-12.5-10.5T1042 634t10-12.5 16-2.5z" />
    </svg>
  );
}

export default SvgPrepliga;
