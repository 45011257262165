import * as React from 'react';

function SvgSvgFullscreen(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.78 26.24" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect
            x={0.5}
            y={0.5}
            width={31.78}
            height={25.24}
            rx={1.8}
            strokeDasharray="8.07 2.01 .01 0 0 0"
            fill="none"
            strokeMiterlimit={10}
          />
          <rect
            x={0.5}
            y={13.89}
            width={14.91}
            height={11.84}
            rx={1.8}
            fill="none"
            strokeMiterlimit={10}
          />
          <path strokeLinecap="round" fill="none" strokeMiterlimit={10} d="M18.77 11.43l7.06-5" />
          <path d="M23.75 4.18l3.51 4.96.72-4.23-4.23-.73z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgFullscreen;
