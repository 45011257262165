import { Button } from '../Shared/Buttons';
import { H3, H2 } from '../Shared/Headings';
import { FC, useEffect } from 'react';
import { selectPatientImplant } from '../../redux/actions/patientActions';
import {
  IAbutmentData,
  IAbutmentStudyData,
  IImplant,
  IImplantData,
  IImplantStudyData,
} from '../../types/implant';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  getStudyImplantData,
  getStudyAbutmentData,
} from '../../redux/actions/referenceDataActions';
import DataItem from '../Shared/DataItem/DataItem';
import styles from './ImplantPanel.module.scss';
import cn from 'classnames';
import { abutmentDataState, implantDataState } from '../../constants/defaultStates';

interface Props {
  implant: IImplant;
  showCreateImplantModal: (tooth: number) => void;
  showCreateAbutmentModal: (tooth: number) => void;
}

const ImplantData: FC<Props> = ({ implant, showCreateImplantModal, showCreateAbutmentModal }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { implantData, abutmentData, studyImplantData, studyAbutmentData } = useAppSelector(
    (state) => state.referenceData
  );

  useEffect(() => {
    if (!studyImplantData || studyImplantData.length === 0) {
      dispatch(getStudyImplantData());
    }
    if (!studyAbutmentData || studyAbutmentData.length === 0) {
      dispatch(getStudyAbutmentData());
    }
  }, []);

  const getImplantData = (implantData: IImplantData[], studyImplantData: IImplantStudyData[]) => {
    let specificImplantData = implantData.find((data) => data.dataId === implant.dataId);

    if (!specificImplantData) {
      specificImplantData = studyImplantData.find((data) => data.dataId === implant.dataId);
    }

    if (!specificImplantData) {
      specificImplantData = implantDataState;
    }

    return specificImplantData;
  };
  const getAbutmentData = (
    abutmentData: IAbutmentData[],
    studyAbutmentData: IAbutmentStudyData[]
  ) => {
    let specificAbutmentData = abutmentData.find((data) => data.dataId === implant.abutmentId);

    if (!specificAbutmentData) {
      specificAbutmentData = studyAbutmentData.find((data) => data.dataId === implant.abutmentId);
    }

    if (!specificAbutmentData) {
      specificAbutmentData = abutmentDataState;
    }

    return specificAbutmentData;
  };

  const modifiedImplantData = getImplantData(implantData, studyImplantData);
  const modifiedAbutmentData = getAbutmentData(abutmentData, studyAbutmentData);

  return (
    <div className={styles.overviewContainer}>
      <button onClick={() => dispatch(selectPatientImplant(-1))} className={styles.closeButton}>
        <i className={styles.closeIcon}>close</i>
      </button>
      <H2 className={styles.tabHeading}>
        <FormattedMessage id="patient.implantAndAbutment" defaultMessage="Implant & abutment" />
      </H2>
      <div className={cn(styles.overviewWrapper)}>
        <div className={cn(styles.dataCol, styles.implantCol)}>
          {implant.dataId === -1 && (
            <Button
              value={intl.formatMessage({
                id: 'patient.addImplant',
                defaultMessage: 'Add implant',
              })}
              leftIcon="implant"
              onClick={() => showCreateImplantModal(implant.tooth)}
              className={styles.buttonFixedWidth}
              noShadow
              small
              leftAlign
              secondary
            />
          )}
          {implant.dataId > 0 && (
            <>
              <div className={styles.headerContainer}>
                <button
                  onClick={() => showCreateImplantModal(implant.tooth)}
                  className={styles.editButton}
                >
                  <i className={styles.editIcon}>draw</i>
                </button>
                <H3>
                  <FormattedMessage id="patient.implant" defaultMessage="Implant" />
                </H3>
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.brand',
                    defaultMessage: 'Brand',
                  })}
                  value={modifiedImplantData.brandName}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.model',
                    defaultMessage: 'Model',
                  })}
                  value={modifiedImplantData.modelName}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.artNumber',
                    defaultMessage: 'Article number',
                  })}
                  value={modifiedImplantData.partNumber}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.platform',
                    defaultMessage: 'Platform',
                  })}
                  value={modifiedImplantData.platformName}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.batchLotNumber',
                    defaultMessage: 'Batch/Lot number',
                  })}
                  value={implant.implantLot}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.diameter',
                    defaultMessage: 'Diameter',
                  })}
                  value={modifiedImplantData.diameter}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.lenth',
                    defaultMessage: 'Length (mm)',
                  })}
                  value={modifiedImplantData.length}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.suitableSmartPeg',
                    defaultMessage: 'Suitable SmartPeg',
                  })}
                  value={modifiedImplantData.smartPeg}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
            </>
          )}
        </div>
        <div className={cn(styles.dataCol, styles.implantCol)}>
          {implant.abutmentId === -1 && implant.dataId > 0 && (
            <Button
              value={intl.formatMessage({
                id: 'patient.addAbutment',
                defaultMessage: 'Add abutment',
              })}
              leftIcon="abutment"
              onClick={() => showCreateAbutmentModal(implant.tooth)}
              className={styles.buttonFixedWidth}
              noShadow
              small
              leftAlign
              secondary
            />
          )}
          {implant.abutmentId > 0 && (
            <div>
              <div className={styles.headerContainer}>
                <button
                  onClick={() => showCreateAbutmentModal(implant.tooth)}
                  className={styles.editButton}
                >
                  <i className={styles.editIcon}>draw</i>
                </button>
                <H3>
                  <FormattedMessage id="patient.abutment" defaultMessage="Abutment" />
                </H3>
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.brand',
                    defaultMessage: 'Brand',
                  })}
                  value={modifiedAbutmentData.brandName}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.model',
                    defaultMessage: 'Model',
                  })}
                  value={modifiedAbutmentData.modelName}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.artNumber',
                    defaultMessage: 'Article number',
                  })}
                  value={modifiedAbutmentData.partNumber}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.platform',
                    defaultMessage: 'Platform',
                  })}
                  value={modifiedAbutmentData.platformName}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.batchLotNumber',
                    defaultMessage: 'Batch/Lot number',
                  })}
                  value={implant.abutmentLot}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
              <div className={styles.dataRow}>
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.diameter',
                    defaultMessage: 'Diameter',
                  })}
                  value={modifiedAbutmentData.diameter}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.lenth',
                    defaultMessage: 'Length (mm)',
                  })}
                  value={modifiedAbutmentData.length}
                  className={cn(styles.dataItem, styles.equal)}
                />
                <DataItem
                  label={intl.formatMessage({
                    id: 'implant.suitableSmartPeg',
                    defaultMessage: 'Suitable SmartPeg',
                  })}
                  value={modifiedAbutmentData.smartPeg}
                  className={cn(styles.dataItem, styles.equal)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImplantData;
