import { IClinic } from '../types/clinic';
import { IAbutmentData, IImplantData } from '../types/implant';
import { IStudyMemeber } from '../types/studyGroups';

export const clinicState: IClinic = {
  id: '',
  name: '',
  logo: '',
  members: [],
  referrals: [],
  userGroupId: '',
  numberOfPractitionersInClinic: '',
  address: {
    street: '',
    careOf: '',
    postcode: '',
    city: '',
    countryCode: '',
    state: '',
  },
};

export const studyMemberState: IStudyMemeber = {
  id: '',
  email: '',
  country: '',
  firstName: '',
  surname: '',
  numberOfImplants: 0,
  pending: false,
  userId: '',
  userPermission: '',
  externalReference: '',
};

export const implantDataState: IImplantData = {
  brandName: '',
  dataId: 0,
  diameter: '',
  length: '',
  modelName: '',
  partNumber: '',
  platformName: '',
  smartPeg: '',
  status: 0,
  type: '',
};

export const abutmentDataState: IAbutmentData = {
  ...implantDataState,
  angle: '',
};
