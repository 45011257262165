import React, { Component } from 'react';
import styles from './VideoTile.module.scss';

class VideoTile extends Component {
  render() {
    return (
      <div className={styles.tile} onClick={this.props.onClick}>
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: 'url(' + this.props.background + ')' }}
        >
          <div className={styles.icon}>
            <i>play</i>
          </div>
        </div>

        <div className={styles.title}>
          <p>{this.props.title}</p>
        </div>
      </div>
    );
  }
}

export default VideoTile;
