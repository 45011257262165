import { defineMessages } from 'react-intl';

function getFromTranslate(definedMessages) {
  return Object.keys(definedMessages).map((x) => ({
    key: x,
    value: definedMessages[x].defaultMessage,
    label: definedMessages[x],
  }));
}

const titlesTranslated = defineMessages({
  Dr: { id: 'referenceData.Dr', defaultMessage: 'Dr' },
  Prof: { id: 'referenceData.Prof', defaultMessage: 'Prof' },
  Mr: { id: 'referenceData.Mr', defaultMessage: 'Mr' },
  MRs: { id: 'referenceData.MRs', defaultMessage: 'Mrs' },
  Ms: { id: 'referenceData.Ms', defaultMessage: 'Ms' },
});
export const titles = getFromTranslate(titlesTranslated);

const professionsTranslated = defineMessages({
  OralSurgeon: { id: 'referenceData.OralSurgeon', defaultMessage: 'Oral Surgeon' },
  Periodontist: { id: 'referenceData.Periodontist', defaultMessage: 'Periodontist' },
  Prosthodontist: { id: 'referenceData.Prosthodontist', defaultMessage: 'Prosthodontist' },
  GeneralDentist: { id: 'referenceData.GeneralDentist', defaultMessage: 'General Dentist' },
  Coordinator: { id: 'referenceData.Coordinator', defaultMessage: 'Coordinator' },
  HeadNurse: { id: 'referenceData.HeadNurse', defaultMessage: 'Head Nurse' },
  Nurse: { id: 'referenceData.Nurse', defaultMessage: 'Nurse' },
  Other: { id: 'referenceData.Other', defaultMessage: 'Other' },
});
export const professions = getFromTranslate(professionsTranslated);

export const numberOfPractitioners = [
  { key: 'One', value: '1' },
  { key: 'TwoToFive', value: '2-5' },
  { key: 'SixToTen', value: '6-10' },
  { key: 'ElevenToTwenty', value: '11-20' },
  { key: 'MoreThanTwenty', value: '>20' },
];

const loadingProtocolsTranslated = defineMessages({
  ImmediateLoading: { id: 'referenceData.ImmediateLoading', defaultMessage: 'Immediate' },
  EarlyLoading: { id: 'referenceData.EarlyLoading', defaultMessage: 'Early' },
  ConventionalLoading: { id: 'referenceData.ConventionalLoading', defaultMessage: 'Conventional' },
});
export const loadingProtocols = getFromTranslate(loadingProtocolsTranslated);

const plannedProstheticSolutionsTranslated = defineMessages({
  Single: { id: 'referenceData.Single', defaultMessage: 'Single' },
  Partial: { id: 'referenceData.Partial', defaultMessage: 'Partial' },
  FullArch: { id: 'referenceData.FullArch', defaultMessage: 'Full Arch' },
});
export const plannedProstheticSolutions = getFromTranslate(plannedProstheticSolutionsTranslated);

const surgicalProtocolsTranslated = defineMessages({
  OneStage: { id: 'referenceData.OneStage', defaultMessage: 'One-Stage' },
  TwoStage: { id: 'referenceData.TwoStage', defaultMessage: 'Two-Stage' },
});
export const surgicalProtocols = getFromTranslate(surgicalProtocolsTranslated);

const patientRiskFactorsTranslated = defineMessages({
  peni: { id: 'referenceData.peni', defaultMessage: 'Penicillin allergy' },
  smoker: { id: 'referenceData.smoker', defaultMessage: 'Smoker' },
  brux: { id: 'referenceData.brux', defaultMessage: 'Bruxist' },
  irrad: { id: 'referenceData.irrad', defaultMessage: 'Irradiated bone' },
  steroid: { id: 'referenceData.steroid', defaultMessage: 'Chronic steroid use' },
  autoim: { id: 'referenceData.autoim', defaultMessage: 'Autoimmune disease' },
  diab: { id: 'referenceData.diab', defaultMessage: 'Diabetes' },
  chemo: { id: 'referenceData.chemo', defaultMessage: 'Chemotherapy' },
  osteo: { id: 'referenceData.osteo', defaultMessage: 'Osteoporosis' },
  osteotreat: { id: 'referenceData.osteotreat', defaultMessage: 'Osteoporosis treatment' },
  perio: { id: 'referenceData.perio', defaultMessage: 'Periodontal disease' },
  cardio: { id: 'referenceData.cardio', defaultMessage: 'Cardiovascular disesase' },
  anticoag: { id: 'referenceData.anticoag', defaultMessage: 'Anticoagulants' },
  recdrug: { id: 'referenceData.recdrug', defaultMessage: 'Recreational drug use' },
});
const patientRiskFactorsCategories = defineMessages({
  Medication: { id: 'referenceData.medication', defaultMessage: 'Medication' },
  Other: { id: 'referenceData.other', defaultMessage: 'Other' },
  Disease: { id: 'referenceData.disease', defaultMessage: 'Disease' },
});
const riskCategoryMaping = [
  { key: 'peni', category: 'Medication' },
  { key: 'smoker', category: 'Other' },
  { key: 'brux', category: 'Other' },
  { key: 'irrad', category: 'Disease' },
  { key: 'steroid', category: 'Medication' },
  { key: 'autoim', category: 'Disease' },
  { key: 'diab', category: 'Disease' },
  { key: 'chemo', category: 'Medication' },
  { key: 'osteo', category: 'Disease' },
  { key: 'osteotreat', category: 'Medication' },
  { key: 'perio', category: 'Disease' },
  { key: 'cardio', category: 'Disease' },
  { key: 'anticoag', category: 'Medication' },
  //{ key: 'marijuana', category: 'Other' },
  { key: 'recdrug', category: 'Other' },
];
export const patientRiskFactors = getFromTranslate(patientRiskFactorsTranslated).map((risk) => {
  const category = riskCategoryMaping.find((x) => risk.key === x.key).category;
  return {
    ...risk,
    category: category,
    categoryLabel: patientRiskFactorsCategories[category],
  };
});

export const implantsYear = [
  { key: 'ZeroToTen', value: '0-10' },
  { key: 'TenToFifty', value: '10-50' },
  { key: 'FiftyToHundred', value: '50-100' },
  { key: 'HundredToTwoHundred', value: '100-200' },
  { key: 'MoreThanTwoHundred', value: '>200' },
];

export const yearsOfExperience = [
  { key: 'ZeroToOne', value: '0-1' },
  { key: 'OneToThree', value: '1-3' },
  { key: 'ThreeToFive', value: '3-5' },
  { key: 'FiveToTen', value: '5-10' },
  { key: 'MoreThanTen', value: '>10' },
];

const prePlacementProceduresTranslated = defineMessages({
  NoProcedure: { id: 'referenceData.NoProcedure', defaultMessage: 'No Additional Procedure' },
  LateralAugmentation: {
    id: 'referenceData.LateralAugmentation',
    defaultMessage: 'Lateral Augmentation',
  },
  VerticalAugmentation: {
    id: 'referenceData.VerticalAugmentation',
    defaultMessage: 'Vertical Augmentation',
  },
  SinusLiftLateralApproach: {
    id: 'referenceData.SinusLiftLateralApproach',
    defaultMessage: 'Sinus Lift - Lateral Approach',
  },
  SinusLiftVerticalApproach: {
    id: 'referenceData.SinusLiftVerticalApproach',
    defaultMessage: 'Sinus Lift - Vertical Approach',
  },
  RidgeSplit: { id: 'referenceData.RidgeSplit', defaultMessage: 'Ridge split' },
  Other: { id: 'referenceData.Other', defaultMessage: 'Other' },
});
export const prePlacementProcedures = getFromTranslate(prePlacementProceduresTranslated);

const implantSitePlacementOptionsTranslated = defineMessages({
  HealedNativeBone: {
    id: 'referenceData.HealedNativeBone',
    defaultMessage: 'Healed site - Native bone',
  },
  HealedBoneGraft: {
    id: 'referenceData.HealedBoneGraft',
    defaultMessage: 'Healed site - bone graft',
  },
  ExtractionPlacement: {
    id: 'referenceData.ExtractionPlacement',
    defaultMessage: 'Extraction & Immediate placement',
  },
  ExtractionPlacementBoneGraft: {
    id: 'referenceData.ExtractionPlacementBoneGraft',
    defaultMessage: 'Extraction & immediate placement with bone graft',
  },
  RePlacement: { id: 'referenceData.RePlacement', defaultMessage: 'Implant re-placement' },
});
export const implantSitePlacementOptions = getFromTranslate(implantSitePlacementOptionsTranslated);

const augmentationMaterialOptionsTranslated = defineMessages({
  Xenograft: { id: 'referenceData.Xenograft', defaultMessage: 'Xenograft' },
  Allograft: { id: 'referenceData.Allograft', defaultMessage: 'Allograft' },
  Autologous: { id: 'referenceData.Autologous', defaultMessage: 'Autologous' },
  Alloplastic: { id: 'referenceData.Alloplastic', defaultMessage: 'Alloplastic' },
  GrowthFactors: { id: 'referenceData.GrowthFactors', defaultMessage: 'Growth Factors' },
  Other: { id: 'referenceData.Other', defaultMessage: 'Other' },
});
export const augmentationMaterialOptions = getFromTranslate(augmentationMaterialOptionsTranslated);

const surgicalTechniqueOptionsTranslated = defineMessages({
  SocketShield: { id: 'referenceData.SocketShield', defaultMessage: 'Socket shield' },
  Other: { id: 'referenceData.Other', defaultMessage: 'Other' },
});
export const surgicalTechniqueOptions = getFromTranslate(surgicalTechniqueOptionsTranslated);

const implantFailureTypesTranslated = defineMessages({
  EarlyFailure: { id: 'referenceData.EarlyFailure', defaultMessage: 'Early Failure' },
  LateFailure: { id: 'referenceData.LateFailure', defaultMessage: 'Late Failure' },
});
export const implantFailureTypes = getFromTranslate(implantFailureTypesTranslated);

const implantReasonForFailureTranslated = defineMessages({
  ComponentFracture: {
    id: 'referenceData.ComponentFracture',
    defaultMessage: 'Component Fracture',
  },
  ImplantFracture: { id: 'referenceData.ImplantFracture', defaultMessage: 'Implant Fracture' },
  Infection: { id: 'referenceData.Infection', defaultMessage: 'Infection' },
  NoOsseointegration: {
    id: 'referenceData.NoOsseointegration',
    defaultMessage: 'No Osseointegration',
  },
  Overloading: { id: 'referenceData.Overloading', defaultMessage: 'Overloading' },
  PeriImplantDisease: {
    id: 'referenceData.PeriImplantDisease',
    defaultMessage: 'Peri-implant disease',
  },
  Unknown: { id: 'referenceData.Unknown', defaultMessage: 'Unknown' },
});
export const implantReasonForFailure = getFromTranslate(implantReasonForFailureTranslated);

export const kpiParameters = defineMessages({
  ActivePatients: {
    id: 'referenceData.ActivePatients',
    defaultMessage: 'Active Patients',
  },
  TotalPatients: {
    id: 'referenceData.TotalPatients',
    defaultMessage: 'Total Patients',
  },
  SuccessRate: {
    id: 'referenceData.SuccessRate',
    defaultMessage: 'Success Rate',
  },
  TotalPlacements: {
    id: 'referenceData.TotalPlacements',
    defaultMessage: 'Total Placements',
  },
  AverageHealingTime: {
    id: 'referenceData.AverageHealingTime',
    defaultMessage: 'Average Healing Time',
  },
  TotalMeasurements: {
    id: 'referenceData.TotalMeasurements',
    defaultMessage: 'Total Measurements',
  },
});

export const otherParameters = defineMessages({
  NoSelection: {
    id: 'referenceData.NoSelection',
    defaultMessage: 'No Selection',
  },
});

export const errors = defineMessages({
  InvalidRegistrationCodeLength: {
    id: 'InvalidRegistrationCodeLength',
    defaultMessage: 'The registration code has not correct length',
  },
  AlertLoggedOutTimeOut: { id: 'AlertLoggedOutTimeOut', defaultMessage: 'Logged out' },
  AlertLoggedOutToken: { id: 'AlertLoggedOutToken', defaultMessage: 'Logged out' },
  InvalidEmailFormat: { id: 'InvalidEmailFormat', defaultMessage: 'Invalid Email Format' },
  InvalidPasswordFormat: { id: 'InvalidPasswordFormat', defaultMessage: 'Invalid Password Format' },
  InvalidPasswordTooShort: { id: 'InvalidPasswordTooShort', defaultMessage: 'Password Too Short' },
  InvalidPasswordTooLong: { id: 'InvalidPasswordTooLong', defaultMessage: 'Password Too Long  ' },
  RequiredEula: { id: 'RequiredEula', defaultMessage: 'Please accept the Terms and Conditions' },
  WrongPasswordOrEmail: {
    id: 'WrongPasswordOrEmail',
    defaultMessage: 'Wrong password or username',
  },
  IsLockedOut: {
    id: 'IsLockedOut',
    defaultMessage: 'Too many login attempts, wait 5 minutes before trying again',
  },
  IsLockedOutReAuth: {
    id: 'IsLockedOutReAuth',
    defaultMessage: 'Wrong password too many times, wait 5 minutes before trying again',
  },
  WrongPasswordReAuth: {
    id: 'WrongPasswordReAuth',
    defaultMessage: 'Wrong password',
  },
  InternalServerError: {
    id: 'InternalServerError',
    defaultMessage: 'Internal server error, wait 5 minutes before trying again',
  },
  RepeatedPasswordNotSame: {
    id: 'RepeatedPasswordNotSame',
    defaultMessage: 'Repeated password is not the same as password',
  },
  EmailAlreadyRegistered: {
    id: 'EmailAlreadyRegistered',
    defaultMessage: 'Email already registered',
  },
  EmailDontExist: {
    id: 'EmailDontExist',
    defaultMessage: 'The email does not exist',
  },
  ResetLinkNotValidAnyMore: {
    id: 'ResetLinkNotValidAnyMore',
    defaultMessage: 'The reset link is no longer valid',
  },
  UserIsAlreadyAMember: {
    id: 'UserIsAlreadyAMember',
    defaultMessage: 'User is already a member',
  },
});
