import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import history from './history';
import { getWindowWidth } from './utils';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_APPLICATION_INSIGHT_KEY, //OsstellConnectWEB
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: history },
    },
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
//appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview

// Tracking functions
const trackWhichDeviceLoggedIn = (email) => {

  var device;
  if (window.ReactNativeWebView) {
    if (getWindowWidth() >= 768) {
      device = 'Tablet';
    } else {
      device = 'Mobile';
    }
  } else {
    device = 'Browser';
  }

  appInsights.trackEvent({
    name: 'WhichDeviceLoggedIn',
    properties: {
      device: device,
      email: email,
    },
  });
};

export { reactPlugin, appInsights, trackWhichDeviceLoggedIn };
