import * as types from '../../constants/actionTypes';
import readModelHub from '../../utils/readModelHub';
import ApiFetch from '../../utils/apiFetch';

/* ----------------------------------------------------------------------- */

function getPatientsRequest() {
  return {
    type: types.GET_PATIENTS,
  };
}

function getPatientsSuccess(data) {
  return {
    type: types.GET_PATIENTS_SUCCESS,
    patients: data,
  };
}

function getPatientsFailed(error) {
  return {
    type: types.GET_PATIENTS_ERROR,
    error,
  };
}

export function getPatients() {
  return (dispatch) => {
    dispatch(getPatientsRequest());

    ApiFetch.get(`patients`)
      .then((data) => {
        dispatch(getPatientsSuccess(data));
      })
      .catch((error) => {
        dispatch(getPatientsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function getPatientRequest(patientId) {
  return {
    type: types.GET_PATIENT,
    patientId,
  };
}

function getPatientSuccess(data) {
  return {
    type: types.GET_PATIENT_SUCCESS,
    patient: data,
  };
}

function getPatientFailed(error) {
  return {
    type: types.GET_PATIENT_ERROR,
    error,
  };
}

export function getPatient(userGroupId, patientId) {
  return (dispatch) => {
    dispatch(getPatientRequest());
    ApiFetch.get(`usergroup/${userGroupId}/patients/${patientId}`)
      .then((data) => {
        dispatch(getPatientSuccess(data));
      })
      .catch((error) => {
        dispatch(getPatientFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function deletePatientRequest(patientId) {
  return {
    type: types.DELETE_PATIENT,
    patientId,
  };
}

function deletePatientSuccess() {
  return {
    type: types.DELETE_PATIENT_SUCCESS,
  };
}

function deletePatientFailed(error) {
  return {
    type: types.DELETE_PATIENT_ERROR,
    error,
  };
}

export function deletePatient(userGroupId, patientId) {
  return (dispatch) => {
    dispatch(deletePatientRequest());

    ApiFetch.delete(`usergroup/${userGroupId}/patients/${patientId}`)
      .then((_) => {
        dispatch(deletePatientSuccess());
      })
      .catch((error) => {
        dispatch(deletePatientFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function createPatientRequest() {
  return {
    type: types.CREATE_PATIENT,
  };
}

function createPatientSuccess(data) {
  return {
    type: types.CREATE_PATIENT_SUCCESS,
    newPatient: data,
  };
}

function createPatientFailed(error) {
  return {
    type: types.CREATE_PATIENT_ERROR,
    error,
  };
}

export function createPatient(
  userGroupId,
  patientNumber,
  firstName,
  lastName,
  attrib,
  dateOfBirth,
  gender,
  comment,
  referralId,
  oralHygiene
) {
  return (dispatch) => {
    dispatch(createPatientRequest());

    const request = {
      patientNumber: patientNumber,
      firstName: firstName,
      lastName: lastName,
      attrib: attrib,
      dateOfBirth: dateOfBirth,
      gender: gender,
      comment: comment,
      referralId: referralId,
      oralHygiene: oralHygiene,
    };

    ApiFetch.post(`usergroup/${userGroupId}/patients`, request)
      .then((data) => {
        dispatch(createPatientSuccess(data));
      })
      .catch((error) => {
        dispatch(createPatientFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function updatePatientRequest(patientId) {
  return {
    type: types.UPDATE_PATIENT,
    patientId,
  };
}

function updatePatientSuccess(_) {
  return {
    type: types.UPDATE_PATIENT_SUCCESS,
  };
}

function updatePatientFailed(error) {
  return {
    type: types.UPDATE_PATIENT_ERROR,
    error,
  };
}

export function updatePatient(
  userGroupId,
  patientId,
  patientNumber,
  firstName,
  lastName,
  attrib,
  dateOfBirth,
  gender,
  comment,
  referralId,
  oralHygiene
) {
  return (dispatch) => {
    dispatch(updatePatientRequest());

    const request = {
      patientNumber: patientNumber,
      firstName: firstName,
      lastName: lastName,
      attrib: attrib,
      dateOfBirth: dateOfBirth,
      gender: gender,
      comment: comment,
      referralId: referralId,
      oralHygiene: oralHygiene,
    };

    ApiFetch.put(`usergroup/${userGroupId}/patients/${patientId}`, request)
      .then((data) => {
        dispatch(updatePatientSuccess(data));
      })
      .catch((error) => {
        dispatch(updatePatientFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function getPatientImplantsRequest() {
  return {
    type: types.GET_PATIENT_IMPLANTS,
  };
}

function getPatientImplantsSuccess(data) {
  return {
    type: types.GET_PATIENT_IMPLANTS_SUCCESS,
    implants: data,
  };
}

function getPatientImplantsFailed(error) {
  return {
    type: types.GET_PATIENT_IMPLANTS_ERROR,
    error,
  };
}

export function getPatientImplants(userGroupId, patientId) {
  return (dispatch) => {
    dispatch(getPatientImplantsRequest());

    ApiFetch.get(`usergroup/${userGroupId}/patients/${patientId}/implants`)
      .then((data) => {
        dispatch(getPatientImplantsSuccess(data));
      })
      .catch((error) => {
        dispatch(getPatientImplantsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function getPatientDentalChartRequest() {
  return {
    type: types.GET_PATIENT_DENTAL_CHART,
  };
}

function getPatientDentalChartSuccess(data) {
  return {
    type: types.GET_PATIENT_DENTAL_CHART_SUCCESS,
    dentalChart: data,
  };
}

function getPatientDentalChartFailed(error) {
  return {
    type: types.GET_PATIENTS_DENTAL_CHART_ERROR,
    error,
  };
}

export function getPatientDentalChart(userGroupId, patientId) {
  return (dispatch) => {
    dispatch(getPatientDentalChartRequest());

    ApiFetch.get(`usergroup/${userGroupId}/patients/${patientId}/dentalchart`)
      .then((data) => {
        dispatch(getPatientDentalChartSuccess(data));
      })
      .catch((error) => {
        dispatch(getPatientDentalChartFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function searchPatientsRequest() {
  return {
    type: types.SEARCH_PATIENTS,
  };
}

function searchPatientsSuccess(data) {
  return {
    type: types.SEARCH_PATIENTS_SUCCESS,
    patients: data,
  };
}

function searchPatientsFailed(error) {
  return {
    type: types.SEARCH_PATIENTS_ERROR,
    error,
  };
}

export function searchPatients(searchText) {
  return (dispatch) => {
    dispatch(searchPatientsRequest());

    ApiFetch.get(`patients/search/${searchText}`)
      .then((data) => {
        dispatch(searchPatientsSuccess(data));
      })
      .catch((error) => {
        dispatch(searchPatientsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

export function exportPatients(callback) {
  return () => {
    ApiFetch.downloadFile(`patients/export`, callback);
  };
}

/* ----------------------------------------------------------------------- */

function exportPatientRequest() {
  return {
    type: types.EXPORT_PATIENT,
  };
}

function exportPatientSuccess() {
  return {
    type: types.EXPORT_PATIENT_SUCCESS,
  };
}

function exportPatientFailed(error) {
  return {
    type: types.EXPORT_PATIENT_ERROR,
    error,
  };
}

export function exportPatient(patientIdentifier, html, callback) {
  return (dispatch) => {
    dispatch(exportPatientRequest());

    const request = {
      html: html,
      patientIdentifier: patientIdentifier,
    };
    ApiFetch.downloadFile(`patient/export`, callback, request)
      .then((_) => {
        dispatch(exportPatientSuccess());
      })
      .catch((error) => {
        dispatch(exportPatientFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

export function selectPatientImplant(tooth) {
  return (dispatch) => {
    dispatch({
      type: types.SELECT_PATIENT_IMPLANT,
      selectedPatientImplant: tooth,
    });
  };
}

/* ----------------------------------------------------------------------- */

function createMeasurementRequest() {
  return {
    type: types.CREATE_MEASUREMENT,
  };
}

function createMeasurementSuccess(_) {
  return {
    type: types.CREATE_MEASUREMENT_SUCCESS,
  };
}

function createMeasurementFailed(error) {
  return {
    type: types.CREATE_MEASUREMENT_ERROR,
    error,
  };
}

export function createMeasurement(
  userGroupId,
  patientId,
  tooth,
  isqBl,
  isqMd,
  type,
  level,
  timeStamp,
  instrumentId,
  measurementId,
  loadingProtocol,
  surgicalProtocol,
  insertionTorque,
  smartPegLot,
  measurements
) {
  return (dispatch) => {
    dispatch(createMeasurementRequest());

    const request = {
      tooth: tooth,
      isqBl: isqBl,
      isqMd: isqMd,
      type: type,
      level: level,
      timeStamp: timeStamp,
      instrumentId: instrumentId,
      measurementId: measurementId,
      loadingProtocol: loadingProtocol,
      surgicalProtocol: surgicalProtocol,
      insertionTorque: insertionTorque,
      smartPegLot: smartPegLot,
      measurements: measurements,
    };

    ApiFetch.post(`usergroup/${userGroupId}/patients/${patientId}/measurements`, request)
      .then((data) => {
        dispatch(createMeasurementSuccess(data));
      })
      .catch((error) => {
        dispatch(createMeasurementFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function deleteMeasurementRequest() {
  return {
    type: types.DELETE_MEASUREMENT,
  };
}

function deleteMeasurementSuccess() {
  return {
    type: types.DELETE_MEASUREMENT_SUCCESS,
  };
}

function deleteMeasurementFailed(error) {
  return {
    type: types.DELETE_MEASUREMENT_ERROR,
    error,
  };
}

export function deleteMeasurement(userGroupId, patientId, tooth, measurementId, measurements) {
  return (dispatch) => {
    dispatch(deleteMeasurementRequest());

    const request = {
      tooth: tooth,
      measurementId: measurementId,
      measurements: measurements,
    };

    ApiFetch.delete(`usergroup/${userGroupId}/patients/${patientId}/measurements`, request)
      .then((data) => {
        dispatch(deleteMeasurementSuccess(data));
      })
      .catch((error) => {
        dispatch(deleteMeasurementFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function createImplantRequest() {
  return {
    type: types.CREATE_IMPLANT,
  };
}

function createImplantSuccess(_) {
  return {
    type: types.CREATE_IMPLANT_SUCCESS,
  };
}

function createImplantFailed(error) {
  return {
    type: types.CREATE_IMPLANT_ERROR,
    error,
  };
}

export function createImplant(
  userGroupId,
  patientId,
  tooth,
  //abutmentId,
  comment,
  //dataId,
  loadingProtocol,
  plannedProstheticSolution,
  surgicalProtocol,
  insertionTorque,
  prePlacementProcedure,
  boneQuality,
  implantSitePlacement,
  augmentationMaterial,
  surgicalTechnique,
  studyGroups
) {
  return (dispatch) => {
    dispatch(createImplantRequest());

    const request = {
      tooth: tooth,
      //abutmentId: abutmentId,
      comment: comment,
      //dataId: dataId,
      loadingProtocol: loadingProtocol,
      plannedProstheticSolution: plannedProstheticSolution,
      surgicalProtocol: surgicalProtocol,
      insertionTorque: insertionTorque,
      prePlacementProcedure: prePlacementProcedure,
      boneQuality: boneQuality,
      implantSitePlacement: implantSitePlacement,
      augmentationMaterial: augmentationMaterial,
      surgicalTechnique: surgicalTechnique,
      studyGroups: studyGroups,
    };

    ApiFetch.post(`usergroup/${userGroupId}/patients/${patientId}/implants`, request)
      .then((data) => {
        dispatch(createImplantSuccess(data));
      })
      .catch((error) => {
        dispatch(createImplantFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */
function deleteImplantRequest() {
  return {
    type: types.DELETE_IMPLANT,
  };
}

function deleteImplantSuccess() {
  return {
    type: types.DELETE_IMPLANT_SUCCESS,
  };
}

function deleteImplantFailed(error) {
  return {
    type: types.DELETE_IMPLANT_ERROR,
    error,
  };
}

export function deleteImplant(userGroupId, patientId, tooth) {
  return (dispatch) => {
    dispatch(deleteImplantRequest());

    const request = {
      tooth: tooth,
    };

    ApiFetch.delete(`usergroup/${userGroupId}/patients/${patientId}/implants`, request)
      .then((data) => {
        dispatch(deleteImplantSuccess(data));
      })
      .catch((error) => {
        dispatch(deleteImplantFailed(error));
      });
  };
}
/* ----------------------------------------------------------------------- */

function setImplantFailedRequest() {
  return {
    type: types.SET_IMPLANT_FAILED,
  };
}

function setImplantFailedSuccess() {
  return {
    type: types.SET_IMPLANT_FAILED_SUCCESS,
  };
}

function setImplantFailedFailed(error) {
  return {
    type: types.SET_IMPLANT_FAILED_ERROR,
    error,
  };
}

export function setImplantFailed(userGroupId, patientId, tooth, date, type, reason) {
  return (dispatch) => {
    dispatch(setImplantFailedRequest());

    const request = {
      tooth: tooth,
      date: date,
      type: type,
      reason: reason,
    };

    ApiFetch.put(`usergroup/${userGroupId}/patients/${patientId}/implants/failed`, request)
      .then((data) => {
        dispatch(setImplantFailedSuccess(data));
      })
      .catch((error) => {
        dispatch(setImplantFailedFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function setImplantDataRequest() {
  return {
    type: types.SET_IMPLANT_DATA,
  };
}

function setImplantDataSuccess(_) {
  return {
    type: types.SET_IMPLANT_DATA_SUCCESS,
  };
}

function setImplantDataFailed(error) {
  return {
    type: types.SET_IMPLANT_DATA_ERROR,
    error,
  };
}

export function setImplantData(
  userGroupId,
  patientId,
  tooth,
  dataId,
  abutmentId,
  implantLot,
  abutmentLot
) {
  return (dispatch) => {
    dispatch(setImplantDataRequest());

    const request = {
      tooth: tooth,
      dataId: dataId,
      abutmentId: abutmentId,
      implantLot: implantLot,
      abutmentLot: abutmentLot,
    };

    ApiFetch.put(`usergroup/${userGroupId}/patients/${patientId}/implants/implant`, request)
      .then((data) => {
        dispatch(setImplantDataSuccess(data));
      })
      .catch((error) => {
        dispatch(setImplantDataFailed(error));
      });
  };
}
/* ----------------------------------------------------------------------- */

function getUsedImplantsRequest() {
  return {
    type: types.GET_USED_IMPLANTS,
  };
}

function getUsedImplantsSuccess(data) {
  return {
    type: types.GET_USED_IMPLANTS_SUCCESS,
    implants: data.implants,
    abutments: data.abutments,
  };
}

function getUsedImplantsFailed(error) {
  return {
    type: types.GET_USED_IMPLANTS_ERROR,
    error,
  };
}

export function getUsedImplants() {
  return (dispatch) => {
    dispatch(getUsedImplantsRequest());

    ApiFetch.get('usedImplants')
      .then((data) => {
        dispatch(getUsedImplantsSuccess(data));
      })
      .catch((error) => {
        dispatch(getUsedImplantsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function restoreDemoPatientsRequest() {
  return {
    type: types.RESTORE_DEMO_PATIENTS,
  };
}

function restoreDemoPatientsSuccess() {
  return {
    type: types.RESTORE_DEMO_PATIENTS_SUCCESS,
  };
}

function restoreDemoPatientsFailed(error) {
  return {
    type: types.RESTORE_DEMO_PATIENTS_ERROR,
    error,
  };
}

export function restoreDemoPatients(userGroupId) {
  return (dispatch) => {
    dispatch(restoreDemoPatientsRequest());

    ApiFetch.post(`usergroup/${userGroupId}/patients/demo`)
      .then((data) => {
        dispatch(restoreDemoPatientsSuccess(data));
      })
      .catch((error) => {
        dispatch(restoreDemoPatientsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

export function subscribeToPatientsChange(user) {
  return (dispatch) => {
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Subscribing to patients (subscribeToPatientsChange) userGroupId:' + userGroup.id
      );
      readModelHub.subscribe(
        'patient',
        userGroup.id,
        () => {
          dispatch(getPatients());
        },
        500
      );
    });
  };
}

export function unsubscribeFromPatientsChange(user) {
  return () => {
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Unsubscribing from patients (unsubscribeFromPatientsChange) userGroupId:' +
          userGroup.id
      );
      readModelHub.unsubscribe('patient', userGroup.id);
    });
  };
}

export function subscribeToPatientChange(userId, userGroupId, patientId) {
  console.log(
    'SignalR: Subscribing to patientdetails (subscribeToPatientChange) userId:' +
      userId +
      ' patientId: ' +
      patientId
  );

  return (dispatch) => {
    readModelHub.subscribe(
      'patient',
      patientId,
      () => {
        dispatch(getPatient(userGroupId, patientId));
      },
      500
    );
  };
}

export function unsubscribeFromPatientChange(patientId) {
  console.log(
    'SignalR: Unsubscribing from patientdetails (unsubscribeFromPatientChange) patientId: ' +
      patientId
  );
  return () => {
    readModelHub.unsubscribe('patient', patientId);
  };
}

export function subscribeToPatientDentalChartChange(userId, userGroupId, patientId) {
  console.log(
    'SignalR: Subscribing to patientdentalchart (subscribeToPatientDentalChartChange) userId:' +
      userId +
      ' patientId: ' +
      patientId
  );

  return (dispatch) => {
    readModelHub.subscribe(
      'implant',
      patientId,
      () => {
        dispatch(getPatientDentalChart(userGroupId, patientId));
      },
      500
    );
    readModelHub.subscribe(
      'measurement',
      patientId,
      () => {
        dispatch(getPatientDentalChart(userGroupId, patientId));
      },
      500
    );
  };
}

export function unsubscribeFromPatientDentalChartChange(patientId) {
  console.log(
    'SignalR: Unsubscribing from patientdentalchart (unsubscribeFromPatientDentalChartChange) patientId: ' +
      patientId
  );
  return () => {
    readModelHub.unsubscribe('implant', patientId);
    readModelHub.unsubscribe('measurement', patientId);
  };
}

export function subscribeToPatientImplantsChange(userId, userGroupId, patientId) {
  console.log(
    'SignalR: Subscribing to patientimplants (subscribeToPatientImplantsChange) userId:' +
      userId +
      ' patientId: ' +
      patientId
  );

  return (dispatch) => {
    readModelHub.subscribe(
      'implant',
      patientId,
      () => {
        dispatch(getPatientImplants(userGroupId, patientId));
      },
      500
    );
  };
}

export function unsubscribeFromPatientImplantsChange(patientId) {
  console.log(
    'SignalR: Unsubscribing from patientimplants (unsubscribeFromPatientImplantsChange) patientId: ' +
      patientId
  );
  return () => {
    readModelHub.unsubscribe('implant', patientId);
  };
}
