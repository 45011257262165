import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function usePromotionRequest(promotionId) {
  return {
    type: types.USE_PROMOTION,
    promotionId,
  };
}

function usePromotionSuccess(promotionId) {
  return {
    type: types.USE_PROMOTION_SUCCESS,
    promotionId,
  };
}

function usePromotionFailed(error) {
  return {
    type: types.USE_PROMOTION_ERROR,
    error,
  };
}

export function getFreeSmartPegs(promotionId, order) {
  return (dispatch) => {
    dispatch(usePromotionRequest(promotionId));

    ApiFetch.put(`promotion/smartpegs/${promotionId}`, order)
      .then((_) => {
        dispatch(usePromotionSuccess(promotionId));
      })
      .catch((error) => {
        dispatch(usePromotionFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function registerPromotionRequest() {
  return {
    type: types.REGISTER_PROMOTION,
  };
}

function registerPromotionSuccess() {
  return {
    type: types.REGISTER_PROMOTION_SUCCESS,
  };
}

function registerPromotionFailed(error) {
  return {
    type: types.REGISTER_PROMOTION_ERROR,
    error,
  };
}

export function registerPromotion(description, quantity, callback) {
  return (dispatch) => {
    dispatch(registerPromotionRequest());

    const request = {
      description: description,
      quantity: quantity,
    };

    ApiFetch.post(`promotion/smartpegs`, request)
      .then((data) => {
        dispatch(registerPromotionSuccess(data));

        if (callback) {
          callback(data.promotion);
        }

        if (data.promotion) dispatch({ type: types.ADD_USER_PROMOTION, promotion: data.promotion });
      })
      .catch((error) => {
        dispatch(registerPromotionFailed(error));
      });
  };
}
