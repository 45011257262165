import { FC } from 'react';
import { IUser } from '../../types/user';
import { useIntl } from 'react-intl';
import { IPatient } from '../../types/patient';
import { showAlert } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../utils/hooks';
import Header from '../../components/header/Header';

interface Props {
  patients: IPatient[];
  user: IUser;
  exportingPatients: boolean;
  restoreDemoPatientsBusy: boolean;
  exportPatientsHandler: () => void;
  restoreDemoPatients: () => void;
}

const PatientsListHeader: FC<Props> = ({
  patients,
  user,
  exportingPatients,
  restoreDemoPatientsBusy,
  exportPatientsHandler,
  restoreDemoPatients,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const createPatientHandler = () => {
    if (user.hasInstrumentPendingUpdates) {
      const content = (
        <div>
          <p>
            {intl.formatMessage({
              id: 'idxMessage.inOrderToUse',
              defaultMessage:
                'In order to use this functionality you need to upgrade your IDx to the latest software.',
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'idxMessage.forMoreInformation',
              defaultMessage: 'For more information on how to update your IDx please follow:',
            })}
          </p>
          <a
            href="https://www.osstell.com/wp-content/uploads/2015/03/Software-Upgrade-6.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({
              id: 'idxMessage.howToUpgrade',
              defaultMessage: 'How to upgrade',
            })}
          </a>
        </div>
      );
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'idxMessage.upgradeAvailable',
            defaultMessage: 'IDx Upgrade available',
          }),
          content
        )
      );
      return;
    }

    if (process.env.REACT_APP_DEMO && patients && patients.length >= 25) {
      const content = (
        <div>
          <p>
            {intl.formatMessage({
              id: 'patientList.demoLimitOfPatients',
              defaultMessage:
                'You have reached the limit of how many patients that are supported in the demo site.',
            })}
          </p>
        </div>
      );
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'patientList.demoLimitation',
            defaultMessage: 'DEMO Limitation',
          }),
          content
        )
      );
      return;
    }

    if (user.sourceUserGroup.id)
      return history.push(`usergroup/${user.sourceUserGroup.id}/patients/new/patientinformation`);
  };

  const headerButtonsLeft = [
    {
      label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
      icon: 'back',
      action: history.goBack,
    },
  ];

  const headerButtonsRight = [
    {
      label: intl.formatMessage({ id: 'patientList.addNew', defaultMessage: 'Add new' }),
      icon: 'add',
      action: createPatientHandler,
    },
    {
      label: intl.formatMessage({ id: 'patientList.export', defaultMessage: 'Export' }),
      icon: 'csv',
      action: exportPatientsHandler,
      loading: exportingPatients,
      disabled: false,
    },
  ];

  if (process.env.REACT_APP_DEMO) {
    headerButtonsRight.push({
      label: intl.formatMessage({
        id: 'patientList.resetDemo',
        defaultMessage: 'Reset Demo',
      }),
      icon: 'reset',
      action: restoreDemoPatients,
      loading: restoreDemoPatientsBusy,
      disabled: restoreDemoPatientsBusy,
    });
  }

  return (
    <Header
      title={intl.formatMessage({ id: 'patientList.Header', defaultMessage: 'PATIENTS' })}
      leftButtons={headerButtonsLeft}
      rightButtons={headerButtonsRight}
    />
  );
};

export default PatientsListHeader;
