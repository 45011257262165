import { FC, useEffect, useState } from 'react';
import { H2 } from '../../components/Shared/Headings';
import { showVideo } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../utils/hooks';
import { FormattedMessage } from 'react-intl';
import ReactPlayer from 'react-player';
import styles from './HomePage.module.scss';
interface Props {
  videoUrl: string;
  campaignVideoUrl: string;
}

const CampaignSection: FC<Props> = ({ videoUrl, campaignVideoUrl }) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 750);
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const checkDeviceWidth = () =>
    !isSmallScreen && dispatch(showVideo(campaignVideoUrl, 'campaignAd2'));

  return (
    <div style={{ marginTop: '3rem' }}>
      <H2>
        <FormattedMessage
          id="home.campaignIntro"
          defaultMessage="Export a visual treatment plan to support your patient"
        />
      </H2>
      <div style={{ cursor: 'pointer' }} onClick={checkDeviceWidth}>
        <ReactPlayer
          className={styles.campaignVideo}
          playing={!isSmallScreen}
          muted={!isSmallScreen}
          url={campaignVideoUrl}
          height="100%"
          width="100%"
          controls
        />
      </div>
      <div>
        <div style={{ marginBottom: '0.6rem' }}>
          Uncover user-friendly treatment visualization with the{' '}
          <span
            className={styles.textLink}
            onClick={() => dispatch(showVideo(videoUrl, 'ConsultationTool'))}
          >
            ConsultationTool
          </span>{' '}
        </div>
        <div>
          Dive into OsstellConnect with other helpful{' '}
          <span className={styles.textLink} onClick={() => history.push('/help')}>
            instruction videos
          </span>
        </div>
      </div>
    </div>
  );
};

export default CampaignSection;
