import * as React from 'react';

function SvgDrawFreehand(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 19.612 19.612">
      <path
        d="M4634.11,5162.688s.66-3.589,3.589-3.589,3.29,2.853,3.449,3.782.918,3.352,2.938,3.34,2.929-2.309,3.2-3.456a2.748,2.748,0,0,1,2.6-2.339c1.914.066,2.632,2.368,2.632,2.368"
        transform="translate(-6924.079 -357.413) rotate(-45)"
        fill="none"
        strokeLinecap="round"
        strokeWidth="1"
      />
    </svg>
  );
}

export default SvgDrawFreehand;
