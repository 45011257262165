import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { useIntl, FormattedMessage } from 'react-intl';

import { resetPassword } from '../../redux/actions/accountActions';

import styles from './ResetPasswordPage.module.scss';

import { InputItem } from '../../components/Shared/Forms';
import { Button } from '../../components/Shared/Buttons';
import { AlertBox } from '../../components/Shared/Alerts';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';

function ResetPassword() {
  const dispatch = useDispatch();
  const intl = useIntl();
  const history = useHistory();
  let query = useQuery();
  const code = query.get('code');

  !code && history.push('/login');

  const resetPasswordStatus = useSelector((state) => state.account.resetPassword);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');

  function renderComplete() {
    return (
      <div>
        <h1 className={styles.h1}>
          <FormattedMessage
            id="resetPassword.ResetComplete"
            defaultMessage="Password reset complete"
          />
        </h1>
        <p className={styles.p}>
          <FormattedMessage
            id="resetPassword.clickToLogin"
            defaultMessage="Click to login with new password"
          />
        </p>
        <Button
          primary
          big
          full
          value={intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' })}
          onClick={() => history.push('/login')}
        />
      </div>
    );
  }

  function onResetPassword() {
    if (password === passwordRepeat && code) {
      dispatch(resetPassword(email, password, passwordRepeat, code));
    } else {
      //TODO Show validation error
      console.log('Error');
    }
  }

  return (
    <div className={styles.resetPage}>
      <KeyHandler keyEventName={KEYPRESS} keyValue="Enter" onKeyHandle={onResetPassword} />
      <div className={styles.resetPanelWrapper}>
        <div className={styles.resetPanel}>
          <div className={styles.logo}>
            <Logo />
          </div>
          {resetPasswordStatus.complete ? (
            renderComplete()
          ) : (
            <div>
              <h1 className={styles.h1}>
                <FormattedMessage
                  id="resetPassword.ResetPassword"
                  defaultMessage="Reset password"
                />
              </h1>
              <p className={styles.p}>
                <FormattedMessage
                  id="resetPassword.ResetInstruction"
                  defaultMessage="Please enter your e-mail and set a new password for your account."
                />
              </p>
              {resetPasswordStatus.error ? (
                <AlertBox
                  headerText={intl.formatMessage({
                    id: 'general.generalError',
                    defaultMessage: 'Oops, there was an error',
                  })}
                  messages={resetPasswordStatus.error.messages.map((m) => m.text)}
                />
              ) : null}
              <div className={styles.inputGroup}>
                <InputItem
                  label={intl.formatMessage({ id: 'general.email', defaultMessage: 'Email' })}
                  type="text"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                  onKeyPress={(evt) => evt.key === 'Enter' && onResetPassword()}
                />
                <div className={styles.inputRow}>
                  <InputItem
                    label={intl.formatMessage({
                      id: 'general.password',
                      defaultMessage: 'Password',
                    })}
                    type="password"
                    value={password}
                    onChange={(evt) => setPassword(evt.target.value)}
                    onKeyPress={(evt) => evt.key === 'Enter' && onResetPassword()}
                  />
                  <InputItem
                    label="Repeat password"
                    type="password"
                    value={passwordRepeat}
                    onChange={(evt) => setPasswordRepeat(evt.target.value)}
                    onKeyPress={(evt) => evt.key === 'Enter' && onResetPassword()}
                  />
                </div>
              </div>
              <Button
                primary
                big
                full
                value={intl.formatMessage({ id: 'general.reset', defaultMessage: 'Reset' })}
                onClick={onResetPassword}
                disabled={
                  !email || !password || password !== passwordRepeat || resetPasswordStatus.busy
                }
                loading={resetPasswordStatus.busy}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ResetPassword;
