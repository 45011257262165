import { FC } from 'react';
import { Bone } from '../../../../../../redux/slices/data-structures/workspaceStructure';
import { useAppSelector } from '../../../../../../utils/hooks';
import { ZoneWidthsUpper } from '../ToothInteractionZone';

const SvgTeethNoseSinusGraftShape: FC = () => {
  const boneState = useAppSelector((state) => state.consultation.workspace.present.boneState);
  const startPosition = 5;
  const depths = boneState
    .filter((_: never, index: number) => index >= startPosition && index < startPosition + 6)
    .map((bone: Bone) => bone.sinusGraft);
  return (
    <g transform={'translate(0 100)'}>
      {depths.map((depth: number, index: number) => {
        const x = ZoneWidthsUpper.slice(startPosition, index + startPosition).reduce(
          (acc, current) => acc + current,
          -6
        );
        return (
          <rect
            x={x}
            y={90 - depth * 40}
            width={ZoneWidthsUpper[index + startPosition]}
            height={200}
            rx="28"
            key={`graftNose_${index}`}
          />
        );
      })}
    </g>
  );
};
export default SvgTeethNoseSinusGraftShape;
