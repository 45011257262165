import { useRef, useEffect, useState } from 'react';
import { Tab, Tabs as TabsComponent, TabList as TL } from 'react-tabs';
import styles from './Tabs.module.scss';
import { getWindowWidth } from '../../../utils/utils';

const TabsWithIconsOnMobile = ({
  children,
  textTabs,
  iconTabs,
  breakPoint,
  className,
  selectedTabPanelClassName,
  onChange,
}) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [width, setWidth] = useState(0);
  const isMounted = useRef(true);

  const shouldRenderTextTabs = width > breakPoint;

  const selectTab = (selectedTabIndex) => {
    onChange && onChange(selectedTabIndex);
    setSelectedTabIndex(selectedTabIndex);
  };

  useEffect(() => {
    isMounted.current = true;
    setWidth(getWindowWidth());
    window.addEventListener('resize', handleResize);

    return () => {
      isMounted.current = false;
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleResize = () => {
    if (isMounted.current) {
      setWidth(getWindowWidth());
    }
  };

  const getTextTabs = (title, i) => {
    return (
      <Tab
        data-cy={`tab-${title}`}
        key={title}
        className={selectedTabIndex === i ? styles.active : undefined}
      >
        <span className={styles.textTabSpan}>
          {title}
          <i className={styles.iconOnTextTab}>{iconTabs[i]}</i>
        </span>
      </Tab>
    );
  };

  const getIconTabs = (iconText, i) => {
    return (
      <Tab
        key={`${i}-${iconText}`}
        className={`${selectedTabIndex === i ? styles.active : null} ${styles.iconTabs}`}
      >
        <i>{iconText}</i>
      </Tab>
    );
  };

  return (
    <TabsComponent
      className={className}
      onSelect={selectTab}
      onChange={onChange && onChange}
      selectedTabPanelClassName={selectedTabPanelClassName && selectedTabPanelClassName}
    >
      <TL className={styles.tabList}>
        {shouldRenderTextTabs
          ? textTabs.map((title, i) => getTextTabs(title, i))
          : iconTabs.map((iconText, i) => getIconTabs(iconText, i))}
      </TL>
      {children}
    </TabsComponent>
  );
};

export default TabsWithIconsOnMobile;
