import { Button } from '../../components/Shared/Buttons';
import { InputItem } from '../../components/Shared/Forms';
import { useHistory } from 'react-router';
import { getUserStatistics } from '../../redux/actions/statisticsActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import LineChartOptionWrapper from '../../components/Charts/LineChartOptionWrapper';
import LineChart from '../../components/Charts/LineChart';
import html2pdf from 'html2pdf.js';
import Spinner from '../../components/Shared/Loaders/Spinner';
import styled from 'styled-components';

const UserStatisticsPage: FC = () => {
  const user = useAppSelector((state) => state.user.user);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const busy = useAppSelector((state) => state.statistics.userStatistics.busy);
  const complete = useAppSelector((state) => state.statistics.userStatistics.complete);
  const error = useAppSelector((state) => state.statistics.userStatistics.error);
  const internalStatistics = useAppSelector((state) => state.statistics.userStatistics.statistics);

  const patientsStats = internalStatistics?.patients;
  const patientsGrowth = internalStatistics?.patientsGrowth;
  const implantsGrowth = internalStatistics?.implantsGrowth;
  const implantssStats = internalStatistics?.implants;
  const measurementsStats = internalStatistics?.measurements;
  const measurementsGrowth = internalStatistics?.measurementsGrowth;
  const userInfo = internalStatistics?.userInfo;
  const signInsbyDevice = internalStatistics?.signInByDevice;
  const scannedSmartpegs = internalStatistics?.scannedSmartpegs;
  const scannedImplants = internalStatistics?.scannedImplants;
  const scannedAbutments = internalStatistics?.scannedAbutments;
  const pageVisits = internalStatistics?.pageVisits;
  const pdfExports = internalStatistics?.pdfExports;

  const [isAllowedAccess, setIsAllowedAccess] = useState(false);
  const [email, setEmail] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);

  const containerRef = useRef(null);

  console.log('this is internalStatistics :', internalStatistics);

  useEffect(() => {
    if (user === undefined || user.email === undefined) {
      return;
    }

    if (
      user.email === 'martin.gabre@osstell.com' ||
      user.email === 'stefan.horn@osstell.com' ||
      user.email === 'thaddeus.picklo@osstell.com' ||
      user.email === 'viktoria.martensson@osstell.com' ||
      user.email === 'petter.fogel@osstell.com' ||
      user.email === 'leon.koopman@osstell.com' ||
      user.email === 'toyah.ohlson@osstell.com'
    ) {
      setIsAllowedAccess(true);
    } else {
      history.goBack();
    }
  }, [user, history]);

  const handleGeneratePDF = async () => {
    setIsDownloading(true);
    if (containerRef.current) {
      const opt = {
        jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait', height: 11.69, width: 'auto' },
        html2canvas: {
          dpi: 192 * 2,
          letterRendering: true,
          useCORS: true,
          logging: true,
          scale: 4,
        },
        image: { type: 'jpeg', quality: 1 },
      };

      const element = containerRef.current;
      const fileName = `${userInfo.firstName}-${userInfo.lastName}-statistics`;

      try {
        await html2pdf().set(opt).from(element).save(fileName);
        setIsDownloading(false);
      } catch (error) {
        console.error('PDF generation error:', error);
        setIsDownloading(false);
      }
    }
  };

  console.log('this is error :', error);

  return (
    <StyledInternalStatisticsPage>
      {!isAllowedAccess && <p>page not found</p>}
      {isAllowedAccess && (
        <>
          <h1>User statistics page</h1>
          <div className="container">
            <div className="search-container">
              <span>Enter user email:</span>
              <InputItem
                dataCy="delete-account-password-input"
                type="text"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => setEmail(evt.target.value)}
                value={email}
              />
              <Button
                disabled={!email}
                value={'Get stats'}
                onClick={() => {
                  dispatch(getUserStatistics(email.toLowerCase()));
                  setEmail('');
                }}
                primary
              />
            </div>
            {busy && (
              <>
                <h2>Getting stats, this will take a moment...</h2>
                <Spinner purple />
              </>
            )}
            {error && <h1>Error: {error.error.exceptionMessage}</h1>}
            {complete && (
              <>
                <PdfContainer>
                  <Button
                    full
                    primary
                    value={'Download'}
                    loading={isDownloading}
                    disabled={isDownloading}
                    onClick={handleGeneratePDF}
                  />
                </PdfContainer>
                <div ref={containerRef}>
                  <UserInfoDiv>
                    <span>
                      <b>Email:</b> {userInfo?.email}
                    </span>
                    <span>
                      <b>First name:</b> {userInfo?.firstName}
                    </span>
                    <span>
                      <b>Last name:</b> {userInfo?.lastName}
                    </span>
                    <span>
                      <b>UserId:</b> {userInfo?.userId}
                    </span>
                    <span>
                      <b>UserGroupId:</b> {userInfo?.userGroupId}
                    </span>
                    <span>
                      <b>Instruments:</b>
                    </span>
                    <ol>
                      {userInfo?.instruments.length > 0 &&
                        userInfo.instruments.map((instrumentString: string, index: number) => (
                          <li key={index}>{instrumentString}</li>
                        ))}
                    </ol>
                  </UserInfoDiv>
                  <ChartContainer>
                    <LineChartOptionWrapper
                      renderGraphOne={() => (
                        <LineChart
                          title="Patients - Total"
                          dataKeys={['amount']}
                          data={patientsStats ? patientsStats.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionOne="Total"
                      renderGraphTwo={() => (
                        <LineChart
                          title="Patients - Weekly growth"
                          dataKeys={['amount']}
                          data={patientsGrowth ? patientsGrowth.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionTwo="Weekly growth"
                    />
                  </ChartContainer>

                  <ChartContainer>
                    <LineChartOptionWrapper
                      renderGraphOne={() => (
                        <LineChart
                          title="Implants - total"
                          dataKeys={['amount']}
                          data={implantssStats ? implantssStats.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionOne="Total"
                      renderGraphTwo={() => (
                        <LineChart
                          title="Implants - Weekly growth "
                          dataKeys={['amount']}
                          data={implantsGrowth ? implantsGrowth.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionTwo="Weekly growth"
                    />
                  </ChartContainer>

                  <ChartContainer>
                    <LineChartOptionWrapper
                      renderGraphOne={() => (
                        <LineChart
                          title="Measurents - total"
                          dataKeys={['amount']}
                          data={measurementsStats ? measurementsStats.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionOne="Total"
                      renderGraphTwo={() => (
                        <LineChart
                          title="Measurents - Weekly growth"
                          dataKeys={['amount']}
                          data={measurementsGrowth ? measurementsGrowth.stats : []}
                          customWidth={750}
                          customHeight={500}
                        />
                      )}
                      optionTwo="Weekly growth"
                    />
                  </ChartContainer>

                  <ChartContainer>
                    <LineChart
                      title="Sign in by device"
                      dataKeys={['total', 'browser', 'mobile', 'tablet']}
                      data={signInsbyDevice}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                  <ChartContainer>
                    <LineChart
                      title="Scanned smartpegs"
                      dataKeys={['amount']}
                      data={scannedSmartpegs}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                  <ChartContainer>
                    <LineChart
                      title="Scanned implants"
                      dataKeys={['amount']}
                      data={scannedImplants}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                  <ChartContainer>
                    <LineChart
                      title="Scanned abutments"
                      dataKeys={['amount']}
                      data={scannedAbutments}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                  <ChartContainer>
                    <LineChart
                      title="Page visits"
                      dataKeys={[
                        'clinicPage',
                        'consultationModule',
                        'helpPage',
                        'patientPage',
                        'patientList',
                      ]}
                      data={pageVisits}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                  <ChartContainer>
                    <LineChart
                      title="Pdf export"
                      dataKeys={['consultation', 'referral', 'patientInformation']}
                      data={pdfExports}
                      infotext="* Data collected since 2023/04/27"
                      customWidth={750}
                      customHeight={500}
                    />
                  </ChartContainer>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </StyledInternalStatisticsPage>
  );
};

const StyledInternalStatisticsPage = styled.div`
  background-color: #edeef7;
  overflow: scroll;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .search-container {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 500px;
      /* border: solid red 1px; */
      * {
        width: 100%;
      }

      span {
        text-align: center;
        margin-bottom: -10px;
        font-size: 20px;
      }
    }
    .user-info {
    }
  }

  .info {
    font-size: 20px;
  }

  h1 {
    text-align: center;
    display: block;
  }
`;

const PdfContainer = styled.div`
  width: 500px;
  margin-bottom: 1rem;
`;

const UserInfoDiv = styled.div`
  background-color: white;
  border-radius: 5px;
  height: 11.69in;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  font-size: 17px;
`;

const ChartContainer = styled.div`
  height: 11.69in;
  background: white;
`;

export default UserStatisticsPage;
