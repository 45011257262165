import React from 'react';
import styles from './Forms.module.scss';
import cn from 'classnames';
import { H2 } from '../Headings';

const InputGroup = (props) => (
  <div className={cn(styles.inputGroup, props.className)}>
    {props.label && <H2 className={styles.h2}>{props.label}</H2>}
    {props.description && <p className={styles.p}>{props.description}</p>}
    {props.children}
  </div>
);

export default InputGroup;
