import { DefaultTheme } from 'styled-components';

const styledTheme: DefaultTheme = {
  colors: {
    purple: 'var(--color-purple)',
    vividpurple: 'var(--color-vividpurple)',
    lightpurple: 'var(--color-lightpurple)',
    ISQhigh: 'var(--color-ISQhigh)',
    ISQmedium: 'var(--color-ISQmedium)',
    ISQlow: 'var(--color-ISQlow)',
    ISQneutral: 'var(--color-ISQneutral)',
    ISQneutralMuted: 'var(--color-ISQneutralMuted)',
    warning: 'var(--color-warning)',
    shadedwhite: 'var(--color-shadedwhite)',
    subtlegrey: 'var(--color-subtlegrey)',
    lightgrey: 'var(--color-lightgrey)',
    midgrey: 'var(--color-midgrey)',
    grey: 'var(--color-grey)',
    greyText: 'var(--color-greyText)',
    tintedLightgrey: 'var(--color-tintedLightgrey)',
    tintedgrey: 'var(--color-tintedgrey)',
    selectedgrey: '#F4F5FC',
  },
};

export { styledTheme };
