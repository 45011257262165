import { FC } from 'react';
import { FormikValues } from 'formik';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

interface Props {
  id: string;
  label: string;
  value?: string;
  error: boolean | undefined;
  formik?: FormikValues;
  onChange?: (event: SelectChangeEvent) => void;
  menuList: { key: string; value: string }[];
  disabled: boolean;
  helperText: string | boolean | undefined;
}

const FormikSelectInput: FC<Props> = ({
  id,
  label,
  value,
  error,
  formik,
  onChange,
  disabled,
  menuList,
  helperText,
}) => {
  return (
    <FormControl fullWidth size={'small'} error={error}>
      <InputLabel>{label}</InputLabel>
      <Select
        id={id}
        label={label}
        value={!formik && value ? value : ''}
        disabled={disabled}
        onChange={!formik && onChange && onChange}
        error={Boolean(error)}
        {...(formik && formik.getFieldProps(id))}
      >
        {menuList.map((item: { key: string; value: string }, idx: number) => (
          <MenuItem key={idx} value={item.key}>
            {item.value}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default FormikSelectInput;
