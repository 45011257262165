import { createTheme } from '@mui/material/styles';

export const osstellConnectTheme = createTheme({
  typography: {
    fontFamily: "'Source Sans Pro', 'sans-serif'",
  },
  palette: {
    primary: {
      main: '#822973',
    },
    secondary: {
      main: '#C749B3',
    },
    info: {
      main: '#94A3B8',
    },
  },
  spacing: 10,
  components: {
    MuiButton: {
      defaultProps: { variant: 'contained' },
      variants: [
        {
          props: { variant: 'contained' },
          style: { fontWeight: 600 },
        },
        {
          props: { variant: 'outlined' },
          style: {
            fontWeight: 600,
            boxShadow:
              '0 0 transparent, 0 0 transparent, 0 0 transparent, 0 0 transparent, 0 1px 3px 0 rgba(0,0,0,.1), 0 1px 2px -1px rgba(0,0,0,.1)',
          },
        },
        {
          props: { variant: 'text' },
          style: { fontWeight: 600 },
        },
      ],
      styleOverrides: {
        root: {
          borderRadius: 8,
          '&.MuiButton-outlined': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#3D3D4D',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 20,
          '&.MuiPopover-paper': {
            padding: 0,
          },
          '&.MuiTableContainer-root': {
            padding: 0,
          },
          '&.MuiDrawer-paper': {
            padding: 0,
          },
          '&.MuiCard-root': {
            padding: 15,
          },
        },
      },
    },
    MuiTooltip: {
      defaultProps: { placement: 'top' },
    },
    MuiTextField: {
      defaultProps: { size: 'small', fullWidth: true },
      styleOverrides: {
        root: {
          borderRadius: 8,
          '.MuiOutlinedInput-root': {
            borderRadius: 8,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiFormControl: {
      defaultProps: { size: 'small' },
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiTab: {
      defaultProps: { iconPosition: 'start' },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 10,
          height: '100%',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.MuiCardContent-root': {
            padding: 0,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 8,
          marginBottom: 8,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          marginBottom: 8,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 8,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
      },
    },
    MuiTableCell: {
      variants: [
        {
          props: { variant: 'head' },
          style: {
            fontSize: '0.9rem',
            fontWeight: 600,
            '@media (max-width:900px)': {
              fontSize: '0.8rem',
            },
          },
        },
        {
          props: { variant: 'body' },
          style: {
            fontSize: '0.8rem',
          },
        },
      ],
    },
  },
});
