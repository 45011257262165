import ApiFetch from '../../utils/apiFetch';
import * as types from '../../constants/actionTypes';

function getInternalStatisticsRequest() {
    return {
      type: types.GET_INTERNAL_STATS,
    };
  }
  
  function getInternalStatisticsSuccess(data) {
    return {
      type: types.GET_INTERNAL_STATS_SUCCESS,
      statistics: data
    };
  }
  
  function getInternalStatisticsFailed(error) {
    return {
      type: types.GET_INTERNAL_STATS_ERROR,
      error,
    };
  }
  
  export function getInternalStatistics() {
    return (dispatch) => {
      dispatch(getInternalStatisticsRequest());
      
      ApiFetch.get('/stats/internalstatistics')
        .then((data) => {
          dispatch(getInternalStatisticsSuccess(data));
        })
        .catch((error) => {
          dispatch(getInternalStatisticsFailed(error));
        });
    };
  }

// ------------------- User statistics action ----------------------

  function getUserStatisticsRequest() {
    return {
      type: types.GET_USER_STATS,
    };
  }
  
  function getUserStatisticsSuccess(data) {
    return {
      type: types.GET_USER_STATS_SUCCESS,
      statistics: data
    };
  }
  
  function getUserStatisticsFailed(error) {
    return {
      type: types.GET_USER_STATS_ERROR,
      error,
    };
  }
  
  export function getUserStatistics(email) {
    return (dispatch) => {
      dispatch(getUserStatisticsRequest());
  
      ApiFetch.get(`stats/userstatistics?email=${email}`, false)
        .then((data) => {
          dispatch(getUserStatisticsSuccess(data));
        })
        .catch((error) => {
          dispatch(getUserStatisticsFailed(error));
        });
    };
  }