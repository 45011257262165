import React, { useEffect, useRef } from 'react';
import Rive, { Artboard, CanvasRenderer, LinearAnimationInstance, RiveCanvas } from 'rive-canvas';
import Konva from 'konva';
import { Layer } from 'react-konva';

// @ts-ignore
import gumsLayer from '../../../../../../../shared/videos/gums.riv';
import { Bone } from '../../../../../../../redux/slices/data-structures/workspaceStructure';
import { useAppSelector } from '../../../../../../../utils/hooks';
import { RootState } from '../../../../../../../redux/store';
import { loadAnimationInstances } from './TeethCanvasBoneLayer';

export function TeethCanvasGumsLayer(props: { width: number; height: number }) {
  const boneDepth = useAppSelector(
    (state: RootState) => state.consultation.workspace.present.boneState
  );
  const gumsMode = useAppSelector(
    (state) => state.consultation.workspace.present.view.gumsTransparencyMode
  );
  const _rive = useRef<RiveCanvas | null>(null);
  const _renderer = useRef<CanvasRenderer | null>(null);
  const _artboard = useRef<Artboard | null>(null);
  const animations = useRef<Record<string, LinearAnimationInstance[]> | null>(null);
  const konvaGumsLayer = useRef<Konva.Layer>(null);

  function update(graft?: boolean) {
    if (animations.current) {
      boneDepth.forEach((bone: Bone, index: number) => {
        if (index < 16) {
          //Upper row
          animations.current!.UpperDepth[index].time =
            (bone.graft ? 0 : bone.depth / 3) +
            (graft && bone.softTissueGraft ? 0 : bone.softTissueDepth / 12);
          animations.current!.UpperDepth[index].apply(_artboard.current!, 1.0);
          if (animations.current!.UpperPeak[index]) {
            animations.current!.UpperPeak[index].time = bone.graft
              ? 0
              : !bone.peak[1]
              ? bone.depth / 3
              : 0;
            animations.current!.UpperPeak[index].apply(_artboard.current!, 1.0);
          }
        } else {
          //Lower row
          animations.current!.LowerDepth[index - 16].time =
            (bone.graft ? 0 : bone.depth / 3) +
            (graft && bone.softTissueGraft ? 0 : bone.softTissueDepth / 12);
          animations.current!.LowerDepth[index - 16].apply(_artboard.current!, 1.0);
          if (animations.current!.LowerPeak[index - 16]) {
            animations.current!.LowerPeak[index - 16].time = bone.graft
              ? 0
              : !bone.peak[1]
              ? bone.depth / 3
              : 0;
            animations.current!.LowerPeak[index - 16].apply(_artboard.current!, 1.0);
          }
        }
      });
    }
  }

  function render() {
    const ctx = konvaGumsLayer.current!.canvas._canvas.getContext('2d');
    if (ctx && _rive.current && _artboard.current && _renderer.current) {
      ctx.save();
      ctx.translate(0, 16);
      _renderer.current.align(
        _rive.current.Fit.none,
        _rive.current.Alignment.topCenter,
        {
          minX: 0,
          minY: 0,
          maxX: props.width ?? 960,
          maxY: props.height ?? 600,
        },
        _artboard.current.bounds
      );
      _artboard.current.advance(1);
      _artboard.current.draw(_renderer.current);
      ctx.restore();
    }
  }

  useEffect(() => {
    if (konvaGumsLayer.current) {
      Rive({
        locateFile: (file) => 'file://' + file,
      }).then(async (rive) => {
        _rive.current = rive;
        const req = new Request(gumsLayer);
        fetch(req)
          .then((res) => {
            return res.arrayBuffer();
          })
          .then(async (buf) => {
            const file = rive.load(new Uint8Array(buf));
            const artboard = file.defaultArtboard();
            _artboard.current = artboard;
            if (!konvaGumsLayer.current) return;

            const UpperDepthAnimations = await loadAnimationInstances(
              rive,
              artboard,
              16,
              1,
              'upper_d'
            );
            const LowerDepthAnimations = await loadAnimationInstances(
              rive,
              artboard,
              16,
              17,
              'lower_d'
            );
            const UpperPeakAnimations = await loadAnimationInstances(
              rive,
              artboard,
              15,
              1,
              'upper_p'
            );
            const LowerPeakAnimations = await loadAnimationInstances(
              rive,
              artboard,
              15,
              17,
              'lower_p'
            );

            //Load all animations into a Record
            animations.current = {
              UpperDepth: UpperDepthAnimations,
              LowerDepth: LowerDepthAnimations,
              UpperPeak: UpperPeakAnimations,
              LowerPeak: LowerPeakAnimations,
            };

            const canvas = konvaGumsLayer.current.canvas._canvas;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            _renderer.current = new rive.CanvasRenderer(ctx);

            //Initial Render
            if (gumsMode) {
              //Fill in graft in background if applicable
              ctx.filter = 'brightness(85%) saturate(180%)';
              update(true);
              _artboard.current?.advance(0);
              render();
              ctx.filter = 'brightness(100%) saturate(100%)';

              update();
              artboard.advance(0);
              render();
            }
          });
      });
    }
  }, []);

  useEffect(() => {
    if (
      _rive.current &&
      _renderer.current &&
      _artboard.current &&
      konvaGumsLayer.current &&
      animations.current
    ) {
      const ctx = konvaGumsLayer.current.canvas._canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          props.width ?? konvaGumsLayer.current.canvas._canvas.width,
          props.height ?? konvaGumsLayer.current.canvas._canvas.height
        );
        if (gumsMode) {
          //Fill in graft in background if applicable
          ctx.filter = 'brightness(85%) saturate(180%)';
          update(true);
          _artboard.current?.advance(0);
          render();
          ctx.filter = 'brightness(100%) saturate(100%)';

          update();
          _artboard.current?.advance(1);
          render();
        }
      }
    }
  });
  /*  [gumsMode, boneDepth, _rive, _renderer, _artboard, animations, konvaGumsLayer]*/

  return <Layer clearBeforeDraw={false} ref={konvaGumsLayer} listening={false} />;
}
