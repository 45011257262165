import { connect } from 'react-redux';
import { Component } from 'react';
import { bindActionCreators } from 'redux';
import { InputItem, InputRow } from '../../../components/Shared/Forms';
import { SectionTabContent, Row, RowItem } from '../../../components/Shared/Section';
import { PanelWrapper, Panel, PanelHeader } from '../../../components/Shared/Panel';
import { Table, TableHead, TableBody, TH, TR, TD } from '../../../components/Shared/Table';
import * as firmwareActions from '../../../redux/actions/firmwareActions';
import Dropdown from '../../../components/Shared/Dropdown/Dropdown';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import styles from './FirmwaresAdministration.module.scss';

class FirmwaresAdministration extends Component {
  constructor(props) {
    super(props);

    let currentFirmware = props.getFirmwares.firmwares.find((firmware) => firmware.current);

    if (!currentFirmware) {
      currentFirmware = props.getFirmwares.firmwares.sort((a, b) => Number(b) - Number(a))[0];
    }

    this.state = {
      selectedFirmwareVersion: currentFirmware ? currentFirmware.firmwareVersion : '',
    };

    this.renderFirmwares = this.renderFirmwares.bind(this);
    this.currentFirmwareChanged = this.currentFirmwareChanged.bind(this);
    this.setFirmwareToCurrent = this.setFirmwareToCurrent.bind(this);
    this.setChosenFirmware = this.setChosenFirmware.bind(this);

    this.dropdownOptions = [
      {
        label: 'Set as current',
        action: this.setFirmwareToCurrent,
      },
      { label: 'Push to instruments', action: '', disabled: true },
      { label: 'Delete', action: '', disabled: true },
    ];
  }

  componentDidMount() {
    this.props.actions.getFirmwares();
    this.props.actions.subscribeToFirmwaresChange();
  }

  componentWillUnmount() {
    this.props.actions.unsubscribeFromFirmwaresChange();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let currentFirmware = nextProps.getFirmwares.firmwares.find((firmware) => firmware.current);

    if (!currentFirmware) {
      currentFirmware = nextProps.getFirmwares.firmwares.sort((a, b) => Number(b) - Number(a))[0];
    }

    this.setState({
      selectedFirmwareVersion: currentFirmware ? currentFirmware.firmwareVersion : '',
    });
  }

  renderDropDown(firmwareId) {
    return (
      <Dropdown
        align="right"
        className={styles.dropDownToggle}
        options={this.dropdownOptions}
        actionData={firmwareId}
      >
        <i className={styles.itemIcon}>more</i>
      </Dropdown>
    );
  }

  setFirmwareToCurrent(firmwareId) {
    if (!firmwareId) {
      firmwareId = this.state.selectedFirmware.id;
    }

    if (this.props.setFirmwareToCurrent) {
      this.props.setFirmwareToCurrent(firmwareId);
    }
  }

  setChosenFirmware() {
    const firmware = this.props.getFirmwares.firmwares.find(
      (firmware) => firmware.firmwareVersion === this.state.selectedFirmwareVersion
    );

    if (this.props.setFirmwareToCurrent) {
      this.props.setFirmwareToCurrent(firmware.id);
    }
  }

  currentFirmwareChanged(evt) {
    this.setState({
      selectedFirmwareVersion: evt.target.value,
    });
  }

  renderFirmwares() {
    const firmwares = this.props.getFirmwares.firmwares;

    return firmwares.map((firmware, index) => {
      return (
        <TR key={index}>
          <TD>{firmware.current ? 'Current' : null}</TD>
          <TD>{firmware.firmwareVersion}</TD>
          <TD>{firmware.createdDate}</TD>
          <TD>{this.renderDropDown(firmware.id)}</TD>
        </TR>
      );
    });
  }

  render() {
    const firmwares = this.props.getFirmwares.firmwares;
    const firmwareVersions = firmwares.map((firmware) => {
      return { value: firmware.firmwareVersion };
    });

    return (
      <SectionTabContent>
        <PanelWrapper className={styles.marginTop}>
          <Panel full filled>
            <PanelHeader smallHeader="Firmware" bigHeader="Default Firmware" />
            <Row>
              <RowItem>
                <InputRow>
                  <InputItem
                    label="Default Firmware"
                    type="select"
                    options={firmwareVersions}
                    onChange={this.currentFirmwareChanged}
                    value={this.state.selectedFirmwareVersion}
                    onWhite
                  />
                  <InputItem
                    label=""
                    type="button"
                    value="Change"
                    onClick={this.setChosenFirmware}
                  />
                </InputRow>
              </RowItem>
            </Row>
          </Panel>
        </PanelWrapper>
        <PanelWrapper>
          <Panel full filled>
            <PanelHeader smallHeader="Firmwares" bigHeader="Firmware list" />
            <Row>
              <RowItem>
                {this.props.getFirmwares.busy ? (
                  <Spinner className={styles.spinnerBackground} />
                ) : (
                  <Table>
                    <TableHead>
                      <TR>
                        <TH>Status</TH>
                        <TH>Version</TH>
                        <TH>Deployed</TH>
                        <TH></TH>
                      </TR>
                    </TableHead>
                    <TableBody>{this.renderFirmwares()}</TableBody>
                  </Table>
                )}
              </RowItem>
            </Row>
          </Panel>
        </PanelWrapper>
      </SectionTabContent>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    getFirmwares: state.firmware.getFirmwares,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(firmwareActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(FirmwaresAdministration);
