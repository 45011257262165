import { FC, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { IUserGroup } from '../../types/user';
import { updateUser } from '../../redux/actions/userActions';
import { clinicTogglerStyles } from './styles/clinicTogglerStyles';
import { useSwitchClinicMutation } from '../../redux/api/clinic/clinicApi';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  Select,
  SelectChangeEvent,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface Props {
  clinics: { userGroupId: string; name: string }[];
  isDisabled?: boolean;
}

const ClinicToggler: FC<Props> = ({ clinics, isDisabled }) => {
  const intl = useIntl();
  const classes = clinicTogglerStyles();
  const theme = useTheme();
  const isBreakpointLg = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useAppDispatch();
  const [switchClinic, { isLoading: isToggling }] = useSwitchClinicMutation();
  const { user, userUpdate } = useAppSelector((state) => state.user);
  const { clinic } = useAppSelector((state) => state.clinic);

  const [selectedClinic, setSelectedClinic] = useState(clinic.userGroupId);

  const switchClinicHandler = async (event: SelectChangeEvent) => {
    const userGroupId = event.target.value;
    setSelectedClinic(userGroupId);

    const res = await switchClinic(userGroupId);
    if ('error' in res && 'data' in res.error) {
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        intl.formatMessage({
          id: 'general.requestProblem',
          defaultMessage: 'There was a problem with your request.',
        }),
        { showCloseButton: false }
      );
      return setSelectedClinic(clinic.userGroupId);
    }

    const { userPermission } = user.userGroups.find((ug: IUserGroup) => ug.id === userGroupId);
    const updatedUser = {
      ...user,
      sourceUserGroup: {
        id: userGroupId,
        userPermission,
      },
    };
    dispatch(updateUser(null, updatedUser));
  };

  const isLoading = isToggling || userUpdate.busy;

  return (
    <FormControl size="small" fullWidth disabled={isDisabled || isLoading}>
      {isBreakpointLg && (
        <InputLabel>
          {isLoading
            ? intl.formatMessage({ id: 'general.loading', defaultMessage: 'Loading' })
            : intl.formatMessage({ id: 'general.selectedClinic', defaultMessage: 'Clinic' })}
        </InputLabel>
      )}
      <Select
        value={selectedClinic}
        onChange={switchClinicHandler}
        label={
          isBreakpointLg
            ? isLoading
              ? intl.formatMessage({ id: 'general.loading', defaultMessage: 'Loading' })
              : intl.formatMessage({ id: 'general.selectedClinic', defaultMessage: 'Clinic' })
            : undefined
        }
        className={classes.select}
      >
        {clinics.map((clinic, idx) => (
          <MenuItem key={idx} value={clinic.userGroupId}>
            {clinic.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ClinicToggler;
