import { H3 } from '../../components/Shared/Headings';
import { Button } from '../../components/Shared/Buttons';
import { showVideo } from '../../redux/actions/generalActions';
import { dismissNote } from '../../redux/actions/userActions';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Section, SectionInner } from '../../components/Shared/Section';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import How_to_use_ConsultationTool from '../../shared/videos/How_to_use_ConsultationTool.mp4';
import AD2_rendered_comprimized from '../../shared/videos/AD2_rendered-comprimized.mp4';
import RegisterInstrumentModal from '../devices-page/RegisterInstrumentModal';
import PromotionsSection from './PromotionsSection';
import CampaignSection from './CampaignSection';
import loginVideoDemo from '../../shared/videos/loginVideoDemo.mp4';
import PatientSearch from '../../components/PatientRecords/PatientSearch';
import LinksSection from './LinksSection';
import loginVideo from '../../shared/videos/loginVideo.mp4';
import HomeTile from '../../components/Tile/HomeTile';
import Header from '../../components/header/Header';
import styles from './HomePage.module.scss';

const HomePage = () => {
  const intl = useIntl();
  const { userId } = useParams<{ userId: string | undefined }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const clinic = useAppSelector((state) => state.clinic.clinic);

  const hasUserAdminRole =
    user.role === 'SuperAdmin' || user.role === 'AdminLv1' || user.role === 'AdminLv2';

  const [toggleDeviceOnboardingModal, setToggleDeviceOnboardingModal] = useState(
    !user.hasRegisteredInstruments && !hasUserAdminRole
  );

  useEffect(() => {
    const launchVideoNoteId = 'd2c6049d-d80d-415d-9c49-15cc0353f1b0';
    if (user.hasRegisteredInstruments && !user.dismissedNotes.includes(launchVideoNoteId)) {
      dispatch(
        showVideo(
          process.env.REACT_APP_DEMO ? loginVideoDemo : loginVideo,
          intl.formatMessage({
            id: 'home.videoHeader',
            defaultMessage: 'Welcome to your new OsstellConnect',
          })
        )
      );
      dispatch(dismissNote(launchVideoNoteId));
    }
  }, [dispatch, intl, user]);

  const navigate = (path: string) => history.push(`/${path}`);

  const toggleDeviceOnboardingModalHandler = (mode?: boolean) => {
    const nextMode = mode !== undefined ? mode : !toggleDeviceOnboardingModal;
    setToggleDeviceOnboardingModal(nextMode);
  };

  const showRegisterInstrument = () => {
    return (
      <div className={styles.promotionBox} onClick={() => toggleDeviceOnboardingModalHandler()}>
        <p>
          <FormattedMessage
            id="home.noInstrument"
            defaultMessage="There is no instrument connected to your account. Please add an instrument to start measuring ISQ."
          />
        </p>
        <div>
          <Button
            value={intl.formatMessage({
              id: 'home.registerInstrument',
              defaultMessage: 'Register instrument',
            })}
            leftIcon="add"
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <RegisterInstrumentModal
        show={toggleDeviceOnboardingModal}
        hide={() => toggleDeviceOnboardingModalHandler(false)}
        actAsOtherId={userId ? userId : null}
        logoutAtCancel
      />
      <Header title={clinic.name?.toUpperCase()} />
      <Section>
        <SectionInner className={styles.content}>
          <PatientSearch />
          <div className={styles.tiles}>
            <HomeTile
              title={intl.formatMessage({ id: 'home.Clinic', defaultMessage: 'Clinic' })}
              icon="clinic"
              onClick={() => navigate('clinic')}
            />
            <HomeTile
              title={intl.formatMessage({ id: 'home.Patients', defaultMessage: 'Patients' })}
              icon="patient_records"
              onClick={() => navigate('patients')}
            />
            <HomeTile
              dataCy="new-patient-home-tile"
              title={intl.formatMessage({
                id: 'home.NewPatient',
                defaultMessage: 'New Patient',
              })}
              icon="add"
              onClick={() =>
                navigate(`usergroup/${user.sourceUserGroup.id}/patients/new/patientinformation`)
              }
            />
          </div>
          <CampaignSection
            videoUrl={How_to_use_ConsultationTool}
            campaignVideoUrl={AD2_rendered_comprimized}
          />
          {!user.hasRegisteredInstruments && (
            <div>
              <div className={styles.divider}></div>
              <H3>
                <FormattedMessage id="home.devices" defaultMessage="Devices" />
              </H3>
              {showRegisterInstrument()}
            </div>
          )}
          {user.promotions && user.promotions.length > 0 && <PromotionsSection user={user} />}
          <div className={styles.divider}></div>
          <LinksSection videoUrl={How_to_use_ConsultationTool} />
        </SectionInner>
      </Section>
    </div>
  );
};

export default HomePage;
