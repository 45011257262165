import * as React from 'react';

function SvgSvgSmile(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.3 29.74" {...props}>
      <defs>
        <style>
          {
            '.SvgSmile_svg__cls-1,.SvgSmile_svg__cls-2{fill:none}.SvgSmile_svg__cls-1{stroke-linecap:round;stroke-linejoin:round}.SvgSmile_svg__cls-2{stroke-miterlimit:10}'
          }
        </style>
      </defs>
      <g id="SvgSmile_svg__Layer_2" data-name="Layer 2">
        <g id="SvgSmile_svg__Layer_1-2" data-name="Layer 1">
          <path
            className="SvgSmile_svg__cls-1"
            d="M33.35 13.3v1.2H28v.19H15.7v-.28h-5.27v-1.2M28 14.5h5.37v-1.2m-11.48-2.59v4H28m11.45-8.14c-2.93-.33-4.66-1.67-6.62-2.8A24.25 24.25 0 0021.77.5a23.11 23.11 0 00-10.59 3C9 4.69 7.4 6.12 4.4 6.5c-2.49.31-4-.59-3.89.07A49.78 49.78 0 003.86 16C7 22.48 12.12 29.28 21.93 29.24c8.92 0 14.3-6.85 17.68-12.93a23.8 23.8 0 003.19-9.76c0-.63-1.66.22-3.35.02zm-5.12 3.19a9.28 9.28 0 001.7.08 7 7 0 001.16-.16 6.62 6.62 0 00.9-.28c.95-.37 1.37-.79 1.56-.62.59.49-1.73 5.68-5.93 9.17a19.45 19.45 0 01-12 4.3 20 20 0 01-12.46-4.43c-4.07-3.37-6.38-8.25-5.62-9 .34-.33 1.12.32 3.07.63h.43l.51.05a13.2 13.2 0 002.78-.12h.33l4.94-.7h.05L17 8.51m14.42.62l1.15.27.75.18"
          />
          <path
            className="SvgSmile_svg__cls-2"
            d="M17 8.51c2.56-.35 4-.51 4.43-.51a45.82 45.82 0 0110 1.17M34.33 9.76c-.36 0-.69-.12-1-.18M10.48 9.44h-.05a13.2 13.2 0 01-2.78.12"
          />
          <path className="SvgSmile_svg__cls-1" d="M15.7 10.71v3.7M21.89 10.71v3.98h6.09v-3.98" />
          <path
            className="SvgSmile_svg__cls-2"
            d="M31.45 9.13l1.15.27.75.18M34.33 9.76a9.28 9.28 0 001.7.08"
          />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path className="SvgSmile_svg__cls-1" d="M18.12 20.61V18.39h7.3V20.61" />
          <path
            className="SvgSmile_svg__cls-1"
            d="M22.04 20.61v-2.22h-3.92V20.61M22.04 20.61v-2.22h3.38V20.61M28.86 18.63h-3.44M18.12 18.72h-3.5"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgSmile;
