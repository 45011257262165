import { H3 } from '../../components/Shared/Headings';
import { Button } from '../Shared/Buttons';
import { InputItem } from '../Shared/Forms';
import { IClinicReferral } from '../../types/clinic';
import { updateReferrals } from '../../redux/actions/clinicActions';
import { showConfirmation } from '../../redux/actions/generalActions';
import { ChangeEvent, FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import styled from 'styled-components';
import Modal from '../Shared/Modal/Modal';

interface Props {
  onHide: () => void;
  show: boolean;
}

const ReferralModal: FC<Props> = ({ onHide, show }) => {
  const dispatch = useAppDispatch();
  const clinic = useAppSelector((state) => state.clinic.clinic);
  const intl = useIntl();

  const [newReferral, setNewReferral] = useState<IClinicReferral>({
    id: '',
    email: '',
    clinic: '',
    clinician: '',
    address: '',
  });

  const [editableReferal, setEditableReferral] = useState<IClinicReferral>({
    id: '',
    email: '',
    clinic: '',
    clinician: '',
    address: '',
  });

  const saveNewReferral = () => {
    dispatch(
      updateReferrals(clinic.id, [
        ...clinic.referrals,
        {
          email: newReferral.email,
          clinic: newReferral.clinic,
          clinician: newReferral.clinician,
          address: newReferral.address,
        },
      ])
    );

    setNewReferral({
      id: '',
      email: '',
      clinic: '',
      clinician: '',
      address: '',
    });
  };

  const updateNewReferralState = (
    event: ChangeEvent<HTMLInputElement>,
    field: keyof IClinicReferral
  ) => {
    const updatedNewReferral = { ...newReferral };
    updatedNewReferral[field] = event.target.value;
    setNewReferral(updatedNewReferral);
  };

  const formIsValid = () =>
    newReferral.email !== '' &&
    newReferral.clinic !== '' &&
    newReferral.clinician !== '' &&
    newReferral.address !== '';

  const startToEditReferral = (id: string) => {
    const referrals = [...clinic.referrals];
    const referralToEdit = referrals.find((referral) => referral.id === id);
    setEditableReferral(referralToEdit);
  };

  const deleteReferral = (id: string) => {
    let referrals = [...clinic.referrals];
    const index = referrals.findIndex((clinician: { id: string }) => clinician.id === id);
    const referralToDelete = referrals.find((clinician: { id: string }) => clinician.id === id);

    if (index !== -1) {
      referrals = referrals.filter((referral) => referral.id !== id);
    }

    dispatch(
      showConfirmation(
        intl.formatMessage({
          id: 'referringClinician.DeleteReferringClinician',
          defaultMessage: 'Delete referring clinician?',
        }),
        <StyledDeleteReferralModal>
          <div>
            <span>
              <b>
                <FormattedMessage id="general.name" defaultMessage="Name" />
                {': '}
              </b>
            </span>
            <span>{referralToDelete.clinician}</span>
          </div>
          <div>
            <span>
              <b>
                <FormattedMessage id="general.clinic" defaultMessage="Clinic Name" />
                {': '}
              </b>
            </span>
            <span>{referralToDelete.clinic}</span>
          </div>
          <div>
            <span>
              <b>
                <FormattedMessage id="general.email" defaultMessage="Email" />
                {': '}
              </b>
            </span>
            <span>{referralToDelete.email}</span>
          </div>
          <div>
            <span>
              <b>
                <FormattedMessage id="general.address" defaultMessage="Address" />:{': '}
              </b>
            </span>
            <span>{referralToDelete.address}</span>
          </div>
        </StyledDeleteReferralModal>,
        () => dispatch(updateReferrals(clinic.id, [...referrals]))
      )
    );
  };

  const updateEditedReferralState = (
    event: ChangeEvent<HTMLInputElement>,
    field: keyof IClinicReferral
  ) => {
    const updatedNewReferral = { ...editableReferal };
    updatedNewReferral[field] = event.target.value;
    setEditableReferral(updatedNewReferral);
  };

  const saveEditedReferral = () => {
    let referrals = [...clinic.referrals];
    const index = referrals.findIndex((clinician) => clinician.id === editableReferal.id);

    if (index !== -1) {
      referrals = referrals.filter((referral) => referral.id !== editableReferal.id);
    }

    referrals.push(editableReferal);
    dispatch(updateReferrals(clinic.id, [...referrals]));
    setEditableReferral({ id: '', email: '', clinic: '', clinician: '', address: '' });
  };

  const renderNewReferralInputs = () => {
    return (
      <div className="newReferralContainer">
        <div className="newReferralInputs">
          <InputItem
            className="newReferralInput"
            dataCy="new-referral-name-input"
            placeholder={intl.formatMessage({
              id: 'general.name',
              defaultMessage: 'Name',
            })}
            dark
            noMarginTop
            noMarginBottom
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateNewReferralState(event, 'clinician')
            }
            value={newReferral.clinician}
          />
          <InputItem
            className="newReferralInput"
            dataCy="new-referral-clinic-input"
            placeholder={intl.formatMessage({
              id: 'general.clinic',
              defaultMessage: 'Clinic Name',
            })}
            dark
            noMarginTop
            noMarginBottom
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateNewReferralState(event, 'clinic')
            }
            value={newReferral.clinic}
          />
          <InputItem
            className="newReferralInput"
            dataCy="new-referral-email-input"
            placeholder={intl.formatMessage({
              id: 'general.email',
              defaultMessage: 'Email',
            })}
            dark
            noMarginTop
            noMarginBottom
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateNewReferralState(event, 'email')
            }
            value={newReferral.email}
          />

          <InputItem
            className="newReferralInput"
            dataCy="new-referral-adress-input"
            placeholder={intl.formatMessage({
              id: 'general.address',
              defaultMessage: 'Address',
            })}
            dark
            noMarginTop
            noMarginBottom
            type="text"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              updateNewReferralState(event, 'address')
            }
            value={newReferral.address}
          />
        </div>
        <Button
          dataCy="add-new-referral-button"
          className="addButton"
          iconButton
          leftIcon=""
          noShadow
          disabled={!formIsValid()}
          onClick={() => saveNewReferral()}
          value={intl.formatMessage({
            id: 'general.add',
            defaultMessage: 'Add',
          })}
          primary
        />
      </div>
    );
  };

  const renderTableBody = () => {
    const referralsToRender = clinic.referrals ? [...clinic.referrals] : [];
    return (
      <div className="existingReferralContainer">
        {clinic.referrals &&
          referralsToRender
            .sort((a, b) => a.clinician.localeCompare(b.clinician))
            .map((referral: IClinicReferral) => renderReferralRows(referral))}
      </div>
    );
  };

  const renderReferralRows = (referral: IClinicReferral) => {
    const isEditable = editableReferal.id === referral.id;

    return (
      <div key={referral.id} className="existingReferral">
        <div className="displayRow">
          <div className="infoContainer">
            <div>
              <span>
                <b>
                  <FormattedMessage id="general.name" defaultMessage="Name" />
                  {': '}
                </b>
              </span>
              <span>{referral.clinician}</span>
            </div>
            <div>
              <span>
                <b>
                  <FormattedMessage id="general.clinic" defaultMessage="Clinic Name" />
                  {': '}
                </b>
              </span>
              <span>{referral.clinic}</span>
            </div>
            <div>
              <span>
                <b>
                  <FormattedMessage id="general.email" defaultMessage="Email" />
                  {': '}
                </b>
              </span>
              <span>{referral.email}</span>
            </div>
            <div>
              <span>
                <b>
                  <FormattedMessage id="general.address" defaultMessage="Address" />
                  {': '}
                </b>
              </span>
              <span>{referral.address}</span>
            </div>
          </div>

          {editableReferal.id !== referral.id && (
            <div className="displayRowButtons">
              <Button
                dataCy="edit-button"
                className="buttonMargin"
                iconButton
                noShadow
                primary
                rightIcon="draw"
                onClick={() => startToEditReferral(referral.id)}
              />
              <Button
                dataCy="delete-button"
                className="buttonMargin"
                iconButton
                noShadow
                primary
                rightIcon=""
                onClick={() => deleteReferral(referral.id)}
              />
            </div>
          )}
        </div>
        {editableReferal.id === referral.id && (
          <div className="expandable">
            <p className="inputLabel">
              <FormattedMessage id="general.name" defaultMessage="Name" />:
            </p>
            <InputItem
              dataCy="edit-input-clinician"
              light
              className={`editableReferralInput`}
              noMarginTop
              noMarginBottom
              type="text"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!isEditable) return;
                updateEditedReferralState(event, 'clinician');
              }}
              value={isEditable ? editableReferal.clinician : referral.clinician}
            />
            <p className="inputLabel">
              <FormattedMessage id="general.clinic" defaultMessage="Clinic Name" />:
            </p>
            <InputItem
              dataCy="edit-input-clinic"
              light
              className={`editableReferralInput`}
              noMarginTop
              noMarginBottom
              type="text"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!isEditable) return;
                updateEditedReferralState(event, 'clinic');
              }}
              value={isEditable ? editableReferal.clinic : referral.clinic}
            />
            <p className="inputLabel">
              <FormattedMessage id="general.email" defaultMessage="Email" />:
            </p>
            <InputItem
              dataCy="edit-input-email"
              className={`editableReferralInput`}
              light
              noMarginTop
              noMarginBottom
              type="text"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!isEditable) return;
                updateEditedReferralState(event, 'email');
              }}
              value={isEditable ? editableReferal.email : referral.email}
            />
            <p className="inputLabel">
              <FormattedMessage id="general.address" defaultMessage="Address" />:
            </p>
            <InputItem
              className={`editableReferralInput`}
              light
              noMarginTop
              noMarginBottom
              type="text"
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (!isEditable) return;
                updateEditedReferralState(event, 'address');
              }}
              value={isEditable ? editableReferal.address : referral.address}
            />
            <div className="expandableButtonContainer">
              <Button
                dataCy="cancel-editing-button"
                className="buttonMargin"
                value="Cancel"
                noShadow
                white
                onClick={() =>
                  setEditableReferral({
                    id: '',
                    email: '',
                    clinic: '',
                    clinician: '',
                    address: '',
                  })
                }
              />
              <Button
                dataCy="done-editing-button"
                className="buttonMargin"
                value="Done"
                noShadow
                primary
                rightIcon="done"
                onClick={() => saveEditedReferral()}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Modal
      header={
        <FormattedMessage
          id="referringClinician.ReferringClinician"
          defaultMessage="Referring Clinician"
        />
      }
      onDismiss={() => onHide()}
      show={show}
    >
      <ModalWrapper>
        <H3>
          <FormattedMessage
            id="referringClinician.AddReferringClinician"
            defaultMessage="Add a referring clinician"
          />
        </H3>
        {renderNewReferralInputs()}
        <H3>
          <FormattedMessage
            id="referringClinician.ReferringClinicians"
            defaultMessage="Referring Clinicians"
          />
        </H3>
        {renderTableBody()}
      </ModalWrapper>
    </Modal>
  );
};

const ModalWrapper = styled.div`
  display: flex;
  padding: 1rem 3rem;
  flex-direction: column;
  align-items: center;
  max-width: 700px;

  .newReferralContainer {
    width: 500px;
    .newReferralInputs {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: flex-start;
      .newReferralInput {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .addButton {
      flex: 1 1 0;
      width: 100px;
    }
  }

  .existingReferralContainer {
    width: 500px;
    .existingReferral {
      background-color: #edeef7;
      max-width: 700px;
      display: flex;
      flex-direction: column;
      border-radius: 5px;
      padding: 15px 10px;
      margin-top: 5px;
      .displayRow {
        display: flex;
        justify-content: space-between;
        .infoContainer {
          width: 100%;
        }
        .displayRowButtons {
          display: flex;
          min-width: 118px;
          align-items: center;
          justify-content: space-between;
          button {
            margin: 0px;
            padding: 0.75rem 1.25rem !important;
          }
        }
      }
      .expandable {
        .inputLabel {
          margin-bottom: 2px;
          margin-left: 3px;
        }
        .editableReferralInput {
          margin-bottom: 5px;
        }
        .expandableButtonContainer {
          margin-top: 25px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          button {
            width: 49%;
          }
        }
      }
    }
  }

  @media (max-width: 750px) {
    padding: 10px 15px;
    .existingReferralContainer,
    .newReferralContainer {
      width: 100%;
    }
  }

  @media (max-width: 500px) {
    .expandableButtonContainer {
      flex-direction: column;

      button {
        width: 100% !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px !important;
      }

      button:last-child {
        margin-top: 10px !important;
      }
    }
  }

  @media (max-width: 430px) {
    .displayRow {
      flex-direction: column;
      .displayRowButtons {
        margin-top: 10px;
        button {
          width: 49%;
          max-width: unset;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
`;

const StyledDeleteReferralModal = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
`;

export default ReferralModal;
