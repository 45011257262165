import { FC, useEffect } from 'react';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { getEula } from '../../redux/actions/eulaActions';
import { useFormik } from 'formik';
import { passwordPattern } from '../../constants/regexPatterns';
import { FormikInputField } from '../../components/formik/formik-input-field/FormikInputField';
import { loginAccountSuccess } from '../../redux/actions/accountActions';
import { useHistory, useLocation } from 'react-router-dom';
import { useCreateProfileMutation } from '../../redux/api/manage-invite/manageInviteApi';
import { createProfileFormValidator } from './helpers/createProfileFormValidator';
import { IFormValues, formInitialValues } from './helpers/formInitialValues';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { Button, Grid, Typography, SelectChangeEvent } from '@mui/material';
import {
  implantsYear,
  professions,
  titles,
  yearsOfExperience,
} from '../../constants/referenceData';
import CreateProfileSelect from '../../components/formik/formik-select-input/FormikSelectInput';
import CountrySelect from '../../components/formik/formik-country-select/FormikCountrySelect';
import Spinner from '../../components/Shared/Loaders/Spinner';

interface Props {
  email: string;
  onOpenModalClick: () => void;
}

const CreateProfileForm: FC<Props> = ({ email, onOpenModalClick }) => {
  const intl = useIntl();
  const history = useHistory();

  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('inviteid');
  const userGroupId = query.get('usergroupid');

  const validate = (values: IFormValues) => createProfileFormValidator(values);

  const [createProfile, { isLoading: isCreating, data }] = useCreateProfileMutation();

  const formik = useFormik({
    initialValues: formInitialValues,
    validate,
    enableReinitialize: false,
    validateOnMount: true,
    onSubmit: async (data) => {
      if (userGroupId && inviteId) {
        const { password, repeatPassword, lastName } = data;
        if (password !== repeatPassword) return passwordsDontMatch();

        const isValid = passwordPattern.test(password);
        if (!isValid) return passwordPatternIsNotValid();

        const res = await createProfile({ ...data, userGroupId, inviteId, surname: lastName });
        if ('error' in res && 'data' in res.error) {
          toastr.error(
            intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
            intl.formatMessage({
              id: 'general.requestProblem',
              defaultMessage: 'There was a problem with your request.',
            }),
            { showCloseButton: false }
          );
          return;
        }
        toastr.success(
          intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
          intl.formatMessage({
            id: 'users.createUserSuccess',
            defaultMessage: 'The user was created successfully',
          }),
          { showCloseButton: false }
        );
      }
    },
  });

  const { countries } = useAppSelector((state) => state.referenceData);
  const { eula } = useAppSelector((state) => state.eula);
  const dispatch = useAppDispatch();

  const passwordsDontMatch = () => {
    formik.setFieldError(
      'repeatPassword',
      intl.formatMessage({
        id: 'general.passwordsDoNotMatch',
        defaultMessage: 'Passwords do not match',
      })
    );
  };

  const passwordPatternIsNotValid = () => {
    formik.setFieldError(
      'password',
      intl.formatMessage({
        id: 'registerAccount.Comment',
        defaultMessage:
          'The password must be at least 6 characters long and contain: uppercase, lowercase, number and special sign.',
      })
    );
  };

  const selectCountryHandler = (event: SelectChangeEvent) => {
    const country = event.target.value;
    if (!country) return;
    formik.setFieldValue('eulaCountry', country);
    dispatch(getEula(country));
  };

  useEffect(() => {
    formik.setFieldValue('email', email);
  }, [email]);

  useEffect(() => {
    if (!eula.id) return;
    formik.setFieldValue('eulaId', eula.id);
  }, [eula]);

  useEffect(() => {
    if (!data) return;
    dispatch(loginAccountSuccess(data, email));
    history.push('/home');
  }, [data, email]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} sm={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'profile.userInformation',
                  defaultMessage: 'User information',
                })}
              </Typography>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikInputField
                id={'firstName'}
                disabled={isCreating}
                label={intl.formatMessage({
                  id: 'general.firstName',
                  defaultMessage: 'First name',
                })}
                helperText={formik.touched.firstName && formik.errors.firstName}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                formik={formik}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikInputField
                id={'lastName'}
                disabled={isCreating}
                label={intl.formatMessage({
                  id: 'general.lastName',
                  defaultMessage: 'First name',
                })}
                helperText={formik.touched.lastName && formik.errors.lastName}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikInputField
                id={'email'}
                type={'email'}
                disabled
                label={intl.formatMessage({
                  id: 'general.email',
                  defaultMessage: 'Email',
                })}
                helperText={formik.touched.email && formik.errors.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                formik={formik}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CreateProfileSelect
                id="title"
                label={intl.formatMessage({
                  id: 'general.title',
                  defaultMessage: 'Title',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={titles}
                helperText={formik.touched.title && formik.errors.title}
                error={formik.touched.title && Boolean(formik.errors.title)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <CreateProfileSelect
                id="profession"
                label={intl.formatMessage({
                  id: 'general.profession',
                  defaultMessage: 'Profession',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={professions}
                helperText={formik.touched.profession && formik.errors.profession}
                error={formik.touched.profession && Boolean(formik.errors.profession)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikInputField
                id={'password'}
                type={'password'}
                disabled={isCreating}
                label={intl.formatMessage({
                  id: 'general.password',
                  defaultMessage: 'Password',
                })}
                helperText={formik.touched.password && formik.errors.password}
                error={formik.touched.password && Boolean(formik.errors.password)}
                formik={formik}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormikInputField
                id={'repeatPassword'}
                type={'password'}
                disabled={isCreating}
                label={intl.formatMessage({
                  id: 'general.repeatPassword',
                  defaultMessage: 'Repeat password',
                })}
                helperText={formik.touched.repeatPassword && formik.errors.repeatPassword}
                error={formik.touched.repeatPassword && Boolean(formik.errors.repeatPassword)}
                formik={formik}
              />
            </Grid>
            <Grid item xs={12}>
              <CountrySelect
                id="eulaCountry"
                label={intl.formatMessage({
                  id: 'registerAccount.SelectCountryLabel',
                  defaultMessage: 'Country for applicable terms and condition',
                })}
                value={formik.values.eulaCountry}
                disabled={isCreating}
                menuList={countries}
                onChange={selectCountryHandler}
                helperText={formik.touched.eulaCountry && formik.errors.eulaCountry}
                error={formik.touched.eulaCountry && Boolean(formik.errors.eulaCountry)}
                onOpenModalClick={onOpenModalClick}
                formik={formik}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'profile.experienceOfPlacements',
                  defaultMessage: 'Experience of implant placements',
                })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CreateProfileSelect
                id="placementsYear"
                label={intl.formatMessage({
                  id: 'profile.placementsYear',
                  defaultMessage: 'Implant placements/Year',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={implantsYear}
                helperText={formik.touched.placementsYear && formik.errors.placementsYear}
                error={formik.touched.placementsYear && Boolean(formik.errors.placementsYear)}
              />
            </Grid>
            <Grid item xs={12}>
              <CreateProfileSelect
                id="yearsOfPlacements"
                label={intl.formatMessage({
                  id: 'profile.yearsOfPlacements',
                  defaultMessage: 'Experience working with implant placements (years)',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={yearsOfExperience}
                helperText={formik.touched.yearsOfPlacements && formik.errors.yearsOfPlacements}
                error={formik.touched.yearsOfPlacements && Boolean(formik.errors.yearsOfPlacements)}
              />
            </Grid>
            <Grid item xs={12} height={60}>
              <Typography variant="h5">
                {intl.formatMessage({
                  id: 'profile.experienceOfRestorations',
                  defaultMessage: 'Experience of final restorations',
                })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CreateProfileSelect
                id="restorationsYear"
                label={intl.formatMessage({
                  id: 'profile.restorationsYear',
                  defaultMessage: 'Final restorations/Year',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={implantsYear}
                helperText={formik.touched.restorationsYear && formik.errors.restorationsYear}
                error={formik.touched.restorationsYear && Boolean(formik.errors.restorationsYear)}
              />
            </Grid>
            <Grid item xs={12}>
              <CreateProfileSelect
                id="yearsOfRestorations"
                label={intl.formatMessage({
                  id: 'profile.yearsOfRestorations',
                  defaultMessage: 'Experience working with final restorations (years)',
                })}
                formik={formik}
                disabled={isCreating}
                menuList={yearsOfExperience}
                helperText={formik.touched.yearsOfRestorations && formik.errors.yearsOfRestorations}
                error={
                  formik.touched.yearsOfRestorations && Boolean(formik.errors.yearsOfRestorations)
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" disabled={isCreating} sx={{ float: 'right' }}>
            {isCreating ? (
              <Spinner />
            ) : (
              intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateProfileForm;
