import { toastr } from 'react-redux-toastr';
import { IFirmware } from '../../types/firmware';
import { toastrOptions } from '../../constants/toastrOptions';
import { SectionTabNav } from '../../components/Shared/Section';
import { IAdminListUser } from '../../types/user';
import { useEffect, useState } from 'react';
import { IAdminListInstrument } from '../../types/instrument';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { deleteUser as deleteUserRequest } from '../../redux/actions/userActions';
import { superAdminTabs, adminLv1Tabs, adminLv2Tabs, AdminTabs } from './helpers/adminTabs';
import { retrievePassword as retrievePasswordRequest } from '../../redux/actions/accountActions';
import { deleteInstruments as deleteInstrumentsRequest } from '../../redux/actions/instrumentActions';
import { setInstrumentsType as setInstrumentsTypeRequest } from '../../redux/actions/instrumentActions';
import { setFirmwareToCurrent as setFirmwareToCurrentRequest } from '../../redux/actions/firmwareActions';
import { setInstrumentsComment as setInstrumentsCommentRequest } from '../../redux/actions/instrumentActions';
import { setInstrumentExpectedFirmwares as setInstrumentExpectedFirmwaresRequest } from '../../redux/actions/instrumentActions';
import InstrumentsAdministration from './Instruments/InstrumentsAdministration';
import StudyGroupsAdministration from './StudyGroups/StudyGroupsAdministration';
import FirmwaresAdministration from './Firmwares/FirmwaresAdministration';
import RefListAdministration from './Implants/RefListAdministration';
import UsersAdministration from './Users/UsersAdministration';
import LogsAdministration from './Logs/LogsAdministration';
import ConfirmationModal from '../../components/Shared/Modals/ConfirmationModal/ConfirmationModal';
import Header from '../../components/header/Header';

interface HeaderData {
  header: string;
  headerData?: string;
  text: string;
  action: () => void;
}

const AdminPage = () => {
  const dispatch = useAppDispatch();
  const { user, deleteUser } = useAppSelector((state) => state.user);
  const retrievePassword = useAppSelector((state) => state.account.retrievePassword);
  const setFirmwareToCurrent = useAppSelector((state) => state.firmware.setFirmwareToCurrent);
  const connectUserGroupToInstruments = useAppSelector(
    (state) => state.userGroup.connectUserGroupToInstruments
  );
  const {
    setInstrumentExpectedFirmwares,
    deleteInstruments,
    setInstrumentsType,
    setInstrumentsComment,
  } = useAppSelector((state) => state.instrument);

  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isRetrievingPassword, setIsRetrievingPassword] = useState(false);
  const [isRegisterFirmware, setIsRegisterFirmware] = useState(false);
  const [isConnectUserGroupToInstruments, setIsConnectUserGroupToInstruments] = useState(false);
  const [isInstrumentExpectedFirmwares, setIsInstrumentExpectedFirmwares] = useState(false);
  const [isDeletingInstruments, setIsDeletingInstruments] = useState(false);
  const [isInstrumentsType, setIsInstrumentsType] = useState(false);
  const [isInstrumentsComment, setIsInstrumentsComment] = useState(false);

  const [tabs, setTabs] = useState<AdminTabs[] | undefined>(undefined);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmModalData, setConfirmModalData] = useState<HeaderData>({
    header: '',
    headerData: undefined,
    text: '',
    action: () => {},
  });

  useEffect(() => {
    if (user && user.role === 'SuperAdmin') return setTabs(superAdminTabs);
    if (user.role === 'AdminLv1') return setTabs(adminLv1Tabs);
    if (user.role === 'AdminLv2') return setTabs(adminLv2Tabs);
  }, [user]);

  useEffect(() => {
    if (deleteUser.complete && isDeletingUser) {
      setIsDeletingUser(false);
      toastr.success('Success', 'User was deleted', toastrOptions);
    }
  }, [deleteUser.complete, isDeletingUser]);

  useEffect(() => {
    if (retrievePassword.complete && isRetrievingPassword) {
      setIsRetrievingPassword(false);
      toastr.success('Success', 'Password reset link sent', toastrOptions);
    }
  }, [retrievePassword.complete, isRetrievingPassword]);

  useEffect(() => {
    if (setFirmwareToCurrent.complete && isRegisterFirmware) {
      setIsRegisterFirmware(false);
      toastr.success('Success', 'Current firmware changed', toastrOptions);
    }
  }, [setFirmwareToCurrent.complete, isRegisterFirmware]);

  useEffect(() => {
    if (connectUserGroupToInstruments.complete && isConnectUserGroupToInstruments) {
      setIsConnectUserGroupToInstruments(false);
      toastr.success('Success', 'Instrument(s) connected to user group', toastrOptions);
    }
  }, [connectUserGroupToInstruments.complete, isConnectUserGroupToInstruments]);

  useEffect(() => {
    if (setInstrumentExpectedFirmwares.complete && isInstrumentExpectedFirmwares) {
      setIsInstrumentExpectedFirmwares(false);
      toastr.success('Success', 'Instrument(s) expected firmwware set', toastrOptions);
    }
  }, [setInstrumentExpectedFirmwares.complete, isInstrumentExpectedFirmwares]);

  useEffect(() => {
    if (deleteInstruments.complete && isDeletingInstruments) {
      setIsDeletingInstruments(false);
      toastr.success('Success', 'Instrument(s) deleted', toastrOptions);
    }
  }, [deleteInstruments.complete, isDeletingInstruments]);

  useEffect(() => {
    if (setInstrumentsType.complete && isInstrumentsType) {
      setIsInstrumentsType(false);
      toastr.success('Success', 'Instrument(s) updated', toastrOptions);
    }
  }, [setInstrumentsType.complete, isInstrumentsType]);

  useEffect(() => {
    if (setInstrumentsComment.complete && isInstrumentsComment) {
      setIsInstrumentsComment(false);
      toastr.success('Success', 'Instrument(s) comment updated', toastrOptions);
    }
  }, [setInstrumentsComment.complete, isInstrumentsComment]);

  const showDeleteUserModal = (user: IAdminListUser, callback: () => void) => {
    setShowConfirmationModal(true);
    setConfirmModalData({
      header: 'Delete user',
      headerData: user.firstName + ' ' + user.surName,
      text: 'Do you want to continue?',
      action: () => deleteUserHandler(user.id, callback),
    });
  };

  const showDeleteInstrumentsModal = (
    instruments: IAdminListInstrument[],
    callback: () => void
  ) => {
    const serialNumbers = instruments.map((instrument) => instrument.serialNumber).join(', ');
    const header = instruments.length > 1 ? 'Delete instruments' : 'Delete instrument';
    setShowConfirmationModal(true);
    setConfirmModalData({
      header: header,
      text: `Delete instrument: ${serialNumbers}. Do you want to continue?`,
      action: () =>
        deleteInstrumentsHandler(
          instruments.map((instrument) => instrument.id),
          callback
        ),
    });
  };

  const showUpdateInstrumentsFirmwareModal = (
    instrumentIds: string[],
    firmware: IFirmware,
    callback: () => void
  ) => {
    const header =
      'Push firmware ' +
      firmware.firmwareVersion +
      ' to ' +
      instrumentIds.length +
      ' instrument(s)';
    setShowConfirmationModal(true);
    setConfirmModalData({
      header: header,
      text: 'Do you want to continue?',
      action: () => updateInstrumentsFirmwareHandler(instrumentIds, firmware.id, callback),
    });
  };

  const showClearInstrumentsFirmwareModal = (instrumentIds: string[], callback: () => void) => {
    const header = 'Remove pushed firmware on ' + instrumentIds.length + ' instrument(s)';
    setShowConfirmationModal(true);
    setConfirmModalData({
      header: header,
      text: 'Do you want to continue?',
      action: () => clearInstrumentsFirmware(instrumentIds, callback),
    });
  };

  const showUpdateInstrumentsTypeAndStatus = (
    instrumentType: string,
    instrumentStatus: { Demo: boolean; Development: boolean; Blocked: boolean },
    comment: string,
    instrumentIds: string[],
    callback: () => void
  ) => {
    const header = 'Update type on ' + instrumentIds.length + ' instrument(s)';
    const headerData =
      'Type: ' +
      instrumentType +
      ', Demo: ' +
      instrumentStatus.Demo +
      ', Development: ' +
      instrumentStatus.Development +
      ', Blocked: ' +
      instrumentStatus.Blocked +
      ', Comment: ' +
      comment;

    setShowConfirmationModal(true);
    setConfirmModalData({
      header: header,
      headerData: headerData,
      text: 'Do you want to continue?',
      action: () =>
        updateInstrumentsTypeAndStatusHandler(
          instrumentType,
          instrumentStatus,
          comment,
          instrumentIds,
          callback
        ),
    });
  };

  const hideConfirmationModal = () => setShowConfirmationModal(false);

  const deleteUserHandler = (userId: string, callback: () => void) => {
    setIsDeletingUser(true);
    dispatch(deleteUserRequest(userId, callback));
  };

  const retrievePasswordHandler = (email: string) => {
    setIsRetrievingPassword(true);
    dispatch(retrievePasswordRequest(email));
  };

  const setFirmwareToCurrentHandler = (firmwareId: string) => {
    setIsRegisterFirmware(true);
    dispatch(setFirmwareToCurrentRequest(firmwareId));
  };

  const updateInstrumentsFirmwareHandler = (
    instrumentIds: string[],
    firmwareId: string,
    callback: () => void
  ) => {
    setIsInstrumentExpectedFirmwares(true);
    dispatch(setInstrumentExpectedFirmwaresRequest(instrumentIds, firmwareId, callback));
  };

  const clearInstrumentsFirmware = (instrumentIds: string[], callback: () => void) => {
    setIsInstrumentExpectedFirmwares(true);
    dispatch(setInstrumentExpectedFirmwaresRequest(instrumentIds, null, callback));
  };

  const deleteInstrumentsHandler = (instrumentIds: string[], callback: () => void) => {
    setIsDeletingInstruments(true);
    dispatch(deleteInstrumentsRequest(instrumentIds, callback));
  };

  const updateInstrumentsTypeAndStatusHandler = (
    instrumentType: string,
    instrumentStatus: { Demo: boolean; Development: boolean; Blocked: boolean },
    comment: string,
    instrumentIds: string[],
    callback: () => void
  ) => {
    setIsInstrumentsType(true);
    dispatch(
      setInstrumentsTypeRequest(instrumentType, instrumentStatus, comment, instrumentIds, callback)
    );
  };

  const updateInstrumentsComment = (
    comment: string,
    instrumentIds: string[],
    callback: () => void
  ) => {
    setIsInstrumentsComment(true);
    dispatch(setInstrumentsCommentRequest(comment, instrumentIds, callback));
  };

  const renderTabs = () => {
    return (
      <>
        {user.role === 'SuperAdmin' && (
          <SectionTabNav tabs={tabs}>
            <UsersAdministration
              deleteUser={showDeleteUserModal}
              retrievePassword={retrievePasswordHandler}
            />
            <InstrumentsAdministration
              updateInstrumentsFirmware={showUpdateInstrumentsFirmwareModal}
              clearInstrumentsFirmware={showClearInstrumentsFirmwareModal}
              updateInstrumentsTypeAndStatus={showUpdateInstrumentsTypeAndStatus}
              deleteInstruments={showDeleteInstrumentsModal}
              updateInstrumentsComment={updateInstrumentsComment}
            />
            <FirmwaresAdministration setFirmwareToCurrent={setFirmwareToCurrentHandler} />
            <StudyGroupsAdministration />
            <LogsAdministration />
            <RefListAdministration />
          </SectionTabNav>
        )}
        {user.role === 'AdminLv1' && (
          <SectionTabNav tabs={tabs}>
            <UsersAdministration
              deleteUser={showDeleteUserModal}
              retrievePassword={retrievePasswordHandler}
            />
            <InstrumentsAdministration
              updateInstrumentsFirmware={showUpdateInstrumentsFirmwareModal}
              clearInstrumentsFirmware={showClearInstrumentsFirmwareModal}
              updateInstrumentsTypeAndStatus={showUpdateInstrumentsTypeAndStatus}
              deleteInstruments={showDeleteInstrumentsModal}
              updateInstrumentsComment={updateInstrumentsComment}
            />
            <LogsAdministration />
          </SectionTabNav>
        )}
        {user.role === 'AdminLv2' && (
          <SectionTabNav tabs={tabs}>
            <UsersAdministration
              deleteUser={showDeleteUserModal}
              retrievePassword={retrievePasswordHandler}
            />
            <InstrumentsAdministration
              updateInstrumentsFirmware={showUpdateInstrumentsFirmwareModal}
              clearInstrumentsFirmware={showClearInstrumentsFirmwareModal}
              updateInstrumentsTypeAndStatus={showUpdateInstrumentsTypeAndStatus}
              deleteInstruments={showDeleteInstrumentsModal}
              updateInstrumentsComment={updateInstrumentsComment}
            />
          </SectionTabNav>
        )}
      </>
    );
  };

  return (
    <div>
      <Header title="Admin" isTogglerHidden />
      {tabs && renderTabs()}
      <ConfirmationModal
        header={confirmModalData.header}
        headerData={confirmModalData.headerData}
        text={confirmModalData.text}
        show={showConfirmationModal}
        hide={hideConfirmationModal}
        action={confirmModalData.action}
      />
    </div>
  );
};

export default AdminPage;
