import React, { SVGProps } from 'react';
import { ImageResponsive } from './ImageResponsive';
import { zeroPad } from '../../utils/transformations';
import { ImageSvg } from './ImageSvg';

const transformToSrcName = (toothIndex: number) => zeroPad((toothIndex % 16) + 1, 2);

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  toothIndex: number;
  svg?: boolean;
  format?: string;
}

export const ToothGraphic: React.FC<Props> = ({ toothIndex, format = 'png', ...props }) => {
  if (toothIndex < 0) return null;
  const path = toothIndex < 16 ? 'teeth-upper-jaw' : 'teeth-lower-jaw';
  return (
    <ImageResponsive
      src={require(`./consultation/${path}/${transformToSrcName(toothIndex + 1)}.${format}`)}
      src2x={require(`./consultation/${path}/${transformToSrcName(toothIndex + 1)}@2x.${format}`)}
      {...props}
    />
  );
};

interface PropsSVG extends SVGProps<SVGImageElement> {
  toothIndex: number;
  width: string | number;
  height: string | number;
  format?: string;
}

export const SvgToothGraphic: React.FC<PropsSVG> = ({
  toothIndex,
  width,
  height,
  format = 'png',
  ...props
}) => {
  if (toothIndex < 0) return null;
  const path = toothIndex < 16 ? 'teeth-upper-jaw' : 'teeth-lower-jaw';
  return (
    <ImageSvg
      {...props}
      src={require(`./consultation/${path}/${transformToSrcName(toothIndex)}@2x.${format}`)}
      width={width}
      height={height}
    />
  );
};
