import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { TeethDraggableNode } from './TeethDraggableNode';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { UPDATE_NODE } from '../../../../../../redux/slices/workspaceSlice';
import SvgTeethNerveLine from './SvgTeethNerveLine';
import { Position } from '../../../../../../redux/slices/data-structures/workspaceStructure';

export function TeethNerveShape({
  rightSide = false,
  enabled = false,
}: {
  rightSide?: boolean;
  enabled?: boolean;
}) {
  const { nodes } = useAppSelector(
    (state) => state.consultation.workspace.present.nerveState[rightSide ? 1 : 0]
  );
  const dispatch = useAppDispatch();
  const boundingArea = useRef<HTMLDivElement>(null);
  const nodeRefs = useRef<Array<HTMLDivElement>>([]);

  const [dragMode, setDragMode] = useState(false);
  const [posUpdate, setPosUpdate] = useState(false);
  const [nodePositions, setNodePositions] = useState<Array<gsap.Point2D | null>>([]);
  const area = rightSide ? 'NERVES_RIGHT' : 'NERVES_LEFT';

  useEffect(() => {
    setNodePositions(
      nodes.map((_node: Position, index: number) => {
        const wrapper = nodeRefs.current[index];
        if (wrapper) {
          const nodeElement = wrapper.getElementsByTagName('div')[0];
          return {
            x: parseFloat(gsap.getProperty(nodeElement, 'x').toString()),
            y: parseFloat(gsap.getProperty(nodeElement, 'y').toString()),
          };
        }
        return null;
      })
    );
  }, [posUpdate, nodes]);

  return (
    <PlacedArea side={rightSide}>
      <NerveArea ref={boundingArea}>
        {nodes.map((node: Position, i: number) => (
          <TeethDraggableNode
            key={'sinus_left_' + i}
            ref={(el) => (el ? (nodeRefs.current[i] = el) : undefined)}
            storedX={node.x}
            storedY={node.y}
            enabled={dragMode}
            setEnabled={setDragMode}
            onDragEnd={(_: PointerEvent, p) => {
              dispatch(
                UPDATE_NODE({
                  nodeArea: area,
                  id: i,
                  x: Math.round(p.x),
                  y: Math.round(p.y),
                })
              );
            }}
            onDrag={(_, __) => setPosUpdate((update) => !update)}
            bounds={boundingArea.current}
          />
        ))}
      </NerveArea>
      <SvgTeethNerveLine
        enabled={enabled}
        positions={nodePositions}
        onClick={() => enabled && setDragMode((mode) => !mode)}
      />
    </PlacedArea>
  );
}

const NerveArea = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-width: 400px;
  min-height: 200px;

  pointer-events: none;
`;
const PlacedArea = styled.section<{ side: boolean }>`
  position: relative;
  transform: ${(props) => (props.side ? 'scaleX(-1)' : 'none')};
  top: 0;
  width: 500px;
  margin: 0 auto;
  pointer-events: none;
`;
