import * as types from '../../constants/actionTypes';

const initialState = {
  getInstrumentLog: {
    complete: false,
    error: undefined,
    busy: false,
    logs: [],
  },
  getAdminLog: {
    complete: false,
    error: undefined,
    busy: false,
    logs: [],
  },
};

export default function log(state = initialState, action) {
  switch (action.type) {
    case types.GET_INSTRUMENT_LOG:
      return Object.assign({}, state, {
        getInstrumentLog: { complete: false, error: undefined, busy: true, logs: [] },
      });
    case types.GET_INSTRUMENT_LOG_SUCCESS:
      return Object.assign({}, state, {
        getInstrumentLog: { complete: true, error: undefined, busy: false, logs: action.logs },
      });
    case types.GET_INSTRUMENT_LOG_ERROR:
      return Object.assign({}, state, {
        getInstrumentLog: { complete: false, error: action.error, busy: false, logs: [] },
      });
    case types.GET_ADMIN_LOG:
      return Object.assign({}, state, {
        getAdminLog: { complete: false, error: undefined, busy: true, logs: [] },
      });
    case types.GET_ADMIN_LOG_SUCCESS:
      return Object.assign({}, state, {
        getAdminLog: { complete: true, error: undefined, busy: false, logs: action.logs },
      });
    case types.GET_ADMIN_LOG_ERROR:
      return Object.assign({}, state, {
        getAdminLog: { complete: false, error: action.error, busy: false, logs: [] },
      });
    case types.CLEAR_LOGS:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
