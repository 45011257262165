import { FC, useState } from 'react';
import Dropdown from '../../components/Shared/Dropdown/Dropdown';
import styled from 'styled-components';
import Button from '../Shared/Buttons/Button';

interface Props {
  renderGraphOne: () => void;
  renderGraphTwo: () => void;
  optionOne: string;
  optionTwo: string;
}

const LineChartOptionWrapper: FC<Props> = ({
  renderGraphOne,
  renderGraphTwo,
  optionOne,
  optionTwo,
}) => {
  const [showGraphOne, setShowGraphOne] = useState(true);
  const dropdownOptions = [
    {
      label: optionOne,
      action: () => setShowGraphOne(true),
    },
    {
      label: optionTwo,
      action: () => setShowGraphOne(false),
    },
  ];

  return (
    <StyledLineChartOptionWrapper>
      <div className="dropdown-container">
        <Dropdown
          options={dropdownOptions}
          align={undefined}
          filterServer={undefined}
          footer={undefined}
          dataCy={undefined}
          actionData={undefined}
          noTranslate={undefined}
          fullWidth={undefined}
          searchBox={undefined}
          searchSelect={undefined}
          className={undefined}
          disabled={undefined}
          onChange={undefined}
        >
          <Button
            className={'dropdown'}
            disabled={false}
            primary
            value={showGraphOne ? optionOne : optionTwo}
            rightIcon="arrow_down"
          />
        </Dropdown>
      </div>
      {showGraphOne && renderGraphOne()}
      {!showGraphOne && renderGraphTwo()}
    </StyledLineChartOptionWrapper>
  );
};

const StyledLineChartOptionWrapper = styled.div`
  background-color: white;
  max-width: 1700px;
  height: 100vh;
  min-width: 500px;
  border-radius: 10px;
  margin-bottom: 20px;
  .dropdown-container {
    box-sizing: border-box;
    width: 100px;
    margin-left: 10px;
    margin-top: 10px;
    .dropdown {
      width: 300px;
    }
  }
`;

export default LineChartOptionWrapper;
