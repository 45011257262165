import React, { ChangeEvent, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import styled from 'styled-components';
import { OverlayBoxRounded } from '../../common/graphics/lib/StyledComponentLibrary';
import SvgCameraliga from '../../shared/icons/osstell-light/CameraLiga';
import {
  addImages,
  deleteImage,
  getImage,
  getThumbNails,
  PatientImage,
  CLOSE_IMAGE,
} from '../../redux/slices/patientImageSlice';
import Resizer from 'react-image-file-resizer';

import Modal from '../../components/Shared/Modal/Modal';
import Spinner from '../Shared/Loaders/Spinner';
import { Button } from '../Shared/Buttons';
import { SET_IMAGE } from '../../redux/slices/workspaceSlice';
import { TOGGLE_MODE_IMAGE_LIBRARY } from '../../redux/slices/UISlice';

interface Props {
  onImageIncluded?: () => void;
}
export const ImageViewer: React.FC<Props> = ({ onImageIncluded }) => {
  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();
  const intl = useIntl();
  const image = useAppSelector((state) => state.patientImages.image);
  const getImageStatus = useAppSelector((state) => state.patientImages.getImageStatus);
  const deleteImageStatus = useAppSelector((state) => state.patientImages.deleteImageStatus);
  const dispatch = useAppDispatch();

  const onDelete = () => {
    dispatch(
      deleteImage({
        userGroupId: userGroupId ?? '',
        patientId: patientId ?? '',
        imageId: image.id ?? '',
      })
    ).then(() => {
      dispatch(getThumbNails({ patientId: patientId ?? '', userGroupId: userGroupId ?? '' }));
      dispatch(CLOSE_IMAGE());
    });
  };

  return (
    <Modal
      header={image?.name}
      fullModal={true}
      show={image !== undefined || getImageStatus.busy}
      onDismiss={() => dispatch(CLOSE_IMAGE())}
    >
      {getImageStatus.busy ? (
        <Spinner purple />
      ) : (
        <ImageViewerWrapper>
          <div>
            <img src={image?.image} alt="" style={{ maxHeight: '70vh' }} />
          </div>
          <div style={{ float: 'right', marginTop: '10px' }}>
            <Button
              loading={deleteImageStatus.busy}
              disabled={deleteImageStatus.busy}
              centered
              value={intl.formatMessage({
                id: 'general.delete',
                defaultMessage: 'Delete',
              })}
              onClick={onDelete}
              leftIcon="delete"
            />
            <Button
              centered
              value={intl.formatMessage({
                id: 'general.addToStep',
                defaultMessage: 'Add to step',
              })}
              onClick={() => {
                dispatch(SET_IMAGE(image.image));
                dispatch(CLOSE_IMAGE());
                onImageIncluded?.();
              }}
              leftIcon="add"
            />
          </div>
        </ImageViewerWrapper>
      )}
    </Modal>
  );
};

const ImageViewerWrapper = styled.div``;

export default function ImageLibrary() {
  const intl = useIntl();
  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();
  const dispatch = useAppDispatch();
  const thumbnails = useAppSelector((state) => state.patientImages.thumbnails);
  const addImagesLoading = useAppSelector((state) => state.patientImages.addImagesStatus.busy);

  //const [pictures, setPictures] = useState<Array<PatientImage>>([]);

  useEffect(() => {
    dispatch(getThumbNails({ patientId: patientId ?? '', userGroupId: userGroupId ?? '' }));
  }, [patientId, userGroupId, dispatch]);

  const onDrop = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const picture = files[0];
    if (picture === undefined || !picture.type.startsWith('image/')) return;
    const image = await resizeImage(picture, 1000);
    const thumbnail = await resizeImage(picture, 200);
    dispatch(
      addImages({
        userGroupId: userGroupId ?? '',
        patientId: patientId ?? '',
        images: [
          {
            name: picture.name,
            imageType: picture.type,
            image: image,
            thumbnailType: 'image/png',
            thumbnail: thumbnail,
          },
        ],
      })
    ).then(() => {
      dispatch(getThumbNails({ patientId: patientId ?? '', userGroupId: userGroupId ?? '' }));
    });
  };

  const resizeImage = (file: File, size: number): Promise<string> => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        size,
        size,
        'PNG',
        100,
        0,
        (uri) => {
          resolve(uri as string);
        },
        'base64'
      );
    });
  };

  return (
    <ImageListWrapper>
      <div>
        {thumbnails.map((image: PatientImage, index: number) => {
          return (
            <ImageWrapper
              key={`image_thumbnail${index}`}
              onClick={() => {
                dispatch(
                  getImage({
                    userGroupId: userGroupId ?? '',
                    patientId: patientId ?? '',
                    imageId: image.id ?? '',
                  })
                ).then(() => dispatch(TOGGLE_MODE_IMAGE_LIBRARY()));
              }}
            >
              <img src={image.image} alt="" />
            </ImageWrapper>
          );
        })}
        {addImagesLoading && (
          <ImageWrapper>
            <Spinner purple />
          </ImageWrapper>
        )}
        <StyledAddButton>
          <input type="file" name="file" id="file" accept="image/*" onChange={onDrop} />
          <label htmlFor="file">
            <SvgCameraliga />
            <span>{intl.formatMessage({ id: 'general.add', defaultMessage: 'Add' })}</span>
          </label>
        </StyledAddButton>
      </div>
    </ImageListWrapper>
  );
}

const StyledAddButton = styled.div`
  width: 90px;
  border: 1px solid #ededed;
  border-radius: 16px;
  input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: 9px;
    white-space: nowrap;
  }
  svg {
    width: 2.5rem;
    height: 2.2rem;
  }
`;

const ImageListWrapper = styled(OverlayBoxRounded)`
  background-color: white;
  padding: 0.5rem;
  margin-left: 5px;

  & > div {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    & > div:not(:first-child) {
      margin-top: 8px;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 90px;
  height: 75px;
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ededed;
  border-radius: 16px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
