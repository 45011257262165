import React from 'react';
import cn from 'classnames';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import styles from './KeyPerformanceIndicator.module.scss';

const KeyPerformanceIndicator = (props) => {
  let value = (
    <div className={styles.value}>
      <FormattedNumber maximumFractionDigits={1} value={props.value} />
    </div>
  );
  if (props.percentage) {
    value = (
      <div className={styles.value}>
        <FormattedNumber maximumFractionDigits={0} value={props.value} />
        <span>%</span>
      </div>
    );
  } else if (props.days) {
    value = (
      <div className={styles.value}>
        <FormattedNumber maximumFractionDigits={1} value={props.value} />
        <span className={styles.trailingText}>
          <FormattedMessage id="general.days" defaultMessage={'days'} />
        </span>
      </div>
    );
  }
  const displayLabel = props.label.split(/(?=[A-Z])/).join(' ');
  return (
    <div className={cn(styles.keyPerformanceIndicator, props.className)}>
      {value}
      <div className={styles.label}>
        <FormattedMessage id={`referenceData.${props.label}`} defaultMessage={displayLabel} />
      </div>
    </div>
  );
};

export default KeyPerformanceIndicator;
