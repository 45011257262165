import React, { Component } from 'react';

import styles from './EditableItem.module.scss';

import { InputItem } from '../Forms';

class EditableItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(evt) {
    this.setState({
      value: evt.target.value,
    });
  }

  render() {
    if (this.props.edit) {
      return (
        <InputItem
          type={this.props.type}
          small
          noPadding
          value={this.state.value}
          onChange={this.onChange}
          onWhite
          className={this.props.noMarginTop && styles.noMarginTop}
        />
      );
    }
    return <div className={styles.displayValue}>{this.state.value}</div>;
  }
}

export default EditableItem;
