import { Theme } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

export const clinicTogglerStyles = makeStyles((theme: Theme) => ({
  select: {
    marginRight: theme.spacing(2),
    width: 225,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      background: 'white',
      marginRight: theme.spacing(0),
    },
  },
}));
