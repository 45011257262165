import * as React from 'react';

function SvgTransparentliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1517 2048" {...props}>
      <path d="M1107 357q73 0 133 30.5t97.5 80.5 58 111.5T1416 705q0 78-10.5 180t-37 225-65 224.5-102.5 170-141 68.5H459q-77 0-141-68.5t-102.5-170T150 1110t-37.5-225T102 705q0-85 30.5-157T232 427.5 399 379q108 0 189 38t171 108l226 185q9 7 9 16t-6 15-15 6q-6 0-14-6-181-147-226-182-84-65-160-101t-174-36q-67 0-118 25.5T201.5 515 159 605t-14 100q0 78 10.5 179.5t35.5 216 60 209.5 89.5 158 118.5 63h601q64 0 118-63t89-158 60-209.5 36-216 11-179.5q0-37-9-77t-29.5-81.5-50.5-74-76-53-102-20.5q-108 0-195 52-8 4-16 2t-13-10q-11-18 7-29 94-57 217-57z" />
    </svg>
  );
}

export default SvgTransparentliga;
