import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { H2 } from '../../Headings';
import SvgBackliga from '../../../../shared/icons/osstell-regular/BackLiga';
import Button from '../../../../components/Shared/Buttons/Button';
import { dismissNote } from '../../../../redux/actions/userActions';

interface WizardSteps {
  text: string;
  title: string;
  ref: any;
  getCalculatedX: (DOMRect: DOMRect, wizardHeight: number, wizardWidth: number) => number;
  getCalculatedY: (DOMRect: DOMRect, wizardHeight: number, wizardWidth: number) => number;
  arrowDirection: string;
}

const WhatsNewWizard: React.FC<{ wizardSteps: WizardSteps[] }> = ({ wizardSteps }) => {
  const [currentWizardStep, setCurrentWizardStep] = useState<number>(0);
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const wizardHeight = 170;
  const wizardWidth = 300;
  const dispatch = useAppDispatch();
  const dismissNotes = useAppSelector((state) => state.user.user.dismissedNotes);
  const dismissNoteId = 'c0c1f4f0-583e-4ccb-94da-a342a98d0d87';
  const shouldRenderWizard = !dismissNotes.includes(dismissNoteId);
  const refsHaveLoaded = x !== 0 && y !== 0;

  useEffect(() => {
    if (wizardSteps[currentWizardStep].ref.current) {
      const DOMRect = wizardSteps[currentWizardStep].ref.current.getBoundingClientRect();
      setY(wizardSteps[currentWizardStep].getCalculatedY(DOMRect, wizardHeight, wizardWidth));
      setX(wizardSteps[currentWizardStep].getCalculatedX(DOMRect, wizardHeight, wizardWidth));
    }
  });

  return (
    <>
      {shouldRenderWizard && refsHaveLoaded ? (
        <StyledWhatsNewWizard
          y={y}
          x={x}
          arrowDirection={wizardSteps[currentWizardStep].arrowDirection}
        >
          <div className="wrapper">
            <H2>{wizardSteps[currentWizardStep].title}</H2>
            <p>{wizardSteps[currentWizardStep].text}</p>
            <div className="buttonContainer">
              {currentWizardStep !== 0 && (
                <div
                  className={'previous'}
                  onClick={() => {
                    if (currentWizardStep === 0) return;
                    setCurrentWizardStep(currentWizardStep - 1);
                  }}
                >
                  <SvgBackliga fill={'black'} width={32} height={32} className={'next_step'} />
                </div>
              )}

              {wizardSteps.length - 1 === currentWizardStep && (
                <Button
                  primary
                  small
                  full
                  value={'Got it!'}
                  onClick={() => {
                    dispatch(dismissNote(dismissNoteId));
                  }}
                  disabled={false}
                />
              )}
              {currentWizardStep !== wizardSteps.length - 1 && (
                <div
                  className={'next'}
                  onClick={() => {
                    if (currentWizardStep === wizardSteps.length - 1) return;

                    setCurrentWizardStep(currentWizardStep + 1);
                  }}
                >
                  <SvgBackliga fill={'black'} width={32} height={32} className={'next_step'} />
                </div>
              )}
            </div>
          </div>
          <div className="arrow"></div>
          <div className="currentStepDiv">{`${currentWizardStep + 1}/${wizardSteps.length}`}</div>
          <i
            className="removeImageButton"
            onClick={() => {
              dispatch(dismissNote(dismissNoteId));
            }}
          >
            close
          </i>
        </StyledWhatsNewWizard>
      ) : null}
    </>
  );
};

const StyledWhatsNewWizard = styled.div<{
  y: number;
  x: number;
  arrowDirection: string;
}>`
  -moz-box-shadow: 0px 0px 10px 0 rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 30%);
  box-shadow: 0px 0px 10px 0 rgb(0 0 0 / 30%);
  display: block;
  height: 170px;
  width: 300px;
  background-color: white;
  border-radius: 8px;
  position: absolute;
  ${(props) => `top: ${props.y}px;`}
  ${(props) => `left: ${props.x}px;`}
  z-index: 100000;

  .wrapper {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    h2 {
      margin-top: 8px;
      text-align: center;
    }
    p {
      width: 90%;
      margin: 0 auto;
      text-align: center;
      word-wrap: break-word;
    }
    top: 0px;
    .buttonContainer {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 77px;
      position: absolute;
      bottom: 0px;
      .next,
      .previous {
        margin: auto 0px;
      }
      button {
        margin: 0px;
        padding: 0px;
        width: 20px;
        height: 10px !important;
      }

      .previous {
        position: absolute;
        left: 14px;
        bottom: 17px;
      }

      .next {
        position: absolute;
        right: 14px;
        bottom: 17px;
      }

      & .previous {
        padding: 0 5px;
        cursor: pointer;
      }
      & .next {
        padding: 0 5px;
        transform: scaleX(-1);
        cursor: pointer;
      }
    }
    .next_step:hover {
      fill: var(--color-vividpurple);
    }
  }
  .arrow {
    width: 50px;
    height: 50px;
    position: relative;
    background-color: white;
    transform: rotate(45deg);
    z-index: -1;
    border-radius: 8px;

    ${(props) => {
      switch (props.arrowDirection) {
        case 'bottom':
          return `
          top: 140px;
          left: 125px;`;
        case 'top':
          return `
            top: -19px;
            left: 125px;`;
        case 'left':
          return `
          top: 60px;
          left: -19px;`;
        case 'right':
          return `
          top: 60px;
          left: 266px;`;
      }
      return '';
    }}
  }

  .removeImageButton {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    color: var(--color-vividpurple);
    top: 1px;
    right: 9px;
  }

  .currentStepDiv {
    position: absolute;
    top: 7px;
    left: 10px;
  }
`;

export default WhatsNewWizard;
