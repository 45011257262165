import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as translationActions from '../../redux/actions/translationActions';

import styles from './SmartPegToolPage.module.scss';

import SmartPegTool from '../../components/SmartPegTool/SmartPegTool';

class SmartPegToolPage extends Component {
  render() {
    const draft = this.props.match.params.draft === 'draft';
    return (
      <div className={styles.smartPegToolPage}>
        <div className={styles.fullHeight}>
          <SmartPegTool draft={draft} />
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    translations: state.translation.currentTranslations,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...translationActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(SmartPegToolPage);
