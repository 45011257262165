import { useIntl } from 'react-intl';
import { FC, useState } from 'react';
import { useAppSelector } from '../../utils/hooks';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';
import { Button, Dialog, DialogActions, DialogTitle, Paper } from '@mui/material';
import CreateProfileForm from './CreateProfileForm';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  email: string;
}

const CreateProfilePanel: FC<Props> = ({ email }) => {
  const intl = useIntl();
  const { eula } = useAppSelector((state) => state.eula);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialogHandler = () => setIsDialogOpen(true);
  const closeDialogHandler = () => setIsDialogOpen(false);

  return (
    <>
      <Paper>
        <Logo />
        <CreateProfileForm email={email} onOpenModalClick={openDialogHandler} />
      </Paper>
      <Dialog open={isDialogOpen} onClose={closeDialogHandler} maxWidth="lg">
        <DialogTitle sx={{}}>
          {intl.formatMessage({
            id: 'registerAccount.TermsAndConditions',
            defaultMessage: 'Terms and conditions',
          })}
          <CloseIcon onClick={closeDialogHandler} cursor={'pointer'} />
        </DialogTitle>
        {eula && <div dangerouslySetInnerHTML={eula.data} />}
        <DialogActions>
          <Button variant="outlined" onClick={closeDialogHandler}>
            {intl.formatMessage({ id: 'general.Close', defaultMessage: 'Close' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CreateProfilePanel;
