import * as types from '../../constants/actionTypes';

const initialState = {
  getEula: {
    complete: false,
    error: undefined,
    busy: false,
  },
  acceptEula: {
    complete: false,
    error: undefined,
    busy: false,
  },
  eula: {},
};

export default function eula(state = initialState, action) {
  let eula;
  switch (action.type) {
    case types.GET_EULA:
      return Object.assign({}, state, {
        getEula: { complete: false, error: undefined, busy: true },
        eula: {},
      });
    case types.GET_EULA_SUCCESS:
      eula = action.data;
      eula.data = {
        __html: eula.data,
      };
      return Object.assign({}, state, {
        getEula: { complete: true, error: undefined, busy: false },
        eula: eula,
      });
    case types.GET_EULA_ERROR:
      return Object.assign({}, state, {
        getEula: { complete: false, error: action.error, busy: false },
        eula: {},
      });
    case types.ACCEPT_EULA:
      return Object.assign({}, state, {
        acceptEula: { complete: false, error: undefined, busy: true },
      });
    case types.ACCEPT_EULA_SUCCESS:
      return Object.assign({}, state, {
        acceptEula: { complete: true, error: undefined, busy: false },
      });
    case types.ACCEPT_EULA_ERROR:
      return Object.assign({}, state, {
        acceptEula: { complete: false, error: action.error, busy: false },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
