import { Button } from '../Shared/Buttons';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { AlertBox } from '../Shared/Alerts';
import { IStudyGroup } from '../../types/studyGroups';
import { toastrOptions } from '../../constants/toastrOptions';
import { IDentalNotation } from '../../types/dentalNotation';
import { IReferenceDataObj } from '../../types/referenceData';
import { IImplant, IImplantStudyGroup } from '../../types/implant';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { createImplant as createImplantRequest } from '../../redux/actions/patientActions';
import { getStudyGroups as getStudyGroupsRequest } from '../../redux/slices/studyGroupSlice';
import { InputItem, InputRow, InputGroup, InputSubGroup } from '../Shared/Forms';
import styles from './CreateTreatmentModal.module.scss';
import Modal from '../Shared/Modal/Modal';

interface Props {
  show: boolean;
  implant: IImplant | null;
  hide: () => void;
}

const CreateTreatmentModal: FC<Props> = ({ show, implant, hide }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [comment, setComment] = useState('');
  const [loadingProtocol, setLoadingProtocol] = useState('NoSelection');
  const [plannedProstheticSolution, setPlannedProstheticSolution] = useState('NoSelection');
  const [surgicalProtocol, setSurgicalProtocol] = useState('NoSelection');
  const [insertionTorque, setInsertionTorque] = useState(-1);
  const [studyGroupsList, setStudyGroupsList] = useState<IImplantStudyGroup[]>([]);
  const [prePlacementProcedure, setPrePlacementProcedure] = useState('NoSelection');
  const [boneQuality, setBoneQuality] = useState('');
  const [implantSitePlacement, setImplantSitePlacement] = useState('NoSelection');
  const [augmentationMaterial, setAugmentationMaterial] = useState('NoSelection');
  const [surgicalTechnique, setSurgicalTechnique] = useState('NoSelection');

  const [isCreatingImplant, setIsCreatingImplant] = useState(false);

  const { user } = useAppSelector((state) => state.user);
  const {
    createImplant,
    currentPatient: patient,
    selectedPatientImplant: selectedImplant,
  } = useAppSelector((state) => state.patient);
  const {
    dentalNotifications,
    loadingProtocols,
    plannedProstheticSolutions,
    surgicalProtocols,
    prePlacementProcedures,
    implantSitePlacementOptions,
    augmentationMaterialOptions,
    surgicalTechniqueOptions,
  } = useAppSelector((state) => state.referenceData);

  const { studyGroups } = useAppSelector((state) => state.studyGroup);

  const modifiedPrePlacementProcedures = prePlacementProcedures.map((x: IReferenceDataObj) => ({
    key: x.key,
    value: intl.formatMessage({ ...x.label }),
  }));

  const boneQualityOptions = [{ value: '1' }, { value: '2' }, { value: '3' }, { value: '4' }];

  const modifiedImplantSitePlacementOptions = implantSitePlacementOptions.map(
    (x: IReferenceDataObj) => ({
      key: x.key,
      value: intl.formatMessage({ ...x.label }),
    })
  );

  const modifiedAugmentationMaterialOptions = augmentationMaterialOptions.map(
    (x: IReferenceDataObj) => ({
      key: x.key,
      value: intl.formatMessage({ ...x.label }),
    })
  );

  const modifiedSurgicalTechniqueOptions = surgicalTechniqueOptions.map((x: IReferenceDataObj) => ({
    key: x.key,
    value: intl.formatMessage({ ...x.label }),
  }));

  useEffect(() => {
    dispatch(getStudyGroupsRequest(false));
  }, []);

  useEffect(() => {
    if (createImplant.complete && isCreatingImplant) {
      setIsCreatingImplant(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({ id: 'implant.implantSaved', defaultMessage: 'Implant saved' }),
        toastrOptions
      );
      hideCreateImplantModalHandler();
    }
  }, [createImplant.complete, isCreatingImplant]);

  useEffect(() => {
    if (show && implant) {
      setComment(implant.comment);
      setLoadingProtocol(implant.loadingProtocol);
      setPlannedProstheticSolution(implant.plannedProstheticSolution);
      setSurgicalProtocol(implant.surgicalProtocol);
      setInsertionTorque(implant.insertionTorque);
      setPrePlacementProcedure(implant.prePlacementProcedure);
      setBoneQuality(implant.boneQuality?.toString());
      setImplantSitePlacement(implant.implantSitePlacement);
      setAugmentationMaterial(implant.augmentationMaterial);
      setSurgicalTechnique(implant.surgicalTechnique);
      setStudyGroupsList(implant.studyGroups);
    }
  }, [show, implant]);

  const hideCreateImplantModalHandler = () => {
    setComment('');
    setLoadingProtocol('NoSelection');
    setPlannedProstheticSolution('NoSelection');
    setSurgicalProtocol('NoSelection');
    setInsertionTorque(-1);
    setStudyGroupsList([]);
    setPrePlacementProcedure('NoSelection');
    setBoneQuality('');
    setImplantSitePlacement('NoSelection');
    setAugmentationMaterial('NoSelection');
    setSurgicalTechnique('NoSelection');
    if (hide) return hide();
  };

  const saveImplantHandler = () => {
    if (selectedImplant <= 0) return;
    setIsCreatingImplant(true);
    dispatch(
      createImplantRequest(
        patient.sourceUserGroupId,
        patient.id,
        selectedImplant,
        comment,
        loadingProtocol,
        plannedProstheticSolution,
        surgicalProtocol,
        insertionTorque,
        prePlacementProcedure,
        boneQuality,
        implantSitePlacement,
        augmentationMaterial,
        surgicalTechnique,
        studyGroupsList
      )
    );
  };

  const loadingProtocolChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setLoadingProtocol(evt.target.value);
    setSurgicalProtocol(evt.target.value === 'ConventionalLoading' ? 'TwoStage' : 'OneStage');
  };

  const plannedProstheticSolutionChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setPlannedProstheticSolution(evt.target.value);

  const surgicalProtocolChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setSurgicalProtocol(evt.target.value);

  const commentChanged = (evt: ChangeEvent<HTMLInputElement>) => setComment(evt.target.value);

  const prePlacementSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setPrePlacementProcedure(evt.target.value);

  const boneQualitySelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setBoneQuality(evt.target.value);

  const implantSitePlacementSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setImplantSitePlacement(evt.target.value);

  const augmentationMaterialSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setAugmentationMaterial(evt.target.value);

  const surgicalTechniqueSelected = (evt: ChangeEvent<HTMLInputElement>) =>
    setSurgicalTechnique(evt.target.value);

  const studyGroupClick = (id: string) => {
    const studyGroups = [...studyGroupsList];
    const index = studyGroups.findIndex((x) => x.id === id);
    if (index === -1) studyGroups.push({ id: id, included: true });
    else studyGroups[index] = { ...studyGroups[index], included: !studyGroups[index].included };

    setStudyGroupsList(studyGroups);
  };

  const renderStudyGroups = () => {
    if (studyGroups && studyGroups.length > 0)
      return (
        <InputSubGroup
          label={intl.formatMessage({
            id: 'implant.studyGroups',
            defaultMessage: 'Study Groups',
          })}
          className={styles.studyGroups}
        >
          {studyGroups
            .filter((group: IStudyGroup) =>
              group.members.some((x) => x.userId === user.id && !x.pending)
            )
            .map((group: IStudyGroup) => {
              return (
                <InputRow key={group.id}>
                  <div>
                    <InputItem
                      type="checkbox"
                      label={group.name}
                      checked={studyGroupsList.some((x) => x.id === group.id && x.included)}
                      onChange={() => studyGroupClick(group.id)}
                      noMargins
                      noMarginBottom
                      className={styles.studyCheckBox}
                    />
                  </div>
                </InputRow>
              );
            })}
        </InputSubGroup>
      );
  };

  const getToothName = (tooth: number) => {
    const notification = dentalNotifications.find((item: IDentalNotation) => item.uns === tooth);
    if (notification) {
      const { name, uns, fdi } = notification;
      const capitalized = name.charAt(0).toUpperCase() + name.slice(1);
      const toothNumber = user.teethNotation === 'UNS' ? uns : fdi;
      return toothNumber + '. ' + capitalized;
    }
    return '';
  };

  const error = createImplant.error;

  return (
    <div>
      <Modal
        header={
          `${getToothName(selectedImplant)} - ` +
          intl.formatMessage({ id: 'implant.addTreatment', defaultMessage: 'Add treatment' })
        }
        onDismiss={hideCreateImplantModalHandler}
        show={show}
      >
        <div className={styles.content}>
          {error ? (
            <AlertBox
              color="black"
              headerText="Oops, there was an error"
              messages={error.messages.map((m: { text: string }) => m.text)}
            />
          ) : null}
          <div className={styles.column}>
            <InputGroup
              label={intl.formatMessage({
                id: 'implant.treatmentOverview',
                defaultMessage: 'Treatment overview',
              })}
            >
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-additional-procedures"
                  label={intl.formatMessage({
                    id: 'implant.additionalProcedures',
                    defaultMessage: 'Additional surgical procedures',
                  })}
                  type="select"
                  options={modifiedPrePlacementProcedures}
                  defaultKey={'NoSelection'}
                  defaultValue={''}
                  value={prePlacementProcedure}
                  onChange={prePlacementSelected}
                  onWhite
                />
              </InputRow>
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-planned-prosthetic"
                  label={intl.formatMessage({
                    id: 'implant.plannedProstheticSolution',
                    defaultMessage: 'Planned Prosthetic Solution',
                  })}
                  type="selectbutton"
                  options={plannedProstheticSolutions.map((x: IReferenceDataObj) => ({
                    key: x.key,
                    value: intl.formatMessage({ ...x.label }),
                  }))}
                  value={plannedProstheticSolution}
                  onChange={plannedProstheticSolutionChanged}
                  centered
                  onWhite
                  span
                />
              </InputRow>
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-loading-protocol"
                  label={intl.formatMessage({
                    id: 'implant.loadingProtocol',
                    defaultMessage: 'Loading Protocol',
                  })}
                  type="selectbutton"
                  options={loadingProtocols.map((x: IReferenceDataObj) => ({
                    key: x.key,
                    value: intl.formatMessage({ ...x.label }),
                  }))}
                  value={loadingProtocol}
                  onChange={loadingProtocolChanged}
                  centered
                  onWhite
                  span
                />
              </InputRow>
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-surgical-protocol"
                  label={intl.formatMessage({
                    id: 'implant.surgicalProtocol',
                    defaultMessage: 'Surgical Protocol',
                  })}
                  type="selectbutton"
                  options={surgicalProtocols.map((x: IReferenceDataObj) => ({
                    key: x.key,
                    value: intl.formatMessage({ ...x.label }),
                  }))}
                  value={surgicalProtocol}
                  onChange={surgicalProtocolChanged}
                  centered
                  onWhite
                  span
                />
              </InputRow>
              {renderStudyGroups()}
            </InputGroup>
          </div>
          <div className={styles.column}>
            <InputGroup
              label={intl.formatMessage({
                id: 'implant.siteAndTreatment',
                defaultMessage: 'Implant site and treatment',
              })}
            >
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-bone-quality"
                  label={intl.formatMessage({
                    id: 'implant.boneQuality',
                    defaultMessage: 'Bone quality',
                  })}
                  type="selectbutton"
                  options={boneQualityOptions}
                  centered
                  onWhite
                  value={boneQuality}
                  onChange={boneQualitySelected}
                  span
                />
              </InputRow>
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-implant-site"
                  label={intl.formatMessage({
                    id: 'implant.implantSitePlacement',
                    defaultMessage: 'Implant site - Placement',
                  })}
                  type="select"
                  options={modifiedImplantSitePlacementOptions}
                  defaultKey={'NoSelection'}
                  defaultValue={''}
                  onWhite
                  value={implantSitePlacement}
                  onChange={implantSitePlacementSelected}
                />
              </InputRow>
              {implantSitePlacement === 'HealedBoneGraft' ||
              implantSitePlacement === 'ExtractionPlacementBoneGraft' ? (
                <InputRow>
                  <InputItem
                    dataCy="create-treatment-modal-augmentation-material"
                    label={intl.formatMessage({
                      id: 'implant.augmentationMaterial',
                      defaultMessage: 'Augmentation Material',
                    })}
                    type="select"
                    options={modifiedAugmentationMaterialOptions}
                    defaultKey={'NoSelection'}
                    defaultValue={''}
                    onWhite
                    value={augmentationMaterial}
                    onChange={augmentationMaterialSelected}
                  />
                </InputRow>
              ) : null}
              {implantSitePlacement === 'ExtractionPlacement' ||
              implantSitePlacement === 'ExtractionPlacementBoneGraft' ? (
                <InputRow>
                  <InputItem
                    label={intl.formatMessage({
                      id: 'implant.surgicalTechnique',
                      defaultMessage: 'Surgical Technique',
                    })}
                    type="select"
                    options={modifiedSurgicalTechniqueOptions}
                    defaultKey={'NoSelection'}
                    defaultValue={''}
                    onWhite
                    value={surgicalTechnique}
                    onChange={surgicalTechniqueSelected}
                  />
                </InputRow>
              ) : null}
              <InputRow>
                <InputItem
                  dataCy="create-treatment-modal-comment"
                  label={intl.formatMessage({
                    id: 'general.comment',
                    defaultMessage: 'Comment',
                  })}
                  type="textarea"
                  value={comment}
                  onChange={commentChanged}
                  onWhite
                />
              </InputRow>
            </InputGroup>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
            onClick={() => hideCreateImplantModalHandler()}
          />
          <Button
            dataCy="create-treatment-modal-save-button"
            primary
            value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
            onClick={saveImplantHandler}
            disabled={isCreatingImplant}
            loading={isCreatingImplant}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateTreatmentModal;
