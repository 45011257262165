import * as React from 'react';

function SvgBarcodeliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1880 2048" {...props}>
      <path d="M378 1456q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V629q0-9 6.5-15.5T357 607t15 6.5 6 15.5v827zm198-849q9 0 15 6.5t6 15.5v827q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V629q0-9 6.5-15.5T576 607zm325 6q9 0 15 6.5t6 15.5v827q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V635q0-9 6.5-15.5T901 613zm-213 0q9 0 15 6.5t6 15.5v827q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V635q0-9 6.5-15.5T688 613zm448 0q9 0 15 6.5t6 15.5v827q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5V635q0-9 6.5-15.5t15.5-6.5zm124-6q9 0 15.5 6.5t6.5 15.5v827q0 9-6.5 15.5t-15.5 6.5-15-6.5-6-15.5V629q0-9 6-15.5t15-6.5zm132 0q9 0 15 6.5t6 15.5v827q0 9-6 15.5t-15 6.5-15-6.5-6-15.5V629q0-9 6-15.5t15-6.5zm153 849q0 9-6 15.5t-15 6.5-15-6.5-6-15.5V629q0-9 6-15.5t15-6.5 15 6.5 6 15.5v827zM409 390q9 0 15.5 6.5T431 412t-6.5 15-15.5 6H191q-19 0-32.5 13.5T145 479v212q0 9-6 15t-15 6-15.5-6-6.5-15V479q0-37 26-63t63-26h218zm1281 0q37 0 62.5 26t25.5 63v212q0 9-6 15t-15 6-15-6-6-15V479q0-19-13.5-32.5T1690 433h-219q-9 0-15-6t-6-15 6-15.5 15-6.5h219zM409 1659q9 0 15.5 6t6.5 15-6.5 15-15.5 6H191q-37 0-63-25.5t-26-62.5v-212q0-9 6.5-15.5t15.5-6.5 15 6.5 6 15.5v212q0 19 13.5 32.5T191 1659h218zm1348-280q9 0 15 6.5t6 15.5v212q0 37-25.5 62.5T1690 1701h-219q-9 0-15-6t-6-15 6-15 15-6h219q19 0 32.5-13.5t13.5-32.5v-212q0-9 6-15.5t15-6.5z" />
    </svg>
  );
}

export default SvgBarcodeliga;
