import { Button } from '../Shared/Buttons';
import { useIntl } from 'react-intl';
import { INewStudy } from '../../types/studyGroups';
import { unwrapResult } from '@reduxjs/toolkit';
import { Table, TableBody, TR, TD } from '../../components/Shared/Table';
import { ChangeEvent, FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { InputItem, InputRow, InputSubGroup } from '..//Shared/Forms/';
import { createStudy, getUserId, getStudyGroups } from '../../redux/slices/studyGroupSlice';
import styled from 'styled-components';
import Modal from '../Shared/Modal/Modal';

interface Props {
  onDismiss?: () => void;
}

interface invite {
  userId: string;
  email: string;
}

export const CreateStudyModal: FC<Props> = ({ onDismiss }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const createStudyStatus = useAppSelector((state) => state.studyGroup.createStudyStatus);
  const [studyInformation, studyInformationSet] = useState<INewStudy>({
    name: '',
    description: '',
    shared: 'private',
    invites: [],
  });
  const [invites, invitesSet] = useState<invite[]>([]);

  const [inviteEmail, inviteEmailSet] = useState<string>('');
  const [inviteError, inviteErrorSet] = useState<string | undefined>();

  const onAdd = () => {
    if (inviteEmail === '') {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.enterEmail',
          defaultMessage: "Enter a user's email address.",
        })
      );
    } else if (invites.some((x) => x.email === inviteEmail)) {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.userAlreadyInvited',
          defaultMessage: 'The user is already invited.',
        })
      );
    } else if (inviteEmail === user.email) {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.inviteOwner',
          defaultMessage: 'Not possible to invite yourself.',
        })
      );
    } else {
      dispatch(getUserId(inviteEmail))
        .then(unwrapResult)
        .then((userId) => {
          invitesSet([...invites, { email: inviteEmail, userId: userId }]);
        })
        .catch((error) =>
          error.code === '404'
            ? inviteErrorSet(
                intl.formatMessage({
                  id: 'study.userDoesNotExist',
                  defaultMessage: 'The user does not exist',
                })
              )
            : console.log('Error', error)
        );
    }
  };

  return (
    <Modal
      header={intl.formatMessage({ id: 'study.createStudy', defaultMessage: 'Create Study' })}
      show={true}
      onDismiss={onDismiss}
    >
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'study.studyName',
            defaultMessage: 'Study Name',
          })}
          type="text"
          onWhite
          value={studyInformation.name}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            studyInformationSet({ ...studyInformation, ...{ name: evt.target.value } })
          }
        />
      </InputRow>
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'study.studyDescription',
            defaultMessage: 'Study Description',
          })}
          type="textarea"
          onWhite
          value={studyInformation.description}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            studyInformationSet({ ...studyInformation, ...{ description: evt.target.value } })
          }
        />
      </InputRow>
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'study.studyType',
            defaultMessage: 'Study Type',
          })}
          type="selectbutton"
          options={[
            {
              key: 'private',
              value: intl.formatMessage({ id: 'study.private', defaultMessage: 'Private' }),
            },
            {
              key: 'shared',
              value: intl.formatMessage({ id: 'study.shared', defaultMessage: 'Shared' }),
            },
          ]}
          onWhite
          value={studyInformation.shared}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            studyInformationSet({ ...studyInformation, ...{ shared: evt.target.value } })
          }
        />
      </InputRow>
      {studyInformation.shared === 'shared' && (
        <div>
          <InputRow>
            <InputItem
              label={intl.formatMessage({
                id: 'study.inviteMemeber',
                defaultMessage: 'Invite Members',
              })}
              type="text"
              onWhite
              value={inviteEmail}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                inviteEmailSet(evt.target.value);
                inviteError && inviteErrorSet(undefined);
              }}
              placeholder={intl.formatMessage({
                id: 'general.email',
                defaultMessage: 'Email',
              })}
            />
            <div
              style={{
                flexBasis: 0,
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '0.5rem',
              }}
            >
              <Button iconButton primary leftIcon="add" onClick={onAdd} />
            </div>
          </InputRow>
          {inviteError && <ErrorText>{inviteError}</ErrorText>}

          {invites.length > 0 && (
            <InputSubGroup
              label={intl.formatMessage({
                id: 'study.invitedMembers',
                defaultMessage: 'Invited Members',
              })}
            >
              <Table>
                <TableBody>
                  {invites.map((invite: invite) => {
                    return (
                      <TR key={invite.email}>
                        <TD>{invite.email}</TD>
                        <TD>
                          <IconButton
                            onClick={() =>
                              invitesSet([...invites.filter((x) => x.userId !== invite.userId)])
                            }
                          >
                            <i>delete</i>
                          </IconButton>
                        </TD>
                      </TR>
                    );
                  })}
                </TableBody>
              </Table>
            </InputSubGroup>
          )}
        </div>
      )}
      <Buttons>
        <Button
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={onDismiss}
        />
        <Button
          primary
          value={intl.formatMessage({ id: 'general.create', defaultMessage: 'Create' })}
          onClick={() => {
            const studyGroup = {
              ...studyInformation,
              ...{
                invites: studyInformation.shared === 'shared' ? invites.map((x) => x.userId) : [],
              },
            };
            dispatch(createStudy(studyGroup)).then(() => {
              dispatch(getStudyGroups(false));
              onDismiss?.();
            });
          }}
          disabled={
            !studyInformation.name ||
            !studyInformation.description ||
            (studyInformation.shared === 'shared' && invites.length === 0) ||
            createStudyStatus.busy
          }
          loading={createStudyStatus.busy}
        />
      </Buttons>
    </Modal>
  );
};

const IconButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 2rem;
  }
`;

const ErrorText = styled.div`
  color: var(--color-warning);
`;

const Buttons = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 460px) {
    flex-direction: column;
    button {
      margin: 0px;
    }

    button:last-child {
      margin-top: 10px;
    }
  }
`;
