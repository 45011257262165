import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Collapsible from 'react-collapsible';
import classNames from 'classnames';

import * as referenceDataActions from '../../redux/actions/referenceDataActions';
import * as translationActions from '../../redux/actions/translationActions';

import {
  smartPegCompatibleSelector,
  abutmentCompatibleSelector,
} from '../../redux/reducers/referenceDataReducer';

import 'whatwg-fetch';

import styles from './SmartPegTool.module.scss';

import smartpegImgLow from './local-assets/smartpeg_low.png';
import { Button } from '../Shared/Buttons';

const SEARCH_TYPES = {
  FREE: 'free',
  IMPLANT: 'implant',
  ABUTMENT: 'abutment',
  SMARTPEG: 'smartpeg',
};

const NAVIGATION_STRINGS = {
  LIST_ARTICLES: 'list-articles',
  MODEL_LIST: 'model-list',
  SINGLE_SMARTPEG: 'single-smartpeg',
};

const RESULT_LENGTH = 40;

class SmartPegTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterList: [],
      resultList: null,
      searchType: SEARCH_TYPES.FREE,
      navigation: SEARCH_TYPES.FREE,
      navigationData: null,
      history: [],
      lastSearchString: '',
      wooData: {},
      downloadingList: false,
    };

    this.addToCartQuantity = React.createRef();

    this.searchChanged = this.searchChanged.bind(this);
    this.downloadList = this.downloadList.bind(this);
  }

  componentDidMount() {
    this.props.actions.getImplantData(true, this.props.draft);
    this.props.actions.getAbutmentData(true, this.props.draft);

    const WOO_DATA_URL =
      'https://shop.osstell.com/wp-json/public-woo/v1/products/12921/variations?per_page=100';
    fetch(WOO_DATA_URL)
      .then((res) => res.json())
      .then((value) => {
        const groupedData = value.reduce((memo, next) => {
          return Object.assign(memo, {
            [next.sku]: next,
          });
        }, {});

        this.setState({
          wooData: groupedData,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    this.props.actions.getSmartPegData();
  }

  downloadList(brandName) {
    this.setState({
      downloadingList: true,
    });
    this.props.actions.getSmartPegListForBrand(brandName, () =>
      this.setState({
        downloadingList: false,
      })
    );
  }

  changeLocale(event) {
    this.props.actions.changeLocale(event.target.value);
  }

  searchChanged(evt) {
    let result = [];
    const text = evt.target.value;

    if (text.length < 1) {
      this.setState({
        resultList: null,
        lastSearchString: text,
      });
      return;
    }

    const filteredImplants = this.props.implants.filter((imp) => imp.status === 0);
    const filteredAbutments = this.props.abutments.filter((ab) => ab.status === 0);
    let tempList;

    switch (this.state.searchType) {
      case SEARCH_TYPES.FREE:
        tempList = filteredImplants;
        break;
      case SEARCH_TYPES.IMPLANT:
        tempList = filteredImplants;
        break;
      case SEARCH_TYPES.ABUTMENT:
        tempList = filteredAbutments;
        break;
      case SEARCH_TYPES.SMARTPEG:
        tempList = this.props.smartPegs;
        result = tempList.filter((smartPeg) => {
          return Object.values(smartPeg).join('').toLowerCase().indexOf(text) !== -1;
        });

        this.setState({
          resultList: result,
          lastSearchString: text,
        });
        // NOTE early return!
        return;
      default:
        tempList = filteredImplants;
        break;
    }

    result = tempList.filter((item) => {
      let match = false;
      if (item.brandName.toLowerCase().indexOf(text.toLowerCase()) !== -1) match = true;

      if (item.modelName.toLowerCase().indexOf(text.toLowerCase()) !== -1) match = true;

      if (item.platformName.toLowerCase().indexOf(text.toLowerCase()) !== -1) match = true;

      if (item.partNumber.toLowerCase().indexOf(text.toLowerCase()) !== -1) match = true;

      return match;
    });

    this.setState({
      resultList: result,
      lastSearchString: text,
    });
  }

  resetSearch() {
    this.setState({
      searchType: SEARCH_TYPES.FREE,
      navigation: SEARCH_TYPES.FREE,
      resultList: null,
      lastSearchString: '',
      history: [],
    });
  }

  setCurrentSearch(searchType) {
    this.setState({
      searchType,
      resultList: null,
      lastSearchString: '',
    });
  }

  navigate(navString, data) {
    this.setState({
      navigation: navString,
      navigationData: data,
      history: this.state.history.concat({
        navigation: this.state.navigation,
        navigationData: this.state.navigationData,
      }),
    });
  }

  navigateBack() {
    const newHistory = this.state.history.concat([]);
    const lastHistory = newHistory.pop();

    this.setState({
      navigation: lastHistory.navigation,
      navigationData: lastHistory.navigationData,
      history: newHistory,
    });
  }

  dataLoaded() {
    return (
      this.props.implants &&
      this.props.implants.length > 0 &&
      this.props.abutments &&
      this.props.abutments.length > 0 &&
      this.props.smartPegs &&
      this.props.smartPegs.length > 0 &&
      Object.keys(this.state.wooData).length > 0
    );
  }

  landingResults() {
    return (
      <ParentComponent>
        <div className={styles.landingPageRoot}>
          <div>
            <input
              className={styles.searchBox}
              onChange={this.searchChanged}
              type="text"
              placeholder="Search"
              defaultValue={this.state.lastSearchString}
            />
          </div>
          {this.state.resultList ? (
            <div>
              <ul className={styles.resultListWrapper}>
                {(this.state.resultList || []).slice(0, RESULT_LENGTH).map((val) => {
                  return (
                    <ArticleItem
                      link
                      key={val.dataId}
                      data={val}
                      navigate={(navString, data) => {
                        this.navigate(navString, data);
                      }}
                    />
                  );
                })}
              </ul>
            </div>
          ) : (
            <div>
              <div>
                <div className={styles.divider}>OR</div>
              </div>
              <div>
                <h2 className={styles.separatorHeader}>Find by</h2>
                <div className={styles.iconButtonWrapper}>
                  <div
                    className={styles.iconButton}
                    onClick={() => {
                      this.setCurrentSearch(SEARCH_TYPES.IMPLANT);
                      this.navigate(SEARCH_TYPES.IMPLANT);
                    }}
                  >
                    <i className={styles.itemIcon}>implant</i>
                    <label className={styles.itemIconLabel}>Implant</label>
                  </div>
                  <div
                    className={styles.iconButton}
                    onClick={() => {
                      this.setCurrentSearch(SEARCH_TYPES.ABUTMENT);
                      this.navigate(SEARCH_TYPES.ABUTMENT);
                    }}
                  >
                    <i className={styles.itemIcon}>abutment</i>
                    <label className={styles.itemIconLabel}>Abutment</label>
                  </div>
                  <div
                    className={styles.iconButton}
                    onClick={() => {
                      this.setCurrentSearch(SEARCH_TYPES.SMARTPEG);
                      this.navigate(SEARCH_TYPES.SMARTPEG);
                    }}
                  >
                    <i className={styles.itemIcon}>smartpeg</i>
                    <label className={styles.itemIconLabel}>SmartPeg</label>
                  </div>
                </div>
              </div>
              <div>
                <div className={styles.divider}>OR</div>
              </div>
              <Button
                className={styles.downloadsmartpegguide}
                centered
                value={'Download SmartPeg Reference Guide'}
                onClick={() => {
                  const newWindow = window.open(
                    'https://shop.osstell.com/wp-content/uploads/2023/09/RefList_25016-82_September-2023.pdf',
                    '_blank',
                    'noopener,noreferrer'
                  );
                  if (newWindow) newWindow.opener = null;
                }}
                primary
              />
            </div>
          )}
        </div>
      </ParentComponent>
    );
  }

  abutmentImplantResults(key) {
    const dataToFilterOn = this.state.lastSearchString ? this.state.resultList : this.props[key];

    const resultsByBrandName = Object.keys(
      (dataToFilterOn || []).reduce((memo, next) => {
        memo[next.brandName] = '';
        return memo;
      }, {})
    );
    resultsByBrandName.sort((a, b) => a.localeCompare(b));

    const backButton = (
      <i
        className={styles.backIcon}
        onClick={() => {
          this.resetSearch();
        }}
      >
        back
      </i>
    );

    const title = { implants: 'Implant', abutments: 'Abutment' }[key];

    return (
      <ParentComponent title={`Find by ${title}`} backButton={backButton}>
        <div>
          <div className={styles.maxWidthWrapper}>
            <div>
              <input
                onChange={this.searchChanged}
                className={styles.searchBox}
                type="text"
                placeholder="Search"
                defaultValue={this.state.lastSearchString}
              />
            </div>
            <div>
              <ul className={styles.resultListWrapper}>
                {(resultsByBrandName || []).map((brandName, index) => {
                  return (
                    <li
                      className={styles.brandNameItem}
                      key={brandName + index}
                      onClick={() => {
                        this.navigate(NAVIGATION_STRINGS.MODEL_LIST, {
                          brandName,
                          propKey: key,
                        });
                      }}
                    >
                      {brandName}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </ParentComponent>
    );
  }

  smartPegResults() {
    const dataToFilterOn = (
      this.state.lastSearchString ? this.state.resultList : this.props.smartPegs
    ).concat([]);

    const getTypeNumber = (type) => type.split(' ')[1];
    dataToFilterOn.sort((a, b) => getTypeNumber(a.type) - getTypeNumber(b.type));

    const backButton = (
      <i
        className={styles.backIcon}
        onClick={() => {
          this.resetSearch();
        }}
      >
        back
      </i>
    );

    return (
      <ParentComponent backButton={backButton}>
        <div>
          <div className={styles.maxWidthWrapper}>
            <div>
              <input
                className={styles.searchBox}
                onChange={this.searchChanged}
                type="text"
                placeholder="Search"
                defaultValue={this.state.lastSearchString}
              />
            </div>
            <ul className={styles.smartPegListWrapper}>
              {dataToFilterOn.map((smartPeg, index) => {
                return (
                  <li
                    key={smartPeg.type + index}
                    className={styles.smartPegListResultItem}
                    onClick={() => {
                      this.navigate(NAVIGATION_STRINGS.SINGLE_SMARTPEG, {
                        type: smartPeg.type,
                      });
                    }}
                  >
                    <div className={styles.smartPegListResultItemContent}>
                      <img className={styles.smartPegImgSmall} src={smartpegImgLow} alt="" />
                      <div className={styles.searchResultText}>{smartPeg.type}</div>
                      <div className={styles.searchResultLabel}>{smartPeg.partNumber}</div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </ParentComponent>
    );
  }

  modelList() {
    const propKey = this.state.navigationData.propKey;
    const brandName = this.state.navigationData.brandName;
    const resultFilteredByBrandName = this.props[propKey].filter((val) => {
      return val.brandName === brandName && val.status === 0;
    });

    const uniqueModels = Object.keys(
      resultFilteredByBrandName.reduce((memo, next) => {
        memo[next.modelName] = '';
        return memo;
      }, {})
    );
    uniqueModels.sort((a, b) => a.localeCompare(b));

    const backButton = (
      <i
        className={styles.backIcon}
        onClick={() => {
          this.navigateBack();
        }}
      >
        back
      </i>
    );

    return (
      <ParentComponent title={brandName} backButton={backButton}>
        <div>
          <ul className={styles.modelSearchResults}>
            {uniqueModels.map((modelName, index) => {
              return (
                <li
                  onClick={() => {
                    this.navigate(NAVIGATION_STRINGS.LIST_ARTICLES, {
                      brandName,
                      filterKey: modelName,
                      propKey,
                      dataKey: 'modelName',
                    });
                  }}
                  className={styles.modelSearchResultsItem}
                  key={modelName + index}
                >
                  <span className={styles.modelSearchItemText}>{modelName}</span>
                </li>
              );
            })}
          </ul>
          <Button
            primary
            value={`Download SmartPeg list for ${brandName}`}
            onClick={() => this.downloadList(brandName)}
            loading={this.state.downloadingList}
            disabled={this.state.downloadingList}
          />
        </div>
      </ParentComponent>
    );
  }

  listArticles() {
    const brandNameKey = this.state.navigationData.brandName;
    const propKey = this.state.navigationData.propKey;
    const dataKey = this.state.navigationData.dataKey;
    const filterKey = this.state.navigationData.filterKey;

    const resultFilteredByBrandName = this.props[propKey].filter((val) => {
      return val['brandName'] === brandNameKey && val[dataKey] === filterKey && val.status === 0;
    });

    const backButton = (
      <i
        className={styles.backIcon}
        onClick={() => {
          this.navigateBack();
        }}
      >
        back
      </i>
    );

    const brandName = resultFilteredByBrandName[0]?.brandName
      ? resultFilteredByBrandName[0]?.brandName
      : '';

    const smartPegs = (resultFilteredByBrandName || []).slice(0, RESULT_LENGTH);

    return (
      <ParentComponent title={`${brandName} - ${filterKey}`} backButton={backButton}>
        <div>
          <ul className={styles.resultListWrapper}>
            {this.sortSmartPegs(smartPegs).map((val) => {
              return (
                <ArticleItem
                  link
                  key={val.dataId}
                  data={val}
                  navigate={(navString, data) => {
                    this.navigate(navString, data);
                  }}
                />
              );
            })}
          </ul>
        </div>
      </ParentComponent>
    );
  }

  renderSingleSmartPeg() {
    const smartPeg = this.props.smartPegs.find(
      (val) => val.type === this.state.navigationData.type
    );
    const compatibleImplants = smartPegCompatibleSelector(this.props.implants, smartPeg.type);
    const compatibleAbutments = abutmentCompatibleSelector(this.props.abutments, smartPeg.type);
    //const smartPegPrice = this.state.wooData[smartPeg.partNumber].price;

    const backButton = (
      <i
        className={styles.backIcon}
        onClick={() => {
          this.navigateBack();
        }}
      >
        back
      </i>
    );
    const CollapsibleTriggerHeader = (string, open) => {
      return (
        <div className={styles.collapsibleHeader}>
          {string} <i className={styles.icon}>{open ? 'arrow_down' : 'arrow_right'}</i>
        </div>
      );
    };
    const CompatibleComponent = (props) => {
      const { brands, title } = props;
      return (
        <div className={styles.compatibleWrapper}>
          <Collapsible
            trigger={CollapsibleTriggerHeader(title, false)}
            triggerWhenOpen={CollapsibleTriggerHeader(title, true)}
          >
            {(brands || []).map((brand) => {
              return (
                <Collapsible
                  trigger={brand.brandName}
                  key={brand.brandName}
                  classParentString={styles.collapsible}
                  className={styles.collapsible}
                  openedClassName={styles.collapsibleOpen}
                  triggerClassName={styles.trigger}
                  triggerOpenedClassName={styles.triggerOpen}
                  transitionTime={200}
                >
                  {(brand.models || []).map((model) => (
                    <Collapsible
                      trigger={model.modelName}
                      key={model.modelName}
                      classParentString={styles.collapsibleInner}
                      className={styles.collapsibleInner}
                      openedClassName={styles.collapsibleOpen}
                      triggerClassName={styles.trigger}
                      triggerOpenedClassName={styles.triggerOpen}
                    >
                      <ul className={styles.resultListWrapper}>
                        {(model.implants || model.abutments || []).map((implant) => (
                          <ArticleItem key={implant.dataId} data={implant} />
                        ))}
                      </ul>
                    </Collapsible>
                  ))}
                </Collapsible>
              );
            })}
          </Collapsible>
        </div>
      );
    };
    const onAddToCart = () => {
      let targetUrl;
      if (process.env.NODE_ENV === 'production') {
        targetUrl = 'https://shop.osstell.com/';
      } else {
        targetUrl = 'http://localhost:8000';
      }
      const data = {
        quantity: this.addToCartQuantity.value,
        sku: smartPeg.partNumber,
      };
      console.log(data);
      // eslint-disable-next-line no-restricted-globals
      parent.postMessage(
        JSON.stringify({
          action: 'ADD_TO_CART',
          data,
        }),
        targetUrl
      );
    };

    return (
      <ParentComponent backButton={backButton}>
        <div>
          <div className={styles.singelSmartPegWrapper}>
            <img className={styles.smartPegImgBig} src={smartpegImgLow} alt="" />
            <div className={styles.searchResultText}>{smartPeg.type}</div>
            <div className={styles.searchResultLabel}>Article no.{smartPeg.partNumber}</div>
            <div>
              <div className={styles.inlineBlock}>
                <label className={styles.quantityLabel}>Quantity</label>
                <input
                  ref={this.addToCartQuantity}
                  className={styles.addToCartQuantity}
                  defaultValue="1"
                  min="1"
                  type="number"
                ></input>
              </div>
              <div className={styles.inlineBlock}>
                <button className={styles.addToCartButton} onClick={onAddToCart}>
                  Add to cart
                </button>
              </div>
            </div>
          </div>
          <div>
            <h2 className={styles.separatorHeader}>SmartPeg type also suitable for</h2>

            {compatibleImplants.brands && compatibleImplants.brands.length > 0 && (
              <CompatibleComponent title="Implants" brands={compatibleImplants.brands} />
            )}

            {compatibleAbutments.brands && compatibleAbutments.brands.length > 0 && (
              <CompatibleComponent title="Abutments" brands={compatibleAbutments.brands} />
            )}
          </div>
        </div>
      </ParentComponent>
    );
  }

  navSwitch() {
    switch (this.state.navigation) {
      case SEARCH_TYPES.FREE:
        return this.landingResults();
      case SEARCH_TYPES.IMPLANT:
        return this.abutmentImplantResults('implants');
      case SEARCH_TYPES.ABUTMENT:
        return this.abutmentImplantResults('abutments');
      case SEARCH_TYPES.SMARTPEG:
        return this.smartPegResults();
      case NAVIGATION_STRINGS.MODEL_LIST:
        return this.modelList();
      case NAVIGATION_STRINGS.LIST_ARTICLES:
        return this.listArticles();
      case NAVIGATION_STRINGS.SINGLE_SMARTPEG:
        return this.renderSingleSmartPeg();
      default:
        return this.landingResults();
    }
  }

  sortSmartPegs(smartPegs) {
    var sortedSmartPegs = smartPegs.sort(function (x, y) {
      if (x.platformName > y.platformName) {
        return 1;
      } else if (x.platformName < y.platformName) {
        return -1;
      }

      if (Number(x.diameter) < Number(y.diameter)) {
        return -1;
      } else if (Number(x.diameter) > Number(y.diameter)) {
        return 1;
      }

      if (Number(x.length) < Number(y.length)) {
        return -1;
      } else if (Number(x.length) > Number(y.length)) {
        return 1;
      } else {
        return 0;
      }
    });

    return sortedSmartPegs;
  }

  render() {
    return (
      <div className={styles.smartPegToolPanelWrapper}>
        <div className={styles.smartPegToolPanel}>
          {this.props.draft && <h1 className={styles.h1}>Draft</h1>}
          {this.dataLoaded() ? (
            this.navSwitch()
          ) : (
            <ParentComponent>
              <div className={styles.loading}> Loading...</div>{' '}
            </ParentComponent>
          )}
        </div>
      </div>
    );
  }
}

function ParentComponent(props) {
  return (
    <div>
      {props.backButton || null}
      <h1 className={styles.h1}>{props.title || 'Find your SmartPeg'}</h1>
      {props.children}
    </div>
  );
}

function ArticleItem(props) {
  const navigate = props.navigate;
  const dataPoint = (labelText, dataPoint) => {
    return (
      <li>
        <div className={styles.searchResultText}>{dataPoint || '-'}</div>
        <div className={styles.searchResultLabel}>{labelText}</div>
      </li>
    );
  };
  const data = props.data;

  const wrapperClass = classNames(
    styles.searchResultsWrapper,
    props.link === true ? styles.searchResultsLink : null,
    props.link === true ? styles.shadow : null,
    props.link === true ? null : styles.searchResultsFlat
  );

  return (
    <li
      className={wrapperClass}
      onClick={() => {
        navigate &&
          navigate(NAVIGATION_STRINGS.SINGLE_SMARTPEG, {
            type: data.smartPeg,
          });
      }}
    >
      <div className={styles.searchResultsMiddleWrapper}>
        <h3 className={styles.searchResultHeader}>{`${data.brandName} / ${data.modelName}`}</h3>
        <ul className={styles.searchResults}>
          {dataPoint('Platform', data.platformName)}
          {dataPoint('Diameter', data.diameter)}
          {dataPoint('Length', data.length)}
          {dataPoint('Part Number', data.partNumber)}
        </ul>
      </div>
      {props.link && (
        <div className={styles.searchResultsMiddleWrapperArrow}>
          <i className={styles.forwardIcon}>forward</i>
        </div>
      )}
    </li>
  );
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    translations: state.translation.currentTranslations,
    implants: state.referenceData.implantData,
    abutments: state.referenceData.abutmentData,
    smartPegs: state.referenceData.smartPegData,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...translationActions, ...referenceDataActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(SmartPegTool);
