import React from 'react';
import styles from './Forms.module.scss';
import cn from 'classnames';

const InputRow = (props) => (
  <section className={cn(styles.inputRow, props.className, props.half ? styles.half : null)}>
    {props.children}
  </section>
);

export default InputRow;
