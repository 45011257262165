import Modal from '../../components/Shared/Modal/Modal';
import React, { useState, useEffect, ChangeEvent } from 'react';
import { Button } from '../../components/Shared/Buttons';
import styled from 'styled-components';
import { InputItem } from '../../components/Shared/Forms';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '../../utils/hooks';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { reauthenticate } from '../../redux/actions/accountActions';
import { userDeleteItSelf } from '../../redux/actions/userActions';
import { clearToken } from '../../redux/actions/accountActions';
import { errors } from '../../constants/referenceData';

interface Props {
  toogleShowDeleteAccountModal: () => void;
  show: boolean;
}

interface Errors {
  [key: string]: {
    id: string;
    defaultMessage: string;
  };
}

const DeleteAccountModal: React.FC<Props> = ({ toogleShowDeleteAccountModal, show }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useAppSelector((state) => state.user.user.email);
  const user = useAppSelector((state) => state.user.user);
  const userGroups = useAppSelector((state) => state.user.user.userGroups);
  const reauthenticateRequest = useAppSelector((state) => state.account.reauthenticate);
  const deleteUser = useAppSelector((state) => state.user.deleteUser);
  const [password, setPassword] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [justClickedDeleteAccount, setJustClickedDeleteAccount] = useState<boolean>(false);
  const refrenceDataErrors: Errors = errors;

  useEffect(() => {
    if (reauthenticateRequest.error && justClickedDeleteAccount) {
      setPassword('');
      showErrorToastr(reauthenticateRequest.error);
      setJustClickedDeleteAccount(false);
    }
  }, [reauthenticateRequest.error]);

  useEffect(() => {
    if (reauthenticateRequest.complete && userGroups[0].userPermission === 'Admin') {
      dispatch(
        userDeleteItSelf(user.id, () => {
          history.push('/accountdeleted');
          dispatch(clearToken(false, false));
        })
      );
    }
  }, [reauthenticateRequest.complete]);

  useEffect(() => {
    if (deleteUser.error) {
      console.log('Error');
      setPassword('');
      showErrorToastr(deleteUser.error);
      setJustClickedDeleteAccount(false);
    }
  }, [deleteUser.error]);

  const showErrorToastr = (errorsArray: { text: string }[]) => {
    errorsArray.forEach((error) => {
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        refrenceDataErrors[error.text]
          ? intl.formatMessage(refrenceDataErrors[error.text])
          : error.text,
        {
          showCloseButton: false,
        }
      );
    });
  };

  const onCloseModal = () => {
    toogleShowDeleteAccountModal();
    setCurrentStep(1);
  };

  const renderStepOne = () => (
    <>
      <p>
        {intl.formatMessage({
          id: 'profile.deleteAccountTextOne',
          defaultMessage:
            'If you delete your OsstellConnect account, you will not be able to retrieve your patients, consultations or measurements. Your instruments will be unregistered so that you can register them to another account in the future. ',
        })}
      </p>
      <div className="button-container">
        <Button
          className="left-button"
          big
          full
          primary
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={() => onCloseModal()}
        />
        <Button
          className="left-button"
          big
          full
          value={intl.formatMessage({
            id: 'profile.continueToAccountDeletion',
            defaultMessage: 'Continue to account deletion',
          })}
          onClick={() => {
            setCurrentStep(2);
          }}
        />
      </div>
    </>
  );

  const renderStepTwo = () => (
    <>
      <p>
        {intl.formatMessage({
          id: 'profile.deleteAccountTextTwo',
          defaultMessage: 'Please re-enter your password to confirm the  deletion of your account.',
        })}
      </p>

      <InputItem
        dataCy="delete-account-password-input"
        label={<FormattedMessage id="general.password" defaultMessage="Password" />}
        type="password"
        onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
        value={password}
        onWhite
      />

      <div className="button-container">
        <Button
          className="left-button"
          big
          full
          primary
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={() => onCloseModal()}
        />
        <Button
          className="left-button"
          big
          full
          value={intl.formatMessage({
            id: 'profile.deleteMyAccount',
            defaultMessage: 'Delete my account',
          })}
          onClick={() => {
            dispatch(reauthenticate(email, password));
            setJustClickedDeleteAccount(true);
          }}
          disabled={reauthenticateRequest.busy || password.length < 6}
          loading={reauthenticateRequest.busy}
        />
      </div>
    </>
  );

  return (
    <StyledDeleteAccountModal>
      <Modal
        modalPanelClass="modal"
        fullModal={true}
        header={intl.formatMessage({
          id: 'profile.deleteAccount',
          defaultMessage: 'Delete account',
        })}
        onDismiss={() => onCloseModal()}
        show={show}
      >
        {currentStep === 1 && renderStepOne()}
        {currentStep === 2 && renderStepTwo()}
      </Modal>
    </StyledDeleteAccountModal>
  );
};

const StyledDeleteAccountModal = styled.div`
  .modal {
    width: 700px;
    height: 700px;
    border-radius: 5px;
    p {
      font-weight: 300;
      font-size: 1.125rem;
      text-align: center;
      margin: 0.5rem 0;
    }

    .button-container {
      display: flex;
      justify-content: space-around;

      .left-button {
        margin-right: 5px;
      }

      .right-button {
        margin-left: 5px;
      }
    }
  }

  @media (max-width: 600px) {
    .modal {
      height: 100%;
      width: 100%;
      border-radius: 0px;
    }
    .button-container {
      flex-direction: column;
      align-items: center;

      .left-button,
      .right-button {
        margin: 10px 0px 0px 0px;
      }
    }
  }
`;

export default DeleteAccountModal;
