import React from 'react';
import styles from './Table.module.scss';
import cn from 'classnames';

const TH = (props) => (
  <th
    className={cn(
      props.className ? props.className : styles.th,
      props.align && styles[props.align]
    )}
  >
    {props.children}
  </th>
);

export default TH;
