import React, { useRef, useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';
import { GOTO_STEP, SET_VISIT_DATE, Visit } from '../../../../../redux/slices/procedureSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { InputItem } from '../../../../Shared/Forms';
import { useState } from 'react';
import ProcedureTimelineVisit from './ProcedureTimelineVisit';

interface Props {
  disabled?: boolean;
  maxWidth?: number;
}

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

function dateDiffInDays(a: Date, b: Date) {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

const ProcedureTimeLine: React.FC<Props> = ({ disabled = false, maxWidth }) => {
  const visits = useAppSelector((state) => state.consultation.procedure.visits);
  const currentVisit = useAppSelector((state) => state.consultation.procedure.currentVisit);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const [minTotalDays, setMinTotalDays] = useState<number>(0);
  const [dayWidth, setDayWidth] = useState<number>(8);
  const timelineRef = useRef<HTMLDivElement>(null);
  const [monthLabels, setMonthLabels] = useState<number[]>([]);
  const [offsets, setOffsets] = useState<number[]>([0]);

  const resize = useCallback(
    function resize() {
      if (!timelineRef?.current?.parentElement) return;
      const clientWidth = maxWidth || timelineRef.current.parentElement.clientWidth;
      const newDayWidth =
        (clientWidth - 70) / (monthLabels.length - new Date(visits[0].date).getDate() / 60) / 31;
      setDayWidth(Math.max(Math.min(newDayWidth, maxWidth ? 100 : 20), 1));
    },
    [maxWidth, visits, monthLabels.length]
  );

  useEffect(() => {
    const totalDays = dateDiffInDays(
      new Date(visits[0].date),
      new Date(visits[visits.length - 1].date)
    );

    setMonthLabels(new Array(Math.round(Math.max(totalDays, minTotalDays, 60) / 30) + 2).fill(1));
    setOffsets(
      visits.map((visit: Visit) => dateDiffInDays(new Date(visits[0].date), new Date(visit.date)))
    );
  }, [visits, minTotalDays]);

  useEffect(() => {
    resize();
  }, [monthLabels.length]);

  useEffect(() => {
    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [resize]);

  return (
    <TimeLine ref={timelineRef}>
      <TimeLineHeaderLine>
        {/* We will maybe use this later, but in another place. */}
        <DateWrapper>
          <InputItem
            disabled={disabled}
            value={new Date(visits[0].date)}
            type="datePicker"
            onChange={(date: Date) => {
              const newDate = new Date(date);
              if (!isNaN(newDate.getTime()))
                dispatch(SET_VISIT_DATE({ index: 0, date: newDate.toUTCString() }));
            }}
            timeVisible={false}
          />
        </DateWrapper>
        {monthLabels.map((_, index) => {
          const newDate = new Date(visits[0].date);
          newDate.setMonth(newDate.getMonth() + index);
          return (
            <MonthLabelBox
              dayWidth={dayWidth}
              key={'month_label_' + index}
              daysInMonth={getDaysInMonth(newDate.getFullYear(), newDate.getMonth() + 1)}
              startDay={newDate.getDate()}
            >
              <span>{new Intl.DateTimeFormat('en-US', { month: 'short' }).format(newDate)}</span>
            </MonthLabelBox>
          );
        })}
      </TimeLineHeaderLine>
      <TimelineRow>
        {visits.length > 0 ? (
          <>
            <TimeLineLine
              length={offsets[offsets.length - 1] * dayWidth}
              start={offsets[0] * dayWidth}
            />
            {offsets.slice(1).map((offset: number, index: number) => (
              <WeeksMarker key={index} offset={((offsets[index] + offset) * dayWidth) / 2}>
                {Math.floor((offset - offsets[index]) / 7) +
                  ' ' +
                  intl.formatMessage({ id: 'general.weeks', defaultMessage: 'weeks' })}
              </WeeksMarker>
            ))}
            {visits.map((visit: Visit, index: number) => {
              const visitDate = new Date(visit.date);
              const offset = dateDiffInDays(new Date(visits[0].date), visitDate);
              return (
                <ProcedureTimelineVisit
                  key={'visit_' + index}
                  index={index}
                  name={visit.name}
                  dayWidth={dayWidth}
                  onDrag={(index, diffDays) => {
                    const newOffset = offset + diffDays;
                    if (index === visits.length - 1 && newOffset > minTotalDays)
                      setMinTotalDays(newOffset);
                    const newOffsets = [...offsets];
                    newOffsets[index] = newOffset;
                    setOffsets(newOffsets);
                  }}
                  onDragStop={(index, diffDays) => {
                    if (diffDays !== 0) {
                      dispatch(
                        SET_VISIT_DATE({
                          index: index,
                          date: new Date(
                            new Date(visitDate).setDate(visitDate.getDate() + diffDays)
                          ).toUTCString(),
                        })
                      );
                      setMinTotalDays(0);
                    }
                    dispatch(GOTO_STEP({ visitIndex: index, stepIndex: 0 }));
                  }}
                  offset={offset}
                  boundLeft={index > 0 ? offsets[index - 1] * dayWidth : undefined}
                  boundRight={
                    index < offsets.length - 1 ? offsets[index + 1] * dayWidth : undefined
                  }
                  selected={!disabled && index === currentVisit}
                  disabled={disabled}
                />
              );
            })}
          </>
        ) : (
          <FormattedMessage
            id={'consultation.procedure.timeline.nosteps'}
            defaultMessage={'No procedure steps exists, please create them in Planning view'}
          />
        )}
      </TimelineRow>
    </TimeLine>
  );
};

interface TimeLineProps {
  length: number;
  start: number;
}
const TimeLineLine = styled.div.attrs<TimeLineProps>((props) => ({
  style: {
    width: props.length - props.start,
    left: props.start,
  },
}))<TimeLineProps>`
  position: absolute;
  margin-top: 47px;
  border-bottom: 5px solid var(--color-purple);
`;

const WeeksMarker = styled.div.attrs<{ offset: number }>((props) => ({
  style: {
    left: props.offset,
  },
}))<{ offset: number }>`
  position: absolute;
  background: #edeef7;
  transform: translate(-50%, 0);
  white-space: nowrap;
  margin-top: 72px;
  font-size: 0.8rem;
`;

const TimeLine = styled.div`
  position: relative;
  padding-left: 70px;
  width: fit-content;
  min-height: 170px;
  height: 100%;
  background-color: #edeef7;
  border-top: 2px solid var(--color-purple);
`;

const DateWrapper = styled.div`
  display: none;
  input {
    border: none;
    background: transparent;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: pointer;
  }
  position: absolute;
  left: -26px;
  cursor: pointer;
`;

const TimeLineHeaderLine = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  max-height: 720px;
  display: flex;
`;

const MonthLabelBox = styled.div<{
  daysInMonth: number;
  startDay: number;
  dayWidth: number;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  ${(props) => `width: ${props.daysInMonth * props.dayWidth}px`};
  ${(props) => `min-width: ${props.daysInMonth * props.dayWidth}px`};
  border-left: 1px solid var(--color-border-20);
  padding-left: 0;
  box-sizing: border-box;

  &:nth-child(2) {
    ${(props) => `margin-left: -${props.startDay * props.dayWidth}px`};
  }

  &:last-child {
    border-right: 1px solid var(--color-border-20);
  }

  span {
    position: relative;
    left: 0;
    top: 4px;
    width: 100%;
    box-sizing: border-box;
    font-size: 11px;
    text-align: center;
    letter-spacing: 1px;
    color: var(--color-border);
    opacity: 0.75;
  }
`;

const TimelineRow = styled.div`
  position: absolute;
  top: 50px;
`;
export default ProcedureTimeLine;
