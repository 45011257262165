import { Button } from '../Shared/Buttons';
import { H3, H2 } from '../Shared/Headings';
import { FC, useState } from 'react';
import { getProjection } from './helpers/getProjection';
import { useAppDispatch } from '../../utils/hooks';
import { selectPatientImplant } from '../../redux/actions/patientActions';
import { getOrderedMeasurements } from './helpers/getOrderedMeasurements';
import { FormattedMessage, useIntl } from 'react-intl';
import { IImplant, IImplantMeasurement } from '../../types/implant';
import ImplantChart from './chart/ImplantChart';
import IsqInfoModal from './IsqInfoModal';
import ISQListItem from './ISQListItem';
import Switch from 'react-switch';
import styles from './ImplantPanel.module.scss';
import cn from 'classnames';

interface Props {
  implant: IImplant;
  showCreateMeasurementModal: (
    tooth: number,
    implantLevel: boolean,
    measurement: IImplantMeasurement | null
  ) => void;
}

const ImplantStability: FC<Props> = ({ implant, showCreateMeasurementModal }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [showAbutment, setShowAbutment] = useState(false);
  const [showDetails, setShowDetails] = useState(false);

  const hasAbutmentMeasurements = () =>
    implant.measurements.find((m) => m.level === 'Abutment') !== undefined;

  const toggleAbutment = () => setShowAbutment((prevState) => !prevState);
  const toggleDetails = () => setShowDetails((prevState) => !prevState);

  const orderedMeasurements = getOrderedMeasurements(implant.measurements);
  const projection = getProjection(orderedMeasurements, intl);

  return (
    <div className={styles.overviewContainer}>
      <button onClick={() => dispatch(selectPatientImplant(-1))} className={styles.closeButton}>
        <i className={styles.closeIcon}>close</i>
      </button>
      <H2 className={styles.tabHeading}>
        <FormattedMessage id="patient.stability" defaultMessage="Stability" />
      </H2>
      <div className={cn(styles.overviewWrapper)}>
        <div className={styles.dataCol}>
          <ISQListItem
            label={intl.formatMessage({
              id: 'patient.measurements',
              defaultMessage: 'Measurements',
            })}
            data={implant}
            showCreateMeasurementModal={showCreateMeasurementModal}
            all
            levelInfo
          />
          {implant.dataId > 0 && (
            <Button
              primary
              value={intl.formatMessage({
                id: 'patient.measureStability',
                defaultMessage: 'Measure stability',
              })}
              leftIcon="smartpeg"
              onClick={() => showCreateMeasurementModal(implant.tooth, true, null)}
              noShadow
              small
              centered
            />
          )}
        </div>
        <div className={cn(styles.dataCol, styles.chartWrapper)}>
          <div>
            <H3>
              <FormattedMessage id="patient.trend" defaultMessage="Trend" />
            </H3>
            <label className={styles.switchWrapper}>
              <span>
                <FormattedMessage id="patient.ShowDetails" defaultMessage="Show Details" />
              </span>
              <Switch
                checked={showDetails}
                onChange={toggleDetails}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={styles.purple}
                offColor={styles.background}
                onHandleColor="#fff"
                offHandleColor={styles.purple}
                activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                handleDiameter={16}
                height={20}
                width={38}
              />
            </label>
            {hasAbutmentMeasurements() && (
              <label className={styles.switchWrapper}>
                <span>
                  <FormattedMessage id="patient.ShowAbutment" defaultMessage="Show Abutment" />
                </span>
                <Switch
                  checked={showAbutment}
                  onChange={toggleAbutment}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor={styles.purple}
                  offColor={styles.background}
                  onHandleColor="#fff"
                  offHandleColor={styles.purple}
                  activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                  handleDiameter={16}
                  height={20}
                  width={38}
                />
              </label>
            )}
          </div>
          <ImplantChart
            measurements={orderedMeasurements}
            tooth={implant.tooth}
            projection={projection}
            excludeAbutment={!showAbutment}
            showDetails={showDetails}
            showCreateMeasurementModal={showCreateMeasurementModal}
            noColor={undefined}
          />
          <div className={styles.isqInfoIcon}>
            <IsqInfoModal />
          </div>
          {showAbutment && (
            <div className={styles.abutmentNote}>
              *{' '}
              <FormattedMessage
                id="patient.abutmentNote"
                defaultMessage="The ISQ on abutment level will not be equal to measuring on implant level and should be used as a relative ISQ value for tracking the implant stability."
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImplantStability;
