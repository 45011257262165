import { useAppSelector } from '../../../../../../../utils/hooks';
import AbutmentFixed from '../../../../../../../common/graphics/consultation/abutment_fixed.png';
import AbutmentOverdenture from '../../../../../../../common/graphics/consultation/abutment_overdenture.png';
import SvgStabilityPeg from '../../../../../../../shared/images/stability_peg.svg';
import { Group, Text } from 'react-konva';
import { CanvasImage, CanvasImageCentered } from '../TeethCanvasViewport';
import TeethData, {
  teethOffset,
} from '../../../../../../../redux/slices/data-structures/teethPositionStructure';
import { TeethCanvasTiltableImplant } from '../TeethCanvasTiltableImplant';

const overdentureLowerY = 220;
const overdentureUpperY = 212;

export function TeethCanvasToolsLayer({
  changeCursor,
}: {
  changeCursor: (cursor: string) => void;
}) {
  const implant = useAppSelector((state) => state.consultation.workspace.present.implant);
  const implantType = useAppSelector((state) => state.consultation.workspace.present.implantType);
  const abutment = useAppSelector((state) => state.consultation.workspace.present.abutment);
  const abutmentType = useAppSelector((state) => state.consultation.workspace.present.abutmentType);
  const bone = useAppSelector((state) => state.consultation.workspace.present.boneState);
  const stability = useAppSelector((state) => state.consultation.workspace.present.stability);
  const overdenture = useAppSelector(
    (state) => state.consultation.workspace.present.teethOverdenture
  );
  const showToothNumbers = useAppSelector(
    (state) => state.consultation.workspace.present.view.toothNumbers
  );
  const teethNotation = useAppSelector((state) => state.user.user.teethNotation);
  return (
    <Group>
      {TeethData.map((teeth, index) => {
        const waveOffset = Math.sin(((index % 16) * Math.PI) / 16) * 12;
        const upperRow = index < 16;
        const depth = bone[index].graft ? 0 : bone[index].depth * 22;
        const toothNumber =
          teethNotation === 'UNS' ? teeth.name.uns.toString() : teeth.name.fdi.toString();

        const restorationHeight =
          bone[index].graft || overdenture[index][1] ? 0 : bone[index].depth;
        const abutmentGraphicOffset = abutmentType[index] === 'OVERDENTURE' ? 6 : 0;

        const root = teethOffset[teeth.id] ?? { x: 0, w: 0 };

        return upperRow ? (
          <Group key={`canvas_tool_${index}`}>
            <Group y={31}>
              {stability[index] && (
                <CanvasImageCentered
                  x={teeth.position - teeth.implantWidth / 4}
                  y={138 - depth - waveOffset + (abutment[index] ? 34 - abutmentGraphicOffset : 0)}
                  url={SvgStabilityPeg}
                />
              )}
              {implant[index] && (
                <TeethCanvasTiltableImplant
                  rootX={teeth.position}
                  rootY={155 - depth - waveOffset * 1.3}
                  clampedWidth={teeth.implantWidth}
                  index={index}
                  changeCursor={changeCursor}
                  type={implantType[index]}
                />
              )}
              {abutment[index] && (
                <CanvasImageCentered
                  x={teeth.position - teeth.implantWidth / 4}
                  y={143 - depth - waveOffset - abutmentGraphicOffset}
                  url={abutmentType[index] === 'OVERDENTURE' ? AbutmentOverdenture : AbutmentFixed}
                  scaleX={teeth.implantWidth / 90}
                  scaleY={-1}
                />
              )}
            </Group>

            <Group>
              {(overdenture[index][0] || overdenture[index][1]) && (
                <>
                  <CanvasImage
                    x={teeth.offset + root.x}
                    y={overdentureUpperY}
                    url={teeth.restoration_base}
                    alignToBottom={upperRow}
                    scaleY={0.5}
                    opacity={1}
                    width={teeth.zoneWidth + root.w}
                  />
                  <CanvasImage
                    x={teeth.offset + root.x}
                    y={overdentureUpperY - 29}
                    url={teeth.restoration_ext}
                    alignToBottom={upperRow}
                    scaleY={0.7 + restorationHeight * 0.5}
                    opacity={1}
                    width={teeth.zoneWidth + root.w}
                  />
                </>
              )}
              {showToothNumbers && (
                <Text
                  x={teeth.position - teeth.implantWidth / 2}
                  y={175}
                  text={toothNumber}
                  fontSize={12}
                  opacity={0.4}
                  align={'center'}
                  width={teeth.implantWidth / 2}
                />
              )}
            </Group>
          </Group>
        ) : (
          <Group key={`canvas_tool_${index}`}>
            {stability[index] && (
              <CanvasImage
                x={teeth.position - teeth.implantWidth / 4 - 6}
                y={
                  286 + depth + waveOffset / 2 + (abutment[index] ? -34 + abutmentGraphicOffset : 0)
                }
                url={SvgStabilityPeg}
                scaleY={-1}
              />
            )}
            <Group>
              {implant[index] && (
                <TeethCanvasTiltableImplant
                  rootX={teeth.position}
                  rootY={315 + depth + waveOffset / 2}
                  clampedWidth={teeth.implantWidth}
                  index={index}
                  changeCursor={changeCursor}
                  type={implantType[index]}
                />
              )}
              {abutment[index] && (
                <CanvasImageCentered
                  x={teeth.position - teeth.implantWidth / 4}
                  y={260 + depth + waveOffset / 2 + abutmentGraphicOffset}
                  url={abutmentType[index] === 'OVERDENTURE' ? AbutmentOverdenture : AbutmentFixed}
                  scaleX={teeth.implantWidth / 100}
                />
              )}
            </Group>

            <Group>
              {(overdenture[index][0] || overdenture[index][1]) && (
                <>
                  <CanvasImage
                    x={teeth.offset + root.x}
                    y={overdentureLowerY}
                    url={teeth.restoration_base}
                    scaleY={0.5}
                    opacity={1}
                    width={teeth.zoneWidth + root.w}
                  />
                  <CanvasImage
                    x={teeth.offset + root.x}
                    y={overdentureLowerY + 29}
                    url={teeth.restoration_ext}
                    scaleY={0.7 + restorationHeight * 0.5}
                    opacity={1}
                    width={teeth.zoneWidth + root.w}
                  />
                </>
              )}
              {showToothNumbers && (
                <Text
                  x={teeth.position - teeth.implantWidth / 2}
                  y={240}
                  text={toothNumber}
                  fontSize={12}
                  opacity={0.4}
                  align={'center'}
                  width={teeth.implantWidth / 2}
                />
              )}
            </Group>
          </Group>
        );
      })}
    </Group>
  );
}
