import { useAppSelector } from '../../../../../../../utils/hooks';
import { Group, Image } from 'react-konva';
import { RootState } from '../../../../../../../redux/store';
import { useEffect, useRef } from 'react';
import Konva from 'konva';
import useImage from 'use-image';
import { useDispatch } from 'react-redux';
import { SET_IMAGE } from '../../../../../../../redux/slices/workspaceSlice';
import { Html } from 'react-konva-utils';
import CircularButton from '../../../../../../Shared/CircularButton/CircularButton';
import styled from 'styled-components';

/**
 * @param {string} removeImageIntl - We pass intl because the parent of ImageLayer
 * does not have <IntlProvider> in its ancestry.
 */

export function ImageLayer(props: { width: number; height: number; removeImageIntl: string }) {
  const dispatch = useDispatch();
  const image = useAppSelector((state: RootState) => state.consultation.workspace.present.image);
  const [img] = useImage(image);
  const imageRef = useRef<Konva.Image>(null);

  useEffect(() => {
    const maxWidth = props.width - 20;
    const maxHeight = props.height - 20;
    const scaleX = maxWidth / (img?.width ?? maxWidth);
    const scaleY = maxHeight / (img?.height ?? maxHeight);
    const scale = Math.min(scaleX, scaleY);

    imageRef.current?.scaleX(scale);
    imageRef.current?.scaleY(scale);
  }, [img, props]);

  return (
    <Group>
      {img && <Image image={img} ref={imageRef} x={0} y={20} />}
      <Html
        divProps={{
          style: {
            position: 'absolute',
            top: '0px',
            left: '0px',
          },
        }}
      >
        <CircularButtonWrapper data-html2canvas-ignore={true}>
          <CircularButton
            className="removeImageButton"
            onClick={() => {
              dispatch(SET_IMAGE(undefined));
            }}
            icon="close"
            buttonText={props.removeImageIntl}
            textToTheSide={true}
          />
        </CircularButtonWrapper>
      </Html>
    </Group>
  );
}

const CircularButtonWrapper = styled.div`
  position: relative;
  .removeImageButton {
    position: absolute;
    left: -17px;
  }
`;
