import * as React from 'react';

function SvgBoneliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1482 2048" {...props}>
      <path d="M1222 927q9 0 15.5 6.5t6.5 15.5v405q0 62-44 105.5t-105 43.5H943q-9 0-15-6t-6-15 6-15 15-6h152q44 0 75-31.5t31-75.5V949q0-9 6-15.5t15-6.5zm-400 534q9 0 15 6t6 15-6 15-15 6H713q-9 0-15-6t-6-15 6-15 15-6h109zm417-1244q59 0 100.5 40.5T1381 355v1051q0 97-68.5 166t-165.5 69H337q-97 0-166-69t-69-166V355q0-57 41-97.5t98-40.5 96.5 36 44.5 91q16 197 62 338.5T555 824q22 0 37-13t25-42.5 16-58 13-78.5q5-37 8.5-56.5t11-48 16.5-43 24.5-25.5 35.5-11q17 0 30.5 6.5T795 476t15.5 28 12 39.5 8 41.5 6.5 47q9 66 17.5 102t27 63 47.5 27q65 0 111-141.5t62-338.5q5-55 43.5-91t93.5-36zm99 1189V355q0-40-28-68t-68-28-67.5 25-30.5 63q-4 58-11.5 115T1108 596t-39 133.5-58.5 96.5-81.5 40q-21 0-38.5-7.5t-30-18.5-22.5-31.5-16-36-12-44-8.5-44.5-6.5-47q-5-37-8-55t-9.5-44-15-37-20.5-11-21 11.5-15 36.5-9.5 44.5T688 637q-5 37-8 55t-10 51-16 50-22.5 36.5-32.5 28-44 8.5q-45 0-81.5-40t-59-96.5T375 596t-24.5-134.5T339 347q-3-38-30.5-63T244 259q-42 0-70.5 28T145 355v1051q0 80 56.5 136t135.5 56h810q79 0 135-56t56-136z" />
    </svg>
  );
}

export default SvgBoneliga;
