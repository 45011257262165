import * as types from '../../constants/actionTypes';

const initialState = {
  userRegistration: {
    complete: false,
    error: undefined,
    busy: false,
  },
  userUpdate: {
    complete: false,
    error: undefined,
    busy: false,
  },
  user: {
    profile: {
      tileSettings: [],
    },
    userCreated: false,
    loaded: false,
    userGroups: [],
  },
  actAsUser: {
    profile: {
      tileSettings: [],
    },
    userCreated: false,
    loaded: false,
    userGroups: [],
  },
  getUsers: {
    users: [],
    complete: false,
    error: undefined,
    busy: false,
  },
  deleteUser: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function user(state = initialState, action) {
  let userCopy;
  let actAsUserCopy;
  let getUsersCopy;
  let usersCopy;
  let userIndex;
  let user;
  let initialStateCopy;

  switch (action.type) {
    case types.REGISTER_USER:
      return Object.assign({}, state, {
        userRegistration: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.REGISTER_USER_SUCCESS:
      return Object.assign({}, state, {
        userRegistration: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.REGISTER_USER_ERROR:
      return Object.assign({}, state, {
        userRegistration: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.UPDATE_USER:
      if (!action.updateOther) {
        userCopy = Object.assign({}, state.user);
        userCopy.title = action.userData.title;
        userCopy.profession = action.userData.profession;
        userCopy.firstName = action.userData.firstName;
        userCopy.surname = action.userData.surname;
        userCopy.email = action.userData.email;
        userCopy.placementsYear = action.userData.placementsYear;
        userCopy.yearsOfPlacements = action.userData.yearsOfPlacements;
        userCopy.restorationsYear = action.userData.restorationsYear;
        userCopy.yearsOfRestorations = action.userData.yearsOfRestorations;
        userCopy.teethNotation = action.userData.teethNotation;
        userCopy.logoutTimeInMinutes = action.userData.logoutTimeInMinutes;
        userCopy.sourceUserGroup = action.userData.sourceUserGroup;
        return Object.assign({}, state, {
          userUpdate: {
            complete: false,
            error: undefined,
            busy: true,
          },
          user: userCopy,
        });
      } else {
        actAsUserCopy = Object.assign({}, state.actAsUser);
        actAsUserCopy.title = action.userData.title;
        actAsUserCopy.profession = action.userData.profession;
        actAsUserCopy.firstName = action.userData.firstName;
        actAsUserCopy.surname = action.userData.surname;
        actAsUserCopy.email = action.userData.email;
        actAsUserCopy.placementsYear = action.userData.placementsYear;
        actAsUserCopy.yearsOfPlacements = action.userData.yearsOfPlacements;
        actAsUserCopy.restorationsYear = action.userData.restorationsYear;
        actAsUserCopy.yearsOfRestorations = action.userData.yearsOfRestorations;
        actAsUserCopy.teethNotation = action.userData.teethNotation;
        actAsUserCopy.logoutTimeInMinutes = action.userData.logoutTimeInMinutes;
        actAsUserCopy.sourceUserGroup = action.userData.sourceUserGroup;
        return Object.assign({}, state, {
          userUpdate: {
            complete: false,
            error: undefined,
            busy: true,
          },
          actAsUser: actAsUserCopy,
        });
      }
    case types.UPDATE_USER_SUCCESS:
      return Object.assign({}, state, {
        userUpdate: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.UPDATE_USER_ERROR:
      return Object.assign({}, state, {
        userUpdate: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.GET_USER:
      return Object.assign({}, state, {});
    case types.GET_USER_SUCCESS:
      if (!action.userId) {
        userCopy = Object.assign({}, action.user);
        userCopy.loaded = true;
        return Object.assign({}, state, {
          user: userCopy,
        });
      } else {
        actAsUserCopy = Object.assign({}, action.user);
        actAsUserCopy.loaded = true;
        return Object.assign({}, state, {
          actAsUser: actAsUserCopy,
        });
      }
    case types.GET_USER_ERROR:
      if (!action.userId) {
        userCopy = Object.assign({}, state.user);
        userCopy.loaded = action.error.statusCode === 404;
        return Object.assign({}, state, {
          user: userCopy,
        });
      } else {
        actAsUserCopy = Object.assign({}, state.user);
        actAsUserCopy.loaded = action.error.statusCode === 404;
        return Object.assign({}, state, {
          actAsUser: actAsUserCopy,
        });
      }
    case types.GET_USERS:
      return Object.assign({}, state, {
        getUsers: {
          complete: false,
          error: undefined,
          busy: true,
          users: [],
        },
      });
    case types.GET_USERS_SUCCESS:
      return Object.assign({}, state, {
        getUsers: {
          complete: true,
          error: undefined,
          busy: false,
          users: action.users,
        },
      });
    case types.GET_USERS_ERROR:
      return Object.assign({}, state, {
        getUsers: {
          complete: false,
          error: action.error,
          busy: false,
          users: [],
        },
      });
    case types.DELETE_USER:
      return Object.assign({}, state, {
        deleteUser: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.DELETE_USER_SUCCESS:
      return Object.assign({}, state, {
        deleteUser: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.DELETE_USER_ERROR:
      return Object.assign({}, state, {
        deleteUser: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    case types.ACCEPT_EULA_SUCCESS:
      userCopy = Object.assign({}, state.user);
      userCopy.currentEulaAccepted = true;
      return Object.assign({}, state, {
        user: userCopy,
      });
    case types.CLEAR_USER:
      userCopy = Object.assign({}, state.user);
      initialStateCopy = Object.assign({}, initialState);
      initialStateCopy.user = userCopy;
      return Object.assign({}, state, initialStateCopy);
    case types.CLEAR_USERS:
      return Object.assign({}, state, {
        getUsers: Object.assign({}, initialState.getUsers),
      });
    // case types.CHANGE_USER_ROLE:
    // 	return Object.assign({}, state, { getUsers: Object.assign({}, initialState.getUsers) });
    case types.CHANGE_USER_ROLE_SUCCESS:
      getUsersCopy = Object.assign({}, state.getUsers);
      usersCopy = [...getUsersCopy.users];
      user = usersCopy.find((user) => user.id === action.userId);
      userIndex = usersCopy.indexOf(user);
      userCopy = Object.assign({}, user);
      userCopy.role = action.newUserRole;
      usersCopy[userIndex] = userCopy;
      getUsersCopy.users = usersCopy;
      return Object.assign({}, state, {
        getUsers: getUsersCopy,
      });
    case types.CHANGE_USER_ROLE_ERROR:
      getUsersCopy = Object.assign({}, state.getUsers);
      usersCopy = [...getUsersCopy.users];
      user = usersCopy.find((user) => user.id === action.userId);
      userIndex = usersCopy.indexOf(user);
      userCopy = Object.assign({}, user);
      usersCopy[userIndex] = userCopy;
      getUsersCopy.users = usersCopy;
      return Object.assign({}, state, {
        getUsers: getUsersCopy,
      });
    case types.ADD_USER_PROMOTION:
      userCopy = Object.assign({}, state.user);
      userCopy.promotions = [...userCopy.promotions, action.promotion];
      return Object.assign({}, state, {
        user: userCopy,
      });
    case types.USE_PROMOTION_SUCCESS:
      userCopy = Object.assign({}, state.user);
      userCopy.promotions = userCopy.promotions.filter((prom) => prom.id !== action.promotionId);
      return Object.assign({}, state, {
        user: userCopy,
      });
    case types.DISMISS_NOTE:
      userCopy = Object.assign({}, state.user);
      userCopy.dismissedNotes = [...userCopy.dismissedNotes, action.noteId];
      return Object.assign({}, state, {
        user: userCopy,
      });
    default:
      return state;
  }
}
