import React from 'react';
import ProcedureTimeLine from './timeline/ProcedureTimeLine';
import ProcedureDetail from './detail/ProcedureDetail';
import styled from 'styled-components';

const ProcedureView: React.FC = () => {
  return (
    <>
      <ProcedureTimeLineWrapper>
        <ProcedureTimeLine />
      </ProcedureTimeLineWrapper>
      <ProcedureDetail />
    </>
  );
};

const ProcedureTimeLineWrapper = styled.div`
  overflow: auto hidden;
  flex: 1 1 0%;
  max-width: 100vw;
  min-height: 200px;
`;

export default ProcedureView;
