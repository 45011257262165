import { IntlShape } from 'react-intl';
import { IImplantMeasurement } from '../../../types/implant';

export const getProjection = (measurements: IImplantMeasurement[], intl: IntlShape) => {
  if (measurements.length > 1) {
    const lastMeasurement = measurements[measurements.length - 1];

    if (lastMeasurement.estimatedLoading) {
      const { formatTime } = intl;

      return {
        date: new Date(lastMeasurement.estimatedLoading).getTime(),
        dateString: formatTime(lastMeasurement.estimatedLoading, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        }),
        BL: 70,
        MD: 70,
        projection: 70,
      };
    }
  }
};
