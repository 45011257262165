import { H2 } from '../../components/Shared/Headings';
import { useIntl } from 'react-intl';
import { appInsights } from '../../utils/applicationInsights';
import { useEffect, useState } from 'react';
import { Section, SectionInner } from '../../components/Shared/Section';
import { IPatient, IPatientSortValues } from '../../types/patient';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  exportPatients,
  subscribeToPatientsChange,
  unsubscribeFromPatientsChange,
  getPatients as getPatientsRequest,
  restoreDemoPatients as restoreDemoPatientsRequest,
} from '../../redux/actions/patientActions';
import PatientsListFilterPanel from './PatientsListFilterPanel';
import PatientRecordsTable from '../../components/PatientRecords/PatientRecordsTable';
import PatientsListHeader from './PatientsListHeader';
import Spinner from '../../components/Shared/Loaders/Spinner';
import styles from './PatientListPage.module.scss';

const PatientListPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { getPatients, restoreDemoPatients } = useAppSelector((state) => state.patient);
  const patients = useAppSelector<IPatient[]>((state) => state.patient.patients);

  const [exportingPatients, setExportingPatients] = useState(false);
  const [sortValues, setSortValues] = useState<IPatientSortValues>({
    selectedOptionIndex: 0,
    propertyToSortBy: 'lastModified',
    sortByDate: true,
  });

  useEffect(() => {
    appInsights.trackEvent({
      name: 'PageVisit',
      properties: {
        page: 'patientlist',
        email: user.email,
      },
    });
    dispatch(subscribeToPatientsChange(user));
    dispatch(getPatientsRequest());

    return () => {
      dispatch(unsubscribeFromPatientsChange(user));
    };
  }, [user, dispatch]);

  const activePatients = patients ? patients.filter((patient) => patient.isPatientActive) : [];
  const inactivePatients = patients ? patients.filter((patient) => !patient.isPatientActive) : [];

  const exportPatientsHandler = () => {
    setExportingPatients(true);
    dispatch(exportPatients(exportDoneHandler));
  };

  const exportDoneHandler = () => setExportingPatients(false);

  const comparePropertyName = (a: IPatient, b: IPatient, propertyName: keyof IPatient) => {
    const aValue = String(a[propertyName]);
    const bValue = String(b[propertyName]);
    return aValue.localeCompare(bValue);
  };

  const compareDate = (a: IPatient, b: IPatient, propertyName: keyof IPatient) => {
    const aValue = new Date(String(a[propertyName])).getTime();
    const bValue = new Date(String(b[propertyName])).getTime();
    return bValue - aValue;
  };

  const sortArray = (
    propertyName: keyof IPatient,
    array: IPatient[],
    compareMethod: (a: IPatient, b: IPatient, propertyName: keyof IPatient) => number
  ) => array.sort((a, b) => compareMethod(a, b, propertyName));

  const sortPatientsData = (patients: IPatient[]) =>
    sortArray(
      sortValues.propertyToSortBy,
      patients,
      sortValues.sortByDate ? compareDate : comparePropertyName
    );

  const restoreDemoPatientsHandler = () =>
    dispatch(restoreDemoPatientsRequest(user.sourceUserGroup.id));

  const sortPatientsHandler = (sortValues: IPatientSortValues) => setSortValues(sortValues);

  return (
    <div>
      <PatientsListHeader
        patients={patients}
        user={user}
        exportingPatients={exportingPatients}
        restoreDemoPatientsBusy={restoreDemoPatients.busy}
        exportPatientsHandler={exportPatientsHandler}
        restoreDemoPatients={restoreDemoPatientsHandler}
      />
      <Section>
        {getPatients.busy ? (
          <Spinner className={styles.spinnerBackground} />
        ) : (
          <SectionInner>
            <PatientsListFilterPanel
              sortValues={sortValues}
              sortPatientsHandler={sortPatientsHandler}
            />
            <H2 className={styles.header}>
              {intl.formatMessage({
                id: 'patientList.activePatients',
                defaultMessage: 'Active patients',
              })}
            </H2>
            <PatientRecordsTable
              dataCy={'active-patients-list'}
              patients={sortPatientsData(activePatients)}
            />
            <H2 className={styles.header}>
              {intl.formatMessage({
                id: 'patientList.inactivePatients',
                defaultMessage: 'Inactive patients',
              })}
            </H2>

            <PatientRecordsTable
              dataCy={'inactive-patients-list'}
              patients={sortPatientsData(inactivePatients)}
            />
          </SectionInner>
        )}
      </Section>
    </div>
  );
};

export default PatientListPage;
