import React from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import { SET_SKINTONE } from '../../../../../../redux/slices/workspaceSlice';

const tones = ['#fceacc', '#e6cea2', '#e5a270', '#b57f50', '#644d41'];

export function SkintoneSelector() {
  const dispatch = useAppDispatch();
  const skintone = useAppSelector((state) => state.consultation.workspace.present.view.skintone);

  return (
    <SkintoneWrapper data-html2canvas-ignore={true}>
      {tones.map((tone, index) => {
        return (
          <Skintone
            key={index}
            color={tone}
            selected={index === skintone}
            onClick={() => dispatch(SET_SKINTONE(index))}
          />
        );
      })}
    </SkintoneWrapper>
  );
}

const SkintoneWrapper = styled.div`
  position: absolute;
  bottom: 40px;
  left: 75%;
  display: flex;
  align-items: center;
`;

const Skintone = styled.div<{ color?: string; selected?: boolean }>`
  width: 25px;
  height: 25px;
  border-radius: 25px;
  margin: 0 5px;
  background: ${(props) => props.color};
  cursor: pointer;
  ${(props) => props.selected && 'border: black 3px solid;'};
`;
