import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as referenceDataActions from '../../../redux/actions/referenceDataActions';

import styles from './Exports.module.scss';

import { Row } from '../../../components/Shared/Section';
import { PanelWrapper, Panel } from '../../../components/Shared/Panel';
import { Button } from '../../../components/Shared/Buttons';
import { InputItem } from '../../../components/Shared/Forms';
import { H2 } from '../../../components/Shared/Headings';

class Exports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportingImplants: false,
      exportingAbutments: false,
      exportingSmartPegList: false,
      exportingSmartPegListDraft: false,
      exportingSmartPegDiffList: false,
      listVersion: '',
      listDate: '',
    };

    this.exportDone = this.exportDone.bind(this);
    this.exportImplants = this.exportImplants.bind(this);
    this.exportAbutments = this.exportAbutments.bind(this);
    this.versionChanged = this.versionChanged.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.exportSmartPegList = this.exportSmartPegList.bind(this);
    this.exportSmartPegDiffList = this.exportSmartPegDiffList.bind(this);
  }

  exportImplants() {
    this.setState({
      exportingImplants: true,
    });

    this.props.actions.exportImplantsSql(this.exportDone);
  }

  exportAbutments() {
    this.setState({
      exportingAbutments: true,
    });

    this.props.actions.exportAbutmentsSql(this.exportDone);
  }

  exportSmartPegList(draft = false) {
    if (draft)
      this.setState({
        exportingSmartPegListDraft: true,
      });
    else
      this.setState({
        exportingSmartPegList: true,
      });

    this.props.actions.exportSmartPegList(draft, this.exportDone);
  }

  exportSmartPegDiffList() {
    this.setState({
      exportingSmartPegDiffList: true,
    });

    this.props.actions.exportSmartPegDiffList(this.exportDone);
  }

  exportDone() {
    this.setState({
      exportingImplants: false,
      exportingAbutments: false,
      exportingSmartPegList: false,
      exportingSmartPegListDraft: false,
      exportingSmartPegDiffList: false,
    });
  }

  versionChanged(evt) {
    this.setState({
      listVersion: evt.target.value,
    });
  }

  dateChanged(evt) {
    this.setState({
      listDate: evt.target.value,
    });
  }

  onRelease() {
    this.props.actions.releaseRefList(this.state.listVersion, this.state.listDate);
  }

  render() {
    return (
      <PanelWrapper>
        <Panel full>
          <H2>Release Reference List</H2>
          <Row>
            <div className={styles.infoText}>
              By pressing release the items listed under the Implants and Abutments tabs will be
              published.
              <br />
              Items that are marked &quot;Pending&quot; will not be published.
              <br />
              Items having status &quot;To ref list&quot; will be changed to &quot;On ref list&quot;
              and be given the info text &quot;New&quot; if nothing else is set. The info text will
              be cleared for all items in the work-tables.
            </div>
          </Row>
          <Row>
            <InputItem
              type="text"
              label="Version number, e.g. '78'"
              value={this.state.listVersion}
              onChange={this.versionChanged}
              onWhite
            />
            <InputItem
              type="text"
              label="Valid from date, e.g. 'November'"
              value={this.state.listDate}
              onChange={this.dateChanged}
              onWhite
            />
          </Row>
          <Row>
            <Button
              primary
              value="Release"
              onClick={this.onRelease.bind(this)}
              loading={this.props.releaseRefList.busy}
              disabled={this.props.releaseRefList.busy}
            />
          </Row>
        </Panel>
        <Panel full>
          <H2>Export Reference list</H2>
          <Button
            primary
            value="Export Diff CSV"
            onClick={this.exportSmartPegDiffList}
            loading={this.state.exportingSmartPegDiffList}
            disabled={this.state.exportingSmartPegDiffList}
          />
          <Button
            primary
            value="Export DRAFT PDF"
            onClick={this.exportSmartPegList.bind(this, true)}
            loading={this.state.exportingSmartPegListDraft}
            disabled={this.state.exportingSmartPegListDraft}
          />
          <Button
            primary
            value="Export PDF"
            onClick={this.exportSmartPegList.bind(this, false)}
            loading={this.state.exportingSmartPegList}
            disabled={this.state.exportingSmartPegList}
          />
          <Button
            primary
            value="Export Implants SQL"
            onClick={this.exportImplants}
            loading={this.state.exportingImplants}
            disabled={this.state.exportingImplants}
          />
          <Button
            primary
            value="Export Abutments SQL"
            onClick={this.exportAbutments}
            loading={this.state.exportingAbutments}
            disabled={this.state.exportingAbutments}
          />
        </Panel>
      </PanelWrapper>
    );
  }
}

function mapState(state) {
  return {
    releaseRefList: state.referenceData.releaseRefList,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(referenceDataActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(Exports);
