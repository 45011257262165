import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tab,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useAppSelector } from '../../utils/hooks';
import { MuiPrototypesPageStyles } from './styles/MuiPrototypesPageStyles';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { SyntheticEvent, useState, useEffect } from 'react';
import TableContainer from '@mui/material/TableContainer';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import OverviewCard from './OverviewCard';
import DeleteIcon from '@mui/icons-material/Delete';
import InputField from './InputField';
import BuildIcon from '@mui/icons-material/Build';
import CloseIcon from '@mui/icons-material/Close';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import HomeIcon from '@mui/icons-material/Home';
import TuneIcon from '@mui/icons-material/Tune';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../components/header/Header';
import Table from '@mui/material/Table';

const MuiPrototypesPage = () => {
  const classes = MuiPrototypesPageStyles();

  const history = useHistory();
  const { user } = useAppSelector((state) => state.user);
  const [isAllowedAccess, setIsAllowedAccess] = useState(false);

  const [age, setAge] = useState('');
  const [tabValue, setTabValue] = useState('1');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (user === undefined || user.email === undefined) {
      return;
    }

    if (
      user.email === 'stefan.horn@osstell.com' ||
      user.email === 'thaddeus.picklo@osstell.com' ||
      user.email === 'viktoria.martensson@osstell.com' ||
      user.email === 'petter.fogel@osstell.com' ||
      user.email === 'leon.koopman@osstell.com' ||
      user.email === 'toyah.ohlson@osstell.com' ||
      user.email === 'henrik.johansson@osstell.com' ||
      user.email === 'daniel.barkum@osstell.com' ||
      user.email === 'marie.gustavsson@osstell.com' ||
      user.email === 'per-olof.lundgren@osstell.com'
    ) {
      setIsAllowedAccess(true);
    } else {
      history.goBack();
    }
  }, [user, history]);

  const selectChangeHandler = (event: SelectChangeEvent) => setAge(event.target.value as string);

  const tabChangeHandler = (_event: SyntheticEvent, newValue: string) => setTabValue(newValue);

  const emailChangeHandler = (value: string) => console.log(value);

  const openSideMenuHandler = () => setIsSideMenuOpen(true);
  const closeSideMenuHandler = () => setIsSideMenuOpen(false);

  const openDialogHandler = () => setIsDialogOpen(true);
  const closeDialogHandler = () => setIsDialogOpen(false);

  function createData(name: string, calories: number, fat: number, carbs: number, protein: number) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

  return (
    <>
      <Header title={'MATERIAL UI PROTOTYPES'} />
      {isAllowedAccess && (
        <>
          <Container sx={{ mb: 75 }}>
            <Stack spacing={4} mb={3}>
              <Paper>
                <Stack spacing={1}>
                  <Typography variant="h4">Buttons</Typography>
                  <Stack spacing={2} direction={{ xs: 'column', md: 'row' }}>
                    <Button variant="text">Text</Button>
                    <Button>Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                    <Button startIcon={<AddIcon />}>Button with icon</Button>
                    <Tooltip title="Icon button">
                      <IconButton aria-label="delete">
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Paper>
              <Paper>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h4">Input Fields</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField id="firstName" defaultValue="Petter" label="First name" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField id="lastName" defaultValue="Fogel" label="Last name" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField id="lastName" defaultValue="Fogel" label="Last name" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Age</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={selectChangeHandler}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <InputField
                      id="email"
                      label="Email"
                      fullWidth
                      onValueChange={emailChangeHandler}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Paper>
                <Typography variant="h4">Tabs</Typography>
                <TabContext value={tabValue}>
                  <TabList onChange={tabChangeHandler} aria-label="icon position tabs example">
                    <Tab icon={<HomeIcon />} label="top" value={'1'} />
                    <Tab icon={<BuildIcon />} label="start" value={'2'} />
                    <Tab icon={<TuneIcon />} label="end" value={'3'} />
                  </TabList>
                  <TabPanel value={'1'}>Content One</TabPanel>
                  <TabPanel value={'2'}>Content Two</TabPanel>
                  <TabPanel value={'3'}>Content Three</TabPanel>
                </TabContext>
              </Paper>
              <Paper>
                <Typography variant="h4">Side Menu</Typography>
                <MenuIcon onClick={openSideMenuHandler} cursor={'pointer'} />
                <Drawer open={isSideMenuOpen} onClose={closeSideMenuHandler}>
                  <Box width={250} role="presentation">
                    <Box sx={{ padding: '8px 16px', float: 'right' }}>
                      <CloseIcon cursor={'pointer'} onClick={closeSideMenuHandler} />
                    </Box>
                    <List disablePadding>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <MenuIcon />
                          </ListItemIcon>
                          <ListItemText primary={'Test'} />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <MenuIcon />
                          </ListItemIcon>
                          <ListItemText primary={'Test'} />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                </Drawer>
              </Paper>
              <Paper>
                <Typography variant="h4">Card</Typography>
                <Stack spacing={1} direction={'row'}>
                  <OverviewCard
                    title={'Active Patients'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'+ 12'}
                    progressInput={'+ 4% from last month'}
                  />
                  <OverviewCard
                    title={'Total Patients'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'+ 201'}
                    progressInput={'+ 2% from last year'}
                  />
                  <OverviewCard
                    title={'Success Rate'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'100 %'}
                    progressInput={'+ 1% from last month'}
                  />
                  <OverviewCard
                    title={'Total Placements'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'+ 33'}
                    progressInput={'+ 6% from last month'}
                  />
                  <OverviewCard
                    title={'Avg Healing Time'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'71 days'}
                    progressInput={'+ 1% from last month'}
                  />
                  <OverviewCard
                    title={'Total Measurements'}
                    icon={<PersonAddIcon fontSize="small" />}
                    value={'+ 42'}
                    progressInput={'+ 1% from last month'}
                  />
                </Stack>
              </Paper>
              <Paper>
                <Typography variant="h4" mb={1}>
                  Dialog
                </Typography>
                <Button onClick={openDialogHandler}>Open Dialog</Button>
                <Dialog open={isDialogOpen} onClose={closeDialogHandler}>
                  <DialogTitle>Dialog title</DialogTitle>
                  <DialogContent>
                    <DialogContentText>Dialog content text</DialogContentText>
                    <Grid container spacing={1}>
                      <Grid item xs={12} lg={6}>
                        <InputField
                          id="firstName"
                          label="First Name"
                          fullWidth
                          onValueChange={() => {}}
                        />
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <InputField
                          id="lastName"
                          label="Last Name"
                          fullWidth
                          onValueChange={() => {}}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button variant="outlined" onClick={closeDialogHandler}>
                      Cancel
                    </Button>
                    <Button onClick={closeDialogHandler}>Subscribe</Button>
                  </DialogActions>
                </Dialog>
              </Paper>
              <Paper>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Dessert (100g serving)</TableCell>
                        <TableCell align="right">Calories</TableCell>
                        <TableCell align="right">Fat&nbsp;(g)</TableCell>
                        <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                        <TableCell align="right">Protein&nbsp;(g)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.calories}</TableCell>
                          <TableCell align="right">{row.fat}</TableCell>
                          <TableCell align="right">{row.carbs}</TableCell>
                          <TableCell align="right">{row.protein}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Stack>
          </Container>
        </>
      )}
    </>
  );
};

export default MuiPrototypesPage;
