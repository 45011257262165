import { Card, CardActions, CardContent, Typography } from '@mui/material';
import { FC } from 'react';

interface Props {
  title: string;
  icon: JSX.Element;
  value: string | number;
  progressInput: string;
}

const OverviewCard: FC<Props> = ({ title, icon, value, progressInput }) => {
  return (
    <Card sx={{ width: '100%', maxWidth: '225px' }}>
      <CardContent>
        <CardActions>
          <Typography sx={{ fontSize: 14 }} fontWeight={600}>
            {title}
          </Typography>
          {icon}
        </CardActions>
        <Typography variant="h4" color="secondary" fontWeight={600}>
          {value}
        </Typography>
        <Typography fontWeight={500} sx={{ fontSize: 14 }} color="info.main">
          {progressInput}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default OverviewCard;
