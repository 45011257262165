import { Center } from '../../../../../common/graphics/lib/StyledComponentLibrary';
import { FC, MouseEvent } from 'react';
import { TeethNoseShape } from './draggables/TeethNoseShape';
import { TeethNerveShape } from './draggables/TeethNerveShape';
import { TeethSinusShape } from './draggables/TeethSinusShape';
import { SkintoneSelector } from '../toolbar/toolbarViewSettings/SkintoneSelector';
import { TeethToolResolver } from './TeethToolResolver';
import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';
import ToothInteractionZone from './ToothInteractionZone';
import TeethCanvasViewport from './canvas/TeethCanvasViewport';
import styled from 'styled-components';

interface Props {
  width: number;
  height: number;
}

const TeethWorkspace: FC<Props> = ({ width, height }) => {
  const teethState = useAppSelector((state) => state.consultation.workspace.present.teethState);
  const currentTool = useAppSelector((state) => state.consultation.view.currentTool);
  const currentSubTool = useAppSelector((state) => state.consultation.view.currentSubTool);
  const viewSettingsSinus = useAppSelector(
    (state) => state.consultation.workspace.present.view.sinusMode
  );
  const viewSettingsNerve = useAppSelector(
    (state) => state.consultation.workspace.present.view.nerveNodes
  );
  const darkMode = useAppSelector((state) => state.consultation.workspace.present.view.darkMode);
  const gumsMode = useAppSelector(
    (state) => state.consultation.workspace.present.view.gumsTransparencyMode
  );
  const imageView = useAppSelector((state) => state.consultation.workspace.present.image);
  const smileMode = useAppSelector((state) => state.consultation.workspace.present.view.smileMode);

  const hideSinusNerve = gumsMode || smileMode || imageView;

  const dispatch = useAppDispatch();
  const enableSvgInteractions = currentSubTool?.name !== 'Transform';

  const onHandleClick = (e: MouseEvent<HTMLDivElement>, id: number) => {
    const clickPositionX =
      e.nativeEvent.offsetX / (e.nativeEvent.target as HTMLElement).offsetWidth;

    const action = TeethToolResolver(currentTool, currentSubTool);
    if (action) {
      dispatch(action({ id, clickPositionX }));
    }
  };

  return (
    <div className={'workspace'}>
      <BackgroundTheme darkMode={darkMode && !imageView} />
      <TeethCanvasViewport width={width} height={height} />
      {smileMode && <SkintoneSelector />}
      <Workspace
        data-testid={'workspace'}
        enabled={
          currentTool &&
          currentTool?.name !== 'Text' &&
          currentTool?.name !== 'Draw' &&
          enableSvgInteractions
        }
      >
        <section className={'upper-interaction'}>
          {teethState.map(
            (_tooth: boolean, index: number) =>
              index < 16 && (
                <ToothInteractionZone
                  id={index}
                  key={'tooth_' + index}
                  onClick={onHandleClick}
                  hideBoundaries
                />
              )
          )}
        </section>
        <section className={'lower-interaction'}>
          {teethState.map(
            (_tooth: boolean, index: number) =>
              index >= 16 && (
                <ToothInteractionZone
                  id={index}
                  key={'tooth_' + index}
                  onClick={onHandleClick}
                  hideBoundaries
                />
              )
          )}
        </section>
        {viewSettingsSinus && !hideSinusNerve && (
          <SinusWorkspace>
            <div className={'left'}>
              <TeethSinusShape enabled={enableSvgInteractions} />
            </div>
            <div className={'center'}>
              <TeethNoseShape enabled={enableSvgInteractions} />
            </div>
            <div className={'right'}>
              <TeethSinusShape rightSide enabled={enableSvgInteractions} />
            </div>
          </SinusWorkspace>
        )}
        {viewSettingsNerve && !hideSinusNerve && (
          <NervesWorkspace>
            <TeethNerveShape enabled={enableSvgInteractions} />
            <TeethNerveShape rightSide enabled={enableSvgInteractions} />
          </NervesWorkspace>
        )}
      </Workspace>
    </div>
  );
};

const BackgroundTheme = styled.div<{ darkMode?: boolean }>`
  background-color: ${(props) => (props.darkMode ? '#222' : 'transparent')};
  transition: background-color 160ms ease-in-out;
  height: 520px;
  width: 920px;
  transform: translateX(-50%);
  position: absolute;
  top: 0;
  left: 50%;
  pointer-events: none;
  border-radius: 16px;
`;

const Workspace = styled.div<{ enabled?: boolean }>`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding-top: 100px;
  pointer-events: ${(props) => (props.enabled ? 'auto' : 'none')};
  & > section {
    display: flex;
    flex-direction: row;
    height: 160px;
    margin-bottom: 16px;
  }
  & > .upper-interaction {
    margin-top: -16px;
    margin-bottom: 20px;
  }
`;

const SinusWorkspace = styled.section`
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 882px;
  height: 100%;
  pointer-events: none;
  transform: translate(-50%, 0);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  & .left {
    grid-area: 1 / 1 / 2 / 2;
  }
  & .center {
    grid-area: 2 / 2 / 3 / 3;
  }
  & .right {
    grid-area: 3 / 3 / 4 / 4;
    margin-left: 100px;
  }
`;

const NervesWorkspace = styled.section`
  position: absolute;
  top: 320px;
  left: calc(50%);
  transform: translateX(-50%);
  ${Center};
  width: 100%;
  max-width: 900px;
  height: 100%;
  pointer-events: none;

  section:first-child {
    margin-right: -116px;
  }
`;

export default TeethWorkspace;
