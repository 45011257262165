import * as React from 'react';

function SvgLossAllliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1841 2048" {...props}>
      <path d="M1658 638q34 0 57.5 23.5T1739 717v518q0 53-36.5 89.5T1613 1361H229q-52 0-89-37t-37-89V717q0-33 23-56t56-23q32 0 55 21t25 52q1 14 3 45 20 307 71 307h185q25 0 46.5-105.5T594 715q2-32 25.5-54.5T674 638q34 0 57 21.5t23 53.5q4 137 27 243.5t48 106.5h184q55 0 71-300 2-30 3-41l1-9q2-32 24.5-53.5T1166 638q34 0 57.5 22.5T1247 715q5 137 27 242.5t47 105.5h184q52 0 72-307 2-31 3-45 2-31 24.5-52t53.5-21zm39 597V717q0-15-11-25.5t-26-10.5q-16 0-26 9.5t-12 23.5q-1 17-3 44-3 61-7 104.5t-12.5 93-20 79.5-30.5 50.5-44 20.5h-184q-33 0-56.5-42.5t-35.5-111-17.5-124T1204 716q0-15-10.5-25t-26.5-10q-15 0-25 10t-12 25l-1 9q-1 6-1.5 19.5t-.5 20.5q-3 60-7 102.5t-12.5 91-20 78-30.5 49.5-44 20H829q-33 0-56.5-42.5t-35.5-111T719.5 828 712 715v-1q0-14-10.5-23.5T675 681q-15 0-26 10.5T636 717q-1 36-3.5 70t-7.5 82-13.5 87-20 74.5-30 55.5-40.5 20H336q-25 0-44-20.5t-30.5-50.5-20-79.5-12.5-93-7-104.5q-2-27-3-44-2-14-12-23.5t-24-9.5q-16 0-27 10.5T145 717v518q0 35 24.5 59.5T229 1319h1384q35 0 59.5-24.5t24.5-59.5z" />
    </svg>
  );
}

export default SvgLossAllliga;
