import * as React from 'react';

function SvgOpaqueliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1390 2048" {...props}>
      <path d="M1043 399q64 0 113.5 28.5t77 73 41.5 92 14 91.5q0 111-19.5 245t-54.5 261.5-93 212.5-126 85H395q-68 0-126-85t-93-212.5T121.5 929 102 684q0-48 12.5-92.5t39-84 73-63T335 421q95 0 166 34t155 99l191 152q13 9 24 9 18 0 30.5-12t12.5-30q0-20-14-32-87-71-156-126 115-116 299-116z" />
    </svg>
  );
}

export default SvgOpaqueliga;
