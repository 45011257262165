import React from 'react';
import {
  Center,
  OverlayBoxShadow,
} from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import { Color } from '../../../../../../redux/slices/data-structures/workspaceStructure';
import styled from 'styled-components';
import { SET_COLOR } from '../../../../../../redux/slices/UISlice';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';

const TeethToolbarDrawPalette: React.FC = () => {
  const currentColor = useAppSelector((state) => state.consultation.view.drawCurrentColor);
  const dispatch = useAppDispatch();
  return (
    <DrawPaletteRow>
      {Object.values(Color).map((color) => {
        const isSelected = currentColor === color;
        return (
          <ColorSwatch
            key={color}
            selected={isSelected}
            onMouseDown={(event) => event.preventDefault()}
            onClick={() => dispatch(SET_COLOR(color))}
            color={color}
          >
            {isSelected && <span>✓</span>}
          </ColorSwatch>
        );
      })}
    </DrawPaletteRow>
  );
};

const DrawPaletteRow = styled.div`
  position: absolute;
  top: -64px;
  left: -340px;
  display: flex;
  ${Center};
  ${OverlayBoxShadow};
  height: 52px;
  background-color: white;
  user-select: none;
  border-radius: 2rem;
  padding: 8px;
`;
const ColorSwatch = styled.div<{ selected?: boolean; color: string }>`
  cursor: pointer;
  background-color: ${(props) => props.color};
  box-sizing: border-box;
  transition: background-color 270ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  width: 32px;
  height: 32px;
  font-size: 9px;
  ${(props) =>
    props.selected &&
    `border: 2px solid ${props.theme.colors.selectedgrey};
     transition: background-color 270ms ease-in-out; 
     color: white; 
     font-size:16px;
     svg {
      fill: ${props.theme.colors.purple};
     }
     `};
  svg {
    width: 22px;
  }
  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export default TeethToolbarDrawPalette;
