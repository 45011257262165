import React, { Component } from 'react';
import styles from './ContactTile.module.scss';

class ContactTile extends Component {
  render() {
    return (
      <div className={styles.tile} onClick={this.props.onClick}>
        <div className={styles.icon}>
          <i>{this.props.icon}</i>
        </div>
        <div className={styles.title}>{this.props.children}</div>
      </div>
    );
  }
}

export default ContactTile;
