import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import styles from './HelpPage.module.scss';
import Header from '../../components/header/Header';

import VideoTile from '../../components/Tile/VideoTile';
import ContactTile from '../../components/Tile/ContactTile';
import { Section, SectionInner } from '../../components/Shared/Section';
import { LinkButton } from '../../components/Shared/Buttons';
import { Link } from 'react-router-dom';

import * as generalActions from '../../redux/actions/generalActions';
import DropdownInfo from '../../components/Shared/Dropdown/DropdownInfo';

import createPatient from '../../shared/images/help/createPatient.jpg';
import createMeasurement from '../../shared/images/help/createMeasurement.jpg';
import exportPatient from '../../shared/images/help/exportPatient.jpg';
import visualizeStability from '../../shared/images/help/visualizeStability.jpg';
import reviewStatistics from '../../shared/images/help/reviewStatistics.jpg';
import NewsConsultationTool from '../../shared/images/news/NewsTile_ConsultationTool_modified.jpg';

import OsstellKey from '../../shared/images/help/OsstellKey.jpg';
import IdxLabel from '../../shared/images/help/idx_label.png';
import BeaconLabel from '../../shared/images/help/beacon_label.png';
import MegaLabel from '../../shared/images/help/mega_label.png';
import BeaconConnectionSymbol from '../../shared/images/help/beaconConnectionSymbol.jpg';
import BeaconConnectionSymbolGIF from '../../shared/images/help/beaconConnectionSymbol.gif';
import MeasureBL from '../../shared/images/help/measureBL.png';
import MeasureMD from '../../shared/images/help/measureMD.png';
import MeasureModal1 from '../../shared/images/help/measureModal1.jpg';
import MeasureModal2 from '../../shared/images/help/measureModal2.jpg';

import Osstell_Beacon_set_up_and_registration_process from '../../shared/videos/Osstell_Beacon_set_up_and_registration_process.mp4';
import How_to_add_a_measurement from '../../shared/videos/How_to_add_a_measurement.mp4';
import How_to_create_a_patient from '../../shared/videos/How_to_create_a_patient.mp4';
import How_to_review_the_statistics_from_your_clinic from '../../shared/videos/How_to_review_the_statistics_from_your_clinic.mp4';
import How_to_visualize_stability_development from '../../shared/videos/How_to_visualize_stability_development.mp4';
import How_to_use_ConsultationTool from '../../shared/videos/How_to_use_ConsultationTool.mp4';

import Can_I_delete_a_measurement from '../../shared/videos/Can_I_delete_a_measurement.mp4';
import Can_I_delete_a_patient from '../../shared/videos/Can_I_delete_a_patient.mp4';
import Can_I_export_all_my_data from '../../shared/videos/Can_I_export_all_my_data.mp4';
import Can_I_manually_edit_implant_data from '../../shared/videos/Can_I_manually_edit_implant_data.mp4';
import First_measurement from '../../shared/videos/First_measurement.mp4';
import Follow_up_measurement from '../../shared/videos/Follow_up_measurement.mp4';
import How_to_edit_patient_data from '../../shared/videos/How_to_edit_patient_data.mp4';
import How_to_measure_on_abutment_Part_1 from '../../shared/videos/How_to_measure_on_abutment_Part_1.mp4';
import How_to_measure_on_abutment_Part_2 from '../../shared/videos/How_to_measure_on_abutment_Part_2.mp4';
import How_to_report_a_failure_Part_1 from '../../shared/videos/How_to_report_a_failure_Part_1.mp4';
import How_to_report_a_failure_Part_2 from '../../shared/videos/How_to_report_a_failure_Part_2.mp4';
import How_to_report_a_failure_Part_3 from '../../shared/videos/How_to_report_a_failure_Part_3.mp4';
import Video_how_to_export_to_PDF from '../../shared/videos/Video_how_to_export_to_PDF.mp4';
import ConsultationTool_Lower_Tool_Bar_right_side from '../../shared/videos/ConsultationTool_Lower_Tool_Bar_right_side.mp4';
import ConsultationTool_Lower_Bar_Teeth from '../../shared/videos/ConsultationTool_Lower_Bar_Teeth.mp4';
import ConsultationTool_Implant_ISQ_and_Restoration_Button from '../../shared/videos/ConsultationTool_Implant_ISQ_and_Restoration_Button.mp4';
import ConsultationTool_Left_Control_Button from '../../shared/videos/ConsultationTool_Left_Control_Button.mp4';
import ConsultationTool_Bone_Loss_and_Grafting_Button from '../../shared/videos/ConsultationTool_Bone_Loss_and_Grafting_Button.mp4';
import ConsultationTool_Soft_Tissue_Button from '../../shared/videos/ConsultationTool_Soft_Tissue_Button.mp4';
import ConsultationTool_Sequence_Viewfrom from '../../shared/videos/ConsultationTool_Sequence_View.mp4';
import ConsultationTool_Prepared_Tooth_And_Restoration from '../../shared/videos/ConsultationTool_Prepared_Tooth_And_Restoration.mp4';
import ConsultationTool_PDF_Export from '../../shared/videos/ConsultationTool_PDF_Export.mp4';
import StudyGroup_Invitation from '../../shared/videos/StudyGroup_Invitation.mp4';
import StudyGroup_Create from '../../shared/videos/StudyGroup_Create.mp4';
import beaconCode from '../../shared/videos/beaconCode.mp4';
import { appInsights } from '../../utils/applicationInsights';

class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.intl = props.intl;

    this.renderVideos = this.renderVideos.bind(this);
    this.renderFAQs = this.renderFAQs.bind(this);

    this.videos = [
      {
        title: this.intl.formatMessage({
          id: 'help.video2',
          defaultMessage: 'How to create a patient',
        }),
        url: How_to_create_a_patient,
        image: createPatient,
      },
      {
        title: this.intl.formatMessage({
          id: 'help.video7',
          defaultMessage: 'How to use consultation tool',
        }),
        url: How_to_use_ConsultationTool,
        image: NewsConsultationTool,
      },
      {
        title: this.intl.formatMessage({
          id: 'help.video3',
          defaultMessage: 'How to add a measurement',
        }),
        url: How_to_add_a_measurement,
        image: createMeasurement,
      },
      {
        title: this.intl.formatMessage({
          id: 'help.video4',
          defaultMessage: 'How to export your patient data as PDF',
        }),
        url: Video_how_to_export_to_PDF,
        image: exportPatient,
      },
      {
        title: this.intl.formatMessage({
          id: 'help.video5',
          defaultMessage: 'How to visualize implant stability development',
        }),
        url: How_to_visualize_stability_development,
        image: visualizeStability,
      },
      {
        title: this.intl.formatMessage({
          id: 'help.video6',
          defaultMessage: 'How to review your statistics',
        }),
        url: How_to_review_the_statistics_from_your_clinic,
        image: reviewStatistics,
      },
    ];
  }

  componentDidMount() {
    appInsights.trackEvent({
      name: 'PageVisit',
      properties: {
        page: 'help',
        email: this.props.user.email,
      },
    });
    //Load Hubspot form
    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '454089',
          formId: 'eeeae791-2817-4aae-bff3-e09a483653ba',
          target: '#hubspotForm',
        });
      }
    });
  }

  showVideo(link, header) {
    this.props.actions.showVideo(link, header);
  }

  renderVideos() {
    return this.videos.map((video, index) => {
      return (
        <VideoTile
          key={index}
          title={video.title}
          icon="play"
          background={video.image}
          onClick={this.showVideo.bind(this, video.url, video.title)}
        />
      );
    });
  }

  renderFAQs() {
    return (
      <div>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.howToRegisterMyInstrument?',
            defaultMessage: 'How to register my instrument?',
          })}
        >
          <FormattedMessage
            id="help.chargeYourBeacon"
            defaultMessage="Charge your Osstell Beacon for 3 hours. 
              Go to osstellconnect.com/register and follow these steps: 
              fill in the form and confirm your account. Install the 
              Beacon Gateway software and connect your Osstell key 
              to a USB port. Fill in your Osstell Beacon serial 
              number (6 digits on the back of your instrument). 
              Fill in your Osstell Beacon registration code by 
              lifting your Osstell Beacon and checking the 
              lower display on your instrument. 
              You can now name your instrument."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, Osstell_Beacon_set_up_and_registration_process)}
          />
          <br />
          <br />
          <strong>
            <FormattedMessage
              id="help.HowcanIfindmyregistrationcode?"
              defaultMessage="How can I find my registration code?"
            />
          </strong>
          <br />
          <br />
          <strong>Osstell Beacon</strong>
          <br />
          <FormattedMessage
            id="help.HowcanIfindmyregistrationcode?.text1"
            defaultMessage="To find your registration code, please:"
          />
          <ul>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text2"
                defaultMessage="Make sure that you have already installed the Beacon Gateway. If you have not installed it yet, please navigate to your clinic in your account, click the instruments tab and download the gateway. Install it."
              />
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text3"
                defaultMessage="Make sure that you have inserted the Osstell Key to your computer."
              />
            </li>
            <div className={styles.faqImageWrapper}>
              <img src={OsstellKey} alt="OsstellKey" />
            </div>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text4a"
                defaultMessage="Take your instrument in hand. It is now connecting to OsstellConnect and you should see the following symbol animating. Please note that it can take a few seconds."
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbolGIF} alt="Beacon Connection Symbol GIF" />
              </div>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text4b"
                defaultMessage="When connected, the symbol stops animating and displays as follows: "
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbol} alt="Beacon Connection Symbol" />
              </div>
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text4c"
                defaultMessage="The registration code is now displayed on the lower screen of your instrument."
              />
              <br />
              <LinkButton
                value={
                  <div>
                    <i>play</i>{' '}
                    {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
                  </div>
                }
                linkAction={this.showVideo.bind(this, beaconCode)}
              />
            </li>
          </ul>
          <div>
            <strong>Osstell IDx</strong>
          </div>
          <FormattedMessage
            id="help.HowcanIfindmyregistrationcode?.text5"
            defaultMessage="When turning on your Osstell IDx for the first time, you will be prompted to select language, time zone, date and time. After that, you will need to set up a 4-digit pin code and a backup password. The backup password is needed when restoring to a backup, so please note it down and save it in a safe place. Your registration code is now displayed on your Osstell IDx."
          />
          <br />
          <br />
          <div>
            <strong>MEGA ISQ II</strong>
          </div>
          <FormattedMessage
            id="help.HowcanIfindmyregistrationcode?.text6"
            defaultMessage="To find your registration code, please:"
          />
          <ul>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text7"
                defaultMessage="Make sure that you have already installed the Beacon Gateway. If you have not installed it yet, please navigate to your clinic in your account, click the instruments tab and download the gateway. Install it."
              />
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text8"
                defaultMessage="Make sure that you have inserted the Osstell Key to your computer"
              />
            </li>
            <div className={styles.faqImageWrapper}>
              <img src={OsstellKey} alt="OsstellKey" />
            </div>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text9a"
                defaultMessage="Take your instrument in hand. It is now connecting to OsstellConnect and you should see the following symbol animating. Please note that it can take a few seconds."
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbolGIF} alt="Beacon Connection Symbol GIF" />
              </div>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text9b"
                defaultMessage="When connected, the symbol stops animating and displays as follows: "
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbol} alt="Beacon Connection Symbol" />
              </div>
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIfindmyregistrationcode?.text9c"
                defaultMessage="The registration code is now displayed on the lower screen of your instrument."
              />
              <br />
              <LinkButton
                value={
                  <div>
                    <i>play</i>{' '}
                    {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
                  </div>
                }
                linkAction={this.showVideo.bind(this, beaconCode)}
              />
            </li>
          </ul>
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.HowcanIfindmySN?',
            defaultMessage: 'How can I find my SN?',
          })}
        >
          <div>
            <strong>Osstell Beacon</strong>
          </div>
          <FormattedMessage
            id="help.HowcanIfindmySN?.text1"
            defaultMessage="You can find the serial number on the back of your Osstell Beacon."
          />
          <div className={styles.faqImageWrapper}>
            <img src={BeaconLabel} alt="BeaconLabel" />
          </div>
          <div>
            <strong>Osstell IDx</strong>
          </div>
          <FormattedMessage
            id="help.HowcanIfindmySN?.text2"
            defaultMessage="You can find the serial number on the back of your Osstell IDx."
          />
          <div className={styles.faqImageWrapper}>
            <img src={IdxLabel} alt="IdxLabel" />
          </div>
          <div>
            <strong>MEGA ISQ II</strong>
          </div>
          <FormattedMessage
            id="help.HowcanIfindmySN?.text3"
            defaultMessage="You can find the serial number on the back of your MEGA ISQ II."
          />
          <div className={styles.faqImageWrapper}>
            <img src={MegaLabel} alt="MEGALabel" />
          </div>
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.HowcanIeditpatientdata?',
            defaultMessage: 'How can I edit patient data?',
          })}
        >
          <FormattedMessage
            id="help.HowcanIeditpatientdata?.text1"
            defaultMessage="Simply navigate to your patient records. Select the patient. Click 'edit' on the top right corner. Edit the data and save."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_edit_patient_data)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.CanImanuallyeditimplantdata?',
            defaultMessage: 'Can I manually edit implant data?',
          })}
        >
          <FormattedMessage
            id="help.CanImanuallyeditimplantdata?.text1"
            defaultMessage="Yes, simply navigate to your patient records. Select the patient. Select the implant you want to edit on the tooth chart. Select the tab 'Implant & abutment'. Click on the pen icon to edit. Edit implant data. Save."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, Can_I_manually_edit_implant_data)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.CanIexportallmydata?',
            defaultMessage: 'Can I export all my data?',
          })}
        >
          <FormattedMessage
            id="help.CanIexportallmydata?.text1"
            defaultMessage="Yes, you can export all your data to an Excel file for further analysis. Simply go to your patients records and click CSV export. The file will appear in your downloads.
            "
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, Can_I_export_all_my_data)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.CanIdeleteapatient?',
            defaultMessage: 'Can I delete a patient?',
          })}
        >
          <FormattedMessage
            id="help.CanIdeleteapatient?.text1"
            defaultMessage="Yes, simply navigate to your patient records. Select the patient you would like to delete. Click delete in the upper right corner. Confirm that you want to delete the patient and all data. Please note that it will be permanently removed from your account.
            "
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, Can_I_delete_a_patient)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.CanIdeleteameasurement?',
            defaultMessage: 'Can I delete a measurement?',
          })}
        >
          <FormattedMessage
            id="help.CanIdeleteameasurement?.text1"
            defaultMessage="Yes, simply navigate to your patient records. Select the patient. Select the implant. Go to the stability tab. Click on the edit icon by the measurement you would like to delete. Click delete. Please note that the measurement will be permanently removed from the patient chart.
            "
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, Can_I_delete_a_measurement)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.HowcanIupdatethesoftwareofmyOsstellIDx??',
            defaultMessage: 'How can I update the software of my Osstell IDx?',
          })}
        >
          <FormattedMessage
            id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text1"
            defaultMessage="Navigate to your clinic in your account. Select the instrument tab. Click on Download firmware."
          />
          <br />
          <FormattedMessage
            id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text2"
            defaultMessage="Download instructions:"
          />
          <ul>
            <li>
              <FormattedMessage
                id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text3"
                defaultMessage="Wait for the download to complete (could take a while depending on your connection)"
              />
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text4"
                defaultMessage="Copy the .m2f binary file to a USB memory stick"
              />
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text5"
                defaultMessage="Insert the memory stick in your IDx while it is running"
              />
            </li>
            <li>
              <FormattedMessage
                id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text6"
                defaultMessage="Follow instructions on the IDx display"
              />
            </li>
          </ul>
          <FormattedMessage
            id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text7"
            defaultMessage="Note: you cannot open or view the .m2f file"
          />
          <br />
          <br />
          <FormattedMessage
            id="help.HowcanIupdatethesoftwareofmyOsstellIDx?.text8"
            defaultMessage="You can also check this guide:"
          />
          <br />
          <a
            href="https://www.osstell.com/wp-content/uploads/2015/03/Software-Upgrade-6.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://www.osstell.com/wp-content/uploads/2015/03/Software-Upgrade-6.0.pdf
          </a>
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.ConnectBeaconRecordData?',
            defaultMessage:
              'How can I connect my Osstell Beacon to OsstellConnect and directly record data?',
          })}
        >
          <FormattedMessage
            id="help.ConnectBeaconRecordData?.text1"
            defaultMessage="Before starting, make sure that you have already installed the Beacon Gateway. If you have not installed it yet, please navigate to your clinic in your account, click the instruments tab and download the gateway. Install it."
          />
          <ul>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text2"
                defaultMessage="Plug in the Osstell Key to a USB port on your computer."
              />
            </li>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text3a"
                defaultMessage="Take your instrument in hand. It is now connecting to OsstellConnect and you should see the following symbol animating. Please note that it can take a few seconds."
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbolGIF} alt="Beacon Connection Symbol GIF" />
              </div>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text3b"
                defaultMessage="When connected, the symbol stops animating and displays as follows: "
              />
              <div className={styles.faqImageWrapper}>
                <img src={BeaconConnectionSymbol} alt="Beacon Connection Symbol" />
              </div>
            </li>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text4"
                defaultMessage="Go to your patient records."
              />
              <ul>
                <li>
                  <FormattedMessage
                    id="help.ConnectBeaconRecordData?.text5"
                    defaultMessage="If it is the first measurement, create a patient. Fill in patient data and save. Click New Treatment and fill in treatment information. Click Add implant, fill in implant data and save. Click measure stability. "
                  />
                  <br />
                  <LinkButton
                    value={
                      <div>
                        <i>play</i>{' '}
                        {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
                      </div>
                    }
                    linkAction={this.showVideo.bind(this, First_measurement)}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="help.ConnectBeaconRecordData?.text6"
                    defaultMessage="If it is a follow up measurement, select the patient and then the tooth on the teeth chart. Click measure stability. "
                  />
                </li>
                <br />
                <LinkButton
                  value={
                    <div>
                      <i>play</i>{' '}
                      {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
                    </div>
                  }
                  linkAction={this.showVideo.bind(this, Follow_up_measurement)}
                />
              </ul>
            </li>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text7"
                defaultMessage="Measure in buccal lingual direction:"
              />
              <div className={styles.faqImageWrapper}>
                <img src={MeasureBL} alt="MeasureBL" />
              </div>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text8"
                defaultMessage="The ISQ value is now showing in OsstellConnect:"
              />
            </li>
            <div className={styles.faqImageWrapper}>
              <img src={MeasureModal1} alt="MeasureModal 1" />
            </div>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text9"
                defaultMessage="Measure in mesial distal direction:"
              />
              <div className={styles.faqImageWrapper}>
                <img src={MeasureMD} alt="MeasureMD" />
              </div>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text10"
                defaultMessage="The ISQ value is now showing in OsstellConnect:"
              />
              <div className={styles.faqImageWrapper}>
                <img src={MeasureModal2} alt="MeasureModal 2" />
              </div>
            </li>
            <li>
              <FormattedMessage
                id="help.ConnectBeaconRecordData?.text11"
                defaultMessage="Fill in the information about the treatment and save."
              />
            </li>
          </ul>
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.UploadDataFromIDx?',
            defaultMessage: 'How can I upload data from my Osstell IDx to OsstellConnect?',
          })}
        >
          <FormattedMessage
            id="help.UploadDataFromIDx?.text1"
            defaultMessage="The data in your Osstell IDx is automatically uploaded to your OsstellConnect account, if it is connected to the internet. To connect your device to the internet, please follow the instructions here:"
          />
          <br />
          <a
            href="https://www.osstell.com/wp-content/uploads/2017/10/How-to-connect-to-the-Internet-6.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://www.osstell.com/wp-content/uploads/2017/10/How-to-connect-to-the-Internet-6.0.pdf
          </a>
          <br />
          <br />
          <FormattedMessage
            id="help.UploadDataFromIDx?.text2"
            defaultMessage="Please note that you can also create backups, for example on a USB stick. Please follow the instructions here:"
          />
          <br />
          <a
            href="https://www.osstell.com/wp-content/uploads/2015/03/Backup-Osstell-IDx-6.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://www.osstell.com/wp-content/uploads/2015/03/Backup-Osstell-IDx-6.0.pdf
          </a>
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.DocumentFailure?',
            defaultMessage: 'How do I document an implant failure?',
          })}
        >
          <FormattedMessage
            id="help.DocumentFailure?.text1"
            defaultMessage="To document an implant failure, go to your patients records. Select the patient, then select the tooth. Click 'Mark as failed'. Choose the type of failure (early or late failure), fill in the failure date. Choose the reason for failure. Click 'Mark as failed'."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_report_a_failure_Part_1)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.DocumentFailure?.text2"
            defaultMessage="You can access the history of the implant failure by navigating to the history tab. Click the tab 'Failed' to see all information related to the treatment and the failure."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_report_a_failure_Part_2)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.DocumentFailure?.text3"
            defaultMessage="A history symbol is displayed on the tooth with a failed implant. "
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_report_a_failure_Part_3)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.MeasureOnAbutment?',
            defaultMessage: 'How do I measure and record ISQ on an abutment?',
          })}
        >
          <FormattedMessage
            id="help.MeasureOnAbutment?.text1"
            defaultMessage="You can measure and record ISQ on an abutment."
          />
          <br />
          <br />
          <FormattedMessage
            id="help.MeasureOnAbutment?.text2"
            defaultMessage="First, navigate to your patients records, select the patient and the implant on the tooth chart. Click 'Add abutment', choose the abutment and save."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_measure_on_abutment_Part_1)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.MeasureOnAbutment?.text3"
            defaultMessage="Second, click 'Measure stability'. Select 'Abutment' under 'Measurement level'. Measure on the abutment, as you would on implant level. Save. To visualize stability, go to the stability tab and toggle on 'Show abutment' to see the details. You can also toggle on 'Show details' to see ISQ for both buccal lingual and mesial distal."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, How_to_measure_on_abutment_Part_2)}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.HowToChangeTeethNotation?',
            defaultMessage: 'How to change teeth notation?',
          })}
        >
          <FormattedMessage
            id="help.HowToChangeTeethNotation?.text1"
            defaultMessage="You can change the teeth notation used in the teeth charts under your profile page."
          />
          <br />
          <LinkButton
            value={
              <div>
                {this.intl.formatMessage({
                  id: 'help.HowToChangeTeethNotation?.text2',
                  defaultMessage: 'View Profile',
                })}
              </div>
            }
            link={'profile'}
          />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.HowToUseStudyGroups?',
            defaultMessage: 'How to use study groups?',
          })}
        >
          <FormattedMessage
            id="help.HowToUseStudyGroups?.text1"
            defaultMessage="How to create and use a study group."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, StudyGroup_Create)}
          />
          <br />
          <br />

          <FormattedMessage
            id="help.HowToUseStudyGroups?.text2"
            defaultMessage="How to accept a study group invitation."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, StudyGroup_Invitation)}
          />
          <br />
          <br />
        </DropdownInfo>
        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.UseConsultationTool?',
            defaultMessage: 'How to use the ConsultationTool?',
          })}
        >
          <FormattedMessage
            id="help.accessConsultationTool"
            defaultMessage="Access the ConsultationTool from the Patients page or from the top right corner in a patient record."
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text1"
            defaultMessage="How to use the lower tool bar."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Lower_Tool_Bar_right_side)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text2"
            defaultMessage="How to use the left tool bar."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(
              this,
              ConsultationTool_Implant_ISQ_and_Restoration_Button
            )}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text3"
            defaultMessage="How to show the loss of a tooth."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Lower_Bar_Teeth)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text4"
            defaultMessage="How to display implants and ISQ measurements."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Left_Control_Button)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text5"
            defaultMessage="How to display bone loss and bone grafting."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Bone_Loss_and_Grafting_Button)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text6"
            defaultMessage="How to display soft tissue loss and soft tissue grafting."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Soft_Tissue_Button)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text7"
            defaultMessage="How to use the sequence view."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Sequence_Viewfrom)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text8"
            defaultMessage="How to display a prepared and restorated tooth."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_Prepared_Tooth_And_Restoration)}
          />
          <br />
          <br />
          <FormattedMessage
            id="help.UseConsultationTool?.text9"
            defaultMessage="How to export a consultation as a PDF."
          />
          <br />
          <LinkButton
            value={
              <div>
                <i>play</i>{' '}
                {this.intl.formatMessage({ id: 'help.showMe', defaultMessage: 'Show me' })}
              </div>
            }
            linkAction={this.showVideo.bind(this, ConsultationTool_PDF_Export)}
          />
          <br />
          <br />
        </DropdownInfo>

        <DropdownInfo
          title={this.intl.formatMessage({
            id: 'help.OsstellConnectApp.text1',
            defaultMessage: 'How to use the OsstellConnect App?',
          })}
        >
          <div>
            <strong>
              <FormattedMessage
                id="help.OsstellConnectApp.text2"
                defaultMessage="How to download the app"
              />
            </strong>
          </div>
          <br />
          <div>
            <FormattedMessage
              id="help.OsstellConnectApp.text3"
              defaultMessage='The app is available for both IOS and Android tablets. You can find the app by searching "osstellconnect" in either Apple App Store or Google Play Store.'
            />

            <br />

            <ul>
              <li>
                {' '}
                <Link
                  to={{ pathname: 'https://apps.apple.com/us/app/osstellconnect/id1588042231' }}
                  target="_blank"
                >
                  Apple App Store
                </Link>
              </li>
              <li>
                {' '}
                <Link
                  to={{
                    pathname:
                      'https://play.google.com/store/apps/details?id=com.osstellconnectnative',
                  }}
                  target="_blank"
                >
                  Google Play Store
                </Link>
              </li>
            </ul>

            <div>
              <strong>
                <FormattedMessage
                  id="help.OsstellConnectApp.text4"
                  defaultMessage="If you registered your instrument on the app but want to use it on a computer later
              on"
                />
              </strong>
            </div>

            <p>
              <FormattedMessage
                id="help.OsstellConnectApp.text5"
                defaultMessage="To use your instrument on a computer you need to download the gateway. Here are the
                  steps to do so:"
              />
            </p>

            <ol>
              <li>
                <FormattedMessage
                  id="help.OsstellConnectApp.text6"
                  defaultMessage="Go to clinic page"
                />
              </li>
              <li>
                <FormattedMessage
                  id="help.OsstellConnectApp.text7"
                  defaultMessage='Click the "instrument tab"'
                />
              </li>
              <li>
                <FormattedMessage
                  id="help.OsstellConnectApp.text8"
                  defaultMessage='Under "Downloads" select the software for your operating system'
                />
              </li>
            </ol>

            <br />

            <div>
              <strong>
                <FormattedMessage
                  id="help.OsstellConnectApp.text9"
                  defaultMessage="How to scan an implant or abutment"
                />
              </strong>
            </div>
          </div>

          <p>
            <FormattedMessage
              id="help.OsstellConnectApp.text10"
              defaultMessage='When adding an implant or abutment to a treatment, click on "Populate by scanning
                  QR-code or barcode" and scan the QR-code/barcode of the product. Not all implants or
                  abutments are supported to be scanned.'
            />
          </p>
        </DropdownInfo>
      </div>
    );
  }

  render() {
    const headerButtonsLeft = [
      {
        label: this.intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
        icon: 'back',
        action: this.props.history.goBack,
      },
    ];
    return (
      <div className={styles.helpPage}>
        <Header
          title={this.intl.formatMessage({ id: 'help.Header', defaultMessage: 'HELP' })}
          leftButtons={headerButtonsLeft}
          isTogglerHidden
        />
        <div className={styles.header}>
          <div className={styles.headerText}>
            <FormattedMessage id="help.HowCanWeHelp" defaultMessage="How can we help" />
          </div>
        </div>
        <Section>
          <SectionInner className={styles.content}>
            <div className={styles.title}>
              <FormattedMessage id="help.videosGuides" defaultMessage="Video Guides" />
            </div>
            <div className={styles.tiles}>{this.renderVideos()}</div>
            <div className={`${styles.title} ${styles.faqTitle}`}>
              <FormattedMessage id="help.faq" defaultMessage="FAQ" />
            </div>
            <div>{this.renderFAQs()}</div>
            <div className={styles.title}>
              <FormattedMessage id="help.ContactUsBy" defaultMessage="Contact us by" />
            </div>
            <div className={styles.tiles}>
              <ContactTile
                icon="chat"
                onClick={() => {
                  if (window.HubSpotConversations) window.HubSpotConversations.widget.open();
                }}
              >
                <span>{this.intl.formatMessage({ id: 'help.Chat', defaultMessage: 'Chat' })}</span>
              </ContactTile>
              <ContactTile icon="phone">
                <span>+46 31-340 82 50</span>
                <span>8:00 - 16:30 (CET)</span>
              </ContactTile>
            </div>
            <div className={`${styles.title} ${styles.sendUsMessageTitle}`}>
              <FormattedMessage id="help.OrSendAMessage" defaultMessage="Or send us a message!" />
            </div>
            <div className={styles.hubspot} id="hubspotForm"></div>
          </SectionInner>
        </Section>
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.user,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...generalActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(HelpPage));
