import React from 'react';
import styles from './DropdownInfo.module.scss';
import Collapsible from 'react-collapsible'; //https://github.com/glennflanagan/react-collapsible

const DropdownInfo = (props) => (
  <div className={styles.collapsible}>
    <Collapsible transitionTime={200} trigger={<div className={styles.title}>{props.title}</div>}>
      <div className={styles.content}>{props.children}</div>
    </Collapsible>
  </div>
);

export default DropdownInfo;
