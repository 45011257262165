import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { useGetUserClinicsQuery } from '../../redux/api/clinic/clinicApi';
import { useHistory, useLocation } from 'react-router-dom';
import { logoutAccount, clearToken } from '../../redux/actions/accountActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import ClinicToggler from '../clinic-toggler/ClinicToggler';
import classNames from 'classnames';
import styles from './SideNav.module.scss';

const SideNav = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { clinicUpdate } = useAppSelector((state) => state.clinic);
  const { data: clinics, refetch } = useGetUserClinicsQuery();
  const { user } = useAppSelector((state) => state.user);
  const isAdmin =
    user.role === 'SuperAdmin' || user.role === 'AdminLv1' || user.role === 'AdminLv2';

  const [navExpanded, setNavExpanded] = useState(false);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setSelectedNavItem(location.pathname.substring(1));
    setIsDisabled(location.pathname.includes('/usergroup/'));
  }, [location]);

  useEffect(() => {
    if (clinics && clinics.length > 1) {
      refetch();
    }
  }, [clinicUpdate, refetch, clinics]);

  const navigate = (path: string) => {
    dismiss();
    history.push(`/${path}`);
  };

  const isNavItemActive = (navItem: string) =>
    classNames(styles.navItem, navItem === selectedNavItem ? styles.navItemActive : null);

  const expand = () => setNavExpanded(true);

  const dismiss = () => setNavExpanded(false);

  const logOut = () => {
    dispatch(logoutAccount());
    dispatch(clearToken(false, false));
    history.push('/');
  };

  return (
    <nav id="sideNav">
      <div className={styles.button} onClick={expand}>
        <i className={styles.navItemIcon}>menu</i>
      </div>
      <div
        className={classNames(styles.overlay, navExpanded ? styles.expanded : null)}
        onClick={dismiss}
      />
      <div className={classNames(styles.nav, navExpanded ? styles.expanded : null)}>
        <div>
          <div className={styles.navItem} onClick={dismiss}>
            <i className={styles.navItemIcon}>close</i>
            <span className={styles.title}></span>
          </div>
          <div className={styles.toggler}>
            {clinics && clinics.length > 1 && (
              <>
                <ClinicToggler clinics={clinics} isDisabled={isDisabled} />
                <div className={styles.divider}></div>
              </>
            )}
          </div>
          <div className={isNavItemActive('home')} onClick={() => navigate('home')}>
            <i className={styles.navItemIcon}>dashboard</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.home', defaultMessage: 'Home' })}
            </span>
          </div>
          <div className={isNavItemActive('clinic')} onClick={() => navigate('clinic')}>
            <i className={styles.navItemIcon}>clinic</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.clinic', defaultMessage: 'Clinic' })}
            </span>
          </div>
          <div className={isNavItemActive('patients')} onClick={() => navigate('patients')}>
            <i className={styles.navItemIcon}>patient_records</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.patients', defaultMessage: 'Patients' })}
            </span>
          </div>
          <div className={isNavItemActive('studies')} onClick={() => navigate('studies')}>
            <i className={styles.navItemIcon}>knowledge_base</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.studies', defaultMessage: 'Studies' })}
            </span>
          </div>
          <div className={styles.divider}></div>
          <div className={isNavItemActive('community')} onClick={() => navigate('community')}>
            <i className={styles.navItemIcon}>insights</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.community', defaultMessage: 'Community' })}
            </span>
          </div>
          {isAdmin ? (
            <div className={isNavItemActive('admin')} onClick={() => navigate('admin')}>
              <i className={styles.navItemIcon}>settings</i>
              <span className={styles.title}>
                {intl.formatMessage({ id: 'menu.admin', defaultMessage: 'Admin' })}
              </span>
            </div>
          ) : null}
        </div>
        <div>
          <div className={isNavItemActive('profile')} onClick={() => navigate('profile')}>
            <i className={styles.navItemIcon}>account</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.profile', defaultMessage: 'Profile' })}
            </span>
          </div>
          <div className={isNavItemActive('help')} onClick={() => navigate('help')}>
            <i className={styles.navItemIcon}>help</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.help', defaultMessage: 'Help' })}
            </span>
          </div>
          <div className={isNavItemActive('about')} onClick={() => navigate('about')}>
            <i className={styles.navItemIcon}>info</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.about', defaultMessage: 'About' })}
            </span>
          </div>
          <div className={styles.navItem} onClick={logOut}>
            <i className={styles.navItemIcon}>sign_out</i>
            <span className={styles.title}>
              {intl.formatMessage({ id: 'menu.signout', defaultMessage: 'Sign out' })}
            </span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default SideNav;
