import * as React from 'react';

function SvgToothliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1517 2048" {...props}>
      <path d="M1107 142q96 0 168.5 53T1382 326t34 165q0 40-4.5 120T1395 810.5t-29 245-46 252-64 225.5-86 161-110 62q-32 0-58.5-12t-44.5-28.5-33.5-48-24.5-56-18-69-12.5-69.5-10.5-73q-4-29-6.5-47.5t-7-44.5-8.5-42.5-10-36.5-12.5-32-14.5-23-18-16-22-5q-23 0-39 17t-27.5 60-16.5 70.5-14 93.5q-1 4-1 6-7 48-10.5 73t-12.5 69.5-18 69-24.5 56-33.5 48-44.5 28.5-58.5 12q-68 0-125.5-82T239 1462t-65.5-278-43-295.5-21.5-248-7-149.5q0-85 30.5-157T232 213.5 399 165q108 0 189 38t171 108l227 184q6 6 6 15t-6 15-15 6q-3 0-11-5-182-146-227-181-85-66-160.5-102T399 207q-67 0-118 25.5t-79.5 68T159 391t-14 100q0 93 11.5 237t37.5 316.5 61 321 89 248.5 115 100q21 0 39-6.5t32-22 24.5-30.5 19.5-43 15-47 12.5-55.5 9-55.5 8.5-60q5-36 7.5-54.5t8-50.5 11-49.5 13-41.5 17-37 21.5-26.5 27.5-19 34.5-5.5q25 0 44 10t33 32.5 23.5 44 17.5 60 12.5 64.5 10.5 73q6 41 8.5 60t9 55.5T930 1565t15 47 19.5 43 24.5 30.5 32 22 39 6.5q61 0 115-100t89-248.5 61-321 37.5-316.5 11.5-237q0-37-9-77t-29.5-81.5-50.5-74-76-53-102-20.5q-110 0-195 51-18 11-29-7t7-29q96-58 217-58zM471 389q-68-34-112-13-41 21-53 85-26 134 20 414 2 9-3 16.5t-14 8.5h-4q-17 0-21-18-47-288-20-429 16-86 76-115 65-32 151 13 7 4 10 13t-1 16q-4 8-12.5 10.5T471 389zM320 966q9-1 16 4t8 14l26 146-42 8q-24-130-26-148-3-20 18-24z" />
    </svg>
  );
}

export default SvgToothliga;
