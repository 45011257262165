import React from 'react';
import styles from './Panel.module.scss';
import cn from 'classnames';

const Panel = (props) => {
  const className = props.overrideClassName
    ? props.className
    : cn(
        props.className,
        styles.panel,
        props.filled ? styles.panelColor : null,
        props.full === true ? styles.full : null,
        props.square ? styles.square : null,
        props.mobileColumn ? styles.mobileColumn : null
      );
  return (
    <div className={className} style={props.style} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

export default Panel;
