import React from 'react';
import styles from './Headings.module.scss';
import cn from 'classnames';

const H1 = (props) => (
  <h1 className={cn(styles.h1, props.className)} style={props.style}>
    {props.children}
  </h1>
);

export default H1;
