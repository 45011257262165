import { ChangeEvent, FC } from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';

interface Props {
  id: string;
  type?: string;
  label: string;
  value?: string;
  fullWidth?: boolean;
  onValueChange: (inputValue: string) => void;
}

const InputField: FC<Props> = ({ id, label, type, fullWidth, value, onValueChange }) => {
  const valueChangeHandler = (e: ChangeEvent<HTMLInputElement>) => onValueChange(e.target.value);

  return (
    <FormControl fullWidth={fullWidth}>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <TextField id={id} type={type} value={value} onChange={valueChangeHandler} />
    </FormControl>
  );
};

export default InputField;
