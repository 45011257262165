import { useIntl } from 'react-intl';
import { useEffect, useState, useRef } from 'react';
import styles from './Dropdown.module.scss';
import cn from 'classnames';

const Dropdown = ({
  align,
  options,
  filterServer,
  footer,
  dataCy,
  actionData,
  noTranslate,
  fullWidth,
  searchBox,
  searchSelect,
  className,
  children,
  disabled,
  onChange,
}) => {
  const intl = useIntl();
  const optionRef = useRef(null);
  const optionsRef = useRef(null);
  const optionWrapperRef = useRef(null);

  const [isToggled, setIsToggled] = useState(filterServer ? true : false);
  const [searchText, setSearchText] = useState('');
  const [optionsList, setOptionsList] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (filterServer && options.length > 0) {
      setIsToggled(true);
    }
  }, [filterServer, options]);

  useEffect(() => {
    setOptionsList(options);
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const optionHeight = optionRef?.current?.offsetHeight || 1;
    const optionsHeight = optionHeight * options.length;
    optionsRef.current.style.height = optionsHeight + 'px';
  }, [optionsList]);

  const onSearchChange = (event) => {
    event.persist();
    const string = event.target.value;
    const filteredOptions = options.filter(
      (d) => d.label.toLowerCase().indexOf(string.toLowerCase()) !== -1
    );
    setFilteredOptions(filteredOptions);
    setSearchText(string);
  };

  const toogle = (e) => {
    if (disabled) return;
    e.stopPropagation();

    if (filterServer) return setIsToggled(true);
    setIsToggled((prevState) => !prevState);
    setTimeout(() => optionWrapperRef.current.focus(), 200);
  };

  const inputFocus = () => setIsToggled(true);

  const collapse = () => {
    setIsToggled(false);
    setSearchText('');
  };

  const doAction = (option, evt) => {
    const { action, disabled, actionData: actionDataOption } = option;
    if (!isToggled && !filterServer) return;
    if (disabled === true) return;
    evt.persist();
    evt.preventDefault();
    evt.stopPropagation();
    onChange && onChange(option);
    action && action(actionDataOption ? actionDataOption : actionData);
    collapse();
  };

  const renderOptions = () => {
    return filteredOptions.map((option, index) => {
      if (option.badgeNumber) {
        const optionStyle = option.disabled === true ? styles.disabledOption : styles.option;

        return (
          <div
            key={index}
            ref={optionRef}
            className={cn(optionStyle, styles.notificationPadding)}
            onMouseDown={(event) => doAction(option, event)}
          >
            <span className={styles.notificationCounter}>{option.badgeNumber}</span>
            {option.label}
          </div>
        );
      }

      return (
        <div
          key={index}
          ref={optionRef}
          className={option.disabled === true ? styles.disabledOption : styles.option}
          onMouseDown={(event) => doAction(option, event)}
        >
          {option.label && !noTranslate ? intl.formatMessage({ id: option.label }) : option.label}
        </div>
      );
    });
  };

  return (
    <div className={cn(styles.dropdown, className, isToggled ? styles.active : null)}>
      <div
        data-cy={dataCy && dataCy}
        className={cn(styles.dropdownToggle, isToggled ? styles.active : null)}
        tabIndex="0"
        onClick={toogle}
      >
        {children}
      </div>
      <div
        ref={optionWrapperRef}
        className={cn(
          styles.options,
          isToggled ? styles.active : null,
          fullWidth ? styles.fullWidth : null,
          searchSelect ? styles.searchSelect : null,
          searchBox ? styles.searchBox : null,
          align === 'left' ? styles.alignLeft : null,
          align === 'right' ? styles.alignRight : null
        )}
        tabIndex="0"
        onBlur={collapse}
      >
        {searchBox && (
          <div className={cn(null)}>
            <input
              type="text"
              placeholder="Search"
              onChange={onSearchChange}
              onFocus={inputFocus}
              value={searchText}
            />
          </div>
        )}
        <div className={styles.inner} ref={optionsRef} style={{}}>
          {renderOptions()}
        </div>
        {footer && (
          <div onClick={collapse} className={cn(styles.footer)}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
