import { Component } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import styles from './StudyGroupRow.module.scss';

import { Row } from '../../../components/Shared/Section';
import { Table, TableHead, TableBody, TH, TR, TD } from '../../../components/Shared/Table';

class StudyGroupRow extends Component {
  constructor(props) {
    super(props);
    this.state = { isToggled: false };
    this.expandGroup = this.expandGroup.bind(this);
  }

  expandGroup(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState((prevState) => ({
      isToggled: !prevState.isToggled,
    }));
  }

  UNSAFE_componentWillReceiveProps() {}

  renderMembers() {
    return this.props.studyGroup.members.map((member, index) => {
      const country = this.props.countries.find((c) => c.key === member.country);
      return (
        <TR key={index}>
          <TD>
            {member.firstName} {member.surname}
          </TD>
          <TD>{member.email}</TD>
          <TD>{country.value}</TD>
          <TD>{member.externalReference}</TD>
          <TD>{member.numberOfImplants}</TD>
        </TR>
      );
    });
  }

  render() {
    return (
      <div className={cn(styles.studyGroupRow, this.state.isToggled ? styles.active : null)}>
        <div className={styles.header} onClick={this.expandGroup}>
          <div className={styles.heading}>
            <h3 className={styles.heading}>
              <span className={styles.name}>{this.props.studyGroup.name}</span>
              <span className={styles.description}>{this.props.studyGroup.description}</span>
            </h3>
            <span className={styles.info}>
              Members: {this.props.studyGroup.members.length}, Implants:{' '}
              {this.props.studyGroup.numberOfImplants}, ID: {this.props.studyGroup.id}
            </span>
          </div>
          <div className={styles.moreButton} onClick={this.handleClick}>
            <i className="osstell-icons">more</i>
          </div>
        </div>
        {this.state.isToggled && (
          <Row>
            <Table>
              <TableHead>
                <TR>
                  <TH>Name</TH>
                  <TH>Email</TH>
                  <TH>Country</TH>
                  <TH>External Referece</TH>
                  <TH>Implants</TH>
                </TR>
              </TableHead>
              <TableBody>{this.renderMembers()}</TableBody>
            </Table>
          </Row>
        )}
      </div>
    );
  }
}

function mapState(state) {
  return {
    countries: state.referenceData.countries,
  };
}

export default connect(mapState)(StudyGroupRow);
