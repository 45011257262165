import * as types from '../../constants/actionTypes';

const initialState = {
  video: {
    show: false,
    url: undefined,
    header: undefined,
  },
  freeSmartPegs: {
    show: false,
    promotion: undefined,
  },
  alert: {
    show: false,
    header: undefined,
    content: undefined,
  },
  confirmation: {
    show: false,
    header: undefined,
    content: undefined,
    action: undefined,
  },
};

export default function firmware(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_VIDEO:
      return Object.assign({}, state, {
        video: { show: true, url: action.videoUrl, header: action.header },
      });
    case types.HIDE_VIDEO:
      return Object.assign({}, state, {
        video: { show: false, url: undefined, header: undefined },
      });
    case types.SHOW_FREE_SMART_PEGS:
      return Object.assign({}, state, {
        freeSmartPegs: { show: true, promotion: action.promotion },
      });
    case types.HIDE_FREE_SMART_PEGS:
      return Object.assign({}, state, { freeSmartPegs: { show: false, promotion: undefined } });
    case types.SHOW_ALERT:
      return Object.assign({}, state, {
        alert: {
          show: true,
          header: action.header,
          content: action.content,
          action: action.action,
        },
      });
    case types.HIDE_ALERT:
      return Object.assign({}, state, {
        alert: { show: false, header: undefined, content: undefined },
      });
    case types.SHOW_CONFIRMATION:
      return Object.assign({}, state, {
        confirmation: {
          show: true,
          header: action.header,
          content: action.content,
          action: action.action,
        },
      });
    case types.HIDE_CONFIRMATION:
      return Object.assign({}, state, {
        confirmation: { show: false, header: undefined, content: undefined, action: undefined },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
