import * as React from 'react';

function SvgSvgFullscreenExit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.78 26.24" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <rect
            x={0.5}
            y={0.5}
            width={31.78}
            height={25.24}
            rx={1.8}
            strokeDasharray="8.07 2.01 .01 0 0 0"
            fill="none"
            strokeMiterlimit={10}
          />
          <rect
            x={0.5}
            y={13.89}
            width={14.91}
            height={11.84}
            rx={1.8}
            fill="none"
            strokeMiterlimit={10}
          />
          <path strokeLinecap="round" fill="none" strokeMiterlimit={10} d="M20.91 9.91l7.07-5" />
          <path d="M19.49 7.2L23 12.15l-4.23-.72.72-4.23z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgFullscreenExit;
