import { FC } from 'react';
import styled from 'styled-components';
import ProcedureDetailHeader from './ProcedureDetailHeader';
import ProcedureDetailPanel from './ProcedureDetailPanel';

const ProcedureDetail: FC = () => {
  return (
    <ProcedureDetailCard>
      <ProcedureDetailHeader />
      <ProcedureDetailPanel />
    </ProcedureDetailCard>
  );
};

const ProcedureDetailCard = styled.div`
  width: 100%;
  min-height: 350px;
  height: 50%;
  background-color: white;
  padding-bottom: 1rem;
`;

export default ProcedureDetail;
