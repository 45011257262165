import {
  bezier,
  SvgPathPartial,
  transformXYToPoint,
} from '../../../../../../common/graphics/lib/SvgPath';
import React, { useMemo } from 'react';

interface Props {
  positions: any;
  onClick?: () => void;
  maskElement?: any;
  rightSide?: boolean;
  enabled?: boolean;
}

const SvgTeethSinusShape: React.FC<Props> = ({
  positions,
  onClick,
  maskElement,
  rightSide,
  enabled,
}) => {
  const allNodes = useMemo(
    () =>
      positions.length > 0
        ? [...positions.map(transformXYToPoint), [positions[0]?.x, positions[0]?.y]]
        : [],
    [positions]
  );

  const path = useMemo(
    () => (
      <path
        d={`${SvgPathPartial({
          points: allNodes,
          command: bezier(),
          moveToStart: true,
        })}`}
        fill={`url(#radial-gradient)`}
        transform="translate(20, 15)"
        onClick={() => onClick?.()}
        style={{ pointerEvents: enabled ? 'all' : 'none' }}
      />
    ),
    [allNodes, onClick]
  );

  return (
    allNodes && (
      <svg width={400} height={200}>
        {maskElement && (
          <svg x={0} y={0} width={'100%'} height={'100%'}>
            <clipPath id={`pathMask_${rightSide ? 'right' : 'left'}`}>{path}</clipPath>
            <svg clipPath={`url(#pathMask_${rightSide ? 'right' : 'left'})`} opacity={0.6}>
              {maskElement}
            </svg>
          </svg>
        )}
        <radialGradient
          id="radial-gradient"
          cx="0.5"
          cy="0.629"
          r="0.663"
          gradientTransform="matrix(-0.987, 0.162, 0.255, 1.555, 0.833, -0.43)"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#d0caca" />
          <stop offset="1" stopColor="#4b4b4b" />
        </radialGradient>
        <g opacity={0.7}>{path}</g>
      </svg>
    )
  );
};
export default SvgTeethSinusShape;
