import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { Box, Container } from '@mui/material';
import { errorToast } from '../../utils/errorToast';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAcceptInviteMutation } from '../../redux/api/manage-invite/manageInviteApi';
import CreateProfilePanel from './CreateProfilePanel';
import Spinner from '../../components/Shared/Loaders/Spinner';

const ManageInvitePage = () => {
  const intl = useIntl();
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const inviteId = query.get('inviteid');
  const userGroupId = query.get('usergroupid');

  const [acceptInvite, { data, isLoading: isAccepting }] = useAcceptInviteMutation();

  const acceptInvteHandler = useCallback(async () => {
    if (userGroupId && inviteId) {
      const res = await acceptInvite({ userGroupId, inviteId });
      if ('error' in res) {
        history.push('/login');
        errorToast(intl, 'InvalidInvite');
      }
      return;
    }
    history.push('/login');
  }, [userGroupId, inviteId, acceptInvite, history, intl]);

  useEffect(() => {
    acceptInvteHandler();
  }, [acceptInvteHandler]);

  useEffect(() => {
    if (data && data.userExists) {
      history.push('/login');
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'users.acceptedInviteSuccess',
          defaultMessage: 'The invite was accepted successfully',
        }),
        { showCloseButton: false }
      );
    }
  }, [data, intl, history]);

  return (
    <Container maxWidth="lg">
      {isAccepting ? (
        <Box mt={3}>
          <Spinner purple />
        </Box>
      ) : (
        <>{data && !data.userExists && <CreateProfilePanel email={data.email} />}</>
      )}
    </Container>
  );
};

export default ManageInvitePage;
