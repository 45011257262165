import React, { Component } from 'react';
import SectionInner from './SectionInner';
import Section from './Section';
import sectionStyles from './Section.module.scss';
import cn from 'classnames';

class SectionTabContent extends Component {
  render() {
    return (
      <div>
        {!this.props.noWrappers && (
          <Section
            className={cn(
              this.props.className,
              this.props.fullWidth ? sectionStyles.fullWidth : null
            )}
          >
            <SectionInner className={this.props.fullWidth ? sectionStyles.fullWidth : null}>
              {this.props.children}
            </SectionInner>
          </Section>
        )}
        {this.props.noWrappers && this.props.children}
      </div>
    );
  }
}

export default SectionTabContent;
