import { Button } from '../Shared/Buttons';
import { H3, H2 } from '../Shared/Headings';
import { useState, useEffect, FC } from 'react';
import { selectPatientImplant } from '../../redux/actions/patientActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { implantDataState, abutmentDataState } from '../../constants/defaultStates';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import {
  IAbutmentData,
  IHistoryImplant,
  IImplant,
  IImplantData,
  IImplantFailureTypes,
} from '../../types/implant';
import ISQListItem from './ISQListItem';
import DataItem from '../Shared/DataItem/DataItem';
import styles from './ImplantPanel.module.scss';
import cn from 'classnames';

interface Props {
  implant: IImplant;
}

const ImplantHistory: FC<Props> = ({ implant }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [activeImplant, setActiveImplant] = useState<IImplant | IHistoryImplant>(implant);

  const {
    implantData,
    abutmentData,
    loadingProtocols,
    studyImplantData,
    surgicalProtocols,
    studyAbutmentData,
    prePlacementProcedures,
    plannedProstheticSolutions,
    implantSitePlacementOptions,
    implantFailureTypes: failTypes,
    implantReasonForFailure: failReasons,
  } = useAppSelector((state) => state.referenceData);

  useEffect(() => {
    if (implant !== activeImplant) {
      setActiveImplant(implant);
    }
  }, [implant]);

  const getImplantData = (implantData: IImplantData[], studyImplantData: IImplantData[]) => {
    let specificImplantData = implantData.find((data) => data.dataId === activeImplant.dataId);

    if (!specificImplantData) {
      specificImplantData = studyImplantData.find((data) => data.dataId === activeImplant.dataId);
    }

    if (!specificImplantData) {
      specificImplantData = implantDataState;
    }

    return specificImplantData;
  };
  const getAbutmentData = (abutmentData: IAbutmentData[], studyAbutmentData: IAbutmentData[]) => {
    let specificAbutmentData = abutmentData.find(
      (data) => data.dataId === activeImplant.abutmentId
    );

    if (!specificAbutmentData) {
      specificAbutmentData = studyAbutmentData.find(
        (data) => data.dataId === activeImplant.abutmentId
      );
    }

    if (!specificAbutmentData) {
      specificAbutmentData = abutmentDataState;
    }

    return specificAbutmentData;
  };

  const getValueFromKey = (array: IImplantFailureTypes[], key: string) => {
    const foundItem = array.find((item) => {
      return item.key === key;
    });
    if (!foundItem) return '-';
    return intl.formatMessage({
      id: ''.concat('referenceData.', foundItem.key),
      defaultMessage: foundItem.value,
    });
  };

  const viewImplant = (implant: IImplant | IHistoryImplant) => setActiveImplant(implant);

  const renderButtonText = (label: string, date?: string) => {
    return (
      <>
        <span className={styles.historyButtonText}>{label}</span>
        {date && (
          <span className={styles.historyButtonDate}>
            <FormattedDate value={date} year="numeric" month="short" day="numeric" />
          </span>
        )}
      </>
    );
  };

  const renderHistoryButtons = (implants: IHistoryImplant[]) => {
    if (implants) {
      return implants.map((implant) => {
        const buttonLabel = implant.status;
        return (
          <Button
            key={implant.lastModified}
            value={renderButtonText(buttonLabel, implant.failureDate)}
            leftIcon="close"
            onClick={() => viewImplant(implant)}
            className={cn(
              styles.historyImplant,
              activeImplant === implant ? styles.activeImplant : null
            )}
            noShadow
            small
            leftAlign
            secondary
          />
        );
      });
    }
  };

  const modifiedImplantData = getImplantData(implantData, studyImplantData);
  const modifiedAbutmentData = getAbutmentData(abutmentData, studyAbutmentData);
  const currentImplant = implant;
  const failedImplants = implant.historyImplants;

  return (
    <div className={styles.overviewContainer}>
      <button onClick={() => dispatch(selectPatientImplant(-1))} className={styles.closeButton}>
        <i className={styles.closeIcon}>close</i>
      </button>
      <H2 className={styles.tabHeading}>
        <FormattedMessage id="patient.history" defaultMessage="History" />
      </H2>
      <div className={cn(styles.overviewWrapper)}>
        <div className={cn(styles.dataCol, styles.historyCol)}>
          <H3>
            <FormattedMessage id="patient.history" defaultMessage="History" />
          </H3>
          <Button
            value={renderButtonText(
              intl.formatMessage({
                id: 'patient.currentState',
                defaultMessage: 'Current state',
              })
            )}
            leftIcon="implant"
            onClick={() => viewImplant(currentImplant)}
            className={cn(
              styles.currentImplant,
              styles.historyImplant,
              activeImplant === currentImplant ? styles.activeImplant : null
            )}
            noShadow
            small
            leftAlign
            secondary
          />
          {renderHistoryButtons(failedImplants)}
        </div>
        <div className={cn(styles.dataCol, styles.historyContent)}>
          {activeImplant && (
            <>
              {activeImplant === currentImplant && (
                <H3>
                  <FormattedMessage id="patient.currentImplant" defaultMessage="Current implant" />
                </H3>
              )}
              {'failureType' in activeImplant && activeImplant !== currentImplant && (
                <H3>
                  <FormattedMessage id="patient.implantFailed" defaultMessage="Implant failed" />{' '}
                  <FormattedDate
                    value={activeImplant.failureDate}
                    year="numeric"
                    month="short"
                    day="numeric"
                  />
                </H3>
              )}
              {'failureType' in activeImplant && activeImplant.failed && (
                <div className={styles.historySection}>
                  <div className={cn(styles.dataRow)}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.typeOfFailure',
                        defaultMessage: 'Type of failure',
                      })}
                      value={getValueFromKey(failTypes, activeImplant.failureType)}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.reasonForFailure',
                        defaultMessage: 'Reason for failure',
                      })}
                      value={getValueFromKey(failReasons, activeImplant.reasonForFailure)}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.failureDate',
                        defaultMessage: 'Failure date',
                      })}
                      value={activeImplant.failureDate}
                      date={activeImplant.failureDate}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                </div>
              )}
              <div className={styles.historySection}>
                <H3>
                  <FormattedMessage id="patient.treatment" defaultMessage="Treatment" />
                </H3>
                <div className={cn(styles.dataRow)}>
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.additionalSurgicalProcedures',
                      defaultMessage: 'Additional surgical procedure',
                    })}
                    value={getValueFromKey(
                      prePlacementProcedures,
                      activeImplant.prePlacementProcedure
                    )}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.loadingProtocol',
                      defaultMessage: 'Loading protocol',
                    })}
                    value={getValueFromKey(loadingProtocols, activeImplant.loadingProtocol)}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.surgicalProtocol',
                      defaultMessage: 'Surgical protocol',
                    })}
                    value={getValueFromKey(surgicalProtocols, activeImplant.surgicalProtocol)}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                </div>
                <div className={cn(styles.dataRow)}>
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.plannedProstheticSolution',
                      defaultMessage: 'Planned prosthetic solution',
                    })}
                    value={getValueFromKey(
                      plannedProstheticSolutions,
                      activeImplant.plannedProstheticSolution
                    )}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.implantSitePlacement',
                      defaultMessage: 'Implant site placement',
                    })}
                    value={getValueFromKey(
                      implantSitePlacementOptions,
                      activeImplant.implantSitePlacement
                    )}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.boneQuality',
                      defaultMessage: 'Bone quality',
                    })}
                    value={activeImplant.boneQuality}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                </div>
                <div className={cn(styles.dataRow)}>
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.maximumTorque',
                      defaultMessage: 'Maximum Insertion Torque (Ncm)',
                    })}
                    value={activeImplant.insertionTorque > 0 ? activeImplant.insertionTorque : ''}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.placement',
                      defaultMessage: 'Placement',
                    })}
                    value={activeImplant.placementDate}
                    date={activeImplant.placementDate}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                  <DataItem
                    label={intl.formatMessage({
                      id: 'patient.readyForFinalRestoration',
                      defaultMessage: 'Ready for final restoration',
                    })}
                    value={activeImplant.loadingDate}
                    date={activeImplant.loadingDate}
                    className={cn(styles.dataItem, styles.equal)}
                  />
                </div>
              </div>
              {modifiedImplantData && activeImplant.dataId > 0 && (
                <div className={styles.historySection}>
                  <H3>
                    <FormattedMessage id="patient.implant" defaultMessage="Implant" />
                  </H3>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.brand',
                        defaultMessage: 'Brand',
                      })}
                      value={modifiedImplantData.brandName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.model',
                        defaultMessage: 'Model',
                      })}
                      value={modifiedImplantData.modelName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.artNumber',
                        defaultMessage: 'Article number',
                      })}
                      value={modifiedImplantData.partNumber}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.platform',
                        defaultMessage: 'Platform',
                      })}
                      value={modifiedImplantData.platformName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.batchLotNumber',
                        defaultMessage: 'Batch/Lot number',
                      })}
                      value={activeImplant.implantLot}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.diameter',
                        defaultMessage: 'Diameter',
                      })}
                      value={modifiedImplantData.diameter}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.lenth',
                        defaultMessage: 'Length (mm)',
                      })}
                      value={modifiedImplantData.length}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.suitableSmartPeg',
                        defaultMessage: 'Suitable SmartPeg',
                      })}
                      value={modifiedImplantData.smartPeg}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                </div>
              )}
              {activeImplant.abutmentId > 0 && (
                <div className={styles.historySection}>
                  <H3>
                    <FormattedMessage id="patient.abutment" defaultMessage="Abutment" />
                  </H3>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.brand',
                        defaultMessage: 'Brand',
                      })}
                      value={modifiedAbutmentData.brandName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.model',
                        defaultMessage: 'Model',
                      })}
                      value={modifiedAbutmentData.modelName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.artNumber',
                        defaultMessage: 'Article number',
                      })}
                      value={modifiedAbutmentData.partNumber}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.platform',
                        defaultMessage: 'Platform',
                      })}
                      value={modifiedAbutmentData.platformName}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.batchLotNumber',
                        defaultMessage: 'Batch/Lot number',
                      })}
                      value={activeImplant.abutmentLot}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                  <div className={styles.dataRow}>
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.diameter',
                        defaultMessage: 'Diameter',
                      })}
                      value={modifiedAbutmentData.diameter}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.lenth',
                        defaultMessage: 'Length (mm)',
                      })}
                      value={modifiedAbutmentData.length}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                    <DataItem
                      label={intl.formatMessage({
                        id: 'implant.suitableSmartPeg',
                        defaultMessage: 'Suitable SmartPeg',
                      })}
                      value={modifiedAbutmentData.smartPeg}
                      className={cn(styles.dataItem, styles.equal)}
                    />
                  </div>
                </div>
              )}
              {activeImplant.measurements.length > 0 && (
                <div className={styles.historySection}>
                  <ISQListItem
                    label={intl.formatMessage({
                      id: 'patient.measurements',
                      defaultMessage: 'Measurements',
                    })}
                    data={activeImplant}
                    all
                    levelInfo
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImplantHistory;
