import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  bottomImplantNode,
  ConsultationWorkspaceState,
  Depth,
  initialWorkspaceState as initialState,
  NodeArea,
  Position,
  topImplantNode,
} from './data-structures/workspaceStructure';
import { intersects } from '../../utils/intersection';

export const workspaceSlice = createSlice({
  name: 'CONSULTATION_WORKSPACE',
  initialState,
  reducers: {
    TOGGLE_TOOTH_SINGLE: (state, action: PayloadAction<{ id: number }>) => {
      state.teethState[action.payload.id] = !state.teethState[action.payload.id];
      state.implant[action.payload.id] = false;
      state.abutment[action.payload.id] = false;
      state.teethPreparation[action.payload.id] = false;
      state.teethOverdenture[action.payload.id][0] = false;
      state.teethOverdenture[action.payload.id][1] = false;
      state.stability[action.payload.id] = false;
    },
    TOGGLE_TOOTH_ALL: (state, action: PayloadAction<{ id: number }>) => {
      const upperRow = action.payload.id < 16;
      const current = state.teethState[action.payload.id];
      state.teethState
        .filter((_, index) => (upperRow ? index < 16 : index >= 16))
        .forEach((_, index) => {
          state.teethState[upperRow ? index : index + 16] = !current;
          state.teethPreparation[upperRow ? index : index + 16] = false;

          if (!current) {
            state.implant[upperRow ? index : index + 16] = false;
            state.abutment[upperRow ? index : index + 16] = false;
            state.teethOverdenture[upperRow ? index : index + 16][0] = false;
            state.teethOverdenture[upperRow ? index : index + 16][1] = false;
            state.stability[upperRow ? index : index + 16] = false;
          }
        });
    },
    LOSS_LOCAL: (state, action: PayloadAction<{ id: number }>) => {
      const newDepth = ((state.boneState[action.payload.id].depth + 1) % 4) as Depth;
      state.boneState[action.payload.id].depth = newDepth;
      if (newDepth === 3) {
        state.teethState[action.payload.id] = false;
      }
    },
    LOSS_ALL: (state, action: PayloadAction<{ id: number }>) => {
      const upperRow = action.payload.id < 16;
      state.boneState
        .filter((_, index) => (upperRow ? index < 16 : index >= 16))
        .forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          const newDepth = ((state.boneState[transformedIndex].depth + 1) % 4) as Depth;
          if (newDepth === 3) {
            state.teethState[transformedIndex] = false;
          }
          state.boneState[transformedIndex].depth = newDepth;
        });
    },
    GRAFT: (state, action: PayloadAction<{ id: number }>) => {
      state.boneState[action.payload.id].graft = !state.boneState[action.payload.id].graft;
    },
    SOFT_TISSUE_LOSS_LOCAL: (state, action: PayloadAction<{ id: number }>) => {
      const newDepth = ((state.boneState[action.payload.id].softTissueDepth + 1) % 4) as Depth;
      state.boneState[action.payload.id].softTissueDepth = newDepth;
    },
    SOFT_TISSUE_LOSS_ALL: (state, action: PayloadAction<{ id: number }>) => {
      const upperRow = action.payload.id < 16;
      state.boneState
        .filter((_, index) => (upperRow ? index < 16 : index >= 16))
        .forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          const newDepth = ((state.boneState[transformedIndex].softTissueDepth + 1) % 4) as Depth;
          state.boneState[transformedIndex].softTissueDepth = newDepth;
        });
    },
    SOFT_TISSUE_GRAFT: (state, action: PayloadAction<{ id: number }>) => {
      state.boneState[action.payload.id].softTissueGraft =
        !state.boneState[action.payload.id].softTissueGraft;
    },
    PEAK: (state, action: PayloadAction<{ id: number; clickPositionX: number }>) => {
      const toggleSideRight = action.payload.clickPositionX >= 0.5;
      const currentPosition = action.payload.id;
      if (toggleSideRight && currentPosition !== 15 && currentPosition !== 31) {
        state.boneState[currentPosition].peak[1] = !state.boneState[currentPosition].peak[1];
        state.boneState[currentPosition + 1].peak[0] =
          !state.boneState[currentPosition + 1].peak[0];
      } else if (!toggleSideRight && currentPosition !== 0 && currentPosition !== 16) {
        state.boneState[currentPosition].peak[0] = !state.boneState[currentPosition].peak[0];
        state.boneState[currentPosition - 1].peak[1] =
          !state.boneState[currentPosition - 1].peak[1];
      }
    },
    SINUS_GRAFT: (state, action: PayloadAction<{ id: number }>) => {
      state.boneState[action.payload.id].sinusGraft = ((state.boneState[action.payload.id]
        .sinusGraft +
        1) %
        4) as Depth;
    },
    UPDATE_NODE: (
      state,
      action: PayloadAction<{ nodeArea: NodeArea; id: number; x: number; y: number }>
    ) => {
      switch (action.payload.nodeArea) {
        case 'SINUS_LEFT': {
          state.sinusState[0].nodes[action.payload.id].x = action.payload.x;
          state.sinusState[0].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        case 'SINUS_RIGHT': {
          state.sinusState[1].nodes[action.payload.id].x = action.payload.x;
          state.sinusState[1].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        case 'NERVES_LEFT': {
          state.nerveState[0].nodes[action.payload.id].x = action.payload.x;
          state.nerveState[0].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        case 'NERVES_RIGHT': {
          state.nerveState[1].nodes[action.payload.id].x = action.payload.x;
          state.nerveState[1].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        case 'NOSE': {
          state.sinusState[2].nodes[action.payload.id].x = action.payload.x;
          state.sinusState[2].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        case 'SMILE': {
          state.smileState[0].nodes[action.payload.id].x = action.payload.x;
          state.smileState[0].nodes[action.payload.id].y = action.payload.y;
          break;
        }
        default:
          console.info('Node area not implemented yet');
      }
    },
    UPDATE_SMAILE_NODE: (state, action: PayloadAction<{ nodes: Position[] }>) => {
      state.smileState[0].nodes = action.payload.nodes;
    },
    UPDATE_IMPLANT_NODE: (state, action: PayloadAction<{ id: number; x: number; y: number }>) => {
      state.implantNode[action.payload.id].x = action.payload.x;
      state.implantNode[action.payload.id].y = action.payload.y;
    },
    IMPLANT: (state, action: PayloadAction<{ id: number }>) => {
      if (!state.implant[action.payload.id]) {
        state.teethState[action.payload.id] = false;
        state.implantNode[action.payload.id] =
          action.payload.id < 16 ? topImplantNode : bottomImplantNode;
        state.teethPreparation[action.payload.id] = false;
      }
      state.implant[action.payload.id] = !state.implant[action.payload.id];
      state.implantType[action.payload.id] = 'TYPE1';
      state.abutment[action.payload.id] = false;
      state.stability[action.payload.id] = false;
    },
    IMPLANT_TYPE2: (state, action: PayloadAction<{ id: number }>) => {
      if (!state.implant[action.payload.id]) {
        state.teethState[action.payload.id] = false;
        state.implantNode[action.payload.id] =
          action.payload.id < 16 ? topImplantNode : bottomImplantNode;
        state.teethPreparation[action.payload.id] = false;
      }
      state.implant[action.payload.id] = !state.implant[action.payload.id];
      state.implantType[action.payload.id] = 'TYPE2';
      state.abutment[action.payload.id] = false;
      state.stability[action.payload.id] = false;
    },
    IMPLANT_TRANSFORM: (state, action: PayloadAction<{ id: number }>) => {
      if (!state.implant[action.payload.id]) {
        state.teethState[action.payload.id] = false;
      }
      state.implant[action.payload.id] = !state.implant[action.payload.id];
    },
    STABILITY: (state, action: PayloadAction<{ id: number }>) => {
      state.stability[action.payload.id] =
        !state.stability[action.payload.id] && state.implant[action.payload.id];
    },
    ABUTMENT: (state, action: PayloadAction<{ id: number }>) => {
      state.abutment[action.payload.id] = !state.abutment[action.payload.id];
      state.abutmentType[action.payload.id] = 'FIXED';
      state.teethState[action.payload.id] = false;
      state.teethPreparation[action.payload.id] = false;

      if (!state.implant[action.payload.id]) {
        state.implant[action.payload.id] = true;
        state.implantNode[action.payload.id] =
          action.payload.id < 16 ? topImplantNode : bottomImplantNode;
      }
    },
    ABUTMENT_OVERDENTURE: (state, action: PayloadAction<{ id: number }>) => {
      state.abutment[action.payload.id] = !state.abutment[action.payload.id];
      state.abutmentType[action.payload.id] = 'OVERDENTURE';
      state.teethState[action.payload.id] = false;
      state.teethPreparation[action.payload.id] = false;

      if (!state.implant[action.payload.id]) {
        state.implant[action.payload.id] = true;
        state.implantNode[action.payload.id] =
          action.payload.id < 16 ? topImplantNode : bottomImplantNode;
      }
    },
    PREPARATION: (state, action: PayloadAction<{ id: number }>) => {
      state.teethState[action.payload.id] = true;
      state.teethPreparation[action.payload.id] = !state.teethPreparation[action.payload.id];
      state.implant[action.payload.id] = false;
      state.abutment[action.payload.id] = false;
      state.stability[action.payload.id] = false;
    },
    RESTORATION_SINGLE: (state, action: PayloadAction<{ id: number }>) => {
      state.teethState[action.payload.id] = false;
      state.teethOverdenture[action.payload.id][0] = !state.teethOverdenture[action.payload.id][0];
      state.teethOverdenture[action.payload.id][1] = false;
      state.stability[action.payload.id] = false;
      if (state.teethState[action.payload.id]) state.teethPreparation[action.payload.id] = true;
    },
    RESTORATION_BRIDGE: (state, action: PayloadAction<{ id: number }>) => {
      const upperRow = action.payload.id < 16;
      const currentRow = state.teethOverdenture.filter((_, index) =>
        upperRow ? index < 16 : index >= 16
      );
      const rowHasOverdenture = currentRow.find((overdenture) => overdenture[0] === true);

      if (rowHasOverdenture) {
        currentRow.forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          if (!state.teethState[transformedIndex] || state.teethPreparation[transformedIndex]) {
            state.teethOverdenture[transformedIndex][0] = false;
          }
        });
      } else {
        currentRow.forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          if (!state.teethState[transformedIndex] || state.teethPreparation[transformedIndex]) {
            state.teethOverdenture[transformedIndex][0] = true;
            state.teethOverdenture[transformedIndex][1] = false;
            state.stability[transformedIndex] = false;
          }
        });
      }
    },
    RESTORATION_OVERDENTURE: (state, action: PayloadAction<{ id: number }>) => {
      const upperRow = action.payload.id < 16;
      const currentRow = state.teethOverdenture.filter((_, index) =>
        upperRow ? index < 16 : index >= 16
      );
      const rowHasOverdenture = currentRow.find((overdenture) => overdenture[1] === true);

      if (rowHasOverdenture) {
        currentRow.forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          if (!state.teethState[transformedIndex] || state.teethPreparation[transformedIndex]) {
            state.teethOverdenture[transformedIndex][1] = false;
          }
        });
      } else {
        currentRow.forEach((_, index) => {
          const transformedIndex = upperRow ? index : index + 16;
          if (!state.teethState[transformedIndex] || state.teethPreparation[transformedIndex]) {
            state.teethOverdenture[transformedIndex][1] = true;
            state.teethOverdenture[transformedIndex][0] = false;
            state.stability[transformedIndex] = false;
          }
        });
      }
    },
    TEXTFIELD_CREATE: (
      state,
      action: PayloadAction<{ id: string; x: number; y: number; color?: string }>
    ) => {
      state.textFields?.push({
        id: action.payload.id,
        position: {
          x: action.payload.x,
          y: action.payload.y,
        },
        text: '',
        color: action.payload.color,
      });
    },
    TEXTFIELD_UPDATE: (
      state,
      action: PayloadAction<{ id: string; x?: number; y?: number; text?: string; color?: string }>
    ) => {
      const textfield = state.textFields?.find((tf) => tf.id === action.payload.id);

      if (state.textFields && textfield) {
        if (typeof action.payload.text !== 'undefined') textfield.text = action.payload.text;

        if (action.payload.x && action.payload.y) {
          textfield.position.x = action.payload.x;
          textfield.position.y = action.payload.y;
        }

        if (action.payload.color) textfield.color = action.payload.color;
      }
    },
    TEXTFIELD_REMOVE: (state, action: PayloadAction<{ id: string }>) => {
      state.textFields = state.textFields?.filter((tf) => tf.id !== action.payload.id);
    },
    DRAWING_CREATE_SHAPE: (
      state,
      action: PayloadAction<{ id: string; x: number; y: number; color?: string }>
    ) => {
      state.drawShapes?.push({
        id: action.payload.id,
        position: {
          x: action.payload.x,
          y: action.payload.y,
        },
        color: action.payload.color || 'black',
        nodes: [
          {
            x: action.payload.x,
            y: action.payload.y,
          },
        ],
      });
    },
    DRAWING_UPDATE: (state, action: PayloadAction<{ id: string; x: number; y: number }>) => {
      const drawingShape = state.drawShapes?.find((shape) => shape.id === action.payload.id);
      if (state.drawShapes && drawingShape) {
        drawingShape.nodes.push({ x: action.payload.x, y: action.payload.y });
      }
    },
    DRAWING_DELETE_CLOSEST_SHAPE: (state, action: PayloadAction<{ x: number; y: number }>) => {
      const tapX = action.payload.x;
      const tapY = action.payload.y;
      state.drawShapes?.forEach((shape, index, allShapes) => {
        shape.nodes.forEach((node, nodeIndex) => {
          const distance = Math.sqrt(Math.pow(tapX - node.x, 2) + Math.pow(tapY - node.y, 2));
          if (distance < 32) {
            state.drawShapes?.find((s) => s.id === shape.id)?.nodes.splice(nodeIndex, 1);
          }
        });
        if (shape.nodes.length <= 1) {
          //one or zero nodes should delete shape
          allShapes.splice(index, 1);
        }
      });
    },
    DRAWING_DELETE_LINE_INTERSECTION: (
      state,
      action: PayloadAction<{ xStart: number; yStart: number; xEnd: number; yEnd: number }>
    ) => {
      const { xStart, yStart, xEnd, yEnd } = action.payload;
      state.drawShapes?.forEach((shape, shapeIndex, allShapes) => {
        const shapeStartNode = shape.nodes[0];
        const shapeEndNode = shape.nodes[1];
        if (shapeStartNode && shapeEndNode) {
          //if only 2 nodes, this is a line so only check the shapes first two nodes
          const isLine = shape.nodes.length === 2;
          if (isLine) {
            if (
              intersects(
                xStart,
                yStart,
                xEnd,
                yEnd,
                shapeStartNode.x,
                shapeStartNode.y,
                shapeEndNode.x,
                shapeEndNode.y
              )
            ) {
              allShapes.splice(shapeIndex, 1);
            }
          } else {
            shape.nodes.forEach((node, index, allShapeNodes) => {
              if (index === 0 || allShapeNodes.length < 2) return;
              if (
                intersects(
                  xStart,
                  yStart,
                  xEnd,
                  yEnd,
                  node.x,
                  node.y,
                  allShapeNodes[index - 1].x,
                  allShapeNodes[index - 1].y
                )
              ) {
                allShapes.splice(shapeIndex, 1);
              }
            });
          }
        }
      });
    },
    DRAWING_CLEAR: (state) => {
      //Clear all drawings
      if (state.drawShapes) state.drawShapes.length = 0;
    },
    TOGGLE_MODE_DARK: (state) => {
      state.view.darkMode = !state.view.darkMode;
    },
    TOGGLE_MODE_GUMS: (state) => {
      state.view.gumsTransparencyMode = !state.view.gumsTransparencyMode;
    },
    TOGGLE_MODE_SINUS: (state) => {
      state.view.sinusMode = !state.view.sinusMode;
    },
    TOGGLE_MODE_NERVE: (state) => {
      state.view.nerveNodes = !state.view.nerveNodes;
    },
    TOGGLE_MODE_TOOTH_NUMBERS: (state) => {
      state.view.toothNumbers = !state.view.toothNumbers;
    },
    TOGGLE_MODE_SMILE: (state) => {
      state.view.smileMode = !state.view.smileMode;
    },
    SET_SKINTONE: (state, action: PayloadAction<number>) => {
      state.view.skintone = action.payload;
    },
    SET_IMAGE: (state, action: PayloadAction<string | undefined>) => {
      state.image = action.payload;
      //Clear all drawings and text fields
      if (state.drawShapes) state.drawShapes.length = 0;
      if (state.textFields) state.textFields.length = 0;
    },
    RESET: (_) => initialState,
    RESTORE: (_, action: PayloadAction<ConsultationWorkspaceState>) => {
      return { ...initialState, ...action.payload };
    },
  },
});

export const {
  TOGGLE_TOOTH_SINGLE,
  TOGGLE_TOOTH_ALL,
  LOSS_LOCAL,
  LOSS_ALL,
  GRAFT,
  SOFT_TISSUE_LOSS_LOCAL,
  SOFT_TISSUE_LOSS_ALL,
  SOFT_TISSUE_GRAFT,
  PEAK,
  SINUS_GRAFT,
  IMPLANT,
  IMPLANT_TYPE2,
  IMPLANT_TRANSFORM,
  UPDATE_NODE,
  UPDATE_SMAILE_NODE,
  RESET,
  RESTORE,
  STABILITY,
  ABUTMENT,
  ABUTMENT_OVERDENTURE,
  PREPARATION,
  RESTORATION_SINGLE,
  RESTORATION_BRIDGE,
  RESTORATION_OVERDENTURE,
  TEXTFIELD_CREATE,
  TEXTFIELD_UPDATE,
  TEXTFIELD_REMOVE,
  DRAWING_CREATE_SHAPE,
  DRAWING_UPDATE,
  DRAWING_DELETE_CLOSEST_SHAPE,
  DRAWING_DELETE_LINE_INTERSECTION,
  DRAWING_CLEAR,
  UPDATE_IMPLANT_NODE,
  TOGGLE_MODE_DARK,
  TOGGLE_MODE_GUMS,
  TOGGLE_MODE_NERVE,
  TOGGLE_MODE_TOOTH_NUMBERS,
  TOGGLE_MODE_SINUS,
  TOGGLE_MODE_SMILE,
  SET_SKINTONE,
  SET_IMAGE,
} = workspaceSlice.actions;

export default workspaceSlice.reducer;
