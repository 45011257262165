import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';
import readModelHub from '../../utils/readModelHub';

function updateClinicRequest(clinicData) {
  return {
    type: types.UPDATE_CLINIC,
    clinicData,
  };
}

function updateClinicSuccess() {
  return {
    type: types.UPDATE_CLINIC_SUCCESS,
  };
}

function updateClinicFailed(error) {
  return {
    type: types.UPDATE_CLINIC_ERROR,
    error,
  };
}

export function updateClinic(clinicData) {
  return (dispatch) => {
    dispatch(updateClinicRequest(clinicData));

    ApiFetch.put('clinic', clinicData)
      .then((data) => {
        dispatch(updateClinicSuccess(data));
      })
      .catch((error) => {
        dispatch(updateClinicFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function getClinicRequest(userId) {
  return {
    type: types.GET_CLINIC,
    userId,
  };
}

function getClinicSuccess(data, userId) {
  return {
    type: types.GET_CLINIC_SUCCESS,
    clinics: data,
    userId,
  };
}

function getClinicFailed(error) {
  return {
    type: types.GET_CLINIC_ERROR,
    error,
  };
}

export function getClinic(userId) {
  return (dispatch) => {
    dispatch(getClinicRequest(userId));

    const url = userId ? `admin/users/${userId}/clinic` : 'clinic';

    ApiFetch.get(url)
      .then((clinic) => {
        dispatch(getClinicSuccess(clinic, userId));
      })
      .catch((error) => {
        dispatch(getClinicFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function updateReferralsRequest(referrals) {
  return {
    type: types.UPDATE_REFERRALS,
    referrals,
  };
}

function updateReferralsSuccess() {
  return {
    type: types.UPDATE_REFERRALS_SUCCESS,
  };
}

function updateReferralsFailed(error) {
  return {
    type: types.UPDATE_REFERRALS_ERROR,
    error,
  };
}

export function updateReferrals(clinicId, referrals) {
  return (dispatch) => {
    dispatch(updateReferralsRequest(referrals));

    const url = `clinic/${clinicId}/referrals`;

    ApiFetch.put(url, referrals)
      .then((data) => {
        dispatch(updateReferralsSuccess(data));
        dispatch(getClinic());
      })
      .catch((error) => {
        dispatch(updateReferralsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

export function subscribeToClinicChange(userId, clinicId) {
  return (dispatch) => {
    console.log('SignalR: Subscribing to clinic (subscribeToClinicChange)');
    readModelHub.subscribe('clinic', clinicId, () => {
      dispatch(getClinic(userId));
    });
  };
}

export function unsubscribeFromClinicChange(clinicId) {
  return () => {
    console.log('SignalR: Unsubscribing from clinic (unsubscribeFromClinicChange)');
    readModelHub.unsubscribe('clinic', clinicId);
  };
}
