import styled from 'styled-components';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { Image, Layer, Stage } from 'react-konva';
import { Provider, ReactReduxContext } from 'react-redux';
import useImage from 'use-image';
import Konva from 'konva';
import { TeethCanvasTeethLayer } from './layers/TeethCanvasTeethLayer';
import { TeethCanvasToolsLayer } from './layers/TeethCanvasToolsLayer';
import { TeethCanvasToolsOverlayLayer } from './layers/TeethCanvasToolsOverlayLayer';
import { TeethCanvasDrawLayer } from './TeethCanvasDrawLayer';
import { TeethCanvasBoneLayer } from './layers/TeethCanvasBoneLayer';
import { TeethCanvasGumsLayer } from './layers/TeethCanvasGumsLayer';
import { ImageLayer } from './layers/ImageLayer';
import { useAppSelector } from '../../../../../../utils/hooks';
import { SmileRiveCanvas } from '../../../smile-view/SmileRiveCanvas';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const GRAPHIC_WIDTH = 800;

interface Props {
  scale?: {
    x: number;
    y: number;
  };
  offsetTop?: number;
  width: number;
  height: number;
}
const TeethCanvasViewport: React.FC<Props> = ({ width, height }) => {
  const stage = useRef<Konva.Stage>(null);
  const intl = useIntl();

  const smileView = useAppSelector((state) => state.consultation.workspace.present.view.smileMode);
  const showImage = useAppSelector((state) => state.consultation.workspace.present.image);

  const globalTransform = useMemo(
    () => ({
      offsetX: width / 2,
      offsetY: height / 2,
      x: width - GRAPHIC_WIDTH / 2 - 28,
      y: height / 2 + 48,
      centeredScaling: true,
      scale: { x: 1, y: 1 },
    }),
    [width, height]
  );

  function changeCursor(cursor: string) {
    if (stage.current) {
      stage.current.container().style.cursor = cursor;
    }
  }

  return (
    <CanvasViewport>
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Stage width={width} height={height} ref={stage}>
            <Provider store={store}>
              {/*
                ---------Layer groups stack:
                1 TeethCanvasTeethLayer
                2 TeethCanvasBoneLayer (Rive?)
                3 TeethCanvasToolsLayer
                4 TeethCanvasGumsLayer (Rive?)
                5 TeethCanvasToolsOverlayLayer
                5 TeethCanvasDrawLayer
                6 Smile overlay
                */}

              {showImage ? (
                <Layer>
                  {/*Patient Image*/}
                  <ImageLayer
                    width={width}
                    height={height}
                    removeImageIntl={intl.formatMessage({
                      id: 'consultation.tool.removeImage',
                      defaultMessage: 'Remove image',
                    })}
                  />
                </Layer>
              ) : (
                <>
                  {/* --- 1. Teeth Layer*/}
                  <Layer {...globalTransform} listening={false}>
                    <TeethCanvasTeethLayer />
                  </Layer>
                  {/* --- 2. Bone (Rive) */}
                  {!showImage && <TeethCanvasBoneLayer width={width} height={height} />}
                  {/* --- 3. Tools between Bone and Gums implants, abutments, crowns, tooth numbers*/}
                  <Layer {...globalTransform}>
                    <TeethCanvasToolsLayer changeCursor={changeCursor} />
                  </Layer>
                  {/* --- 4. Gums*/}
                  <TeethCanvasGumsLayer width={width} height={height} />
                </>
              )}

              {/* --- 5. Tools overlay and Text/Drawings*/}
              <Layer {...globalTransform}>
                {/*Overdenture & ISQ Bar */}
                {!showImage && <TeethCanvasToolsOverlayLayer />}

                {/*Text/Drawings */}
                <TeethCanvasDrawLayer changeCursor={changeCursor} />
              </Layer>

              {/* --- 6. Smile Overlay */}
              {smileView && (
                <SmileRiveCanvas
                  width={width}
                  height={height}
                  globalTransform={globalTransform}
                  setLoading={() => {}}
                />
              )}
            </Provider>
          </Stage>
        )}
      </ReactReduxContext.Consumer>
    </CanvasViewport>
  );
};

export default TeethCanvasViewport;

const CanvasViewport = styled.div`
  position: absolute;
  top: 0px;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  max-height: 550px;
  pointer-events: all;

  & * {
    pointer-events: all;
  }
`;

interface CanvasImageProps extends Partial<Konva.ImageConfig> {
  url: string;
  alignToBottom?: boolean;
  alignMiddle?: boolean;
}

const Loading: React.FC<Konva.TextConfig> = (_props) => {
  return null; //If needed we can have a loading bar displaying when loading images
};

export const CanvasImage: React.FC<CanvasImageProps> = ({ url, alignToBottom, ...props }) => {
  const [img] = useImage(url);
  const imageRef = useRef<Konva.Image>(null);
  useEffect(() => {
    // imageRef.current?.cache();
  }, [img]);

  return img ? (
    <Image
      {...props}
      image={img}
      offsetX={img?.width / 2 + (props.offsetX || 0)}
      offsetY={alignToBottom ? img?.height : 0}
      x={img?.width / 2 + (props.x || 0)}
      y={props.y || 0}
      ref={imageRef}
    />
  ) : (
    <Loading x={props.x} y={props.y} />
  );
};

export const CanvasImageCentered: React.FC<CanvasImageProps> = ({
  url,
  _alignToBottom,
  ...props
}) => {
  const [img] = useImage(url);
  const imageRef = useRef<Konva.Image>(null);
  useEffect(() => {
    imageRef.current?.cache();
  }, [img]);

  return img ? (
    <Image
      {...props}
      image={img}
      offsetX={img?.width / 2 + (props.offsetX || 0)}
      offsetY={img?.height / 2 + (props.offsetY || 0)}
      x={props.x || 0}
      y={props.y || 0}
      ref={imageRef}
    />
  ) : (
    <Loading x={props.x} y={props.y} />
  );
};
