import { Component } from 'react';
import Datetime from 'react-datetime';
import 'moment/locale/en-gb';
import 'react-datetime/css/react-datetime.css';

import styles from './Forms.module.scss';
import classNames from 'classnames';
import { Button } from '../Buttons';
import Dropdown from '../Dropdown/Dropdown';
import SelectButton from '../Buttons/SelectButton';

class InputItem extends Component {
  constructor() {
    super();
    this.state = { invalidDate: false };

    this.checkValidDate = this.checkValidDate.bind(this);

    this.dateOptions = [
      'Jan 2015',
      'Feb 2015',
      'Mar 2015',
      'Apr 2015',
      'May 2015',
      'Jun 2015',
      'Jul 2015',
      'Aug 2015',
      'Sept 2015',
      'Okt 2015',
      'Nov 2015',
      'Dec 2015',
      'Jan 2016',
      'Feb 2016',
      'Mar 2016',
      'Apr 2016',
      'May 2016',
      'Jun 2016',
      'Jul 2016',
      'Aug 2016',
      'Sept 2016',
      'Okt 2016',
      'Nov 2016',
      'Dec 2016',
      'Jan 2017',
      'Feb 2017',
      'Mar 2017',
    ];
    this.placementOptions = [1, 10, 20, 50, 100, 200, 500, 1000];
    this.ageOptions = [
      10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105,
    ];
  }

  datePickerDay(props, currentDate, selectedDate) {
    const selected = currentDate.isSame(selectedDate, 'day');
    const today = currentDate.isSame(Date.now(), 'day');
    return (
      <td
        {...props}
        className={classNames(
          props.className,
          today ? styles.datePickerToday : null,
          selected ? styles.datePickerSelectedDay : null
        )}
      >
        {currentDate.date()}
      </td>
    );
  }

  checkValidDate(input, onlyPositiveValidation = false) {
    if (input._isAMomentObject || input === '') {
      return this.setState({ invalidDate: false });
    }
    if (!onlyPositiveValidation) this.setState({ invalidDate: true });
    return;
  }

  render() {
    const cn = classNames(
      this.props.className,
      this.props.noBorder ? styles.inputItemNoBorder : styles.inputItem,
      this.props.noGrow ? null : styles.grow,
      this.props.shrink ? styles.shrink : null,
      this.props.light === true ? styles.light : null,
      this.props.dark && styles.dark,
      this.props.small === true ? styles.small : null,
      this.props.label || this.props.noMarginTop ? null : styles.marginTop,
      this.props.noPadding === true ? styles.noPadding : null,
      !this.props.noMarginBottom && styles.marginBottom
    );

    let InputItem = <div></div>;
    let InputWrapper = <div></div>;

    const SubLabel =
      this.props.minLabel && this.props.maxLabel ? (
        <div className={styles.subLabel}>
          <label className={styles.label}>{this.props.minLabel}</label>
          <label className={styles.label}>{this.props.maxLabel}</label>
        </div>
      ) : null;

    if (this.props.type === 'select') {
      const inputOptions = this.props.options.map((option, optionIndex) => (
        <option key={optionIndex} value={option.key !== undefined ? option.key : option.value}>
          {option.value}
        </option>
      ));

      InputItem = (
        <div
          className={classNames(styles.selectWrapper, this.props.onWhite ? styles.onWhite : null)}
        >
          <select
            data-cy={this.props.dataCy && this.props.dataCy}
            disabled={this.props.disabled}
            className={classNames(
              styles.inputItem,
              styles.select,
              this.props.onWhite ? styles.onWhite : null
            )}
            onChange={this.props.onChange}
            value={this.props.value}
          >
            {this.props.defaultKey || this.props.defaultKey ? (
              <option
                value={this.props.defaultKey ? this.props.defaultKey : this.props.defaultValue}
                className={classNames(this.props.hiddenDefault ? styles.hiddenOption : null)}
              >
                {this.props.defaultValue}
              </option>
            ) : null}
            {inputOptions}
          </select>
        </div>
      );
    }

    if (this.props.type === 'select-groups') {
      const inputGroups = this.props.optionGroups.map((optionGroup, groupIndex) => {
        const inputOptions = optionGroup.options.map((option, optionIndex) => (
          <option
            key={groupIndex + optionIndex}
            value={option.key !== undefined ? option.key : option.value}
          >
            {option.value}
          </option>
        ));
        return (
          <optgroup key={optionGroup.label} label={optionGroup.label}>
            {inputOptions}
          </optgroup>
        );
      });

      InputItem = (
        <div className={styles.selectWrapper}>
          <select
            disabled={this.props.disabled}
            className={classNames(styles.inputItem, styles.select)}
            onChange={this.props.onChange}
            value={this.props.value}
          >
            <option
              value={this.props.defaultKey ? this.props.defaultKey : this.props.defaultValue}
              className={classNames(this.props.hiddenDefault ? styles.hiddenOption : null)}
            >
              {this.props.defaultValue}
            </option>
            {inputGroups}
          </select>
        </div>
      );
    }

    if (this.props.type === 'time-span') {
      const inputOptions = this.dateOptions.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
      InputItem = (
        <div className={classNames(styles.timeSelect)}>
          <select
            className={classNames(styles.inputItem, styles.select, styles.timeSelect)}
            onChange={this.props.onChange}
          >
            {inputOptions}
          </select>
          <select
            className={classNames(styles.inputItem, styles.select, styles.timeSelect)}
            onChange={this.props.onChange}
          >
            {inputOptions}
          </select>
        </div>
      );
    }

    if (this.props.type === 'date') {
      const inputProps = {
        className: classNames(styles.dateInput, this.props.onWhite ? styles.onWhite : null),
        readOnly: true,
        disabled: this.props.disabled,
      };
      InputItem = (
        <Datetime
          className={classNames(
            styles.datePicker,
            this.state.invalidDate ? styles.invalidDate : null
          )}
          locale="en-gb"
          dateFormat="MMM DD, YYYY"
          onChange={(e) => {
            this.props.onChange(e);
            this.checkValidDate(e, true);
          }}
          onClose={this.checkValidDate}
          value={this.props.value}
          timeFormat={this.props.timeVisible}
          inputProps={inputProps}
          renderDay={this.datePickerDay}
          closeOnSelect={true}
          initialViewMode={this.props.startWithYears ? 'years' : 'days'}
          initialViewDate={this.props.initialDate}
        />
      );
    }

    if (this.props.type === 'range') {
      const inputOptionsMin = this.props.options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
      const inputOptionsMax = this.props.options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ));
      InputItem = (
        <div className={classNames(styles.rangeSelect)}>
          <select
            className={classNames(styles.inputItem, styles.select, styles.rangeSelect)}
            onChange={this.props.onChange}
          >
            {inputOptionsMin}
          </select>
          <select
            className={classNames(styles.inputItem, styles.select, styles.rangeSelect)}
            onChange={this.props.onChange}
          >
            {inputOptionsMax}
          </select>
        </div>
      );
    }

    if (this.props.type === 'tags') {
      const inputOptions = this.props.tags.map((tag) => (
        <div key={tag} className={styles.tag}>
          {tag}
        </div>
      ));

      InputItem = (
        <div className={styles.tagFilter}>
          {inputOptions} <div className={classNames(styles.tag, styles.add)}>+</div>
        </div>
      );
    }

    if (
      this.props.type === 'text' ||
      this.props.type === 'email' ||
      this.props.type === 'password'
    ) {
      InputItem = (
        <input
          data-cy={this.props.dataCy && this.props.dataCy}
          type={this.props.type}
          className={this.props.onWhite ? styles.onWhite : null}
          onChange={this.props.onChange}
          onKeyPress={this.props.onKeyPress}
          value={this.props.value}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          pattern={this.props.pattern}
        />
      );
    }

    if (this.props.type === 'textarea') {
      InputItem = (
        <textarea
          data-cy={this.props.dataCy && this.props.dataCy}
          type="textarea"
          className={this.props.onWhite ? styles.onWhite : null}
          onChange={this.props.onChange}
          onKeyPress={this.props.onKeyPress}
          value={this.props.value}
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          maxLength={this.props.maxLength && this.props.maxLength}
        />
      );
    }

    if (this.props.type === 'button') {
      InputItem = (
        <Button
          value={this.props.value}
          onClick={this.props.onClick}
          primary={this.props.primary}
          className={styles.button}
          loading={this.props.loading}
        />
      );
    }

    if (this.props.type === 'selectbutton') {
      InputItem = (
        <SelectButton
          dataCy={this.props.dataCy && this.props.dataCy}
          options={this.props.options}
          onChange={this.props.onChange}
          onClick={this.props.onClick}
          value={this.props.value}
          centered={this.props.centered}
          vertical={this.props.vertical}
          onWhite={this.props.onWhite}
          span={this.props.span}
        />
      );
    }

    if (this.props.type === 'boolbutton') {
      InputItem = (
        <SelectButton
          bool
          dataCy={this.props.dataCy && this.props.dataCy}
          boolText={this.props.boolText}
          onChange={this.props.onChange}
          value={this.props.value}
          label={this.props.buttonLabel}
          checked={this.props.checked}
          onWhite={this.props.onWhite}
          span={this.props.span}
        />
      );
    }

    if (this.props.type === 'checkbox') {
      InputItem = (
        <input
          type="checkbox"
          onChange={this.props.onChange}
          value={this.props.value}
          onKeyPress={this.props.onKeyPress}
          checked={this.props.checked}
        />
      );
    }

    if (this.props.type === 'search') {
      InputItem = (
        <div className={styles.searchBox}>
          <i>search</i>{' '}
          <input
            type="text"
            placeholder={this.props.placeholder}
            onChange={this.props.onChange}
            onKeyPress={this.props.onKeyPress}
            value={this.props.value}
          />
        </div>
      );
    }

    if (this.props.type === 'search-select') {
      InputItem = (
        <Dropdown
          dataCy={this.props.dataCy && this.props.dataCy}
          className={styles.item}
          fullWidth
          align="left"
          options={this.props.options}
          footer={this.props.footer}
          onChange={this.props.onChange}
          searchBox
        >
          <div className={classNames(styles.selectWrapper, styles.select)}>
            {this.props.options.find((x) => x.value === this.props.value)?.label ||
              this.props.value}
          </div>
        </Dropdown>
      );
    }

    if (this.props.type === 'image') {
      InputItem = (
        <div className={styles.image}>
          {this.props.description && (
            <div className={styles.hoverBox}>
              <div className={styles.description}>{this.props.description}</div>
            </div>
          )}
          <img src="http://placehold.it/350x350?text=Profile photo" alt="profile" />
        </div>
      );
    }

    if (this.props.type === 'slider') {
      InputItem = (
        <div className={styles.sliderWrapper} onClick={this.props.onClick}>
          <input type="checkbox" checked={this.props.checked} />
          <span className={styles.slider}></span>
        </div>
      );
    }

    if (this.props.type === 'checkbox') {
      InputWrapper = (
        <label
          className={classNames(styles.checkBox, this.props.noMargins ? null : styles.margins)}
        >
          {InputItem}
          <span
            className={classNames(
              styles.label,
              this.props.disabled && !this.props.inactive ? styles.disabled : null
            )}
          >
            {this.props.label}
          </span>
        </label>
      );
    } else {
      InputWrapper = (
        <div>
          {this.props.label && (
            <label
              className={classNames(
                styles.label,
                this.props.disabled && !this.props.inactive ? styles.disabled : null
              )}
            >
              {this.props.label}
            </label>
          )}
          {SubLabel}
          {InputItem}
        </div>
      );
    }

    if (this.props.type === 'datePicker')
      return (
        <Datetime
          className={classNames(this.state.invalidDate ? styles.invalidDate : null)}
          locale="en-gb"
          dateFormat="MMM DD, YYYY"
          onChange={(e) => {
            this.props.onChange(e);
            this.checkValidDate(e, true);
          }}
          onClose={this.checkValidDate}
          value={this.props.value}
          timeFormat={this.props.timeVisible}
          renderDay={this.datePickerDay}
          closeOnSelect={true}
          initialViewMode={this.props.startWithYears ? 'years' : 'days'}
          initialViewDate={this.props.initialDate}
          inputProps={{ disabled: this.props.disabled }}
        />
      );

    return (
      <div className={classNames(cn, this.props.maxLabel ? styles.hasSublabel : null)}>
        {InputWrapper}
      </div>
    );
  }
}

export default InputItem;
