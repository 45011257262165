import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';
import Storage from '../../utils/storage';
import base64url from 'base64url';
import { trackWhichDeviceLoggedIn } from '../../utils/applicationInsights';

function registerAccountRequest() {
  return {
    type: types.REGISTER_ACCOUNT,
  };
}

function registerAccountSuccess() {
  return {
    type: types.REGISTER_ACCOUNT_SUCCESS,
  };
}

function registerAccountFailed(error) {
  return {
    type: types.REGISTER_ACCOUNT_ERROR,
    error,
  };
}

export function registerAccount(accountData) {
  return (dispatch) => {
    dispatch(registerAccountRequest());

    ApiFetch.post('accounts/register', accountData, true)
      .then((data) => {
        dispatch(registerAccountSuccess(data));
      })
      .catch((error) => {
        dispatch(registerAccountFailed(error));
      });
  };
}

function loginAccountRequest() {
  return {
    type: types.LOGIN_ACCOUNT,
  };
}

export function loginAccountSuccess(token, email) {
  return {
    type: types.LOGIN_ACCOUNT_SUCCESS,
    token: token,
    email: email,
  };
}

function loginAccountFailed(error) {
  return {
    type: types.LOGIN_ACCOUNT_ERROR,
    error: error,
  };
}

export function loginAccount(email, password) {
  return (dispatch) => {
    dispatch(loginAccountRequest());

    const request = {
      userName: email,
      password: password,
    };

    ApiFetch.post('accounts/login', request, true)
      .then((token) => {
        trackWhichDeviceLoggedIn(email);
        dispatch(loginAccountSuccess(token, email));
      })
      .catch((error) => {
        dispatch(loginAccountFailed(error));
      });
  };
}

function reauthenticateRequest() {
  return {
    type: types.REAUTHENTICATE,
  };
}

function reauthenticateSuccess() {
  return {
    type: types.REAUTHENTICATE_SUCCESS,
  };
}

function reauthenticateError(error) {
  return {
    type: types.REAUTHENTICATE_ERROR,
    errorMessages: error.messages,
  };
}

export function reauthenticate(email, password) {
  return (dispatch) => {
    dispatch(reauthenticateRequest());
    const request = {
      userName: email,
      password: password,
    };

    ApiFetch.post('accounts/reauthenticate', request, false)
      .then(() => {
        dispatch(reauthenticateSuccess());
      })
      .catch((error) => {
        dispatch(reauthenticateError(error));
      });
  };
}

function retrievePasswordRequest() {
  return {
    type: types.RETRIEVE_PASSWORD,
  };
}

function retrievePasswordSuccess() {
  return {
    type: types.RETRIEVE_PASSWORD_SUCCESS,
  };
}

function retrievePasswordFailed(error) {
  return {
    type: types.RETRIEVE_PASSWORD_ERROR,
    error,
  };
}

export function retrievePassword(email) {
  return (dispatch) => {
    dispatch(retrievePasswordRequest());

    ApiFetch.post('accounts/forgotpassword', email, true)
      .then((data) => {
        dispatch(retrievePasswordSuccess(data));
      })
      .catch((error) => {
        dispatch(retrievePasswordFailed(error));
      });
  };
}

function resetPasswordRequest() {
  return {
    type: types.RESET_PASSWORD,
  };
}

function resetPasswordSuccess() {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
  };
}

function resetPasswordFailed(error) {
  return {
    type: types.RESET_PASSWORD_ERROR,
    error,
  };
}

export function resetPassword(email, password, passwordRepeat, code) {
  return (dispatch) => {
    dispatch(resetPasswordRequest());
    const request = {
      email,
      password,
      passwordRepeat,
      code,
    };

    ApiFetch.post('accounts/resetpassword', request, true)
      .then((data) => {
        dispatch(resetPasswordSuccess(data));
      })
      .catch((error) => {
        dispatch(resetPasswordFailed(error));
      });
  };
}

function confirmAccountRequest(token) {
  return {
    type: types.CONFIRM_ACCOUNT,
    token: token,
  };
}

function confirmAccountSuccess(_) {
  return {
    type: types.CONFIRM_ACCOUNT_SUCCESS,
    //token: data,
  };
}

function confirmAccountFailed(error) {
  return {
    type: types.CONFIRM_ACCOUNT_ERROR,
    error,
  };
}

export function confirmAccount(userId, code, token) {
  return (dispatch) => {
    dispatch(confirmAccountRequest(base64url.decode(token.substring(0, token.length - 1))));
    const request = {
      userId,
      code,
      token,
    };
    ApiFetch.put('accounts/confirm', request)
      .then((data) => {
        dispatch(confirmAccountSuccess(data));
      })
      .catch((error) => {
        dispatch(confirmAccountFailed(error));
      });
  };
}
export function addTokenToBrowser(token) {
  return (dispatch) => {
    dispatch(addToken(token));
  };
}

function addToken(token) {
  return {
    type: types.ADD_TOKEN,
    token: token,
  };
}

export function clearToken(tokenExpired, inactivity) {
  return {
    type: types.CLEAR_TOKEN,
    tokenExpired,
    inactivity,
  };
}

export function logoutAccount() {
  Storage.clearErrors();
  Storage.clearApiCalls();

  return () => {
    ApiFetch.post('accounts/logout');
  };
}

export function setInactivityWarning(inactivity) {
  return {
    type: types.INACTIVITY_WARNING,
    inactivity,
  };
}
