import * as React from 'react';

function SvgSmartpegliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 776 2048" {...props}>
      <path d="M484 1555q21-5 26 15 2 8-2.5 16t-12.5 10l-206 54q-2 1-5 1-16 0-21-16-2-8 3-16t13-10zm-1 127q8-2 16 3t10 13-2.5 16-12.5 10l-205 54q-2 1-6 1-15 0-20-16-2-8 2.5-16t12.5-10zM620 344q22 0 38 16t16 38v206q0 23-16 39t-38 16h-79l-1 723q0 29-18.5 53t-46.5 31l-213 56q-2 1-6 1-15 0-20-16-2-8 2.5-16t12.5-10l213-56q15-4 24-16t9-27l1-766h122q12 0 12-12V398q0-12-12-12H157q-12 0-12 12v206q0 12 12 12h122l-1 734q0 9-6.5 15.5T256 1372t-15-6.5-6-15.5l1-691h-79q-23 0-39-16.5T102 604V398q0-22 16-38t39-16h82q-4-6-4-10v-95q0-43 30-73t73-30h98q43 0 73 30t30 73v95q0 4-4 10h85zm-343-10q0 4-4 10h228q-4-6-4-10v-95q0-25-17.5-43T436 178h-98q-26 0-43.5 18T277 239v95z" />
    </svg>
  );
}

export default SvgSmartpegliga;
