import React, { Component } from 'react';
import styles from './DataItem.module.scss';
import cn from 'classnames';
import {
  FormattedMessage,
  FormattedDate,
  FormattedTime,
  FormattedRelativeTime,
  FormattedNumber,
  injectIntl,
} from 'react-intl';

class DataItem extends Component {
  renderLabel() {
    const message = {
      id: this.props.labelId ?? this.props.label,
      defaultMessage: this.props.label,
    };
    return (
      <div className={styles.label}>
        <FormattedMessage {...message} />
      </div>
    );
  }

  renderValue() {
    const value = this.props.value
      ? this.props.value
      : this.props.defaultValue
      ? this.props.defaultValue
      : '-';

    if (this.props.noValueTranslation) {
      return (
        <div className={styles.value}>
          <span>{value}</span>
        </div>
      );
    }

    if (this.props.date) {
      return (
        <div className={styles.value}>
          <FormattedDate value={value} year="numeric" month="short" day="numeric" />
        </div>
      );
    }
    if (this.props.time) {
      return (
        <div className={styles.value}>
          <FormattedTime value={value} />
        </div>
      );
    }
    if (this.props.relative) {
      return value && value !== '-' ? (
        <div className={styles.value}>
          <FormattedRelativeTime value={value} />
          <span className={styles.subDate}>
            <FormattedTime value={value} month="short" day="2-digit" />
          </span>
        </div>
      ) : (
        '-'
      );
    }

    if (this.props.number) {
      return (
        <div className={styles.value}>
          <FormattedNumber value={value} />
        </div>
      );
    }

    return <div className={styles.value}>{value}</div>;
  }

  renderMeasurement() {
    const value = this.props.value
      ? this.props.value
      : this.props.defaultValue
      ? this.props.defaultValue
      : '-';
    const formattedValue = this.props.number ? <FormattedNumber value={value} /> : { value };

    const currentBl = this.props.auxData.currentBl ? this.props.auxData.currentBl : '-';
    const currentMd = this.props.auxData.currentMd ? this.props.auxData.currentMd : '-';

    return (
      <div className={styles.value}>
        {formattedValue}
        <div className={styles.trailingStats}>
          <div>BL: {currentBl}</div>
          <div>MD: {currentMd}</div>
        </div>
      </div>
    );
  }

  renderInsight() {
    const value = this.props.value
      ? this.props.value
      : this.props.defaultValue
      ? this.props.defaultValue
      : '-';
    const insightStyle = cn(styles.value, styles.insightStatus);
    return (
      <div className={insightStyle}>
        {value}
        {this.props.insight && <div className={styles.insight}>{this.props.insight}</div>}
      </div>
    );
  }

  render() {
    let valueContent;

    if (this.props.measurement) {
      valueContent = this.renderMeasurement();
    } else if (this.props.insight) {
      valueContent = this.renderInsight();
    } else {
      valueContent = this.props.link ? (
        <a href={this.props.link}>{this.renderValue()}</a>
      ) : (
        this.renderValue()
      );
    }

    return (
      <div
        className={cn(
          styles.dataItem,
          this.props.className,
          this.props.small ? styles.small : null,
          this.props.span ? styles.span : null,
          this.props.deviceSmall ? styles.deviceSmall : null,
          this.props.deviceLarge ? styles.deviceLarge : null,
          this.props.size === 'big' ? styles.bigVal : null,
          this.props.size === 'mid' ? styles.midVal : null
        )}
      >
        {this.renderLabel()}
        {valueContent}
      </div>
    );
  }
}

export default injectIntl(DataItem);
