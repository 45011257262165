import React from 'react';
import styles from './Headings.module.scss';
import cn from 'classnames';

const H3 = (props) => (
  <h3 className={cn(styles.h3, props.className)} style={props.style}>
    {props.children}
  </h3>
);

export default H3;
