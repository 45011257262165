export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

export const transformToothIdToUNS = (id: number): string => {
  if (id >= 16) {
    return (48 - id).toString();
  }
  return (id + 1).toString();
};

export const transformToothIdToFDI = (id: number): string => {
  return id.toString(); //not implemented yet
};

export function lerp(start: number, end: number, alpha: number) {
  return (1 - alpha) * start + alpha * end;
}
