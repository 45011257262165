import { FC } from 'react';
import { H2, H3, H4 } from '../Shared/Headings';
import { LargeButton } from '../Shared/Buttons';
import { FormattedMessage, useIntl } from 'react-intl';
import { windowsIcon, macIcon, IDxIcon } from '../../constants/registerIllustrations';
import styles from './Devices.module.scss';
import cn from 'classnames';

interface Props {
  downLoadingFirmwareType: string | null;
  onDownloadFirmwareClick: (name: string) => void;
}

const DevicesDownloadGateways: FC<Props> = ({
  downLoadingFirmwareType,
  onDownloadFirmwareClick,
}) => {
  const intl = useIntl();

  return (
    <>
      <H2 className={styles.dowloadHeader}>
        <FormattedMessage id="devices.downloads" defaultMessage="Downloads" />
      </H2>

      <div className={styles.downloadSections}>
        <div className={styles.downloadSection}>
          <H3>
            <FormattedMessage id="devices.beaconGateway" defaultMessage="Beacon Gateway" />
          </H3>
          <div className={cn(styles.buttonWrapper)}>
            <LargeButton
              label={intl.formatMessage({
                id: 'devices.downloadForWindows',
                defaultMessage: 'Download for Windows',
              })}
              loading={downLoadingFirmwareType === 'latestgatewaypc'}
              onClick={() => onDownloadFirmwareClick('latestgatewaypc')}
            >
              {windowsIcon}
            </LargeButton>
            <LargeButton
              label={intl.formatMessage({
                id: 'devices.downloadForMac',
                defaultMessage: 'Download for Mac',
              })}
              loading={downLoadingFirmwareType === 'latestgatewaymac'}
              onClick={() => onDownloadFirmwareClick('latestgatewaymac')}
            >
              {macIcon}
            </LargeButton>
          </div>
          <div className={styles.downloadDescription}>
            <H4>
              <FormattedMessage
                id="devices.downloadInstructions"
                defaultMessage="Download instructions"
              />
            </H4>
            <FormattedMessage
              id="devices.download11"
              defaultMessage="1. Download the Gateway software for your Beacon/Mega ISQ II (click button above)"
            />
            <br />
            <FormattedMessage
              id="devices.download12"
              defaultMessage="2. Wait for the download to complete (could take a while depending on your connection)"
            />
            <br />
            <FormattedMessage
              id="devices.download13"
              defaultMessage="3. Run the downloaded installation file"
            />
          </div>
        </div>

        <div className={styles.downloadSection}>
          <H3>
            <FormattedMessage id="devices.idxFirmware" defaultMessage="IDx firmware" />
          </H3>
          <div className={cn(styles.buttonWrapper)}>
            <LargeButton
              label={intl.formatMessage({
                id: 'devices.downloadFirmware',
                defaultMessage: 'Download firmware',
              })}
              loading={downLoadingFirmwareType === 'latestfirmware'}
              onClick={() => onDownloadFirmwareClick('latestfirmware')}
            >
              {IDxIcon}
            </LargeButton>
          </div>
          <div className={styles.downloadDescription}>
            <H4>
              <FormattedMessage
                id="devices.downloadInstructions"
                defaultMessage="Download instructions"
              />
            </H4>
            <FormattedMessage
              id="devices.download21"
              defaultMessage="1. Download latest firmware for your IDx (click button above)"
            />
            <br />
            <FormattedMessage
              id="devices.download22"
              defaultMessage="2. Wait for the download to complete (could take a while depending on your connection)"
            />
            <br />
            <FormattedMessage
              id="devices.download23"
              defaultMessage="3. Copy the .m2f binary file to a USB memory stick"
            />
            <br />
            <FormattedMessage
              id="devices.download24"
              defaultMessage="4. Insert the memory stick in your IDx while it is running"
            />
            <br />
            <FormattedMessage
              id="devices.download25"
              defaultMessage="5. Follow instructions on the IDx display"
            />
            <br />
            <div className={styles.noteContainer}>
              <FormattedMessage
                id="devices.downloadNote"
                defaultMessage="Note: you cannot open or view the .m2f file"
              />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DevicesDownloadGateways;
