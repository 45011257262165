import React from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Shared/Buttons';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

const DeleteAccountCompletePage: React.FC = () => {
  return (
    <StyledDeleteAccountCompletePage>
      <div className={'panel'}>
        <div className={'logo'}>
          <Logo />
        </div>
        <p>
          <FormattedMessage
            id="profile.accountDeletionComplete"
            defaultMessage="Your account have successfully been deleted."
          />
        </p>
        <Link to="/">
          <Button
            centered
            primary
            big
            full
            value={
              <FormattedMessage id="profile.backToMainPage" defaultMessage="Back to start page" />
            }
            leftIcon="back"
            onClick={() => {}}
          />
        </Link>
      </div>
    </StyledDeleteAccountCompletePage>
  );
};

const StyledDeleteAccountCompletePage = styled.div`
  width: 100%;
  height: 100%;
  background-color: #edeef7;

  .panel {
    margin: 0 auto;
    height: 100%;
    justify-content: center;
    min-width: 320px;
    max-width: 520px;
    flex-grow: 1;
  }

  .logo {
    svg {
      height: 80px;
      display: block;
      margin: 0 auto;
    }
  }
`;

export default DeleteAccountCompletePage;
