import { zeroPad } from '../../../utils/transformations';
import { dentalNotations } from '../../../constants/dentalNotations';

// @ts-ignore
export const graphics = require.context('../../../common/graphics/', true);

// prettier-ignore
const ZoneWidthsUpper = [55, 60, 77, 41,    46, 46, 40, 54,    58, 44, 47, 48,    31, 68, 64, 57];
// prettier-ignore
const ZoneWidthsLower = [64, 71, 76, 48,    41, 42, 42, 40,    42, 44, 42, 37,    36, 66, 66, 71];

const InteractionZoneWidths = [...ZoneWidthsUpper, ...ZoneWidthsLower];

// prettier-ignore
const ImplanteWidthsUpper = [70, 70, 70, 60,    60, 60, 40, 60,    60, 40, 60, 60,    60, 70, 70, 70];
// prettier-ignore
const ImplantWidthsLower = [70, 70, 70, 60,    60, 60, 40, 40,    40, 40, 60, 60,    60, 70, 70, 70];

const ImplantWidths = [...ImplanteWidthsUpper, ...ImplantWidthsLower];

export function calculateFinetuneOffset(index: number) {
  let offset;
  switch (index) {
    case 0:
    case 1:
      offset = 16;
      break;
    case 2:
    case 7:
    case 13:
    case 14:
    case 15:
      offset = 8;
      break;

    case 3:
    case 4:
    case 5:
    case 6:
    case 8:
    case 16:
      offset = 4;
      break;

    case 19:
    case 20:
    case 21:
    case 24:
    case 25:
    case 26:
    case 29:
    case 31:
      offset = -2;
      break;
    case 22:
      offset = 6;
      break;
    case 18:
      offset = -12;
      break;
    case 28:
      offset = 10;
      break;
    default:
      offset = 0;
      break;
  }
  return offset;
}

export const teethOffset: { x: number; w: number }[] = [
  // Upper
  { x: 11, w: 1 },
  { x: 12, w: 2 },
  { x: 14, w: -10 },
  { x: 2, w: 3 },
  { x: 4, w: 1 },
  { x: 4, w: -0 },
  { x: 4, w: 5 },
  { x: 7, w: 1 },
  { x: 8, w: -5 },
  { x: 2, w: 2 },
  { x: 3, w: 1 },
  { x: 4, w: -2 },
  { x: 1, w: 15 },
  { x: 15, w: -2 },
  { x: 12, w: -1 },
  { x: 10, w: -2 },
  // Lower
  { x: 23, w: 0 },
  { x: 19, w: -4 },
  { x: 14, w: -9 },
  { x: 3, w: -1 },
  { x: 1, w: 3 },
  { x: 2, w: 1 },
  { x: 0, w: 3 },
  { x: 1, w: 3 }, //8
  { x: 2, w: 0 },
  { x: 1, w: 0 },
  { x: 0, w: 1 },
  { x: 0, w: 5 },
  { x: 3, w: 13 },
  { x: 15, w: 2 },
  { x: 16, w: 2 },
  { x: 15, w: -6 },
];

// prettier-ignore
const upper = [62, 116, 180, 233,    276, 323, 360, 415,    470, 514, 565, 610,    652, 714, 775, 830];
// prettier-ignore
const lower = [68, 132, 200, 256,    298, 338, 375, 418,    458, 500, 548, 588,    632, 690, 760, 822];
//implants are rotated and a bit trickier to position perfectly, use this manual array instead
const positionCenters = [...upper, ...lower];

const TeethData = InteractionZoneWidths.map((zone, index) => ({
  id: index,
  url: graphics(
    `./consultation/teeth-${index < 16 ? 'upper' : 'lower'}-jaw/${zeroPad(
      (index % 16) + 1,
      2
    )}@2x.png`
  ),
  restoration_base: graphics(
    `./consultation/teeth-${index < 16 ? 'upper' : 'lower'}-jaw/${zeroPad(
      (index % 16) + 1,
      2
    )}_base.png`
  ),
  restoration_ext: graphics(
    `./consultation/teeth-${index < 16 ? 'upper' : 'lower'}-jaw/${zeroPad(
      (index % 16) + 1,
      2
    )}_extension.png`
  ),
  zoneWidth: zone,
  name: dentalNotations[index < 16 ? index : 47 - index],
})).map((zone, index, allTeeth) => {
  const upperRow = index < 16;
  const offset = allTeeth
    .filter((_, i) => (upperRow ? i < index : i >= 16 && i < index))
    .reduce((acc, current) => {
      return acc + current.zoneWidth;
    }, 0);
  return {
    ...zone,
    offset: offset,
    center: offset + zone.zoneWidth / 2,
    position: positionCenters[index],
    implantWidth: ImplantWidths[index],
  };
});

export default TeethData;
