import React, { SVGProps } from 'react';
import { lerp } from '../../../utils/transformations';

export type Point = [number, number];

const SvgPathPartial = ({
  points,
  command,
  moveToStart = true,
}: {
  points: Point[];
  command: (point: Point, i: number, array: Point[]) => string;
  moveToStart?: boolean;
}) =>
  points.reduce(
    (previous: string, point: Point, i: number, array: Point[]) =>
      i === 0
        ? `${moveToStart ? 'M' : 'L'} ${point[0]},${point[1]}`
        : `${previous} ${command(point, i, array)}`,
    ''
  );

interface Props extends Omit<SVGProps<SVGPathElement>, 'points'> {
  points: Point[];
  command: (point: Point, i: number, array: Point[]) => string;
  closed?: boolean;
}
const SvgPath: React.FC<Props> = ({ points, command, closed = false, ...props }) => {
  const d = SvgPathPartial({ points, command });
  return <path d={`${d} ${closed ? 'Z' : ''}`} stroke="grey" {...props} />;
};

const lineProperties = (pointA: Point, pointB: Point) => {
  const lengthX = pointB[0] - pointA[0];
  const lengthY = pointB[1] - pointA[1];
  return {
    length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
    angle: Math.atan2(lengthY, lengthX),
  };
};

const controlPoint = (smooth = 0.2, lineCalc: typeof lineProperties = lineProperties) => (
  current: Point,
  previous: Point,
  next: Point,
  reverse = false
) => {
  const prevPoint = previous || current;
  const nextPoint = next || current;
  const opposedLine = lineCalc(prevPoint, nextPoint);
  const angle = opposedLine.angle + (reverse ? Math.PI : 0);
  const length = opposedLine.length * smooth;
  const x = current[0] + Math.cos(angle) * length;
  const y = current[1] + Math.sin(angle) * length;
  return [x, y];
};

const lineCommand = (point: Point) => `L ${point[0]} ${point[1]}`;

const bezierCommand = (
  controlPointCalc: (current: Point, previous: Point, next: Point, reverse?: boolean) => number[]
) => (point: Point, i: number, array: Point[]) => {
  const [cpsX, cpsY] = controlPointCalc(array[i - 1], array[i - 2], point);
  const [cpeX, cpeY] = controlPointCalc(point, array[i - 1], array[i + 1], true);
  return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`;
};

const bezier = (smooth = 0.2) => bezierCommand(controlPoint(smooth));

const transformPointToXY = (point: Point) => ({
  x: point[0],
  y: point[1],
});
const transformXYToPoint = ({ x, y }: { x: number; y: number }) => [x, y];

const CurvePointsBuilder = ({
  start,
  middle,
  end,
  sharpness = 0.22,
}: {
  start: Point;
  middle: Point;
  end: Point;
  sharpness?: number;
}) => {
  const up: Point[] = [
    [lerp(start[0], middle[0], sharpness), lerp(start[1], middle[1], 1 - sharpness)],
  ];
  const down: Point[] = [
    [lerp(middle[0], end[0], 1 - sharpness), lerp(middle[1], end[1], sharpness)],
  ];
  return [
    [start[0], start[1]] as Point,
    ...up,
    [middle[0], middle[1]] as Point,
    ...down,
    [end[0], end[1]] as Point,
  ];
};

export {
  transformPointToXY,
  transformXYToPoint,
  controlPoint,
  lineProperties,
  lineCommand,
  bezierCommand,
  bezier,
  SvgPathPartial,
  CurvePointsBuilder,
};
export default SvgPath;
