import React, { Component } from 'react';
import cn from 'classnames';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import { color } from 'd3-color';
import { animated } from 'react-spring';
import { osstellChartTheme } from './chartTheme';
import styles from './Charts.module.scss';
const getTspanGroups = (value, maxLineLength, maxLines = 2) => {
  if (typeof value === 'number' && isFinite(value)) {
    if (Math.floor(value) !== value) return;
  }
  const words = String(value).split(' ');

  //reduces the words into lines of maxLineLength
  const assembleLines = words.reduce(
    (acc, word) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + ' ' + word,
      };
    },
    { lines: [], currLine: '' }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  //for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  let children = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={0} dy={dy} key={i} style={osstellChartTheme.axis.ticks.text}>
        {
          // if on the second line, and that line's length is within 3 of the max length, add ellipsis
          1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
        }
      </tspan>
    );
    //increment dy to render next line text below
    dy += 10;
  });

  return children;
};

const colors = ['#C749B3', '#562982', '#296A82', '#298267', '#822929'];

const gradients = colors.map((c, i) => {
  return linearGradientDef(i, [
    { offset: 0, color: c },
    { offset: 100, color: color(c).darker(1).formatHex() },
  ]);
});

const dataKeys = ['value'];

const fillMatch = (data) =>
  data.map((_d, i) => {
    return {
      match: (d) => {
        return d.data.index === i;
      },
      id: i,
    };
  });

const calcMax = (data) => Math.max(...data.map((d) => d.value));
class BarChart extends Component {
  constructor(props) {
    super();
    this.state = {
      layout: props.layout || 'vertical',
      data: props.data,
      maxVal: calcMax(props.data),
    };
    this.CustomBarComponent = this.CustomBarComponent.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const maxVal = calcMax(nextProps.data);
    if (maxVal > 0) {
      this.setState({ maxVal });
    }
  }
  CustomBarComponent(data) {
    const {
      x,
      y,
      width,
      height,
      //color,
      showTooltip,
      hideTooltip,
      label,
      data: {
        fill,
        id,
        index,
        data: { tooltip },
      },
    } = data;
    const { layout } = this.state;
    const maxSize = 25;
    const clipId = `${id}-${index}-${Math.random()}`;

    const proposedWidth = width > maxSize ? maxSize : width;
    const proposedHeight = height > maxSize ? maxSize : height;

    const adjustedWidth = layout === 'horizontal' ? width : proposedWidth;
    const adjustedHeight = layout === 'horizontal' ? proposedHeight : height;

    const adjustedX = layout === 'horizontal' ? x : x + (width - adjustedWidth) / 2;
    const adjustedY = layout === 'horizontal' ? y + (height - adjustedHeight) / 2 : y;

    const cornerRadius = 8;
    const clipWidth = layout === 'horizontal' ? adjustedWidth + cornerRadius - 1 : adjustedWidth;
    const clipHeight = layout === 'horizontal' ? adjustedHeight : adjustedHeight + cornerRadius;
    const clipX = layout === 'horizontal' ? adjustedX - cornerRadius : adjustedX;
    const clipY = adjustedY;

    const tooltipContent = (
      <div className={styles.tooltip}>
        {tooltip || label}
        {this.props.unit}
      </div>
    );

    return (
      <g
        onMouseMove={(event) => {
          showTooltip(tooltipContent, event);
        }}
        onMouseLeave={() => {
          hideTooltip();
        }}
      >
        <defs>
          <clipPath id={clipId}>
            <rect
              x={clipX}
              y={clipY}
              width={clipWidth}
              height={clipHeight}
              rx={cornerRadius}
              ry={cornerRadius}
            />
          </clipPath>
        </defs>
        <rect
          x={adjustedX}
          y={adjustedY}
          width={adjustedWidth}
          height={adjustedHeight}
          clipPath={`url(#${clipId})`}
          fill={fill}
        />
      </g>
    );
  }
  render() {
    return (
      <div className={styles.chartWrapper}>
        {this.props.yLegend && this.state.layout === 'vertical' && (
          <div className={cn(styles.legend, styles.legendTop)}>{this.props.yLegend}</div>
        )}
        <div style={{ height: '250px' }}>
          <ResponsiveBar
            theme={osstellChartTheme}
            barComponent={this.CustomBarComponent}
            data={this.props.data}
            maxValue={this.state.maxVal}
            keys={dataKeys}
            indexBy={(d) => d.label}
            margin={{
              top: 10,
              right: 10,
              bottom: 50,
              left: this.state.layout === 'horizontal' ? 70 : 20,
            }}
            padding={0.5}
            valueScale={{ type: 'linear' }}
            colors={colors}
            colorBy="index"
            fill={this.props.data ? fillMatch(this.props.data) : null}
            defs={gradients}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            layout={this.state.layout}
            enableGridX={this.state.layout === 'horizontal'}
            enableGridY={this.state.layout === 'vertical'}
            axisBottom={{
              tickSize: 0,
              tickPadding: 10,
              tickRotation: 0,
              legend: null,
              legendPosition: 'middle',
              legendOffset: 32,
              renderTick: ({ animatedProps, textAnchor, textBaseline, textX, textY, value }) => {
                return (
                  <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      style={osstellChartTheme.axis.ticks.text}
                      transform={`translate(${textX},${textY + 10})`}
                    >
                      {this.state.layout === 'vertical' && getTspanGroups(value, 10)}
                      {this.state.layout !== 'vertical' && getTspanGroups(value, 10)}
                    </text>
                  </animated.g>
                );
              },
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 2,
              tickRotation: 0,
              legend: null,
              legendPosition: 'end',
              legendOffset: -40,
              renderTick: ({ animatedProps, textAnchor, textBaseline, textX, textY, value }) => {
                return (
                  <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      style={osstellChartTheme.axis.ticks.text}
                      transform={`translate(${textX},${textY})`}
                    >
                      {this.state.layout === 'vertical' && getTspanGroups(value, 10)}
                      {this.state.layout !== 'vertical' && getTspanGroups(value, 10)}
                    </text>
                  </animated.g>
                );
              },
            }}
            enableLabel={false}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            isInteractive={true}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>

        {this.props.yLegend && this.state.layout === 'horizontal' && (
          <div className={cn(styles.legend, styles.legendBottom)}>{this.props.yLegend}</div>
        )}
      </div>
    );
  }
}

export default BarChart;
