import { ChangeEvent, FC, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Modal from '../Shared/Modal/Modal';
import { Button } from '../Shared/Buttons';
import { InputItem, InputRow, InputSubGroup } from '..//Shared/Forms/';
import { Table, TableBody, TR, TD } from '../../components/Shared/Table';
import {
  updateStudy,
  getUserId,
  getStudyGroups,
  deleteStudy,
} from '../../redux/slices/studyGroupSlice';
import { IStudyGroup } from '../../types/studyGroups';
import { unwrapResult } from '@reduxjs/toolkit';
import { studyMemberState } from '../../constants/defaultStates';
import { showConfirmation } from '../../redux/actions/generalActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';

interface Props {
  onDismiss?: () => void;
  study: IStudyGroup;
}

export const EditStudyModal: FC<Props> = ({ onDismiss, study }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);
  const [studyInformation, studyInformationSet] = useState<IStudyGroup>(study);
  const [invites, invitesSet] = useState([...study.members]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  const [inviteEmail, inviteEmailSet] = useState<string>('');
  const [inviteError, inviteErrorSet] = useState<string | undefined>();

  const onAdd = () => {
    if (inviteEmail === '') {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.enterEmail',
          defaultMessage: "Enter a user's email address.",
        })
      );
    } else if (invites.some((x) => x.email === inviteEmail)) {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.userAlreadyInvited',
          defaultMessage: 'The user is already invited.',
        })
      );
    } else if (inviteEmail === user.email) {
      inviteErrorSet(
        intl.formatMessage({
          id: 'study.inviteOwner',
          defaultMessage: 'Not possible to invite yourself.',
        })
      );
    } else {
      dispatch(getUserId(inviteEmail))
        .then(unwrapResult)
        .then((userId) => {
          const newInvite = studyMemberState;
          invitesSet([...invites, { ...newInvite, email: inviteEmail, userId }]);
        })
        .catch((error) =>
          error.code === '404'
            ? inviteErrorSet(
                intl.formatMessage({
                  id: 'study.userDoesNotExist',
                  defaultMessage: 'The user does not exist',
                })
              )
            : console.log('Error', error)
        );
    }
  };

  return (
    <Modal
      header={intl.formatMessage({ id: 'study.editStudy', defaultMessage: 'Edit Study' })}
      show={true}
      onDismiss={onDismiss}
    >
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'study.studyName',
            defaultMessage: 'Study Name',
          })}
          type="text"
          onWhite
          value={studyInformation.name}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            studyInformationSet({ ...studyInformation, ...{ name: evt.target.value } })
          }
        />
      </InputRow>
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'study.studyDescription',
            defaultMessage: 'Study Description',
          })}
          type="textarea"
          onWhite
          value={studyInformation.description}
          onChange={(evt: ChangeEvent<HTMLInputElement>) =>
            studyInformationSet({ ...studyInformation, ...{ description: evt.target.value } })
          }
        />
      </InputRow>
      <div>
        <InputRow>
          <StyledEditStudyRow>
            <InputItem
              label={intl.formatMessage({
                id: 'study.inviteMemeber',
                defaultMessage: 'Invite Members',
              })}
              type="text"
              onWhite
              value={inviteEmail}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                inviteEmailSet(evt.target.value);
                inviteError && inviteErrorSet(undefined);
              }}
              placeholder={intl.formatMessage({
                id: 'general.email',
                defaultMessage: 'Email',
              })}
            />
            <Button primary onClick={onAdd} value={<i>add</i>} />
          </StyledEditStudyRow>
        </InputRow>
        {inviteError && <ErrorText>{inviteError}</ErrorText>}

        {invites.length > 0 && (
          <InputSubGroup
            label={intl.formatMessage({
              id: 'study.members',
              defaultMessage: 'Members',
            })}
          >
            <Table>
              <TableBody>
                {invites.map((invite) => {
                  return (
                    <TR key={invite.email}>
                      <TD>{invite.email}</TD>
                      <TD>{invite.userPermission}</TD>
                      <TD>
                        {invite.userPermission !== 'Admin' && (
                          <IconButton
                            onClick={() =>
                              dispatch(
                                showConfirmation(
                                  intl.formatMessage({
                                    id: 'study.removeUserFromStudy',
                                    defaultMessage:
                                      'Are you sure you want to remove the user from this group?',
                                  }),
                                  <></>,
                                  () =>
                                    invitesSet([
                                      ...invites.filter((x) => x.userId !== invite.userId),
                                    ])
                                )
                              )
                            }
                          >
                            <i>delete</i>
                          </IconButton>
                        )}
                      </TD>
                    </TR>
                  );
                })}
              </TableBody>
            </Table>
          </InputSubGroup>
        )}
      </div>

      <Buttons>
        <Button
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={onDismiss}
        />
        <Button
          value={intl.formatMessage({
            id: 'study.delete',
            defaultMessage: 'Delete',
          })}
          onClick={() =>
            dispatch(
              showConfirmation(
                intl.formatMessage({
                  id: 'study.deleteStudyConfirmation',
                  defaultMessage: 'Are you sure you want to delete this study?',
                }),
                <></>,
                () => {
                  setDeleting(true);
                  dispatch(deleteStudy(study.id)).then(() => {
                    setDeleting(false);
                    dispatch(getStudyGroups(false));
                    onDismiss?.();
                  });
                }
              )
            )
          }
          loading={deleting}
          disabled={deleting || updating}
        />
        <Button
          primary
          value={intl.formatMessage({ id: 'general.update', defaultMessage: 'Update' })}
          onClick={() => {
            setUpdating(true);
            const studyGroup = {
              ...studyInformation,
              ...{ invites: invites.map((x) => x.userId) },
            };
            dispatch(updateStudy(studyGroup)).then(() => {
              setUpdating(false);
              dispatch(getStudyGroups(false));
              onDismiss?.();
            });
          }}
          disabled={!studyInformation.name || !studyInformation.description || updating || deleting}
          loading={updating}
        />
      </Buttons>
    </Modal>
  );
};

const IconButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 2rem;
  }
`;

const StyledEditStudyRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  div:first-child {
    width: 97%;
    margin: 0px;
  }
  button {
    height: 48px;
    width: 50px !important;
    min-width: unset;
    div {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    i {
      color: white;
      font-size: 35px;
    }
  }
`;

const ErrorText = styled.div`
  color: var(--color-warning);
`;

const Buttons = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 750px) {
    flex-direction: column;
    button {
      margin: 0px;
      margin-top: 10px;
    }
  }
`;
