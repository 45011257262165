import { H2 } from '../../components/Shared/Headings';
import { FC } from 'react';
import { Button } from '../../components/Shared/Buttons';
import { showVideo } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../../utils/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as LogoCommunity } from '../../shared/icons/assets-logo_community.svg';
import NewsConsultationTool from '../../shared/images/news/NewsTile_ConsultationTool_1.1.jpg';
import JeffGanelesBlogPost from '../../shared/images/news/Jeff-Ganeles-Blog-Post-V3_adjusted.png';
import NewsEducation from '../../shared/images/news/NewsTile_Education.jpg';
import NewsCommunity from '../../shared/images/news/NewsTile_Community.jpg';
import NewsTile from '../../components/Tile/NewsTile';
import styles from './HomePage.module.scss';

interface Props {
  videoUrl: string;
}

const LinksSection: FC<Props> = ({ videoUrl }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  return (
    <>
      <H2>
        <FormattedMessage id="home.links" defaultMessage="Links" />
      </H2>
      <div>
        <div
          className={styles.linkContainer}
          onClick={() =>
            window.open(
              'https://www.osstell.com/blog/improving-patient-acceptance-of-dental-implant-treatment-with-osstellconnect/'
            )
          }
        >
          <div className={styles.imgHolder}>
            <img src={JeffGanelesBlogPost} alt={JeffGanelesBlogPost} />
          </div>
          <div className={styles.contentHolder}>
            <p className={styles.header}>Blog Post with Dr. Jeffrey Ganeles</p>
            <p>
              <FormattedMessage
                id="home.jeffGanelesBlogPost"
                defaultMessage="Improving patient acceptence of dental implant treatment with OsstellConnect."
              />
              <br />
              <FormattedMessage
                id="home.ConsultationToolInfoPlace"
                defaultMessage="Overcome the patient's concerns with transparent treatment explanations."
              />
            </p>
            <Button
              primary
              value={intl.formatMessage({
                id: 'home.readBlogPost',
                defaultMessage: 'Read Blog Post',
              })}
            />
          </div>
        </div>
        <NewsTile
          onClick={() => dispatch(showVideo(videoUrl, 'ConsultationTool'))}
          background={NewsConsultationTool}
          removeBackgroundOnMobile
        >
          <div className={styles.newsContent}>
            <p className={styles.header}>ConsultationTool</p>
            <p>
              <FormattedMessage
                id="home.ConsultationToolInfo"
                defaultMessage="ConsultationTool is a user-friendly and detailed software tool that helps you present
                  individualized treatment options to your patients."
              />
              <br />
              <FormattedMessage
                id="home.ConsultationToolInfoPlace"
                defaultMessage="Access it from the top right corner in a patient record."
              />
            </p>
            <Button
              primary
              value={intl.formatMessage({
                id: 'home.watchDemo',
                defaultMessage: 'Watch Demo',
              })}
            />
          </div>
        </NewsTile>
        <NewsTile
          onClick={() => window.open('https://www.osstellcampus.com/')}
          background={NewsEducation}
          removeBackgroundOnMobile
        >
          <div className={styles.newsContent}>
            <p className={styles.header}>
              <FormattedMessage id="home.CampusNews" defaultMessage="Osstell Online Campus" />
            </p>
            <p>
              <FormattedMessage
                id="home.CampusNewsText"
                defaultMessage="Learn from world-renowned speakers about the use of Osstell ISQ diagnostics in various treatment indications, illustrated by cases and clinical evidence."
              />
            </p>
            <Button
              primary
              value={intl.formatMessage({
                id: 'home.CampusNewsButton',
                defaultMessage: 'Earn CE credits',
              })}
            />
          </div>
        </NewsTile>
        <NewsTile
          onClick={() => history.push('community')}
          background={NewsCommunity}
          removeBackgroundOnMobile
        >
          <div className={styles.newsContent}>
            <div className={styles.header}>
              <div className={styles.logoCommunity}>
                <LogoCommunity />
              </div>
            </div>
            <p>
              <FormattedMessage
                id="home.CommunityNewsText"
                defaultMessage="Share your valuable feedback and suggestions to further improve the experience of OsstellConnect."
              />
            </p>
            <Button
              primary
              value={intl.formatMessage({
                id: 'home.CommunityNewsButton',
                defaultMessage: 'Share ideas',
              })}
            />
          </div>
        </NewsTile>
      </div>
    </>
  );
};

export default LinksSection;
