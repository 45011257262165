import { IClinic } from '../../../types/clinic';
import { RootState } from '../../store';
import { HttpMethods } from '../../../constants/enums';
import { ICustomError } from '../../../types/customError';
import { createHeaders } from '../createHeaders';
import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface ClinicInviteRequest {
  userGroupId: string;
  email: string;
  permission: string;
}

export const clinicApi = createApi({
  reducerPath: 'clinicApi',
  tagTypes: ['Clinic'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).account.token;

      headers = createHeaders(token);
      return headers;
    },
  }) as BaseQueryFn<string | FetchArgs, unknown, ICustomError, Record<string, unknown>>,
  endpoints: (builder) => ({
    getClinic: builder.query<IClinic, void>({
      query: () => '/clinic',
      providesTags: ['Clinic'],
    }),
    sendClinicInvite: builder.mutation<ICustomError, ClinicInviteRequest>({
      query: (body) => ({
        url: '/clinic/invite',
        method: HttpMethods.POST,
        body,
      }),
      invalidatesTags: ['Clinic'],
    }),
    deleteClinicInvite: builder.mutation<void, { userGroupId: string; email: string }>({
      query(body) {
        return {
          url: `/clinic/invite`,
          method: HttpMethods.DELETE,
          body,
        };
      },
      invalidatesTags: ['Clinic'],
    }),
    deleteClinicUser: builder.mutation<void, { userGroupId: string; userId: string | undefined }>({
      query({ userGroupId, userId }) {
        return {
          url: `clinic/${userGroupId}/${userId}`,
          method: HttpMethods.DELETE,
        };
      },
      invalidatesTags: ['Clinic'],
    }),
    getUserClinics: builder.query<{ userGroupId: string; name: string }[], void>({
      query: () => '/clinic/all',
    }),
    switchClinic: builder.mutation<string, string>({
      query(body) {
        return {
          url: `/users/usergroup?userGroupId=${body}`,
          method: HttpMethods.PUT,
        };
      },
      invalidatesTags: ['Clinic'],
    }),
  }),
});

export const {
  useGetClinicQuery,
  useSendClinicInviteMutation,
  useDeleteClinicInviteMutation,
  useDeleteClinicUserMutation,
  useGetUserClinicsQuery,
  useSwitchClinicMutation,
} = clinicApi;
