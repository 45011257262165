import * as types from '../../constants/actionTypes';

const initialState = {
  getInstruments: {
    complete: false,
    error: undefined,
    busy: false,
  },
  registerInstrument: {
    complete: false,
    error: undefined,
    busy: false,
    instrument: undefined,
  },
  deleteInstruments: {
    complete: false,
    error: undefined,
    busy: false,
  },
  setInstrumentExpectedFirmwares: {
    complete: false,
    error: undefined,
    busy: false,
  },
  shareInstrumentPatients: {
    complete: false,
    error: undefined,
    busy: false,
    sharePatients: undefined,
  },
  setInstrumentsType: {
    complete: false,
    error: undefined,
    busy: false,
  },
  setInstrumentsComment: {
    complete: false,
    error: undefined,
    busy: false,
  },
  setInstrumentName: {
    complete: false,
    error: undefined,
    busy: false,
  },
  activeInstrument: {
    Active: false,
    InstrumentId: undefined,
    SerialNumber: undefined,
    NextDir: undefined,
  },
  instruments: [],
};

export default function patient(state = initialState, action) {
  let registerInstrumentCopy;
  switch (action.type) {
    case types.GET_INSTRUMENTS:
      return Object.assign({}, state, {
        getInstruments: { complete: false, error: undefined, busy: true },
        instruments: [],
      });
    case types.GET_INSTRUMENTS_SUCCESS:
      return Object.assign({}, state, {
        getInstruments: {
          complete: true,
          error: undefined,
          busy: false,
        },
        instruments: action.instruments,
      });
    case types.GET_INSTRUMENTS_ERROR:
      return Object.assign({}, state, {
        getInstruments: { complete: false, error: action.error, busy: false },
        instruments: [],
      });
    case types.REGISTER_INSTRUMENT:
      return Object.assign({}, state, {
        registerInstrument: {
          complete: false,
          error: undefined,
          busy: true,
          instrument: undefined,
        },
      });
    case types.REGISTER_INSTRUMENT_SUCCESS:
      return Object.assign({}, state, {
        registerInstrument: {
          complete: true,
          error: undefined,
          busy: false,
          instrument: action.instrument,
        },
      });
    case types.REGISTER_INSTRUMENT_ERROR:
      return Object.assign({}, state, {
        registerInstrument: {
          complete: false,
          error: action.error,
          busy: false,
          instrument: undefined,
        },
      });
    case types.DELETE_INSTRUMENTS:
      return Object.assign({}, state, {
        deleteInstruments: { complete: false, error: undefined, busy: true },
      });
    case types.DELETE_INSTRUMENTS_SUCCESS:
      return Object.assign({}, state, {
        deleteInstruments: { complete: true, error: undefined, busy: false },
      });
    case types.DELETE_INSTRUMENTS_ERROR:
      return Object.assign({}, state, {
        deleteInstruments: { complete: false, error: action.error, busy: false },
      });
    case types.SET_INSTRUMENT_EXPECTED_FIRMWARES:
      return Object.assign({}, state, {
        setInstrumentExpectedFirmwares: { complete: false, error: undefined, busy: true },
      });
    case types.SET_INSTRUMENT_EXPECTED_FIRMWARES_SUCCESS:
      return Object.assign({}, state, {
        setInstrumentExpectedFirmwares: { complete: true, error: undefined, busy: false },
      });
    case types.SET_INSTRUMENT_EXPECTED_FIRMWARES_ERROR:
      return Object.assign({}, state, {
        setInstrumentExpectedFirmwares: { complete: false, error: action.error, busy: false },
      });
    case types.SHARE_INSTRUMENT_PATIENTS:
      return Object.assign({}, state, {
        shareInstrumentPatients: {
          complete: false,
          error: undefined,
          busy: true,
          sharePatients: action.sharePatients,
        },
      });
    case types.SHARE_INSTRUMENT_PATIENTS_SUCCESS:
      return Object.assign({}, state, {
        shareInstrumentPatients: { complete: true, error: undefined, busy: false },
      });
    case types.SHARE_INSTRUMENT_PATIENTS_ERROR:
      return Object.assign({}, state, {
        shareInstrumentPatients: { complete: false, error: action.error, busy: false },
      });
    case types.SET_INSTRUMENTS_TYPE:
      return Object.assign({}, state, {
        setInstrumentsType: { complete: false, error: undefined, busy: true },
      });
    case types.SET_INSTRUMENTS_TYPE_SUCCESS:
      return Object.assign({}, state, {
        setInstrumentsType: { complete: true, error: undefined, busy: false },
      });
    case types.SET_INSTRUMENTS_TYPE_ERROR:
      return Object.assign({}, state, {
        setInstrumentsType: { complete: false, error: action.error, busy: false },
      });
    case types.SET_INSTRUMENTS_COMMENT:
      return Object.assign({}, state, {
        setInstrumentsComment: { complete: false, error: undefined, busy: true },
      });
    case types.SET_INSTRUMENTS_COMMENT_SUCCESS:
      return Object.assign({}, state, {
        setInstrumentsComment: { complete: true, error: undefined, busy: false },
      });
    case types.SET_INSTRUMENTS_COMMENT_ERROR:
      return Object.assign({}, state, {
        setInstrumentsComment: { complete: false, error: action.error, busy: false },
      });
    case types.SET_INSTRUMENT_NAME:
      return Object.assign({}, state, {
        setInstrumentName: { complete: false, error: undefined, busy: true },
      });
    case types.SET_INSTRUMENT_NAME_SUCCESS:
      return Object.assign({}, state, {
        setInstrumentName: { complete: true, error: undefined, busy: false },
      });
    case types.SET_INSTRUMENT_NAME_ERROR:
      return Object.assign({}, state, {
        setInstrumentName: { complete: false, error: action.error, busy: false },
      });
    case types.CLEAR_REGISTER_INSTRUMENT:
      registerInstrumentCopy = Object.assign({}, state.registerInstrument);
      registerInstrumentCopy.puk = '';
      registerInstrumentCopy.error = undefined;
      return Object.assign({}, state, { registerInstrument: registerInstrumentCopy });
    case types.CLEAR_INSTRUMENTS:
      return Object.assign({}, state, initialState);
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    case types.RECEIVE_INSTRUMENT_STATUS:
      //If active instrument disconnects, set initial state
      if (
        !action.activeInstrument.Active &&
        action.activeInstrument.InstrumentId === state.activeInstrument.InstrumentId
      ) {
        return Object.assign({}, state, { activeInstrument: initialState.activeInstrument });
      } else if (action.activeInstrument.Active) {
        return Object.assign({}, state, { activeInstrument: action.activeInstrument });
      }
      return state;
    default:
      return state;
  }
}
