import { useIntl } from 'react-intl';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector, useOnClickOutside } from '../../../../../../utils/hooks';
import { MODAL_SAVE } from '../../../../../../redux/slices/UISlice';
import styled from 'styled-components';
import Prompt from '../../../../../Shared/Modals/Prompt';
import {
  Center,
  OverlayBoxShadow,
} from '../../../../../../common/graphics/lib/StyledComponentLibrary';
import { InputGroup, InputItem } from '../../../../../Shared/Forms';
import { Button } from '../../../../../Shared/Buttons';
import { storeConsultation } from '../../../../../../redux/slices/apiSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  SET_DIRTY,
  SET_ID,
  SET_NAME,
  UPDATE_STEP,
  RESET,
} from '../../../../../../redux/slices/procedureSlice';
import { RESET as RESET_WORKSPACE } from '../../../../../../redux/slices/workspaceSlice';
import { showAlert } from '../../../../../../redux/actions/generalActions';
import { appInsights } from '../../../../../../utils/applicationInsights';

interface Props {
  userGroupId?: string;
  patientId?: string;
  takeScreenshot?: () => Promise<string | undefined>;
}

const SavePrompt: FC<Props> = ({ patientId, userGroupId, takeScreenshot }) => {
  const intl = useIntl();
  const promptRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state) => state.user.user);
  const dispatch = useAppDispatch();
  const procedure = useAppSelector((state) => state.consultation.procedure);
  const consultationId = useAppSelector((state) => state.consultation.procedure.consultationId);
  const workspacePresent = useAppSelector((state) => state.consultation.workspace.present);
  const showModal = useAppSelector((state) => state.consultation.view.modal.saveOpen);
  const configurationName = useAppSelector((state) => state.consultation.procedure.name);

  const configurationHasUnsavedChanges = useAppSelector(
    (state) => state.consultation.procedure.configurationHasUnsavedChanges
  );

  const inactivityWarning = useAppSelector((state) => state.account.inactivityWarning);

  const [name, setName] = useState<string>('');
  const [saving, setSaving] = useState<boolean>(false);

  useOnClickOutside(promptRef, () => dispatch(MODAL_SAVE(false)));

  useEffect(() => {
    if (inactivityWarning && configurationHasUnsavedChanges) {
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'general.warning',
            defaultMessage: `Warning`,
          }),
          intl.formatMessage({
            id: 'consultation.inactivityAlert',
            defaultMessage: `You will soon be logged out due to inactivity, please save your consultation.`,
          }),
          () => dispatch(MODAL_SAVE(true))
        )
      );
    }
  }, [inactivityWarning]);

  useEffect(() => {
    setName(configurationName ?? '');
  }, [configurationName, setName]);

  async function handleConfigurationSave(name: string, createNew = true) {
    setSaving(true);
    try {
      if (takeScreenshot) {
        const screenshot = await takeScreenshot();

        dispatch(
          UPDATE_STEP({
            updateData: {
              state: workspacePresent,
              previewImageSrc: screenshot,
            },
          })
        );
      }

      await dispatch(
        storeConsultation({
          userGroupId: userGroupId ?? '',
          patientId: patientId ?? '',
          name: name,
          createdBy: user.id,
          consultationId: createNew ? '' : procedure.consultationId,
        })
      )
        .then(unwrapResult)
        .then((consultationId) => {
          dispatch(SET_ID(consultationId));
          dispatch(SET_NAME(name));
          dispatch(MODAL_SAVE(false));
          dispatch(SET_DIRTY(false));
          setSaving(false);
          appInsights.trackEvent({
            name: 'consultation',
            properties: {
              type: 'savedasnew',
              email: user.email,
            },
          });
        });
    } catch (e) {
      setSaving(false);
      console.error(e);
      return Promise.reject(e);
    }
  }

  const resetConsultation = () => {
    dispatch(RESET());
    dispatch(RESET_WORKSPACE());
  };

  return (
    <>
      {showModal ? (
        <StyledPrompt ref={promptRef} xOffset={'144px'}>
          <InputGroup>
            <InputItem
              dataCy="consultation-save-modal-input"
              label={intl.formatMessage({
                id: 'consultation.configuration.save.nameprompt',
                defaultMessage: 'Consultation name',
              })}
              type="text"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => setName(e.target.value)}
              value={name}
              placeholder={'none'}
              noPadding
              onWhite
            />
          </InputGroup>
          <section>
            <Button
              dataCy="consultation-save-modal-button"
              loading={saving}
              disabled={name === '' || saving}
              centered
              primary
              full
              value={intl.formatMessage({
                id: 'general.saveAsNew',
                defaultMessage: 'Save as new',
              })}
              onClick={() => handleConfigurationSave(name, true)}
            />
          </section>
        </StyledPrompt>
      ) : null}
      <Prompt
        onNav={() => resetConsultation()}
        when={configurationHasUnsavedChanges}
        title={intl.formatMessage({
          id: 'consultation.unsavedChanges',
          defaultMessage: 'Unsaved changes',
        })}
        content={intl.formatMessage({
          id: 'consultation.saveChanges?',
          defaultMessage: 'Would you like to save changes before leaving?',
        })}
        cancelText={intl.formatMessage({
          id: 'consultation.doNotSave',
          defaultMessage: 'Do not save',
        })}
        okText={intl.formatMessage({
          id: 'general.save',
          defaultMessage: 'Save',
        })}
        onOK={async () => {
          if (consultationId) {
            await handleConfigurationSave(configurationName, false).then(() => resetConsultation());
            return true;
          } else {
            dispatch(MODAL_SAVE(true));
            return false;
          }
        }}
        onCancel={() => {
          resetConsultation();
          return true;
        }}
        onDismiss={() => false}
      />
    </>
  );
};

export const StyledPrompt = styled.div<{ xOffset: string }>`
  ${Center};
  ${OverlayBoxShadow};

  display: flex;
  flex-direction: column;
  position: fixed;
  top: 70px;
  right: ${(props) => props.xOffset};
  z-index: 90000000;

  padding: 1rem;
  background-color: white;
  border-radius: 5px;

  & span.table-head-row {
    color: var(--color-purple);
    font-size: 1rem;
    font-weight: normal;
  }

  section {
    max-width: 330px;
    display: flex;
    & :first-child {
      margin-right: 0.5rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 0.5rem;
    align-items: center;
    max-width: 700px;
    & li {
      padding: 1rem;
    }

    & li:hover {
      color: ${(props) => props.theme.colors.purple};
    }
  }
`;

export default SavePrompt;
