import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useIntl } from 'react-intl';
import { UserPermissionEnum } from '../../../constants/enums';
import { FormikValues } from 'formik';
import { FC } from 'react';

interface Props {
  disabled?: boolean;
  formik: FormikValues;
}

const UserPermissionSelect: FC<Props> = ({ disabled, formik }) => {
  const intl = useIntl();

  return (
    <FormControl fullWidth size={'small'}>
      <InputLabel>
        {intl.formatMessage({
          id: 'general.userPermission',
          defaultMessage: 'User permission',
        })}
      </InputLabel>
      <Select
        id="permission"
        label={intl.formatMessage({
          id: 'general.userPermission',
          defaultMessage: 'User permission',
        })}
        disabled={disabled}
        error={formik.touched.permission && Boolean(formik.errors.permission)}
        {...formik.getFieldProps('permission')}
      >
        <MenuItem value={UserPermissionEnum.USER}>
          {intl.formatMessage({
            id: 'general.user',
            defaultMessage: 'User',
          })}
        </MenuItem>
        <MenuItem value={UserPermissionEnum.ADMIN}>
          {intl.formatMessage({
            id: 'general.admin',
            defaultMessage: 'Admin',
          })}
        </MenuItem>
      </Select>
      {formik.touched.permission && formik.errors.permission && (
        <FormHelperText error>{formik.errors.permission}</FormHelperText>
      )}
    </FormControl>
  );
};

export default UserPermissionSelect;
