import * as types from '../../constants/actionTypes';

const initialState = {
    internalStatistics: {
        complete: false,
        error: undefined,
        busy: false,
        statistics: {},
    },
    userStatistics: {
      complete: false,
      error: undefined,
      busy: false,
      statistics: {},
  }
};

export default function statistics(state = initialState, action) {
  switch (action.type) {
    case types.GET_INTERNAL_STATS:
      return Object.assign({}, state, {
        internalStatistics: { complete: false, error: undefined, busy: true, statistics: {} },
      });
    case types.GET_INTERNAL_STATS_SUCCESS:
      return Object.assign({}, state, {
        internalStatistics: { complete: true, error: undefined, busy: false, statistics: action.statistics },
      });
    case types.GET_INTERNAL_STATS_ERROR:
      return Object.assign({}, state, {
        internalStatistics: { complete: false, error: action.error, busy: false, statistics: {} },
      });
    case types.GET_USER_STATS:
      return Object.assign({}, state, {
        userStatistics: { complete: false, error: undefined, busy: true, statistics: {} },
      });
    case types.GET_USER_STATS_SUCCESS:
      return Object.assign({}, state, {
        userStatistics: { complete: true, error: undefined, busy: false, statistics: action.statistics },
      });
    case types.GET_USER_STATS_ERROR:
      return Object.assign({}, state, {
        userStatistics: { complete: false, error: action.error, busy: false, statistics: {} },
      });
    default:
      return state;
  }
}
