import * as React from 'react';
interface SvgWithTilted extends React.SVGProps<SVGSVGElement> {
  tilted?: boolean;
}
function SvgImplantliga({ tilted, ...props }: SvgWithTilted) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 845 2048"
      {...props}
      transform={tilted ? 'rotate(30)' : ''}
    >
      <path d="M724 147q9 0 15.5 6t6.5 15v136q0 9-6 15L635 428q-5 5-9 6L231 547q-2 1-6 1-16 0-21-15-2-8 2.5-16.5T219 506l389-112 95-99V189H145v106l47 49q14 16-1 30-16 14-30-1l-53-54q-6-6-6-15V168q0-9 6.5-15t15.5-6h600zM226 753q-16 0-21-15-2-8 2.5-16.5T220 711l392-113q8-2 16.5 2t10.5 12-2.5 16.5T624 639L232 752q-2 1-6 1zm0 209q-16 0-21-16-2-8 2.5-16t12.5-10l392-114q8-2 16.5 2.5T639 821t-2.5 16-12.5 10L232 961q-2 1-6 1zm0 208q-16 0-21-15-2-8 2.5-16.5T220 1128l392-113q8-2 16.5 2.5T639 1030t-2.5 16-12.5 10l-392 113q-2 1-6 1zm0 209q-16 0-21-16-2-8 2.5-16t12.5-10l392-113q8-2 16.5 2t10.5 12-2.5 16-12.5 10l-392 114q-2 1-6 1zm393 223q9 0 15 6t6 15v35q0 55-46 82-82 41-172 41t-170-40q-47-28-47-83v-117l407-118q8-2 16.5 2t10.5 12-2.5 16.5T624 1464l-377 109v85q0 30 26 45 68 33 149 33.5t151-33.5q24-15 24-45v-35q0-9 6.5-15t15.5-6z" />
    </svg>
  );
}

export default SvgImplantliga;
