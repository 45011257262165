import { toastr } from 'react-redux-toastr';
import { getUser } from '../../redux/actions/userActions';
import { useHistory } from 'react-router-dom';
import { IPromotion } from '../../types/promotion';
import { toastrOptions } from '../../constants/toastrOptions';
import { showConfirmation } from '../../redux/actions/generalActions';
import { Button, LargeButton } from '../../components/Shared/Buttons';
import { InputItem, InputRow } from '../../components/Shared/Forms';
import { FormattedMessage, useIntl } from 'react-intl';
import { clearToken, logoutAccount } from '../../redux/actions/accountActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { ChangeEvent, FC, Fragment, useEffect, useRef, useState } from 'react';
import { downloadFirmware as downloadFirmwareRequest } from '../../redux/actions/firmwareActions';
import {
  clearRegisterInstrument,
  setInstrumentName as setInstrumentNameRequest,
  registerInstrument as registerInstrumentRequest,
} from '../../redux/actions/instrumentActions';
import beaconSerialNumber from './Images/beaconSerialNumber.png';
import ChangeDeviceName from './ChangeDeviceName';
import idxSerialNumber from './Images/idxSerialNumber.png';
import installGateway from './Images/installGateway.png';
import megaISQImage from './Images/megaISQ.png';
import beaconImage from './Images/beacon.png';
import beaconCode from '../../shared/videos/beaconCode.mp4';
import idxImage from './Images/idx.png';
import styles from './RegisterInstrumentModal.module.scss';
import Modal from '../../components/Shared/Modal/Modal';
import cn from 'classnames';

import {
  windowsIcon,
  macIcon,
  connectKeyIllustration,
  connectionSymbol,
  idxCode,
} from '../../constants/registerIllustrations';

interface IRegisterStep {
  action: () => void;
  disabled: () => boolean;
  buttonLabel: string;
  buttonIcon: string;
  isStep: boolean;
  name: string;
  loading?: () => boolean;
  optional?: boolean;
}

interface IRegisterSteps {
  [key: string]: IRegisterStep;
}

interface IDeviceNames {
  [key: string]: string;
}

const deviceNames: IDeviceNames = {
  Beacon: 'Osstell Beacon',
  MegaISQ: 'MEGA ISQ II',
  IDx: 'Osstell IDX',
};

interface IStepList {
  [key: string]: string[];
}

interface Props {
  show: boolean;
  hide: (reloadInstruments: boolean) => void;
  actAsOtherId: string | null;
  logoutAtCancel?: boolean;
  fullModal?: boolean;
  demo?: boolean;
}

const RegisterInstrumentModal: FC<Props> = ({
  show,
  hide,
  actAsOtherId,
  logoutAtCancel,
  fullModal,
  demo,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { user } = useAppSelector((state) => state.user);
  const { downloadFirmware } = useAppSelector((state) => state.firmware);
  const { registerInstrument, activeInstrument } = useAppSelector((state) => state.instrument);

  const [serialNumber, setSerialNumber] = useState('');
  const [registrationCode, setRegistrationCode] = useState('');
  const [instrumentName, setInstrumentName] = useState('');
  const [puk, setPuk] = useState('');
  const [instrumentId, setInstrumentId] = useState('');
  const [selectedInstrument, setSelectedInstrument] = useState<string | undefined>(undefined);
  const [currentStepCount, setCurrentStepCount] = useState(0);
  const [currentStep, setCurrentStep] = useState<IRegisterStep | null>(null);
  const [currentRegisterStepCount, setCurrentRegisterStepCount] = useState(0);
  const [maxSteps, setMaxSteps] = useState(0);
  const [currentStepList, setCurrentStepList] = useState<string[]>([]);
  const [downLoadingFirmwareType, setDownLoadingFirmwareType] = useState<string | null>(null);
  const [error, setError] = useState<{ messages: { text: string; key: string }[] } | null>(null);

  const [isDownloadingFirmware, setIsDownloadingFirmware] = useState(false);
  const [isFirmwareDownloaded, setIsFirmwareDownloaded] = useState(false);
  const [isInstrumentRegistered, setIsInstrumentRegistered] = useState(false);

  const vidRef = useRef(null);

  const stepList: IStepList = {
    Beacon: ['select', 'download', 'install', 'connect', 'serial', 'registrationCode', 'naming'],
    MegaISQ: ['select', 'download', 'install', 'connect', 'serial', 'registrationCode', 'naming'],
    IDx: ['select', 'serial', 'registrationCode', 'puk', 'naming'],
    BeaconApp: ['select', 'pendingInstrument', 'naming'],
  };

  interface IFormValidation {
    [key: string]: { serial: number; regCode: number };
  }

  const formValidation: IFormValidation = {
    Beacon: { serial: 6, regCode: 4 },
    MegaISQ: { serial: 6, regCode: 4 },
    IDx: { serial: 5, regCode: 4 },
  };

  const registerSteps: IRegisterSteps = {
    select: {
      action: () => incrementStep(),
      disabled: () => !selectedInstrument || registerInstrument.busy || demo,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: false,
      name: 'select',
    },
    download: {
      action: () => incrementStep(),
      disabled: () => !selectedInstrument || !isFirmwareDownloaded,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'download',
    },
    install: {
      action: () => incrementStep(),
      disabled: () => !selectedInstrument,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'install',
    },
    connect: {
      action: () => incrementStep(),
      disabled: () => !selectedInstrument,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'connect',
    },
    serial: {
      action: () => incrementStep(),
      disabled: () =>
        !serialNumber ||
        !selectedInstrument ||
        serialNumber.length < formValidation[selectedInstrument].serial,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'serial',
    },
    registrationCode: {
      action: () => registerInstrumentHandler(),
      disabled: () =>
        !registrationCode ||
        !selectedInstrument ||
        registrationCode.length < formValidation[selectedInstrument].regCode,
      loading: () => registerInstrument.busy,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'registrationCode',
    },
    naming: {
      action: () => saveInstrumentName(),
      disabled: () => !instrumentName,
      buttonLabel: intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' }),
      buttonIcon: 'done',
      isStep: false,
      optional: true,
      name: 'naming',
    },
    puk: {
      action: () => incrementStep(),
      disabled: () => false,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'puk',
    },
    pendingInstrument: {
      action: () => registerInstrumentHandler(),
      disabled: () => !activeInstrument.RegCode,
      loading: () => registerInstrument.busy,
      buttonLabel: intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' }),
      buttonIcon: 'forward',
      isStep: true,
      name: 'pendingInstrument',
    },
  };

  const actAsOther = actAsOtherId ? true : false;

  useEffect(() => {
    if (show) {
      resetState();
      setCurrentStep(registerSteps.select);
    }
  }, [show]);

  useEffect(() => {
    if (selectedInstrument) {
      setCurrentStep(registerSteps.select);
    }
  }, [selectedInstrument]);

  useEffect(() => {
    if (selectedInstrument && isFirmwareDownloaded) {
      setCurrentStep(registerSteps.download);
    }
  }, [selectedInstrument, isFirmwareDownloaded]);

  useEffect(() => {
    if (serialNumber) {
      if (selectedInstrument && serialNumber.length === formValidation[selectedInstrument].serial) {
        setCurrentStep(() => {
          const updatedSteps = { ...registerSteps };
          updatedSteps.serial.disabled = () => false;
          return updatedSteps.serial;
        });
      }
      setCurrentStep(registerSteps.serial);
    }
  }, [serialNumber]);

  useEffect(() => {
    if (registrationCode) {
      if (
        selectedInstrument &&
        registrationCode.length === formValidation[selectedInstrument].regCode
      ) {
        setCurrentStep(() => {
          const updatedSteps = { ...registerSteps };
          updatedSteps.registrationCode.disabled = () => false;
          return updatedSteps.registrationCode;
        });
      }
      setCurrentStep(registerSteps.registrationCode);
    }
  }, [registrationCode]);

  useEffect(() => {
    if (currentStepCount > 0 || selectedInstrument) {
      const nextStepCount = currentStepCount;
      const nextStepName = currentStepList[nextStepCount];

      const nextStep = registerSteps[nextStepName];
      const regStepCount = nextStepCount < maxSteps ? nextStepCount : maxSteps;
      if (!nextStepName) {
        setCurrentStepCount(0);
        setCurrentStep(null);
        hideRegisterInstrumentModal(true);
        return;
      }

      setCurrentStep(nextStep);
      setCurrentRegisterStepCount(regStepCount);
    }
  }, [currentStepCount]);

  useEffect(() => {
    if (instrumentName) {
      setCurrentStep(registerSteps.naming);
    }
  }, [instrumentName]);

  useEffect(() => {
    if (activeInstrument.RegCode) {
      setSerialNumber(activeInstrument.SerialNumber);
      setRegistrationCode(activeInstrument.RegCode);
    }

    if (registerInstrument.instrument) {
      setPuk(registerInstrument.instrument.puk);
      setInstrumentId(registerInstrument.instrument.id);
      setIsInstrumentRegistered(true);
    }

    if (downloadFirmware.complete && isDownloadingFirmware) {
      setIsDownloadingFirmware(false);
      setIsFirmwareDownloaded(true);
      setDownLoadingFirmwareType(null);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'devices.firmwareDownloaded',
          defaultMessage: 'Firmware downloaded',
        }),
        toastrOptions
      );
    }
  }, [activeInstrument, downloadFirmware, isDownloadingFirmware, registerInstrument]);

  useEffect(() => {
    if (selectedInstrument === 'IDx' && registerInstrument.complete && currentStepCount === 2) {
      incrementStep();
    }

    if (
      selectedInstrument !== 'IDx' &&
      registerInstrument.complete &&
      currentStepList.length === currentStepCount + 2
    ) {
      incrementStep();
    }
  }, [selectedInstrument, registerInstrument, currentStepList]);

  useEffect(() => {
    if (error || registerInstrument.error) {
      showErrorToast();
    }
  }, [error, registerInstrument.error, currentStepCount]);

  const resetState = () => {
    setSerialNumber('');
    setRegistrationCode('');
    setInstrumentName('');
    setPuk('');
    setInstrumentId('');
    setSelectedInstrument(undefined);
    setCurrentStepCount(0);
    setCurrentRegisterStepCount(0);
    setCurrentStep(null);
    setMaxSteps(0);
    setCurrentStepList([]);
    setError(null);
    setIsFirmwareDownloaded(false);
  };

  const getCurrentUserId = () => (actAsOtherId ? actAsOtherId : user.id);

  const hideRegisterInstrumentModal = (reloadInstruments: boolean) => {
    if (!reloadInstruments && logoutAtCancel) {
      dispatch(clearToken(false, false));
      dispatch(logoutAccount());
      history.push('/login');
    }

    resetState();

    dispatch(clearRegisterInstrument());

    if (hide) {
      hide(reloadInstruments || isInstrumentRegistered);
      setIsInstrumentRegistered(false);
    }

    dispatch(getUser());
  };

  const serialNumberChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setSerialNumber(evt.target.value);

  const registrationCodeChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setRegistrationCode(evt.target.value.toLowerCase());

  const instrumentNameChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setInstrumentName(evt.target.value);

  const saveInstrumentName = () => {
    const id = instrumentId;
    const name = instrumentName;
    dispatch(setInstrumentNameRequest(id, name));
    incrementStep();
  };

  const registerInstrumentHandler = () => {
    if (getCurrentUserId() && selectedInstrument && serialNumber && registrationCode) {
      setError(null);

      const instrumentType =
        selectedInstrument === 'Beacon' || selectedInstrument === 'MegaISQ' ? 4 : 0;

      const instrumentVariant = selectedInstrument === 'MegaISQ' ? 1 : null;

      dispatch(
        registerInstrumentRequest(
          getCurrentUserId(),
          instrumentType,
          instrumentVariant,
          serialNumber,
          registrationCode,
          actAsOther,
          setPromotion
        )
      );
    } else {
      const errorMessages = [];
      if (!selectedInstrument) {
        errorMessages.push({
          key: 'MissingInstrumentType',
          text: intl.formatMessage({
            id: 'registerDevice.selectInstrument',
            defaultMessage: 'Please select instrument to register',
          }),
        });
      } else if (!serialNumber) {
        errorMessages.push({
          key: 'MissingSerialNumber',
          text: intl.formatMessage({
            id: 'registerDevice.enterSerialNimber',
            defaultMessage: 'Please enter a serial number',
          }),
        });
      } else if (!registrationCode) {
        errorMessages.push({
          key: 'MissingRegistrationCode',
          text: intl.formatMessage({
            id: 'registerDevice.enterRegistrationCode',
            defaultMessage: 'Please enter a registration code',
          }),
        });
      }

      setError({ messages: errorMessages });
      showErrorToast();
    }
  };
  const setPromotion = (promotion: IPromotion) => promotion;

  const instrumentSelected = (type: string) => {
    const modifiedStepList =
      type === 'IDx'
        ? stepList['IDx']
        : window.ReactNativeWebView
        ? stepList['BeaconApp']
        : stepList[type];

    setSelectedInstrument(type);

    setCurrentStepList(modifiedStepList);
    setMaxSteps(
      modifiedStepList.reduce((steps, step) => {
        registerSteps[step].isStep && steps++;
        return steps;
      }, 0)
    );
  };

  const incrementStep = (step = 1) => setCurrentStepCount((prevState) => prevState + step);

  const downloadGateway = (name: string) => {
    setIsDownloadingFirmware(true);
    setDownLoadingFirmwareType(name);
    dispatch(downloadFirmwareRequest(name));
  };

  const showErrorToast = () => {
    const errors = error || registerInstrument.error;
    dispatch(clearRegisterInstrument());
    setError(null);

    const toastrOptions = {
      showCloseButton: false,
      position: 'bottom-right',
      preventDuplicates: true,
      closeOnToastrClick: true,
      timeOut: 5000,
    };

    errors &&
      errors.messages.forEach((m: { text: string }) =>
        toastr.error(
          intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
          intl.formatMessage({ id: m.text }),
          toastrOptions
        )
      );
  };

  const renderNavButtons = () => {
    const hideButtonText = currentStep?.optional
      ? intl.formatMessage({ id: 'general.skip', defaultMessage: 'Skip' })
      : intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' });

    return (
      <Fragment>
        {(currentStep?.isStep || currentStep?.optional) && (
          <Button
            big
            value={hideButtonText}
            onClick={() =>
              currentStep?.optional ? hideRegisterInstrumentModal(true) : incrementStep(-1)
            }
          />
        )}
        {currentStep && (
          <Button
            primary
            big
            value={currentStep.buttonLabel}
            onClick={() => currentStep.action()}
            disabled={currentStep.disabled()}
            loading={(currentStep.loading && currentStep.loading()) || registerInstrument.busy}
            rightIcon={currentStep.buttonIcon}
          />
        )}
      </Fragment>
    );
  };

  const renderDeviceSelection = () => {
    return (
      <Fragment>
        <p>
          {intl.formatMessage({
            id: 'registerDevice.whatModel',
            defaultMessage: 'What model would you like to add?',
          })}
        </p>
        <div className={styles.buttonWrapper}>
          <LargeButton
            secondary
            label="Osstell IDx"
            selected={selectedInstrument === 'IDx'}
            onClick={() => instrumentSelected('IDx')}
          >
            <img src={idxImage} alt="" />
          </LargeButton>
          <LargeButton
            secondary
            label="Osstell Beacon"
            selected={selectedInstrument === 'Beacon'}
            onClick={() => instrumentSelected('Beacon')}
          >
            <img src={beaconImage} alt="" />
          </LargeButton>
          <LargeButton
            secondary
            label="Mega ISQ II"
            selected={selectedInstrument === 'MegaISQ'}
            onClick={() => instrumentSelected('MegaISQ')}
          >
            <img src={megaISQImage} alt="" />
          </LargeButton>
        </div>
      </Fragment>
    );
  };

  const renderDownloadPanel = () => {
    const instrumentName = selectedInstrument ? deviceNames[selectedInstrument] : '';
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="registerDevice.downloadDescription"
            defaultMessage="To use {instrument} you need to download and install the Beacon Gateway."
            values={{ instrument: deviceNames[instrumentName] }}
          />
        </p>
        <div className={`${styles.buttonWrapper} ${styles.dowloadFirmware}`}>
          <LargeButton
            secondary
            label={intl.formatMessage({
              id: 'registerDevice.downloadForWindows',
              defaultMessage: 'Download for Windows',
            })}
            loading={downLoadingFirmwareType === 'latestgatewaypc'}
            onClick={() => downloadGateway('latestgatewaypc')}
          >
            {windowsIcon}
          </LargeButton>
          <LargeButton
            secondary
            label={intl.formatMessage({
              id: 'registerDevice.downloadForMac',
              defaultMessage: 'Download for Mac',
            })}
            loading={downLoadingFirmwareType === 'latestgatewaymac'}
            onClick={() => downloadGateway('latestgatewaymac')}
          >
            {macIcon}
          </LargeButton>
        </div>
      </Fragment>
    );
  };
  const renderInstallPanel = () => {
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="registerDevice.downloadDescriptionPostDownload"
            defaultMessage="Install the downloaded Gateway software and follow the instructions on screen"
          />
        </p>
        <div className={styles.narrow}>
          <div className={styles.illustrationWrapper}>
            <img className={styles.imageSize} src={installGateway} alt="" />
          </div>
        </div>
      </Fragment>
    );
  };

  const renderConnectKey = () => {
    return (
      <Fragment>
        <p>
          <FormattedMessage
            id="registerDevice.insertKey"
            defaultMessage="Insert the Osstell key to a USB port on your computer"
          />
        </p>
        <div className={styles.narrow}>
          <div className={styles.illustrationWrapper}>{connectKeyIllustration}</div>
        </div>
      </Fragment>
    );
  };

  const renderEnterSerial = () => {
    const instrumentName = selectedInstrument ? deviceNames[selectedInstrument] : '';

    return (
      <Fragment>
        <p>
          {selectedInstrument !== 'IDx' && (
            <FormattedMessage
              id="registerDevice.enterSerialBeacon"
              defaultMessage="Fill in the serial number (6 digits). You can find it on the back of your instrument."
              values={{ instrument: deviceNames[instrumentName] }}
            />
          )}
          {selectedInstrument === 'IDx' && (
            <FormattedMessage
              id="registerDevice.enterSerialIDx"
              defaultMessage="Enter your IDx serial number. It can be found on the label on the backside of the IDx"
            />
          )}
        </p>
        <div className={styles.narrow}>
          <div className={styles.illustrationWrapper}>
            {selectedInstrument !== 'IDx' && <img src={beaconSerialNumber} alt="beaconSerial" />}
            {selectedInstrument === 'IDx' && <img src={idxSerialNumber} alt="idxSerial" />}
          </div>
          <InputRow>
            <InputItem
              type="text"
              placeholder={intl.formatMessage({
                id: 'registerDevice.serialPlaceholder',
                defaultMessage: 'Enter serial number',
              })}
              onChange={serialNumberChanged}
              value={serialNumber}
              dark
            />
          </InputRow>
        </div>
      </Fragment>
    );
  };
  const renderRegistrationCode = () => {
    const instrumentName = selectedInstrument ? deviceNames[selectedInstrument] : '';

    return (
      <Fragment>
        <p>
          {selectedInstrument !== 'IDx' && (
            <FormattedMessage
              id="registerDevice.enterRegistrationCodeBeacon"
              defaultMessage="Fill in the registration code. You can find it in the lower display on your instrument."
              values={{ instrument: deviceNames[instrumentName] }}
            />
          )}
          {selectedInstrument === 'IDx' && (
            <FormattedMessage
              id="registerDevice.enterRegistrationCodeIdx"
              defaultMessage="Enter the registration code that can be seen on the IDx screen"
            />
          )}
        </p>

        {selectedInstrument !== 'IDx' && (
          <div className={styles.illustrationWrapper}>
            <video muted autoPlay loop playsInline>
              <source ref={vidRef} src={beaconCode} type="video/mp4" />
            </video>
          </div>
        )}

        {selectedInstrument === 'IDx' && (
          <div className={styles.narrow}>
            <div className={styles.illustrationWrapper}>
              <img src={idxCode} alt="IDxCode" />
            </div>
          </div>
        )}

        <div className={styles.narrow}>
          <InputRow>
            <InputItem
              type="text"
              placeholder={intl.formatMessage({
                id: 'registerDevice.registrationCodePlaceholder',
                defaultMessage: 'Enter registration code',
              })}
              onChange={registrationCodeChanged}
              value={registrationCode}
              dark
            />
          </InputRow>
        </div>
        {selectedInstrument !== 'IDx' && (
          <div className={styles.note}>
            <p>
              <FormattedMessage
                id="registerDevice.enterRegistrationCodeNote"
                defaultMessage="Note that this procedure might take a few seconds, as your instrument is establishing a connection to the gateway."
              />
              <br />
              <FormattedMessage
                id="registerDevice.enterRegistrationCodeWhenConnected"
                defaultMessage="When connected the animation of the {icon} symbol will stop and be fully shown."
                values={{
                  icon: connectionSymbol,
                }}
              />
            </p>
          </div>
        )}
      </Fragment>
    );
  };

  const renderNameDevice = () => {
    return (
      <Fragment>
        <ChangeDeviceName
          instrumentNameChanged={instrumentNameChanged}
          instrumentName={instrumentName}
          selectedInstrumentType={selectedInstrument}
        />
      </Fragment>
    );
  };

  const renderPUK = () => {
    return (
      <Fragment>
        <p>
          <FormattedMessage id="registerDevice.PUKdescription" defaultMessage="Puk description" />
        </p>
        <div className={styles.narrow}>
          <div className={cn(styles.pukWrapper, styles.showPuk)}>
            <div>
              <p>
                {intl.formatMessage({
                  id: 'registerDevice.enterPUK',
                  defaultMessage: 'Enter this PUK code on your IDx',
                })}
              </p>
              <div className={styles.illustrationWrapper}>
                <p className={styles.puk}>{puk}</p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  const renderPendingInstrumet = () => {
    return activeInstrument.Active && activeInstrument.RegCode ? (
      <Fragment>
        <p>
          <FormattedMessage
            id="registerDevice.registerConnectedInstrument"
            defaultMessage="Do you want to register the connected instrument: "
          />
        </p>
        <p>
          <FormattedMessage id="registerDevice.serialNumber" defaultMessage="Serial Number: " />
          <strong>#{activeInstrument.SerialNumber}</strong>
        </p>
      </Fragment>
    ) : (
      <Fragment>
        <p>
          <FormattedMessage
            id="registerDevice.startBeacon"
            defaultMessage="Start the instrument and let it connect to the App."
          />
        </p>

        <div className={styles.illustrationWrapper}>
          <video muted autoPlay loop playsInline>
            <source ref={vidRef} src={beaconCode} type="video/mp4" />
          </video>
        </div>
        <div className={styles.note}>
          <p>
            <FormattedMessage
              id="registerDevice.pendingInstrumentNote"
              defaultMessage="Please make sure you have granted the app access to Bluetooth."
            />
          </p>
        </div>
      </Fragment>
    );
  };

  const onDismiss = () => {
    resetState();
    if (logoutAtCancel && !currentStep?.optional) {
      const content = (
        <div>
          <p>
            {intl.formatMessage({
              id: 'registerDevice.ondissmissText',
              defaultMessage:
                'You need to register an instrument in order to use OsstellConnect. If you choose to abort you can login with your created credentials later and finalize the registration.',
            })}
          </p>
        </div>
      );
      dispatch(
        showConfirmation(
          intl.formatMessage({
            id: 'registerDevice.abortRegistration',
            defaultMessage: 'Abort Registration',
          }),
          content,
          hideRegisterInstrumentModal
        )
      );
      return;
    }

    hideRegisterInstrumentModal(true);
  };

  return (
    <Modal
      fullModal={fullModal}
      header={intl.formatMessage({
        id: 'registerDevice.registerInstrument',
        defaultMessage: 'Register Instrument',
      })}
      onDismiss={onDismiss}
      show={show}
      modalPanelClass={styles.modal}
      modalContentClass={styles.content}
    >
      <div className={styles.modalInner}>
        <div className={styles.mainWrapper}>
          {currentStepCount > 0 && (
            <div className={styles.stepNav}>
              <Button
                leftIcon="back"
                white
                big
                noShadow
                iconButton
                primary
                onClick={() => incrementStep(-1)}
              />
              <FormattedMessage id="registerDevice.step" defaultMessage="Step" />{' '}
              {currentRegisterStepCount} / {maxSteps}
            </div>
          )}
          {(currentStep &&
            currentStep.name == registerSteps.select.name &&
            renderDeviceSelection()) ||
            (currentStep &&
              currentStep.name === registerSteps.download.name &&
              renderDownloadPanel()) ||
            (currentStep &&
              currentStep.name === registerSteps.install.name &&
              renderInstallPanel()) ||
            (currentStep &&
              currentStep.name === registerSteps.connect.name &&
              renderConnectKey()) ||
            (currentStep &&
              currentStep.name === registerSteps.serial.name &&
              renderEnterSerial()) ||
            (currentStep &&
              currentStep.name === registerSteps.registrationCode.name &&
              renderRegistrationCode()) ||
            (currentStep && currentStep.name === registerSteps.naming.name && renderNameDevice()) ||
            (currentStep && currentStep.name === registerSteps.puk.name && renderPUK()) ||
            (currentStep &&
              currentStep.name === registerSteps.pendingInstrument.name &&
              renderPendingInstrumet())}
        </div>
        <div className={styles.footer}>{renderNavButtons()}</div>
      </div>
    </Modal>
  );
};

export default RegisterInstrumentModal;
