import history from '../utils/history';
// @ts-ignore
import { routerMiddleware, syncHistoryWithStore } from 'react-router-redux';
import thunkMiddleWare from 'redux-thunk';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { clinicApi } from './api/clinic/clinicApi';
import { configureStore } from '@reduxjs/toolkit';
import { manageInviteApi } from './api/manage-invite/manageInviteApi';
import appReducers from './reducers/appReducers';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['account', 'translation', 'consultation'],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, appReducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      routerMiddleware(routerMiddleware(history as any) as any),
      thunkMiddleWare,
      clinicApi.middleware,
      manageInviteApi.middleware,
    ]),
  devTools: true,
});

const Store = {
  store: store,
  persistor: persistStore(store),
  history: syncHistoryWithStore(history, store),
  purge() {
    //this.persistor.purge(); broken!
  },

  dispatch(action: any) {
    store.dispatch(action);
  },
};

export default Store;
export type RootState = ReturnType<typeof Store.store.getState>;
export type AppDispatch = typeof Store.store.dispatch;
