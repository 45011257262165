import { FC } from 'react';
import { useIntl } from 'react-intl';
import { IPatient } from '../../types/patient';
import { useHistory } from 'react-router-dom';
import { SearchSelect } from '../../components/Shared/Forms';
import { searchPatients } from '../../redux/actions/patientActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import styled from 'styled-components';

interface Props {
  floatRight?: boolean;
  marginTop?: boolean;
}

const PatientSearch: FC<Props> = ({ floatRight, marginTop }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const searchResult = useAppSelector((state) => state.patient.searchPatients);

  const navigateToPatient = (userGroupId: string, patientId: string) =>
    history.push('/usergroup/' + userGroupId + '/patients/' + patientId);

  const onSearch = (searchText: string) => searchText && dispatch(searchPatients(searchText));

  const options = searchResult.patients.map((patient: IPatient) => {
    return {
      label: `${patient.patientIdentifier} ${patient.firstName || patient.lastName ? '-' : ''} ${
        patient.firstName ?? ''
      } ${patient.lastName ?? ''}`,
      action: () => navigateToPatient(patient.sourceUserGroupId, patient.id),
    };
  });
  return (
    <SearchBox floatRight={floatRight} marginTop={marginTop}>
      <SearchSelect
        options={options}
        small
        placeholder={intl.formatMessage({
          id: 'home.SearchPatient',
          defaultMessage: 'Search for patients',
        })}
        noBorder
        noPadding={floatRight}
        filterServer={onSearch}
      />
    </SearchBox>
  );
};

const SearchBox = styled.div<{ floatRight?: boolean; marginTop?: boolean }>`
  padding: 0px 0rem;
  input {
    font-size: $fontNorm;
    border-radius: 5px;
    &:focus {
      box-shadow: none;
    }
  }
  ${(props) => props.floatRight && 'float: right;'}
  ${(props) => props.marginTop && 'margin-top: 9px;'}
`;

export default PatientSearch;
