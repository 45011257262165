import { useHistory } from 'react-router';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../Buttons/Button';
import Modal from '../Modal/Modal';

const Prompt: FC<{
  when: boolean;
  onOK: () => Promise<boolean>;
  onCancel: () => boolean;
  onNav?: () => void;
  onDismiss: () => boolean;
  title: string;
  content: string;
  okText: string;
  cancelText: string;
}> = ({ when, onOK, onCancel, onNav, onDismiss, title, content, okText, cancelText }) => {
  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [okLoading, setOkLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const unblockRef = useRef<ReturnType<typeof history.block>>();

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (when && location.pathname !== '/login') {
        setCurrentPath(location.pathname);
        setShowPrompt(true);
        return 'false';
      }
      onNav?.();
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when, history]);

  const handleOK = useCallback(async () => {
    setOkLoading(true);
    const canRoute = await Promise.resolve(onOK());
    setOkLoading(false);
    setShowPrompt(false);

    if (!canRoute) return;
    if (unblockRef.current) unblockRef.current();
    history.push(currentPath);
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    setCancelLoading(true);
    const canRoute = await Promise.resolve(onCancel());
    setCancelLoading(false);
    setShowPrompt(false);

    if (!canRoute) return;
    if (unblockRef.current) unblockRef.current();
    history.push(currentPath);
  }, [currentPath, history, onCancel]);

  const handleDismiss = useCallback(async () => {
    setShowPrompt(false);

    const canRoute = await Promise.resolve(onDismiss());
    if (!canRoute) return;
    if (unblockRef.current) unblockRef.current();
    history.push(currentPath);
  }, [currentPath, history, onDismiss]);

  return showPrompt ? (
    <Modal header={title} onDismiss={handleDismiss} show={true}>
      <p>{content}</p>
      <StyledButtonsContainer>
        <Button
          big
          value={cancelText}
          onClick={handleCancel}
          loading={cancelLoading}
          disabled={okLoading || cancelLoading}
        />
        <Button
          primary
          big
          value={okText}
          onClick={handleOK}
          loading={okLoading}
          disabled={okLoading || cancelLoading}
        />
      </StyledButtonsContainer>
    </Modal>
  ) : null;
};
const StyledButtonsContainer = styled.div`
  display: flex;
`;

export default Prompt;
