import * as React from 'react';

function SvgAbutmentliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 761 2048" {...props}>
      <path d="M607 997q22 0 37.5 15.5T660 1050v5l-34 296q-5 39-35 60l-39 28q-19 14-19 35v41q0 29-18 52.5t-46 30.5l-194 50q-1 0-2.5.5t-2.5.5q-18 0-21-16-2-8 2.5-16t12.5-10l195-51q32-8 32-41v-41q0-44 36-70l39-28q16-12 18-30l34-295q0-12-11-12H156q-13 2-11 12l34 295q2 18 18 30l39 28q36 26 36 70v26q0 9-6 15.5t-15 6.5-15.5-6.5-6.5-15.5v-26q0-22-18-35l-39-28q-31-22-36-60l-33-296q-2-22 11-39t35-19l7-1h29l57-779q3-41 32.5-68t69.5-27h83q41 0 71 28.5t32 68.5l48 778h29zM284 221l-56 776h307l-48-776q-1-24-18.5-40T427 165h-83q-24 0-41.5 16T284 221zm194 1460q8-2 16 2.5t10 12.5-2.5 16-12.5 10l-204 54q-2 1-6 1-15 0-20-16-2-8 2.5-16t12.5-10z" />
    </svg>
  );
}

export default SvgAbutmentliga;
