import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { defineMessages } from 'react-intl';
import {
  ConsultationTool,
  ConsultationSubTool,
  ColorType,
} from './data-structures/workspaceStructure';

export interface ConsultationUIState {
  currentTab: ConsultationTab;
  exportView: boolean;
  currentTool?: ConsultationTool;
  currentSubTool?: ConsultationSubTool;
  imageLibrary: boolean;
  textFieldEditing?: string;
  drawShapeId?: string;
  drawCurrentColor: ColorType;
  modal: {
    saveOpen: boolean;
    loadOpen: boolean;
    isqInfoOpen: boolean;
  };
}

export enum ConsultationTab {
  Planning,
  Procedure,
}

export const ConsultationTabLabels = defineMessages({
  [ConsultationTab[ConsultationTab.Planning]]: {
    id: 'consultation.tab.planning',
    defaultMessage: 'Planning',
  },
  [ConsultationTab[ConsultationTab.Procedure]]: {
    id: 'consultation.tab.treatmentSequence',
    defaultMessage: 'Treatment Sequence',
  },
});

export const initialState: ConsultationUIState = {
  currentTab: ConsultationTab.Planning,
  exportView: false,
  imageLibrary: false,
  textFieldEditing: undefined,
  drawShapeId: undefined,
  drawCurrentColor: '#000000',
  modal: {
    saveOpen: false,
    loadOpen: false,
    isqInfoOpen: false,
  },
};

export const UISlice = createSlice({
  name: 'CONSULTATION_UI',
  initialState,
  reducers: {
    SET_CURRENT_TAB: (state, action: PayloadAction<ConsultationTab>) => {
      state.currentTab = action.payload;
    },
    SET_EXPORT_VIEW: (state, action: PayloadAction<boolean>) => {
      state.exportView = action.payload;
    },
    SET_TOOL: (state, action: PayloadAction<ConsultationTool | undefined>) => {
      state.currentTool = action.payload;
      state.currentSubTool = action.payload?.subTools?.[0];
    },
    SET_SUBTOOL: (state, action: PayloadAction<ConsultationSubTool | undefined>) => {
      state.currentSubTool = action.payload;
    },
    SET_COLOR: (state, action: PayloadAction<ColorType>) => {
      state.drawCurrentColor = action.payload;
    },
    SET_TEXTFIELD_STATUS: (state, action: PayloadAction<{ id: string | undefined }>) => {
      state.textFieldEditing = action.payload.id;
    },
    SET_DRAWING_STATUS: (state, action: PayloadAction<{ id: string | undefined }>) => {
      if (state.drawShapeId === action.payload.id) {
        state.drawShapeId = undefined;
      } else {
        state.drawShapeId = action.payload.id;
      }
    },
    TOGGLE_MODE_IMAGE_LIBRARY: (state) => {
      state.imageLibrary = !state.imageLibrary;
    },
    MODAL_SAVE: (state, action: PayloadAction<boolean>) => {
      state.modal.saveOpen = action.payload;
    },
    MODAL_LOAD: (state, action: PayloadAction<boolean>) => {
      state.modal.loadOpen = action.payload;
    },
    MODAL_ISQ: (state, action: PayloadAction<boolean>) => {
      state.modal.isqInfoOpen = action.payload;
    },
    RESET: (_) => initialState,
  },
});

export const {
  SET_CURRENT_TAB,
  SET_EXPORT_VIEW,
  SET_TOOL,
  SET_SUBTOOL,
  SET_TEXTFIELD_STATUS,
  SET_DRAWING_STATUS,
  SET_COLOR,
  RESET,
  TOGGLE_MODE_IMAGE_LIBRARY,
  MODAL_SAVE,
  MODAL_LOAD,
  MODAL_ISQ,
} = UISlice.actions;

export const currentTab = (state: RootState) => state.consultation.view.currentTab;
export const exportView = (state: RootState) => state.consultation.view.exportView;

export default UISlice.reducer;
