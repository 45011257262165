import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedTime, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import KeyHandler, { KEYPRESS } from 'react-key-handler';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import * as logActions from '../../../redux/actions/logActions';

import styles from './LogsAdministration.module.scss';

import { SectionTabContent, Row, RowItem } from '../../../components/Shared/Section';
import { PanelWrapper, Panel } from '../../../components/Shared/Panel';
import { InputItem, InputRow } from '../../../components/Shared/Forms';
import { Table, TableHead, TableBody, TH, TR, TD } from '../../../components/Shared/Table';
import Spinner from '../../../components/Shared/Loaders/Spinner';

class LogsAdministration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serialNumber: '',
      instrumentLogs: this.props.getInstrumentLog.logs ? this.props.getInstrumentLog.logs : [],
      adminLogs: this.props.getAdminLog.logs ? this.props.getAdminLog.logs : [],
      selectedTabIndex: 0,
    };
    this.serialNumberChanged = this.serialNumberChanged.bind(this);
    this.renderInstrumentLogs = this.renderInstrumentLogs.bind(this);
    this.renderAdminLogs = this.renderAdminLogs.bind(this);
    this.getInstrumentLog = this.getInstrumentLog.bind(this);
    this.getAdminLog = this.getAdminLog.bind(this);
    this.selectTab = this.selectTab.bind(this);
    this.adminSearchTypeChanged = this.adminSearchTypeChanged.bind(this);
    this.adminSearchTextChanged = this.adminSearchTextChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.selectedAdminSearchType = 1;
    this.adminSearchText = '';

    this.props.actions.clearLogs();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.getInstrumentLog.logs) {
      this.setState({
        instrumentLogs: nextProps.getInstrumentLog.logs,
      });
    }
    if (nextProps.getAdminLog.logs) {
      this.setState({
        adminLogs: nextProps.getAdminLog.logs,
      });
    }
  }

  serialNumberChanged(evt) {
    this.setState({
      serialNumber: evt.target.value,
    });
  }

  adminSearchTextChanged(evt) {
    this.adminSearchText = evt.target.value;
  }

  getInstrumentLog() {
    if (this.state.selectedTabIndex === 1) {
      this.props.actions.getInstrumentLog('IDx', this.state.serialNumber);
    } else if (this.state.selectedTabIndex === 2) {
      this.props.actions.getInstrumentLog('Beacon', this.state.serialNumber);
    } else if (this.state.selectedTabIndex === 3) {
      this.props.actions.getInstrumentLog('Gateway', this.state.serialNumber);
    }
  }

  getAdminLog() {
    const searchCriteria = {
      searchText: this.adminSearchText,
      searchType: this.selectedAdminSearchType,
    };

    this.props.actions.getAdminLog(searchCriteria);
  }

  renderAdminLogs() {
    return this.state.adminLogs.map((log, index) => {
      return (
        <TR key={index} className={styles.tr}>
          <TD>{log.type}</TD>
          <TD className={styles.dataTD}>{log.data}</TD>
          <TD>
            {log.userName} ({log.userEmail})
          </TD>
          <TD className={styles.dateTD}>
            <FormattedTime value={log.created} year="numeric" month="short" day="2-digit" />
          </TD>
        </TR>
      );
    });
  }

  renderInstrumentLogs() {
    return this.state.instrumentLogs.map((log, index) => {
      return (
        <TR key={index} className={styles.tr}>
          <TD>{log.type}</TD>
          <TD className={styles.dataTD}>{log.data}</TD>
          <TD className={styles.dateTD}>
            <FormattedTime value={log.date} year="numeric" month="short" day="2-digit" />
          </TD>
        </TR>
      );
    });
  }

  selectTab(selectedTabIndex, lastSelectedTabIndex) {
    this.props.actions.clearLogs();
    this.setState({
      selectedTabIndex: selectedTabIndex,
    });
  }

  adminSearchTypeChanged(evt) {
    this.selectedAdminSearchType = evt.target.value;
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      if (this.state.selectedTabIndex === 0) {
        this.getAdminLog();
      } else if (
        this.state.selectedTabIndex === 1 ||
        this.state.selectedTabIndex === 2 ||
        this.state.selectedTabIndex === 3
      ) {
        this.getInstrumentLog();
      }
    }
  }

  render() {
    const adminSearchTypes = [
      { value: 'Name', key: 1 },
      { value: 'Email', key: 2 },
    ];
    return (
      <SectionTabContent>
        <KeyHandler keyEventName={KEYPRESS} keyValue="Enter" onKeyHandle={this.getLog} />
        <PanelWrapper className={styles.marginTop}>
          <Panel full filled>
            <Tabs onSelect={this.selectTab}>
              <TabList className={styles.tabList}>
                <Tab className={this.state.selectedTabIndex === 0 ? styles.active : null}>
                  Admin logs
                </Tab>
                <Tab className={this.state.selectedTabIndex === 1 ? styles.active : null}>
                  IDx logs
                </Tab>
                <Tab className={this.state.selectedTabIndex === 2 ? styles.active : null}>
                  Beacon logs
                </Tab>
                <Tab className={this.state.selectedTabIndex === 3 ? styles.active : null}>
                  Gateway logs
                </Tab>
              </TabList>
              <TabPanel className={styles.tabContent}>
                <Row>
                  <RowItem>
                    <InputRow>
                      <InputItem
                        type="search"
                        placeholder="Name or email"
                        onChange={this.adminSearchTextChanged}
                        onKeyPress={this.handleKeyPress}
                        onWhite
                      />
                      <div className={styles.searchTypeDropdown}>
                        <InputItem
                          label="Search type"
                          type="select"
                          options={adminSearchTypes}
                          onChange={this.adminSearchTypeChanged}
                          onWhite
                        />
                      </div>
                      <InputItem
                        label=""
                        type="button"
                        primary
                        value="Get logs"
                        onClick={this.getAdminLog}
                        disabled={this.props.getAdminLog.busy}
                        loading={this.props.getAdminLog.busy}
                      />
                    </InputRow>
                  </RowItem>
                </Row>
                <Row>
                  <RowItem>
                    {this.props.loading ? (
                      <Spinner className={styles.spinnerBackground} />
                    ) : (
                      <Table>
                        <TableHead>
                          <TR>
                            <TH>Type</TH>
                            <TH>Data</TH>
                            <TH>By</TH>
                            <TH>Date</TH>
                          </TR>
                        </TableHead>
                        <TableBody>{this.renderAdminLogs()}</TableBody>
                      </Table>
                    )}
                  </RowItem>
                </Row>
              </TabPanel>
              <TabPanel className={styles.tabContent}>
                <Row>
                  <RowItem>
                    <InputRow>
                      <InputItem
                        type="search"
                        placeholder="Serial number (e.g. 00010)"
                        onChange={this.serialNumberChanged}
                        onKeyPress={this.handleKeyPress}
                      />
                      <InputItem
                        type="button"
                        primary
                        value="Get logs"
                        onClick={this.getInstrumentLog}
                        disabled={this.props.getInstrumentLog.busy}
                        loading={this.props.getInstrumentLog.busy}
                      />
                    </InputRow>
                  </RowItem>
                </Row>
                <Row>
                  <RowItem>
                    {this.props.loading ? (
                      <Spinner className={styles.spinnerBackground} />
                    ) : (
                      <Table>
                        <TableHead>
                          <TR>
                            <TH>Type</TH>
                            <TH>Data</TH>
                            <TH>Date</TH>
                          </TR>
                        </TableHead>
                        <TableBody>{this.renderInstrumentLogs()}</TableBody>
                      </Table>
                    )}
                  </RowItem>
                </Row>
              </TabPanel>
              <TabPanel className={styles.tabContent}>
                <Row>
                  <RowItem>
                    <InputRow>
                      <InputItem
                        type="search"
                        placeholder="Serial number (e.g. 000010)"
                        onChange={this.serialNumberChanged}
                        onKeyPress={this.handleKeyPress}
                      />
                      <InputItem
                        type="button"
                        primary
                        value="Get logs"
                        onClick={this.getInstrumentLog}
                        disabled={this.props.getInstrumentLog.busy}
                        loading={this.props.getInstrumentLog.busy}
                      />
                    </InputRow>
                  </RowItem>
                </Row>
                <Row>
                  <RowItem>
                    {this.props.loading ? (
                      <Spinner className={styles.spinnerBackground} />
                    ) : (
                      <Table>
                        <TableHead>
                          <TR>
                            <TH>Type</TH>
                            <TH>Data</TH>
                            <TH>Date</TH>
                          </TR>
                        </TableHead>
                        <TableBody>{this.renderInstrumentLogs()}</TableBody>
                      </Table>
                    )}
                  </RowItem>
                </Row>
              </TabPanel>
              <TabPanel className={styles.tabContent}>
                <Row>
                  <RowItem>
                    <InputRow>
                      <InputItem
                        type="search"
                        placeholder="Gateway ID (e.g. 79365514-6320-4854-8a4a-648a5503a1d4)"
                        onChange={this.serialNumberChanged}
                        onKeyPress={this.handleKeyPress}
                      />
                      <InputItem
                        type="button"
                        primary
                        value="Get logs"
                        onClick={this.getInstrumentLog}
                        disabled={this.props.getInstrumentLog.busy}
                        loading={this.props.getInstrumentLog.busy}
                      />
                    </InputRow>
                  </RowItem>
                </Row>
                <Row>
                  <RowItem>
                    {this.props.loading ? (
                      <Spinner className={styles.spinnerBackground} />
                    ) : (
                      <Table>
                        <TableHead>
                          <TR>
                            <TH>Type</TH>
                            <TH>Data</TH>
                            <TH>Date</TH>
                          </TR>
                        </TableHead>
                        <TableBody>{this.renderInstrumentLogs()}</TableBody>
                      </Table>
                    )}
                  </RowItem>
                </Row>
              </TabPanel>
            </Tabs>
          </Panel>
        </PanelWrapper>
      </SectionTabContent>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    getInstrumentLog: state.log.getInstrumentLog,
    getAdminLog: state.log.getAdminLog,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(logActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(LogsAdministration));
