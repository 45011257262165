import { toastr } from 'react-redux-toastr';
import { IntlShape } from 'react-intl';

export const errorToast = (intl: IntlShape, modelState: { [key: string]: string[] } | string) => {
  if (typeof modelState === 'string') {
    return toastr.error(
      intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
      intl.formatMessage({ id: modelState, defaultMessage: modelState }),
      {
        showCloseButton: false,
      }
    );
  }
  Object.keys(modelState).forEach((key) => {
    modelState[key].forEach((m) => {
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        intl.formatMessage({ id: key, defaultMessage: m }),
        {
          showCloseButton: false,
        }
      );
    });
  });
};
