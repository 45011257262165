import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { ProcedureStep } from '../../../../../../redux/slices/procedureSlice';
import styled from 'styled-components';
import Spinner from '../../../../../Shared/Loaders/Spinner';

export const StepPreviewImage: React.FC<{
  visitIndex: number;
  index: number;
  step: ProcedureStep;
  selected: boolean;
  translateY?: number;
  onDrag: (index: number, newIndex: number, offset: number) => void;
  onDragStop: (index: number, yAxis: number) => void;
  setIsStepDragged: (value: boolean) => void;
  loading?: boolean;
}> = ({
  index,
  step,
  selected,
  onDrag,
  onDragStop,
  translateY,
  visitIndex,
  setIsStepDragged,
  loading,
}) => {
  const [newIndex, setNewIndex] = useState<number | undefined>(undefined);
  const [isDragged, setIsDragged] = useState(false);
  return (
    <StepPreviewImageWrapper isDragged={isDragged} data-cy={`step-${index}-visit-${visitIndex}`}>
      <Draggable
        axis="y"
        handle=".handle"
        onDrag={(_, data) => {
          setIsDragged(true);
          setIsStepDragged(true);
          const newIndex = index + Math.round(data.y / 70);
          setNewIndex(newIndex);
          onDrag(index, newIndex, data.y);
        }}
        onStop={(_, data) => {
          setIsDragged(false);
          setIsStepDragged(false);
          setNewIndex(undefined);
          onDragStop(index, data.y);
        }}
        position={{ x: newIndex ? -5 : 0, y: translateY ?? 0 }}
      >
        <div className="handle">
          {loading || !step?.previewImageSrc ? (
            <Spinner purple />
          ) : (
            <img
              className={selected ? 'selected' : ''}
              src={step?.previewImageSrc || ''}
              alt={'procedure step screenshot'}
            />
          )}
        </div>
      </Draggable>
    </StepPreviewImageWrapper>
  );
};

const StepPreviewImageWrapper = styled.div<{ isDragged: boolean }>`
  ${(props) => props.isDragged && `pointer-events: none;`};
  cursor: grabbing;
`;
