import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ApiFetch from '../../../src/utils/apiFetch';
interface ActionStatus {
  complete: boolean;
  busy: boolean;
  error?: string;
}

export interface PatientImage {
  id?: string;
  name: string;
  imageType: string;
  image: string;
}

export interface NewPatientImage {
  id?: string;
  name: string;
  imageType: string;
  image: string;
  thumbnailType: string;
  thumbnail: string;
}

interface PatientImageState {
  getThumbNailsStatus: ActionStatus;
  getImageStatus: ActionStatus;
  addImagesStatus: ActionStatus;
  deleteImageStatus: ActionStatus;
  thumbnails: Array<PatientImage>;
  image?: PatientImage;
}

const actionStatusInitial = {
  complete: false,
  error: undefined,
  busy: false,
};

export const initialState: PatientImageState = {
  getThumbNailsStatus: actionStatusInitial,
  getImageStatus: actionStatusInitial,
  addImagesStatus: actionStatusInitial,
  deleteImageStatus: actionStatusInitial,
  thumbnails: [],
  image: undefined,
};

export const getThumbNails = createAsyncThunk(
  'consultation/getThumbNails',
  async ({ userGroupId, patientId }: { userGroupId: string; patientId: string }) => {
    const response = await ApiFetch.get(`usergroup/${userGroupId}/patients/${patientId}/images`);
    return response;
  }
);

export const getImage = createAsyncThunk(
  'consultation/getImage',
  async ({
    userGroupId,
    patientId,
    imageId,
  }: {
    userGroupId: string;
    patientId: string;
    imageId: string;
  }) => {
    const response = await ApiFetch.get(
      `usergroup/${userGroupId}/patients/${patientId}/images/${imageId}`
    );
    return response;
  }
);

export const addImages = createAsyncThunk(
  'consultation/addImages',
  async ({
    userGroupId,
    patientId,
    images,
  }: {
    userGroupId: string;
    patientId: string;
    images: Array<NewPatientImage>;
  }) => {
    const response = await ApiFetch.post(
      `usergroup/${userGroupId}/patients/${patientId}/images`,
      images
    );
    return response;
  }
);

export const deleteImage = createAsyncThunk(
  'consultation/deleteImage',
  async ({
    userGroupId,
    patientId,
    imageId,
  }: {
    userGroupId: string;
    patientId: string;
    imageId: string;
  }) => {
    const response = await ApiFetch.delete(
      `usergroup/${userGroupId}/patients/${patientId}/images/${imageId}`
    );
    return response;
  }
);

export const patientImageSlice = createSlice({
  name: 'patientImages',
  initialState,
  reducers: {
    CLOSE_IMAGE: (state) => {
      state.image = undefined;
    },
  },
  extraReducers: (builder) => {
    //Get thumbnails
    builder.addCase(getThumbNails.fulfilled, (state, action) => {
      state.thumbnails = action.payload;
      state.getThumbNailsStatus.complete = true;
      state.getThumbNailsStatus.error = undefined;
      state.getThumbNailsStatus.busy = false;
    });
    builder.addCase(getThumbNails.rejected, (state, action) => {
      state.getThumbNailsStatus.complete = true;
      state.getThumbNailsStatus.error = action.error.message;
      state.getThumbNailsStatus.busy = false;
    });
    builder.addCase(getThumbNails.pending, (state, _) => {
      state.getThumbNailsStatus.complete = false;
      state.getThumbNailsStatus.error = undefined;
      state.getThumbNailsStatus.busy = true;
    });

    //Get image
    builder.addCase(getImage.fulfilled, (state, action) => {
      state.image = action.payload;
      state.getImageStatus.complete = true;
      state.getImageStatus.error = undefined;
      state.getImageStatus.busy = false;
    });
    builder.addCase(getImage.rejected, (state, action) => {
      state.getImageStatus.complete = true;
      state.getImageStatus.error = action.error.message;
      state.getImageStatus.busy = false;
    });
    builder.addCase(getImage.pending, (state, _) => {
      state.getImageStatus.complete = false;
      state.getImageStatus.error = undefined;
      state.getImageStatus.busy = true;
    });

    //Add image
    builder.addCase(addImages.fulfilled, (state, _) => {
      state.addImagesStatus.complete = true;
      state.addImagesStatus.error = undefined;
      state.addImagesStatus.busy = false;
    });
    builder.addCase(addImages.rejected, (state, action) => {
      state.addImagesStatus.complete = true;
      state.addImagesStatus.error = action.error.message;
      state.addImagesStatus.busy = false;
    });
    builder.addCase(addImages.pending, (state, _) => {
      state.addImagesStatus.complete = false;
      state.addImagesStatus.error = undefined;
      state.addImagesStatus.busy = true;
    });

    //Delete image
    builder.addCase(deleteImage.fulfilled, (state, _) => {
      state.deleteImageStatus.complete = true;
      state.deleteImageStatus.error = undefined;
      state.deleteImageStatus.busy = false;
    });
    builder.addCase(deleteImage.rejected, (state, action) => {
      state.deleteImageStatus.complete = true;
      state.deleteImageStatus.error = action.error.message;
      state.deleteImageStatus.busy = false;
    });
    builder.addCase(deleteImage.pending, (state, _) => {
      state.deleteImageStatus.complete = false;
      state.deleteImageStatus.error = undefined;
      state.deleteImageStatus.busy = true;
    });
  },
});

export const { CLOSE_IMAGE } = patientImageSlice.actions;
export default patientImageSlice.reducer;
