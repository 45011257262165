import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import KeyPerformanceIndicator from './KeyPerformanceIndicator';

import * as keyPerformanceIndicatorsActions from '../../redux/actions/keyPerformanceIndicatorsActions';

import styles from './KeyPerformanceIndicators.module.scss';

class KeyPerformanceIndicators extends Component {
  constructor(props) {
    super(props);

    this.intl = props.intl;

    this.keyPerformanceIndicators = {
      fetched: false,
    };
  }

  componentDidMount() {
    this.props.actions.subscribeToKeyPerformanceIndicatorsChange(this.props.user);
  }

  componentWillUnmount() {
    this.props.actions.unsubscribeFromKeyPerformanceIndicatorsChange(this.props.user);
  }

  renderKeyPerformanceIndicators() {
    if (
      this.props.keyPerformanceIndicators.items &&
      this.props.keyPerformanceIndicators.items.length > 0
    ) {
      return this.props.keyPerformanceIndicators.items.map((kpi, index) => {
        return (
          <KeyPerformanceIndicator
            key={index}
            label={this.intl.formatMessage({
              id: ''.concat('referenceData.', kpi.type),
              defaultMessage: kpi.type,
            })}
            value={kpi.value}
            tooltip={kpi.description}
            percentage={kpi.type === 'SuccessRate'}
            days={kpi.type === 'AverageHealingTime'}
          />
        );
      });
    }
  }

  render() {
    return (
      <div className={styles.keyPerformanceIndicators}>{this.renderKeyPerformanceIndicators()}</div>
    );
  }
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(keyPerformanceIndicatorsActions, dispatch),
  };
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    user: state.user.user,
    keyPerformanceIndicators: state.keyPerformanceIndicators,
  };
}

export default connect(mapState, mapDispatch)(injectIntl(KeyPerformanceIndicators));
