import * as React from 'react';

function SvgSvgLoad(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 22.7" {...props}>
      <path
        d="M21.5 5.2H23c.2 0 .4.2.4.3 0 0 0 0 0 0v16.2c0 .2-.2.4-.4.4h0H.9c-.2 0-.4-.2-.4-.4h0V5.6c0-.2.2-.3.4-.3h20.3c.2 0 .4-.2.4-.3 0 0 0 0 0 0V2.7c0-.2-.2-.3-.4-.3h0-12c-.1-.1-.3-.2-.3-.4h0c0-.8-.7-1.5-1.5-1.5H2.7c-.2 0-.3.2-.3.4v4.5"
        fill="none"
        stroke={props.fill}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgSvgLoad;
