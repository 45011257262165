import React, { Component } from 'react';
import styles from './HomeTile.module.scss';
import { Panel } from '../Shared/Panel';

class HomeTile extends Component {
  render() {
    return (
      <Panel filled square mobileColumn className={styles.tile} onClick={this.props.onClick}>
        <div className={styles.icon} data-cy={this.props.dataCy}>
          <i>{this.props.icon}</i>
        </div>
        <div className={styles.title}>{this.props.title}</div>
      </Panel>
    );
  }
}

export default HomeTile;
