import * as React from 'react';

function SvgNerveliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1740 2048" {...props}>
      <path d="M871 810q55 0 94.5 39.5T1005 945q0 55-39.5 94.5T871 1079q-56 0-95.5-39.5T736 945t40-95 95-40zm606 0q67 0 114.5 39.5T1639 945q0 55-47.5 94.5T1477 1079h-160q-44 146-167 239t-279 93-279.5-93T424 1079H264q-67 0-114.5-39.5T102 945q0-56 47.5-95.5T264 810h160q44-145 167.5-238T871 479t279 92.5T1317 810h160zm-607 558q176 0 300-124t124-299-124-299.5T870 521q-175 0-299 124.5T447 945t123.5 299T870 1368z" />
    </svg>
  );
}

export default SvgNerveliga;
