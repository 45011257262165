import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { TeethDraggableNode } from './TeethDraggableNode';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { UPDATE_NODE } from '../../../../../../redux/slices/workspaceSlice';
import SvgTeethSinusShape from './SvgTeethSinusShape';
import SvgTeethGraftShapes from './SvgTeethSinusGraftShapes';
import { Position } from '../../../../../../redux/slices/data-structures/workspaceStructure';

export function TeethSinusShape({
  enabled = false,
  rightSide = false,
}: {
  enabled?: boolean;
  rightSide?: boolean;
}) {
  const { nodes } = useAppSelector(
    (state) => state.consultation.workspace.present.sinusState[rightSide ? 1 : 0]
  );

  const dispatch = useAppDispatch();
  const boundingArea = useRef<HTMLDivElement>(null);
  const nodeRefs = useRef<Array<HTMLDivElement>>([]);

  const [dragMode, setDragMode] = useState(false);
  const [posUpdate, setPosUpdate] = useState(false);
  const [nodePositions, setNodePositions] = useState<Array<gsap.Point2D | null>>([]);

  useEffect(() => {
    setNodePositions(
      nodes.map((_node: Position, index: number) => {
        const wrapper = nodeRefs.current[index];
        if (wrapper) {
          const nodeElement = wrapper.getElementsByTagName('div')[0];
          return {
            x: parseFloat(gsap.getProperty(nodeElement, 'x').toString()),
            y: parseFloat(gsap.getProperty(nodeElement, 'y').toString()),
          };
        }
        return null;
      })
    );
  }, [posUpdate, nodes]);

  return (
    <PlacedArea side={rightSide}>
      <SinusArea ref={boundingArea}>
        {nodes.map((node: Position, i: number) => (
          <TeethDraggableNode
            key={'sinus_left_' + i}
            ref={(el) => (el ? (nodeRefs.current[i] = el) : undefined)}
            storedX={node.x}
            storedY={node.y}
            enabled={dragMode}
            setEnabled={setDragMode}
            onDragEnd={(_: PointerEvent, p) => {
              dispatch(
                UPDATE_NODE({
                  nodeArea: rightSide ? 'SINUS_RIGHT' : 'SINUS_LEFT',
                  id: i,
                  x: Math.round(p.x),
                  y: Math.round(p.y),
                })
              );
            }}
            onDrag={(_, __) => setPosUpdate((update) => !update)}
            bounds={boundingArea.current}
          />
        ))}
        <SvgTeethSinusShape
          enabled={enabled}
          rightSide={rightSide}
          positions={nodePositions}
          onClick={() => {
            enabled && setDragMode((drag) => !drag);
          }}
          maskElement={<SvgTeethGraftShapes rightSide={rightSide} />}
        />
      </SinusArea>
    </PlacedArea>
  );
}

const SinusArea = styled.div`
  min-width: 400px;
  min-height: 200px;
  border-radius: 32px;
  pointer-events: none;
`;
const PlacedArea = styled.section<{ side: boolean }>`
  width: 100%;
  pointer-events: none;
`;
