import React from 'react';
import styled from 'styled-components';

interface Props {
  onClick: () => void;
  icon: string;
  buttonText: string;
  className?: string;
  textToTheSide?: boolean;
  dataCy?: string;
}
const CircularButton: React.FC<Props> = ({
  onClick,
  icon,
  buttonText,
  className,
  textToTheSide = false,
  dataCy,
}) => {
  return (
    <StyledCircularButton
      icon={icon}
      data-cy={dataCy && dataCy}
      className={className && className}
      textToTheSide={textToTheSide}
      onClick={() => onClick()}
    >
      <div className="circleDiv">
        <i>{icon}</i>
      </div>
      <div className="textDiv">{buttonText}</div>
    </StyledCircularButton>
  );
};

const StyledCircularButton = styled.div<{
  textToTheSide: boolean;
  icon: string;
}>`
  cursor: pointer;
  display: flex;
  ${(props) => `${!props.textToTheSide && 'flex-direction: column;'}`};
  align-items: center;
  justify-content: center;
  .circleDiv {
    height: 18px;
    cursor: pointer;
    width: 18px;
    background-color: #822973;
    border-radius: 50%;
    position: relative;
    i {
      position: absolute;
      font-size: 16px;
      color: white;
      vertical-align: middle;
      top: 1px;
      ${(props) => `${props.icon === 'close' && 'left: 4px;'}`};
      ${(props) => `${props.icon === 'add' && 'left: 3px;'}`};
    }
  }
  .textDiv {
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;
    ${(props) => `${props.textToTheSide ? 'margin-left: 5px;' : 'margin-top: 5px;'}`};
    font-size: 9px;
  }
`;

export default CircularButton;
