import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router';
import { clearToken, logoutAccount, setInactivityWarning } from '../redux/actions/accountActions';
import { useAppDispatch, useAppSelector } from './hooks';
import { useEffect, useState } from 'react';

function LogoutTimer() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoutTimeInMinutes = useAppSelector((state) => state.user.user.logoutTimeInMinutes);
  const inactivityWarning = useAppSelector((state) => state.account.inactivityWarning);
  const promptTimeout = 120000; // Two minutes

  const [millisecondsToLogout, setMillisecondsToLogout] = useState<number>(
    logoutTimeInMinutes * 60000
  );

  useEffect(() => {
    setMillisecondsToLogout(logoutTimeInMinutes * 60000);
  }, [logoutTimeInMinutes]);

  const idleTimer = useIdleTimer({
    timeout: millisecondsToLogout - (promptTimeout || 0),
    promptTimeout: promptTimeout,
    onIdle: () => {
      dispatch(logoutAccount());
      dispatch(clearToken(false, true));
      history.push('/login');
    },
    onPrompt: () => dispatch(setInactivityWarning(true)),
    onAction: () => {
      if (inactivityWarning) {
        idleTimer.reset();
        dispatch(setInactivityWarning(false));
      }
    },
  });

  return null;
}

export default LogoutTimer;
