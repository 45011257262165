import React, { Component } from 'react';
import cn from 'classnames';

import styles from './Panel.module.scss';

import { H2 } from '../Headings';
import PanelOptions from './PanelOptions';
import PanelButtons from './PanelButtons';

class PanelHeader extends Component {
  render() {
    const header = cn(
      styles.header,
      this.props.className,
      this.props.insights ? styles.insightsBorder : null
    );

    return (
      <div className={header}>
        <div>
          <H2 className={styles.h2} style={this.props.style}>
            {this.props.header}
          </H2>
        </div>
        {this.props.buttons && <PanelButtons buttons={this.props.buttons} />}
        {this.props.options && <PanelOptions options={this.props.options} />}
      </div>
    );
  }
}

export default PanelHeader;
