import React, { useEffect, useRef } from 'react';
import Rive, { Artboard, CanvasRenderer, LinearAnimationInstance, RiveCanvas } from 'rive-canvas';
import Konva from 'konva';
import { Layer } from 'react-konva';

// @ts-ignore
import boneLayer from '../../../../../../../shared/videos/bone.riv';
import { Bone } from '../../../../../../../redux/slices/data-structures/workspaceStructure';
import { useAppSelector } from '../../../../../../../utils/hooks';
import { RootState } from '../../../../../../../redux/store';
import { zeroPad } from '../../../../../../../utils/transformations';

export async function loadAnimationInstances(
  rive: RiveCanvas,
  artboard: Artboard,
  numberOfAnimations: number,
  startOffset: number,
  animationNamePrefix: string
) {
  const instances = [];
  for (let i = 0; i < numberOfAnimations; i++) {
    instances.push(
      new rive.LinearAnimationInstance(
        artboard.animationByName(`${animationNamePrefix}${zeroPad(i + startOffset, 2)}`)
      )
    );
  }
  return instances;
}

export function TeethCanvasBoneLayer(props: { width: number; height: number }) {
  const boneDepth = useAppSelector(
    (state: RootState) => state.consultation.workspace.present.boneState
  );

  const _rive = useRef<RiveCanvas | null>(null);
  const _renderer = useRef<CanvasRenderer | null>(null);
  const _artboard = useRef<Artboard | null>(null);
  const animations = useRef<Record<string, LinearAnimationInstance[]> | null>(null);
  const konvaBoneLayer = useRef<Konva.Layer>(null);

  function update() {
    if (animations.current) {
      boneDepth.forEach((bone: Bone, index: number) => {
        if (index < 16) {
          //Upper row
          animations.current!.UpperDepth[index].time = bone.depth / 3;
          animations.current!.UpperDepth[index].apply(_artboard.current!, 1.0);
          if (animations.current!.UpperPeak[index]) {
            animations.current!.UpperPeak[index].time = !bone.peak[1] ? bone.depth / 3 : 0;
            animations.current!.UpperPeak[index].apply(_artboard.current!, 1.0);
          }
        } else {
          //Lower row
          animations.current!.LowerDepth[index - 16].time = bone.depth / 3;
          animations.current!.LowerDepth[index - 16].apply(_artboard.current!, 1.0);
          if (animations.current!.LowerPeak[index - 16]) {
            animations.current!.LowerPeak[index - 16].time = !bone.peak[1] ? bone.depth / 3 : 0;
            animations.current!.LowerPeak[index - 16].apply(_artboard.current!, 1.0);
          }
        }
      });
    }
  }

  function updateGraft() {
    if (animations.current) {
      boneDepth.forEach((bone: Bone, index: number) => {
        if (index < 16) {
          //Upper row
          animations.current!.UpperDepth[index].time = bone.graft ? 0 : bone.depth / 3;
          animations.current!.UpperDepth[index].apply(_artboard.current!, 1.0);
          if (animations.current!.UpperPeak[index]) {
            animations.current!.UpperPeak[index].time = bone.graft
              ? 0
              : !bone.peak[1]
              ? bone.depth / 3
              : 0;
            animations.current!.UpperPeak[index].apply(_artboard.current!, 1.0);
          }
        } else {
          //Lower row
          animations.current!.LowerDepth[index - 16].time = !bone.graft ? 1 : 0;
          animations.current!.LowerDepth[index - 16].apply(_artboard.current!, 1.0);
          if (animations.current!.LowerPeak[index - 16]) {
            animations.current!.LowerPeak[index - 16].time = bone.graft
              ? 0
              : !bone.peak[1]
              ? bone.depth / 3
              : 0;
            animations.current!.LowerPeak[index - 16].apply(_artboard.current!, 1.0);
          }
        }
      });
    }
  }

  function render() {
    const ctx = konvaBoneLayer.current!.canvas._canvas.getContext('2d');
    if (ctx && _rive.current && _artboard.current && _renderer.current) {
      ctx.beginPath();
      ctx.save();
      ctx.translate(0, 16);
      _renderer.current.align(
        _rive.current.Fit.none,
        _rive.current.Alignment.topCenter,
        {
          minX: 0,
          minY: 0,
          maxX: props.width ?? 960,
          maxY: props.height ?? 600,
        },
        _artboard.current.bounds
      );
      _artboard.current.advance(0);
      _artboard.current.draw(_renderer.current);
      ctx.restore();
    }
  }

  useEffect(() => {
    if (konvaBoneLayer.current) {
      Rive({
        locateFile: (file) => 'file://' + file,
      }).then(async (rive) => {
        _rive.current = rive;
        const req = new Request(boneLayer);
        fetch(req)
          .then((res) => {
            return res.arrayBuffer();
          })
          .then(async (buf) => {
            const file = rive.load(new Uint8Array(buf));
            const artboard = file.defaultArtboard();
            _artboard.current = artboard;
            if (!konvaBoneLayer.current) return;

            const UpperDepthAnimations = await loadAnimationInstances(
              rive,
              artboard,
              16,
              1,
              'upper_d'
            );
            const LowerDepthAnimations = await loadAnimationInstances(
              rive,
              artboard,
              16,
              17,
              'lower_d'
            );
            const UpperPeakAnimations = await loadAnimationInstances(
              rive,
              artboard,
              15,
              1,
              'upper_p'
            );
            const LowerPeakAnimations = await loadAnimationInstances(
              rive,
              artboard,
              15,
              17,
              'lower_p'
            );

            //Load all animations into a Record
            animations.current = {
              UpperDepth: UpperDepthAnimations,
              LowerDepth: LowerDepthAnimations,
              UpperPeak: UpperPeakAnimations,
              LowerPeak: LowerPeakAnimations,
            };

            const canvas = konvaBoneLayer.current.canvas._canvas;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            _renderer.current = new rive.CanvasRenderer(ctx);

            //Fill in graft in background if applicable
            updateGraft();
            ctx.globalAlpha = 1;
            ctx.filter = 'contrast(20%) sepia(20%)';
            _artboard.current?.advance(0);
            render();
            ctx.globalAlpha = 1;
            ctx.filter = 'contrast(100%) sepia(0%)';

            //Initial Render
            update();
            artboard.advance(0);
            render();
          });
      });
    }
  }, []);

  useEffect(() => {
    if (
      _rive.current &&
      _renderer.current &&
      _artboard.current &&
      konvaBoneLayer.current &&
      animations.current
    ) {
      const ctx = konvaBoneLayer.current.canvas._canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          konvaBoneLayer.current.canvas._canvas.width,
          konvaBoneLayer.current.canvas._canvas.height
        );

        //Fill in graft in background if applicable
        updateGraft();
        ctx.globalAlpha = 1;
        ctx.filter = 'contrast(20%) sepia(20%)';
        _artboard.current?.advance(0);
        render();
        ctx.globalAlpha = 1;
        ctx.filter = 'contrast(100%) sepia(0%)';

        update();
        _artboard.current?.advance(0);
        render();
      }
    }
  });
  /*  [boneDepth, _rive, _renderer, _artboard, animations, konvaBoneLayer]*/
  return <Layer clearBeforeDraw={false} ref={konvaBoneLayer} listening={false} />;
}
