import { useEffect } from 'react';
import { appInsights } from '../../utils/applicationInsights';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  hideVideo,
  hideAlert,
  hideConfirmation,
  hideFreeSmartPegs,
} from '../../redux/actions/generalActions';
import MuiPrototypesPageStyles from '../material-ui-prototypes-page/MuiPrototypesPage';
import InstrumentSubscriber from '../../components/Instrument/InstrumentSubscriber';
import FreeSmartPegsModal from '../../components/Promotions/FreeSmartPegsModal';
import ConfirmationModal from '../../components/Shared/Modals/ConfirmationModal/ConfirmationModal';
import LogoutTimer from '../../utils/LogoutTimer';
import VideoModal from '../../components/Shared/Modals/VideoModal/VideoModal';
import AlertModal from '../../components/Shared/Modals/AlertModal/AlertModal';
import SideNav from '../../components/side-nav/SideNav';
import styles from './MainPage.module.scss';
import {
  HomePage,
  DevicesPage,
  PatientListPage,
  PatientPage,
  PatientExportPage,
  AdminPage,
  PatientInformationPage,
  ConsultationPage,
  AboutPage,
  HelpPage,
  ProfilePage,
  ClinicPage,
  CommunityPage,
  StudyPage,
} from '..';

const MainPage = () => {
  const dispatch = useAppDispatch();
  const { freeSmartPegs } = useAppSelector((state) => state.general);

  return (
    <div className={styles.app}>
      <SideNav />
      <main id="main" className={styles.main}>
        <div id="appContent" className={styles.content}>
          <Switch>
            <Route path="/home" component={HomePage} />
            <Route exact path="/devices" component={DevicesPage} />
            <Route path="/devices/:userId" component={DevicesPage} />
            <Route path="/clinic" component={ClinicPage} />
            <Route
              path="/usergroup/:userGroupId/patients/:patientId"
              exact
              component={PatientPage}
            />
            <Route exact path="/patients" component={PatientListPage} />
            <Route
              exact
              path="/usergroup/:userGroupId/patients/:patientId/export"
              component={PatientExportPage}
            />
            <Route
              exact
              path="/usergroup/:userGroupId/patients/:patientId/consultation"
              component={ConsultationPage}
            />
            <Route
              exact
              path="/usergroup/:userGroupId/patients/:patientId/patientInformation"
              component={PatientInformationPage}
            />
            <Route
              exact
              path="/usergroup/:userGroupId/patients/:patientId/patientInformation/:section"
              component={PatientInformationPage}
            />
            <Route path="/admin" component={AdminPage} />
            <Route path="/consultation" component={ConsultationPage} />
            <Route path="/about" component={AboutPage} />
            <Route path="/help" component={HelpPage} />
            <Route exact path="/profile" component={ProfilePage} />
            <Route path="/profile/:userId" component={ProfilePage} />
            <Route path="/community" component={CommunityPage} />
            <Route path="/studies" component={StudyPage} />
            <Route path="/material-ui-prototypes" component={MuiPrototypesPageStyles} />
            <Route path="*">
              <Redirect to="/home" />
            </Route>
          </Switch>

          <FreeSmartPegsModal
            hide={() => dispatch(hideFreeSmartPegs())}
            show={freeSmartPegs.show}
          />
          <GlobalVideoModal />
          <GlobalAlertModal />
          <GlobalConfirmationModal />
          <InstrumentSubscriber />
        </div>
      </main>
      <LogoutTimer />
    </div>
  );
};

const GlobalVideoModal = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { video } = useAppSelector((state) => state.general);

  const convertToCamelCase = (inputString: string) =>
    inputString.replace(/\b\w/g, (match) => match.toUpperCase()).replace(/\s/g, '');

  const newVideoHeader = video.header && convertToCamelCase(video.header);

  const checkVideoHeaderString = (header: string) => {
    if (header.includes('campaignAd'))
      return 'Export a visual treatment plan to support your patient';
    if (header.includes('ConsultationTool'))
      return 'Access the ConsultationTool from the top right corner in a patient record';
    return '';
  };

  const newHeaderTitle =
    video.header && checkVideoHeaderString(video.header)
      ? checkVideoHeaderString(video.header)
      : video.header;

  useEffect(() => {
    if (video.header) {
      appInsights.trackEvent({
        name: 'ViewedVideo',
        properties: {
          videoHeader: newVideoHeader,
          email: user.email,
        },
      });
    }
  }, [video, user, newHeaderTitle, newVideoHeader]);

  return (
    <VideoModal
      hide={() => dispatch(hideVideo())}
      show={video.show}
      header={newHeaderTitle}
      videoUrl={video.url}
      userEmail={user.email}
    />
  );
};

const GlobalAlertModal = () => {
  const dispatch = useAppDispatch();
  const { alert } = useAppSelector((state) => state.general);
  return (
    <AlertModal
      hide={() => dispatch(hideAlert())}
      show={alert.show}
      header={alert.header}
      content={alert.content}
      action={alert.action}
    />
  );
};

const GlobalConfirmationModal = () => {
  const dispatch = useAppDispatch();
  const { confirmation } = useAppSelector((state) => state.general);
  return (
    <ConfirmationModal
      hide={() => dispatch(hideConfirmation())}
      show={confirmation.show}
      header={confirmation.header}
      content={confirmation.content}
      action={confirmation.action}
    />
  );
};

export default MainPage;
