import React, { Component } from 'react';

import styles from './Panel.module.scss';
import { Button } from '../Buttons';

class PanelButtons extends Component {
  renderButtons() {
    return this.props.buttons.map((button) => {
      return <Button key={button.label} value={button.label} onClick={button.action} />;
    });
  }

  render() {
    return <div className={styles.buttonWrapper}>{this.renderButtons()}</div>;
  }
}

export default PanelButtons;
