export const languageOptions = [
  {
    value: 'English',
    key: 'en',
  },
  {
    value: 'Deutsch',
    key: 'de',
  },
  {
    value: 'Français',
    key: 'fr',
  },
  {
    value: 'Italiano',
    key: 'it',
  },
  {
    value: 'Español',
    key: 'es',
  },
  {
    value: '简体中文',
    key: 'zh',
  },
  {
    value: '한국어',
    key: 'ko',
  },
  {
    value: 'Português (Brasil)',
    key: 'pt',
  },
  {
    value: '日本語',
    key: 'ja',
  },
];
