import styled, { css } from 'styled-components';

export const Center = css`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const OverlayBoxShadow = css`
  box-shadow: 0 0 8px #2f2f2f29;
`;

export const OverlayBoxRounded = styled.div`
  ${Center}
  ${OverlayBoxShadow}
  border-radius: 16px;
`;
