import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, FormattedTime } from 'react-intl';
import classNames from 'classnames';

import styles from './PatientRecordsTable.module.scss';

import { Table, TableHead, TableBody, TH, TR, TD } from '../Shared/Table';

class PatientRecordsTable extends Component {
  getStatusMarker(patientIsq) {
    const statusMarkerHigh = classNames(styles.statusMarker, styles.high);
    const statusMarkerMedium = classNames(styles.statusMarker, styles.medium);
    const statusMarkerLow = classNames(styles.statusMarker, styles.low);

    if (patientIsq < 60) return statusMarkerLow;
    else if (patientIsq < 70) return statusMarkerMedium;

    return statusMarkerHigh;
  }

  navigateToPatient(patient, event) {
    const currentLocationPath = this.props.history.location.pathname;
    const newPath = `/usergroup/${patient.sourceUserGroupId}/patients/${patient.id}`;

    if (currentLocationPath !== newPath) {
      this.props.history.push(newPath);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  navigate(path, event) {
    this.props.history.push(`/${path}`);
    event.preventDefault();
    event.stopPropagation();
  }

  renderPatientRowsOnMobile(patient) {
    return (
      <div
        key={`${patient.id}-mobile`}
        className={styles.mobileRow}
        onClick={this.navigateToPatient.bind(this, patient)}
      >
        <div className={styles.mobileRowOne}>
          {' '}
          <div className={styles.mobilePatienId}>
            <b>
              {' '}
              <FormattedMessage id="patientList.patientID" defaultMessage="Patient ID" />:
            </b>{' '}
            {patient.patientIdentifier}
          </div>
          <div className={styles.mobileLastChanged}>
            {new Date(patient.lastModified).toLocaleDateString('en-US')}
          </div>
        </div>
        <div>
          <span>
            <b>
              <FormattedMessage id="general.Name" defaultMessage="Name" />:{' '}
            </b>
          </span>
          <span>
            {patient.firstName} {patient.lastName}
          </span>
        </div>
      </div>
    );
  }

  renderPatientRow(patient) {
    return (
      <TR
        key={`${patient.id}-bigscreen`}
        className={styles.tr}
        onClick={this.navigateToPatient.bind(this, patient)}
      >
        <TD dataCy={this.props.dataCy && `${this.props.dataCy}-id`}>{patient.patientIdentifier}</TD>
        <TD>
          <span data-cy={this.props.dataCy && `${this.props.dataCy}-firstname`}>
            {patient.firstName}
          </span>{' '}
          <span data-cy={this.props.dataCy && `${this.props.dataCy}-lastname`}>
            {patient.lastName}
          </span>
        </TD>
        <TD
          dataCy={this.props.dataCy && `${this.props.dataCy}-last-modified`}
          className={styles.lastChangedTd}
        >
          <FormattedTime value={patient.lastModified} month="short" day="2-digit" year="numeric" />
        </TD>
        <TD
          className={styles.tdButton}
          onClick={this.navigate.bind(
            this,
            `usergroup/${patient.sourceUserGroupId}/patients/${patient.id}/consultation`
          )}
        >
          {patient.consultations.length > 0 && <i className={styles.button}>patient_records</i>}
        </TD>
      </TR>
    );
  }

  renderPatientRows(patients) {
    return <TableBody>{patients.map((patient) => this.renderPatientRow(patient))}</TableBody>;
  }

  renderTableHeaders() {
    return (
      <TableHead>
        <TR header className={styles.tableHead}>
          <TH>
            <FormattedMessage id="patientList.patientID" defaultMessage="Patient ID" />
          </TH>
          <TH>
            <FormattedMessage id="patientList.patientName" defaultMessage="Patient Name" />
          </TH>
          <TH>
            <FormattedMessage id="patientList.lastChange" defaultMessage="Last Change" />
          </TH>
          <TH className={styles.consultationTh}>
            <FormattedMessage id="patientList.consultation" defaultMessage="Consultation" />
          </TH>
        </TR>
      </TableHead>
    );
  }

  render() {
    return (
      <>
        <div>
          {this.props.patients &&
            this.props.patients.map((patient) => this.renderPatientRowsOnMobile(patient))}
        </div>
        <Table>
          {this.renderTableHeaders()}
          {this.renderPatientRows(this.props.patients)}
        </Table>
      </>
    );
  }
}

export default withRouter(PatientRecordsTable);
