import * as React from 'react';

function SvgDrawLines(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.414 14.414" {...props}>
      <path
        id="Path_1121"
        data-name="Path 1121"
        d="M0,13,13,0"
        transform="translate(0.707 0.707)"
        fill="none"
        strokeLinecap="round"
        strokeWidth=".8"
      />
    </svg>
  );
}

export default SvgDrawLines;
