import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import {
  ProcedureStep,
  ADD_STEP,
  REMOVE_STEP,
  MOVE_STEP_WITHIN_VISIT,
  MOVE_STEP_BETWEEN_VISITS,
  UPDATE_STEP,
} from '../../../../../../redux/slices/procedureSlice';
import { StepPreviewImage } from './StepPreviewImage';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import CircularButton from '../../../../../Shared/CircularButton/CircularButton';
import { ActionCreators } from 'redux-undo';

export const Visit: React.FC<{
  handleTakeScreenShot: () => Promise<string | undefined>;
  procedureStepStates: ProcedureStep[];
  onClickImage?: (stepIndex: number, visitIndex: number) => void;
  hasUnsavedChanges: boolean;
  visitIndex: number;
  visitName: string;
  isHovered: boolean;
  setHoveredVisit: (visitIndex: number | undefined) => void;
  setIsStepDragged: (isStepDragged: boolean) => void;
  visitOffsetArray: number[];
}> = ({
  procedureStepStates,
  onClickImage,
  hasUnsavedChanges,
  visitIndex,
  handleTakeScreenShot,
  visitName,
  isHovered,
  setHoveredVisit,
  setIsStepDragged,
  visitOffsetArray,
}) => {
  const dispatch = useAppDispatch();
  const currentVisit = useAppSelector((state) => state.consultation.procedure.currentVisit);
  const currentStep = useAppSelector((state) => state.consultation.procedure.currentStep);
  const workspacePresent = useAppSelector((state) => state.consultation.workspace.present);
  const workspacePastLength = useAppSelector((state) => state.consultation.workspace.past.length);
  const workspaceFutureLength = useAppSelector(
    (state) => state.consultation.workspace.future.length
  );
  const intl = useIntl();
  const [movingStep, setMovingStep] = useState<
    | {
        index: number;
        newIndex: number;
      }
    | undefined
  >(undefined);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (visitIndex === currentVisit) {
      if (
        workspacePastLength === 0 &&
        workspaceFutureLength === 0 &&
        procedureStepStates[currentStep]?.previewImageSrc
      )
        return;
      if (timer) clearTimeout(timer);
      let mounted = true;
      const id = setTimeout(() => {
        handleTakeScreenShot().then((screenshot) => {
          if (!mounted) return;
          dispatch(
            UPDATE_STEP({
              updateData: {
                previewImageSrc: screenshot,
              },
            })
          );
        });
      }, 2000);
      setTimer(id);
      return () => {
        mounted = false;
        clearTimeout(id);
      };
    }
  }, [workspacePastLength, workspaceFutureLength]);

  const visitHeader = 23; // px
  const stepHeight = 60; // px

  const getHoveredVisitIndex = (draggedStepIndex: number, yOffsetOfDraggedStep: number) => {
    const stepYPositionInRealationToItsVisit = visitHeader + stepHeight * draggedStepIndex;
    const stepYPositionInRelationToPage =
      visitIndex === 0
        ? stepYPositionInRealationToItsVisit
        : visitOffsetArray[visitIndex - 1] + stepYPositionInRealationToItsVisit;

    const currentPosition = stepYPositionInRelationToPage + yOffsetOfDraggedStep;

    let result;
    for (let i = 0; i <= visitOffsetArray.length - 1; i++) {
      if (currentPosition < visitOffsetArray[i]) {
        result = i;
        break;
      }
    }
    return result;
  };

  return (
    <StyledVisitWrapper hasUnsavedChanges={hasUnsavedChanges} isHovered={isHovered}>
      <div className="visitHeader">
        <p data-cy={`visit-header-${visitIndex}`}>{visitName}</p>
      </div>
      {procedureStepStates?.map((step, index) => {
        let translate = 0;
        if (movingStep) {
          if (index < movingStep.index && index >= movingStep.newIndex) translate = 70;
          else if (index > movingStep.index && index <= movingStep.newIndex) translate = -70;
        }
        return (
          <StepPreviewImage
            setIsStepDragged={setIsStepDragged}
            visitIndex={visitIndex}
            key={`preview_step_${index}`}
            index={index}
            step={step}
            selected={index === currentStep && visitIndex === currentVisit}
            translateY={translate}
            onDrag={(index, newIndex, offSet) => {
              setMovingStep({
                index: index,
                newIndex: newIndex,
              });
              setHoveredVisit(getHoveredVisitIndex(index, offSet));
            }}
            onDragStop={(stepindex, offSet) => {
              const hoveredVisitIndex = getHoveredVisitIndex(stepindex, offSet);
              if (hoveredVisitIndex !== visitIndex && hoveredVisitIndex !== undefined) {
                dispatch(
                  MOVE_STEP_BETWEEN_VISITS({
                    stepIndex: index,
                    visitIndex: visitIndex,
                    newVisitIndex: hoveredVisitIndex,
                  })
                );
                setMovingStep(undefined);
              } else if (movingStep && movingStep.index !== movingStep.newIndex) {
                dispatch(
                  MOVE_STEP_WITHIN_VISIT({
                    stepIndex: movingStep.index,
                    newStepIndex: movingStep.newIndex,
                    visitIndex: visitIndex,
                  })
                );
                setMovingStep(undefined);
              } else {
                onClickImage?.(index, visitIndex);
              }
            }}
          />
        );
      })}

      <div className="stepButtonContainer">
        <CircularButton
          dataCy={`consultation-add-step-${visitIndex}`}
          className="stepButton"
          icon="add"
          buttonText={intl.formatMessage({
            id: 'consultation.addStep',
            defaultMessage: 'Add Step',
          })}
          onClick={() => {
            handleTakeScreenShot().then((screenshotImgSrc) => {
              const id = uuidv4();
              dispatch(
                ADD_STEP({
                  id: id,
                  workspace: workspacePresent,
                  screenshot: screenshotImgSrc || '',
                  visitIndex: visitIndex,
                })
              );
              dispatch(ActionCreators.clearHistory());
            });
          }}
        />
        {visitIndex === currentVisit && (
          <CircularButton
            dataCy={`consultation-remove-step`}
            className="stepButton"
            icon="close"
            buttonText={intl.formatMessage({
              id: 'consultation.removeStep',
              defaultMessage: 'Remove Step',
            })}
            onClick={() => {
              dispatch(
                REMOVE_STEP({
                  visitIndex: currentVisit,
                  stepIndex: currentStep,
                })
              );
            }}
          />
        )}
      </div>
    </StyledVisitWrapper>
  );
};

const StyledVisitWrapper = styled.div<{
  hasUnsavedChanges: boolean;
  isHovered: boolean;
}>`
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 8px;
  border: 3px solid ${(props) => `${props.isHovered ? '#E86A92' : '#822973'}`};
  -webkit-transition: border 150ms ease-out;
  -moz-transition: border 150ms ease-out;
  -o-transition: border 150ms ease-out;
  transition: border 150ms ease-out;
  margin-bottom: 5px;
  min-width: 112px;
  min-height: 62px;
  max-height: 10000px;
  overflow: visible;
  height: auto;
  text-align: center;
  .visitHeader {
    cursor: default;
    border-top: none;
    height: 20px;
    background-color: ${(props) => `${props.isHovered ? '#E86A92' : '#822973'}`};
    -webkit-transition: background-color 150ms ease-out;
    -moz-transition: background-color 150ms ease-out;
    -o-transition: background-color 150ms ease-out;
    transition: background-color 150ms ease-out;
    border-radius: 0px 0px 0px 0px;
    margin-bottom: 5px;

    p {
      width: 108px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: center;
      vertical-align: middle;
      color: white;
      margin: auto;
    }
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    height: 60px;
    object-fit: contain;
    opacity: 0.5;
    border-radius: 8px;
    pointer-events: none;

    &:hover {
      opacity: 1;
    }

    &.selected {
      border: 2px solid rgba(128, 0, 128, 0.39);
      opacity: 1;
    }
  }
  .stepButtonContainer {
    display: flex;
    padding: 5px 0px 5px 0px;
    .stepButton {
      flex: 1 1 0px;
    }
  }
`;
