import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { RefObject, useEffect, useState, useRef } from 'react';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useImage = (src: string) => {
  const [image, setLoadedImage] = useState<string | null>(null);
  useEffect(() => {
    setLoadedImage(null);
    if (src) {
      const handleLoad = () => {
        setLoadedImage(src);
      };
      const image = new Image();
      image.addEventListener('load', handleLoad);
      image.src = src;
      return () => {
        image.removeEventListener('load', handleLoad);
      };
    }
  }, [src]);
  return image;
};

export const useImages = (
  sources: string[]
): { loadedImages: HTMLImageElement[]; hasLoaded: boolean } => {
  const [loadedImages, setLoadedImages] = useState<HTMLImageElement[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  useEffect(() => {
    if (sources && !loaded) {
      const promiseArray = sources.map(function (src) {
        const prom = new Promise<void>(function (resolve) {
          const img = new Image();
          img.onload = function () {
            setLoadedImages((images) => [...images, img]);
            resolve();
          };
          img.src = src;
        });
        return prom;
      });
      Promise.all(promiseArray).then(() => setLoaded(true));
    }
  }, [sources, loaded]);

  return {
    loadedImages: loadedImages,
    hasLoaded: loaded,
  };
};

type AnyEvent = MouseEvent | TouchEvent;

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: AnyEvent) => void
) {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const el = ref?.current;

      // Do nothing if clicking ref's element or descendent elements
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };

    // Reload only if ref or handler changes
  }, [ref, handler]);
}

export function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
