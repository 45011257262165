import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';

import { InputItem, InputRow, InputGroup } from '../Shared/Forms';

import * as clinicActions from '../../redux/actions/clinicActions';
import * as referenceDataActions from '../../redux/actions/referenceDataActions';

import styles from './FreeSmartPegs.module.scss';

class FreeSmartPegs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clinicAddress: {
        name: '',
        street: '',
        careOf: '',
        city: '',
        postcode: '',
        countryCode: '',
        state: '',
      },
      selectedSmartPegs: [],
    };

    this.intl = props.intl;

    this.nameChanged = this.nameChanged.bind(this);
    this.streetChanged = this.streetChanged.bind(this);
    this.careOfChanged = this.careOfChanged.bind(this);
    this.cityChanged = this.cityChanged.bind(this);
    this.postcodeChanged = this.postcodeChanged.bind(this);
    this.countryCodeChanged = this.countryCodeChanged.bind(this);
    this.stateChanged = this.stateChanged.bind(this);
    this.renderSmartPegs = this.renderSmartPegs.bind(this);
    this.renderClinicAddress = this.renderClinicAddress.bind(this);
    this.smartPegChanged = this.smartPegChanged.bind(this);
  }

  smartPegChanged(index, option) {
    let selectedSmartPegs = this.state.selectedSmartPegs;
    selectedSmartPegs[index] = option.value;
    this.setState(
      {
        selectedSmartPegs: selectedSmartPegs,
      },
      () => this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  nameChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.name = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  streetChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.street = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  careOfChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.careOf = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  cityChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.city = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  postcodeChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.postcode = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  countryCodeChanged(option) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.countryCode = option.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  stateChanged(evt) {
    let clinicAddress = Object.assign({}, this.state.clinicAddress);
    clinicAddress.state = evt.target.value;
    this.setState({ clinicAddress }, () =>
      this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.clinic.loaded) {
      this.setState(
        (_prevState) => ({
          clinicAddress: {
            name: nextProps.clinic.name,
            street: nextProps.clinic.address.street,
            careOf: nextProps.clinic.address.careOf,
            city: nextProps.clinic.address.city,
            postcode: nextProps.clinic.address.postcode,
            countryCode: nextProps.clinic.address.countryCode,
            state: nextProps.clinic.address.state,
          },
        }),
        () => this.props.sendData(this.state.clinicAddress, this.state.selectedSmartPegs)
      );
    }
  }

  componentDidMount() {
    this.props.actions.getSmartPegData(this.props.user.megagenUser);
    this.props.actions.getClinic();
  }

  renderSmartPegs() {
    let smartPegs = this.props.user.megagenUser
      ? this.props.smartPegsMegagen
      : this.props.smartPegs;
    if (smartPegs && smartPegs.length > 0) {
      let smartPegsOptions = smartPegs
        .sort(function (a, b) {
          if (a.type < b.type) {
            return -1;
          }
          if (a.type > b.type) {
            return 1;
          }
          return 0;
        })
        .map((smartPeg) => {
          return { label: smartPeg.type, value: smartPeg.type };
        });

      const li = [];
      for (let i = 0; i < this.props.quantity; i++) {
        const displayNumber = i + 1;
        li.push(
          <li key={i}>
            <InputItem
              dark
              label={
                this.intl.formatMessage({
                  id: 'freeSmartPegs.selectSmartPeg',
                  defaultMessage: 'Select SmartPeg',
                }) +
                ' ' +
                displayNumber +
                ' (5 pcs)'
              }
              type="search-select"
              options={smartPegsOptions}
              onChange={this.smartPegChanged.bind(this, li.length)}
              value={this.state.selectedSmartPegs[i]}
            />
          </li>
        );
      }

      return (
        <div>
          <ul className={styles.smartPegSelectionList}>{li}</ul>
        </div>
      );
    }

    return null;
  }

  renderClinicAddress() {
    const countries = this.props.countries.map((country, index) => {
      return { label: country.value, value: country.key };
    });
    return (
      <InputGroup
        label={this.intl.formatMessage({
          id: 'freeSmartPegs.shipTo',
          defaultMessage: 'Ship to (clinic address)',
        })}
      >
        <InputRow>
          <InputItem
            dark
            label={this.intl.formatMessage({ id: 'general.name', defaultMessage: 'Name' })}
            type="text"
            value={this.state.clinicAddress.name}
            onChange={this.nameChanged}
          />
        </InputRow>
        <InputRow>
          <InputItem
            dark
            label={this.intl.formatMessage({
              id: 'general.address',
              defaultMessage: 'Address',
            })}
            type="text"
            value={this.state.clinicAddress.street}
            onChange={this.streetChanged}
          />
          <InputItem
            dark
            label={this.intl.formatMessage({ id: 'general.co', defaultMessage: 'Co' })}
            type="text"
            value={this.state.clinicAddress.careOf}
            onChange={this.careOfChanged}
          />
        </InputRow>
        <InputRow>
          <InputItem
            dark
            label={this.intl.formatMessage({ id: 'general.zipCode', defaultMessage: 'Zip code' })}
            type="text"
            value={this.state.clinicAddress.postcode}
            onChange={this.postcodeChanged}
          />
          <InputItem
            dark
            label={this.intl.formatMessage({ id: 'general.state', defaultMessage: 'State' })}
            type="text"
            onChange={this.stateChanged}
            value={this.state.clinicAddress.state}
          />
          <InputItem
            dark
            label={this.intl.formatMessage({ id: 'general.city', defaultMessage: 'City' })}
            type="text"
            onChange={this.cityChanged}
            value={this.state.clinicAddress.city}
          />
        </InputRow>
        <InputRow>
          <InputItem
            dark
            label={this.intl.formatMessage({
              id: 'general.country',
              defaultMessage: 'Country',
            })}
            type="search-select"
            options={countries}
            onChange={this.countryCodeChanged}
            value={this.state.clinicAddress.countryCode}
          />
        </InputRow>
        <InputRow>
          <p className={styles.infoText}>
            <FormattedMessage
              id="freeSmartPegs.shippingInformation"
              defaultMessage="The SmartPegs will be sent by mail. Please consider that the delivery may take from three to five weeks. The order may be sent from one of our local partners."
            />
          </p>
        </InputRow>
      </InputGroup>
    );
  }

  render() {
    return (
      <div>
        {this.renderSmartPegs()}
        <div>{this.renderClinicAddress()}</div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.user,
    clinic: state.clinic.clinic,
    smartPegs: state.referenceData.smartPegData,
    smartPegsMegagen: state.referenceData.smartPegDataMegagen,
    countries: state.referenceData.countries,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...clinicActions, ...referenceDataActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(FreeSmartPegs));
