import { UPDATE_SMAILE_NODE } from '../../../../redux/slices/workspaceSlice';
import { useCallback, useEffect, useRef, useState } from 'react';
import Rive, { Artboard, CanvasRenderer, LinearAnimationInstance, RiveCanvas } from 'rive-canvas';
import Konva from 'konva';
import { Image, Layer } from 'react-konva';
import { useAppDispatch, useAppSelector } from '../../../../utils/hooks';
import useImage from 'use-image';
import NodeScale from '../../../../common/graphics/consultation/NodeScale.svg';
import NodeTranslate from '../../../../common/graphics/consultation/NodeTranslate.svg';

// @ts-ignore
import smileview from '../../../../shared/videos/smileview-skintones.riv';
import { Position } from '../../../../redux/slices/data-structures/workspaceStructure';

export function SmileRiveCanvas({
  setLoading,
  globalTransform,
  width,
  height,
}: {
  setLoading: (loading: boolean) => void;
  globalTransform: {
    offsetX: number;
    offsetY: number;
    x: number;
    y: number;
    centeredScaling: boolean;
    scale: {
      x: number;
      y: number;
    };
  };
  width: number;
  height: number;
}) {
  const _rive = useRef<RiveCanvas | null>(null);
  const _renderer = useRef<CanvasRenderer | null>(null);
  const _artboard = useRef<Artboard | null>(null);
  const animations = useRef<Record<string, LinearAnimationInstance> | null>(null);
  const smileOverlay = useRef<Konva.Layer>(null);
  const [dragMode, setDragMode] = useState(true);

  const { nodes } = useAppSelector((state) => state.consultation.workspace.present.smileState[0]);
  const [nodePositions, setNodePositions] = useState<Array<Position>>(nodes);
  const skintone = useAppSelector((state) => state.consultation.workspace.present.view.skintone);
  const dispatch = useAppDispatch();

  const [scaleImg] = useImage(NodeScale);
  const [translateImg] = useImage(NodeTranslate);

  useEffect(() => {
    setNodePositions(nodes);
  }, [nodes]);

  const update = useCallback(
    function update() {
      if (animations.current) {
        animations.current.ScaleX.time =
          (Math.abs(nodePositions[1].x - nodePositions[0].x) - 160) / 220;
        animations.current.ScaleX.apply(_artboard.current!, 1.0);
        animations.current.UpperboneTranslate.time =
          Math.abs(nodePositions[1].y + 32 - nodePositions[0].y) / 220;
        animations.current.UpperboneTranslate.apply(_artboard.current!, 1.0);
        /*        animations.current.LowerboneTranslate.time = (nodePositions[0].y - 100) / 220;
        animations.current.LowerboneTranslate.apply(_artboard.current!, 1.0);*/
        animations.current.GlobalX.time = (nodePositions[1].x - 7.5 - 320) / 200;
        animations.current.GlobalX.apply(_artboard.current!, 1.0);
        animations.current.GlobalY.time =
          (nodePositions[1].y + 32 - (nodePositions[0].y - 100) / 220) / 300;
        animations.current.GlobalY.apply(_artboard.current!, 1.0);
        animations.current.SkinMix.time = skintone / 60 + 0.001;
        animations.current.SkinMix.apply(_artboard.current!, 1.0);
      }
      setLoading(true);
    },
    [setLoading, nodePositions, skintone]
  );

  const render = useCallback(
    function render() {
      const ctx = smileOverlay.current!.canvas._canvas.getContext('2d');
      if (ctx && _rive.current && _artboard.current && _renderer.current) {
        ctx.save();
        _renderer.current.align(
          _rive.current.Fit.scaleDown,
          _rive.current.Alignment.topCenter,
          {
            minX: 0,
            minY: 0,
            maxX: width ?? 960,
            maxY: height ?? 600,
          },
          _artboard.current.bounds
        );

        _artboard.current.advance(0);
        _artboard.current.draw(_renderer.current);
        ctx.restore();
      }
      setLoading(false);
    },
    [setLoading, _renderer]
  );

  useEffect(() => {
    if (smileOverlay.current) {
      setLoading(true);
      Rive({
        locateFile: (file) => 'file://' + file,
      }).then((rive) => {
        _rive.current = rive;
        const req = new Request(smileview);
        fetch(req)
          .then((res) => {
            return res.arrayBuffer();
          })
          .then((buf) => {
            const file = rive.load(new Uint8Array(buf));
            const artboard = file.defaultArtboard();
            _artboard.current = artboard;
            if (!smileOverlay.current) return;

            //Load all animations into a Record
            animations.current = {
              ScaleX: new rive.LinearAnimationInstance(artboard.animationByName('ScaleX')),
              GlobalX: new rive.LinearAnimationInstance(artboard.animationByName('GlobalX')),
              GlobalY: new rive.LinearAnimationInstance(artboard.animationByName('GlobalY')),
              /*           LowerboneTranslate: new rive.LinearAnimationInstance(
                artboard.animationByName('LowerBoneTranslate')
              ),*/
              UpperboneTranslate: new rive.LinearAnimationInstance(
                artboard.animationByName('UpperBoneTranslate')
              ),
              SkinMix: new rive.LinearAnimationInstance(artboard.animationByName('SkinMix')),
            };

            const canvas = smileOverlay.current.canvas._canvas;
            const ctx = canvas.getContext('2d');
            if (!ctx) return;
            _renderer.current = new rive.CanvasRenderer(ctx);

            //Initial Render
            update();
            artboard.advance(0);
            render();
          });
      });
    }
  }, []);

  useEffect(() => {
    if (
      _rive.current &&
      _renderer.current &&
      _artboard.current &&
      smileOverlay.current &&
      animations.current
    ) {
      const ctx = smileOverlay.current.canvas._canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(
          0,
          0,
          width ?? smileOverlay.current.canvas._canvas.width,
          height ?? smileOverlay.current.canvas._canvas.height
        );
        _artboard.current?.advance(1);
        update();
        render();
      }
    }
  });
  /*  [nodePositions, _rive, _renderer, _artboard, animations, smileOverlay, render, update]*/

  return (
    <>
      <Layer ref={smileOverlay} listening={false} />
      <Layer
        {...globalTransform}
        ref={(el) => {
          if (el) {
            el.canvas._canvas.setAttribute('data-html2canvas-ignore', 'true');
          }
        }}
      >
        {nodePositions.map((node: Position, i: number) => {
          return (
            <Image
              key={`smile_node_${i}`}
              image={i === 0 ? scaleImg : translateImg}
              x={node.x}
              y={node.y}
              dragBoundFunc={(pos) => {
                if (i === 1) {
                  return {
                    x: Math.min(Math.max(360, pos.x), 560),
                    y: Math.min(Math.max(10, pos.y), 260),
                  };
                } else {
                  return {
                    x: Math.min(
                      Math.max(nodePositions[1].x - 300, pos.x),
                      nodePositions[1].x + 400
                    ),
                    y: Math.min(
                      Math.max(nodePositions[1].y - 100, pos.y, -70),
                      nodePositions[1].y + 270
                    ),
                  };
                }
              }}
              onDragMove={(e) => {
                setNodePositions((prevState) => {
                  const updatedPos = [...prevState];
                  const deltaX = updatedPos[i].x - e.target.attrs.x;
                  const deltaY = updatedPos[i].y - e.target.attrs.y;

                  updatedPos[i] = {
                    x: Math.round(e.target.attrs.x),
                    y: Math.round(e.target.attrs.y),
                  };

                  if (i === 1) {
                    updatedPos[0] = {
                      x: Math.round(updatedPos[0].x - deltaX),
                      y: Math.round(updatedPos[0].y - deltaY),
                    };
                  }
                  return updatedPos;
                });
              }}
              onDragEnd={(_e) => {
                dispatch(UPDATE_SMAILE_NODE({ nodes: nodePositions }));
              }}
              draggable={dragMode}
              onClick={() => setDragMode((dragMode) => !dragMode)}
              opacity={dragMode ? 1 : 0.25}
            />
          );
        })}
      </Layer>
    </>
  );
}
