export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS';
export const GET_TRANSLATIONS_ERROR = 'GET_TRANSLATIONS_ERROR';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const GET_EULA = 'GET_EULA';
export const GET_EULA_SUCCESS = 'GET_EULA_SUCCESS';
export const GET_EULA_ERROR = 'GET_EULA_ERROR';

export const ACCEPT_EULA = 'ACCEPT_EULA';
export const ACCEPT_EULA_SUCCESS = 'ACCEPT_EULA_SUCCESS';
export const ACCEPT_EULA_ERROR = 'ACCEPT_EULA_ERROR';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_ERROR = 'GET_COUNTRIES_ERROR';

export const GET_DENTAL_NOTIFICATIONS = 'GET_DENTAL_NOTIFICATIONS';
export const GET_DENTAL_NOTIFICATIONS_SUCCESS = 'GET_DENTAL_NOTIFICATIONS_SUCCESS';
export const GET_DENTAL_NOTIFICATIONS_ERROR = 'GET_DENTAL_NOTIFICATIONS_ERROR';

export const GET_IMPLANT_DATA = 'GET_IMPLANT_DATA';
export const GET_IMPLANT_DATA_SUCCESS = 'GET_IMPLANT_DATA_SUCCESS';
export const GET_IMPLANT_DATA_ERROR = 'GET_IMPLANT_DATA_ERROR';

export const GET_ABUTMENT_DATA = 'GET_ABUTMENT_DATA';
export const GET_ABUTMENT_DATA_SUCCESS = 'GET_ABUTMENT_DATA_SUCCESS';
export const GET_ABUTMENT_DATA_ERROR = 'GET_ABUTMENT_DATA_ERROR';

export const GET_SMARTPEG_DATA = 'GET_SMARTPEG_DATA';
export const GET_SMARTPEG_DATA_SUCCESS = 'GET_SMARTPEG_DATA_SUCCESS';
export const GET_SMARTPEG_DATA_ERROR = 'GET_SMARTPEG_DATA_ERROR';

export const GET_REFLIST_DATA = 'GET_REFLIST_DATA';
export const GET_REFLIST_DATA_SUCCESS = 'GET_REFLIST_DATA_SUCCESS';
export const GET_REFLIST_DATA_ERROR = 'GET_REFLIST_DATA_ERROR';

export const GET_STUDY_IMPLANT_DATA = 'GET_STUDY_IMPLANT_DATA';
export const GET_STUDY_IMPLANT_DATA_SUCCESS = 'GET_STUDY_IMPLANT_DATA_SUCCESS';
export const GET_STUDY_IMPLANT_DATA_ERROR = 'GET_STUDY_IMPLANT_DATA_ERROR';

export const GET_STUDY_ABUTMENT_DATA = 'GET_STUDY_ABUTMENT_DATA';
export const GET_STUDY_ABUTMENT_DATA_SUCCESS = 'GET_STUDY_ABUTMENT_DATA_SUCCESS';
export const GET_STUDY_ABUTMENT_DATA_ERROR = 'GET_STUDY_ABUTMENT_DATA_ERROR';

export const UPDATE_IMPLANT_DATA = 'UPDATE_IMPLANT_DATA';
export const UPDATE_IMPLANT_DATA_SUCCESS = 'UPDATE_IMPLANT_DATA_SUCCESS';
export const UPDATE_IMPLANT_DATA_ERROR = 'UPDATE_IMPLANT_DATA_ERROR';

export const UPDATE_ABUTMENT_DATA = 'UPDATE_ABUTMENT_DATA';
export const UPDATE_ABUTMENT_DATA_SUCCESS = 'UPDATE_ABUTMENT_DATA_SUCCESS';
export const UPDATE_ABUTMENT_DATA_ERROR = 'UPDATE_ABUTMENT_DATA_ERROR';

export const RELEASE_REFLIST = 'RELEASE_REFLIST';
export const RELEASE_REFLIST_SUCCESS = 'RELEASE_REFLIST_SUCCESS';
export const RELEASE_REFLIST_ERROR = 'RELEASE_REFLIST_ERROR';

export const REGISTER_ACCOUNT = 'REGISTER_ACCOUNT';
export const REGISTER_ACCOUNT_SUCCESS = 'REGISTER_ACCOUNT_SUCCESS';
export const REGISTER_ACCOUNT_ERROR = 'REGISTER_ACCOUNT_ERROR';

export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_ERROR = 'CONFIRM_ACCOUNT_ERROR';

export const LOGIN_ACCOUNT = 'LOGIN_ACCOUNT';
export const LOGIN_ACCOUNT_SUCCESS = 'LOGIN_ACCOUNT_SUCCESS';
export const LOGIN_ACCOUNT_ERROR = 'LOGIN_ACCOUNT_ERROR';

export const RESET_ACCOUNT_PASSWORD = 'RESET_ACCOUNT_PASSWORD';
export const RESET_ACCOUNT_PASSWORD_SUCCESS = 'RESET_ACCOUNT_PASSWORD_SUCCESS';
export const RESET_ACCOUNT_PASSWORD_ERROR = 'RESET_ACCOUNT_PASSWORD_ERROR';

export const CLEAR_TOKEN = 'CLEAR_TOKEN';

export const ADD_TOKEN = 'ADD_TOKEN';

export const INACTIVITY_WARNING = 'INACTIVITY_WARNING';

export const RETRIEVE_PASSWORD = 'RETRIEVE_PASSWORD';
export const RETRIEVE_PASSWORD_SUCCESS = 'RETRIEVE_PASSWORD_SUCCESS';
export const RETRIEVE_PASSWORD_ERROR = 'RETRIEVE_PASSWORD_ERROR';

export const REAUTHENTICATE = 'REAUTHENTICATE';
export const REAUTHENTICATE_SUCCESS = 'REAUTHENTICATE_SUCCESS';
export const REAUTHENTICATE_ERROR = 'REAUTHENTICATE_ERROR';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UDPATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const CHANGE_USER_ROLE = 'CHANGE_USER_ROLE';
export const CHANGE_USER_ROLE_SUCCESS = 'CHANGE_USER_ROLE_SUCCESS';
export const CHANGE_USER_ROLE_ERROR = 'CHANGE_USER_ROLE_ERROR';

export const DISMISS_NOTE = 'DISMISS_NOTE';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const CLEAR_USER = 'CLEAR_USER';
export const CLEAR_USERS = 'CLEAR_USERS';

export const GET_USER_GROUPS = 'GET_USER_GROUPS';
export const GET_USER_GROUPS_SUCCESS = 'GET_USER_GROUPS_SUCCESS';
export const GET_USER_GROUPS_ERROR = 'GET_USER_GROUPS_ERROR';

export const CONNECT_USER_GROUP_TO_INSTRUMENTS = 'CONNECT_USER_GROUP_TO_INSTRUMENTS';
export const CONNECT_USER_GROUP_TO_INSTRUMENTS_SUCCESS =
  'CONNECT_USER_GROUP_TO_INSTRUMENTS_SUCCESS';
export const CONNECT_USER_GROUP_TO_INSTRUMENTS_ERROR = 'CONNECT_USER_GROUP_TO_INSTRUMENTS_ERROR';

export const GET_STUDY_GROUPS = 'GET_STUDY_GROUPS';
export const GET_STUDY_GROUPS_SUCCESS = 'GET_STUDY_GROUPS_SUCCESS';
export const GET_STUDY_GROUPS_ERROR = 'GET_STUDY_GROUPS_ERROR';

export const UPDATE_CLINIC = 'UPDATE_CLINIC';
export const UPDATE_CLINIC_SUCCESS = 'UDPATE_CLINIC_SUCCESS';
export const UPDATE_CLINIC_ERROR = 'UPDATE_CLINIC_ERROR';

export const UPDATE_REFERRALS = 'UPDATE_REFERRALS';
export const UPDATE_REFERRALS_SUCCESS = 'UDPATE_REFERRALS_SUCCESS';
export const UPDATE_REFERRALS_ERROR = 'UPDATE_REFERRALS_ERROR';

export const GET_CLINIC = 'GET_CLINIC';
export const GET_CLINIC_SUCCESS = 'GET_CLINIC_SUCCESS';
export const GET_CLINIC_ERROR = 'GET_CLINIC_ERROR';

export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_ERROR = 'GET_PATIENTS_ERROR';

export const GET_PATIENT = 'GET_PATIENT';
export const GET_PATIENT_SUCCESS = 'GET_PATIENT_SUCCESS';
export const GET_PATIENT_ERROR = 'GET_PATIENT_ERROR';

export const DELETE_PATIENT = 'DELETE_PATIENT';
export const DELETE_PATIENT_SUCCESS = 'DELETE_PATIENT_SUCCESS';
export const DELETE_PATIENT_ERROR = 'DELETE_PATIENT_ERROR';

export const CREATE_PATIENT = 'CREATE_PATIENT';
export const CREATE_PATIENT_SUCCESS = 'CREATE_PATIENT_SUCCESS';
export const CREATE_PATIENT_ERROR = 'CREATE_PATIENT_ERROR';

export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const UPDATE_PATIENT_SUCCESS = 'UPDATE_PATIENT_SUCCESS';
export const UPDATE_PATIENT_ERROR = 'UPDATE_PATIENT_ERROR';

export const GET_PATIENT_DENTAL_CHART = 'GET_PATIENT_DENTAL_CHART';
export const GET_PATIENT_DENTAL_CHART_SUCCESS = 'GET_PATIENT_DENTAL_CHART_SUCCESS';
export const GET_PATIENT_DENTAL_CHART_ERROR = 'GET_PATIENT_DENTAL_CHART_ERROR';

export const GET_PATIENT_IMPLANTS = 'GET_PATIENT_IMPLANTS';
export const GET_PATIENT_IMPLANTS_SUCCESS = 'GET_PATIENT_IMPLANTS_SUCCESS';
export const GET_PATIENT_IMPLANTS_ERROR = 'GET_PATIENT_IMPLANTS_ERROR';

export const GET_PATIENTS_DENTAL_CHART_ERROR = 'GET_PATIENTS_DENTAL_CHART_ERROR';

export const SEARCH_PATIENTS = 'SEARCH_PATIENTS';
export const SEARCH_PATIENTS_SUCCESS = 'SEARCH_PATIENTS_SUCCESS';
export const SEARCH_PATIENTS_ERROR = 'SEARCH_PATIENTS_ERROR';

export const SELECT_PATIENT_IMPLANT = 'SELECT_PATIENT_IMPLANT';

export const CREATE_MEASUREMENT = 'CREATE_MEASUREMENT';
export const CREATE_MEASUREMENT_SUCCESS = 'CREATE_MEASUREMENT_SUCCESS';
export const CREATE_MEASUREMENT_ERROR = 'CREATE_MEASUREMENT_ERROR';

export const DELETE_MEASUREMENT = 'DELETE_MEASUREMENT';
export const DELETE_MEASUREMENT_SUCCESS = 'DELETE_MEASUREMENT_SUCCESS';
export const DELETE_MEASUREMENT_ERROR = 'DELETE_MEASUREMENT_ERROR';

export const CREATE_IMPLANT = 'CREATE_IMPLANT';
export const CREATE_IMPLANT_SUCCESS = 'CREATE_IMPLANT_SUCCESS';
export const CREATE_IMPLANT_ERROR = 'CREATE_IMPLANT_ERROR';

export const DELETE_IMPLANT = 'DELETE_IMPLANT';
export const DELETE_IMPLANT_SUCCESS = 'DELETE_IMPLANT_SUCCESS';
export const DELETE_IMPLANT_ERROR = 'DELETE_IMPLANT_ERROR';

export const SET_IMPLANT_DATA = 'SET_IMPLANT_DATA';
export const SET_IMPLANT_DATA_SUCCESS = 'SET_IMPLANT_DATA_SUCCESS';
export const SET_IMPLANT_DATA_ERROR = 'SET_IMPLANT_DATA_ERROR';

export const SET_IMPLANT_FAILED = 'SET_IMPLANT_FAILED';
export const SET_IMPLANT_FAILED_SUCCESS = 'SET_IMPLANT_FAILED_SUCCESS';
export const SET_IMPLANT_FAILED_ERROR = 'SET_IMPLANT_FAILED_ERROR';

export const GET_USED_IMPLANTS = 'GET_USED_IMPLANTS';
export const GET_USED_IMPLANTS_SUCCESS = 'GET_USED_IMPLANTS_SUCCESS';
export const GET_USED_IMPLANTS_ERROR = 'GET_USED_IMPLANTS_ERROR';

export const RECEIVE_MEASUREMENT = 'RECEIVE_MEASUREMENT';
export const EXPORT_PATIENTS = 'EXPORT_PATIENTS';

export const EXPORT_PATIENT = 'EXPORT_PATIENT';
export const EXPORT_PATIENT_SUCCESS = 'EXPORT_PATIENT_SUCCESS';
export const EXPORT_PATIENT_ERROR = 'EXPORT_PATIENT_ERROR';

export const RESTORE_DEMO_PATIENTS = 'RESTORE_DEMO_PATIENTS';
export const RESTORE_DEMO_PATIENTS_SUCCESS = 'RESTORE_DEMO_PATIENTS_SUCCESS';
export const RESTORE_DEMO_PATIENTS_ERROR = 'RESTORE_DEMO_PATIENTS_ERROR';

export const GET_INSTRUMENTS = 'GET_INSTRUMENTS';
export const GET_INSTRUMENTS_SUCCESS = 'GET_INSTRUMENTS_SUCCESS';
export const GET_INSTRUMENTS_ERROR = 'GET_INSTRUMENTS_ERROR';

export const REGISTER_INSTRUMENT = 'REGISTER_INSTRUMENT';
export const REGISTER_INSTRUMENT_SUCCESS = 'REGISTER_INSTRUMENT_SUCCESS';
export const REGISTER_INSTRUMENT_ERROR = 'REGISTER_INSTRUMENT_ERROR';

export const DELETE_INSTRUMENTS = 'DELETE_INSTRUMENTS';
export const DELETE_INSTRUMENTS_SUCCESS = 'DELETE_INSTRUMENTS_SUCCESS';
export const DELETE_INSTRUMENTS_ERROR = 'DELETE_INSTRUMENTS_ERROR';

export const SET_INSTRUMENT_EXPECTED_FIRMWARES = 'SET_INSTRUMENT_EXPECTED_FIRMWARES';
export const SET_INSTRUMENT_EXPECTED_FIRMWARES_SUCCESS =
  'SET_INSTRUMENT_EXPECTED_FIRMWARES_SUCCESS';
export const SET_INSTRUMENT_EXPECTED_FIRMWARES_ERROR = 'SET_INSTRUMENT_EXPECTED_FIRMWARES_ERROR';

export const SHARE_INSTRUMENT_PATIENTS = 'SHARE_INSTRUMENT_PATIENTS';
export const SHARE_INSTRUMENT_PATIENTS_SUCCESS = 'SHARE_INSTRUMENT_PATIENTS_SUCCESS';
export const SHARE_INSTRUMENT_PATIENTS_ERROR = 'SHARE_INSTRUMENT_PATIENTS_ERROR';

export const SET_INSTRUMENTS_TYPE = 'SET_INSTRUMENTS_TYPE';
export const SET_INSTRUMENTS_TYPE_SUCCESS = 'SET_INSTRUMENTS_TYPE_SUCCESS';
export const SET_INSTRUMENTS_TYPE_ERROR = 'SET_INSTRUMENTS_TYPE_ERROR';

export const SET_INSTRUMENT_NAME = 'SET_INSTRUMENT_NAME';
export const SET_INSTRUMENT_NAME_SUCCESS = 'SET_INSTRUMENT_NAME_SUCCESS';
export const SET_INSTRUMENT_NAME_ERROR = 'SET_INSTRUMENT_NAME_ERROR';

export const CLEAR_REGISTER_INSTRUMENT = 'CLEAR_REGISTER_INSTRUMENT';

export const SET_INSTRUMENTS_COMMENT = 'SET_INSTRUMENTS_COMMENT';
export const SET_INSTRUMENTS_COMMENT_SUCCESS = 'SET_INSTRUMENTS_COMMENT_SUCCESS';
export const SET_INSTRUMENTS_COMMENT_ERROR = 'SET_INSTRUMENTS_COMMENT_ERROR';

export const CLEAR_INSTRUMENTS = 'CLEAR_INSTRUMENTS';

export const GET_INSTRUMENT_LOG = 'GET_INSTRUMENT_LOG';
export const GET_INSTRUMENT_LOG_SUCCESS = 'GET_INSTRUMENT_LOG_SUCCESS';
export const GET_INSTRUMENT_LOG_ERROR = 'GET_INSTRUMENT_LOG_ERROR';

export const GET_ADMIN_LOG = 'GET_ADMIN_LOG';
export const GET_ADMIN_LOG_SUCCESS = 'GET_ADMIN_LOG_SUCCESS';
export const GET_ADMIN_LOG_ERROR = 'GET_ADMIN_LOG_ERROR';

export const CLEAR_LOGS = 'CLEAR_LOGS';

export const RECEIVE_INSTRUMENT_STATUS = 'RECEIVE_INSTRUMENT_STATUS';

export const GET_FIRMWARES = 'GET_FIRMWARES';
export const GET_FIRMWARES_SUCCESS = 'GET_FIRMWARES_SUCCESS';
export const GET_FIRMWARES_ERROR = 'GET_FIRMWARES_ERROR';

export const REGISTER_FIRMWARE = 'REGISTER_FIRMWARE';
export const REGISTER_FIRMWARE_SUCCESS = 'REGISTER_FIRMWARE_SUCCESS';
export const REGISTER_FIRMWARE_ERROR = 'REGISTER_FIRMWARE_ERROR';

export const DOWNLOAD_FIRMWARE = 'DOWNLOAD_FIRMWARE';
export const DOWNLOAD_FIRMWARE_SUCCESS = 'DOWNLOAD_FIRMWARE_SUCCESS';
export const DOWNLOAD_FIRMWARE_ERROR = 'DOWNLOAD_FIRMWARE_ERROR';

export const SET_FIRMWARE_TO_CURRENT = 'SET_FIRMWARE_TO_CURRENT';
export const SET_FIRMWARE_TO_CURRENT_SUCCESS = 'SET_FIRMWARE_TO_CURRENT_SUCCESS';
export const SET_FIRMWARE_TO_CURRENT_ERROR = 'SET_FIRMWARE_TO_CURRENT_ERROR';

export const GET_INTEGRATIONS = 'GET_INTEGRATIONS';
export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';
export const GET_INTEGRATIONS_ERROR = 'GET_INTEGRATIONS_ERROR';

export const REGISTER_INTEGRATION = 'REGISTER_INTEGRATION';
export const REGISTER_INTEGRATION_SUCCESS = 'REGISTER_INTEGRATION_SUCCESS';
export const REGISTER_INTEGRATION_ERROR = 'REGISTER_INTEGRATION_ERROR';

export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const DELETE_INTEGRATION_ERROR = 'DELETE_INTEGRATION_ERROR';

export const GET_KEY_PERFORMANCE_INDICATORS = 'GET_KEY_PERFORMANCE_INDICATORS';
export const GET_KEY_PERFORMANCE_INDICATORS_SUCCESS = 'GET_KEY_PERFORMANCE_INDICATORS_SUCCESS';
export const GET_KEY_PERFORMANCE_INDICATORS_ERROR = 'GET_KEY_PERFORMANCE_INDICATORS_ERROR';

// Tile action constants
export const GET_TILES = 'GET_TILES';
export const GET_TILES_SUCCESS = 'GET_TILES_SUCCESS';
export const GET_TILES_ERROR = 'GET_TILES_ERROR';

export const GET_NEWS_TILE = 'GET_NEWS_TILE';
export const GET_NEWS_TILE_SUCCESS = 'GET_NEWS_TILE_SUCCESS';
export const GET_NEWS_TILE_ERROR = 'GET_NEWS_TILE_ERROR';

export const GET_WELCOME_TILE = 'GET_WELCOME_TILE';
export const GET_WELCOME_TILE_SUCCESS = 'GET_WELCOME_TILE_SUCCESS';
export const GET_WELCOME_TILE_ERROR = 'GET_WELCOME_TILE_ERROR';

export const GET_ACTIVE_PATIENTS_TILE = 'GET_ACTIVE_PATIENTS_TILE';
export const GET_ACTIVE_PATIENTS_TILE_SUCCESS = 'GET_ACTIVE_PATIENTS_TILE_SUCCESS';
export const GET_ACTIVE_PATIENTS_TILE_ERROR = 'GET_ACTIVE_PATIENTS_TILE_ERROR';

export const GET_MEASUREMENTS_TILE = 'GET_MEASUREMENTS_TILE';
export const GET_MEASUREMENTS_TILE_SUCCESS = 'GET_MEASUREMENTS_TILE_SUCCESS';
export const GET_MEASUREMENTS_TILE_ERROR = 'GET_MEASUREMENTS_TILE_ERROR';

export const GET_PATIENTS_TILE = 'GET_PATIENTS_TILE';
export const GET_PATIENTS_TILE_SUCCESS = 'GET_PATIENTS_TILE_SUCCESS';
export const GET_PATIENTS_TILE_ERROR = 'GET_PATIENTS_TILE_ERROR';

export const GET_PLACEMENTS_TILE = 'GET_PLACEMENTS_TILE';
export const GET_PLACEMENTS_TILE_SUCCESS = 'GET_PLACEMENTS_TILE_SUCCESS';
export const GET_PLACEMENTS_TILE_ERROR = 'GET_PLACEMENTS_TILE_ERROR';

export const GET_AVERAGE_HEALING_TIME_TILE = 'GET_AVERAGE_HEALING_TIME_TILE';
export const GET_AVERAGE_HEALING_TIME_TILE_SUCCESS = 'GET_AVERAGE_HEALING_TIME_TILE_SUCCESS';
export const GET_AVERAGE_HEALING_TIME_TILE_ERROR = 'GET_AVERAGE_HEALING_TIME_TILE_ERROR';

export const GET_SUCCESS_RATE_TILE = 'GET_SUCCESS_RATE_TILE';
export const GET_SUCCESS_RATE_TILE_SUCCESS = 'GET_SUCCESS_RATE_TILE_SUCCESS';
export const GET_SUCCESS_RATE_TILE_ERROR = 'GET_SUCCESS_RATE_TILE_ERROR';

export const GET_INSIGHTS_TILE = 'GET_INSIGHTS_TILE';
export const GET_INSIGHTS_TILE_SUCCESS = 'GET_INSIGHTS_TILE_SUCCESS';
export const GET_INSIGHTS_TILE_ERROR = 'GET_INSIGHTS_TILE_ERROR';

export const GET_INSIGHTS = 'GET_INSIGHTS';
export const GET_INSIGHTS_SUCCESS = 'GET_INSIGHTS_SUCCESS';
export const GET_INSIGHTS_ERROR = 'GET_INSIGHTS_ERROR';

export const GET_CLINIC_INSIGHTS = 'GET_CLINIC_INSIGHTS';
export const GET_CLINIC_INSIGHTS_SUCCESS = 'GET_CLINIC_INSIGHTS_SUCCESS';
export const GET_CLINIC_INSIGHTS_ERROR = 'GET_CLINIC_INSIGHTS_ERROR';

export const SHOW_VIDEO = 'SHOW_VIDEO';
export const HIDE_VIDEO = 'HIDE_VIDEO';

export const REGISTER_TRANSLATIONS = 'REGISTER_FIRMWARE';
export const REGISTER_TRANSLATIONS_SUCCESS = 'REGISTER_TRANSLATIONS_SUCCESS';
export const REGISTER_TRANSLATIONS_ERROR = 'REGISTER_TRANSLATIONS_ERROR';

export const SHOW_FREE_SMART_PEGS = 'SHOW_FREE_SMART_PEGS';
export const HIDE_FREE_SMART_PEGS = 'HIDE_FREE_SMART_PEGS';

export const USE_PROMOTION = 'USE_PROMOTION';
export const USE_PROMOTION_SUCCESS = 'USE_PROMOTION_SUCCESS';
export const USE_PROMOTION_ERROR = 'USE_PROMOTION_ERROR';

export const ADD_USER_PROMOTION = 'ADD_USER_PROMOTION';

export const REGISTER_PROMOTION = 'REGISTER_PROMOTION';
export const REGISTER_PROMOTION_SUCCESS = 'REGISTER_PROMOTION_SUCCESS';
export const REGISTER_PROMOTION_ERROR = 'REGISTER_PROMOTION_ERROR';

export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';

// export const GET_APP_SETTINGS = 'GET_APP_SETTINGS';
// export const GET_APP_SETTINGS_SUCCESS = 'GET_APP_SETTINGS_SUCCESS';
// export const GET_APP_SETTINGS_ERROR = 'GET_APP_SETTINGS_ERROR';

export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const HIDE_CONFIRMATION = 'HIDE_CONFIRMATION';

export const GET_INTERNAL_STATS = 'GET_INTERNAL_STATS';
export const GET_INTERNAL_STATS_SUCCESS = 'GET_INTERNAL_STATS_SUCCESS';
export const GET_INTERNAL_STATS_ERROR = 'GET_INTERNAL_STATS_ERROR';

export const GET_USER_STATS = 'GET_USER_STATS';
export const GET_USER_STATS_SUCCESS = 'GET_USER_STATS_SUCCESS';
export const GET_USER_STATS_ERROR = 'GET_USER_STATS_ERROR';
