import * as React from 'react';

function SvgSinusliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1259 2048" {...props}>
      <path d="M1093 422q27 26 42 59.5t20.5 62.5 1.5 74-9 73-16 80l-12 55q-12 59-4 172 10 145-12 262-17 84-60 139t-105 70q-23 5-42 5-99 0-167-87-47-59-263-277l-42.5-42.5-43-43.5-39-40-39.5-40-34-35-33-35-26.5-29.5L185 816l-17-22q-79-107-63-190 15-80 117-145 49-31 113.5-44.5T466 402t146 4q96 4 152 4 88 0 168-25 35-10 54.5-12t47.5 8.5 59 40.5z" />
    </svg>
  );
}

export default SvgSinusliga;
