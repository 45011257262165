import { CanvasImage } from '../TeethCanvasViewport';
import { useAppSelector } from '../../../../../../../utils/hooks';
import SvgStabilityBar from '../../../../../../../shared/images/ISQ_bar.svg';
import OverdentureGumsUpper from '../../../../../../../common/graphics/consultation/overdenture_upper_rive_v=2.png';
import OverdentureGumsLower from '../../../../../../../common/graphics/consultation/overdenture_lower_rive_v=2.png';

import TeethData, {
  calculateFinetuneOffset,
  teethOffset,
} from '../../../../../../../redux/slices/data-structures/teethPositionStructure';
import * as React from 'react';
import { Group } from 'react-konva';
import Konva from 'konva';
import styled from 'styled-components';
import { Html } from 'react-konva-utils';
import { MODAL_ISQ } from '../../../../../../../redux/slices/UISlice';
import { useDispatch } from 'react-redux';

export function TeethCanvasToolsOverlayLayer() {
  const dispatch = useDispatch();
  const abutment = useAppSelector((state) => state.consultation.workspace.present.abutment);
  const bone = useAppSelector((state) => state.consultation.workspace.present.boneState);
  const stability = useAppSelector((state) => state.consultation.workspace.present.stability);
  const overdenture = useAppSelector(
    (state) => state.consultation.workspace.present.teethOverdenture
  );

  return (
    <>
      {TeethData.map((teeth, index) => {
        const waveOffset = Math.sin(((index % 16) * Math.PI) / 16) * 16;
        const upperRow = index < 16;
        const depth = bone[index].graft ? 0 : bone[index].depth * 24;
        const offset = calculateFinetuneOffset(index);

        return (
          <Group key={`overlay_${index}`}>
            {upperRow ? (
              <>
                {stability[index] && (
                  <Group
                    x={teeth.offset + offset}
                    offsetX={-teeth.zoneWidth / 2 + 60}
                    y={100 - depth - waveOffset + (abutment[index] ? 26 : 0) + (index % 2) * 16}
                  >
                    <CanvasImage url={SvgStabilityBar} />
                    <Html
                      divProps={{
                        style: {
                          position: 'absolute',
                          top: '-10px',
                          left: '100px',
                        },
                      }}
                    >
                      <InfoIcon
                        onClick={() => dispatch(MODAL_ISQ(true))}
                        data-html2canvas-ignore={true}
                      >
                        <i>info</i>
                      </InfoIcon>
                    </Html>
                  </Group>
                )}
              </>
            ) : (
              <>
                {stability[index] && (
                  <Group
                    x={teeth.offset - 20 + offset}
                    offsetX={-teeth.zoneWidth + 2 + 54}
                    y={300 + depth + waveOffset / 2 + (abutment[index] ? 0 : 26) + (index % 2) * 16}
                  >
                    <CanvasImage url={SvgStabilityBar} scaleY={-1} />
                    <Html
                      divProps={{
                        style: {
                          position: 'absolute',
                          top: '-10px',
                          left: '100px',
                        },
                      }}
                    >
                      <InfoIcon
                        onClick={() => dispatch(MODAL_ISQ(true))}
                        data-html2canvas-ignore={true}
                      >
                        <i>info</i>
                      </InfoIcon>
                    </Html>
                  </Group>
                )}
              </>
            )}
          </Group>
        );
      })}

      <Group
        clipFunc={function (ctx: Konva.Context) {
          let addToPrevious = false;
          const rects = TeethData.slice(0, 16).reduce<{ x: number; x2: number }[]>(
            (accumulator, teeth, currentIndex) => {
              if (overdenture[currentIndex][1]) {
                const root = teethOffset[teeth.id] ?? { x: 0, w: 0 };
                const x2 = teeth.offset + root.x + teeth.zoneWidth + root.w;
                if (addToPrevious) {
                  accumulator[accumulator.length - 1].x2 = x2;
                } else {
                  accumulator.push({ x: teeth.offset + root.x, x2: x2 });
                }
                addToPrevious = true;
              } else addToPrevious = false;
              return accumulator;
            },
            []
          );
          ctx.beginPath();
          rects.forEach((rect) => ctx.rect(rect.x, 80, rect.x2 - rect.x, 160));
          ctx.closePath();
        }}
      >
        <CanvasImage
          x={6}
          offsetX={0}
          y={-8}
          url={OverdentureGumsUpper}
          width={846}
          opacity={0.5}
        />
      </Group>

      <Group
        clipFunc={function (ctx: Konva.Context) {
          let addToPrevious = false;
          const rects = TeethData.slice(16).reduce<{ x: number; x2: number }[]>(
            (accumulator, teeth, currentIndex) => {
              const index = 16 + currentIndex;
              if (overdenture[index][1]) {
                const root = teethOffset[teeth.id] ?? { x: 0, w: 0 };
                const x2 = teeth.offset + root.x + teeth.zoneWidth + root.w;
                if (addToPrevious) {
                  accumulator[accumulator.length - 1].x2 = x2;
                } else {
                  accumulator.push({ x: teeth.offset + root.x, x2: x2 });
                }
                addToPrevious = true;
              } else addToPrevious = false;
              return accumulator;
            },
            []
          );
          ctx.beginPath();
          rects.forEach((rect) => ctx.rect(rect.x, 170, rect.x2 - rect.x, 180));
          ctx.closePath();
        }}
      >
        <CanvasImage x={8} y={240} url={OverdentureGumsLower} width={839.5} opacity={0.5} />
      </Group>
    </>
  );
}

const InfoIcon = styled.div`
  cursor: pointer;
  font-size: 0.8rem;
  i {
    font-size: 1.5rem;
    color: #fff;
    margin-right: 2px;
  }
`;
