import React from 'react';
import styles from './Panel.module.scss';
import cn from 'classnames';

const PanelWrapper = (props) => (
  <div id={props.id} className={cn(styles.panelWrapper, props.className)}>
    {props.children}
  </div>
);

export default PanelWrapper;
