import * as types from '../../constants/actionTypes';
import readModelHub from '../../utils/readModelHub';
import ApiFetch from '../../utils/apiFetch';

function getKeyPerformanceIndicatorsRequest() {
  return {
    type: types.GET_KEY_PERFORMANCE_INDICATORS,
  };
}

function getKeyPerformanceIndicatorsSuccess(data) {
  return {
    type: types.GET_KEY_PERFORMANCE_INDICATORS_SUCCESS,
    keyPerformanceIndicators: data,
  };
}

function getKeyPerformanceIndicatorsFailed(error) {
  return {
    type: types.GET_KEY_PERFORMANCE_INDICATORS_ERROR,
    error,
  };
}

export function getKeyPerformanceIndicators() {
  return (dispatch) => {
    dispatch(getKeyPerformanceIndicatorsRequest());

    ApiFetch.get(`kpi`)
      .then((data) => {
        dispatch(getKeyPerformanceIndicatorsSuccess(data));
      })
      .catch((error) => {
        dispatch(getKeyPerformanceIndicatorsFailed(error));
      });
  };
}

export function subscribeToKeyPerformanceIndicatorsChange(user) {
  return (dispatch) => {
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Subscribing to keyPerformanceIndicators (subscribeToKeyPerformanceIndicatorsChange) userGroupId:' +
          userGroup.id
      );
      readModelHub.subscribe(
        'patient',
        userGroup.id,
        () => {
          dispatch(getKeyPerformanceIndicators(user.id));
        },
        true
      );
      readModelHub.subscribe(
        'implant',
        userGroup.id,
        () => {
          dispatch(getKeyPerformanceIndicators(user.id));
        },
        true
      );
      readModelHub.subscribe(
        'measurement',
        userGroup.id,
        () => {
          dispatch(getKeyPerformanceIndicators(user.id));
        },
        true
      );
    });
  };
}

export function unsubscribeFromKeyPerformanceIndicatorsChange(user) {
  return () => {
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Unsubscribing from keyPerformanceIndicators (unsubscribeFromKeyPerformanceIndicatorsChange) userGroupID:' +
          userGroup.id
      );
      readModelHub.unsubscribe('patient', userGroup.id);
    });
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Unsubscribing from keyPerformanceIndicators (unsubscribeFromKeyPerformanceIndicatorsChange) userGroupID:' +
          userGroup.id
      );
      readModelHub.unsubscribe('implant', userGroup.id);
    });
    user.userGroups.forEach((userGroup) => {
      console.log(
        'SignalR: Unsubscribing from keyPerformanceIndicators (unsubscribeFromKeyPerformanceIndicatorsChange) userGroupID:' +
          userGroup.id
      );
      readModelHub.unsubscribe('measurement', userGroup.id);
    });
  };
}
