import styled from 'styled-components';

const TeethToolItem = styled.div<{ selected?: boolean; disabled?: boolean }>`
  cursor: pointer;
  background-color: white;
  transition: background-color 270ms ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 64px;
  height: 64px;
  font-size: 9px;
  ${(props) =>
    props.selected &&
    `background-color: ${props.theme.colors.selectedgrey};
     transition: background-color 270ms ease-in-out; 
     color: ${props.theme.colors.purple}; 
     svg {
      fill: ${props.theme.colors.purple};
     }
     `};
  ${(props) =>
    props.disabled &&
    `transition: background-color 270ms ease-in-out; 
     color: ${props.theme.colors.lightgrey}; 
     svg {
      fill: ${props.theme.colors.lightgrey};
     }
     pointer-events: none;
     cursor: none;
     `};
  svg {
    width: 36px;
    height: 36px;
  }
`;

export default TeethToolItem;
