import React, { Component } from 'react';
import styles from './ImplantChart.module.scss';

export default class ChartDot extends Component {
  render() {
    const { cx, cy, payload } = this.props;
    const size = 30;
    const radius = 5;
    let color;

    if (this.props.noColor) {
      color = styles.noColor;
    } else if (this.props.projection) {
      color = styles.vividpurple;
    } else if (payload.abutment) {
      color = styles.ISQneutral;
    } else {
      if (payload.lowest < 60) {
        color = styles.ISQlow;
      } else if (payload.lowest < 70) {
        color = styles.ISQmedium;
      } else {
        color = styles.ISQhigh;
      }
    }

    if (cy !== null) {
      return (
        <svg
          x={cx - size / 2}
          y={cy - size / 2}
          width={size}
          height={size}
          style={{ cursor: 'pointer' }}
          onClick={this.props.dotClicked.bind(this, payload)}
        >
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius}
            // stroke={color}
            // strokeWidth={3}
            fill={color}
          />
          <circle
            cx={size / 2}
            cy={size / 2}
            r={radius * 2.5}
            stroke={color}
            strokeWidth={1}
            fill={color}
            fillOpacity={0}
          />
        </svg>
      );
    }

    return null;
  }
}
