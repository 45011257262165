import { Panel } from '../../components/Shared/Panel';
import { Button } from '../../components/Shared/Buttons';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { AlertBox } from '../../components/Shared/Alerts';
import { appInsights } from '../../utils/applicationInsights';
import { toastrOptions } from '../../constants/toastrOptions';
import { ChangeEvent, useEffect, useState } from 'react';
import { InputRow, InputItem } from '../../components/Shared/Forms';
import { useHistory, useParams } from 'react-router-dom';
import { getClinic as getClinicRequest } from '../../redux/actions/clinicActions';
import { useAppSelector, useAppDispatch } from '../../utils/hooks';
import {
  getPatient as getPatientRequest,
  getPatientImplants as getPatientImplantsRequest,
  exportPatient as exportPatientRequest,
} from '../../redux/actions/patientActions';
import ReferralReport from '../../components/patient-export/ReferralReport';
import PatientReport from '../../components/patient-export/PatientReport';
import Spinner from '../../components/Shared/Loaders/Spinner';
import styles from './PatientExportPage.module.scss';
import Header from '../../components/header/Header';
import Modal from '../../components/Shared/Modal/Modal';

const PatientExportPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { patientId, userGroupId } = useParams<Record<string, string | undefined>>();
  const {
    getPatient,
    exportPatient,
    currentPatient: patient,
    currentImplants: patientImplants,
  } = useAppSelector((state) => state.patient);
  const { user } = useAppSelector((state) => state.user);
  const { clinic } = useAppSelector((state) => state.clinic);

  const [isEdit, setIsEdit] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('referral');

  const headerButtonsLeft = [
    {
      label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
      icon: 'back',
      action: history.goBack,
    },
  ];
  const headerButtonsRight = [
    {
      label: intl.formatMessage({ id: 'general.export', defaultMessage: 'Export' }),
      icon: 'export',
      action: () => exportPatientHandler(),
      loading: isExporting,
    },
  ];

  const templateOptions = [
    {
      key: 'referral',
      value: intl.formatMessage({ id: 'patientexport.referral', defaultMessage: 'Referral' }),
    },
    {
      key: 'patient',
      value: intl.formatMessage({
        id: 'patientexport.patientInformation',
        defaultMessage: 'Patient Information',
      }),
    },
  ];

  const error = exportPatient.error;

  useEffect(() => {
    dispatch(getPatientRequest(userGroupId, patientId));
    dispatch(getPatientImplantsRequest(userGroupId, patientId));
    dispatch(getClinicRequest());
  }, []);

  useEffect(() => {
    if (clinic.loaded && patient.id) {
      setIsLoaded(true);
    }

    if (exportPatient.complete && isExporting) {
      setIsExporting(false);
      setIsEdit(true);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'patientexport.exported',
          defaultMessage: 'Patient exported',
        }),
        toastrOptions
      );
    }
  }, [clinic.loaded, patient.id, exportPatient.complete, isExporting]);

  useEffect(() => {
    if (isExporting && !isEdit) {
      appInsights.trackEvent({
        name: 'PdfExport',
        properties: {
          type: selectedTemplate,
          email: user.email,
        },
      });
      const html = '<!DOCTYPE html>' + document.documentElement.outerHTML;
      const patientIdentifier = patient.patientNumber
        ? patient.patientNumber
        : patient.patientIdentifier;
      dispatch(exportPatientRequest(patientIdentifier, html, null));
    }
  }, [isExporting, isEdit]);

  const exportPatientHandler = () => {
    setIsExporting(true);
    setIsEdit(false);
  };

  const templateSelectedHandler = (evt: ChangeEvent<HTMLInputElement>) =>
    setSelectedTemplate(evt.target.value);

  return (
    <div>
      <Header
        title={intl.formatMessage({
          id: 'patientexport.Header',
          defaultMessage: 'PATIENT EXPORT',
        })}
        leftButtons={headerButtonsLeft}
        rightButtons={headerButtonsRight}
        isTogglerHidden
      />
      <div className="pageContent">
        <div>
          {error ? (
            <AlertBox
              color="black"
              headerText="Oops, there was an error"
              messages={error.messages.map((m: { text: string }) => m.text)}
            />
          ) : null}
          <Panel full>
            <InputRow>
              <InputItem
                label={intl.formatMessage({
                  id: 'patientexport.selectTemplate',
                  defaultMessage: 'Select export template',
                })}
                type="selectbutton"
                onChange={templateSelectedHandler}
                options={templateOptions}
                value={selectedTemplate}
                noGrow
              />
            </InputRow>
          </Panel>
        </div>
        {getPatient.busy && !isLoaded ? (
          <Spinner className={styles.spinnerBackground} />
        ) : selectedTemplate !== 'referral' ? (
          <PatientReport
            user={user}
            clinic={clinic}
            patient={patient}
            patientImplants={patientImplants}
            edit={isEdit}
          />
        ) : (
          <ReferralReport
            user={user}
            clinic={clinic}
            patient={patient}
            patientImplants={patientImplants}
            edit={isEdit}
          />
        )}
      </div>
      <div className={styles.screenToSmallModal}>
        <Modal
          header={intl.formatMessage({
            id: 'general.screenToSmall',
            defaultMessage: 'Screen too small',
          })}
          show={true}
          onDismiss={() => history.goBack()}
        >
          <p>
            {intl.formatMessage({
              id: 'general.screenToSmallForThisFeature',
              defaultMessage: 'Screen too small for this feature.',
            })}
          </p>
          <div>
            <Button
              primary
              big
              full
              value={intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' })}
              onClick={() => history.goBack()}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default PatientExportPage;
