import * as React from 'react';
import { useAppSelector } from '../../../../../../../utils/hooks';
import { Group } from 'react-konva';
import Konva from 'konva';
import { CanvasImage } from '../TeethCanvasViewport';

const rootOffset: { x: number; w: number }[] = [
  // Upper
  { x: 12, w: 0 },
  { x: 12, w: -1 },
  { x: 3, w: -22 },
  { x: 6, w: -2 },
  { x: 3, w: -3 },
  { x: 4, w: -5 },
  { x: 7, w: 8 },
  { x: 3, w: -3 },
  { x: 0, w: -8 },
  { x: 1, w: -2 },
  { x: 2, w: -4 },
  { x: 0, w: -4 },
  { x: 10, w: 14 },
  { x: 6, w: -4 },
  { x: 3, w: -4 },
  { x: 2, w: -6 },
  // Lower
  { x: 12, w: 0 },
  { x: 8, w: -5 },
  { x: 3, w: -22 },
  { x: 5, w: -2 },
  { x: 3, w: -2 },
  { x: 2, w: -4 },
  { x: 3, w: 0 },
  { x: 6, w: 0 },
  { x: 4, w: -2 },
  { x: 1, w: -4 },
  { x: 3, w: -4 },
  { x: 2, w: 2 },
  { x: 7, w: 13 },
  { x: 6, w: -2 },
  { x: 6, w: 0 },
  { x: 6, w: -8 },
];

function CanvasTeethPosition(props: {
  x: number;
  upperRow: boolean;
  teeth: { id: number; zoneWidth: number; url: string; offset: number; center: number };
}) {
  const { x, upperRow, teeth: t } = props;

  const teeth = useAppSelector((state) => state.consultation.workspace.present.teethState);
  const bone = useAppSelector((state) => state.consultation.workspace.present.boneState);
  const teethPreparation = useAppSelector(
    (state) => state.consultation.workspace.present.teethPreparation
  );
  const depth = bone[t.id].depth * 6;

  const ClipMask = (ctx: Konva.Context) => {
    if (teeth[t.id] || teethPreparation[t.id]) ctx.rect(x, -200, 120, 400);
    if (teethPreparation[t.id]) {
      ctx.beginPath();

      const root = rootOffset[t.id] ?? { x: 0, w: 0 };
      if (upperRow) {
        let startX = t.center;
        ctx.rect(startX, -200, root.w > 0 ? t.zoneWidth + root.w : t.zoneWidth, 150 - depth);

        startX = startX + root.x;
        const quart = (t.zoneWidth - root.x + root.w) / 4;
        const height = -50 - depth;

        ctx.moveTo(startX, height);
        ctx.bezierCurveTo(
          startX + quart,
          height,
          startX + quart,
          -10 - depth / 2,
          startX + quart * 2,
          -10 - depth / 2
        );
        ctx.bezierCurveTo(
          startX + quart * 3,
          -10 - depth / 2,
          startX + quart * 3,
          height,
          startX + quart * 4,
          height
        );
      } else {
        let startX = t.center;
        ctx.rect(startX, 200, root.w > 0 ? t.zoneWidth + root.w : t.zoneWidth, -150 + depth);

        startX = startX + root.x;
        const quart = (t.zoneWidth - root.x + root.w) / 4;
        const height = 50 + depth;

        ctx.moveTo(startX, height);
        ctx.bezierCurveTo(
          startX + quart,
          height,
          startX + quart,
          15 + depth / 2,
          startX + quart * 2,
          15 + depth / 2
        );
        ctx.bezierCurveTo(
          startX + quart * 3,
          15 + depth / 2,
          startX + quart * 3,
          height,
          startX + quart * 4,
          height
        );
      }
    }
  };

  return (
    <>
      <Group clipFunc={ClipMask}>
        <CanvasImage
          x={x}
          y={upperRow ? 0 : 8}
          url={t.url}
          alignToBottom={upperRow}
          scale={{ x: 0.5, y: 0.5 }}
        />
      </Group>
    </>
  );
}

export default CanvasTeethPosition;
