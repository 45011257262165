import { FC } from 'react';
import { IUser } from '../../types/user';
import { H2, H4 } from '../../components/Shared/Headings';
import { Button } from '../../components/Shared/Buttons';
import { useAppDispatch } from '../../utils/hooks';
import { showFreeSmartPegs } from '../../redux/actions/generalActions';
import { useIntl, FormattedMessage } from 'react-intl';
import styles from './HomePage.module.scss';

interface Props {
  user: IUser;
}

const PromotionsSection: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { promotions } = user;

  return (
    <div>
      <div className={styles.divider}></div>
      <div className={styles.promotionsContainer}>
        <H2>
          <FormattedMessage id="home.promotions" defaultMessage="Promotions" />
        </H2>
        <H4>
          {'('}
          <FormattedMessage
            id="home.notAvailableInAllCountries"
            defaultMessage="Not available in all countries, please contact your local representative"
          />
          {')'}
        </H4>
      </div>
      {promotions.map((promotion) => {
        if (promotion.type === 'FreeSmartPegs') {
          return (
            <div
              className={styles.promotionBox}
              onClick={() => dispatch(showFreeSmartPegs(promotion))}
              key={promotion.id}
            >
              <p>
                <FormattedMessage
                  id="home.smartpegPromotion"
                  defaultMessage="We noticed that you haven’t used your free SmartPeg offer. Go ahead and use it!"
                />
              </p>
              <div>
                <Button
                  value={intl.formatMessage({
                    id: 'home.smartpegPromotionButton',
                    defaultMessage: 'Collect SmartPegs!',
                  })}
                />
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default PromotionsSection;
