import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getTranslationsRequest() {
  return {
    type: types.GET_TRANSLATIONS,
  };
}

function getTranslationsSuccess(data, publicTranslations) {
  return {
    type: types.GET_TRANSLATIONS_SUCCESS,
    translations: data,
    publicTranslations,
  };
}

function getTranslationsFailed(error) {
  return {
    type: types.GET_TRANSLATIONS_ERROR,
    error,
  };
}

export function getTranslations(publicTranslations) {
  return (dispatch) => {
    dispatch(getTranslationsRequest());

    const url = publicTranslations ? 'translations/public' : 'translations';

    ApiFetch.get(url, publicTranslations ? true : false)
      .then((data) => {
        dispatch(getTranslationsSuccess(data, publicTranslations));
      })
      .catch((error) => {
        dispatch(getTranslationsFailed(error));
      });
  };
}

export function changeLocale(locale) {
  return {
    type: types.CHANGE_LOCALE,
    locale,
  };
}

export function exportTranslations(callback) {
  return () => {
    ApiFetch.downloadFile(`translations/export`, callback);
  };
}

function registerTranslationsRequest() {
  return {
    type: types.REGISTER_TRANSLATIONS,
  };
}

function registerTranslationsSuccess() {
  return {
    type: types.REGISTER_TRANSLATIONS_SUCCESS,
  };
}

function registerTranslationsFailed(error) {
  return {
    type: types.REGISTER_TRANSLATIONS_ERROR,
    error,
  };
}

export function registerTranslations(version, file, callback) {
  return (dispatch) => {
    dispatch(registerTranslationsRequest());

    ApiFetch.postFile(`translations/import?version=${version}`, file)
      .then((_) => {
        dispatch(registerTranslationsSuccess());
        callback();
      })
      .catch((error) => {
        dispatch(registerTranslationsFailed(error));
      });
  };
}
