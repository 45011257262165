import { FC } from 'react';
import { IUser } from '../../types/user';
import { Button } from '../Shared/Buttons';
import { H3, H2 } from '../Shared/Headings';
import { useIntl } from 'react-intl';
import { getProjection } from './helpers/getProjection';
import { FormattedMessage } from 'react-intl';
import { IReferenceDataObj } from '../../types/referenceData';
import { selectPatientImplant } from '../../redux/actions/patientActions';
import { getOrderedMeasurements } from './helpers/getOrderedMeasurements';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { IImplant, IImplantMeasurement, IImplantMeasurementProjection } from '../../types/implant';
import ISQListItem from './ISQListItem';
import DataItem from '../Shared/DataItem/DataItem';
import styles from './ImplantPanel.module.scss';
import cn from 'classnames';

interface Props {
  showCreateMeasurementModal: (
    tooth: number,
    isStability: boolean,
    measurement: IImplantMeasurement | null
  ) => void;
  showCreateTreatmentModal: (tooth: number) => void;
  showCreateAbutmentModal: (tooth: number) => void;
  showCreateImplantModal: (tooth: number) => void;
  showFailImplantModal: (tooth: number) => void;
  deleteTreatment: (tooth: number) => void;
  implant: IImplant;
}

const ImplantOverview: FC<Props> = ({
  showCreateMeasurementModal,
  showCreateTreatmentModal,
  showCreateAbutmentModal,
  showCreateImplantModal,
  showFailImplantModal,
  deleteTreatment,
  implant,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector<{ user: IUser }>((state) => state.user);
  const {
    loadingProtocols,
    plannedProstheticSolutions,
    surgicalProtocols,
    prePlacementProcedures,
    implantSitePlacementOptions,
  } = useAppSelector((state) => state.referenceData);

  const getDaysToBeReady = (
    lastMeasurement: string | undefined,
    projection?: IImplantMeasurementProjection
  ) => {
    if (projection && lastMeasurement) {
      return Math.floor(
        (new Date(projection.date).getTime() - new Date(lastMeasurement).getTime()) / 86400000
      );
    }
  };

  const getHealingTime = (placementDate?: string, loadingDate?: string) => {
    if (!placementDate || !loadingDate) return;
    const days = Math.floor(
      (new Date(loadingDate).getTime() - new Date(placementDate).getTime()) / 86400000
    );
    if (days > 0) return days;
  };

  const getStatus = () => {
    if (implant.dataId === -1)
      return intl.formatMessage({
        id: 'patient.awaitingImplant',
        defaultMessage: 'Awaiting implant',
      });
    if (implant.loadingDate)
      return intl.formatMessage({
        id: 'patient.readyForFinalRestoration',
        defaultMessage: 'Ready for final restoration',
      });
    if (implant.status === 'AwaitingLoading')
      return intl.formatMessage({
        id: 'patient.awaitingLoading',
        defaultMessage: 'Awaiting loading',
      });
    return implant.status;
  };

  const getValueFromKey = (array: IReferenceDataObj[], key: string) => {
    const foundItem = array.find((item) => {
      return item.key === key;
    });
    if (!foundItem) return '-';
    return intl.formatMessage({
      id: ''.concat('referenceData.', foundItem.key),
      defaultMessage: foundItem.value,
    });
  };

  const lastMeasurement = implant.lastMeasurementDate;
  const orderedMeasurements = getOrderedMeasurements(implant.measurements);

  const projection = getProjection(orderedMeasurements, intl);
  const status = getStatus();

  const daysToBeReady = getDaysToBeReady(lastMeasurement, projection);
  const insightText = daysToBeReady
    ? `${intl.formatMessage({
        id: 'general.readyIn',
        defaultMessage: 'ready in',
      })} ${daysToBeReady} ${intl.formatMessage({
        id: 'general.days',
        defaultMessage: 'days',
      })}`
    : undefined;

  const healingTime = getHealingTime(implant.placementDate, implant.loadingDate);
  const healingTimeText = healingTime
    ? `${healingTime} ${intl.formatMessage({ id: 'general.days', defaultMessage: 'days' })}`
    : undefined;

  const studyGroups = user.studyGroups
    .filter((x) => implant.studyGroups.map((y) => y.id).includes(x.id))
    .map((z) => z.name);
  return (
    <div className={styles.overviewContainer}>
      <button onClick={() => dispatch(selectPatientImplant(-1))} className={styles.closeButton}>
        <i className={styles.closeIcon}>close</i>
      </button>
      <H2 className={styles.tabHeading}>
        <FormattedMessage id="patient.Treatment" defaultMessage="Treatment" />
      </H2>
      <div className={cn(styles.overviewWrapper, styles.reverseColumn)}>
        <div className={styles.dataCol}>
          <div className={cn(styles.dataRow, styles.spread)}>
            <DataItem
              label={intl.formatMessage({
                id: 'patient.additionalSurgicalProcedures',
                defaultMessage: 'Additional surgical procedure',
              })}
              value={getValueFromKey(prePlacementProcedures, implant.prePlacementProcedure)}
              className={styles.dataItem}
            />
            <DataItem
              label={intl.formatMessage({
                id: 'patient.loadingProtocol',
                defaultMessage: 'Loading protocol',
              })}
              value={getValueFromKey(loadingProtocols, implant.loadingProtocol)}
              className={styles.dataItem}
            />
          </div>
          <div className={cn(styles.dataRow, styles.spread)}>
            <DataItem
              label={intl.formatMessage({
                id: 'patient.surgicalProtocol',
                defaultMessage: 'Surgical protocol',
              })}
              value={getValueFromKey(surgicalProtocols, implant.surgicalProtocol)}
              className={styles.dataItem}
            />
            <DataItem
              label={intl.formatMessage({
                id: 'patient.plannedProstheticSolution',
                defaultMessage: 'Planned prosthetic solution',
              })}
              value={getValueFromKey(plannedProstheticSolutions, implant.plannedProstheticSolution)}
              className={styles.dataItem}
            />
          </div>
          <div className={cn(styles.dataRow, styles.spread)}>
            <DataItem
              label={intl.formatMessage({
                id: 'patient.implantSitePlacement',
                defaultMessage: 'Implant site placement',
              })}
              value={getValueFromKey(implantSitePlacementOptions, implant.implantSitePlacement)}
              className={styles.dataItem}
            />
            <DataItem
              label={intl.formatMessage({
                id: 'patient.boneQuality',
                defaultMessage: 'Bone quality',
              })}
              value={implant.boneQuality}
              className={styles.dataItem}
            />
          </div>
          {implant.studyGroups.length > 0 ? (
            <div className={cn(styles.dataRow, styles.spread)}>
              <DataItem
                label={intl.formatMessage({
                  id: 'patient.studyGroup',
                  defaultMessage: 'Study Group',
                })}
                value={studyGroups.join(', ')}
                className={styles.dataItem}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.dataCol}>
          <div className={cn(styles.dataRow, styles.spread)}>
            <DataItem
              label={intl.formatMessage({
                id: 'patient.status',
                defaultMessage: 'Status',
              })}
              value={status}
              insight={insightText}
              className={styles.dataItem}
            />
            {implant.dataId > 0 && (
              <DataItem
                label={intl.formatMessage({
                  id: 'patient.healingTime',
                  defaultMessage: 'Healing time',
                })}
                value={healingTimeText}
                className={styles.dataItem}
              />
            )}
          </div>
          <div className={cn(styles.dataRow, styles.spread)}>
            {implant.dataId > 0 && (
              <DataItem
                label={intl.formatMessage({
                  id: 'patient.placement',
                  defaultMessage: 'Placement',
                })}
                value={implant.placementDate}
                date={implant.placementDate !== undefined}
                className={styles.dataItem}
              />
            )}
            {implant.dataId > 0 && (
              <DataItem
                label={intl.formatMessage({
                  id: 'patient.readyForFinalRestoration',
                  defaultMessage: 'Ready for final restoration',
                })}
                value={implant.loadingDate}
                date={implant.loadingDate !== undefined}
                className={styles.dataItem}
              />
            )}
          </div>
          <div className={cn(styles.dataRow)}>
            <DataItem
              label={intl.formatMessage({
                id: 'patient.maximumInsertionTorque',
                defaultMessage: 'Maximum Insertion Torque (Ncm)',
              })}
              value={implant.insertionTorque > 0 ? implant.insertionTorque : ''}
              className={styles.dataItem}
            />
          </div>
          {implant.measurements.length > 0 && (
            <div className={styles.dataRow}>
              <ISQListItem
                label={intl.formatMessage({
                  id: 'patient.lastMeasurement',
                  defaultMessage: 'Last measurement',
                })}
                data={implant}
                levelInfo
              />
            </div>
          )}
          <div className={styles.dataRow}>
            <DataItem
              label={intl.formatMessage({
                id: 'general.comment',
                defaultMessage: 'Comment',
              })}
              value={implant.comment}
              className={styles.dataItem}
            />
          </div>
        </div>
        <div className={cn(styles.dataCol, styles.actionCol)}>
          <H3>
            <FormattedMessage id="patient.Actions" defaultMessage="Actions" />
          </H3>
          {implant.dataId === -1 && (
            <Button
              value={intl.formatMessage({
                id: 'patient.addImplant',
                defaultMessage: 'Add implant',
              })}
              leftIcon="implant"
              onClick={() => showCreateImplantModal(implant.tooth)}
              noShadow
              small
              leftAlign
              primary
            />
          )}
          {implant.dataId > 0 && (
            <>
              <Button
                dataCy="impant-overview-measure-stability"
                primary
                value={intl.formatMessage({
                  id: 'patient.measureStability',
                  defaultMessage: 'Measure stability',
                })}
                leftIcon="smartpeg"
                onClick={() => showCreateMeasurementModal(implant.tooth, true, null)}
                noShadow
                small
                leftAlign
              />
              {implant.abutmentId === -1 && (
                <Button
                  value={intl.formatMessage({
                    id: 'patient.addAbutment',
                    defaultMessage: 'Add abutment',
                  })}
                  leftIcon="abutment"
                  onClick={() => showCreateAbutmentModal(implant.tooth)}
                  noShadow
                  small
                  leftAlign
                  secondary
                />
              )}
              <Button
                value={intl.formatMessage({
                  id: 'patient.markAsFailed',
                  defaultMessage: 'Mark as failed',
                })}
                leftIcon="close"
                onClick={() => showFailImplantModal(implant.tooth)}
                noShadow
                small
                leftAlign
                secondary
              />
            </>
          )}
          <Button
            value={intl.formatMessage({
              id: 'patient.editTreatment',
              defaultMessage: 'Edit treatment',
            })}
            leftIcon="draw"
            onClick={() => showCreateTreatmentModal(implant.tooth)}
            noShadow
            small
            leftAlign
            secondary
          />
          <Button
            value={intl.formatMessage({
              id: 'patient.removeTreatment',
              defaultMessage: 'Remove treatment',
            })}
            leftIcon="delete"
            onClick={() => deleteTreatment(implant.tooth)}
            noShadow
            small
            leftAlign
            secondary
          />
        </div>
      </div>
    </div>
  );
};

export default ImplantOverview;
