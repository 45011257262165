import { Row } from '../../../components/Shared/Section';
import { roles } from '../helpers/roles';
import { InputItem } from '../../../components/Shared/Forms';
import { useHistory } from 'react-router';
import { IAdminListUser } from '../../../types/user';
import { useAppSelector } from '../../../utils/hooks';
import { FC, useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import ClinicView from './ClinicView';
import styles from './UserRow.module.scss';
import cn from 'classnames';

interface Props {
  user: IAdminListUser;
  deleteAction: (user: IAdminListUser) => void;
  retrievePasswordAction: (email: string) => void;
  userRoleChanged: (userId: string, userSsoId: string, selectedRole: string) => void;
  changeUserRoleDisabled: boolean;
}

const UserRow: FC<Props> = ({
  user,
  deleteAction,
  retrievePasswordAction,
  userRoleChanged,
  changeUserRoleDisabled,
}) => {
  const history = useHistory();

  const [userRole, setUserRole] = useState(user.role);
  const [isToggled, setIsToggled] = useState(false);
  const { countries } = useAppSelector((state) => state.referenceData);

  const [isClinicModalOpen, setIsClinicModalOpen] = useState(false);

  const country = user.eulaCountry
    ? countries.find((c: { key: string; value: string }) => c.key === user.eulaCountry)
    : undefined;
  const countryValue = country ? ' - ' + country.value : '';

  const viewClinic = () => setIsClinicModalOpen(true);
  const closeClinicView = () => setIsClinicModalOpen(false);

  const expandUser = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsToggled((prevState) => !prevState);
  };

  const deleteUser = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (deleteAction) return deleteAction(user);
  };

  const retrievePassword = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (retrievePasswordAction) return retrievePasswordAction(user.email);
  };

  const parseUserGroupRoleHandler = () => {
    if (user.userGroups.some((userGroup) => userGroup.userPermission === 'Admin'))
      return 'GroupAdmin';
    return 'GroupMember';
  };

  const navigateToUser = () => history.push(`profile/${user.id}`);

  const navigateToDevices = () => history.push(`devices/${user.id}`);

  const userRoleChangedHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    setUserRole(parseInt(evt.target.value));
    userRoleChanged(user.id, user.ssoId, evt.target.value);
  };

  useEffect(() => {
    if (user.role) {
      setUserRole(user.role);
    }
  }, [user.role]);

  const userGroupRole = parseUserGroupRoleHandler();

  return (
    <>
      <div className={cn(styles.userRow, isToggled ? styles.active : null)}>
        <div className={styles.header} onClick={expandUser}>
          <div className={styles.heading}>
            <h3 className={styles.heading}>
              <span className={styles.name}>
                {user.firstName} {user.surName}
              </span>
              <span className={styles.clinic}>{user.clinicName}</span>
            </h3>
            <span className={styles.email}>{user.email + countryValue}</span>
          </div>
          <div>
            <span className={styles.badge}>{userGroupRole}</span>
          </div>
          <div className={styles.lastSeen}>{user.lastSeen || ''}</div>
          <div className={styles.moreButton}>
            <i className="osstell-icons">more</i>
          </div>
        </div>
        {isToggled && (
          <div className={styles.actionBox}>
            <Row>
              <div className={styles.roleWrapper}>
                {changeUserRoleDisabled === true ? (
                  <InputItem
                    disabled
                    type="select"
                    small
                    options={roles}
                    value={userRole}
                    onChange={userRoleChangedHandler}
                  />
                ) : (
                  <InputItem
                    type="select"
                    small
                    options={roles}
                    value={userRole}
                    onChange={userRoleChangedHandler}
                  />
                )}
              </div>
              <div>
                <InputItem type="button" small value="Edit profile" onClick={navigateToUser} />
              </div>
              <div>
                <InputItem type="button" small value="View clinic" onClick={viewClinic} />
              </div>
              <div>
                <InputItem
                  type="button"
                  small
                  value="Administrate instruments"
                  onClick={navigateToDevices}
                />
              </div>
              <div>
                <InputItem
                  type="button"
                  small
                  value="Send password reset link"
                  onClick={retrievePassword}
                />
              </div>
              <div>
                <InputItem type="button" small value="Delete user" onClick={deleteUser} />
              </div>
            </Row>
          </div>
        )}
      </div>
      {isClinicModalOpen && (
        <ClinicView
          userId={user.id}
          countries={countries}
          isClinicModalOpen={isClinicModalOpen}
          onModalCloseClick={closeClinicView}
        />
      )}
    </>
  );
};

export default UserRow;
