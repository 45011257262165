import * as React from 'react';

function SvgBackliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1452 2048" {...props}>
      <path d="M202 1119l380 425q12 13 11 30.5t-14 29.5q-13 11-28 11-20 0-32-14l-406-454q-11-12-11-28t11-28l406-454q12-13 29.5-14t30.5 11 14 29.5-11 30.5zm1106-43q18 0 30.5 12.5t12.5 30.5-12.5 30-30.5 12H349q-18 0-30-12t-12-30 12-30.5 30-12.5h959z" />
    </svg>
  );
}

export default SvgBackliga;
