import * as React from 'react';

function SvgCameraliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1503 2048" {...props}>
      <path d="M752 804q106 0 181.5 75t75.5 182-75.5 182-181.5 75-181.5-75-75.5-182 75.5-182T752 804zm0 471q88 0 151-62.5t63-151.5-63-152-151-63-151 63-63 152 63 151.5 151 62.5zm443-446q9 0 15 6.5t6 15.5-6 15-15 6h-92q-9 0-15.5-6t-6.5-15 6.5-15.5 15.5-6.5h92zm-28-158q97 0 166 68.5t69 165.5v359q0 97-69 166t-166 69H337q-97 0-166-69t-69-166V905q0-97 69-165.5T337 671h100l71-141q30-59 95-59h298q65 0 95 59l71 141h100zm192 593V905q0-79-56.5-135.5T1167 713h-114q-12 0-19-12l-76-152q-18-36-57-36H603q-39 0-57 36l-76 152q-7 12-19 12H337q-79 0-135.5 56.5T145 905v359q0 80 56.5 136t135.5 56h830q79 0 135.5-56t56.5-136z" />
    </svg>
  );
}

export default SvgCameraliga;
