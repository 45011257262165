import React from 'react';
import styles from './NewsTile.module.scss';

const NewsTile = (props) => (
  <div
    className={`${styles.tile} ${
      props.removeBackgroundOnMobile && styles.removeBackgroundOnMobile
    }`}
    onClick={props.onClick}
    style={{ backgroundImage: 'url(' + props.background + ')' }}
  >
    <div className={styles.newsContent}>{props.children}</div>
  </div>
);

export default NewsTile;
