import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Section, SectionInner } from '../../components/Shared/Section';
import { AlertBox } from '../../components/Shared/Alerts';

import * as userActions from '../../redux/actions/userActions';
import * as clinicActions from '../../redux/actions/clinicActions';
import * as accountActions from '../../redux/actions/accountActions';

import styles from './RegisterUserPage.module.scss';

import { InputItem, InputRow, InputGroup } from '../../components/Shared/Forms';
import { Button } from '../../components/Shared/Buttons';
import { ReactComponent as Logo } from '../../shared/icons/assets_login-logo.svg';

class RegisterUserPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: 0,
      profession: 0,
      numberOfPractitioners: 0,
      firstName: '',
      surName: '',
      placementsYear: undefined,
      yearsOfPlacements: undefined,
      restorationsYear: undefined,
      yearsOfRestorations: undefined,
      email: '',
      clinicName: '',
      address: '',
      co: '',
      postcode: '',
      city: '',
      countryCode: props.eulaCountry,
      state: '',
    };

    this.intl = props.intl;
    this.titleChanged = this.titleChanged.bind(this);
    this.professionChanged = this.professionChanged.bind(this);
    this.numberOfPractitionersChanged = this.numberOfPractitionersChanged.bind(this);
    this.firstNameChanged = this.firstNameChanged.bind(this);
    this.surNameChanged = this.surNameChanged.bind(this);
    this.placementsYearChanged = this.placementsYearChanged.bind(this);
    this.yearsOfPlacementsChanged = this.yearsOfPlacementsChanged.bind(this);
    this.restorationsYearChanged = this.restorationsYearChanged.bind(this);
    this.yearsOfRestorationsChanged = this.yearsOfRestorationsChanged.bind(this);
    this.emailChanged = this.emailChanged.bind(this);
    this.clinicNameChanged = this.clinicNameChanged.bind(this);
    this.addressChanged = this.addressChanged.bind(this);
    this.coChanged = this.coChanged.bind(this);
    this.postcodeChanged = this.postcodeChanged.bind(this);
    this.stateChanged = this.stateChanged.bind(this);
    this.cityChanged = this.cityChanged.bind(this);
    this.countryChanged = this.countryChanged.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.missingField = this.missingField.bind(this);
  }

  firstNameChanged(evt) {
    this.setState({
      firstName: evt.target.value,
    });
  }

  surNameChanged(evt) {
    this.setState({
      surName: evt.target.value,
    });
  }

  emailChanged(evt) {
    this.setState({
      email: evt.target.value,
    });
  }

  placementsYearChanged(evt) {
    this.setState({
      placementsYear: evt.target.value,
    });
  }

  yearsOfPlacementsChanged(evt) {
    this.setState({
      yearsOfPlacements: evt.target.value,
    });
  }

  restorationsYearChanged(evt) {
    this.setState({
      restorationsYear: evt.target.value,
    });
  }

  yearsOfRestorationsChanged(evt) {
    this.setState({
      yearsOfRestorations: evt.target.value,
    });
  }

  clinicNameChanged(evt) {
    this.setState({
      clinicName: evt.target.value,
    });
  }

  addressChanged(evt) {
    this.setState({
      address: evt.target.value,
    });
  }

  coChanged(evt) {
    this.setState({
      co: evt.target.value,
    });
  }

  postcodeChanged(evt) {
    this.setState({
      postcode: evt.target.value,
    });
  }

  stateChanged(evt) {
    this.setState({
      state: evt.target.value,
    });
  }

  cityChanged(evt) {
    this.setState({
      city: evt.target.value,
    });
  }

  countryChanged(option) {
    this.setState({
      countryCode: option.value,
    });
  }

  titleChanged(evt) {
    this.setState({
      title: evt.target.value,
    });
  }

  professionChanged(evt) {
    this.setState({
      profession: evt.target.value,
    });
  }

  numberOfPractitionersChanged(evt) {
    this.setState({
      numberOfPractitioners: evt.target.value,
    });
  }

  registerUser() {
    this.props.actions.registerUser(this.state, this.props.eulaId, this.props.eulaCountry);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.userRegistration.complete) {
      //Get the new created user
      this.props.actions.getUser();
      this.props.actions.getClinic();
    }
  }

  missingField() {
    return (
      !this.state.firstName ||
      !this.state.surName ||
      !this.state.placementsYear ||
      !this.state.yearsOfPlacements ||
      !this.state.restorationsYear ||
      !this.state.yearsOfRestorations ||
      !this.state.clinicName ||
      !this.state.address ||
      !this.state.countryCode ||
      !this.state.postcode ||
      !this.state.city
    );
  }

  render() {
    const error = this.props.userRegistration.error;
    const countries = this.props.countries.map((country) => {
      return { label: country.value, value: country.key };
    });
    const content = (
      <div>
        <h1 className={styles.h1}>
          <FormattedMessage
            id="registerUser.FinalizeRegistration"
            defaultMessage="Create your Profile and Clinic"
          />
        </h1>
        <p className={styles.p}>
          <FormattedMessage
            id="registerUser.FillInInformation"
            defaultMessage="Please fill in information about your profile and clinic."
          />
        </p>
        {error ? (
          <AlertBox
            headerText={this.intl.formatMessage({
              id: 'general.generalError',
              defaultMessage: 'Oops, there was an error',
            })}
            messages={error.messages.map((m) => m.text)}
          />
        ) : null}

        <InputGroup
          label={this.intl.formatMessage({
            id: 'registerUser.UserInformation',
            defaultMessage: 'User information',
          })}
        >
          <InputRow>
            <InputItem
              className={styles.title}
              label={
                '*' + this.intl.formatMessage({ id: 'general.title', defaultMessage: 'Title' })
              }
              type="select"
              options={this.props.titles.map((x) => ({
                key: x.key,
                value: this.intl.formatMessage({ ...x.label }),
              }))}
              onChange={this.titleChanged}
            />
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({ id: 'general.firstName', defaultMessage: 'First Name' })
              }
              type="text"
              onChange={this.firstNameChanged}
            />
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({ id: 'general.lastName', defaultMessage: 'Last Name' })
              }
              type="text"
              onChange={this.surNameChanged}
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({ id: 'general.profession', defaultMessage: 'Profession' })
              }
              type="select"
              options={this.props.professions.map((x) => ({
                key: x.key,
                value: this.intl.formatMessage({ ...x.label }),
              }))}
              onChange={this.professionChanged}
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({
                  id: 'profile.placementsYear',
                  defaultMessage: 'Implant placements/Year',
                })
              }
              type="selectbutton"
              onChange={this.placementsYearChanged}
              options={this.props.implantsYearOptions}
              value={this.state.placementsYear}
              noGrow
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({
                  id: 'profile.yearsOfPlacements',
                  defaultMessage: 'Experience working with implant placements (years)',
                })
              }
              type="selectbutton"
              onChange={this.yearsOfPlacementsChanged}
              options={this.props.yearsOfExperienceOptions}
              value={this.state.yearsOfPlacements}
              noGrow
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({
                  id: 'profile.restorationsYear',
                  defaultMessage: 'Final restorations/Year',
                })
              }
              type="selectbutton"
              onChange={this.restorationsYearChanged}
              options={this.props.implantsYearOptions}
              value={this.state.restorationsYear}
              noGrow
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({
                  id: 'profile.yearsOfRestorations',
                  defaultMessage: 'Experience working with final restorations (years)',
                })
              }
              type="selectbutton"
              onChange={this.yearsOfRestorationsChanged}
              options={this.props.yearsOfExperienceOptions}
              value={this.state.yearsOfRestorations}
              noGrow
            />
          </InputRow>
        </InputGroup>

        <InputGroup
          label={this.intl.formatMessage({
            id: 'registerUser.ClinicInformation',
            defaultMessage: 'Clinic information',
          })}
        >
          <InputRow>
            <InputItem
              label={
                '*' +
                this.intl.formatMessage({ id: 'general.clinic', defaultMessage: 'Clinic Name' })
              }
              type="text"
              onChange={this.clinicNameChanged}
            />
            <InputItem
              label={this.intl.formatMessage({
                id: 'general.numberOfPractitioners',
                defaultMessage: 'Number of practitioners in clinic',
              })}
              type="select"
              options={this.props.numberOfPractitioners}
              onChange={this.numberOfPractitionersChanged}
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' + this.intl.formatMessage({ id: 'general.address', defaultMessage: 'Address' })
              }
              type="text"
              onChange={this.addressChanged}
            />
            <InputItem
              label={this.intl.formatMessage({ id: 'general.co', defaultMessage: 'Co' })}
              type="text"
              onChange={this.coChanged}
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' + this.intl.formatMessage({ id: 'general.zipCode', defaultMessage: 'Zip code' })
              }
              type="text"
              className={styles.zip}
              onChange={this.postcodeChanged}
            />
            <InputItem
              label={this.intl.formatMessage({ id: 'general.state', defaultMessage: 'State' })}
              type="text"
              onChange={this.stateChanged}
            />
            <InputItem
              label={'*' + this.intl.formatMessage({ id: 'general.city', defaultMessage: 'City' })}
              type="text"
              onChange={this.cityChanged}
            />
          </InputRow>
          <InputRow>
            <InputItem
              label={
                '*' + this.intl.formatMessage({ id: 'general.country', defaultMessage: 'Country' })
              }
              type="search-select"
              options={countries}
              onChange={this.countryChanged}
              value={this.state.countryCode}
            />
          </InputRow>
        </InputGroup>
        <Button
          primary
          big
          full
          value={this.intl.formatMessage({ id: 'general.next', defaultMessage: 'Next' })}
          onClick={this.registerUser}
          disabled={this.props.userRegistration.busy || this.missingField()}
          loading={this.props.userRegistration.busy}
        />
      </div>
    );

    const oldLinkMessage = (
      <div>
        <AlertBox
          headerText={this.intl.formatMessage({
            id: 'general.youHaveUsedAnOldRegistrationLink',
            defaultMessage: 'You have used an old registration link',
          })}
          messages={[
            {
              text: this.intl.formatMessage({
                id: 'general.oldRegLinkFindLatestEmail',
                defaultMessage:
                  'Please find the latest registration email and use that link instead.',
              }),
            },
          ].map((m) => m.text)}
        />
        <br />
        <br />
        <Button
          centered
          primary
          big
          full
          value={
            <FormattedMessage id="general.backToLoginPage" defaultMessage="Back to login page" />
          }
          leftIcon="back"
          onClick={() => {
            this.props.actions.logoutAccount();
            this.props.actions.clearToken(false, false);
            this.props.history.push('/');
          }}
        />
      </div>
    );

    const isOldLink = error?.error?.message === 'SSOUserNotFound';

    return (
      <div className={styles.registrationPage}>
        <div className={styles.fullHeight}>
          <Section>
            <SectionInner>
              <div className={styles.registrationPanelWrapper}>
                <div className={styles.registrationPanel}>
                  <div className={styles.logo}>
                    <Logo />
                  </div>
                  {isOldLink ? oldLinkMessage : content}
                </div>
              </div>
            </SectionInner>
          </Section>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    countries: state.referenceData.countries,
    userRegistration: state.user.userRegistration,
    titles: state.referenceData.titles,
    professions: state.referenceData.professions,
    numberOfPractitioners: state.referenceData.numberOfPractitioners,
    implantsYearOptions: state.referenceData.implantsYear,
    yearsOfExperienceOptions: state.referenceData.yearsOfExperience,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators({ ...userActions, ...clinicActions, ...accountActions }, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(RegisterUserPage));
