import { toastr } from 'react-redux-toastr';
import { toastrOptions } from '../../../constants/toastrOptions';
import { getRefListData } from '../../../redux/actions/referenceDataActions';
import { SectionTabContent } from '../../../components/Shared/Section';
import { useEffect, useState } from 'react';
import { PanelWrapper, Panel } from '../../../components/Shared/Panel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useAppDispatch, useAppSelector } from '../../../utils/hooks';
import ImplantList from './ImplantList';
import Exports from './Exports';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import styles from './RefListAdministration.module.scss';

const RefListAdministration = () => {
  const dispatch = useAppDispatch();
  const releaseRefList = useAppSelector((state) => state.referenceData.releaseRefList);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { refListData: refList, refListResponse } = useAppSelector((state) => state.referenceData);
  const { updateImplantData, updateAbutmentData } = useAppSelector((state) => state.referenceData);

  useEffect(() => [dispatch(getRefListData())], [dispatch]);

  useEffect(() => {
    if (updateImplantData.complete || updateAbutmentData.complete || releaseRefList.complete) {
      toastr.success('Success', 'Reflist updated', toastrOptions);
      dispatch(getRefListData());
    }
  }, [updateImplantData.complete, updateAbutmentData.complete, releaseRefList.complete, dispatch]);

  const selectTab = (selectedTabIndex, _) => setSelectedTabIndex(selectedTabIndex);

  return (
    <SectionTabContent fullWidth>
      <PanelWrapper className={styles.marginTop}>
        <Panel full>
          <Tabs className={styles.tabWrapper} onSelect={selectTab}>
            <TabList className={styles.tabList}>
              <Tab className={selectedTabIndex === 0 ? styles.active : null}>Implants</Tab>
              <Tab className={selectedTabIndex === 1 ? styles.active : null}>Abutments</Tab>
              <Tab className={selectedTabIndex === 2 ? styles.active : null}>SmartPegs</Tab>
              <Tab className={selectedTabIndex === 3 ? styles.active : null}>Release/Exports</Tab>
            </TabList>
            <TabPanel className={styles.tabContent}>
              {refListResponse.busy && !refList.implants ? (
                <Spinner purple />
              ) : (
                <ImplantList implants={refList.implants} smartPegs={refList.smartPegs} />
              )}
            </TabPanel>
            <TabPanel className={styles.tabContent}>
              <ImplantList implants={refList.abutments} smartPegs={refList.smartPegs} abutments />
            </TabPanel>
            <TabPanel className={styles.tabContent}>
              <div>List of SmartPegs - TBD</div>
            </TabPanel>
            <TabPanel className={styles.tabContent}>
              <Exports />
            </TabPanel>
          </Tabs>
        </Panel>
      </PanelWrapper>
    </SectionTabContent>
  );
};

export default RefListAdministration;
