import * as types from '../../constants/actionTypes';

const initialState = {
  getInsights: {
    complete: false,
    error: undefined,
    busy: false,
    insights: {
      healingTime: [],
      successRate: [],
      patients: [],
      placements: [],
    },
  },
  getClinicInsights: {
    complete: false,
    error: undefined,
    busy: false,
  },
  clinicInsights: undefined,
};

export default function firmware(state = initialState, action) {
  switch (action.type) {
    case types.GET_INSIGHTS:
      return Object.assign({}, state, {
        getInsights: {
          complete: false,
          error: undefined,
          busy: true,
          insights: initialState.getInsights.insights,
        },
      });
    case types.GET_INSIGHTS_SUCCESS:
      return Object.assign({}, state, {
        getInsights: { complete: true, error: undefined, busy: false, insights: action.insights },
      });
    case types.GET_INSIGHTS_ERROR:
      return Object.assign({}, state, {
        getInsights: {
          complete: false,
          error: action.error,
          busy: false,
          insights: initialState.getInsights.insights,
        },
      });

    case types.GET_CLINIC_INSIGHTS:
      return Object.assign({}, state, {
        getClinicInsights: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.GET_CLINIC_INSIGHTS_SUCCESS:
      return Object.assign({}, state, {
        getClinicInsights: {
          complete: true,
          error: undefined,
          busy: false,
        },
        clinicInsights: action.insights,
      });
    case types.GET_CLINIC_INSIGHTS_ERROR:
      return Object.assign({}, state, {
        getClinicInsights: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });

    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
