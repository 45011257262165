import * as types from '../../constants/actionTypes';

const initialState = {
  content: {},
};

export default function tile(state = initialState, action) {
  let contentCopy;
  let content = [];

  switch (action.type) {
    case types.GET_TILES:
      return Object.assign({}, state, {});
    case types.GET_TILES_SUCCESS:
      action.content.forEach((tile) => {
        content[tile.type.toLowerCase()] = tile;
      });

      return Object.assign({}, state, { content: content });
    case types.GET_TILES_ERROR:
      return Object.assign({}, state, {});
    case types.GET_NEWS_TILE:
      return Object.assign({}, state, {});
    case types.GET_NEWS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.news = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_NEWS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_WELCOME_TILE:
      return Object.assign({}, state, {});
    case types.GET_WELCOME_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.welcome = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_WELCOME_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_ACTIVE_PATIENTS_TILE:
      return Object.assign({}, state, {});
    case types.GET_ACTIVE_PATIENTS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.activepatients = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_ACTIVE_PATIENTS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_MEASUREMENTS_TILE:
      return Object.assign({}, state, {});
    case types.GET_MEASUREMENTS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.measurements = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_MEASUREMENTS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_PATIENTS_TILE:
      return Object.assign({}, state, {});
    case types.GET_PATIENTS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.patients = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_PATIENTS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_PLACEMENTS_TILE:
      return Object.assign({}, state, {});
    case types.GET_PLACEMENTS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.placements = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_PLACEMENTS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_SUCCESS_RATE_TILE:
      return Object.assign({}, state, {});
    case types.GET_SUCCESS_RATE_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.successrate = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_SUCCESS_RATE_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_AVERAGE_HEALING_TIME_TILE:
      return Object.assign({}, state, {});
    case types.GET_AVERAGE_HEALING_TIME_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.averagehealingtime = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_AVERAGE_HEALING_TIME_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.GET_INSIGHTS_TILE:
      return Object.assign({}, state, {});
    case types.GET_INSIGHTS_TILE_SUCCESS:
      contentCopy = Object.assign({}, state.content);
      contentCopy.insights = action.content;
      return Object.assign({}, state, { content: contentCopy });
    case types.GET_INSIGHTS_TILE_ERROR:
      return Object.assign({}, state, {});
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
