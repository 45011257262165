import React from 'react';
import styles from './Forms.module.scss';
import cn from 'classnames';

const InputSubGroup = (props) => (
  <div>
    {props.label && <label className={styles.label}>{props.label}</label>}
    <div className={cn(styles.inputSubGroup, props.className)}>{props.children}</div>
  </div>
);

export default InputSubGroup;
