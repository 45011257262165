import { FC } from 'react';
import { IPatientSortValues } from '../../types/patient';
import PatientSearch from '../../components/PatientRecords/PatientSearch';
import Dropdown from '../../components/Shared/Dropdown/Dropdown';
import Button from '../../../src/components/Shared/Buttons/Button';
import styles from './PatientListPage.module.scss';

interface Props {
  sortValues: IPatientSortValues;
  sortPatientsHandler: (sortValues: IPatientSortValues) => void;
}

const PatientsListFilterPanel: FC<Props> = ({ sortValues, sortPatientsHandler }) => {
  const dropdownOptions = [
    {
      label: 'Recently updated',
      action: () => {
        sortPatientsHandler({
          selectedOptionIndex: 0,
          propertyToSortBy: 'lastModified',
          sortByDate: true,
        });
      },
    },
    {
      label: 'First name',
      action: () => {
        sortPatientsHandler({
          selectedOptionIndex: 1,
          propertyToSortBy: 'firstName',
          sortByDate: false,
        });
      },
    },
    {
      label: 'Last name',
      action: () => {
        sortPatientsHandler({
          selectedOptionIndex: 2,
          propertyToSortBy: 'lastName',
          sortByDate: false,
        });
      },
    },
    {
      label: 'Patient ID  ',
      action: () => {
        sortPatientsHandler({
          selectedOptionIndex: 3,
          propertyToSortBy: 'patientIdentifier',
          sortByDate: false,
        });
      },
    },
  ];

  return (
    <div className={styles.searchFlexContainer}>
      <PatientSearch />
      <Dropdown
        className={styles.dropdown}
        options={dropdownOptions}
        disabled={false}
        dataCy={`active-patients-list-dropdown`}
        align={undefined}
        filterServer={undefined}
        footer={undefined}
        actionData={undefined}
        noTranslate={undefined}
        fullWidth={undefined}
        searchBox={undefined}
        searchSelect={undefined}
        onChange={undefined}
      >
        <Button
          className={styles.dropdownButton}
          disabled={false}
          small
          white
          value={`Sort by: ${dropdownOptions[sortValues.selectedOptionIndex].label}`}
          rightIcon="arrow_down"
        />
      </Dropdown>
    </div>
  );
};

export default PatientsListFilterPanel;
