import { useState } from 'react';
import { Tab, Tabs as TabsComponent, TabList as TL } from 'react-tabs';
import styles from './Tabs.module.scss';

const Tabs = (props) => {
  const { className, children, tabs, onChange } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const selectTabHandler = (selectedTabIndex) => {
    onChange && onChange(selectedTabIndex);
    setSelectedTabIndex(selectedTabIndex);
  };

  return (
    <TabsComponent onSelect={selectTabHandler} className={className} {...props}>
      <TL className={styles.tabList}>
        {tabs.map((title, i) => (
          <Tab
            data-cy={`tab-${title}`}
            key={title}
            className={selectedTabIndex === i ? styles.active : null}
          >
            {title}
          </Tab>
        ))}
      </TL>
      {children}
    </TabsComponent>
  );
};

export default Tabs;
