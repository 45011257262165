import { toastr } from 'react-redux-toastr';
import { Button } from '../Shared/Buttons';
import { showAlert } from '../../redux/actions/generalActions';
import { toastrOptions } from '../../constants/toastrOptions';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { IDxIcon, beaconIcon } from '../../constants/registerIllustrations';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { Table, TableHead, TableBody, TH, TR, TD } from '../Shared/Table';
import { downloadFirmware as downloadFirmwareRequest } from '../../redux/actions/firmwareActions';
import {
  setInstrumentName as setInstrumentNameRequest,
  unsubscribeFromInstrumentsChange,
  shareInstrumentPatients as shareInstrumentPatientsRequest,
  subscribeToInstrumentsChange,
} from '../../redux/actions/instrumentActions';
import DevicesDownloadGateways from './DevicesDownloadGateways';
import ChangeDeviceName from '../../pages/devices-page/ChangeDeviceName';
import Dropdown from '../Shared/Dropdown/Dropdown';
import styles from './Devices.module.scss';
import Modal from '../Shared/Modal/Modal';
import { IInstrument, IInstrumentTableRowData } from '../../types/instrument';
import Spinner from '../Shared/Loaders/Spinner';

const devices: { [key: string]: JSX.Element } = {
  Beacon: beaconIcon,
  IDx: IDxIcon,
  MegaISQ2: beaconIcon,
};

interface Props {
  instruments: IInstrument[];
  actAsOtherId?: string | null;
}

const Devices: FC<Props> = ({ instruments, actAsOtherId }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [downLoadingFirmwareType, setDownLoadingFirmwareType] = useState<string | null>(null);
  const [showInstrumentNameModal, setShowInstrumentNameModal] = useState(false);
  const [instrumentNameValue, setInstrumentNameValue] = useState('');
  const [selectedInstrumentType, setSelectedInstrumentType] = useState<string | null>('Beacon');
  const [selectedInstrument, setSelectedInstrument] = useState<IInstrumentTableRowData | null>(
    null
  );
  const [isFetchingInstruments, setIsFetchingInstruments] = useState(false);
  const [isDownloadingFirmware, setIsDownloadingFirmware] = useState(false);
  const [isSharingInstrumentPatients, setIsSharingInstrumentPatients] = useState(false);

  const { user } = useAppSelector((state) => state.user);
  const { downloadFirmware } = useAppSelector((state) => state.firmware);
  const { getInstruments, setInstrumentName, shareInstrumentPatients } = useAppSelector(
    (state) => state.instrument
  );

  const actAsOther = actAsOtherId ? true : false;

  useEffect(() => {
    setIsFetchingInstruments(true);

    return () => {
      const instrumentSourceUserGroupIds = instruments
        .map((instrument) => instrument.sourceUserGroupId)
        .filter(onlyUnique);

      dispatch(unsubscribeFromInstrumentsChange(instrumentSourceUserGroupIds));
    };
  }, []);

  const onlyUnique = (value: string | undefined, index: number, self: (string | undefined)[]) =>
    self.indexOf(value) === index;

  useEffect(() => {
    if (shareInstrumentPatients.complete && isSharingInstrumentPatients) {
      const message =
        shareInstrumentPatients.sharePatients === true
          ? intl.formatMessage({
              id: 'devices.patientShared',
              defaultMessage: 'Instrument patients shared',
            })
          : intl.formatMessage({
              id: 'devices.patientNotShared',
              defaultMessage: 'Instrument patients not shared',
            });
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({ id: message }),
        toastrOptions
      );
    }
  }, [shareInstrumentPatients]);

  useEffect(() => {
    if (setInstrumentName.complete) {
      closeInstrumentModal();
    }

    if (getInstruments.complete && isFetchingInstruments) {
      setIsFetchingInstruments(false);
      const instrumentSourceUserGroupIds = instruments
        .map((instrument) => instrument.sourceUserGroupId)
        .filter(onlyUnique);

      dispatch(subscribeToInstrumentsChange(instrumentSourceUserGroupIds, getCurrentUserId()));
    }
  }, [setInstrumentName, getInstruments, isFetchingInstruments, instruments]);

  useEffect(() => {
    if (downloadFirmware.complete && isDownloadingFirmware) {
      setIsDownloadingFirmware(false);
      setSelectedInstrumentType(null);
      setDownLoadingFirmwareType(null);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'devices.firmwareDownloaded',
          defaultMessage: 'Firmware downloaded',
        }),
        toastrOptions
      );
    }
  }, [downloadFirmware]);

  const getCurrentUserId = () => (actAsOtherId ? actAsOtherId : user.id);

  const shareInstrumentPatientsHandler = (instrumentId: string, share: boolean) => {
    if (user.hasInstrumentPendingUpdates) {
      const content = (
        <div>
          <p>
            {intl.formatMessage({
              id: 'idxMessage.inOrderToUse',
              defaultMessage:
                'In order to use this functionality you need to upgrade your IDx to the latest software.',
            })}
          </p>
          <p>
            {intl.formatMessage({
              id: 'idxMessage.forMoreInformation',
              defaultMessage: 'For more information on how to update your IDx please follow:',
            })}
          </p>
          <a
            href="https://www.osstell.com/wp-content/uploads/2015/03/Software-Upgrade-6.0.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {intl.formatMessage({
              id: 'idxMessage.howToUpgrade',
              defaultMessage: 'How to upgrade',
            })}
          </a>
        </div>
      );
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'idxMessage.upgradeAvailable',
            defaultMessage: 'IDx Upgrade available',
          }),
          content
        )
      );
      return;
    }
    setIsSharingInstrumentPatients(true);
    dispatch(shareInstrumentPatientsRequest(getCurrentUserId(), instrumentId, share, actAsOther));
  };

  const renderDeviceRows = () => {
    if (instruments && instruments.length > 0) {
      return instruments.map((instrument, index) => {
        const inst = {
          id: instrument.id,
          serialNumber: instrument.serialNumber,
          pukCode: instrument.puk,
          lastMeasurement: instrument.lastMeasurement,
          firmwareVersion: instrument.firmware,
          name: instrument.name ? instrument.name : instrument.serialNumber,
          shareAllPatients: instrument.shareAllPatients,
          sourceSystem: instrument.sourceSystem,
          variant: instrument.variant,
          instrumentModel: instrument.instrumentModel,
          demo: instrument.setDemo,
        };

        return (
          <TR key={index}>
            <TD className={styles.icon} contentWidth>
              <div className={styles.deviceIcon}>
                {devices[inst.instrumentModel] || devices.IDx}
              </div>
            </TD>
            <TD>{inst.name}</TD>
            <TD align="right" contentWidth>
              {getSharingDataString(inst)}
            </TD>
            <TD align="right" contentWidth>
              {(inst.instrumentModel === 'IDx' && inst.pukCode) || 'N/A'}
            </TD>
            <TD align="right" contentWidth>
              {inst.serialNumber || '-'}
            </TD>
            <TD align="right" contentWidth>
              {inst.firmwareVersion || '-'}
            </TD>
            <TD contentWidth button>
              <Dropdown
                align="right"
                options={getInstrumentOptions(inst)}
                className={undefined}
                filterServer={undefined}
                footer={undefined}
                dataCy={undefined}
                actionData={undefined}
                noTranslate={undefined}
                fullWidth={undefined}
                searchBox={undefined}
                searchSelect={undefined}
                disabled={undefined}
                onChange={undefined}
              >
                <div className={styles.dropdownToggle}>
                  <i></i>
                </div>
              </Dropdown>
            </TD>
          </TR>
        );
      });
    }
  };

  const renderDeviceRowsOnMobile = () => {
    if (instruments && instruments.length > 0) {
      return instruments.map((instrument, index) => {
        const inst = {
          id: instrument.id,
          serialNumber: instrument.serialNumber,
          pukCode: instrument.puk,
          lastMeasurement: instrument.lastMeasurement,
          firmwareVersion: instrument.firmware,
          name: instrument.name ? instrument.name : instrument.serialNumber,
          shareAllPatients: instrument.shareAllPatients,
          sourceSystem: instrument.sourceSystem,
          variant: instrument.variant,
          instrumentModel: instrument.instrumentModel,
          demo: instrument.setDemo,
        };
        return (
          <div key={`${index}-mobile`} className={styles.mobileRow}>
            <div className={styles.rowInfoContainer}>
              <div className={styles.rowInMobileRow}>
                <span>
                  <b>
                    {<FormattedMessage id="devices.tableHeader.type" defaultMessage="Type" />}:{' '}
                  </b>
                  {inst.instrumentModel}
                </span>
                <span>
                  <b>
                    {<FormattedMessage id="devices.tableHeader.name" defaultMessage="Name" />}:{' '}
                  </b>
                  {inst.name}
                </span>
              </div>
              <div className={styles.rowInMobileRow}>
                <span>
                  <b>
                    {
                      <FormattedMessage
                        id="devices.tableHeader.sharingData"
                        defaultMessage="Sharing data"
                      />
                    }
                    :{' '}
                  </b>
                  {getSharingDataString(inst)}{' '}
                </span>
                <span>
                  <b>{<FormattedMessage id="devices.tableHeader.puk" defaultMessage="Puk" />}: </b>
                  {(inst.instrumentModel === 'IDx' && inst.pukCode) || 'N/A'}
                </span>
              </div>
              <div>
                <span>
                  <b>
                    {
                      <FormattedMessage
                        id="devices.tableHeader.serial"
                        defaultMessage="Serial number"
                      />
                    }
                    :{' '}
                  </b>
                  {inst.serialNumber || '-'}
                </span>
              </div>
              <div>
                <span>
                  <b>
                    {
                      <FormattedMessage
                        id="devices.tableHeader.firmware"
                        defaultMessage="Firmware"
                      />
                    }
                    :{' '}
                  </b>
                  {inst.firmwareVersion || '-'}
                </span>
              </div>
            </div>

            <Dropdown
              className={styles.deviceButton}
              align="right"
              options={getInstrumentOptions(inst)}
              filterServer={undefined}
              footer={undefined}
              dataCy={undefined}
              actionData={undefined}
              noTranslate={undefined}
              fullWidth={undefined}
              searchBox={undefined}
              searchSelect={undefined}
              disabled={undefined}
              onChange={undefined}
            >
              <div className={styles.dropdownToggle}>
                <i></i>
              </div>
            </Dropdown>
          </div>
        );
      });
    }
  };

  const getInstrumentOptions = (inst: IInstrumentTableRowData) => {
    const options = [];
    options.push({
      label: intl.formatMessage({ id: 'devices.changeName', defaultMessage: 'Change name' }),
      action: () => {
        if (user.role === 'Demo') {
          dispatch(
            showAlert(
              intl.formatMessage({
                id: 'patientList.demoLimitation',
                defaultMessage: 'DEMO Limitation',
              }),
              intl.formatMessage({
                id: 'general.actionLimitedOnDemoAccount',
                defaultMessage: 'You cannot perform this action on a demo account.',
              })
            )
          );
        } else {
          openInstrumentNameModal(inst);
        }
      },
    });
    inst.instrumentModel === 'IDx' &&
      options.push({
        label: intl.formatMessage({
          id: 'devices.toggleDataSharing',
          defaultMessage: 'Toggle data sharing',
        }),
        action: () => shareInstrumentPatientsHandler(inst.id, !inst.shareAllPatients),
      });
    return options;
  };
  const getSharingDataString = (inst: IInstrumentTableRowData) => {
    if (inst.instrumentModel !== 'IDx') return 'N/A';
    return inst.shareAllPatients
      ? intl.formatMessage({ id: 'general.yes', defaultMessage: 'Yes' })
      : intl.formatMessage({ id: 'general.no', defaultMessage: 'No' });
  };
  const downloadFirmwareHandler = (name: string) => {
    setIsDownloadingFirmware(true);
    setDownLoadingFirmwareType(name);
    dispatch(downloadFirmwareRequest(name));
  };

  const renderTableHeaders = () => {
    return (
      <TableHead>
        <TR>
          <TH>
            <FormattedMessage id="devices.tableHeader.type" defaultMessage="Type" />
          </TH>
          <TH>
            <FormattedMessage id="devices.tableHeader.name" defaultMessage="Name" />
          </TH>
          <TH align="right">
            <FormattedMessage id="devices.tableHeader.sharingData" defaultMessage="Sharing data" />
          </TH>
          <TH align="right">
            <FormattedMessage id="devices.tableHeader.puk" defaultMessage="Puk" />
          </TH>
          <TH align="right">
            <FormattedMessage id="devices.tableHeader.serial" defaultMessage="Serial number" />
          </TH>
          <TH align="right">
            <FormattedMessage id="devices.tableHeader.firmware" defaultMessage="Firmware" />
          </TH>
          <TH></TH>
        </TR>
      </TableHead>
    );
  };

  const renderTableBody = () => <TableBody>{renderDeviceRows()}</TableBody>;

  const instrumentNameChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setInstrumentNameValue(evt.target.value);

  const saveInstrumentName = () => {
    const id = selectedInstrument && selectedInstrument.id;
    const name = instrumentNameValue;
    dispatch(setInstrumentNameRequest(id, name));
  };

  const selectIntrument = (inst: IInstrumentTableRowData | null) => {
    setSelectedInstrument(inst);
    setSelectedInstrumentType(inst && inst.instrumentModel);
    setInstrumentNameValue(inst ? inst.name : '');
  };

  const openInstrumentNameModal = (inst: IInstrumentTableRowData) => {
    selectIntrument(inst);
    setShowInstrumentNameModal(true);
  };

  const closeInstrumentModal = () => {
    selectIntrument(null);
    setShowInstrumentNameModal(false);
  };

  return (
    <div>
      {renderDeviceRowsOnMobile()}
      <div className={styles.deviceTable}>
        <Table>
          {renderTableHeaders()}
          {renderTableBody()}
        </Table>
        {getInstruments.busy && <Spinner className={styles.spinnerBackground} />}
      </div>
      <DevicesDownloadGateways
        downLoadingFirmwareType={downLoadingFirmwareType}
        onDownloadFirmwareClick={downloadFirmwareHandler}
      />
      <Modal
        fullModal={true}
        header={intl.formatMessage({
          id: 'devices.changeInstrumentName',
          defaultMessage: 'Change Instrument Name',
        })}
        onDismiss={() => closeInstrumentModal()}
        show={showInstrumentNameModal}
        modalContentClass={styles.modalContent}
      >
        <ChangeDeviceName
          instrumentNameChanged={instrumentNameChanged}
          instrumentName={instrumentNameValue}
          selectedInstrumentType={selectedInstrumentType}
        />
        <div className={styles.modalButtonWrapper}>
          <Button
            big
            value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
            onClick={() => closeInstrumentModal()}
          />
          <Button
            primary
            big
            value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
            onClick={() => saveInstrumentName()}
            disabled={!instrumentNameValue}
            loading={setInstrumentName.busy}
            rightIcon={'done'}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Devices;
