import * as React from 'react';

function SvgGraftliga(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1482 2048" {...props}>
      <path d="M1240 216q59 0 100.5 41t41.5 98v1051q0 97-69 165.5t-166 68.5H337q-97 0-166-68.5T102 1406V355q0-57 41-98t98-41 96.5 36.5T382 344v9h720v-9q5-55 44-91.5t94-36.5zm-210 733l-28 69q19-37 28-69zm309 457V355q0-40-28-68t-68-28-67.5 25-30.5 63q-3 37-6 90-6 100-12 172t-16.5 157.5-26 143.5-36.5 109-51 76.5-68 25.5H555q-38 0-68-25.5t-51-76.5-36.5-109-26-143.5T357 609t-12-172q-3-53-6-90-3-38-30.5-63T244 259q-42 0-70.5 28T145 355v1051q0 80 56 136t135 56h811q79 0 135.5-56t56.5-136z" />
    </svg>
  );
}

export default SvgGraftliga;
