import { Button } from '../../../components/Shared/Buttons';
import { connect } from 'react-redux';
import { Component } from 'react';
import { InputItem } from '../../../components/Shared/Forms';
import { PanelWrapper, Panel } from '../../../components/Shared/Panel';
import { FormattedTime, injectIntl } from 'react-intl';
import { SectionTabContent, Row, RowItem } from '../../../components/Shared/Section';
import { Table, TableHead, TableBody, TH, TR, TD } from '../../../components/Shared/Table';
import { bindActionCreators } from 'redux';
import * as instrumentActions from '../../../redux/actions/instrumentActions';
import * as userGroupActions from '../../../redux/actions/userGroupActions';
import * as firmwareActions from '../../../redux/actions/firmwareActions';
import * as userActions from '../../../redux/actions/userActions';
import megaISQImage from '../../devices-page/Images/megaISQ.jpg';
import beaconImage from '../../devices-page/Images/beacon.jpg';
import idxImage from '../../devices-page/Images/idx.jpg';
import Spinner from '../../../components/Shared/Loaders/Spinner';
import styles from './InstrumentsAdministration.module.scss';

class InstrumentsAdministration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedInstrumentIds: [],
      selectAll: false,
      selectedFirmwareVersion:
        this.props.getFirmwares.firmwares.length > 0
          ? this.props.getFirmwares.firmwares[0].firmwareVersion
          : '',
      users: this.props.getUsers.users ? this.props.getUsers.users : [],
      exportDone: false,
      selectedInstrumentStatuses: {
        Demo: false,
        Development: false,
        Blocked: false,
      },
      selectedInstrumentType: 'Customer',
      selectedInstrumentComment: '',
    };
    this.intl = props.intl;

    this.exportInstruments = this.exportInstruments.bind(this);
    this.exportDone = this.exportDone.bind(this);

    this.filterText = '';
    this.selectedInstrumentTypes = {
      Customer: false,
      Internal: false,
      Demo: false,
      Development: false,
      DIO: false,
    };

    this.selectedInstrumentFilterStatuses = {
      Demo: false,
      Development: false,
    };

    this.instrumentTypes = [
      { value: 'Customer' },
      { value: 'Internal' },
      { value: 'Demo' },
      { value: 'Development' },
      { value: 'DIO' },
    ];

    this.searchCriteria = {};

    this.instrumentSelected = this.instrumentSelected.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.updateInstrumentsFirmware = this.updateInstrumentsFirmware.bind(this);
    this.clearInstrumentsFirmware = this.clearInstrumentsFirmware.bind(this);
    this.firmwareChanged = this.firmwareChanged.bind(this);
    this.deleteInstruments = this.deleteInstruments.bind(this);
    this.instrumentTypeFilterChanged = this.instrumentTypeFilterChanged.bind(this);
    this.instrumentStatusFilterChanged = this.instrumentStatusFilterChanged.bind(this);
    this.filterTextChanged = this.filterTextChanged.bind(this);
    this.updateInstrumentsTypeAndStatus = this.updateInstrumentsTypeAndStatus.bind(this);
    this.instrumentTypeChanged = this.instrumentTypeChanged.bind(this);
    this.instrumentStatusChanged = this.instrumentStatusChanged.bind(this);
    this.instrumentFirmwareFilterChanged = this.instrumentFirmwareFilterChanged.bind(this);
    this.getInstruments = this.getInstruments.bind(this);
    this.searchTypeChanged = this.searchTypeChanged.bind(this);
    this.renderInstruments = this.renderInstruments.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.instrumentCommentChanged = this.instrumentCommentChanged.bind(this);

    this.selectedInstrumentFilterFirmware = false;
    this.currentFirmware = undefined;

    this.selectedSearchType = 1;

    if (this.props.user.role === 'SuperAdmin') {
      this.props.actions.getFirmwares();
    }

    this.props.actions.clearInstruments();
    this.props.actions.clearUsers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.getFirmwares.complete && nextProps.getFirmwares.complete) {
      const firmware = nextProps.getFirmwares.firmwares.find(
        (firmware) => firmware.current === true
      );
      if (firmware !== undefined) {
        this.currentFirmware = firmware.firmwareVersion;
      }
      this.setState({
        selectedFirmwareVersion:
          firmware !== undefined
            ? firmware.firmwareVersion
            : nextProps.getFirmwares.firmware.length > 0
            ? nextProps.getFirmwares.firmwares[0].firmwareVersion
            : '',
      });
    }
  }

  componentWillUnmount() {
    this.props.actions.unsubscribeFromFirmwaresChange();
    this.props.actions.unsubscribeFromInstrumentsChange();
    this.props.actions.clearInstruments();
    this.props.actions.clearUsers();
  }

  instrumentSelected(instrument) {
    let ids = [];
    if (this.state.selectedInstrumentIds.indexOf(instrument.id) !== -1) {
      ids = this.state.selectedInstrumentIds.filter((id) => id !== instrument.id);
    } else {
      ids = this.state.selectedInstrumentIds.concat(instrument.id);
    }
    this.setState({
      selectedInstrumentIds: ids,
    });

    if (ids.length === 1) {
      const instrument = this.props.instruments.find((x) => x.id === ids[0]);
      this.setState({
        selectedInstrumentStatuses: {
          Demo: instrument.setDemo,
          Development: instrument.isDevelopment,
          Blocked: instrument.isBlocked,
        },
        selectedInstrumentType: instrument.instrumentType,
        selectedInstrumentComment: instrument.comment,
      });
      return;
    }
    //Clear statuses, type and comment if we have slected more than one instrument
    this.setState({
      selectedInstrumentStatuses: {
        Demo: false,
        Development: false,
        Blocked: false,
      },
      selectedInstrumentType: 'Customer',
      selectedInstrumentComment: '',
    });
  }

  toggleAll() {
    if (!this.state.selectAll) {
      this.setState({
        selectedInstrumentIds: this.props.instruments.map((instrument) => instrument.id),
        selectAll: true,
      });
    } else {
      this.setState({
        selectedInstrumentIds: [],
        selectAll: false,
      });
    }

    //Clear statuses, type and comment if we have slected more than one instrument
    this.setState({
      selectedInstrumentStatuses: {
        Demo: false,
        Development: false,
        Blocked: false,
      },
      selectedInstrumentType: 'Customer',
      selectedInstrumentComment: '',
    });
  }

  exportInstruments() {
    this.setState({
      exportingInstruments: true,
    });

    const instrumentTypes = [];

    if (this.selectedInstrumentTypes.Customer) {
      instrumentTypes.push(0);
    }
    if (this.selectedInstrumentTypes.Internal) {
      instrumentTypes.push(1);
    }
    if (this.selectedInstrumentTypes.Demo) {
      instrumentTypes.push(2);
    }
    if (this.selectedInstrumentTypes.Development) {
      instrumentTypes.push(3);
    }
    if (this.selectedInstrumentTypes.DIO) {
      instrumentTypes.push(4);
    }

    const searchCriteria = {
      searchText: this.filterText,
      instrumentTypes: instrumentTypes,
      setDemo: this.selectedInstrumentFilterStatuses.Demo,
      isDevelopment: this.selectedInstrumentFilterStatuses.Development,
      notCurrentFirmware: this.selectedInstrumentFilterFirmware,
      searchType: this.selectedSearchType,
    };

    this.props.actions.exportInstruments(searchCriteria, this.exportDone);
  }

  exportDone() {
    this.setState({
      exportingInstruments: false,
      exportingHubspot: false,
    });
  }

  getInstruments() {
    const instrumentTypes = [];

    if (this.selectedInstrumentTypes.Customer) {
      instrumentTypes.push(0);
    }
    if (this.selectedInstrumentTypes.Internal) {
      instrumentTypes.push(1);
    }
    if (this.selectedInstrumentTypes.Demo) {
      instrumentTypes.push(2);
    }
    if (this.selectedInstrumentTypes.Development) {
      instrumentTypes.push(3);
    }
    if (this.selectedInstrumentTypes.DIO) {
      instrumentTypes.push(4);
    }

    const searchCriteria = {
      searchText: this.filterText,
      instrumentTypes: instrumentTypes,
      setDemo: this.selectedInstrumentFilterStatuses.Demo,
      isDevelopment: this.selectedInstrumentFilterStatuses.Development,
      notCurrentFirmware: this.selectedInstrumentFilterFirmware,
      searchType: this.selectedSearchType,
    };

    this.props.actions.getInstruments(null, searchCriteria);

    this.setState({
      selectedInstrumentIds: [],
      selectAll: false,
    });
  }

  renderInstruments() {
    return this.props.instruments.map((instrument, index) => {
      let country = instrument.country
        ? this.props.countries.find((c) => c.key === instrument.country)
        : undefined;

      if (country) {
        country = country.value;
      }

      let instrumentImage;
      if (instrument.sourceSystem === 'Idx') {
        instrumentImage = idxImage;
      } else {
        if (instrument.variant === 'MegaISQ2') {
          instrumentImage = megaISQImage;
        } else {
          instrumentImage = beaconImage;
        }
      }

      return (
        <TR key={index} className={instrument.isBlocked ? styles.blocked : null}>
          <TD>
            <input
              type="checkbox"
              onChange={this.instrumentSelected.bind(this, instrument)}
              checked={this.state.selectedInstrumentIds.indexOf(instrument.id) !== -1}
            />
          </TD>
          <TD>
            <img className={styles.instrumentImage} src={instrumentImage} alt="" />
          </TD>
          <TD>{instrument.serialNumber}</TD>
          <TD>{instrument.instrumentType}</TD>
          <TD>{instrument.clinicName ? instrument.clinicName : '-'}</TD>
          <TD>{instrument.ownerEmail ? instrument.ownerEmail : '-'}</TD>
          <TD>{country ? country : '-'}</TD>
          <TD>
            {instrument.registered ? (
              <div>
                <FormattedTime
                  value={instrument.registered}
                  year="numeric"
                  month="short"
                  day="2-digit"
                />
              </div>
            ) : (
              '-'
            )}
          </TD>
          <TD>{instrument.currentFirmware ? instrument.currentFirmware : '-'}</TD>
          <TD>{instrument.expectedFirmware ? instrument.expectedFirmware : '-'}</TD>
          <TD>{instrument.puk}</TD>
          <TD>{instrument.regCode}</TD>
          <TD>
            {instrument.isDemo ? 'Yes' : 'No'} / {instrument.setDemo ? 'Yes' : 'No'}
          </TD>
          <TD>{instrument.isDevelopment ? 'Yes' : 'No'}</TD>
        </TR>
      );
    });
  }

  updateInstrumentsFirmware() {
    const firmware = this.props.getFirmwares.firmwares.find(
      (firmware) => firmware.firmwareVersion === this.state.selectedFirmwareVersion
    );
    const selectedInstrumentIds = this.state.selectedInstrumentIds;

    if (this.props.updateInstrumentsFirmware) {
      this.props.updateInstrumentsFirmware(selectedInstrumentIds, firmware, this.getInstruments);
    }
  }

  clearInstrumentsFirmware() {
    const selectedInstrumentIds = this.state.selectedInstrumentIds;

    if (this.props.clearInstrumentsFirmware) {
      this.props.clearInstrumentsFirmware(selectedInstrumentIds, this.getInstruments);
    }
  }

  firmwareChanged(evt) {
    this.setState({
      selectedFirmwareVersion: evt.target.value,
    });
  }

  deleteInstruments(e) {
    e.stopPropagation();
    e.preventDefault();
    if (this.props.deleteInstruments) {
      const instruments = this.props.instruments.filter(
        (instrument) => this.state.selectedInstrumentIds.indexOf(instrument.id) !== -1
      );
      this.props.deleteInstruments(instruments, this.getInstruments);
    }
  }

  filterTextChanged(evt) {
    const text = evt.target.value;

    if (text) {
      this.filterText = text.toLowerCase();
    } else {
      this.filterText = '';
    }
  }

  instrumentTypeFilterChanged(evt) {
    this.selectedInstrumentTypes[evt.target.value] =
      !this.selectedInstrumentTypes[evt.target.value];
  }

  instrumentStatusFilterChanged(evt) {
    this.selectedInstrumentFilterStatuses[evt.target.value] =
      !this.selectedInstrumentFilterStatuses[evt.target.value];
  }

  instrumentFirmwareFilterChanged() {
    this.selectedInstrumentFilterFirmware = !this.selectedInstrumentFilterFirmware;
  }

  instrumentTypeChanged(evt) {
    this.setState({
      selectedInstrumentType: evt.target.value,
    });
  }

  instrumentStatusChanged(evt) {
    const newStatus = this.state.selectedInstrumentStatuses;
    if (evt.target.value === 'Demo') {
      newStatus.Demo = !newStatus.Demo;
    } else if (evt.target.value === 'Development') {
      newStatus.Development = !newStatus.Development;
    } else if (evt.target.value === 'Blocked') {
      newStatus.Blocked = !newStatus.Blocked;
    }
    this.setState({
      selectedInstrumentStatuses: newStatus,
    });
  }

  updateInstrumentsTypeAndStatus() {
    const selectedInstrumentIds = this.state.selectedInstrumentIds;
    const selectedInstrumentStatuses = this.state.selectedInstrumentStatuses;
    const selectedInstrumentType = this.state.selectedInstrumentType;
    const comment = this.state.selectedInstrumentComment;

    if (this.props.updateInstrumentsTypeAndStatus) {
      this.props.updateInstrumentsTypeAndStatus(
        selectedInstrumentType,
        selectedInstrumentStatuses,
        comment,
        selectedInstrumentIds,
        this.getInstruments
      );
    }
  }

  instrumentCommentChanged(evt) {
    this.setState({
      selectedInstrumentComment: evt.target.value,
    });
  }

  searchTypeChanged(evt) {
    this.selectedSearchType = evt.target.value;
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.getInstruments();
    }
  }

  render() {
    const firmwares = this.props.getFirmwares.firmwares;
    const firmwareVersions = firmwares.map((firmware) => {
      return { value: firmware.firmwareVersion };
    });
    const loading = this.props.getFirmwares.busy || this.props.getInstruments.busy;
    const currentFirmwareLable =
      'Instrument not having the current firmware' +
      ': ' +
      (this.currentFirmware ? this.currentFirmware : 'N/A');
    const searchTypes = [
      { value: 'Serial number', key: 1 },
      { value: 'Clinic name', key: 2 },
      { value: 'Owner email', key: 3 },
    ];
    return (
      <SectionTabContent fullWidth>
        <Row className={styles.marginTop}>
          {(this.props.user.role === 'SuperAdmin' || this.props.user.role === 'AdminLv1') && (
            <RowItem>
              <div className="push-right">
                <Button
                  primary
                  value="Instrument export"
                  onClick={this.exportInstruments}
                  loading={this.state.exportingInstruments}
                  disabled={this.state.exportingInstruments}
                />
              </div>
            </RowItem>
          )}
        </Row>
        <PanelWrapper className={styles.marginTop}>
          <Panel full filled>
            <Row className={styles.headerRow}>
              <InputItem
                type="search"
                placeholder="Find an instrument by serial number, clinic name or owner"
                onChange={this.filterTextChanged}
                onKeyPress={this.handleKeyPress}
                onWhite
              />
              <div className={styles.searchTypeDropdown}>
                <InputItem
                  label="Search type"
                  type="select"
                  options={searchTypes}
                  onChange={this.searchTypeChanged}
                  onWhite
                />
              </div>
              <Button
                className={styles.findButton}
                primary
                value="Find"
                onClick={() => {
                  this.getInstruments(false);
                }}
                loading={this.props.getInstruments.busy}
                disabled={this.props.getInstruments.busy}
              />
            </Row>

            {this.props.user.role === 'SuperAdmin' && (
              <div>
                <div>
                  Instrument type
                  <Row>
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Customer"
                      label="Customer"
                      onChange={this.instrumentTypeFilterChanged}
                      noGrow
                    />
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Internal"
                      label="Internal"
                      onChange={this.instrumentTypeFilterChanged}
                      noGrow
                    />
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Demo"
                      label="Demo"
                      onChange={this.instrumentTypeFilterChanged}
                      noGrow
                    />
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Development"
                      label="Development"
                      onChange={this.instrumentTypeFilterChanged}
                      noGrow
                    />
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="DIO"
                      label="DIO"
                      onChange={this.instrumentTypeFilterChanged}
                      noGrow
                    />
                    <div className={styles.flexContainer}></div>
                  </Row>
                </div>
                <div>
                  Instrument status
                  <Row>
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Demo"
                      label="Demo (set value)"
                      onChange={this.instrumentStatusFilterChanged}
                      noGrow
                    />
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Development"
                      label="Development"
                      onChange={this.instrumentStatusFilterChanged}
                      noGrow
                    />
                    <div className={styles.flexContainer}></div>
                  </Row>
                </div>
                <div>
                  Firmware status
                  <Row>
                    <InputItem
                      type="checkbox"
                      noMargins
                      value="Firmware"
                      label={currentFirmwareLable}
                      onChange={this.instrumentFirmwareFilterChanged}
                    />
                    <div className={styles.flexContainer}></div>
                  </Row>
                </div>
              </div>
            )}

            {this.state.selectedInstrumentIds.length > 0 && (
              <div className={styles.actionBox}>
                <Row>
                  {this.state.selectedInstrumentIds.length === 1 && (
                    <p>{this.state.selectedInstrumentIds.length} Instrument selected</p>
                  )}
                  {this.state.selectedInstrumentIds.length > 1 && (
                    <p>{this.state.selectedInstrumentIds.length} Instruments selected</p>
                  )}
                </Row>
                {(this.props.user.role === 'SuperAdmin' || this.props.user.role === 'AdminLv1') && (
                  <Row className={styles.actions}>
                    <InputItem
                      small
                      label="Push IDx firmware to selected instruments"
                      type="select"
                      options={firmwareVersions}
                      value={this.state.selectedFirmwareVersion}
                      onChange={this.firmwareChanged}
                      noGrow
                    />
                    <InputItem
                      small
                      type="button"
                      value="Push Firmware"
                      onClick={this.updateInstrumentsFirmware}
                      noGrow
                    />
                    <InputItem
                      small
                      type="button"
                      value="Clear firmware"
                      onClick={this.clearInstrumentsFirmware}
                      noGrow
                    />
                  </Row>
                )}
                <Row className={styles.actions}>
                  <InputItem
                    small
                    label="Set type on selected instruments"
                    type="select"
                    value={this.state.selectedInstrumentType}
                    options={this.instrumentTypes}
                    onChange={this.instrumentTypeChanged}
                    noGrow
                  />
                  <InputItem
                    type="checkbox"
                    value="Demo"
                    label="Demo"
                    checked={this.state.selectedInstrumentStatuses.Demo}
                    onChange={this.instrumentStatusChanged}
                    noGrow
                  />
                  <InputItem
                    type="checkbox"
                    value="Development"
                    label="Development"
                    checked={this.state.selectedInstrumentStatuses.Development}
                    onChange={this.instrumentStatusChanged}
                    noGrow
                  />
                  <InputItem
                    type="checkbox"
                    value="Blocked"
                    label="Blocked"
                    checked={this.state.selectedInstrumentStatuses.Blocked}
                    onChange={this.instrumentStatusChanged}
                    noGrow
                  />
                </Row>
                <Row>
                  <InputItem
                    small
                    type="text"
                    label="Instrument Comment"
                    onChange={this.instrumentCommentChanged}
                    value={this.state.selectedInstrumentComment}
                  />
                  <InputItem
                    small
                    type="button"
                    value="Update"
                    onClick={this.updateInstrumentsTypeAndStatus}
                  />
                </Row>
                <Row className={styles.actions}>
                  <InputItem
                    small
                    type="button"
                    label="Remove selected instruments from user"
                    value="Remove"
                    onClick={this.deleteInstruments}
                  />
                </Row>
              </div>
            )}
            <Row>
              <RowItem>
                {loading ? (
                  <Spinner className={styles.spinnerBackground} />
                ) : (
                  <Table>
                    <TableHead>
                      <TR>
                        <TH>
                          <input
                            type="checkbox"
                            onChange={this.toggleAll}
                            checked={this.state.selectAll}
                          />
                        </TH>
                        <TH></TH>
                        <TH>Serial</TH>
                        <TH>Type</TH>
                        <TH>Clinic</TH>
                        <TH>Owner</TH>
                        <TH>Country</TH>
                        <TH>Registered</TH>
                        <TH>Current FW</TH>
                        <TH>Expected FW</TH>
                        <TH>Puk</TH>
                        <TH>Reg. Code</TH>
                        <TH>Demo (is/set to be)</TH>
                        <TH>Development</TH>
                      </TR>
                    </TableHead>
                    <TableBody>{this.renderInstruments()}</TableBody>
                  </Table>
                )}
              </RowItem>
            </Row>
          </Panel>
        </PanelWrapper>
      </SectionTabContent>
    );
  }
}

function mapState(state) {
  return {
    locale: state.translation.locale,
    getInstruments: state.instrument.getInstruments,
    instruments: state.instrument.instruments,
    getFirmwares: state.firmware.getFirmwares,
    getUsers: state.user.getUsers,
    countries: state.referenceData.countries,
    user: state.user.user,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(
      { ...instrumentActions, ...userGroupActions, ...firmwareActions, ...userActions },
      dispatch
    ),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(InstrumentsAdministration));
