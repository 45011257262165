import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import * as patientActions from '../../redux/actions/patientActions';

import ToothWH from './ToothWH';

import styles from './DentalChart.module.scss';

class DentalChart extends Component {
  componentWillUnmount() {
    //Deselect tooth
    this.props.actions.selectPatientImplant(-1);
  }

  renderDentalChart(position) {
    if (this.props.dentalNotifications && this.props.dentalNotifications.length > 0) {
      //Skip wisdom teeth and filter upper or lower
      let notifications = this.props.dentalNotifications
        .filter(
          (notification) =>
            notification.uns !== 1 &&
            notification.uns !== 16 &&
            notification.uns !== 32 &&
            notification.uns !== 17
        )
        .filter(
          (notification) =>
            (position === 'upper' && notification.uns < 17) ||
            (position === 'lower' && notification.uns > 16)
        );

      if (position === 'lower') {
        notifications = notifications.reverse();
      }
      return notifications.map(
        function (notification, index) {
          let implant = undefined;
          if (this.props.implants && this.props.implants.length > 0) {
            implant = this.props.implants.find((implant) => implant.tooth === notification.uns);
          }
          let selected = this.props.selectedImplant === notification.uns;
          let faded =
            this.props.selectedImplant > 0 && this.props.selectedImplant !== notification.uns;
          return (
            <ToothWH
              dataCy={`tooth-wh-${position}-${index}`}
              key={notification.uns}
              notation={notification.uns}
              fdiNotation={notification.fdi}
              displayNotation={this.props.user.teethNotation}
              position={position}
              implant={implant}
              faded={faded}
              selected={selected}
              static={this.props.static}
              small={this.props.small}
            />
          );
        }.bind(this)
      );
    }

    return null;
  }

  render() {
    return (
      <div className={styles.dentalChart}>
        <div className={styles.row}>{this.renderDentalChart('upper')}</div>
        <div className={styles.row}>{this.renderDentalChart('lower')}</div>
      </div>
    );
  }
}

function mapState(state) {
  return {
    user: state.user.user,
    dentalNotifications: state.referenceData.dentalNotifications,
    selectedImplant: state.patient.selectedPatientImplant,
  };
}

function mapDispatch(dispatch) {
  return {
    actions: bindActionCreators(patientActions, dispatch),
  };
}

export default connect(mapState, mapDispatch)(injectIntl(DentalChart));
