import { Button } from '../Shared/Buttons';
import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { AlertBox } from '../Shared/Alerts';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import styles from './CreateImplantModal.module.scss';
import Modal from '../Shared/Modal/Modal';
import {
  getUsedImplants as getUsedImplantsRequest,
  setImplantData as setImplantDataRequest,
} from '../../redux/actions/patientActions';
import {
  getImplantData as getImplantDataRequest,
  getAbutmentData as getAbutmentDataRequest,
  getStudyImplantData as getStudyImplantDataRequest,
  getStudyAbutmentData as getStudyAbutmentDataRequest,
} from '../../redux/actions/referenceDataActions';
import ImplantSelector from './ImplantSelector';
import DataItem from '../Shared/DataItem/DataItem';
import { toastrOptions } from '../../constants/toastrOptions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import {
  IAbutmentData,
  IAbutmentStudyData,
  IImplant,
  IImplantData,
  IImplantStudyData,
  IImplantStudyGroup,
} from '../../types/implant';

interface Props {
  show: boolean;
  hide: () => void;
  implant: IImplant | null;
  abutment: boolean;
}

const CreateImplantModal: FC<Props> = ({ show, hide, implant, abutment }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();

  const [dataId, setDataId] = useState<string | number>(-1);
  const [abutmentId, setAbutmentId] = useState<string | number>(-1);
  const [implantLot, setImplantLot] = useState('');
  const [abutmentLot, setAbutmentLot] = useState('');
  const [errorMessages, setErrorMessages] = useState<{ messages: { text: string }[] } | undefined>(
    undefined
  );
  const [isStudyTreatment, setIsStudyTreatment] = useState(false);
  const [studyImplants, setStudyImplants] = useState<IImplantStudyData[]>([]);
  const [studyAbutments, setStudyAbutments] = useState<IAbutmentStudyData[]>([]);

  const [isSettingImplantData, setIsSettingImplantData] = useState(false);

  const { user } = useAppSelector((state) => state.user);
  const {
    currentPatient: patient,
    setImplantData,
    selectedPatientImplant: selectedImplant,
    currentImplants: currentImplant,
    usedImplants,
    usedAbutments,
  } = useAppSelector((state) => state.patient);
  const {
    implantData: implants,
    abutmentData: abutments,
    studyImplantData,
    studyAbutmentData,
  } = useAppSelector((state) => state.referenceData);

  const { studyGroups } = useAppSelector((state) => state.studyGroup);

  useEffect(() => {
    if (!implants || implants.length === 0) {
      dispatch(getImplantDataRequest(true));
    }
    if (!abutments || abutments.length === 0) {
      dispatch(getAbutmentDataRequest(true));
    }
    if (!usedImplants) {
      dispatch(getUsedImplantsRequest());
    }
    if (!studyImplantData || studyImplantData.length === 0) {
      dispatch(getStudyImplantDataRequest());
    }
    if (!studyAbutmentData || studyAbutmentData.length === 0) {
      dispatch(getStudyAbutmentDataRequest());
    }

    // get study implants and abutments if its a study tooth
    const studiesOfSelectedTooth = currentImplant.find(
      (implant: IImplant) => implant.tooth === selectedImplant
    )?.studyGroups;
    if (studiesOfSelectedTooth !== undefined && studiesOfSelectedTooth.length > 0) {
      getStudyImplantsAndAbutments(studiesOfSelectedTooth, abutment);
    }

    dispatch(getUsedImplantsRequest());

    if (implant) {
      setAbutmentId(implant.abutmentId);
      setDataId(implant.dataId);
      setImplantLot(implant.implantLot ?? '');
      setAbutmentLot(implant.abutmentLot ?? '');
    }
  }, []);

  useEffect(() => {
    if (setImplantData.complete && isSettingImplantData) {
      setIsSettingImplantData(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({ id: 'implant.implantSaved', defaultMessage: 'Implant saved' }),
        toastrOptions
      );
      hideSetImplantDataModal();
    }
  }, [setImplantData.complete, isSettingImplantData]);

  const getStudyImplantsAndAbutments = (
    studiesOfSelectedTooth: IImplantStudyGroup[],
    nextPropsAbutment: boolean
  ) => {
    const studyGroupsOfUser = studyGroups;

    let studyImplantToGet: number[] = [];
    let studyAbutmentToGet: number[] = [];

    // Loop through studies of selected tooth and study groups of the user.
    // If we find a match add studyImplants/studyAbutments to variables above.
    for (let i = 0; i < studiesOfSelectedTooth.length; i++) {
      for (let j = 0; j < studyGroupsOfUser.length; j++) {
        if (studiesOfSelectedTooth[i].id === studyGroupsOfUser[j].id) {
          studyImplantToGet = [...studyImplantToGet, ...studyGroupsOfUser[j].studyImplants];
          studyAbutmentToGet = [...studyAbutmentToGet, ...studyGroupsOfUser[j].studyAbutments];
        }
      }
    }

    if (nextPropsAbutment && studyAbutmentToGet.length > 0) {
      const studyAbutmentToDropDown: IAbutmentStudyData[] = [];
      studyAbutmentToGet.forEach((id) => {
        const abutment = studyAbutmentData.find(
          (abutment: IAbutmentStudyData) => abutment.dataId === id
        );
        studyAbutmentToDropDown.push(abutment);
      });

      setIsStudyTreatment(true);
      setStudyAbutments(studyAbutmentToDropDown);
    } else if (!nextPropsAbutment && studyImplantToGet.length > 0) {
      const studyImplantsToDropDown: IImplantStudyData[] = [];
      studyImplantToGet.forEach((id) => {
        const implant = studyImplantData.find(
          (implant: IImplantStudyData) => implant.dataId === id
        );
        studyImplantsToDropDown.push(implant);
      });

      setIsStudyTreatment(true);
      setStudyImplants(studyImplantsToDropDown);
    } else {
      setIsStudyTreatment(false);
    }
  };

  const hideSetImplantDataModal = () => {
    resetState();
    if (hide) {
      hide();
    }
  };

  const saveImplant = () => {
    if (selectedImplant <= 0) return;

    setIsSettingImplantData(true);
    dispatch(
      setImplantDataRequest(
        patient.sourceUserGroupId,
        patient.id,
        selectedImplant,
        dataId,
        abutmentId,
        implantLot,
        abutmentLot
      )
    );
  };

  const onImplantSelected = (id: string | number) => setDataId(id);

  const onAbutmentSelected = (id: string | number) => setAbutmentId(id);

  const errorMessagesHandler = (errorMessageArray: { messages: { text: string }[] } | undefined) =>
    setErrorMessages(errorMessageArray);

  const resetState = () => {
    setDataId(-1);
    setAbutmentId(-1);
    setImplantLot('');
    setAbutmentLot('');
    setErrorMessages(undefined);
    setIsStudyTreatment(false);
    setStudyImplants([]);
    setStudyAbutments([]);
  };

  const implantLotChanged = (evt: ChangeEvent<HTMLInputElement>) => setImplantLot(evt.target.value);

  const abutmentLotChanged = (evt: ChangeEvent<HTMLInputElement>) =>
    setAbutmentLot(evt.target.value);

  const renderImplants = () => {
    if (implants && implants.length > 0) {
      let implant = isStudyTreatment
        ? studyImplants.find((x) => x.dataId === Number(dataId))
        : implants.find((x: IImplantData) => x.dataId === Number(dataId));

      if (!implant) implant = {};
      const implantsToShow = isStudyTreatment ? studyImplants : implants;

      return (
        <div className={styles.content}>
          <div className={styles.column}>
            <ImplantSelector
              study={isStudyTreatment}
              implants={implantsToShow}
              usedImplants={usedImplants}
              dataId={dataId}
              onImplantSelected={onImplantSelected}
              lot={implantLot}
              lotChanged={implantLotChanged}
              setErrorMessages={errorMessagesHandler}
              resetImplantModalState={resetState}
              type="implant"
              userId={user.id}
              email={user.email}
            />
          </div>
          <div className={styles.column}>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({ id: 'implant.model', defaultMessage: 'Model' })}
                value={implant.modelName}
                span
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.platform',
                  defaultMessage: 'Platform',
                })}
                value={implant.platformName}
              />
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.artNumber',
                  defaultMessage: 'Article number',
                })}
                value={implant.partNumber}
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.diameter',
                  defaultMessage: 'Diameter',
                })}
                value={implant.diameter}
              />
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.lenth',
                  defaultMessage: 'Length (mm)',
                })}
                value={implant.length}
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.suitableSmartPeg',
                  defaultMessage: 'Suitable SmartPeg',
                })}
                value={implant.smartPeg}
                span
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const renderAbutments = () => {
    if (implants && implants.length > 0) {
      let implant = isStudyTreatment
        ? studyAbutments.find((x) => x.dataId === Number(abutmentId))
        : abutments.find((x: IAbutmentData) => x.dataId === Number(abutmentId));

      if (!implant) implant = {};
      const abutmentsToShow = isStudyTreatment === true ? studyAbutments : abutments;
      return (
        <div className={styles.content}>
          <div className={styles.column}>
            <ImplantSelector
              study={isStudyTreatment}
              implants={abutmentsToShow}
              usedImplants={usedAbutments}
              dataId={abutmentId}
              onImplantSelected={onAbutmentSelected}
              lot={abutmentLot}
              lotChanged={abutmentLotChanged}
              setErrorMessages={errorMessagesHandler}
              resetImplantModalState={resetState}
              type="abutment"
              userId={user.id}
              email={user.email}
            />
          </div>
          <div className={styles.column}>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({ id: 'implant.model', defaultMessage: 'Model' })}
                value={implant.modelName}
                span
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.platform',
                  defaultMessage: 'Platform',
                })}
                value={implant.platformName}
              />
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.artNumber',
                  defaultMessage: 'Article number',
                })}
                value={implant.partNumber}
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.diameter',
                  defaultMessage: 'Diameter',
                })}
                value={implant.diameter}
              />
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.lenth',
                  defaultMessage: 'Length (mm)',
                })}
                value={implant.length}
              />
              <DataItem
                label={intl.formatMessage({ id: 'implant.angle', defaultMessage: 'Angle' })}
                value={implant.angle}
              />
            </div>
            <div className={styles.dataRow}>
              <DataItem
                label={intl.formatMessage({
                  id: 'implant.suitableSmartPeg',
                  defaultMessage: 'Suitable SmartPeg',
                })}
                value={implant.smartPeg}
                span
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const disableSave = () =>
    !((abutment && Number(abutmentId) > 0) || (!abutment && Number(dataId) > 0));

  const header = abutment
    ? intl.formatMessage({
        id: 'addimplant.addAbutment',
        defaultMessage: 'Add Abutment',
      })
    : intl.formatMessage({
        id: 'addimplant.addImplant',
        defaultMessage: 'Add Implant',
      });

  const error = setImplantData.error || errorMessages;
  return (
    <Modal
      modalPanelClass={styles.modalPanel}
      header={header}
      onDismiss={hideSetImplantDataModal}
      show={show}
    >
      {error ? (
        <AlertBox
          color="black"
          headerText={intl.formatMessage({
            id: 'general.generalError',
            defaultMessage: 'Oops, there was an error',
          })}
          messages={error.messages.map((m: { text: string }) => m.text)}
        />
      ) : null}
      {abutment ? renderAbutments() : renderImplants()}
      <div className={styles.buttons}>
        <Button
          centered
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          onClick={() => hideSetImplantDataModal()}
          leftIcon="back"
        />
        <Button
          primary
          value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
          onClick={saveImplant}
          disabled={disableSave()}
        />
      </div>
    </Modal>
  );
};

export default CreateImplantModal;
