import * as types from '../../constants/actionTypes';

const initialState = {
  locale: 'en',
  allTranslations: {},
  currentTranslations: {},
  uploadTranslations: {
    complete: false,
    error: undefined,
    busy: false,
  },
  publicTranslationsLoaded: false,
  privateTranslationsLoaded: false,
};

function parseTranslations(locale, translations) {
  const keys = Object.keys(translations);
  const parsedTranslations = {};

  keys.forEach((key) => {
    parsedTranslations[key] = translations[key][locale];
  });

  return parsedTranslations;
}

export default function translation(state = initialState, action) {
  switch (action.type) {
    case types.GET_TRANSLATIONS:
      return Object.assign({}, state, { loaded: false });
    case types.GET_TRANSLATIONS_SUCCESS:
      return Object.assign({}, state, {
        allTranslations: action.translations,
        currentTranslations: parseTranslations(state.locale, action.translations),
        publicTranslationsLoaded: state.publicTranslationsLoaded || action.publicTranslations,
        privateTranslationsLoaded: state.privateTranslationsLoaded || !action.publicTranslations,
      });
    case types.GET_TRANSLATIONS_ERROR:
      return Object.assign({}, state, {});
    case types.CHANGE_LOCALE:
      return Object.assign({}, state, {
        locale: action.locale,
        currentTranslations: parseTranslations(action.locale, state.allTranslations),
      });
    case types.REGISTER_TRANSLATIONS:
      return Object.assign({}, state, {
        uploadTranslations: {
          complete: false,
          error: undefined,
          busy: true,
        },
      });
    case types.REGISTER_TRANSLATIONS_SUCCESS:
      return Object.assign({}, state, {
        uploadTranslations: {
          complete: true,
          error: undefined,
          busy: false,
        },
      });
    case types.REGISTER_TRANSLATIONS_ERROR:
      return Object.assign({}, state, {
        uploadTranslations: {
          complete: false,
          error: action.error,
          busy: false,
        },
      });
    default:
      return state;
  }
}
