import { Component } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import { color } from 'd3-color';
import { animated } from 'react-spring';
import { osstellChartTheme } from './chartTheme';
import styles from './Charts.module.scss';
const getTspanGroups = (value, maxLineLength, maxLines = 2) => {
  if (typeof value === 'number' && isFinite(value)) {
    if (Math.floor(value) !== value) return;
  }
  const words = String(value).split(' ');

  //reduces the words into lines of maxLineLength
  const assembleLines = words.reduce(
    (acc, word) => {
      //if the current line isn't empty and the word + current line is larger than the allowed line size, create a new line and update current line
      if ((word + acc.currLine).length > maxLineLength && acc.currLine !== '') {
        return {
          lines: acc.lines.concat([acc.currLine]),
          currLine: word,
        };
      }
      //otherwise add the word to the current line
      return {
        ...acc,
        currLine: acc.currLine + ' ' + word,
      };
    },
    { lines: [], currLine: '' }
  );

  //add the ending state of current line (the last line) to lines
  const allLines = assembleLines.lines.concat([assembleLines.currLine]);

  //for now, only take first 2 lines due to tick spacing and possible overflow
  const lines = allLines.slice(0, maxLines);
  let children = [];
  let dy = 0;

  lines.forEach((lineText, i) => {
    children.push(
      <tspan x={0} dy={dy} key={i} style={osstellChartTheme.axis.ticks.text}>
        {
          // if on the second line, and that line's length is within 3 of the max length, add ellipsis
          1 === i && allLines.length > 2 ? lineText.slice(0, maxLineLength - 3) + '...' : lineText
        }
      </tspan>
    );
    //increment dy to render next line text below
    dy += 10;
  });

  return children;
};

const colors = ['#C749B3', '#562982', '#296A82', '#298267', '#822929'];

const gradients = colors.map((c, i) => {
  return linearGradientDef(i, [
    { offset: 0, color: c },
    { offset: 100, color: color(c).darker(1).formatHex() },
  ]);
});

const dataKeys = ['min', 'stickMin', 'main', 'stickMax', 'max'];

const fillMatch = (data) =>
  data.map((_d, i) => {
    return {
      match: (d) => {
        return d.data.index === i;
      },
      id: i,
    };
  });

// const CustomBarComponent = ({ x, y, width, height, color }) => (
//   <circle cx={x + width / 2} cy={y + height / 2} r={Math.min(width, height) / 2} fill={color} />
// );
//let minMemo = 0;
const CustomBarComponent = (data) => {
  const {
    x,
    y,
    width,
    height,
    color,
    showTooltip,
    hideTooltip,
    data: {
      fill,
      id,
      data: { text },
    },
  } = data;

  const lineWidth = 2;

  //if (id === 'min') minMemo = y - lineWidth;

  const tooltipContent = (
    <div className={styles.tooltip}>
      <div>
        <strong>Max:</strong>
        <strong>75%:</strong>
        <strong>25%:</strong>
        <strong>Min:</strong>
      </div>
      <div>
        <span>{text.max}</span>
        <span>{text.highPercentile}</span>
        <span>{text.lowPercentile}</span>
        <span>{text.min}</span>
      </div>
    </div>
  );

  return (
    // <circle cx={x + width / 2} cy={y + height / 2} r={Math.min(width, height) / 2} fill={color} />
    <g
      onMouseMove={(event) => {
        showTooltip(tooltipContent, event);
      }}
      onMouseLeave={() => {
        hideTooltip();
      }}
    >
      {id === 'main' && (
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          rx="8"
          fill={id === 'dummy' ? 'transparent' : fill}
        />
      )}
      {id === 'min' && (
        <rect
          x={x}
          y={y - lineWidth}
          width={width}
          height={lineWidth}
          fill={id === 'dummy' ? 'transparent' : color}
        />
      )}
      {id === 'max' && (
        <rect
          x={x}
          y={y + height}
          width={width}
          height={lineWidth}
          fill={id === 'dummy' ? 'transparent' : color}
        />
      )}
      {id === 'stickMin' && (
        <line
          x1={x + width / 2}
          y1={y}
          x2={x + width / 2}
          y2={y + height}
          stroke={color}
          strokeWidth={lineWidth}
        />
      )}
      {id === 'stickMax' && (
        <line
          x1={x + width / 2}
          y1={y}
          x2={x + width / 2}
          y2={y + height}
          stroke={color}
          strokeWidth={lineWidth}
        />
      )}
    </g>
  );
};

class CandleStickChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: props.layout || 'vertical',
      data: props.data,
    };
  }

  formatData(data) {
    return data.map((d) => {
      return {
        label: d.label,
        min: d.min,
        stickMin: d.lowPercentile - d.min,
        main: d.highPercentile - d.lowPercentile,
        stickMax: d.max - d.highPercentile,
        max: 1,
        text: d,
      };
    });
  }
  render() {
    const chartData = this.formatData(this.props.data);
    return (
      <div style={{ height: '250px' }} className={styles.chartWrapper}>
        {this.props.yLegend && <div className={styles.legend}>{this.props.yLegend}</div>}
        <ResponsiveBar
          theme={osstellChartTheme}
          barComponent={CustomBarComponent}
          data={chartData}
          keys={dataKeys}
          maxValue={90}
          indexBy={(d) => d.label}
          margin={{
            top: 10,
            right: 0,
            bottom: 50,
            left: this.state.layout === 'horizontal' ? 50 : 20,
          }}
          padding={0.65}
          valueScale={{ type: 'linear' }}
          colors={colors}
          colorBy="index"
          fill={fillMatch(this.state.data)}
          defs={gradients}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          layout={this.state.layout}
          enableGridX={this.state.layout === 'horizontal'}
          enableGridY={this.state.layout === 'vertical'}
          axisBottom={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legend: null,
            legendPosition: 'middle',
            legendOffset: 32,
            renderTick: ({ animatedProps, textAnchor, textBaseline, textX, textY, value }) => {
              return (
                <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    style={osstellChartTheme.axis.ticks.text}
                    transform={`translate(${textX},${textY + 10})`}
                  >
                    {this.state.layout === 'vertical' && getTspanGroups(value, 10)}
                    {this.state.layout !== 'vertical' && getTspanGroups(value, 10)}
                  </text>
                </animated.g>
              );
            },
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: null,
            legendPosition: 'end',
            legendOffset: -40,
            renderTick: ({ animatedProps, textAnchor, textBaseline, textX, textY, value }) => {
              return (
                <animated.g transform={animatedProps.transform} opacity={animatedProps.opacity}>
                  <text
                    alignmentBaseline={textBaseline}
                    textAnchor={textAnchor}
                    style={osstellChartTheme.axis.ticks.text}
                    transform={`translate(${textX},${textY})`}
                  >
                    {this.state.layout === 'vertical' && getTspanGroups(value, 10)}
                    {this.state.layout !== 'vertical' && getTspanGroups(value, 10)}
                  </text>
                </animated.g>
              );
            },
          }}
          enableLabel={false}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          isInteractive={true}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    );
  }
}

export default CandleStickChart;
