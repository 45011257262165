import React, { Component } from 'react';
import classNames from 'classnames';
import Spinner from '../Loaders/Spinner';

import styles from './Buttons.module.scss';

class LargButton extends Component {
  render() {
    const cn = classNames(
      this.props.className,
      styles.largeButton,
      !this.props.noShadow && styles.shadow,
      this.props.light ? styles.light : null,
      this.props.white ? styles.white : null,
      this.props.primary === true ? styles.primary : null,
      this.props.secondary === true ? styles.secondary : null,
      this.props.full === true ? styles.full : null,
      this.props.selected === true ? styles.selected : null,

      this.props.disabled === true
        ? this.props.primary === true
          ? styles.disabledPrimary
          : styles.disabled
        : null,
      !this.props.loading && (this.props.rightIcon || this.props.leftIcon) ? styles.hasIcon : null
    );

    return (
      <button
        id={this.props.id}
        className={cn}
        onClick={this.props.onClick}
        disabled={this.props.disabled === true}
      >
        <div className={classNames(styles.contentWrapper)}>
          {this.props.loading && <Spinner className={styles.spinnerBackground} />}
          {!this.props.loading && this.props.children}
        </div>

        {this.props.label && (
          <div className={classNames(styles.labelWrapper)}>{this.props.label}</div>
        )}
      </button>
    );
  }
}

export default LargButton;
