import { useEffect, useState } from 'react';
import { H1 } from '../../components/Shared/Headings';
import { Button } from '../../components/Shared/Buttons';
import { TabPanel } from '../../components/Shared/Tabs';
import { dummyData } from '../../constants/dummyData';
import { showAlert } from '../../redux/actions/generalActions';
import { appInsights } from '../../utils/applicationInsights';
import { getClinicInsights } from '../../redux/actions/insightActions';
import { useHistory, useParams } from 'react-router-dom';
import { Section, SectionInner } from '../../components/Shared/Section';
import { FormattedMessage, useIntl } from 'react-intl';
import { getKeyPerformanceIndicators } from '../../redux/actions/keyPerformanceIndicatorsActions';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getInstruments as getInstrumentsRequest } from '../../redux/actions/instrumentActions';
import { IClinicOverview, IFilterStats, IFilteredData } from '../../types/clinicOverview';
import RegisterInstrumentModal from '../devices-page/RegisterInstrumentModal';
import ClinicSettingsTabPanel from './ClinicSettingsTabPanel';
import TabsWithIconsOnMobile from '../../components/Shared/Tabs/TabsWithIconsOnMobile';
import ClinicTabPanel from './ClinicTabPanel';
import UsersTabPanel from './UsersTabPanel';
import Devices from '../../components/devices/Devices';
import Header from '../../components/header/Header';
import styles from './ClinicPage.module.scss';

const ClinicPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { userId } = useParams<{ userId: string | undefined }>();
  const { user } = useAppSelector((state) => state.user);
  const { clinic } = useAppSelector((state) => state.clinic);
  const { instruments, getInstruments } = useAppSelector((state) => state.instrument);
  const { clinicInsights } = useAppSelector((state) => state.insight);

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [filteredData, setFilteredData] = useState<IFilteredData>(dummyData);
  const [filterStats, setFilterStats] = useState<IFilterStats | null>(null);

  const headerButtonsLeft = [
    {
      label: intl.formatMessage({ id: 'general.back', defaultMessage: 'Back' }),
      icon: 'back',
      action: history.goBack,
    },
  ];

  useEffect(() => {
    appInsights.trackEvent({
      name: 'PageVisit',
      properties: {
        page: 'clinic',
        email: user.email,
      },
    });
    dispatch(getKeyPerformanceIndicators());
    dispatch(getClinicInsights());
    dispatch(getInstrumentsRequest(user.id));
  }, [user]);

  const hideRegisterInstrumentModal = () => setIsRegisterModalOpen(false);

  const showRegisterInstrumentModal = () => {
    if (user.role !== 'Demo') return setIsRegisterModalOpen(true);
    dispatch(
      showAlert(
        intl.formatMessage({
          id: 'patientList.demoLimitation',
          defaultMessage: 'DEMO Limitation',
        }),
        intl.formatMessage({
          id: 'general.actionLimitedOnDemoAccount',
          defaultMessage: 'You cannot perform this action on a demo account.',
        })
      )
    );
  };

  const filterChanged = (event: IClinicOverview) => {
    const { filterStats } = event;
    const data = { ...filteredData };
    data.healingTimeProtocol = event.filteredData.healingTimeProtocol;
    data.healingTimeBrand = event.filteredData.healingTimeBrand;
    data.healingTimeRisk = event.filteredData.healingTimeRisk;
    data.failureProtocol = event.filteredData.failureProtocol;
    data.failureProcedure = event.filteredData.failureProcedure;
    data.failureBrand = event.filteredData.failureBrand;
    data.failureRisk = event.filteredData.failureRisk;
    data.isqPlacement = event.filteredData.isqPlacement;
    data.isqReady = event.filteredData.isqReady;
    data.distributionProtocol = event.filteredData.distributionProtocol;
    data.distributionRisk = event.filteredData.distributionRisk;
    data.distributionStage = event.filteredData.distributionStage;
    data.distributionBrand = event.filteredData.distributionBrand;
    setFilteredData({ ...data });
    setFilterStats({ ...filterStats });
  };

  return (
    <div style={{ width: '100%' }}>
      <Header
        title={clinic.name?.toUpperCase()}
        rightButtons={[]}
        leftButtons={headerButtonsLeft}
      />
      <Section>
        <SectionInner>
          <TabsWithIconsOnMobile
            breakPoint={700}
            textTabs={[
              intl.formatMessage({
                id: 'clinicPage.clinicTab',
                defaultMessage: 'Clinic',
              }),
              intl.formatMessage({
                id: 'clinicPage.instrumentTab',
                defaultMessage: 'Instruments',
              }),
              intl.formatMessage({
                id: 'clinicPage.UsersTab',
                defaultMessage: 'Users',
              }),
              intl.formatMessage({
                id: 'clinicPage.settings',
                defaultMessage: 'Settings',
              }),
            ]}
            iconTabs={['clinic', 'beacon', 'resources', 'settings']}
            selectedTabPanelClassName={undefined}
            className={undefined}
            onChange={undefined}
          >
            <TabPanel>
              <ClinicTabPanel
                intl={intl}
                filterStats={filterStats}
                clinicInsights={clinicInsights}
                filteredData={filteredData}
                filterChanged={filterChanged}
              />
            </TabPanel>
            <TabPanel>
              <H1 className={styles.pageHeading}>
                <FormattedMessage id="clinicPage.instruments" defaultMessage="Instruments" />
              </H1>
              <div className={styles.addInstrument}>
                <Button
                  primary
                  value={intl.formatMessage({
                    id: 'devices.addInstrument',
                    defaultMessage: 'Add instrument',
                  })}
                  onClick={showRegisterInstrumentModal}
                  rightIcon="add"
                />
              </div>
              <Devices instruments={instruments} />
            </TabPanel>
            <TabPanel>
              <UsersTabPanel />
            </TabPanel>
            <TabPanel>{clinic.address && <ClinicSettingsTabPanel clinic={clinic} />}</TabPanel>
          </TabsWithIconsOnMobile>
        </SectionInner>
      </Section>
      <RegisterInstrumentModal
        show={isRegisterModalOpen}
        hide={() => hideRegisterInstrumentModal()}
        actAsOtherId={userId ? userId : null}
      />
    </div>
  );
};

export default ClinicPage;
