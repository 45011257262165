import SvgImplant from '../../../../../../shared/images/implant.svg';
import { Group, Image } from 'react-konva';
import Konva from 'konva';
import React, { useEffect, useRef, useState } from 'react';
import useImage from 'use-image';
import { UPDATE_IMPLANT_NODE } from '../../../../../../redux/slices/workspaceSlice';
import ImageNode from '../../../../../../common/graphics/consultation/node.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import { Position } from '../../../../../../redux/slices/data-structures/workspaceStructure';

interface Props {
  rootX: number;
  rootY: number;
  clampedWidth: number;
  index: number;
  changeCursor: (cursor: string) => void;
  type: string;
}

export function TeethCanvasTiltableImplant({
  rootX,
  rootY,
  clampedWidth,
  index,
  changeCursor,
  type,
}: Props) {
  const currentTool = useAppSelector((state) => state.consultation.view.currentTool);
  const currentSubTool = useAppSelector((state) => state.consultation.view.currentSubTool);
  const isSelected = currentTool?.name === 'Implant' && currentSubTool?.name === 'Transform';

  const [shape, setShape] = useState<Konva.ShapeConfig>({
    x: -clampedWidth / 4,
    y: -32,
    width: clampedWidth / 2,
    height: 64,
    rotation: 0,
  });

  const [img] = useImage(SvgImplant);
  const [nodeImg] = useImage(ImageNode);

  const imageRef = useRef<Konva.Image>(null);
  const implantGroup = useRef<Konva.Group>(null);

  const node = useAppSelector((state) => state.consultation.workspace.present.implantNode[index]);
  const [localNode, setLocalNode] = useState<Position>(node);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocalNode(node);
  }, [node]);

  useEffect(() => {
    const rot = (Math.atan2(localNode.y, localNode.x) * 180) / Math.PI;
    const distance = Math.hypot(localNode.y, localNode.x);
    setShape({
      ...shape,
      offsetY: distance,
      height: distance,
      rotation: rot + 90,
    });
    if (implantGroup.current) {
      implantGroup.current.draw();
    }
  }, [localNode]);

  useEffect(() => {
    if (type === 'TYPE2') {
      imageRef.current?.cache();
      imageRef.current?.filters([Konva.Filters.Brighten]);
      imageRef.current?.brightness(-0.3);
    }
  }, [img, localNode]);

  return (
    <Group x={rootX} y={rootY} ref={implantGroup}>
      {img && localNode && (
        <Group {...shape} offsetX={clampedWidth / 4}>
          <Image
            image={img}
            ref={imageRef}
            fillEnabled={false}
            width={shape.width}
            height={shape.height}
            perfectDrawEnabled={false}
          />
        </Group>
      )}

      {isSelected && nodeImg && (
        <Image
          key={`smile_node_${index}`}
          image={nodeImg}
          x={node.x}
          y={node.y}
          offsetX={clampedWidth / 2 - 3}
          offsetY={44}
          onDragMove={(e) => {
            setLocalNode({
              x: Math.round(e.target.attrs.x),
              y: Math.round(e.target.attrs.y),
            });
          }}
          onDragEnd={(e) => {
            dispatch(
              UPDATE_IMPLANT_NODE({
                id: index,
                x: Math.round(e.target.attrs.x),
                y: Math.round(e.target.attrs.y),
              })
            );
          }}
          draggable={isSelected}
          onMouseOver={() => changeCursor('grab')}
          onMouseLeave={() => changeCursor('crosshair')}
        />
      )}
    </Group>
  );
}
