import * as types from '../../constants/actionTypes';
import readModelHub from '../../utils/readModelHub';
import ApiFetch from '../../utils/apiFetch';

function getInstrumentsRequest() {
  return {
    type: types.GET_INSTRUMENTS,
  };
}

function getInstrumentsSuccess(data) {
  return {
    type: types.GET_INSTRUMENTS_SUCCESS,
    instruments: data,
  };
}

function getInstrumentsFailed(error) {
  return {
    type: types.GET_INSTRUMENTS_ERROR,
    error,
  };
}

export function getInstruments(userId, searchCriteria) {
  return (dispatch) => {
    dispatch(getInstrumentsRequest());

    const url = userId ? `user/${userId}/instruments` : 'admin/instruments';

    if (userId) {
      ApiFetch.get(url)
        .then((data) => {
          dispatch(getInstrumentsSuccess(data));
        })
        .catch((error) => {
          dispatch(getInstrumentsFailed(error));
        });
    } else {
      ApiFetch.post(url, searchCriteria)
        .then((data) => {
          dispatch(getInstrumentsSuccess(data));
        })
        .catch((error) => {
          dispatch(getInstrumentsFailed(error));
        });
    }
  };
}

/* ----------------------------------------------------------------------- */

function registerInstrumentRequest() {
  return {
    type: types.REGISTER_INSTRUMENT,
  };
}

function registerInstrumentSuccess(data) {
  return {
    type: types.REGISTER_INSTRUMENT_SUCCESS,
    instrument: data.instrument,
  };
}

function registerInstrumentFailed(error) {
  return {
    type: types.REGISTER_INSTRUMENT_ERROR,
    error,
  };
}

export function registerInstrument(
  userId,
  sourceSystem,
  variant,
  serialNumber,
  registrationCode,
  actAsOther,
  callback
) {
  return (dispatch) => {
    dispatch(registerInstrumentRequest());

    const request = {
      sourceSystem,
      variant,
      serialNumber,
      registrationCode,
    };

    const url = actAsOther === true ? `admin/instruments/${userId}` : `user/${userId}/instruments`;

    ApiFetch.post(url, request)
      .then((data) => {
        dispatch(registerInstrumentSuccess(data));

        if (callback) {
          callback(data.promotion);
        }
        if (data.promotion) dispatch({ type: types.ADD_USER_PROMOTION, promotion: data.promotion });
      })
      .catch((error) => {
        dispatch(registerInstrumentFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function deleteInstrumentsRequest() {
  return {
    type: types.DELETE_INSTRUMENTS,
  };
}

function deleteInstrumentsSuccess() {
  return {
    type: types.DELETE_INSTRUMENTS_SUCCESS,
  };
}

function deleteInstrumentsFailed(error) {
  return {
    type: types.DELETE_INSTRUMENTS_ERROR,
    error,
  };
}

export function deleteInstruments(instrumentIds, callback) {
  return (dispatch) => {
    dispatch(deleteInstrumentsRequest());

    ApiFetch.delete(`admin/instruments`, instrumentIds)
      .then((data) => {
        dispatch(deleteInstrumentsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(deleteInstrumentsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function setInstrumentExpectedFirmwaresRequest() {
  return {
    type: types.SET_INSTRUMENT_EXPECTED_FIRMWARES,
  };
}

function setInstrumentExpectedFirmwaresSuccess() {
  return {
    type: types.SET_INSTRUMENT_EXPECTED_FIRMWARES_SUCCESS,
  };
}

function setInstrumentExpectedFirmwaresFailed(error) {
  return {
    type: types.SET_INSTRUMENT_EXPECTED_FIRMWARES_ERROR,
    error,
  };
}

export function setInstrumentExpectedFirmwares(instrumentIds, firmwareId, callback) {
  return (dispatch) => {
    dispatch(setInstrumentExpectedFirmwaresRequest());

    if (firmwareId) {
      ApiFetch.put(`admin/firmwares/${firmwareId}/instruments`, instrumentIds)
        .then((data) => {
          dispatch(setInstrumentExpectedFirmwaresSuccess(data));
          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          dispatch(setInstrumentExpectedFirmwaresFailed(error));
        });
    } else {
      ApiFetch.delete(`admin/instruments/firmware`, instrumentIds)
        .then((data) => {
          dispatch(setInstrumentExpectedFirmwaresSuccess(data));
          callback();
        })
        .catch((error) => {
          dispatch(setInstrumentExpectedFirmwaresFailed(error));
        });
    }
  };
}

/* ----------------------------------------------------------------------- */

function shareInstrumentPatientsRequest(sharePatients) {
  return {
    type: types.SHARE_INSTRUMENT_PATIENTS,
    sharePatients,
  };
}

function shareInstrumentPatientsSuccess() {
  return {
    type: types.SHARE_INSTRUMENT_PATIENTS_SUCCESS,
  };
}

function shareInstrumentPatientsFailed(error) {
  return {
    type: types.SHARE_INSTRUMENT_PATIENTS_ERROR,
    error,
  };
}

export function shareInstrumentPatients(userId, instrumentId, sharePatients, actAsOther, callback) {
  return (dispatch) => {
    dispatch(shareInstrumentPatientsRequest(sharePatients));

    const url =
      actAsOther === true
        ? `admin/user/${userId}/instruments/${instrumentId}/sharePatients`
        : `user/${userId}/instruments/${instrumentId}/sharePatients`;

    ApiFetch.put(url, sharePatients)
      .then((data) => {
        dispatch(shareInstrumentPatientsSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(shareInstrumentPatientsFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function setInstrumentNameRequest(name) {
  return {
    type: types.SET_INSTRUMENT_NAME,
    name,
  };
}

function setInstrumentNameSuccess() {
  return {
    type: types.SET_INSTRUMENT_NAME_SUCCESS,
  };
}

function setInstrumentNameFailed(error) {
  return {
    type: types.SET_INSTRUMENT_NAME_ERROR,
    error,
  };
}

export function setInstrumentName(instrumentId, name) {
  return (dispatch) => {
    dispatch(setInstrumentNameRequest(name));

    const url = `instruments/${instrumentId}/name`;

    ApiFetch.put(url, name)
      .then((data) => {
        dispatch(setInstrumentNameSuccess(data));
      })
      .catch((error) => {
        dispatch(setInstrumentNameFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function setInstrumentsTypeRequest() {
  return {
    type: types.SET_INSTRUMENTS_TYPE,
  };
}

function setInstrumentsTypeSuccess() {
  return {
    type: types.SET_INSTRUMENTS_TYPE_SUCCESS,
  };
}

function setInstrumentsTypeFailed(error) {
  return {
    type: types.SET_INSTRUMENTS_TYPE_ERROR,
    error,
  };
}

export function setInstrumentsType(
  instrumentType,
  instrumentStatus,
  comment,
  instrumentIds,
  callback
) {
  return (dispatch) => {
    dispatch(setInstrumentsTypeRequest());

    const request = {
      instrumentIds: instrumentIds,
      type: instrumentType,
      setDemo: instrumentStatus.Demo,
      isDevelopment: instrumentStatus.Development,
      isBlocked: instrumentStatus.Blocked,
      comment: comment,
    };

    ApiFetch.put(`admin/instruments/setstatus`, request)
      .then((_) => {
        dispatch(setInstrumentsTypeSuccess());
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(setInstrumentsTypeFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

function setInstrumentsCommentRequest() {
  return {
    type: types.SET_INSTRUMENTS_COMMENT,
  };
}

function setInstrumentsCommentSuccess() {
  return {
    type: types.SET_INSTRUMENTS_COMMENT_SUCCESS,
  };
}

function setInstrumentsCommentFailed(error) {
  return {
    type: types.SET_INSTRUMENTS_COMMENT_ERROR,
    error,
  };
}

export function setInstrumentsComment(comment, instrumentIds, callback) {
  return (dispatch) => {
    dispatch(setInstrumentsCommentRequest());

    const request = {
      instrumentIds: instrumentIds,
      comment: comment,
    };

    ApiFetch.put(`admin/instruments/setcomment`, request)
      .then((_) => {
        dispatch(setInstrumentsCommentSuccess());
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        dispatch(setInstrumentsCommentFailed(error));
      });
  };
}

/* ----------------------------------------------------------------------- */

export function clearRegisterInstrument() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_REGISTER_INSTRUMENT,
    });
  };
}

/* ----------------------------------------------------------------------- */

export function clearInstruments() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_INSTRUMENTS,
    });
  };
}

/* ----------------------------------------------------------------------- */

export function subscribeToInstrumentsChange(userGroupIds, userId) {
  return (dispatch) => {
    if (userGroupIds) {
      userGroupIds.forEach((userGroupId) => {
        console.log(
          'SignalR: Subscribing to instruments (subscribeToInstrumentsChange) userGroupId:' +
            userGroupId
        );
        readModelHub.subscribe('instrument', userGroupId, () => {
          dispatch(getInstruments(userId));
        });
      });
    } else {
      console.log('SignalR: Subscribing to instruments (subscribeToInstrumentsChange)');
      readModelHub.subscribe('instrument', null, () => {
        dispatch(getInstruments());
      });
    }
  };
}

export function unsubscribeFromInstrumentsChange(userGroupIds) {
  return () => {
    if (userGroupIds) {
      userGroupIds.forEach((userGroupId) => {
        console.log(
          'SignalR: Unsubscribing from instruments (unsubscribeFromInstrumentsChange) userGroupId:' +
            userGroupId
        );
        readModelHub.unsubscribe('instrument', userGroupId);
      });
    } else {
      console.log('SignalR: Unsubscribing from instruments (unsubscribeFromInstrumentsChange)');
      readModelHub.unsubscribe('instrument');
    }
  };
}

/* ----------------------------------------------------------------------- */

export function receiveMeasurement(measurement) {
  return (dispatch) => {
    dispatch({
      type: types.RECEIVE_MEASUREMENT,
      receivedMeasurement: measurement,
    });
  };
}

/* ----------------------------------------------------------------------- */

const listeners = {
  status: {},
  measurement: {},
};

export function subscribeToReceviedMeasurement(instruemtId) {
  console.log(
    'SignalR: Subscribing to measurements (subscribeToReceviedMeasurement) instruemtId: ' +
      instruemtId
  );

  return (dispatch) => {
    listeners.measurement[instruemtId] = window.addEventListener('ble_msr', (e) => {
      if (e.detail.InstrumentId === instruemtId) dispatch(receiveMeasurement(e.detail));
    });

    readModelHub.subscribeToData('measurement', instruemtId, (data) => {
      dispatch(receiveMeasurement(data));
    });
  };
}

export function unsubscribeToReceviedMeasurement(instruemtId) {
  console.log(
    'SignalR: Unsubscribing from measurements (unsubscribeToReceviedMeasurement) instruemtId: ' +
      instruemtId
  );

  return () => {
    window.removeEventListener('ble_msr', listeners.measurement[instruemtId]);
    delete listeners.measurement[instruemtId];

    readModelHub.unsubscribeToData('measurement', instruemtId);
  };
}

/* ----------------------------------------------------------------------- */
export function receiveInstrumentStatus(data) {
  return (dispatch) => {
    dispatch({
      type: types.RECEIVE_INSTRUMENT_STATUS,
      activeInstrument: data,
    });
  };
}

export function subscribeToInstrumentStatus(userGroupId) {
  console.log(
    'SignalR: Subscribing to instrument status (subscribeToInstrumentStatus) userGroupId: ' +
      userGroupId
  );

  return (dispatch) => {
    listeners.status[userGroupId] = window.addEventListener('ble_status', (e) => {
      dispatch(receiveInstrumentStatus(e.detail));
    });

    readModelHub.subscribeToData('instrumentstatus', userGroupId, (data) => {
      dispatch(receiveInstrumentStatus(data));
    });
  };
}

export function unsubscribeToInstrumentStatus(userGroupId) {
  console.log(
    'SignalR: Unsubscribing from instrument status (unsubscribeToInstrumentStatus) userGroupId: ' +
      userGroupId
  );
  return () => {
    window.removeEventListener('ble_status', listeners.status[userGroupId]);
    delete listeners.status[userGroupId];

    readModelHub.unsubscribeToData('instrumentstatus', userGroupId);
  };
}

export function exportInstruments(searchCriteria, callback) {
  return () => {
    ApiFetch.downloadFile(`admin/instruments/export`, callback, searchCriteria);
  };
}
