import React from 'react';
import styles from './Headings.module.scss';
import cn from 'classnames';

const H4 = (props) => (
  <h4 className={cn(styles.h4, props.className)} style={props.style}>
    {props.children}
  </h4>
);

export default H4;
