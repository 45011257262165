import { toastr } from 'react-redux-toastr';
import { useIntl } from 'react-intl';
import { appInsights } from '../../utils/applicationInsights';
import { IImplantData } from '../../types/implant';
import { toastrOptions } from '../../constants/toastrOptions';
import { InputItem, InputRow } from '../Shared/Forms';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import SvgBarcodeliga from '../../shared/icons/osstell-light/BarcodeLiga';
import gs1Parser from '../../modules/gs1-parser';
import styled from 'styled-components';

interface Props {
  study: boolean;
  implants: IImplantData[];
  usedImplants: { count: number; dataId: number }[];
  dataId: string | number;
  onImplantSelected: (id: string | number) => void;
  lot: string;
  lotChanged: (evt: ChangeEvent<HTMLInputElement>) => void;
  setErrorMessages: (
    errorMessageArray: { messages: { text: string; id: string }[] } | undefined
  ) => void;
  resetImplantModalState: () => void;
  type: string;
  userId: string;
  email: string;
}

const ImplantSelector: FC<Props> = ({
  study,
  implants,
  usedImplants,
  dataId,
  onImplantSelected,
  lot,
  lotChanged,
  setErrorMessages,
  resetImplantModalState,
  type,
  userId,
  email,
}) => {
  const intl = useIntl();
  const [selectedFrequentlyUsedImplant, setSelectedFrequentlyUsedImplant] = useState<
    string | number
  >(-1);
  const [selectedImplantBrand, setSelectedImplantBrand] = useState('');

  useEffect(() => {
    if (implants && implants.length > 0 && Number(dataId) > 0) {
      const implant = implants.find((x) => x.dataId === dataId);
      if (implant) {
        setSelectedImplantBrand(implant.brandName);
      }
    }
  }, [implants, dataId]);

  const setErrorMessagesHandler = (errorMessage: string) => {
    setErrorMessages({
      messages: [
        {
          text: errorMessage,
          id: 'NoMatch',
        },
      ],
    });
  };

  interface BarcodeEvent extends Event {
    detail: string;
  }

  const boundOnScan = async (e: BarcodeEvent) => {
    let diNumberFound: string = '';
    let parsedLotNumber: string = '';
    let foundInOurDb: IImplantData | undefined;

    const barCodeStandard = e.type || null;
    const codeValue = e.detail;

    // toastr.success('barCodeStandard', typeof barCodeStandard + barCodeStandard, toastrOptions);

    // toastr.success('codeValue', typeof codeValue + codeValue, toastrOptions);

    resetImplantModalState();
    setSelectedImplantBrand('');
    setSelectedFrequentlyUsedImplant(-1);
    let parsingSuccess;

    if (barCodeStandard) {
      const udi = codeValue.replace('\x1D', '').trim();
      let result;

      try {
        result = gs1Parser(udi);
      } catch (error) {
        setErrorMessagesHandler(
          intl.formatMessage({
            id: 'addimplant.noMatchOnScan1',
            defaultMessage: `This barcode was not parsable. Barcode standard: GS1.}`,
          })
        );
        parsingSuccess = false;
        appInsights.trackEvent({
          name: 'ScannedArticleFail',
          properties: {
            type: type,
            barCodeStandard: barCodeStandard,
            parsingSuccess: false,
            foundInOurDb: false,
            barCode: codeValue,
            parsedLotNumber: false,
            userId: userId,
            email: email,
          },
        });
      }

      // go through all elements parsed and set di and lot if found
      if (result && result.parsedCodeItems) {
        parsingSuccess = true;
        result.parsedCodeItems.forEach((parsedElement) => {
          if (parsedElement.ai === '01') {
            toastr.success('parsedElement 01', parsedElement.ai, toastrOptions);

            diNumberFound = parsedElement.data;
          }

          if (parsedElement.ai === '10') {
            toastr.success('parsedElement 10', parsedElement.ai, toastrOptions);

            parsedLotNumber = parsedElement.data;
          }
        });
      }
    } else {
      setErrorMessagesHandler(
        intl.formatMessage({
          id: 'addimplant.noMatchOnScan2',
          defaultMessage: `No barcode standard we support was detected.`,
        })
      );
      parsingSuccess = false;

      appInsights.trackEvent({
        name: 'ScannedArticleFail',
        properties: {
          type: type,
          barCodeStandard: false,
          parsingSuccess: false,
          foundInOurDb: false,
          barCode: codeValue,
          parsedLotNumber: false,
          userId: userId,
          email: email,
        },
      });
    }

    if (parsingSuccess) {
      // same for all parsers
      if (diNumberFound) {
        toastr.success('diNumberFound', diNumberFound, toastrOptions);
        foundInOurDb = implants.find((implant) => implant?.udi === diNumberFound);
      }

      toastr.success('foundInOurDb', foundInOurDb!.brandName, toastrOptions);

      if (foundInOurDb) {
        setSelectedImplant(foundInOurDb.dataId);
      }

      if (parsedLotNumber) {
        lotChanged({ target: { value: parsedLotNumber } } as ChangeEvent<HTMLInputElement>);
      }

      if (foundInOurDb && parsedLotNumber) {
        appInsights.trackEvent({
          name: 'ScannedArticleSuccess',
          properties: {
            type: type,
            barCodeStandard: barCodeStandard,
            barCode: codeValue,
            parsedLotNumber: parsedLotNumber,
            userId: userId,
            email: email,
          },
        });
      } else {
        appInsights.trackEvent({
          name: 'ScannedArticleFail',
          properties: {
            type: type,
            barCodeStandard: barCodeStandard,
            parsingSuccess: parsingSuccess,
            foundInOurDb: foundInOurDb,
            barCode: codeValue,
            parsedLotNumber: parsedLotNumber,
            userId: userId,
            email: email,
          },
        });
      }

      if (!foundInOurDb && !parsedLotNumber) {
        setErrorMessagesHandler(
          intl.formatMessage({
            id: 'addimplant.noMatchOnScan3',
            defaultMessage: `Barcode was parsed but it didn't contain a LOT-number and we don't have a match on the UDI number to our SmartPeg reference list.`,
          })
        );
      } else if (!foundInOurDb) {
        setErrorMessagesHandler(
          intl.formatMessage({
            id: 'addimplant.noMatchOnScan4',
            defaultMessage: `The Lot-number was parsed but we don't have a match on the UDI number to our SmartPeg reference list.`,
          })
        );
      } else if (!parsedLotNumber) {
        setErrorMessagesHandler(
          intl.formatMessage({
            id: 'addimplant.noMatchOnScan5',
            defaultMessage: `We found a match on the UDI number to our SmartPeg reference list but the barcode didn't contain a LOT-number.`,
          })
        );
      }
    }
  };

  const implantBrandSelected = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelectedImplantBrand(evt.target.value);
    setSelectedFrequentlyUsedImplant(-1);
    onImplantSelected(-1);
  };

  const implantListSelected = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelectedFrequentlyUsedImplant(-1);
    onImplantSelected(evt.target.value);
  };

  const setSelectedImplant = (dataId: string | number) => {
    const implant = implants.find((x) => x.dataId === Number(dataId));
    if (implant) {
      const evt = { target: { value: implant.brandName } };
      implantBrandSelected(evt as ChangeEvent<HTMLInputElement>);
      onImplantSelected(dataId);
    }
  };

  const frequentlyUsedImplantSelected = (evt: ChangeEvent<HTMLInputElement>) => {
    setSelectedImplant(evt.target.value);
    setSelectedFrequentlyUsedImplant(evt.target.value);
  };

  const getBrands = () => {
    if (!implants || implants.length === 0) return [];
    let tempBrands = [];
    const implantStatus = study ? 3 : 0;
    tempBrands = implants.filter((imp) => imp.status === implantStatus);
    tempBrands = tempBrands
      .map((x) => {
        return x.brandName;
      })
      .filter((v, i, a) => a.indexOf(v) === i)
      .map((x) => {
        return { value: x };
      });
    tempBrands.sort(function (a, b) {
      return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
    });
    return tempBrands;
  };

  const getFrequentlyUsed = () => {
    if (!implants || implants.length === 0 || usedImplants === undefined) return [];

    const implantList: { key: number; value: string }[] = [];
    usedImplants.forEach((x) => {
      const implant = implants.find((imp) => imp.dataId === x.dataId);
      if (implant !== undefined && implant.status === 0) {
        implantList.push({
          key: implant.dataId,
          value:
            implant.brandName +
            (implant.modelName ? ', ' + implant.modelName : '') +
            (implant.platformName ? ', ' + implant.platformName : '') +
            (implant.diameter ? ', D' + implant.diameter : '') +
            (implant.length ? ', L' + implant.length : '') +
            (implant.partNumber ? ', ' + implant.partNumber : ''),
        });
      }
    });
    return implantList;
  };

  const getList = () => {
    if (selectedImplantBrand === '') return [];
    const implantStatus = study ? 3 : 0;
    let list = [];
    list = implants.filter(
      (x) => x.brandName === selectedImplantBrand && x.status === implantStatus
    );
    list = list.map((x) => {
      return {
        key: x.dataId,
        value:
          (x.modelName ? x.modelName + ', ' : '') +
          (x.platformName ? x.platformName + ', ' : '') +
          (x.diameter ? 'D' + x.diameter + ', ' : '') +
          (x.length ? 'L' + x.length + ', ' : '') +
          (x.partNumber ? x.partNumber : ''),
      };
    });
    list = list.sort(function (a, b) {
      return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
    });
    return list;
  };

  useEffect(() => {
    const barcodeEventListener: EventListener = (e) => {
      const barcodeEvent = e as BarcodeEvent;
      boundOnScan(barcodeEvent);
    };
    if (window.ReactNativeWebView) {
      window.addEventListener('barcode', barcodeEventListener, true);
    }

    return () => {
      if (window.ReactNativeWebView) {
        window.removeEventListener('barcode', barcodeEventListener, true);
      }
    };
  }, []);

  return (
    <div>
      {window.ReactNativeWebView && (
        <>
          <StyledLabel>
            {intl.formatMessage({
              id: 'addimplant.populateByScanningBarcode',
              defaultMessage: 'Populate by scanning QR-code or barcode',
            })}
          </StyledLabel>
          <StyledScanButton
            onClick={() => {
              window.ReactNativeWebView &&
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    type: 'startscan',
                    scanText: intl.formatMessage({
                      id: 'app.scanbarcode',
                      defaultMessage: 'Scan the UDI number',
                    }),
                  })
                );
            }}
          >
            <SvgBarcodeliga className={'icon'} width={'40'} />
          </StyledScanButton>
        </>
      )}
      {!study && (
        <>
          <InputRow>
            <InputItem
              label={intl.formatMessage({
                id: 'addimplant.populateFromfrequentlyUsed',
                defaultMessage: 'Populate from frequently used',
              })}
              type="select"
              options={getFrequentlyUsed()}
              noTranslate
              defaultKey={-1}
              defaultValue={intl.formatMessage({
                id: 'addimplant.pleaseSelect',
                defaultMessage: '- Please select -',
              })}
              value={selectedFrequentlyUsedImplant}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                frequentlyUsedImplantSelected(evt);
                setErrorMessages(undefined);
              }}
              onWhite
            />
          </InputRow>
          <StyledBorder />
        </>
      )}
      <InputRow>
        <InputItem
          label={intl.formatMessage({ id: 'addimplant.brand', defaultMessage: 'Brand' })}
          type="select"
          options={getBrands()}
          noTranslate
          defaultKey={-1}
          defaultValue={intl.formatMessage({
            id: 'addimplant.pleaseSelect',
            defaultMessage: '- Please select -',
          })}
          value={selectedImplantBrand}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            implantBrandSelected(evt);
            setErrorMessages(undefined);
          }}
          onWhite
        />
      </InputRow>
      {selectedImplantBrand !== '' ? (
        <InputRow>
          <InputItem
            label={intl.formatMessage({ id: 'addimplant.list', defaultMessage: 'List' })}
            type="select"
            options={getList()}
            noTranslate
            defaultKey={-1}
            defaultValue={intl.formatMessage({
              id: 'addimplant.pleaseSelect',
              defaultMessage: '- Please select -',
            })}
            value={dataId}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              implantListSelected(evt);
              setErrorMessages(undefined);
            }}
            onWhite
          />
        </InputRow>
      ) : null}
      <InputRow>
        <InputItem
          label={intl.formatMessage({
            id: 'addimplant.batchLotNumber',
            defaultMessage: 'Batch/Lot number',
          })}
          type="text"
          value={lot}
          onChange={(evt: ChangeEvent<HTMLInputElement>) => {
            lotChanged(evt);
            setErrorMessages(undefined);
          }}
          onWhite
        />
      </InputRow>
    </div>
  );
};

const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: 400;
  color: black;
  line-height: 1.5rem;
  display: block;
  height: 1.5rem;
  margin-bottom: 0.25rem;
  display: block;
`;

const StyledScanButton = styled.div`
  border: 'solid red 3px';
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(237, 238, 247);
  height: 48px;
  border-radius: 8px;
  width: 100%;
  .icon {
    display: block;
    top: 2px;
  }
`;

const StyledBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #edeef7;
  margin: 5px 0 5px 0;
`;

export default ImplantSelector;
