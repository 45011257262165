import { useAppDispatch, useAppSelector } from '../../../../../../utils/hooks';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TeethDraggableNode } from './TeethDraggableNode';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { UPDATE_NODE } from '../../../../../../redux/slices/workspaceSlice';
import SvgTeethNoseSinusGraftShape from './SvgTeethNoseSinusGraftShape';
import { Position } from '../../../../../../redux/slices/data-structures/workspaceStructure';

const SHOW_ORIENTATION = true;

export function TeethNoseShape({ enabled = false }: { enabled?: boolean }) {
  const { nodes } = useAppSelector((state) => state.consultation.workspace.present.sinusState[2]);

  const dispatch = useAppDispatch();
  const boundingArea = useRef<HTMLDivElement>(null);
  const nodeRefs = useRef<Array<HTMLDivElement>>([]);

  const [dragMode, setDragMode] = useState(false);
  const [posUpdate, setPosUpdate] = useState(false);
  const [nodePositions, setNodePositions] = useState<Array<gsap.Point2D | null>>([]);

  useEffect(() => {
    setNodePositions(
      nodes.map((_node: Position, index: number) => {
        const wrapper = nodeRefs.current[index];
        if (wrapper) {
          const nodeElement = wrapper.getElementsByTagName('div')[0];
          return {
            x: parseFloat(gsap.getProperty(nodeElement, 'x').toString()),
            y: parseFloat(gsap.getProperty(nodeElement, 'y').toString()),
          };
        }
        return null;
      })
    );
  }, [posUpdate, nodes]);

  const ScaleFactors = useCallback(() => {
    const scaleY = nodePositions[0]?.y;
    const normalizedScaleY = scaleY ? scaleY : 0;
    const scaleX = nodePositions[1]?.x;
    const normalizedScaleX = scaleX ? scaleX : 0;
    return [(normalizedScaleX * 0.008).toString(), (normalizedScaleY * 0.005).toString()];
  }, [nodePositions]);

  const path = useMemo(
    () => (
      <path
        id="NOSE"
        d="M5580.989,250.244c6.977,0,8.3,21.764,3.194,36.736s88.412,40.765,77.4-36.736-47.242-116.336-80.592-115.181-76.49,42.51-78.826,115.181,77.2,56.181,75.242,36.736S5574.012,250.244,5580.989,250.244Z"
        transform={`translate(-5442.113 -135.037) translate(5580 135) scale(${
          parseFloat(ScaleFactors()[0]) + 0.7
        } ${parseFloat(ScaleFactors()[1]) + 0.5}) translate(-5580 -135)`}
        fill={`url(#radial-gradient)`}
        opacity={0.7}
        onClick={() => {
          enabled && setDragMode((drag) => !drag);
        }}
        style={{ pointerEvents: enabled ? 'all' : 'none' }}
      />
    ),
    [ScaleFactors, setDragMode]
  );

  return (
    <PlacedArea>
      <SinusArea ref={boundingArea}>
        {/*Nose shape*/}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="360.371"
          height="368.551"
          viewBox="0 0 360.371 368.551"
          className={'Nose'}
        >
          <clipPath id={`pathMask_nose`}>{path}</clipPath>
          <svg clipPath={`url(#pathMask_nose`} opacity={0.6}>
            {<SvgTeethNoseSinusGraftShape />}
          </svg>

          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.629"
            r="0.663"
            gradientTransform="matrix(-0.987, 0.162, 0.255, 1.555, 0.833, -0.43)"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stopColor="#d0caca" />
            <stop offset="1" stopColor="#4b4b4b" />
          </radialGradient>

          {path}
        </svg>
        <div className={'nose_draggables'}>
          <TeethDraggableNode
            key={'nose_scaleY'}
            ref={(el) => (el ? (nodeRefs.current[0] = el) : undefined)}
            storedX={nodes[0].x}
            storedY={nodes[0].y}
            enabled={dragMode}
            setEnabled={setDragMode}
            onDragEnd={(_: PointerEvent, p) => {
              dispatch(
                UPDATE_NODE({
                  nodeArea: 'NOSE',
                  id: 0,
                  x: Math.round(p.x),
                  y: Math.round(p.y),
                })
              );
            }}
            onDrag={(_, __) => setPosUpdate((update) => !update)}
            bounds={{ minX: 0, minY: 0, maxX: 0, maxY: 100 }}
          />
          <TeethDraggableNode
            key={'nose_scaleX'}
            ref={(el) => (el ? (nodeRefs.current[1] = el) : undefined)}
            storedX={nodes[1].x}
            storedY={nodes[1].y}
            enabled={dragMode}
            setEnabled={setDragMode}
            onDragEnd={(_: PointerEvent, p) => {
              dispatch(
                UPDATE_NODE({
                  nodeArea: 'NOSE',
                  id: 1,
                  x: Math.round(p.x),
                  y: Math.round(p.y),
                })
              );
            }}
            onDrag={(_, __) => setPosUpdate((update) => !update)}
            bounds={{ minX: 0, minY: 0, maxX: 100, maxY: 0 }}
          />
        </div>

        {SHOW_ORIENTATION && (
          <svg className={'orientation'} data-html2canvas-ignore={true}>
            <defs>
              <marker
                id="arrowhead"
                markerWidth="8"
                markerHeight="6"
                refX="0"
                refY="3"
                orient="auto"
                opacity={0.5}
              >
                <polygon points="0 0, 8 3, 0 6" />
              </marker>
            </defs>
            <line
              x1="80"
              y1="40"
              x2="80"
              y2={(nodePositions[0]?.y || 0) + 41}
              stroke="red"
              strokeWidth="2"
              markerEnd="url(#arrowhead)"
              opacity={!dragMode ? 0.05 : 0.5}
              transform={'translate(26 20)'}
            />
            <line
              x1="40"
              y1="80"
              x2={(nodePositions[1]?.x || 0) + 41}
              y2="80"
              stroke="green"
              strokeWidth="2"
              markerEnd="url(#arrowhead)"
              opacity={!dragMode ? 0.05 : 0.5}
              transform={'translate(70 -30)'}
            />
          </svg>
        )}
      </SinusArea>
    </PlacedArea>
  );
}

const PlacedArea = styled.section`
  position: absolute;
  left: calc(50% - 8px);
  top: -30px;
  width: 100px;
  height: 300px;
  pointer-events: none;
`;
const SinusArea = styled.div`
  display: grid;
  place-content: center;
  margin-top: 40px;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  pointer-events: none;

  & > svg.orientation {
    grid-area: 1 / 1 / 2 / 2;
    width: 200px;
    margin-left: 32px;
    line {
      transition: opacity 300ms ease-in;
    }
  }

  & > svg.Nose {
    grid-area: 1 / 1 / 2 / 2;
    path {
      pointer-events: auto;
    }
  }

  & > div {
    width: 120px;
    height: 120px;
    grid-area: 1 / 1 / 2 / 2;
    margin-left: 122px;
    margin-top: 32px;
  }
`;
