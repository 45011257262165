import * as React from 'react';

function SvgSvgSave(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.85 25.85" {...props}>
      <defs>
        <style>{'.SvgSave_svg__cls-1{fill:none;stroke-miterlimit:10}'}</style>
      </defs>
      <g id="SvgSave_svg__Layer_2" data-name="Layer 2">
        <g id="SvgSave_svg__Layer_1-2" data-name="Layer 1">
          <path
            className="SvgSave_svg__cls-1"
            d="M25.35 5.74v18.91a.7.7 0 01-.71.7H1.21a.7.7 0 01-.71-.7V1.21A.71.71 0 011.21.5h18.9a5.24 5.24 0 015.24 5.24z"
            stroke={props.fill}
          />
          <path
            className="SvgSave_svg__cls-1"
            d="M17.91.5h1.35a.7.7 0 01.7.71v6.4a.7.7 0 01-.7.71H6a.71.71 0 01-.71-.71v-6.4A.71.71 0 016 .5h9.32"
            stroke={props.fill}
          />
          <rect
            className="SvgSave_svg__cls-1"
            x={2.78}
            y={15.14}
            width={19.07}
            height={10.21}
            rx={0.71}
            stroke={props.fill}
          />
          <path
            className="SvgSave_svg__cls-1"
            d="M17.91.5v5.05a.71.71 0 01-.71.71H16a.71.71 0 01-.71-.71V.5"
            stroke={props.fill}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgSvgSave;
