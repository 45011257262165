import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Section, SectionInner } from '../../components/Shared/Section';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { getInstruments as getInstrumentsRequest } from '../../redux/actions/instrumentActions';
import RegisterInstrumentModal from './RegisterInstrumentModal';
import Devices from '../../components/devices/Devices';
import Header from '../../components/header/Header';

const DevicesPage = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { userId } = useParams<{ userId: string | undefined }>();

  const { user } = useAppSelector((state) => state.user);
  const { instruments } = useAppSelector((state) => state.instrument);

  const [fullModal, setFullModal] = useState(false);
  const [showRegisterInstrumentModal, setShowRegisterInstrumentModal] = useState(false);

  const actAsOther = userId ? true : false;

  const headerButtonsRight = [
    {
      label: intl.formatMessage({ id: 'devices.addNew', defaultMessage: 'Add new' }),
      icon: 'add',
      action: () => showRegisterInstrumentModalHandler(),
    },
  ];

  useEffect(() => {
    if (
      user.role !== 'SuperAdmin' &&
      user.role !== 'AdminLv1' &&
      user.role !== 'AdminLv2' &&
      actAsOther
    )
      return history.push('/home');

    dispatch(getInstrumentsRequest(getCurrentUserId()));
  }, []);

  const getCurrentUserId = () => userId || user.id;

  const showRegisterInstrumentModalHandler = () => {
    setShowRegisterInstrumentModal(true);
    setFullModal(instruments.length === 0);
  };

  const hideRegisterInstrumentModal = () => {
    setShowRegisterInstrumentModal(false);
    setFullModal(false);
  };

  const isDemo = user.role === 'Demo';

  return (
    <div>
      <Header
        title={
          intl.formatMessage({
            id: 'devices.Instruments',
            defaultMessage: 'INSTRUMENTS',
          }) + (actAsOther ? ' (Admin)' : '')
        }
        isTogglerHidden
        rightButtons={headerButtonsRight}
      />
      <Section>
        <SectionInner>
          <Devices instruments={instruments} actAsOtherId={userId || null} />
        </SectionInner>
      </Section>
      <RegisterInstrumentModal
        fullModal={fullModal}
        demo={isDemo}
        show={showRegisterInstrumentModal}
        hide={hideRegisterInstrumentModal}
        actAsOtherId={userId || null}
      />
    </div>
  );
};

export default DevicesPage;
