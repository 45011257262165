import * as types from '../../constants/actionTypes';

const initialState = {
  getUserGroups: {
    complete: false,
    error: undefined,
    busy: false,
    userGroups: [],
  },
  connectUserGroupToInstruments: {
    complete: false,
    error: undefined,
    busy: false,
  },
};

export default function userGroups(state = initialState, action) {
  switch (action.type) {
    case types.GET_USER_GROUPS:
      return Object.assign({}, state, {
        getUserGroups: {
          complete: false,
          error: undefined,
          busy: true,
          userGroups: state.getUserGroups.userGroups,
        },
      });
    case types.GET_USER_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        getUserGroups: {
          complete: true,
          error: undefined,
          busy: false,
          userGroups: action.userGroups,
        },
      });
    case types.GET_USER_GROUPS_ERROR:
      return Object.assign({}, state, {
        getUserGroups: {
          complete: false,
          error: action.error,
          busy: false,
          userGroups: state.getUserGroups.userGroups,
        },
      });
    case types.CONNECT_USER_GROUP_TO_INSTRUMENTS:
      return Object.assign({}, state, {
        connectUserGroupToInstruments: { complete: false, error: undefined, busy: true },
      });
    case types.CONNECT_USER_GROUP_TO_INSTRUMENTS_SUCCESS:
      return Object.assign({}, state, {
        connectUserGroupToInstruments: { complete: true, error: undefined, busy: false },
      });
    case types.CONNECT_USER_GROUP_TO_INSTRUMENTS_ERROR:
      return Object.assign({}, state, {
        connectUserGroupToInstruments: { complete: false, error: action.error, busy: false },
      });
    case types.CLEAR_TOKEN:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}
