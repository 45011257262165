import { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import sectionStyles from './Section.module.scss';
import styles from './SectionTabNav.module.scss';
import cn from 'classnames';

class SectionTabNav extends Component {
  constructor(props) {
    super(props);
    this.selectTab = this.selectTab.bind(this);
    this.state = {
      selectedTabIndex: 0,
    };
  }

  selectTab(selectedTabIndex) {
    this.setState({
      selectedTabIndex: selectedTabIndex,
    });
  }

  render() {
    return (
      <div>
        <Tabs onSelect={this.selectTab}>
          <TabList
            className={cn(
              styles.tabList,
              sectionStyles.inner,
              this.props.marginBottom ? styles.marginBottom : null
            )}
          >
            {this.props.tabs.map((tab, i) => {
              const classNames = cn(
                this.state.selectedTabIndex === i ? styles.active : null,
                tab.disabled ? styles.tabDisabled : null
              );
              return (
                <Tab key={i} className={classNames} disabled={tab.disabled}>
                  {tab.name}
                </Tab>
              );
            })}
          </TabList>
          {this.props.children.map((child, i) => (
            <TabPanel key={i}>{child}</TabPanel>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default SectionTabNav;
