import * as types from '../../constants/actionTypes';
import ApiFetch from '../../utils/apiFetch';

function getEulaRequest() {
  return {
    type: types.GET_EULA,
  };
}

function getEulaSuccess(data) {
  return {
    type: types.GET_EULA_SUCCESS,
    data: data,
  };
}

function getEulaFailed(error) {
  return {
    type: types.GET_EULA_ERROR,
    error,
  };
}

export function getEula(country) {
  return (dispatch) => {
    dispatch(getEulaRequest());

    ApiFetch.get(`eula/${country}`, true)
      .then((data) => {
        dispatch(getEulaSuccess(data));
      })
      .catch((error) => {
        dispatch(getEulaFailed(error));
      });
  };
}

function acceptEulaRequest() {
  return {
    type: types.ACCEPT_EULA,
  };
}

function acceptEulaSuccess() {
  return {
    type: types.ACCEPT_EULA_SUCCESS,
  };
}

function acceptEulaFailed(error) {
  return {
    type: types.ACCEPT_EULA_ERROR,
    error,
  };
}

export function acceptEula(userId, eulaId) {
  return (dispatch) => {
    dispatch(acceptEulaRequest());

    ApiFetch.put(`users/${userId}/eula/${eulaId}/accept`)
      .then((data) => {
        dispatch(acceptEulaSuccess(data));
      })
      .catch((error) => {
        dispatch(acceptEulaFailed(error));
      });
  };
}
