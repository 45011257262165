import { Row } from '../../components/Shared/Section';
import { toastr } from 'react-redux-toastr';
import { IClinic } from '../../types/clinic';
import { Button } from '../../components/Shared/Buttons';
import { showAlert } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router';
import { updateClinic } from '../../redux/actions/clinicActions';
import { numberOfPractitioners } from '../../constants/referenceData';
import { FormattedMessage, useIntl } from 'react-intl';
import { clinicState as defaultValue } from '../../constants/defaultStates';
import { useAppDispatch, useAppSelector } from '../../utils/hooks';
import { useState, useEffect, FC, ChangeEvent } from 'react';
import { InputItem, InputRow, InputGroup, InputSubGroup } from '../../components/Shared/Forms';
import ImageUploader from '../../components/Images/ImageUploader';
import styles from './ClinicPage.module.scss';
import { UserPermissionEnum } from '../../constants/enums';

interface Props {
  clinic: IClinic;
}
const ClinicSettingsTabPanel: FC<Props> = ({ clinic }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { countries } = useAppSelector((state) => state.referenceData);
  const { clinicUpdate } = useAppSelector((state) => state.clinic);

  const [isUpdating, setIsUpdating] = useState(false);
  const [clinicState, setClinicState] = useState<IClinic>(defaultValue);

  const countriesList = countries.map((country: { key: string; value: string }) => {
    return { label: country.value, value: country.key };
  });

  const isNotAdmin = user.sourceUserGroup.userPermission !== UserPermissionEnum.ADMIN;

  useEffect(() => {
    setClinicState(clinic);
  }, [clinic]);

  useEffect(() => {
    if (isUpdating && clinicUpdate.complete && !clinicUpdate.error) {
      setIsUpdating(false);
      toastr.success(
        intl.formatMessage({ id: 'general.success', defaultMessage: 'Success' }),
        intl.formatMessage({
          id: 'clinicSettings.clinicUpdated',
          defaultMessage: 'Clinic information updated',
        }),
        {
          showCloseButton: false,
        }
      );
    }
    if (clinicUpdate.error) {
      setIsUpdating(false);
      toastr.error(
        intl.formatMessage({ id: 'general.error', defaultMessage: 'Error' }),
        intl.formatMessage({
          id: 'general.requestProblem',
          defaultMessage: 'There was a problem with your request.',
        }),
        {
          showCloseButton: false,
        }
      );
    }
  }, [clinicUpdate, intl, isUpdating]);

  const clinicNameChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      name: evt.target.value,
    }));
  };

  const streetChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        street: evt.target.value,
      },
    }));
  };

  const careOfChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        careOf: evt.target.value,
      },
    }));
  };

  const postcodeChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        postcode: evt.target.value,
      },
    }));
  };

  const stateChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        state: evt.target.value,
      },
    }));
  };

  const cityChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        city: evt.target.value,
      },
    }));
  };

  const countryChanged = (option: { value: string }) => {
    setClinicState((prevState) => ({
      ...prevState,
      address: {
        ...prevState.address,
        countryCode: option.value,
      },
    }));
  };

  const numberOfPractitionersChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    setClinicState((prevState) => ({
      ...prevState,
      numberOfPractitionersInClinic: evt.target.value,
    }));
  };

  const updateClinicHandler = () => {
    if (user.role === 'Demo') {
      dispatch(
        showAlert(
          intl.formatMessage({
            id: 'patientList.demoLimitation',
            defaultMessage: 'DEMO Limitation',
          }),
          intl.formatMessage({
            id: 'general.actionLimitedOnDemoAccount',
            defaultMessage: 'You cannot perform this action on a demo account.',
          })
        )
      );
    } else {
      setIsUpdating(true);
      dispatch(updateClinic(clinicState));
    }
  };

  const missingField = () => {
    return (
      !clinicState.name ||
      !clinicState.address.street ||
      !clinicState.address.countryCode ||
      !clinicState.address.postcode ||
      !clinicState.address.city
    );
  };

  return (
    <section className={styles.settingsSection}>
      <InputGroup
        label={intl.formatMessage({
          id: 'clinicSettings.ClinicInformation',
          defaultMessage: 'Clinic information',
        })}
      >
        <InputRow>
          <InputItem
            label={
              '*' + intl.formatMessage({ id: 'general.clinic', defaultMessage: 'Clinic Name' })
            }
            type="text"
            onChange={clinicNameChanged}
            value={clinicState.name}
          />
          <InputItem
            label={intl.formatMessage({
              id: 'general.numberOfPractitioners',
              defaultMessage: 'Number of practitioners in clinic',
            })}
            type="select"
            options={numberOfPractitioners}
            onChange={numberOfPractitionersChanged}
            value={clinicState.numberOfPractitionersInClinic}
          />
        </InputRow>
        <InputRow>
          <InputItem
            label={'*' + intl.formatMessage({ id: 'general.address', defaultMessage: 'Address' })}
            type="text"
            onChange={streetChanged}
            value={clinicState.address.street}
          />
          <InputItem
            label={intl.formatMessage({ id: 'general.co', defaultMessage: 'Co' })}
            type="text"
            onChange={careOfChanged}
            value={clinicState.address.careOf}
          />
        </InputRow>
        <InputRow>
          <InputItem
            label={'*' + intl.formatMessage({ id: 'general.zipCode', defaultMessage: 'Zip code' })}
            type="text"
            onChange={postcodeChanged}
            value={clinicState.address.postcode}
          />
          <InputItem
            label={intl.formatMessage({ id: 'general.state', defaultMessage: 'State' })}
            type="text"
            onChange={stateChanged}
            value={clinicState.address.state}
          />
          <InputItem
            label={'*' + intl.formatMessage({ id: 'general.city', defaultMessage: 'City' })}
            type="text"
            onChange={cityChanged}
            value={clinicState.address.city}
          />
        </InputRow>
        <InputRow>
          <InputItem
            label={'*' + intl.formatMessage({ id: 'general.country', defaultMessage: 'Country' })}
            type="search-select"
            options={countriesList}
            onChange={countryChanged}
            value={clinicState.address.countryCode}
          />
        </InputRow>
        <InputSubGroup
          label={intl.formatMessage({
            id: 'general.clinicLogo',
            defaultMessage: 'Clinic Logo',
          })}
        >
          <ImageUploader
            // @ts-ignore
            size={200}
            image={clinicState.logo}
            label={intl.formatMessage({
              id: 'general.upload',
              defaultMessage: 'Upload',
            })}
            onUpload={(image) =>
              setClinicState((prevState) => ({
                ...prevState,
                logo: image,
              }))
            }
          />
        </InputSubGroup>
      </InputGroup>
      <Row right>
        <em>
          * <FormattedMessage id="general.mandatoryFields" defaultMessage="Mandatory fields" />
        </em>
      </Row>
      <Row right className={styles.buttonRow}>
        <Button
          value={intl.formatMessage({ id: 'general.cancel', defaultMessage: 'Cancel' })}
          rightIcon="close"
          onClick={history.goBack}
          white
        />
        <Button
          primary
          value={intl.formatMessage({ id: 'general.save', defaultMessage: 'Save' })}
          rightIcon="done"
          onClick={updateClinicHandler}
          disabled={isUpdating || missingField() || isNotAdmin}
          loading={isUpdating}
        />
      </Row>
    </section>
  );
};

export default ClinicSettingsTabPanel;
