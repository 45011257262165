import React, { Component } from 'react';
import Dropdown from '../Dropdown/Dropdown';
import InputItem from './InputItem';

class SearchSelect extends Component {
  constructor(props) {
    super(props);

    if (!props.filterServer) {
      this.state = {
        data: props.options,
        filteredData: props.options,
      };
    } else {
      this.state = {
        searchText: '',
      };
    }

    this.filterData = this.filterData.bind(this);
  }

  filterData(event) {
    event.preventDefault();

    this.lastKeyPress = Date.now();
    const filterValue = event.target.value;

    setTimeout(() => {
      let now = Date.now() - 300;
      if (this.lastKeyPress <= now) {
        if (this.props.filterServer) {
          this.props.filterServer(filterValue);
          this.setState({
            searchText: filterValue,
          });
        } else {
          const regex = new RegExp(filterValue, 'i');
          const filtered = this.state.data.filter(function (option) {
            return option.label.search(regex) > -1;
          });

          this.setState({
            filteredData: filtered,
          });
        }
      }
    }, 300);
  }

  render() {
    const options = this.props.filterServer
      ? this.state.searchText
        ? this.props.options
        : []
      : this.state.filteredData;

    return (
      <Dropdown
        searchSelect
        fullWidth
        align="left"
        options={options}
        filterServer={this.props.filterServer}
        noTranslate={this.props.noTranslate ? true : false}
      >
        <InputItem
          type="search"
          small={this.props.small}
          label={this.props.label}
          onChange={this.filterData}
          placeholder={this.props.placeholder}
          noBorder={this.props.noBorder}
          noPadding={this.props.noPadding}
        />
      </Dropdown>
    );
  }
}

export default SearchSelect;
