import * as React from 'react';

function SvgBridge(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1873 2048" {...props}>
      <path d="M515 673q62 0 99 46t37 105q0 35-7 87.5t-22.5 117-48.5 109-77 44.5H257q-44 0-77-44.5t-48.5-109-22.5-117-7-87.5q0-58 34-100t97-42q32 0 62 10t47.5 21 41.5 30l90 73q8 6 8 16 0 9-6 15t-15 6q-5 0-12-5-73-58-91-72-67-52-125-52-29 0-48.5 12.5t-27 32-10 32T145 824q0 42 7.5 95t21 103 36 84 47.5 34h239q25 0 47.5-34t36-84 21-103 7.5-95q0-39-23.5-74T515 715q-39 0-71 19-18 11-29-7-11-19 7-30 39-24 93-24zm563 0q62 0 98.5 46t36.5 105q0 35-7 87t-22 117-48 109.5-77 44.5H820q-44 0-77-44.5t-48.5-109-22.5-117-7-87.5q0-58 34-100.5t97-42.5q32 0 62 10t47.5 21 41.5 30l90 74q7 7 7 15 0 9-6 15t-15 6q-5 0-12-5-72-57-90-71-67-52-125-52-29 0-48.5 12.5t-27 32-10 32T708 824q0 42 7.5 95t21 103 36 84 47.5 34h239q25 0 47.5-34t36-84 21-103 7.5-95q0-39-23.5-74t-69.5-35q-39 0-71 19-8 4-16.5 2T977 726q-4-8-2-16t10-13q39-24 93-24zm558 0q62 0 98.5 46t36.5 105q0 35-7 87t-22 117-48 109.5-77 44.5h-239q-44 0-77-44.5t-48.5-109-22.5-117-7-87.5q0-58 34-100.5t97-42.5q32 0 62 10t47.5 21 41.5 30l90 74q8 6 8 16 0 9-6 15t-15 6q-6 0-13-5-72-58-90-72-67-52-125-52-29 0-48.5 12.5t-27 32-10 32-2.5 23.5q0 42 7.5 95t21 103 36 84 47.5 34h239q25 0 47.5-34t36-84 21-103 7.5-95q0-39-23.5-74t-69.5-35q-39 0-71 19-19 11-30-7-4-8-2-16.5t10-13.5q39-24 93-24z" />
    </svg>
  );
}

export default SvgBridge;
