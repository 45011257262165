import { render } from 'react-dom';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Store from './redux/store';

import history from './utils/history';

import App from './containers/App.jsx';
import { SmartPegToolPage } from './pages';
import { PersistGate } from 'redux-persist/integration/react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './utils/applicationInsights';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { osstellConnectTheme } from './config/theme';

render(
  <AppInsightsContext.Provider value={reactPlugin}>
    <MuiThemeProvider theme={osstellConnectTheme}>
      <Provider store={Store.store}>
        <PersistGate loading={null} persistor={Store.persistor}>
          <BrowserRouter
            history={history}
            getUserConfirmation={() => {
              /* Empty callback to block the default browser prompt */
            }}
          >
            <Switch>
              <Route exact path="/findsmartpeg" component={SmartPegToolPage} />
              <Route path="/findsmartpeg/:draft" component={SmartPegToolPage} />
              <Route path="/" component={App} />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  </AppInsightsContext.Provider>,
  document.getElementById('root')
);
